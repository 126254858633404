import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Grid, Typography, Button } from '@material-ui/core';
import { Testimonial } from '../model';
import axios from '../../utils/axios.utils';
import TestimonialForm from '../components/TestimonialForm';

import AdminPageHeader from '../../adminTools/components/AdminPageHeader';

const EditTestimonialPage: React.FC = () => {
  const { testimonialId } = useParams<{ testimonialId: string }>();
  const history = useHistory();

  const [formValues, setFormValues] = useState<Testimonial>();
  const [formErrors, setFormErrors] = useState<string>('');
  const [imageFileData, setImageFileData] = useState<FormData>();

  const editTestimonial = async () => {
    const combinedFormData = new FormData();

    // Append the image file, then the rest of the formValues
    if (imageFileData) {
      imageFileData.forEach((value, key) => {
        combinedFormData.append(key, value);
      });
    }

    Object.entries(formValues).forEach(([key, value]) => {
      combinedFormData.append(`testimonial[${key}]`, value);
    });

    try {
      await axios.put(`testimonials/${testimonialId}`, combinedFormData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      history.push(`/admin/testimonials/${testimonialId}`);
    } catch (error: any) {
      setFormErrors(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    if (testimonialId) {
      axios.get(`testimonials/${testimonialId}`).then((response) => {
        setFormValues(response?.data?.result);
      });
    }
  }, [testimonialId]);

  return (
    <Grid container style={{ padding: '1% 2%' }} spacing={3}>
      <AdminPageHeader title={formValues.name} backButton={{ link: '/admin/testimonials', label: 'Testimonials' }} />
      <Grid item xs={12}>
        <TestimonialForm
          formValues={formValues}
          setFormValues={setFormValues}
          formErrors={formErrors}
          setImageFileData={setImageFileData}
        />
      </Grid>
      <Grid item xs={2}>
        <Button onClick={() => editTestimonial()} className="primary-btn">
          Update
        </Button>
      </Grid>
    </Grid>
  );
};

export default EditTestimonialPage;
