import React from 'react';
import { IDataTableColumn } from '../../dataTable/DataTable/model';

const completedInvitationsColumns: IDataTableColumn<any>[] = [
  {
    name: 'Sign Up Date',
    selector: 'created_at',
    sortable: true,
  },
  {
    name: 'Amount',
    selector: 'inviter_amount',
    sortable: true,
    cell: (row) => <div>{row.invitation_opportunity_id === 30 ? 'N/A' : `$${row.inviter_amount}`}</div>,
  },
  {
    name: 'Status',
    selector: 'status',
    sortable: true,
    cell: ({ status }) => (
      <div
        style={{
          fontWeight: 700,
          textTransform: 'uppercase',
          fontSize: 14,
          color: ['opportunity booked', 'payment pending', 'complete', 'survey complete'].includes(status)
            ? 'green'
            : '#4ba9c8',
          whiteSpace: 'break-spaces',
        }}
      >
        {status}
      </div>
    ),
  },
];

export default completedInvitationsColumns;
