import React, { useState } from 'react';
import axios from '../../utils/axios.utils';
import { Opportunity } from '../../opportunities/model';
import { DashboardInterview, ProfileCompletePercentages, InsighterDashboardResponse } from '../model';

interface InsighterDashboardContextInterface {
  fetchDashboardOpportunities?: (impersonatedUserId?: string) => Promise<void>;
  interviews?: DashboardInterview[];
  recommendedOpportunities?: Opportunity[];
  opportunityLoading?: boolean;
  invitationOpportunitiesAvailable?: boolean;
  fetchProfileCompletePercentages?: () => Promise<void>;
  profileCompletePercentages?: ProfileCompletePercentages;
}

const InsighterDashboardContext = React.createContext<InsighterDashboardContextInterface>({});
const InsighterDashboardContextConsumer = InsighterDashboardContext.Consumer;

const InsighterDashboardContextProvider: React.FC = ({ children }) => {
  const [interviews, setInterviews] = useState<DashboardInterview[]>([]);
  const [recommendedOpportunities, setRecommendedOpportunities] = useState<Opportunity[]>([]);
  const [opportunityLoading, setOpportunityLoading] = useState<boolean>(false);
  const [invitationOpportunitiesAvailable, setInvitationOpportunitiesAvailable] = React.useState(false);
  const [profileCompletePercentages, setProfileCompletePercentages] = React.useState<ProfileCompletePercentages>({});
  const [profileCompletePercentagesLoading, setProfileCompletePercentagesLoading] = React.useState(false);

  const fetchDashboardOpportunities = async (impersonatedUserId?: string) => {
    setOpportunityLoading(true);
    let response: InsighterDashboardResponse;

    if (impersonatedUserId) {
      response = await axios.get<string, InsighterDashboardResponse>(`impersonations/${impersonatedUserId}/dashboard`);
      setProfileCompletePercentages(response?.data?.profile_complete_percentages);
    } else {
      response = await axios.get<string, InsighterDashboardResponse>(`insighter_dashboards.json`);
    }

    setInterviews(response?.data?.interviews);
    setRecommendedOpportunities(response?.data?.recommended_opportunities);
    setInvitationOpportunitiesAvailable(response?.data?.invitation_opportunities_available);
    setOpportunityLoading(false);
  };

  const fetchProfileCompletePercentages = async () => {
    setProfileCompletePercentagesLoading(true);
    const response = await axios.get<string, any>('profile_complete_percentages');

    setProfileCompletePercentagesLoading(false);
    setProfileCompletePercentages(response?.data);
  };

  return (
    <InsighterDashboardContext.Provider
      value={{
        fetchDashboardOpportunities,
        interviews,
        recommendedOpportunities,
        opportunityLoading,
        invitationOpportunitiesAvailable,
        fetchProfileCompletePercentages,
        profileCompletePercentages,
      }}
    >
      {children}
    </InsighterDashboardContext.Provider>
  );
};

export { InsighterDashboardContextProvider, InsighterDashboardContextConsumer, InsighterDashboardContext };
