import React, { Dispatch, SetStateAction, useState, useEffect } from 'react';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import FormInput from '../../form/components/FormInput';
import { TeamBio } from '../model';

interface TeamBioFormProps {
  formValues: TeamBio;
  setFormValues: Dispatch<SetStateAction<TeamBio>>;
  formErrors: string;
}

const TeamBioForm: React.FC<TeamBioFormProps> = (props: TeamBioFormProps) => {
  const { formValues, setFormValues, formErrors } = props;

  const [imagePreview, setImagePreview] = useState<string | null>(formValues?.main_image || null);

  useEffect(() => {
    if (formValues?.main_image) {
      setImagePreview(formValues?.main_image);
    }
  }, [formValues?.main_image]);

  return (
    <Card style={{ padding: 20 }}>
      <Grid container spacing={3}>
        <Grid item xs={10}>
          <FormInput
            type="text"
            label="Name"
            value={formValues?.name}
            onChange={(value) => setFormValues({ ...formValues, name: value })}
          />
        </Grid>
        <Grid item xs={10}>
          <FormInput
            type="text"
            label="Title"
            value={formValues?.title}
            onChange={(value) => setFormValues({ ...formValues, title: value })}
          />
        </Grid>
        <Grid item xs={10}>
          <FormInput
            type="text"
            label="LinkedIn Profile Link"
            value={formValues?.link}
            onChange={(value) => setFormValues({ ...formValues, link: value })}
          />
        </Grid>
        <Grid item xs={10}>
          <Typography variant="h6" style={{ marginBottom: 0 }}>
            Bio Text
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <ReactQuill
            theme="snow"
            value={formValues?.bio}
            onChange={(value) => setFormValues({ ...formValues, bio: value })}
          />
        </Grid>
        <Grid item xs={12}>
          <p style={{ color: 'red' }}>{formErrors}</p>
        </Grid>
      </Grid>
    </Card>
  );
};

export default TeamBioForm;
