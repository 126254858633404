import React from 'react';
import { useParams } from 'react-router-dom';
import { Card, Layout, Loading } from '@shopify/polaris';
import Page from '../../shared/components/Page';
import RecruitmentPartnerForm from '../components/RecruitmentPartnerForm';
import { RecruitmentPartnersContext } from '../contexts/RecruitmentPartnersContext';
import FormSkeleton from '../../loading/components/FormSkeleton';
import BackButton from '../../navigation/components/BackButton';

const EditRecruitmentPartner: React.FunctionComponent = () => {
  const { recruitmentPartnerId } = useParams<Record<string, string>>();
  const [recruitmentPartnerFetched, setRecruitmentPartnerFetched] = React.useState<boolean>(false);
  const { recruitmentPartner, fetchRecruitmentPartner, recruitmentPartnersLoading } =
    React.useContext(RecruitmentPartnersContext);

  React.useEffect(() => {
    if (!recruitmentPartnerFetched) {
      fetchRecruitmentPartner(recruitmentPartnerId);
      setRecruitmentPartnerFetched(true);
    }
  }, [recruitmentPartnerFetched, fetchRecruitmentPartner, recruitmentPartnerId]);

  const loadingMarkup = recruitmentPartnersLoading ? <Loading /> : null;

  // ---- Page markup ----
  const actualPageMarkup = (
    <>
      <BackButton pageAction={false} />
      <Page fullWidth title={`${recruitmentPartner?.name} `}>
        <Layout>
          <Layout.Section>
            <Card sectioned>
              <RecruitmentPartnerForm recruitmentPartner={recruitmentPartner} />
            </Card>
          </Layout.Section>
          <Layout.Section secondary />
        </Layout>
      </Page>
    </>
  );

  // ---- Loading ----
  const loadingPageMarkup = <FormSkeleton />;

  const pageMarkup = recruitmentPartnersLoading ? loadingPageMarkup : actualPageMarkup;

  return (
    <>
      {loadingMarkup}
      {pageMarkup}
    </>
  );
};

export default EditRecruitmentPartner;
