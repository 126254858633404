const interviewStatuses = [
  { label: 'Upcoming', value: 'upcoming' },
  { label: 'Canceled', value: 'canceled' },
  { label: 'No Show', value: 'no show' },
  { label: 'Rescheduling Needed', value: 'rescheduling needed' },
  { label: 'Past Due', value: 'past due' },
  { label: 'Complete', value: 'complete' },
  { label: 'Do Not Reschedule', value: 'do not reschedule' },
  { label: 'Hidden', value: 'hidden' },
  { label: 'Unknown', value: 'unknown' },
];

export default interviewStatuses;
