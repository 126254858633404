import React, { FunctionComponent, useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import BasicFooter from '../../navigation/components/BasicFooter';

import HeroPageHeader from '../../redesignedFrontend/common/HeroPageHeader';
import GetPaidForResearchBanner from '../../redesignedFrontend/opportunitiesListPage/sections/GetPaidForResearchBanner';
import OpportunitiesGallery from '../../redesignedFrontend/opportunitiesListPage/sections/OpportunitiesGallery';

import axios from '../../utils/axios.utils';

const InsighterOpportunitiesList: FunctionComponent = () => {
  let [insighterOpportunities, setInsighterOpportunities] = useState<any[]>([]);

  const fetchInsighterOpportunities = async () => {
    let response = await axios.get<string, any>(`insighter_opportunities/profile`);

    setInsighterOpportunities(response?.data?.result);
  };

  useEffect(() => {
    fetchInsighterOpportunities();
  }, []);

  return (
    <Grid container>
      <Grid item xs={12} style={{ marginBottom: 50 }}>
        <HeroPageHeader
          ornamentText="Get Paid for Research"
          titleText="Your Healthcare Insights"
          subtitleText="Unlock Change"
          heroImageUrl="https://cdn.theinsighters.com/OpportunitiesHero.png"
        />
      </Grid>
      <Grid item xs={12}>
        <GetPaidForResearchBanner />
      </Grid>
      <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
        <OpportunitiesGallery opportunities={insighterOpportunities} />
      </Grid>
      <Grid item xs={12}>
        <BasicFooter />
      </Grid>
    </Grid>
  );
};

export default InsighterOpportunitiesList;
