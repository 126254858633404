import React, { Dispatch, SetStateAction, useState, useEffect } from 'react';
import { Card, Grid } from '@material-ui/core';
import { DropZone } from '@shopify/polaris';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import FormInput from '../../form/components/FormInput';
import SelectInput from '../../form/components/SelectInput';
import { BlogPost } from '../model';
import { SelectOption } from '../../form/model';

import axios from '../../utils/axios.utils';

interface BlogPostFormProps {
  formValues: BlogPost;
  setFormValues: Dispatch<SetStateAction<BlogPost>>;
  formErrors: string;
  setImageFileData: Dispatch<SetStateAction<FormData>>;
}

const BlogPostForm: React.FC<BlogPostFormProps> = (props: BlogPostFormProps) => {
  const { formValues, setFormValues, formErrors, setImageFileData } = props;

  let [authorOptions, setAuthorOptions] = useState<SelectOption[]>([]);

  const fetchAuthorOptions = async () => {
    try {
      const response = await axios.get('/blog_posts/new');
      setAuthorOptions(response.data?.author_options);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchAuthorOptions();
  }, []);

  return (
    <Card style={{ padding: 20 }}>
      <Grid container spacing={3}>
        <Grid item xs={10}>
          <FormInput
            type="text"
            label="Blog Title"
            value={formValues?.title}
            onChange={(value) => setFormValues({ ...formValues, title: value })}
          />
        </Grid>
        <Grid item xs={5}>
          <SelectInput
            label="Author"
            choices={authorOptions}
            value={formValues?.blog_author_id}
            onChange={(value) => setFormValues({ ...formValues, blog_author_id: value })}
          />
        </Grid>
        <Grid item xs={5}>
          <SelectInput
            label="Status"
            choices={[
              { label: 'Active', value: 'active' },
              { label: 'Inactive', value: 'inactive' },
            ]}
            value={formValues?.status}
            onChange={(value) => setFormValues({ ...formValues, status: value })}
          />
        </Grid>
        <Grid item xs={5}>
          <SelectInput
            label="Featured"
            choices={[
              { label: 'Featured', value: 'true' },
              { label: 'Not Featured', value: 'false' },
            ]}
            value={formValues?.featured}
            onChange={(value) => setFormValues({ ...formValues, featured: value })}
          />
        </Grid>
        <Grid item xs={10}>
          <FormInput
            type="text"
            label="Thumbnail Description"
            value={formValues?.thumbnail_description}
            onChange={(value) => setFormValues({ ...formValues, thumbnail_description: value })}
          />
        </Grid>
        <Grid item xs={10}>
          <ReactQuill
            theme="snow"
            value={formValues?.content}
            onChange={(value) => setFormValues({ ...formValues, content: value })}
          />
        </Grid>
        <Grid item xs={12}>
          <p style={{ color: 'red' }}>{formErrors}</p>
        </Grid>
      </Grid>
    </Card>
  );
};

export default BlogPostForm;
