import React, { Children, cloneElement } from 'react';
import { Grid, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  wrapper: {
    position: 'relative',
    overflow: 'hidden',
  },
  container: {
    display: 'inline-flex',
    flex: '1 1 auto',
  },
});

interface ProjectTimelineProps {
  children?: any;
  height?: any;
  minEvents?: any;
  placeholder?: any;
}

const ProjectTimeline: React.FC<ProjectTimelineProps> = (props) => {
  const classes = useStyles();

  const { children, height = 185, placeholder } = props;
  const childrenWithProps = children
    ? Children.map(children, (child, index) =>
        cloneElement(child, {
          placeholder,
          first: index === 0,
        }),
      )
    : [];

  return (
    <Grid className={classes.wrapper} style={{ height, margin: '-1rem 2rem', textAlign: 'center' }}>
      <Grid className={classes.container} style={{ maxWidth: '100%' }}>
        <>
          {childrenWithProps.map((child: number, index: number) => {
            return (
              <Grid item xs={3} key={index}>
                {child}
              </Grid>
            );
          })}
        </>
      </Grid>
    </Grid>
  );
};

export default ProjectTimeline;
