import React from 'react';
import { Page, Button } from '@shopify/polaris';
import { Grid } from '@material-ui/core';
import SelectInput from '../../form/components/SelectInput';
import DashboardNavigation from '../components/DashboardNavigation';
import { DashboardsContext } from '../contexts/DashboardsContext';
import DemographicsStackedBarChart from '../components/DemographicsStackedBarChart';
import DemographicsBarChart from '../components/DemographicsBarChart';

interface DemographicDrillDownDashboardProps {
  isLoading: boolean;
}

const DemographicDrillDownDashboard: React.FunctionComponent<DemographicDrillDownDashboardProps> = () => {
  const {
    conditionChoices,
    dashboardLoading,
    setDashboardLoading,
    datasets,
    fetchConditionChoices,
    fetchDrillDownData,
    fetchSubsetChoices,
    labels,
    setDatasets,
    setLabels,
    subsetChoices,
  } = React.useContext(DashboardsContext);
  const [firstDemographic, setFirstDemographic] = React.useState('');
  const [secondDemographic, setSecondDemographic] = React.useState('');
  const [firstDemographicSubset, setFirstDemographicSubset] = React.useState('');
  const [condition, setCondition] = React.useState('');

  const blankData = {
    labels: [],
    datasets: [
      {
        label: 'Demographic',
        data: [],
        backgroundColor: '',
      },
    ],
  };

  const demographicOneChoices = [
    { label: 'Condition Category', value: 'Condition Category' },
    { label: 'Gender', value: 'Gender' },
    { label: 'Race/Ethnicity', value: 'Race/Ethnicity' },
    { label: 'Sexual Orientation', value: 'Sexual Orientation' },
    { label: 'Transgender Identity', value: 'Transgender Identity' },
    { label: 'Veteran Status', value: 'Veteran Status' },
  ];

  const demographicTwoChoices = demographicOneChoices.filter((obj) => {
    if (obj.label !== firstDemographic && obj.label !== 'Condition Category') {
      return true;
    }
    return false;
  });

  let chart;

  if (firstDemographic && secondDemographic && firstDemographicSubset && condition && labels && datasets) {
    chart = <DemographicsBarChart data={{ labels, datasets }} />;
  } else if (firstDemographic && secondDemographic && labels && datasets) {
    chart = <DemographicsStackedBarChart data={{ labels, datasets }} />;
  } else if (firstDemographic === 'Condition Category' && firstDemographicSubset && labels && datasets) {
    chart = <DemographicsBarChart data={{ labels, datasets }} />;
  } else if (firstDemographic === 'Condition Category' && firstDemographicSubset === '' && labels && datasets) {
    chart = <DemographicsBarChart data={{ labels, datasets }} />;
  } else {
    chart = <DemographicsBarChart data={blankData} />;
  }

  return (
    <Page fullWidth>
      <DashboardNavigation currentTab={5} />
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={3}>
          <SelectInput
            label="Demographic One"
            choices={demographicOneChoices}
            value={firstDemographic}
            onChange={(value) => {
              setLabels(undefined);
              setDatasets(undefined);
              setFirstDemographicSubset('');
              setCondition('');
              fetchSubsetChoices(value);
              setFirstDemographic(value);
            }}
          />
        </Grid>
        {firstDemographic && (
          <Grid item xs={12} sm={6} md={3}>
            <SelectInput
              label="Demographic One Subset"
              choices={subsetChoices}
              value={firstDemographicSubset}
              onChange={(value) => {
                setLabels(undefined);
                setDatasets(undefined);
                setCondition('');
                fetchConditionChoices(value);
                setFirstDemographicSubset(value);
              }}
            />
          </Grid>
        )}
        {firstDemographic === 'Condition Category' && (
          <Grid item xs={12} sm={6} md={3}>
            <SelectInput
              label="Condition"
              choices={conditionChoices}
              value={condition}
              onChange={(value) => {
                setLabels(undefined);
                setDatasets(undefined);
                setCondition(value);
              }}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={6} md={3}>
          <SelectInput
            label="Demographic Two"
            choices={demographicTwoChoices}
            value={secondDemographic}
            onChange={(value) => {
              setLabels(undefined);
              setDatasets(undefined);
              setSecondDemographic(value);
            }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <div style={{ display: 'flex', alignItems: 'flex-end', height: '100%', paddingBottom: '5px' }}>
            <span style={{ marginRight: '5px' }}>
              <Button
                primary
                loading={dashboardLoading}
                onClick={() =>
                  fetchDrillDownData(firstDemographic, firstDemographicSubset, condition, secondDemographic)
                }
              >
                Generate Chart
              </Button>
            </span>
            <Button
              onClick={() => {
                setFirstDemographic('');
                setSecondDemographic('');
                setFirstDemographicSubset('');
                setCondition('');
                setDashboardLoading(false);
              }}
            >
              Clear Choices
            </Button>
          </div>
        </Grid>
      </Grid>

      {chart}
    </Page>
  );
};

export default DemographicDrillDownDashboard;
