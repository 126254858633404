/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Loading, Toast } from '@shopify/polaris';
import { format } from 'date-fns';
import DataTable from '../../dataTable/DataTable/DataTable';
import { InterviewsContext } from '../contexts/InterviewsContext';
import { UserContext } from '../../auth/contexts/UserContext';
import ExpandedInterviewList from './ExpandedInterviewList';
import interviewColumns from '../constants/interviewColumns';
import financialInterviewColumns from '../constants/financialInterviewColumns';

const InterviewsList: React.FunctionComponent = () => {
  const history = useHistory();

  const {
    fetchInterviews,
    interviews,
    interviewLoading,
    filterValues,
    setFilterValues,
    statusFilters,
    selectedDates,
    totalRecords,
  } = React.useContext(InterviewsContext);

  const { currentUserHasRole } = React.useContext(UserContext);

  const [interviewsFetched, setInterviewsFetched] = React.useState(false);

  const [dateFilters] = React.useState({
    start: new Date(new Date().getTime() - 14 * 24 * 60 * 60 * 1000),
    end: new Date(new Date().getTime() + 365 * 24 * 60 * 60 * 1000),
  });

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  React.useEffect(() => {
    if (!interviewsFetched) {
      let interviewFilters = sessionStorage.getItem('interviewFilters');

      if (!interviewFilters) {
        interviewFilters = 'ransack[status_in][]=upcoming';

        // If user came from a link that includes 'previous_days=true' parameter
        // Start with a date filter.
        if (queryParams.get('previous_days')) {
          const yesterday = new Date();
          yesterday.setDate(yesterday.getDate() - 1);
          interviewFilters += `&ransack[scheduled_time_gteq]=${format(new Date(2023, 0, 1), 'dd-MM-yyyy')}`;
          interviewFilters += `&ransack[scheduled_time_lteq]=${format(yesterday, 'dd-MM-yyyy')}`;
        }
      }
      fetchInterviews(interviewFilters);
      setInterviewsFetched(true);
    }
  }, [interviewsFetched, setInterviewsFetched, fetchInterviews, dateFilters]);

  const [toastActive, setToastActive] = useState(false);
  const loadingMarkup = interviewLoading ? <Loading /> : null;

  const toggleToastActive = useCallback(() => setToastActive((toastActive) => !toastActive), []);

  const toastMarkup = toastActive ? <Toast onDismiss={toggleToastActive} content="Changes saved" /> : null;

  const formattedStatusFilter = statusFilters
    ?.map((filter, index) => {
      const prefix = index === 0 ? '' : '&';
      return `${prefix}ransack[status_in][]=${filter}`;
    })
    .join('');

  const handleColumnSort = async (column: Record<string, string>, sortDirection: string) => {
    const columnNamesToRansack = {
      project_number: 'project_project_number',
      phase_name: 'phase_name',
      user_name: 'user_last_name',
      scheduled_time: 'scheduled_time',
      recruiter: 'recruiter_last_name',
      project_manager: 'project_manager_last_name',
      status: 'status',
    };

    const sortKey = columnNamesToRansack[column.selector];
    setFilterValues({ ...filterValues, sort: `${sortKey} ${sortDirection}` });

    const filterParams = {
      queryFilter: `ransack[query]=${filterValues.query}`,
      formattedStatusFilter,
      startDateFilter: `ransack[scheduled_time_gteq]=${format(selectedDates?.start, 'dd-MM-yyyy')}`,
      endDateFilter: `ransack[scheduled_time_lteq]=${format(selectedDates?.end, 'dd-MM-yyyy')}`,
      sort: `sort=${sortKey} ${sortDirection}`,
    };

    fetchInterviews(Object.values(filterParams).join('&'));
  };

  const handlePageChange = (page: number) => {
    const pageParams = {
      page: `page=${page}`,
      queryFilter: `ransack[query]=${filterValues.query}`,
      formattedStatusFilter,
      startDateFilter: `ransack[scheduled_time_gteq]=${format(selectedDates?.start, 'dd-MM-yyyy')}`,
      endDateFilter: `ransack[scheduled_time_lteq]=${format(selectedDates?.end, 'dd-MM-yyyy')}`,
      sort: `sort=${filterValues.sort}`,
    };

    fetchInterviews(Object.values(pageParams).join('&'));
  };

  const columns = currentUserHasRole('financial') ? financialInterviewColumns : interviewColumns;

  let handleRowClicked = (row) => {
    if (currentUserHasRole('admin')) {
      history.push(`/admin/interviews/${row.id}`);
    }
  };

  return (
    <>
      {loadingMarkup}
      <DataTable
        onRowClicked={handleRowClicked}
        columns={columns}
        data={interviews}
        progressPending={interviewLoading}
        striped
        highlightOnHover
        pointerOnHover={currentUserHasRole('admin')}
        pagination
        paginationPerPage={25}
        paginationRowsPerPageOptions={[25]}
        paginationServer
        paginationTotalRows={totalRecords}
        onChangePage={handlePageChange}
        noHeader
        sortServer
        onSort={handleColumnSort}
        expandableRows
        expandableRowsComponent={<ExpandedInterviewList data />}
      />
      {toastMarkup}
    </>
  );
};

export default InterviewsList;
