import React, { useState, useEffect, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Grid, Card, Typography } from '@mui/material';
import { InsighterTagsContext } from '../contexts/InsighterTagsContext';
import BackButton from '../../navigation/components/BackButton';
import ColumnSelector from '../../shared/components/ColumnSelector';
import DataTable from '../../dataTable/DataTable/DataTable';
import { Insighter } from '../../insighters/model';
import insighterPossibleColumns from '../../shared/constants/insighterPossibleColumns';
import PrepareDownloadButton from '../../shared/components/PrepareDownloadButton';

const InsighterTagDetail: React.FunctionComponent = () => {
  const history = useHistory();
  const { insighterTagId } = useParams<Record<string, string>>();
  const [insighterTagFetched, setInsighterTagFetched] = React.useState(false);
  const { insighterTag, fetchInsighterTag, insighterTagsLoading } = React.useContext(InsighterTagsContext);
  const [insighters, setInsighters] = useState<Insighter[]>([]);

  const [visibleColumns, setVisibleColumns] = useState([]);

  useEffect(() => {
    if (!insighterTagFetched) {
      fetchInsighterTag(insighterTagId);
      setInsighterTagFetched(true);
    }
  }, [insighterTagFetched, fetchInsighterTag, insighterTagId]);

  useEffect(() => {
    if (insighterTag?.insighters) {
      setInsighters(insighterTag.insighters);
    }
  }, [insighterTag]);

  return (
    <>
      <Grid style={{ display: 'flex', justifyContent: 'space-between' }}>
        <BackButton pageAction={false} />
        <PrepareDownloadButton
          filename={`Insighters with Insighter Tag ${insighterTag?.name}.csv`}
          visibleColumns={visibleColumns}
          link={`/insighter_tags/${insighterTagId}/download`}
          includeResult
        />
      </Grid>
      <Grid style={{ margin: 10 }}>
        <Typography className="title" variant="h3">
          All Insighters with {insighterTag.name}
        </Typography>
        <br />
        <ColumnSelector setVisibleColumns={setVisibleColumns} columnChoices={insighterPossibleColumns} />
        <br />
        <Card>
          <DataTable
            onRowClicked={(row: Record<string, string>) => history.push(`/admin/insighters/${row.id}`)}
            columns={visibleColumns}
            data={insighters}
            pagination
            progressPending={insighterTagsLoading}
            striped
            highlightOnHover
            pointerOnHover
            noHeader
            // paginationServer
            paginationPerPage={25}
            paginationRowsPerPageOptions={[25]}
            paginationTotalRows={insighters?.length}
            // onChangePage={handlePageChange}
          />
        </Card>
      </Grid>
    </>
  );
};

export default InsighterTagDetail;
