import React from 'react';
import { Button, FormLayout } from '@shopify/polaris';
import { Grid } from '@material-ui/core';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import axios from '../../utils/axios.utils';
import FormInput from '../../form/components/FormInput';
import SelectInput from '../../form/components/SelectInput';
import InsighterAutocompleteInput from '../../form/components/InsighterAutocompleteInput';
import { InterviewsContext } from '../contexts/InterviewsContext';
import { Interview, InterviewFormValues } from '../model';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import interviewStatuses from '../constants/interviewStatuses';
import { payoutTypeChoices } from '../../payments/constants/paymentChoices';

interface InterviewFormProps {
  interview?: Interview;
  interviewLoading?: boolean;
  afterCreate?: () => void;
}

const InterviewForm: React.FC<InterviewFormProps> = (props) => {
  const { interview, interviewLoading, afterCreate } = props;
  const { updateInterview, deleteInterview, createInterview, interviewSubmitting, formOptions, formError } =
    React.useContext(InterviewsContext);

  const [phaseOptions, setPhaseOptions] = React.useState<any[]>([]);
  const [formValues, setFormValues] = React.useState<InterviewFormValues>({});
  const [editorState, setEditorState] = React.useState();

  React.useEffect(() => {
    if (interview.segment) {
      const blocksFromHtml = htmlToDraft(interview.segment);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
      const initialState = EditorState.createWithContent(contentState);
      setEditorState(initialState);
    }
  }, [interview]);

  React.useEffect(() => {
    setFormValues({
      project_id: interview?.project_id,
      phase_id: interview?.phase_id,
      scheduled_time: interview?.scheduled_time_form,
      scheduled_date: interview?.scheduled_date_form,
      recruiter_id: interview?.recruiter_id,
      // project_manager_id: interview?.project_manager_id,
      user_id: interview?.user_id,
      user_email: interview?.user_email,
      user_first_name: interview?.user_first_name,
      user_last_name: interview?.user_last_name,
      status: interview?.status?.toLowerCase() || 'upcoming',
      account_status: interview?.account_status,
      payment_amount: interview?.payment_amount,
      segment: interview?.segment || '\n',
      moderator_id: interview?.moderator_id,
      note: interview?.note,
      payment_note: interview?.payment_note,
      recruitment_partner_id: interview?.recruitment_partner_id,
      payout_category: interview?.payout_category,
    });

    if (interview?.project_id) {
      axios.get<string, any>(`interviews/new?project_id=${interview?.project_id}`).then((response) => {
        setPhaseOptions(response?.data?.phase_options);
      });
    }
  }, [interview]);

  const handleUpdate = () => {
    updateInterview(interview.id, formValues);
  };

  const handleCreate = () => {
    if (afterCreate) {
      createInterview(formValues, afterCreate);
    } else {
      createInterview(formValues);
    }
  };

  const handleDelete = () => {
    deleteInterview(interview.id);
  };

  const handleProjectChange = async (value: string) => {
    await axios.get<string, any>(`interviews/new?project_id=${value}`).then((response) => {
      setPhaseOptions(response?.data?.phase_options);
      setFormValues({ ...formValues, project_id: value });
    });
  };

  const handlePhaseChange = async (value: string) => {
    if (!formValues?.payment_amount && value) {
      await axios.get<string, any>(`interviews/new?phase_id=${value}`).then((response) => {
        setFormValues({ ...formValues, payment_amount: response?.data?.default_payment_amount, phase_id: value });
      });
    } else {
      setFormValues({ ...formValues, phase_id: value });
    }
  };

  return (
    !interviewLoading && (
      <FormLayout>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <h2 className="Polaris-Heading">Interview Information</h2>
          </Grid>
          <Grid item xs={6}>
            <SelectInput
              label="Project"
              choices={formOptions.project_options}
              value={formValues.project_id}
              onChange={handleProjectChange}
            />
          </Grid>
          <Grid item xs={6}>
            <SelectInput
              label="Phase"
              choices={phaseOptions}
              value={formValues.phase_id}
              onChange={handlePhaseChange}
            />
          </Grid>
          <Grid item xs={6}>
            <SelectInput
              label="Account Status"
              choices={[
                { label: 'Insighter', value: 'insighter' },
                { label: 'Outside Respondent', value: 'outside_respondent' },
                { label: 'Outside Recruiter', value: 'outside_recruiter' },
              ]}
              value={formValues.account_status}
              onChange={(value) => setFormValues({ ...formValues, account_status: value })}
            />
          </Grid>
          {formValues.account_status === 'outside_recruiter' && (
            <>
              <Grid item xs={6}>
                <SelectInput
                  label="Outside Recruiter"
                  choices={formOptions.recruitment_partner_options}
                  value={formValues.recruitment_partner_id}
                  onChange={(value) => setFormValues({ ...formValues, recruitment_partner_id: value })}
                />
              </Grid>
              <Grid item xs={6}>
                <FormInput
                  label="Recruited Interviewee First Name"
                  onChange={(value) => setFormValues({ ...formValues, user_first_name: value })}
                  value={formValues.user_first_name}
                />
              </Grid>
            </>
          )}
          {formValues.account_status === 'outside_respondent' && (
            <>
              <Grid item xs={6}>
                <FormInput
                  label="Outside Respondent Email"
                  onChange={(value) => setFormValues({ ...formValues, user_email: value })}
                  value={formValues.user_email}
                />
              </Grid>
              <Grid item xs={6}>
                <FormInput
                  label="Outside Respondent First Name"
                  onChange={(value) => setFormValues({ ...formValues, user_first_name: value })}
                  value={formValues.user_first_name}
                />
              </Grid>
              <Grid item xs={6}>
                <FormInput
                  label="Outside Respondent Last Name"
                  onChange={(value) => setFormValues({ ...formValues, user_last_name: value })}
                  value={formValues.user_last_name}
                />
              </Grid>
            </>
          )}
          {formValues.account_status === 'insighter' && (
            <Grid item xs={6}>
              <InsighterAutocompleteInput
                label="Insighter Email"
                onChange={(value) => setFormValues({ ...formValues, user_email: value })}
                value={formValues.user_email}
              />
            </Grid>
          )}
          <Grid item xs={6}>
            <FormInput
              label="Scheduled Time"
              type="date"
              value={formValues.scheduled_date}
              onChange={(value) => setFormValues({ ...formValues, scheduled_date: value })}
            />
          </Grid>
          <Grid item xs={6}>
            <FormInput
              label="Scheduled Time"
              type="time"
              value={formValues.scheduled_time}
              onChange={(value) => setFormValues({ ...formValues, scheduled_time: value })}
            />
          </Grid>
          <Grid item xs={6}>
            <SelectInput
              label="Recruiter"
              choices={formOptions.project_manager_options}
              onChange={(value) => setFormValues({ ...formValues, recruiter_id: value })}
              value={formValues.recruiter_id}
              defaultChoice={{ value: 'Select a Recruiter', label: 'None' }}
            />
          </Grid>
          <Grid item xs={6}>
            <SelectInput
              label="Moderator"
              choices={formOptions.project_manager_options}
              onChange={(value) => setFormValues({ ...formValues, moderator_id: value })}
              value={formValues.moderator_id}
              defaultChoice={{ value: 'Select a Recruiter', label: 'None' }}
            />
          </Grid>
          <Grid item xs={6}>
            <SelectInput
              label="Status"
              choices={interviewStatuses}
              onChange={(value) => (value !== 'complete' ? setFormValues({ ...formValues, status: value }) : null)}
              value={formValues.status}
            />
          </Grid>
          <Grid item xs={6}>
            <FormInput
              label="Interview Payment"
              type="money"
              value={formValues.payment_amount}
              onChange={(value) => {
                const formattedValue = value.charAt(0) === '$' ? value.slice(1) : value;
                setFormValues({ ...formValues, payment_amount: formattedValue });
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <SelectInput
              label="Payout"
              choices={payoutTypeChoices}
              onChange={(value) => setFormValues({ ...formValues, payout_category: value })}
              value={formValues.payout_category}
            />
          </Grid>
          <Grid item xs={12}>
            <br />
            <div className="Polaris-Labelled__LabelWrapper">
              <div className="Polaris-Label">
                <p className="Polaris-Label__Text">Segment</p>
              </div>
            </div>
            <Editor
              editorState={editorState}
              wrapperClassName="demo-wrapper"
              editorClassName="demo-editor"
              onEditorStateChange={(value) => {
                setEditorState(value);
                setFormValues({
                  ...formValues,
                  segment: draftToHtml(convertToRaw(value.getCurrentContent())).replaceAll('<p></p>', '<br />'),
                });
              }}
              toolbar={{
                inline: { inDropdown: false },
                list: {
                  inDropdown: false,
                  className: undefined,
                  component: undefined,
                  dropdownClassName: undefined,
                  options: ['unordered', 'ordered'],
                },
                textAlign: { inDropdown: true },
                history: { inDropdown: false },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormInput
              type="text"
              label="Interview Notes"
              value={formValues.note}
              onChange={(value) => setFormValues({ ...formValues, note: value })}
              multiline
              rows={4}
            />
          </Grid>
          <Grid item xs={12}>
            <FormInput
              type="text"
              label="Payment Notes"
              value={formValues.payment_note}
              onChange={(value) => setFormValues({ ...formValues, payment_note: value })}
              multiline
              rows={4}
            />
          </Grid>
        </Grid>
        <>
          {formError && <p style={{ color: 'red', marginBottom: '8px' }}>{formError}</p>}
          {interview.id ? (
            <>
              <Button primary onClick={handleUpdate} loading={interviewSubmitting}>
                Update Interview
              </Button>
              <span style={{ marginLeft: '8px' }}>
                <Button destructive onClick={handleDelete} loading={interviewSubmitting}>
                  Delete Interview
                </Button>
              </span>
            </>
          ) : (
            <Button primary onClick={handleCreate} loading={interviewSubmitting}>
              Create Interview
            </Button>
          )}
        </>
      </FormLayout>
    )
  );
};

export default InterviewForm;
