import React from 'react';
import { useHistory } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import axios from '../../utils/axios.utils';
import { Topic } from '../model';
import ConfirmCancelModal from '../../modal/components/MaterialModal';

interface DeleteTopicConfirmModalProps {
  topicToDelete?: Topic;
  deleteTopicConfirmModalOpen?: boolean;
  setDeleteTopicConfirmModalOpen?: any;
}

const DeleteTopicConfirmModal: React.FC<DeleteTopicConfirmModalProps> = (props) => {
  const history = useHistory();

  const { topicToDelete, deleteTopicConfirmModalOpen, setDeleteTopicConfirmModalOpen } = props;

  const handleDeleteTopic = async () => {
    try {
      await axios.delete(`topics/${topicToDelete?.id}`);
      history.push('/admin/topics');
    } catch (error: any) {
      console.log(error);
    }
  };

  return (
    <ConfirmCancelModal
      isOpen={deleteTopicConfirmModalOpen}
      title="Delete Topic"
      onCancel={() => setDeleteTopicConfirmModalOpen(false)}
      onWarning={() => handleDeleteTopic()}
      warningLabel="Delete"
      cancelLabel="Cancel"
      hasWarningButton
      hasCancelButton
      hasFooterBar={false}
      height="250px"
    >
      <div style={{ marginTop: '20px', marginBottom: '20px' }}>
        <Typography variant="h2">{topicToDelete?.name}</Typography>
        <p>Are you sure you want to delete this topic?</p>
      </div>
    </ConfirmCancelModal>
  );
};

export default DeleteTopicConfirmModal;
