import React, { useState } from 'react';
import { Button, Card } from '@shopify/polaris';
import { Grid } from '@material-ui/core';
import { format } from 'date-fns';
import { DashboardsContext } from '../contexts/DashboardsContext';
import FormInput from '../../form/components/FormInput';

const PaymentFilterBar: React.FunctionComponent = () => {
  const { fetchDailyAnalytics, dashboardLoading, sixMonthDate, selectedDates, setSelectedDates, dailyAnalyticsData } =
    React.useContext(DashboardsContext);
  const [buttonSelected, setButtonSelected] = useState('sixMonths');

  const handleApply = () => {
    setButtonSelected('none');
    fetchDailyAnalytics(selectedDates);
  };

  const sixMonths = () => {
    const newDates = {
      start: sixMonthDate(),
      end: new Date(),
    };

    setButtonSelected('sixMonths');
    setSelectedDates(newDates);
    fetchDailyAnalytics(newDates);
  };

  const yearToDate = () => {
    const newDates = {
      start: new Date(new Date().getFullYear(), 0, 1),
      end: new Date(),
    };

    setButtonSelected('ytd');
    setSelectedDates(newDates);
    fetchDailyAnalytics(newDates);
  };

  const allTime = () => {
    const newDates = {
      start: new Date(2021, 1, 28),
      end: new Date(),
    };

    setButtonSelected('allTime');
    setSelectedDates(newDates);
    fetchDailyAnalytics(newDates);
  };

  const tryCatchDate = (value) => {
    try {
      return `${format(value, 'yyyy-MM-dd')}`;
    } catch {
      return `${value}`;
    }
  };

  return (
    <Grid xs={5} style={{ margin: 'auto' }}>
      <Card>
        <br />
        <Grid style={{ display: 'flex', margin: 10 }}>
          <Grid xs={6}>
            <FormInput
              type="date"
              label="Start Date"
              placeholder="01/05/2023"
              value={tryCatchDate(selectedDates?.start)}
              onChange={(value: string) => setSelectedDates({ ...selectedDates, start: value })}
            />
            <FormInput
              type="date"
              label="End Date"
              value={tryCatchDate(selectedDates?.end)}
              onChange={(value: string) => setSelectedDates({ ...selectedDates, end: value })}
            />
          </Grid>
          <Grid xs={6} style={{ margin: 'auto', padding: 20 }}>
            <Button primary onClick={handleApply}>
              Set Dates
            </Button>
          </Grid>
        </Grid>

        <br />
        <Grid container>
          <Grid style={{ margin: 'auto', display: 'flex', justifyContent: 'space-around' }}>
            <Grid>
              <Button onClick={sixMonths} disabled={buttonSelected === 'sixMonths'}>
                6 months
              </Button>
            </Grid>
            &nbsp;
            <Grid>
              <Button onClick={yearToDate} disabled={buttonSelected === 'ytd'}>
                Year-to-Date
              </Button>
            </Grid>
            &nbsp;
            <Grid>
              <Button onClick={allTime} disabled={buttonSelected === 'allTime'}>
                All Time
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <br />
      </Card>
    </Grid>
  );
};

export default PaymentFilterBar;
