import React from 'react';
import Typography from '@material-ui/core/Typography';

const ReachOutToEmail: React.FC = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <Typography
        style={{
          maxWidth: 400,
          backgroundColor: '#4D8A79',
          color: 'white',
          borderRadius: 5,
          textAlign: 'center',
          fontFamily: 'Rethink Sans',
          fontSize: 18,
          padding: 10,
        }}
      >
        Reach out to hello@theinsighters.com
      </Typography>
    </div>
  );
};

export default ReachOutToEmail;
