import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import EventViewer from './EventViewer';
import { EventResource } from '../model';

const useCustomStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down(960)]: {
      display: 'flex',
      justifyContent: 'center',
    },
  },
  eventItem: {
    marginBottom: 50,
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down(960)]: {
      marginBottom: 80,
    },
  },
}));

interface EventViewerRowProps {
  eventResources: EventResource[];
}

const EventViewerRow: React.FC<EventViewerRowProps> = (props) => {
  const { eventResources } = props;

  const customClasses = useCustomStyles();

  return (
    <Grid container className={customClasses.container}>
      {eventResources.map((event, index) => (
        <Grid item xs={10} md={4} key={`event-${index}`} className={customClasses.eventItem}>
          <EventViewer eventResource={event} />
        </Grid>
      ))}
    </Grid>
  );
};

export default EventViewerRow;
