import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@material-ui/core';
import DataTable from '../../dataTable/DataTable/DataTable';
import { IDataTableColumn } from '../../dataTable/DataTable/model';
import getStatusColor from '../../interviews/constants/getStatusColor';

const columns: IDataTableColumn<any>[] = [
  {
    name: 'Description',
    selector: 'description',
    sortable: true,
  },
  {
    name: 'Payment Category',
    selector: 'payout_category',
    sortable: true,
  },
  {
    name: 'Project Number',
    selector: 'project_number',
    sortable: true,
  },
  {
    name: 'Created At',
    selector: 'created_at',
    sortable: true,
    grow: 1.2,
  },
  {
    name: 'Amount',
    selector: 'number_amount',
    sortable: true,
    cell: ({ number_amount }) => <div>${number_amount}</div>,
  },
  {
    name: 'Notes',
    selector: 'notes',
    sortable: true,
    grow: 2,
  },
  {
    name: 'Status',
    selector: 'status',
    sortable: true,
    cell: ({ status }) => (
      <div
        style={{
          fontWeight: 700,
          textTransform: 'uppercase',
          fontSize: 12,
          color: getStatusColor(status),
        }}
      >
        {status}
      </div>
    ),
  },
];

interface PaymentHistoryProps {
  payments: any;
}

const PaymentHistory: React.FC<PaymentHistoryProps> = (props) => {
  const { payments } = props;

  return (
    <>
      <Accordion>
        <AccordionSummary>
          <Typography variant="h5" style={{ fontSize: 14, fontWeight: 'bold' }}>
            Payment History ({payments?.length})
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <DataTable
            onRowClicked={() => null}
            columns={columns}
            data={payments}
            striped
            highlightOnHover
            pointerOnHover
            noHeader
          />
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default PaymentHistory;
