import React from 'react';
import { Card } from '@shopify/polaris';
import Page from '../../shared/components/Page';
import ClientProjectFilterBar from '../components/ClientProjectFilterBar';
import ClientProjectsListFinancial from '../components/ClientProjectsListFinancial';

const ClientProjectsListContainer: React.FunctionComponent = () => {
  return (
    <Page fullWidth title="Project Budgets">
      <Card>
        <br />
        <ClientProjectFilterBar financial />
        <ClientProjectsListFinancial />
      </Card>
    </Page>
  );
};

export default ClientProjectsListContainer;
