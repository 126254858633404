import React from 'react';
import { Card } from '@shopify/polaris';
import Page from '../../shared/components/Page';
import InterviewScheduleList from '../components/InterviewScheduleList';

const InterviewSchedule: React.FunctionComponent = () => {
  return (
    <Page fullWidth title="Interview Schedule">
      <Card>
        <InterviewScheduleList />
      </Card>
    </Page>
  );
};

export default InterviewSchedule;
