import React, { useState } from 'react';
import { Button, Grid, FormControl, FilledInput, InputAdornment, IconButton } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { UserContext } from '../contexts/UserContext';
import PasswordBanner from '../components/PasswordBanner';
import siteWideStyles from '../../styles/siteWideStyles';
import Page from '../../shared/components/Page';

const NewPassword: React.FC = () => {
  const [password, setPassword] = useState('');
  const { updateProfilePassword, formErrors } = React.useContext(UserContext);
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Page fullWidth title="">
      <div style={{ marginTop: 100, marginLeft: 20, marginRight: 20 }}>
        <Grid container>
          <Grid item xs={12} md={3} />
          <Grid item xs={12} md={6}>
            <h1 className="Polaris-Header-Title" style={{ fontFamily: 'Cooper', fontSize: '36px', marginBottom: 10 }}>
              Set Password
            </h1>
            <br />
            <FormControl variant="filled" fullWidth>
              <div className="Polaris-Labelled__LabelWrapper">
                <div className="Polaris-Label"> Password</div>
              </div>
              <FilledInput
                id="formInput"
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                style={{ height: '40px' }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton edge="end" onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                inputProps={{
                  style: {
                    fontSize: '1.4rem',
                    paddingLeft: '10px',
                    paddingTop: '10px',
                  },
                }}
              />
            </FormControl>
            <Grid style={{ marginTop: 10 }}>
              <PasswordBanner password={password} />
            </Grid>
            <br />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} md={3} />
          <Grid item>
            <p style={{ color: 'red' }}>{formErrors?.password}</p>
            <Button variant="contained" className="auth-btn" onClick={() => updateProfilePassword(password)}>
              Continue
            </Button>
          </Grid>
        </Grid>
      </div>
    </Page>
  );
};

export default NewPassword;
