import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useLocation } from 'react-router-dom';
import HoverButton from '../../common/HoverButton';
import TitleBackgroundImage from '../../../../assets/redesignAssets/TitleBackgroundImage.png';
import TitleSubBackgroundImage from '../../../../assets/redesignAssets/TitleSubBackground.png';
import OpportunityDetailHero from '../assets/OpportunityDetailHero.png';
import SectionOrnament from '../../common/SectionOrnament';
import { Opportunity } from '../../../opportunities/model';
import useStyles from '../../constants/redesignStyles';

const styleOverrides = makeStyles((theme) => ({
  ...useStyles,
  heroTitleContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    backgroundImage: `url(${TitleBackgroundImage})`,
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    width: '100%',
  },
  heroTextWrapper: {
    marginLeft: '20%',
  },
  h1Override: {
    color: '#3B4255',
    fontFamily: 'Crimson Text',
    fontSize: '64px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '100%',
    letterSpacing: '-4.25px',
    width: '100%',
    margin: 'auto 15% auto auto',
    paddingRight: 3,

    [theme.breakpoints.down(960)]: {
      fontSize: '36px',
      letterSpacing: '-2.25px',
    },
  },
  h1AccentLineContainerOverride: {
    backgroundImage: `url(${TitleSubBackgroundImage})`,
    margin: 'auto 15% auto -5%',
    height: '7em',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
  },
}));

interface OpportunityDetailheroProps {
  insighterOpportunity: Opportunity;
}

const OpportunityDetailhero: React.FC<OpportunityDetailheroProps> = (props) => {
  const classes = styleOverrides();
  const { insighterOpportunity } = props;

  const { search, hash } = useLocation();
  const invitationCode = new URLSearchParams(search).get('invitation_code');

  return insighterOpportunity ? (
    <Grid container>
      <Grid item xs={12} md={7}>
        <div
          style={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '50vh',
          }}
        >
          {window.innerWidth >= 960 && (
            <img
              src={TitleBackgroundImage}
              style={{
                position: 'absolute',
                width: '100%',
                height: 'auto',
                top: 0,
                left: 0,
                zIndex: 0,
                objectFit: 'cover',
              }}
              alt="title background"
            />
          )}
          <div style={{ paddingLeft: '10%', paddingTop: '5%' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                paddingRight: '50%',
              }}
            >
              <SectionOrnament text="your insights are needed" centerText={false} />
            </div>
            <h1 className={classes.h1Override} style={{ position: 'relative', maxWidth: '90%' }}>
              {insighterOpportunity?.name}
            </h1>
            <br />
            <HoverButton text="Apply Now" link={invitationCode ? '#sign-up-form' : insighterOpportunity.screener_url} />
          </div>
        </div>
      </Grid>
      <Grid item xs={12} md={5}>
        <img
          src={insighterOpportunity.hero_image_url || OpportunityDetailHero}
          alt="Hero"
          style={{ width: '100%', height: 'auto' }}
        />
      </Grid>
    </Grid>
  ) : (
    <></>
  );
};

export default OpportunityDetailhero;
