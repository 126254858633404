// Is this file still being used?

import React, { useCallback, useState } from 'react';
import { Card, Loading, Page, Toast } from '@shopify/polaris';
// import { useHistory } from 'react-router-dom';
// import DataTableSkeleton from '../../loading/components/DataTableSkeleton';
import DataTable from '../../dataTable/DataTable/DataTable';
import InvitationFilterBar from '../components/InvitationFilterBar';
// import { ProjectsContext } from '../contexts/ProjectsContext';
import invitationColumns from '../constants/invitationColumns';

const InvitationsList: React.FunctionComponent = () => {
  // const [projectsFetched, setProjectsFetched] = React.useState(false);
  const [filterValues, setFilterValues] = React.useState<Record<string, any>>({
    query: '',
  });

  // const { projects, fetchProjects, projectLoading } = React.useContext(ProjectsContext);

  // React.useEffect(() => {
  //   if (!projectsFetched) {
  //     fetchProjects('ransack[client_name_not_eq]=Sommer Consulting');
  //     setProjectsFetched(true);
  //   }
  // }, [projectsFetched, fetchProjects]);

  // const history = useHistory();
  const [toastActive, setToastActive] = useState(false);
  // const loadingMarkup = projectLoading ? <Loading /> : null;

  const toggleToastActive = useCallback(() => setToastActive((toastActive) => !toastActive), []);
  const toastMarkup = toastActive ? <Toast onDismiss={toggleToastActive} content="Changes saved" /> : null;

  const data = [
    {
      inviter_name: 'Nancy Roberts',
      invitee_name: 'Carol Kelly',
      signup_date: '08-01-2021',
      project_name: 'Birth Control Project',
      phase_name: 'Qual Interviews',
      amount: '$25',
      status: 'paid',
    },
    {
      inviter_name: 'Maria Moore',
      invitee_name: 'Jennifer Parker',
      signup_date: '09-02-2021',
      project_name: 'N/A',
      phase_name: 'N/A',
      amount: '$10',
      status: 'paid',
    },
    {
      inviter_name: 'Michelle Butler',
      invitee_name: 'William Jackson',
      signup_date: '10-24-2021',
      project_name: 'Fertility Journey Gap Research',
      phase_name: 'Phase I',
      amount: '$50',
      status: 'paid',
    },
    {
      inviter_name: 'Richard King',
      invitee_name: 'William Wood',
      signup_date: '12-22-2021',
      project_name: 'N/A',
      phase_name: 'N/A',
      amount: '$10',
      status: 'pending',
    },
    {
      inviter_name: 'Ruth Jordan',
      invitee_name: 'Elizabeth Bell',
      signup_date: '01-01-2022',
      project_name: 'Fertility Journey Gap Research',
      phase_name: 'Phase I',
      amount: '$50',
      status: 'pending',
    },
  ];

  const pageMarkup = (
    <Page
      fullWidth
      title="All Invitations"
      pagination={{
        hasPrevious: true,
        hasNext: true,
      }}
    >
      <Card>
        <br />
        <InvitationFilterBar />
        <DataTable columns={invitationColumns()} data={data} striped highlightOnHover pointerOnHover noHeader />
      </Card>
    </Page>
  );

  // ---- Loading ----
  // const loadingPageMarkup = <DataTableSkeleton />;
  // const pageMarkup = projectLoading ? loadingPageMarkup : actualPageMarkup;

  // return (
  //   <>
  //     {loadingMarkup}
  //     {pageMarkup}
  //     {toastMarkup}
  //   </>

  return (
    <>
      {pageMarkup}
      {toastMarkup}
    </>
  );
};

export default InvitationsList;
