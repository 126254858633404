const filterTests = [
  [
    [
      ['ethnicities_name_eq', '=', 'Asian'],
      'and',
      ['exclude_from_mailchimp_eq', '=', 'false'],
      'and',
      ['is_hcp_eq', '=', 'false'],
    ],
    'ransack[ethnicities_name_eq]=Asian&ransack[exclude_from_mailchimp_eq]=false&ransack[is_hcp_eq]=false&',
  ],
  [
    [
      ['insighter_roles_name__not_in', '<>', 'EMT'],
      'and',
      ['insighter_roles_name__not_in', '<>', 'Midwife'],
      'and',
      ['conditions_name_eq', '=', 'Brain ischemia'],
    ],
    'ransack[insighter_roles_name__not_in][]=EMT&ransack[insighter_roles_name__not_in][]=Midwife&ransack[conditions_name_eq]=Brain ischemia&',
  ],
  [['insighter_roles_name__not_in', '<>', 'Nurse'], 'ransack[insighter_roles_name__not_in][]=Nurse&'],
  [['is_hcp_eq', '=', 'false'], 'ransack[is_hcp_eq]=false&'],
  [['already_in_phase', '=', 'false'], 'ransack[already_in_phase]=39&'],
  [
    [['already_in_phase', '=', 'false'], 'and', ['age_geq', '>=', '65']],
    'ransack[already_in_phase]=39&ransack[age_geq]=65&',
  ],
];

export default filterTests;
