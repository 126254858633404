import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import ArrowIcon from '../../../assets/redesignAssets/staticImages/ArrowIcon.png';

interface ReadMoreArrowProps {
  message: string;
  link: string;
}

const ReadMoreArrow: React.FC<ReadMoreArrowProps> = (props) => {
  const { message, link } = props;

  return (
    <Grid>
      <a href={link} style={{ textDecoration: 'none', cursor: 'pointer' }} target="_blank" rel="noopener noreferrer">
        <Typography
          style={{
            marginTop: 10,
            color: '#488C7A',
            fontSize: 12,
            fontFamily: 'Rethink Sans, sans-serif',
            fontWeight: 'bold',
            lineHeight: 1,
          }}
        >
          {message} <img src={ArrowIcon} alt="Arrow" style={{ width: 14, height: 'auto' }} />
        </Typography>
      </a>
    </Grid>
  );
};

export default ReadMoreArrow;
