import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import SeparatorBarComponent from '../../navigation/components/SeparatorBarComponent';
import useStyles from '../../redesignedFrontend/constants/redesignStyles';
import { CaseStudy, CaseStudyKeyDetail } from '../model';

const CaseStudyOverviewSection: React.FC<{ caseStudy: CaseStudy }> = ({ caseStudy }) => {
  let classes = useStyles();

  const KeyDetailDisplay: React.FC<{ keyDetail: CaseStudyKeyDetail }> = ({ keyDetail }) => {
    return (
      <Grid container style={{ display: 'flex', justifyContent: 'flex-start' }}>
        <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center' }}>
          <img src={keyDetail?.s3_image_url} alt={keyDetail?.content} style={{ height: '50px', width: '50px' }} />
        </Grid>
        <Grid item xs={9} style={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="h2" style={{ fontFamily: 'Crimson Text', fontSize: '18px' }}>
            {keyDetail?.content}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid container>
      <Grid item xs={12} md={6} style={{ paddingLeft: '10%', paddingRight: '10%' }}>
        <Typography
          className={classes.h2}
          style={{
            color: '#3B4255',
            fontFamily: 'Crimson Text',
            fontSize: '36px',
            fontStyle: 'normal',
            lineHeight: '150%',
            fontWeight: 700,
            marginBottom: 10,
            paddingBottom: 5,
          }}
        >
          Case Study Overview
        </Typography>

        <Typography className="uploaded-content" style={{ marginBottom: 15 }}>
          {/* eslint-disable-next-line react/no-danger */}
          <div dangerouslySetInnerHTML={{ __html: caseStudy?.overview_text }} />
        </Typography>
      </Grid>

      <Grid item xs={12} md={6}>
        {caseStudy?.case_study_key_details?.map((keyDetail, index) => (
          <>
            <div key={`key_detail_${index}`}>
              <KeyDetailDisplay keyDetail={keyDetail} />
            </div>
            {index !== caseStudy?.case_study_key_details?.length - 1 && <SeparatorBarComponent />}
          </>
        ))}
      </Grid>
    </Grid>
  );
};

export default CaseStudyOverviewSection;
