import React from 'react';
import { useHistory } from 'react-router-dom';
import axios from '../../utils/axios.utils';
import ConfirmCancelModal from '../../modal/components/MaterialModal';

interface ArchiveOpportunityConfirmModalProps {
  opportunityId?: string;
  archiveOpportunityConfirmModalOpen?: boolean;
  setArchiveOpportunityConfirmModalOpen?: any;
}

const ArchiveOpportunityConfirmModal: React.FC<ArchiveOpportunityConfirmModalProps> = (props) => {
  const history = useHistory();

  const { opportunityId, archiveOpportunityConfirmModalOpen, setArchiveOpportunityConfirmModalOpen } = props;

  const archiveOpportunity = async () => {
    await axios.put<string, any>(`opportunities/${opportunityId}.json`, {
      opportunity: { status: 'archived', update_status: 'false' },
    });

    history.push('/admin/opportunities/');
  };

  return (
    <ConfirmCancelModal
      isOpen={archiveOpportunityConfirmModalOpen}
      title="Archive Opportunity"
      onCancel={() => setArchiveOpportunityConfirmModalOpen(false)}
      onWarning={() => archiveOpportunity()}
      warningLabel="Archive"
      cancelLabel="Cancel"
      hasWarningButton
      hasCancelButton
      hasFooterBar={false}
      height="250px"
    >
      <div style={{ marginTop: '20px', marginBottom: '20px' }}>
        <p>Are you sure you want to archive this opportunity?</p>
      </div>
    </ConfirmCancelModal>
  );
};

export default ArchiveOpportunityConfirmModal;
