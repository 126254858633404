import React from 'react';
import { IDataTableColumn } from '../../dataTable/DataTable/model';

const InsighterFinderColumns: IDataTableColumn<any>[] = [
  {
    name: 'Name',
    selector: 'name',
    sortable: true,
    grow: 0.6,
    cell: (row) => (
      <div>
        <a style={{ color: '#1D1D1D' }} href={`/admin/insighters/${row.id}`}>
          {row.name}
        </a>
      </div>
    ),
  },
  {
    name: 'User ID',
    selector: 'uuid',
    sortable: true,
    grow: 0.6,
  },
  {
    name: 'Insighter Role',
    selector: 'insighter_roles',
    sortable: true,
  },
  {
    name: 'Condition',
    selector: 'diagnosis',
    sortable: false,
    grow: 2,
  },
  {
    name: 'Ethnicity',
    selector: 'ethnicity_names',
    sortable: false,
  },
  {
    name: 'Gender',
    selector: 'gender',
    sortable: true,
  },
  {
    name: 'Sexuality',
    selector: 'sexuality',
    sortable: true,
    cell: (row) => <p style={{ textTransform: 'capitalize' }}>{row.sexuality}</p>,
  },
  {
    name: 'City',
    selector: 'city',
    sortable: true,
  },
  {
    name: 'State',
    selector: 'state',
    sortable: true,
  },
];

export default InsighterFinderColumns;
