import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import InsighterRoleForm from '../components/InsighterRoleForm';
import { InsighterRolesContext } from '../contexts/InsighterRolesContext';

import AdminPageHeader from '../../adminTools/components/AdminPageHeader';

const EditInsighterRole: React.FunctionComponent = () => {
  let { insighterRoleId } = useParams<Record<string, string>>();
  let [insighterRoleFetched, setInsighterRoleFetched] = React.useState<boolean>(false);
  let { insighterRole, fetchInsighterRole, insighterRolesLoading } = React.useContext(InsighterRolesContext);

  useEffect(() => {
    if (!insighterRoleFetched) {
      fetchInsighterRole(insighterRoleId);
      setInsighterRoleFetched(true);
    }
  }, [insighterRoleFetched, fetchInsighterRole, insighterRoleId]);

  return (
    <Grid container style={{ padding: '1% 2%' }}>
      <AdminPageHeader
        title={insighterRole?.name}
        backButton={{ label: 'Insighter Roles', link: '/admin/insighter-roles' }}
      />
      <Card style={{ padding: '2%' }}>
        <InsighterRoleForm insighterRole={insighterRole} />
      </Card>
    </Grid>
  );
};

export default EditInsighterRole;
