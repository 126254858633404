import React, { useContext, Dispatch, SetStateAction } from 'react';

import { useHistory } from 'react-router';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import { UserContext } from '../../auth/contexts/UserContext';

interface ContentManagementButtonsProps {
  recordId: string;
  recordLink: string;
  recordLabelPlural: string;
  recordLabelSingular: string;
  deleteModalOpen: Dispatch<SetStateAction<boolean>>;
}

const ContentManagementButtons: React.FC<ContentManagementButtonsProps> = (props) => {
  let history = useHistory();

  let { recordId, recordLink, recordLabelPlural, recordLabelSingular, deleteModalOpen } = props;

  let { currentUserHasRole } = useContext(UserContext);

  return (
    <>
      {currentUserHasRole('admin') && (
        <Grid container spacing={3}>
          <Grid item>
            <Button onClick={() => history.push(`/admin/${recordLink}`)} className="secondary-btn">
              {recordLabelPlural}
            </Button>
          </Grid>
          <Grid item>
            <Button onClick={() => history.push(`/admin/${recordLink}/${recordId}/edit`)} className="primary-btn">
              Edit this {recordLabelSingular}
            </Button>
          </Grid>
          <Grid item>
            <Button onClick={() => deleteModalOpen(true)} className="destructive-btn">
              Delete this {recordLabelSingular}
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default ContentManagementButtons;
