import React from 'react';
import { useParams } from 'react-router-dom';
import { Card, Layout, Loading } from '@shopify/polaris';
import Page from '../../shared/components/Page';
import InterviewForm from '../components/InterviewForm';
import { InterviewsContext } from '../contexts/InterviewsContext';
import FormSkeleton from '../../loading/components/FormSkeleton';

const EditInterview: React.FunctionComponent = () => {
  const { interviewId } = useParams<Record<string, string>>();
  const [interviewFetched, setInterviewFetched] = React.useState<boolean>(false);
  const { interview, fetchInterview, interviewLoading, fetchFormOptions } = React.useContext(InterviewsContext);

  React.useEffect(() => {
    if (!interviewFetched) {
      fetchFormOptions();
      fetchInterview(interviewId);
      setInterviewFetched(true);
    }
  }, [interviewFetched, fetchInterview, interviewId, fetchFormOptions]);

  const loadingMarkup = interviewLoading ? <Loading /> : null;

  const InterviewMetadata = () => {
    return (
      <>
        <p>{`Created on ${interview?.created_at}`}</p>
        <p>{`Project Manager: ${interview?.project_manager}`}</p>
        <p>{`Secondary Manager: ${interview?.secondary_project_manager}`}</p>
      </>
    );
  };

  // ---- Page markup ----
  const actualPageMarkup = (
    <Page
      fullWidth
      title={`${interview.project_name} Interview for ${interview.user_name}`}
      // additionalMetadata={`Created on ${interview?.created_at}\nSecond line content here`}
      additionalMetadata={<InterviewMetadata />}
    >
      <Layout>
        <Layout.Section>
          <Card sectioned>
            {interview.id && <InterviewForm interview={interview} interviewLoading={interviewLoading} />}
          </Card>
        </Layout.Section>
        <Layout.Section secondary />
      </Layout>
    </Page>
  );

  // ---- Loading ----
  const loadingPageMarkup = <FormSkeleton />;

  const pageMarkup = interviewLoading ? loadingPageMarkup : actualPageMarkup;

  return (
    <>
      {loadingMarkup}
      {pageMarkup}
    </>
  );
};

export default EditInterview;
