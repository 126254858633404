import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router';
import { Grid, Card, Button } from '@material-ui/core';
import { CaseStudy, CaseStudyKeyDetail } from '../model';
import { Icon } from '../../icons/model';
import ChooseIconModal from '../components/ChooseIconModal';
import KeyDetailsComboBox from '../components/KeyDetailComboBox';
import AdminPageHeader from '../../adminTools/components/AdminPageHeader';
import axios from '../../utils/axios.utils';

let emptyKeyDetail = {
  id: null,
  position_index: 0,
  content: '',
  icon_id: null,
  s3_image_url: null,
};

const CaseStudyEditKeyDetailsPage: React.FC = (props) => {
  let { caseStudyId } = useParams<{ caseStudyId: string }>();
  let history = useHistory();

  let [caseStudy, setCaseStudy] = useState<CaseStudy>();
  let [icons, setIcons] = useState<Icon[]>([]);
  let [chooseIconModalOpen, setChooseIconModalOpen] = useState<boolean>(false);
  let [keyDetailIndexToEdit, setKeyDetailIndexToEdit] = useState<number>(null);

  let fetchIcons = async () => {
    try {
      let response = await axios.get('icons');
      setIcons(response?.data?.result);
    } catch {
      console.log('Error fetching icons');
    }
  };

  const fetchCaseStudy = async () => {
    try {
      const response = await axios.get(`case_studies/${caseStudyId}`);
      let fetchedCaseStudy = response?.data?.result;
      if (fetchedCaseStudy.case_study_key_details?.length === 0) {
        fetchedCaseStudy.case_study_key_details.push(emptyKeyDetail);
      }
      setCaseStudy(response?.data?.result);
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchIcons();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetchCaseStudy();
    // eslint-disable-next-line
  }, [caseStudyId]);

  let handleSaveCaseStudy = async () => {
    try {
      await axios.put(`case_studies/${caseStudyId}/key_details`, { key_details: caseStudy?.case_study_key_details });
      history.push(`/case-studies/${caseStudyId}`);
    } catch (error: any) {
      console.log(error);
    }
  };

  let handleAddNewKeyDetail = () => {
    let newKeyDetail: CaseStudyKeyDetail = { ...emptyKeyDetail };
    newKeyDetail.position_index = caseStudy.case_study_key_details?.length;

    let keyDetails = [...caseStudy.case_study_key_details];
    keyDetails.push(newKeyDetail);

    let updatedCaseStudy = { ...caseStudy, case_study_key_details: keyDetails };
    setCaseStudy(updatedCaseStudy);
  };

  return (
    <Grid container style={{ padding: '1% 2%' }}>
      <ChooseIconModal
        isOpen={chooseIconModalOpen}
        setOpen={setChooseIconModalOpen}
        icons={icons}
        keyDetailIndexToEdit={keyDetailIndexToEdit}
        caseStudy={caseStudy}
        setCaseStudy={setCaseStudy}
      />
      <AdminPageHeader
        title="Edit Case Study Key Details"
        backButton={{ label: 'Case Study Images', link: `/admin/case-studies/${caseStudyId}/edit-images` }}
      />
      <Grid item xs={12}>
        <Card style={{ marginTop: 10, padding: '2%' }}>
          <Grid container>
            {caseStudy?.case_study_key_details?.map((keyDetail, index) => (
              <Grid item xs={12} key={`keyDetail-${index}`} style={{ marginBottom: 10 }}>
                <KeyDetailsComboBox
                  caseStudy={caseStudy}
                  setCaseStudy={setCaseStudy}
                  keyDetail={keyDetail}
                  index={index}
                  setChooseIconModalOpen={setChooseIconModalOpen}
                  setKeyDetailIndexToEdit={setKeyDetailIndexToEdit}
                />
              </Grid>
            ))}
          </Grid>
        </Card>
        <Grid item xs={12} style={{ marginTop: 10 }}>
          <Button className="secondary-btn" onClick={handleAddNewKeyDetail}>
            + Key Detail
          </Button>
        </Grid>
        <Grid item xs={12} style={{ marginTop: 10 }}>
          <Button className="primary-btn" onClick={handleSaveCaseStudy}>
            Save Key Details
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CaseStudyEditKeyDetailsPage;
