import React, { Dispatch, SetStateAction } from 'react';
import { Button, Card, Checkbox, FormLayout } from '@shopify/polaris';
import Snackbar from '@mui/material/Snackbar';
import { Grid } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';
import { ProjectChecklistFormValues } from '../model';

interface ProjectChecklistCardProps {
  checklistCard?: Record<string, any>;
  updateChecklist?: () => void;
  formValues?: ProjectChecklistFormValues;
  setFormValues?: Dispatch<SetStateAction<unknown>>;
}

const ProjectChecklistCard: React.FunctionComponent<ProjectChecklistCardProps> = (props) => {
  const { checklistCard, updateChecklist, formValues, setFormValues } = props;
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);

  const handleFormChange = (key: string, value: boolean | string) => {
    if (value === true) {
      setFormValues({ ...formValues, [key]: 'complete' });
      setSnackbarOpen(true);
      return;
    }

    if (value === 'not needed') {
      setFormValues({ ...formValues, [key]: 'not needed' });
      setSnackbarOpen(true);
      return;
    }

    setFormValues({ ...formValues, [key]: '' });
    setSnackbarOpen(true);
  };

  const action = (
    <>
      <Button onClick={() => updateChecklist()}>Save Changes</Button>
    </>
  );

  return (
    <>
      <Snackbar
        open={snackbarOpen}
        action={action}
        message="Unsaved changes to checklist."
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        ContentProps={{
          sx: {
            background: '#72a295',
            boxShadow: 'none',
            fontSize: '1.5rem',
          },
        }}
      />
      <br />
      <Card sectioned title={checklistCard.title}>
        <Grid container item xs={12} rowSpacing={1}>
          {checklistCard.description && (
            <Grid item xs={12}>
              <p style={{ marginBottom: '8px' }}>{checklistCard.description}</p>
            </Grid>
          )}
          <div style={{ width: '100%' }}>
            <FormLayout>
              <Grid container style={{ paddingLeft: '20px' }} spacing={1}>
                {checklistCard.checkIcons.map((check) => (
                  <>
                    {checklistCard.genericCard ? (
                      <Grid key={check.label} item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ marginRight: '10px' }}>
                          <Checkbox
                            disabled={check.disabled as boolean}
                            onChange={(value) => handleFormChange(check.databaseKey, value)}
                            label={check.label}
                            checked={check.checked as boolean}
                          />
                        </div>
                        {check.disabled && checklistCard.genericCard && (
                          <AddIcon
                            fontSize="large"
                            style={{ color: 'grey', cursor: 'pointer' }}
                            onClick={() => handleFormChange(check.databaseKey, '')}
                          />
                        )}
                        {!check.disabled && checklistCard.genericCard && (
                          <ClearIcon
                            fontSize="large"
                            style={{ color: 'grey', cursor: 'pointer' }}
                            onClick={() => handleFormChange(check.databaseKey, 'not needed')}
                          />
                        )}
                      </Grid>
                    ) : (
                      <Grid item xs={12} sm={6} md={4} lg={4} key={check.label}>
                        <div style={{ display: 'flex' }}>
                          <div style={{ textAlign: 'right', marginRight: '20px' }}>{check.icon}</div>
                          <p>{check.label}</p>
                        </div>
                      </Grid>
                    )}
                  </>
                ))}
              </Grid>
            </FormLayout>
          </div>
          {!checklistCard.genericCard && checklistCard.customLinkActive && (
            <Grid item container xs={12}>
              <div style={{ marginTop: '8px', marginRight: '4px' }}>
                <Button primary url={checklistCard.customLinkUrl}>
                  {checklistCard.customLinkLabel}
                </Button>
              </div>
            </Grid>
          )}
        </Grid>
      </Card>
    </>
  );
};

export default ProjectChecklistCard;
