import React, { useState, Dispatch, SetStateAction } from 'react';
import Grid from '@material-ui/core/Grid';
import ConfirmCancelModal from '../../modal/components/MaterialModal';
import { Icon } from '../../icons/model';
import { CaseStudy } from '../model';

interface ChooseIconModalProps {
  isOpen: boolean;
  icons: Icon[];
  setOpen: Dispatch<SetStateAction<boolean>>;
  keyDetailIndexToEdit: number;
  caseStudy: CaseStudy;
  setCaseStudy: Dispatch<SetStateAction<CaseStudy>>;
}

const ChooseIconModal: React.FC<ChooseIconModalProps> = (props) => {
  let { isOpen, setOpen, icons, keyDetailIndexToEdit, caseStudy, setCaseStudy } = props;

  let handleIconChosen = (icon) => {
    console.log(icon);
    let keyDetails = [...caseStudy.case_study_key_details];
    keyDetails[keyDetailIndexToEdit].icon_id = icon.id;
    keyDetails[keyDetailIndexToEdit].s3_image_url = icon.s3_image_url;

    let updatedCaseStudy = { ...caseStudy, case_study_key_details: keyDetails };
    setCaseStudy(updatedCaseStudy);

    setOpen(false);
  };

  return (
    <ConfirmCancelModal isOpen={isOpen} onCancel={() => setOpen(false)} title="Choose Icon" height="400px">
      <Grid container spacing={2}>
        {icons.map((icon) => (
          <Grid item xs={1} key={icon.id} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <button
              type="button"
              style={{ border: 'none', background: 'none', padding: 0, cursor: 'pointer' }}
              onClick={() => handleIconChosen(icon)}
            >
              <img src={icon.s3_image_url} alt={icon.name} style={{ maxWidth: '50px', maxHeight: '50px' }} />
            </button>
          </Grid>
        ))}
      </Grid>
    </ConfirmCancelModal>
  );
};

export default ChooseIconModal;
