import React from 'react';
import { useParams } from 'react-router-dom';
import { Card, Layout, Loading } from '@shopify/polaris';
import Page from '../../shared/components/Page';
import SpecialtyForm from '../components/SpecialtyForm';
import { SpecialtiesContext } from '../contexts/SpecialtiesContext';
import FormSkeleton from '../../loading/components/FormSkeleton';
import BackButton from '../../navigation/components/BackButton';

const EditSpecialty: React.FunctionComponent = () => {
  const { specialtyId } = useParams<Record<string, string>>();
  const [specialtyFetched, setSpecialtyFetched] = React.useState<boolean>(false);
  const { specialty, fetchSpecialty, fetchFormOptions, specialtiesLoading } = React.useContext(SpecialtiesContext);

  React.useEffect(() => {
    if (!specialtyFetched) {
      fetchFormOptions();
      fetchSpecialty(specialtyId);
      setSpecialtyFetched(true);
    }
  }, [specialtyFetched, fetchSpecialty, specialtyId, fetchFormOptions]);

  const loadingMarkup = specialtiesLoading ? <Loading /> : null;

  // ---- Page markup ----
  const actualPageMarkup = (
    <>
      <BackButton pageAction={false} />
      <Page fullWidth title={`${specialty?.name} `}>
        <Layout>
          <Layout.Section>
            <Card sectioned>
              <SpecialtyForm specialty={specialty} />
            </Card>
          </Layout.Section>
          <Layout.Section secondary />
        </Layout>
      </Page>
    </>
  );

  // ---- Loading ----
  const loadingPageMarkup = <FormSkeleton />;

  const pageMarkup = specialtiesLoading ? loadingPageMarkup : actualPageMarkup;

  return (
    <>
      {loadingMarkup}
      {pageMarkup}
    </>
  );
};

export default EditSpecialty;
