import React from 'react';
import { Button, Card, ChoiceList, Filters } from '@shopify/polaris';
import { Grid } from '@material-ui/core';
import { ExpensesContext } from '../contexts/ExpensesContext';

const ExpensesFilterBar: React.FunctionComponent = () => {
  const { fetchExpenses, statusFilter, setStatusFilter, sortParams, setFetchParams, setExpensesSum } =
    React.useContext(ExpensesContext);
  const [queryValue, setQueryValue] = React.useState<string>(sessionStorage.getItem('expenseFilterQuery') || '');
  const [statusFilters, selectedStatusFilters] = React.useState<string[]>(['']);
  const [appliedFiltersDisplay, setAppliedFiltersDisplay] = React.useState<any[]>([]);

  React.useEffect(() => {
    const appliedFiltersArray = [];

    if (statusFilter) {
      appliedFiltersArray.push({
        key: 'category',
        label: `Filtered by ${statusFilter}`,
        onRemove: () => {
          setStatusFilter('');
        },
      });
    }

    setAppliedFiltersDisplay([...appliedFiltersArray]);
  }, [statusFilter, setStatusFilter, setAppliedFiltersDisplay]);

  const applyFilters = () => {
    const queryFilterRansack = `ransack[project_project_number_cont]=${queryValue}`;
    const statusFilterRansack = `ransack[status_eq]=${statusFilters[0]}`;

    sessionStorage.setItem('expenseFilterQuery', queryValue);
    sessionStorage.setItem('expenseFilters', `${queryFilterRansack}&${statusFilterRansack}&${sortParams}`);

    setExpensesSum(null);
    setFetchParams(`${queryFilterRansack}&${statusFilterRansack}`);
    fetchExpenses(`${queryFilterRansack}&${statusFilterRansack}&${sortParams}`);
  };

  const clearFilters = () => {
    setStatusFilter('');
    setQueryValue('');
    setExpensesSum(null);
    setFetchParams('ransack[query]=');
    sessionStorage.removeItem('expenseFilterQuery');
    sessionStorage.removeItem('expenseFilters');
    fetchExpenses();
  };

  const handleFiltersKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      applyFilters();
    }
  };

  return (
    <Card.Section>
      <div role="form" onKeyDown={handleFiltersKeyPress}>
        <Filters
          queryValue={queryValue}
          queryPlaceholder="Filter by project number..."
          filters={[
            {
              key: 'status',
              label: 'Status',
              filter: (
                <ChoiceList
                  title="Status"
                  titleHidden
                  choices={[
                    { label: 'Incomplete', value: 'incomplete' },
                    { label: 'Complete', value: 'complete' },
                    { label: 'Processing', value: 'processing' },
                    { label: 'Hold', value: 'hold' },
                    { label: 'Failed', value: 'failed' },
                  ]}
                  selected={statusFilters}
                  onChange={(value) => selectedStatusFilters(value)}
                />
              ),
              shortcut: true,
            },
          ]}
          appliedFilters={appliedFiltersDisplay}
          onQueryChange={(value) => setQueryValue(value)}
          onQueryClear={() => setQueryValue('')}
          onClearAll={() => null}
        >
          <Grid container>
            <Grid item>
              <div style={{ paddingLeft: '8px' }}>
                <Button primary onClick={applyFilters}>
                  Apply Filters
                </Button>
              </div>
            </Grid>
            <Grid item>
              <div style={{ paddingLeft: '8px' }}>
                <Button onClick={clearFilters}>Clear Filters</Button>
              </div>
            </Grid>
          </Grid>
        </Filters>
      </div>
    </Card.Section>
  );
};

export default ExpensesFilterBar;
