import React, { useCallback, useState } from 'react';
import { Loading, Toast } from '@shopify/polaris';
import { useHistory } from 'react-router-dom';
import DataTable from '../../dataTable/DataTable/DataTable';
import { IDataTableColumn } from '../../dataTable/DataTable/model';
import { OpportunitiesContext } from '../contexts/OpportunitiesContext';

const columns: IDataTableColumn<any>[] = [
  {
    name: 'Name',
    selector: 'name',
    sortable: true,
    grow: 2,
  },
  {
    name: 'Category',
    selector: 'category',
    sortable: true,
  },
  {
    name: 'Audience',
    selector: 'audience',
    sortable: true,
  },
  {
    name: 'Project Name',
    selector: 'project_name',
    sortable: true,
  },
  {
    name: 'Project Number',
    selector: 'project_number',
    sortable: false,
    cell: (row) => (
      <div>
        <a style={{ color: '#1D1D1D' }} href={`/admin/projects/${row.project_id}`}>
          {row.project_number}
        </a>
      </div>
    ),
  },
  {
    name: 'Condition',
    selector: 'diagnosis',
    sortable: false,
  },
  {
    name: 'Start Date',
    selector: 'start_date',
    sortable: true,
  },
  {
    name: 'End Date',
    selector: 'end_date',
    sortable: true,
  },
  {
    name: 'Status',
    selector: 'status',
    sortable: true,
    cell: ({ status }) => (
      <div
        style={{
          fontWeight: 700,
          textTransform: 'uppercase',
          fontSize: 14,
          color: ['prescreen', 'active'].includes(status) ? 'green' : 'red',
        }}
      >
        {status}
      </div>
    ),
  },
];

const OpportunitiesList: React.FunctionComponent = () => {
  const history = useHistory();
  const [opportunitiesFetched, setOpportunitiesFetched] = React.useState(false);
  const [toastActive, setToastActive] = useState(false);
  const {
    opportunities,
    fetchOpportunities,
    opportunityLoading,
    totalRecords,
    fetchParams,
    sortParams,
    setSortParams,
  } = React.useContext(OpportunitiesContext);

  const loadingMarkup = opportunityLoading ? <Loading /> : null;

  React.useEffect(() => {
    const opportunityFilters = sessionStorage.getItem('opportunityFilters');

    if (!opportunitiesFetched) {
      if (opportunityFilters) {
        fetchOpportunities(opportunityFilters);
      } else {
        fetchOpportunities();
      }
      setOpportunitiesFetched(true);
    }
  }, [opportunitiesFetched, fetchOpportunities]);

  const toggleToastActive = useCallback(() => setToastActive((toastActive) => !toastActive), []);

  const toastMarkup = toastActive ? <Toast onDismiss={toggleToastActive} content="Changes saved" /> : null;

  const handleColumnSort = async (column: Record<string, string>, sortDirection: string) => {
    const columnNamesToRansack = {
      name: 'name',
      category: 'category',
      project_name: 'project_name',
      project_number: 'project_number',
      start_date: 'start_date',
      end_date: 'end_date',
      status: 'status',
    };

    const sort = `sort=${columnNamesToRansack[column.selector]} ${sortDirection}`;

    setSortParams(sort);
    fetchOpportunities(`${fetchParams}&${sort}`);
  };

  const handlePageChange = (page: number) => {
    fetchOpportunities(`page=${page}&${fetchParams}&${sortParams}`);
  };

  const pageMarkup = (
    <DataTable
      onRowClicked={(row: Record<string, string>) => history.push(`/admin/opportunities/${row.id}`)}
      columns={columns}
      data={opportunities}
      progressPending={opportunityLoading}
      striped
      highlightOnHover
      pointerOnHover
      noHeader
      pagination
      paginationPerPage={25}
      paginationRowsPerPageOptions={[25]}
      paginationServer
      paginationTotalRows={totalRecords}
      onChangePage={handlePageChange}
      sortServer
      onSort={handleColumnSort}
    />
  );

  return (
    <>
      {loadingMarkup}
      {pageMarkup}
      {toastMarkup}
    </>
  );
};

export default OpportunitiesList;
