import React from 'react';
import {
  Card,
  Layout,
  Loading,
  Page,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPage,
  TextContainer,
} from '@shopify/polaris';
import { Doughnut } from 'react-chartjs-2';
import DashboardNavigation from '../components/DashboardNavigation';
import ProjectCountBoxes from '../components/ProjectCountBoxes';
import { DashboardsContext } from '../contexts/DashboardsContext';

interface PhasesDashboardProps {
  isLoading: boolean;
}

const PhasesDashboard: React.FunctionComponent<PhasesDashboardProps> = (props) => {
  const { fetchPhaseAnalytics, phaseAnalyticsData, dashboardLoading } = React.useContext(DashboardsContext);
  const [dashboardFetched, setdashboardFetched] = React.useState(false);

  React.useEffect(() => {
    if (!dashboardFetched) {
      fetchPhaseAnalytics();
      setdashboardFetched(true);
    }
  }, [dashboardFetched, fetchPhaseAnalytics]);

  const loadingMarkup = dashboardLoading ? <Loading /> : null;

  const actualPageMarkup = (
    <Page fullWidth>
      <DashboardNavigation currentTab={1} />
      <ProjectCountBoxes dashboard={phaseAnalyticsData} />
      <br />
      {phaseAnalyticsData?.activePhases?.map((phase) => {
        return (
          <div
            key={phase.name}
            style={{
              width: '40%',
              marginLeft: '5%',
              marginRight: '5%',
              marginTop: '15px',
              marginBottom: '50px',
              display: 'inline-block',
            }}
          >
            <div>
              <h2 style={{ textAlign: 'center', fontSize: 24 }}>{phase.name}</h2>
            </div>
            <br />
            <div>
              <h2 style={{ textAlign: 'center', fontSize: 16 }}>Respondent Quota: {phase.respondent_quota}</h2>
              <h2 style={{ textAlign: 'center', fontSize: 16 }}>Scheduled Interviews: {phase.scheduled_interviews}</h2>
              <h2 style={{ textAlign: 'center', fontSize: 16 }}>Completed Interviews: {phase.completed_interviews}</h2>
              <h2 style={{ textAlign: 'center', fontSize: 16 }}>Remaining Slots: {phase.remaining_slots}</h2>
            </div>
            <br />
            <Doughnut
              data={{
                labels: ['Scheduled Interviews', 'Completed Interviews', 'Remaining Slots'],
                datasets: [
                  {
                    label: '# of Respondents',
                    data: [phase.scheduled_interviews, phase.completed_interviews, phase.remaining_slots],
                    backgroundColor: ['#545D78', '#2D87BB', '#EC6B56'],
                    borderColor: ['#545D78', '#2D87BB', '#EC6B56'],
                    borderWidth: 1,
                  },
                ],
              }}
            />
          </div>
        );
      })}
    </Page>
  );

  // ---- Loading ----
  const loadingPageMarkup = (
    <SkeletonPage>
      <Layout>
        <Layout.Section>
          <Card sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText lines={9} />
            </TextContainer>
          </Card>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );

  const pageMarkup = dashboardLoading ? loadingPageMarkup : actualPageMarkup;

  return (
    <>
      {loadingMarkup}
      {pageMarkup}
    </>
  );
};

export default PhasesDashboard;
