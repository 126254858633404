import React from 'react';
import { Grid } from '@material-ui/core';
import { FormLayout } from '@shopify/polaris';
import SelectInput from '../../form/components/SelectInput';
import { LeadsContext } from '../contexts/LeadsContext';
import { Lead, LeadFormValues } from '../model';
import { InsighterFinderContext } from '../../insighterFinder/contexts/InsighterFinderContext';
import ModalButton from '../../modal/components/ModalButton';

interface LeadFormProps {
  lead?: Lead;
  phaseId?: string;
  afterAction?: () => void;
}

const LeadForm: React.FC<LeadFormProps> = (props) => {
  const { lead, afterAction } = props;

  const [formValues, setFormValues] = React.useState<LeadFormValues>({});

  const { updateLead, leadSubmitting } = React.useContext(LeadsContext);
  const { leadGroupOptions } = React.useContext(InsighterFinderContext);

  React.useEffect(() => {
    setFormValues({
      lead_group_id: lead?.id,
    });
  }, [lead]);

  const handleUpdate = () => {
    if (afterAction) {
      updateLead(lead, formValues);
    } else {
      updateLead(lead, formValues);
    }
  };

  return (
    <FormLayout>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <SelectInput
            label="Lead Group"
            value={formValues.lead_group_id}
            choices={leadGroupOptions?.lead_group_options}
            onChange={(value) => setFormValues({ ...formValues, lead_group_id: value })}
          />
        </Grid>
      </Grid>
      <ModalButton theme="primary" onClick={handleUpdate} text="Update Lead" loading={leadSubmitting} />
    </FormLayout>
  );
};

export default LeadForm;
