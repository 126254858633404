import React from 'react';
import { useHistory } from 'react-router-dom';
import axios from '../../utils/axios.utils';
import ConfirmCancelModal from '../../modal/components/MaterialModal';

interface DeleteCaseStudyConfirmModalProps {
  caseStudyId?: string;
  deleteCaseStudyConfirmModalOpen?: boolean;
  setDeleteCaseStudyConfirmModalOpen?: any;
}

const DeleteCaseStudyConfirmModal: React.FC<DeleteCaseStudyConfirmModalProps> = (props) => {
  const history = useHistory();

  const { caseStudyId, deleteCaseStudyConfirmModalOpen, setDeleteCaseStudyConfirmModalOpen } = props;

  const handleDeleteCaseStudy = async () => {
    try {
      await axios.delete(`case_studies/${caseStudyId}`);
      history.push('/admin/case-studies');
    } catch (error: any) {
      console.log(error);
    }
  };

  return (
    <ConfirmCancelModal
      isOpen={deleteCaseStudyConfirmModalOpen}
      title="Delete CaseStudy"
      onCancel={() => setDeleteCaseStudyConfirmModalOpen(false)}
      onWarning={() => handleDeleteCaseStudy()}
      warningLabel="Delete"
      cancelLabel="Cancel"
      hasWarningButton
      hasCancelButton
      hasFooterBar={false}
      height="250px"
    >
      <div style={{ marginTop: '20px', marginBottom: '20px' }}>
        <p>Are you sure you want to delete this case study?</p>
      </div>
    </ConfirmCancelModal>
  );
};

export default DeleteCaseStudyConfirmModal;
