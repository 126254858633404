import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TitleBackgroundImage from '../../../../assets/redesignAssets/TitleBackgroundImage.png';
import SectionOrnament from '../../common/SectionOrnament';
import MediumOrnament from '../../common/MediumOrnament';
import useStyles from '../../constants/redesignStyles';

const styleOverrides = makeStyles((theme) => ({
  ...useStyles,
  h1Override: {
    color: '#3B4255',
    fontFamily: 'Crimson Text',
    fontSize: '64px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '100%',
    letterSpacing: '-4.25px',
    width: '100%',
    margin: 'auto 15% auto auto',

    [theme.breakpoints.down(960)]: {
      marginLeft: 0,
      textAlign: 'center',
    },
  },
  heroWrapper: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '50vh',

    [theme.breakpoints.down(960)]: {
      height: 'auto',
      paddingTop: 100,
    },
  },
  blogTitleContainer: {
    paddingLeft: '10%',
    marginTop: -10,

    [theme.breakpoints.down(960)]: {
      paddingLeft: 0,
    },
  },
  ornamentContainer: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: '25%',

    [theme.breakpoints.down(960)]: {
      paddingRight: 0,
    },
  },
  heroContainer: {
    width: 'auto',
    height: '500px',

    [theme.breakpoints.down(960)]: {
      width: '100%',
      height: 'auto',
    },
  },
}));

interface BlogPostDetailHeroProps {
  blogPost: any;
}

const BlogPostDetailHero: React.FC<BlogPostDetailHeroProps> = (props) => {
  const { blogPost } = props;
  const classes = styleOverrides();

  const [isLargeScreen, setIsLargeScreen] = React.useState<boolean>(window.innerWidth >= 960);

  React.useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 960);
    };

    window.addEventListener('resize', handleResize);

    // Initial check on mount
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Grid container>
      <Grid item xs={12} md={7}>
        <div className={classes.heroWrapper}>
          <div className={classes.blogTitleContainer}>
            <div className={classes.ornamentContainer}>
              {isLargeScreen ? (
                <SectionOrnament text={blogPost?.blog_author?.name} centerText={false} />
              ) : (
                <MediumOrnament text={blogPost?.blog_author?.name} centerText />
              )}
            </div>
            <h1
              className={classes.h1Override}
              style={{ position: 'relative', color: '#545D78', letterSpacing: '-2px', maxWidth: 600 }}
            >
              {blogPost?.title}
            </h1>
          </div>
        </div>
      </Grid>
      <Grid item xs={12} md={5}>
        <img src={blogPost?.hero_image_url} alt="Hero" style={{ width: '100%', height: 'auto' }} />
      </Grid>
    </Grid>
  );
};

export default BlogPostDetailHero;
