import React from 'react';
import { Button, FormLayout } from '@shopify/polaris';
import { Grid } from '@material-ui/core';
import FormInput from '../../form/components/FormInput';
import SelectInput from '../../form/components/SelectInput';
import { InsighterRolesContext } from '../contexts/InsighterRolesContext';
import { InsighterRole, InsighterRoleFormValues } from '../model';
import ConfirmCancelModal from '../../modal/components/MaterialModal';

interface InsighterRoleFormProps {
  insighterRole?: InsighterRole;
}

const InsighterRoleForm: React.FC<InsighterRoleFormProps> = (props) => {
  const { insighterRole } = props;
  const { formError, updateInsighterRole, createInsighterRole, insighterRoleSubmitting } =
    React.useContext(InsighterRolesContext);

  const [formValues, setFormValues] = React.useState<InsighterRoleFormValues>({});
  const [confirmModalOpen, setConfirmModalOpen] = React.useState(false);

  React.useEffect(() => {
    setFormValues({
      name: insighterRole?.name,
      status: insighterRole?.status,
      hcp_role: insighterRole?.hcp_role,
    });
  }, [insighterRole]);

  const handleUpdate = () => {
    updateInsighterRole(insighterRole.id, formValues);
  };

  const handleArchive = () => {
    updateInsighterRole(insighterRole.id, { ...formValues, archived: !insighterRole.archived });
  };

  const handleCreate = () => {
    createInsighterRole(formValues);
  };

  return (
    <FormLayout>
      <ConfirmCancelModal
        isOpen={confirmModalOpen}
        title="Archive Insighter Role"
        onCancel={() => setConfirmModalOpen(false)}
        onWarning={() => handleArchive()}
        warningLabel="Archive"
        cancelLabel="Cancel"
        hasWarningButton
        hasCancelButton
        hasFooterBar={false}
        height="250px"
      >
        <div style={{ marginTop: '20px', marginBottom: '20px' }}>
          <p>Are you sure you want to archive this Insighter Role?</p>
        </div>
      </ConfirmCancelModal>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <h2 className="Polaris-Heading">Insighter Role Information</h2>
        </Grid>
        <Grid item xs={12}>
          <FormInput
            label="Name"
            type="text"
            value={formValues.name}
            onChange={(value) => setFormValues({ ...formValues, name: value })}
          />
        </Grid>
        <Grid item xs={12}>
          <SelectInput
            label="HCP Role"
            choices={[
              { label: 'Yes', value: 'true' },
              { label: 'No', value: 'false' },
            ]}
            value={formValues.hcp_role}
            onChange={(value) => setFormValues({ ...formValues, hcp_role: value })}
          />
        </Grid>
        <Grid item xs={12}>
          <SelectInput
            label="Status"
            choices={[
              { label: 'Active', value: 'active' },
              { label: 'Inactive', value: 'inactive' },
            ]}
            value={formValues.status}
            onChange={(value) => setFormValues({ ...formValues, status: value })}
          />
        </Grid>
      </Grid>

      <>
        {formError && <p style={{ marginBottom: '8px', color: 'red' }}>{formError}</p>}
        {insighterRole?.id ? (
          <Grid>
            <Button primary onClick={handleUpdate} loading={insighterRoleSubmitting}>
              Update Insighter Role
            </Button>
            &emsp;
            {insighterRole?.archived ? (
              <Button onClick={handleArchive} loading={insighterRoleSubmitting}>
                Unarchive
              </Button>
            ) : (
              <Button destructive onClick={() => setConfirmModalOpen(true)} loading={insighterRoleSubmitting}>
                Archive
              </Button>
            )}
          </Grid>
        ) : (
          <Button primary onClick={handleCreate} loading={insighterRoleSubmitting}>
            Create Insighter Role
          </Button>
        )}
      </>
    </FormLayout>
  );
};

export default InsighterRoleForm;
