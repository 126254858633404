import React, { useContext, useState } from 'react';
import { ListItemIcon, MenuItem, makeStyles } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import CustomMaterialMenu from '../../dataTable/rowMenu/CustomMaterialMenu';
import { PhaseLead } from '../model';
import ConfirmCancelModal from '../../modal/components/MaterialModal';
import LeadForm from './LeadForm';
import { LeadGroupsContext } from '../contexts/LeadGroupsContext';

const useStyles = makeStyles(() => ({
  listItemIcon: {
    minWidth: '24px',
  },
  modalText: {
    fontSize: '20px',
    marginLeft: '20%',
    marginBottom: '20px',
    width: '60%',
  },
}));

interface PhaseLeadsRowMenuProps {
  lead: PhaseLead;
}

const PhaseLeadsRowMenu: React.FC<PhaseLeadsRowMenuProps> = (props) => {
  const { lead } = props;
  const classes = useStyles();
  const [modalOpen, setModalOpen] = useState(false);
  const { deleteLead } = useContext(LeadGroupsContext);

  return (
    <>
      <ConfirmCancelModal isOpen={modalOpen} onCancel={() => setModalOpen(false)} title="Update Lead">
        <div style={{ margin: '40px 20px' }}>
          <LeadForm
            lead={lead}
            afterAction={() => {
              setModalOpen(false);
            }}
          />
        </div>
      </ConfirmCancelModal>
      <CustomMaterialMenu size="small">
        <MenuItem onClick={() => setModalOpen(true)} style={{ fontSize: '1.4rem' }}>
          <ListItemIcon className={classes.listItemIcon}>
            <EditIcon fontSize="small" color="primary" />
          </ListItemIcon>
          Edit Lead
        </MenuItem>
        <MenuItem onClick={() => deleteLead(lead.id)} style={{ fontSize: '1.4rem' }}>
          <ListItemIcon className={classes.listItemIcon}>
            <EditIcon fontSize="small" color="primary" />
          </ListItemIcon>
          Remove Lead
        </MenuItem>
      </CustomMaterialMenu>
    </>
  );
};

export default PhaseLeadsRowMenu;
