import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { Grid } from '@material-ui/core';
import { HomepageResource } from './model';
import axios from '../../utils/axios.utils';
import HomepageResourcesHeader from './components/HomepageResourcesHeader';
import FeaturedResourcesComponent from './components/FeaturedResources';
import HomepageResourcesFilterBar from './components/HomepageResourcesFilterBar';
import HomepageResourcesGallery from './components/HomepageResourcesGallery';
import HomepageResourceViewer from './components/HomepageResourceViewer';
import Footer from '../common/RedesignFooter';

const ResourcesPage: React.FC = () => {
  let { category } = useParams<{ category: string }>();

  let [galleryResources, setGalleryResources] = useState<HomepageResource[]>([]);
  let [featuredResources, setFeaturedResources] = useState<HomepageResource[]>([]);
  let [selectedCategory, setSelectedCategory] = useState<string | undefined>(category);

  const fetchGalleryResources = async (category?: string, query?: string) => {
    try {
      const response = await axios.get('/homepage_resources', { params: { category, query } });
      setGalleryResources(response.data?.result);
    } catch (error) {
      console.error(error);
    }
  };

  // It's a little redundant that we make two calls to the same endpoint, but when we tried combining them into one function,
  // the live site would append the new resources, rather than replacing the old ones.
  const fetchFeaturedResources = async () => {
    try {
      const response = await axios.get('/homepage_resources');
      setFeaturedResources(response.data?.result?.filter((resource: HomepageResource) => resource.featured));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchFeaturedResources();
  }, []);

  useEffect(() => {
    fetchGalleryResources(selectedCategory);
  }, [selectedCategory]);

  let minHeight = window.innerWidth <= 960 ? '300px' : '200px';

  return (
    <Grid container>
      <Grid item xs={12}>
        <HomepageResourcesHeader />
      </Grid>
      <Grid item xs={12}>
        <FeaturedResourcesComponent featuredResources={featuredResources} />
      </Grid>
      <Grid item xs={12} style={{ marginTop: 20 }}>
        <HomepageResourcesFilterBar
          fetchGalleryResources={fetchGalleryResources}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
        />
      </Grid>
      <Grid item xs={12} style={{ marginTop: 20 }}>
        <Grid container style={{ paddingLeft: '12%', paddingRight: '12%', minHeight: '400px' }}>
          {galleryResources?.map((homepageResource) => (
            <Grid item xs={12} md={6} key={homepageResource?.id} style={{ marginBottom: 10, minHeight }}>
              <HomepageResourceViewer homepageResource={homepageResource} />
            </Grid>
          ))}
        </Grid>{' '}
      </Grid>
      <Grid item xs={12} style={{ marginTop: 20 }}>
        <Footer />
      </Grid>
    </Grid>
  );
};

export default ResourcesPage;
