import React from 'react';
import { useHistory } from 'react-router-dom';
import axios from '../../utils/axios.utils';
import { InsighterTag, InsighterTagFormValues } from '../model';
import { SelectOption } from '../../form/model';

interface InsighterTagsContextInterface {
  fetchInsighterTags?: (params?: string) => void;
  fetchInsighterTag?: (insighterTagId: string) => void;
  insighterTagsLoading?: boolean;
  insighterTag?: InsighterTag;
  insighterTags?: InsighterTag[];

  fetchFormOptions?: () => void;
  formOptions?: Record<string, SelectOption[]>;
  updateInsighterTag?: (insighterTagId: string, formValues: InsighterTagFormValues) => void;
  createInsighterTag?: (formValues: InsighterTagFormValues) => void;
  insighterTagSubmitting?: boolean;
  formError?: string;
}

const InsighterTagsContext = React.createContext<InsighterTagsContextInterface>({});

const InsighterTagsContextConsumer = InsighterTagsContext.Consumer;
const InsighterTagsContextProvider: React.FC = ({ children }) => {
  const history = useHistory();

  const [insighterTags, setInsighterTags] = React.useState<InsighterTag[]>([]);
  const [insighterTag, setInsighterTag] = React.useState<InsighterTag>({});
  const [formOptions, setFormOptions] = React.useState<Record<string, SelectOption[]>>({});
  const [insighterTagsLoading, setInsighterTagsLoading] = React.useState<boolean>(false);
  const [insighterTagSubmitting, setInsighterTagSubmitting] = React.useState(false);

  const fetchInsighterTags = async (params = '') => {
    setInsighterTagsLoading(true);

    const response = await axios.get<string, any>(`insighter_tags?${params}`);

    setInsighterTagsLoading(false);
    setInsighterTags(response?.data?.result);
  };

  const fetchInsighterTag = async (insighterTagId: string) => {
    setInsighterTagsLoading(true);

    const response = await axios.get<string, any>(`insighter_tags/${insighterTagId}`);

    setInsighterTagsLoading(false);
    setInsighterTag(response?.data?.result);
  };

  const createInsighterTag = async (formValues: InsighterTagFormValues) => {
    setInsighterTagSubmitting(true);

    await axios.post<string, any>(`insighter_tags`, {
      insighter_tag: { ...formValues },
    });

    setInsighterTagSubmitting(false);
    history.push('/admin/insighter-tags');
  };

  const updateInsighterTag = async (insighterTagId: string, formValues: InsighterTagFormValues) => {
    setInsighterTagSubmitting(true);

    await axios.put<string, any>(`insighter_tags/${insighterTagId}`, {
      insighter_tag: { ...formValues },
    });

    setInsighterTagSubmitting(false);
    history.push('/admin/insighter-tags');
  };

  const fetchFormOptions = async () => {
    const response = await axios.get<string, any>(`insighter_tags/new`);

    setFormOptions(response?.data);
  };

  return (
    <InsighterTagsContext.Provider
      value={{
        fetchInsighterTags,
        fetchInsighterTag,
        insighterTagsLoading,
        insighterTag,
        insighterTags,

        fetchFormOptions,
        formOptions,
        updateInsighterTag,
        createInsighterTag,
        insighterTagSubmitting,
      }}
    >
      {children}
    </InsighterTagsContext.Provider>
  );
};

export { InsighterTagsContextProvider, InsighterTagsContextConsumer, InsighterTagsContext };
