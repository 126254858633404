import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Grid, makeStyles } from '@material-ui/core';
import axios from '../../utils/axios.utils';
import RedesignFooter from '../common/RedesignFooter';
import DeleteBlogAuthorConfirmModal from './components/DeleteBlogAuthorConfirmModal';
import { BlogAuthor } from '../../blogPosts/model';

import BlogAuthorViewer from './components/BlogAuthorViewer';

import ContentManagementButtons from '../../shared/components/ContentManagementButtons';

const useStyles = makeStyles({
  sectionGrid: {
    margin: 15,
  },
});

const BlogAuthorDetailPage: React.FC = () => {
  let classes = useStyles();

  let { blogAuthorId } = useParams<{ blogAuthorId: string }>();

  let [blogAuthor, setBlogAuthor] = useState<BlogAuthor>();
  let [deleteBlogAuthorConfirmModalOpen, setDeleteBlogAuthorConfirmModalOpen] = useState(false);

  const fetchBlogAuthor = async () => {
    try {
      const response = await axios.get(`blog_authors/${blogAuthorId}`);
      setBlogAuthor(response?.data?.result);
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchBlogAuthor();
    // eslint-disable-next-line
  }, []);

  return (
    <Grid container style={{ maxWidth: '1600px', margin: 'auto', padding: '2%' }}>
      <DeleteBlogAuthorConfirmModal
        blogAuthorId={blogAuthorId}
        deleteBlogAuthorConfirmModalOpen={deleteBlogAuthorConfirmModalOpen}
        setDeleteBlogAuthorConfirmModalOpen={setDeleteBlogAuthorConfirmModalOpen}
      />
      <Grid item xs={12} style={{ marginBottom: 20 }}>
        <ContentManagementButtons
          recordId={blogAuthorId}
          recordLink="blog-authors"
          recordLabelPlural="Blog Authors"
          recordLabelSingular="Blog Author"
          deleteModalOpen={setDeleteBlogAuthorConfirmModalOpen}
        />
      </Grid>
      <Grid item xs={12} style={{ marginBottom: 25 }}>
        <BlogAuthorViewer blogAuthor={blogAuthor} />
      </Grid>
      <Grid item xs={12} style={{ marginBottom: 20 }}>
        <ContentManagementButtons
          recordId={blogAuthorId}
          recordLink="blog-authors"
          recordLabelPlural="Blog Authors"
          recordLabelSingular="Blog Author"
          deleteModalOpen={setDeleteBlogAuthorConfirmModalOpen}
        />
      </Grid>
      <Grid item xs={12} className={classes.sectionGrid}>
        <RedesignFooter />
      </Grid>
    </Grid>
  );
};

export default BlogAuthorDetailPage;
