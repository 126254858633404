import React from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import MediumOrnament from '../../common/MediumOrnament';
import useStyles from '../../constants/redesignStyles';
import LightBulbImage from '../assets/AboutLightBulb.png';

const useCustomStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    maxWidth: 1200,
    margin: '-120px auto -60px',
    [theme.breakpoints.down(960)]: {
      marginTop: 0,
    },
  },
  imageContainer: {
    width: '100%',
    height: 'auto',
    maxWidth: 500,
  },
  textContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  ornamentContainer: {
    marginBottom: 20,
    marginTop: 10,
  },
  smallText: {
    lineHeight: '1.5em',
    maxWidth: 500,
    fontWeight: 500,
  },
  lightbulbGrid: {
    order: 1, // Default order for larger screens
    [theme.breakpoints.down(960)]: {
      display: 'flex',
      justifyContent: 'center',
      order: 2, // Text comes first on mobile
    },
  },
  textGrid: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    order: 2,

    [theme.breakpoints.down(960)]: {
      justifyContent: 'center',
      textAlign: 'center',
      order: 1, // Image comes after text on mobile
    },
  },
}));

const ContentSection: React.FC = () => {
  const classes = useStyles();
  const customClasses = useCustomStyles();

  const [isCentered, setIsCentered] = React.useState<boolean>(window.innerWidth <= 960);

  React.useEffect(() => {
    const handleResize = () => {
      setIsCentered(window.innerWidth <= 960);
    };

    window.addEventListener('resize', handleResize);

    // Initial check on mount
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Grid container spacing={3} className={customClasses.container}>
      <Grid item xs={12} md={6} className={customClasses.lightbulbGrid}>
        <img src={LightBulbImage} alt="Hero" className={customClasses.imageContainer} />
      </Grid>
      <Grid item xs={12} md={6} className={customClasses.textGrid}>
        <div>
          <div>
            <MediumOrnament text="Discovering Insights" centerComponent={isCentered} centerText />
            <div style={{ marginBottom: 20, marginTop: 10 }}>
              <Typography className={classes.h2}>
                <span style={{ fontWeight: 600 }}>Find Out What Makes Us</span>
              </Typography>
              <Typography className={classes.h2}>
                <span style={{ color: '#488C7A', fontWeight: 700 }}>Light Up</span>
              </Typography>
            </div>
            <div>
              <Typography className={classes.smallText} style={{ lineHeight: '1.5em', maxWidth: 500, fontWeight: 500 }}>
                The Insighters matches you with paid research opportunities. We are part of an established research
                company, Sommer Consulting, that unlocks your insights to make healthcare more accessible for all.
              </Typography>
            </div>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default ContentSection;
