import React, { useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';

import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';

import InternalUserForm from '../components/InternalUserForm';
import { InternalUsersContext } from '../contexts/InternalUsersContext';

import AdminPageHeader from '../../adminTools/components/AdminPageHeader';

const EditInternalUser: React.FunctionComponent = () => {
  const { userId } = useParams<Record<string, string>>();
  const { internalUser, fetchInternalUser } = useContext(InternalUsersContext);

  useEffect(() => {
    fetchInternalUser(userId);
  }, [userId]);

  return (
    <Grid container style={{ padding: '1% 2%' }}>
      <AdminPageHeader
        title={`Update Account Details ${internalUser?.first_name} ${internalUser?.last_name}`}
        backButton={{ label: 'Internal Users', link: '/admin/users' }}
      />
      <Card style={{ padding: '2%' }}>
        <InternalUserForm internalUser={internalUser} />
      </Card>
    </Grid>
  );
};

export default EditInternalUser;
