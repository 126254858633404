import React, { useEffect, useState, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Button } from '@shopify/polaris';
import { Grid } from '@material-ui/core';
import { CSVLink } from 'react-csv';
import Page from '../../shared/components/Page';
import { LeadGroupsContext } from '../contexts/LeadGroupsContext';
import { InsighterFinderContext } from '../../insighterFinder/contexts/InsighterFinderContext';
import BackButton from '../../navigation/components/BackButton';
import LeadsList from '../components/LeadsList';
import todaysDate from '../../utils/dateFunctions';

const LeadGroupDetail: React.FunctionComponent = () => {
  const history = useHistory();

  const { leadGroupId } = useParams<Record<string, string>>();
  const [leadGroupFetched, setLeadGroupFetched] = useState(false);
  const [leadsFetched, setLeadsFetched] = useState(false);

  const { leadGroup, fetchLeadGroup, leadGroupLoading, fetchLeads, leadDownloadData } = useContext(LeadGroupsContext);
  const { fetchLeadGroupOptions } = useContext(InsighterFinderContext);

  useEffect(() => {
    if (leadGroup?.phase_id) {
      fetchLeadGroupOptions(leadGroup?.phase_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leadGroup]);

  useEffect(() => {
    if (!leadGroupFetched) {
      fetchLeadGroup(leadGroupId);
      setLeadGroupFetched(true);
    }
  }, [leadGroupFetched, fetchLeadGroup, leadGroupId]);

  useEffect(() => {
    if (!leadsFetched) {
      setLeadsFetched(true);
      fetchLeads(`lead_group_id=${leadGroupId}`);
    }
  }, [leadsFetched, leadGroup, fetchLeads, leadGroupId]);

  return leadGroupLoading ? (
    <></>
  ) : (
    <>
      <BackButton pageAction url={`/admin/phases/${leadGroup?.phase_id}`} />
      <br />
      <Page fullWidth>
        <Grid container justifyContent="space-between">
          <Grid xs={8} item>
            <h1 className="Polaris-Header-Title">{`${leadGroup?.name} for ${leadGroup?.phase_name}`}</h1>
          </Grid>
          <Grid xs={4} item style={{ justifyContent: 'end' }}>
            <>
              {leadDownloadData && (
                <CSVLink
                  data={leadDownloadData}
                  filename={`Lead Group-${leadGroup?.name}-${todaysDate()}.csv`}
                  style={{ textDecoration: 'none' }}
                >
                  <Button primary>Download</Button>
                </CSVLink>
              )}
              <span style={{ marginLeft: 7 }} />
              <Button onClick={() => history.push(`/admin/insighter_finder/${leadGroup?.id}`)}>Find Leads</Button>
              <span style={{ marginLeft: 7 }} />
              <Button onClick={() => history.push(`/admin/list_uploader/${leadGroup?.id}`)}>List Uploader</Button>
            </>
          </Grid>
        </Grid>
        <br />
        <LeadsList />
      </Page>
    </>
  );
};

export default LeadGroupDetail;
