import React from 'react';
import axios from '../../utils/axios.utils';

interface NavigationContextInterface {
  fetchNavigation?: () => Promise<void>;
  displayInvitations?: boolean;
  navigationLoading?: boolean;
}

const NavigationContext = React.createContext<NavigationContextInterface>({});

const NavigationContextConsumer = NavigationContext.Consumer;

const NavigationContextProvider: React.FC = ({ children }) => {
  const [displayInvitations, setDisplayInvitations] = React.useState<boolean>(false);
  const [navigationLoading, setNavigationLoading] = React.useState<boolean>(true);

  const fetchNavigation = async () => {
    setNavigationLoading(true);
    const response = await axios.get<string, any>(`navigation.json`);
    setDisplayInvitations(response?.data?.display_invitations);
    setNavigationLoading(false);
  };

  return (
    <NavigationContext.Provider
      value={{
        fetchNavigation,
        displayInvitations,
        navigationLoading,
      }}
    >
      {children}
    </NavigationContext.Provider>
  );
};

export { NavigationContextProvider, NavigationContextConsumer, NavigationContext };
