import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { Instagram, LinkedIn, Facebook } from '@material-ui/icons';
import XIcon from '../../../assets/social/XLogo.png';

const useCustomStyles = makeStyles((theme) => ({
  iconStyles: {
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    backgroundColor: '#72A295',
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '2%',
  },
}));

const socialMediaData = [
  {
    name: 'Facebook',
    icon: Facebook,
    link: 'https://www.facebook.com/TheInsightersdotcom/',
  },
  {
    name: 'LinkedIn',
    icon: LinkedIn,
    link: 'https://www.linkedin.com/company/theinsighters',
  },
  {
    name: 'Instagram',
    icon: Instagram,
    link: 'https://www.instagram.com/theinsighters_/',
  },
  {
    name: 'XIcon',
    image: XIcon,
    link: 'https://twitter.com/theinsighters_',
    isImage: true,
  },
];

const SocialMediaLinks: React.FC = () => {
  const customClasses = useCustomStyles();

  return (
    <Grid container>
      <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
        {socialMediaData.map((socialMediaLink, index) => (
          <div key={index} className={customClasses.iconStyles}>
            <a
              href={socialMediaLink.link}
              target="_blank"
              rel="noreferrer"
              style={{ color: 'white', display: 'flex', alignItems: 'center' }}
            >
              {socialMediaLink.isImage ? (
                <img
                  src={socialMediaLink.image}
                  alt={socialMediaLink.name}
                  style={{ width: '10px', height: '10px', marginTop: '-2px' }}
                />
              ) : (
                <socialMediaLink.icon style={{ fontSize: '16px', marginTop: '-2px' }} />
              )}
            </a>
          </div>
        ))}
      </Grid>
    </Grid>
  );
};

export default SocialMediaLinks;
