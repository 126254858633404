const colorsByStatus = {
  'do not reschedule': 'red',
  canceled: 'red',
  'terminated user': 'red',
  'suspicious user': 'red',
  failed: 'red',
  'past due': 'orange',
  'rescheduling needed': 'orange',
  'no show': 'orange',
  incomplete: 'orange',
  hold: 'orange',
  'shared ip with terminated user': 'orange',
  'shared ip with suspicious user': 'orange',
  complete: 'green',
  insighter: 'green',
  'outside recruiter': 'gray',
  none: 'gray',
};

const getStatusColor = (status: string): string => {
  return colorsByStatus[status?.toLowerCase()] || '#4ba9c8';
};

export default getStatusColor;
