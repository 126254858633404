import React from 'react';
import { useParams } from 'react-router-dom';
import { Card } from '@shopify/polaris';
import DataTable from '../../dataTable/DataTable/DataTable';
import { LeadGroupsContext } from '../contexts/LeadGroupsContext';
import leadColumns from '../constants/leadColumns';

const customStyles = {
  cells: {
    style: {
      padding: '8px',
      wordBreak: 'break-word',
    },
  },
};

const LeadsList: React.FunctionComponent = () => {
  const { leadGroupId } = useParams<Record<string, string>>();
  const [sortParams, setSortParams] = React.useState('');

  const { fetchLeads, leads, leadsLoading, totalRecords } = React.useContext(LeadGroupsContext);

  const handlePageChange = (page: number) => {
    fetchLeads(`lead_group_id=${leadGroupId}&page=${page}&${sortParams}`);
  };

  const handleColumnSort = async (column: Record<string, string>, sortDirection: string) => {
    const columnNamesToRansack = {
      uuid: 'user_uuid',
      name: 'user_last_name',
      created_at: 'created_at',
      account_status: 'account_status',
      status: 'status',
    };

    const sort = `sort=${columnNamesToRansack[column.selector]} ${sortDirection}`;
    setSortParams(sort);
    fetchLeads(`lead_group_id=${leadGroupId}&${sort}`);
  };

  const ExpandedComponent = ({ data }) => (
    <Card.Section title="Admin Notes">
      {data?.insighter_notes.length > 0 ? (
        <>
          {data.insighter_notes.map((note: Record<string, string>, index: number) => (
            <div key={index}>
              {index !== 0 && (
                <hr
                  style={{ margin: '20px 0', width: '100%', border: 'none', height: '1px', backgroundColor: '#ddd' }}
                />
              )}
              <p>
                <strong>From:</strong> {note.author}
                <span style={{ float: 'right' }}>
                  <strong>Date:</strong> {note.created_at}
                </span>
              </p>
              <p style={{ marginTop: 10, width: '80%' }}>
                <strong>Note:</strong> {note.description}
              </p>
            </div>
          ))}
        </>
      ) : (
        <p>This insighter has no admin notes.</p>
      )}
    </Card.Section>
  );

  return (
    <>
      <DataTable
        columns={leadColumns()}
        data={leads}
        pagination
        highlightOnHover
        pointerOnHover
        noHeader
        customStyles={customStyles}
        expandableRows
        expandableRowsComponent={<ExpandedComponent data />}
        sortServer
        progressPending={leadsLoading}
        paginationServer
        paginationPerPage={25}
        paginationRowsPerPageOptions={[25]}
        paginationTotalRows={totalRecords}
        onChangePage={handlePageChange}
        onSort={handleColumnSort}
      />
    </>
  );
};

export default LeadsList;
