import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, FilledInput, FormControl, FormHelperText, Input, InputAdornment, Tooltip } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';

interface FormInputProps {
  value: string;
  label: string;
  onChange?: (value: string) => void;
  onBlur?: () => void;
  display?: string;
  styleOverrides?: string;
  disabled?: boolean;
  error?: boolean;
  errorMessage?: string;
  placeholder?: string;
  isFilled?: boolean;
  multiline?: boolean;
  type?: string;
  name?: string;
  tooltip?: string;
  rows?: number;
  inputProps?: Record<string, string | number>;
}

const useStyles = makeStyles((_theme) => ({
  textField: {
    minHeight: '30px',
    width: '100%',
    margin: '0 0 10px',
  },
  helperText: {
    fontSize: '12px',
  },
  inputAdornment: {
    position: 'relative',
    top: '-7px',
    fontSize: '1.4rem',
    color: '#000',
    marginRight: '-10px',
  },
}));

const FormInput: React.FC<FormInputProps> = (props) => {
  const {
    value,
    label,
    onChange,
    onBlur,
    styleOverrides,
    errorMessage,
    disabled = false,
    placeholder = '',
    type,
    display,
    isFilled = true,
    multiline,
    rows,
    inputProps = {},
    tooltip = '',
    name,
  } = props;

  const classes = useStyles();

  const handleChange = (event: any): void => {
    if (onChange) {
      onChange(event.target.value);
    }
  };

  const handleBlur = () => {
    if (onBlur) {
      onBlur();
    }
  };

  const inputStyles = () => {
    if (multiline) {
      return { fontSize: '1.4rem', padding: '0px', lineHeight: 1.25, marginTop: -10 };
    }

    return { fontSize: '1.4rem', padding: '12px' };
  };

  const formattedInput = isFilled ? (
    <FilledInput
      multiline={multiline}
      rows={rows}
      onChange={handleChange}
      onBlur={handleBlur}
      type={type}
      disabled={disabled}
      placeholder={placeholder}
      value={value}
      startAdornment={
        type === 'money' ? (
          <InputAdornment position="start">
            <p className={classes.inputAdornment}>$</p>
          </InputAdornment>
        ) : null
      }
      inputProps={{
        ...inputProps,
        style: inputStyles(),
      }}
    />
  ) : (
    <Input
      onChange={handleChange}
      type={type}
      value={value}
      inputProps={{ ...inputProps, style: { fontSize: '1.4rem', padding: '12px' } }}
    />
  );

  return (
    <Box display={display}>
      <>
        <FormControl
          variant="filled"
          error={errorMessage?.length > 0}
          className={`${styleOverrides} ${classes.textField}`}
        >
          <div className="Polaris-Labelled__LabelWrapper">
            <div className="Polaris-Label">
              <label className="Polaris-Label__Text" htmlFor="formInput" style={{ textTransform: 'capitalize' }}>
                {label}
                {tooltip && (
                  <Tooltip title={<p style={{ fontSize: '1.4rem', padding: 5, lineHeight: '1.8rem' }}>{tooltip}</p>}>
                    <HelpIcon style={{ marginLeft: 5 }} />
                  </Tooltip>
                )}
              </label>
            </div>
          </div>
          {formattedInput}
          {errorMessage && <FormHelperText className={classes.helperText}>{`${errorMessage}`}</FormHelperText>}
        </FormControl>
      </>
    </Box>
  );
};

export default FormInput;
