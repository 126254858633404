import React from 'react';
import {
  Card,
  Layout,
  Loading,
  Page,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPage,
  TextContainer,
} from '@shopify/polaris';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import DashboardNavigation from '../components/DashboardNavigation';
import SignupCountBoxes from '../components/SignupCountBoxes';
import { DashboardsContext } from '../contexts/DashboardsContext';
import DashboardFilterBar from '../components/DashboardFilterBar';

interface InsighterSignupDashboardProps {
  isLoading: boolean;
}

const InsighterSignupDashboard: React.FunctionComponent<InsighterSignupDashboardProps> = () => {
  const { fetchSignupAnalytics, signupAnalyticsData, dashboardLoading, fetchDailyAnalytics, dailyAnalyticsData } =
    React.useContext(DashboardsContext);
  const [dashboardFetched, setdashboardFetched] = React.useState(false);

  React.useEffect(() => {
    if (!dashboardFetched) {
      fetchSignupAnalytics();
      fetchDailyAnalytics(null);
      setdashboardFetched(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardFetched]);

  const loadingMarkup = dashboardLoading ? <Loading /> : null;

  ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
  };

  const data = {
    labels: dailyAnalyticsData.dateValues,
    datasets: [
      {
        label: 'Insighter Signups',
        backgroundColor: 'rgb(255, 99, 132)',
        borderColor: 'rgb(255, 99, 132)',
        data: dailyAnalyticsData.countValues,
      },
    ],
  };

  const actualPageMarkup = (
    <Page fullWidth>
      <DashboardNavigation currentTab={2} />
      <SignupCountBoxes dashboard={signupAnalyticsData} />
      <Line style={{ marginTop: '15px' }} options={options} data={data} />
      <br />
      <DashboardFilterBar />
    </Page>
  );

  // ---- Loading ----
  const loadingPageMarkup = (
    <SkeletonPage>
      <Layout>
        <Layout.Section>
          <Card sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText lines={9} />
            </TextContainer>
          </Card>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );

  const pageMarkup = dashboardLoading ? loadingPageMarkup : actualPageMarkup;

  return (
    <>
      {loadingMarkup}
      {pageMarkup}
    </>
  );
};

export default InsighterSignupDashboard;
