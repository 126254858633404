import React from 'react';
import { Grid } from '@material-ui/core';
import SeparatorBar from '../../redesignedFrontend/opportunityDetailPage/assets/KeyDetailsBorder.png';

const SeparatorBarComponent: React.FC = () => {
  return (
    <Grid
      item
      xs={12}
      style={{
        display: 'flex',
        justifyContent: 'left',
        paddingLeft: '20px',
        marginTop: '5px',
        zIndex: 2,
      }}
    >
      <img src={SeparatorBar} alt="Separator Bar" style={{ width: '80%', height: 'auto', margin: 10 }} />
    </Grid>
  );
};

export default SeparatorBarComponent;
