import React, { Dispatch, SetStateAction, useState, useEffect } from 'react';
import { Card, Grid } from '@material-ui/core';
import { DropZone } from '@shopify/polaris';
import FormInput from '../../form/components/FormInput';
import { Testimonial } from '../model';
import SelectInput from '../../form/components/SelectInput';

interface TestimonialFormProps {
  formValues: Testimonial;
  setFormValues: Dispatch<SetStateAction<Testimonial>>;
  formErrors: string;
  setImageFileData: Dispatch<SetStateAction<FormData>>;
}

const TestimonialForm: React.FC<TestimonialFormProps> = (props: TestimonialFormProps) => {
  const { formValues, setFormValues, formErrors, setImageFileData } = props;

  const [imagePreview, setImagePreview] = useState<string | null>(formValues?.s3_image_url || null);

  const handleAttachFile = (files: File[]) => {
    const newFormData = new FormData();
    const attachedFile = files[0];
    newFormData.append('file', attachedFile, attachedFile.name);
    setImageFileData(newFormData);

    const previewUrl = URL.createObjectURL(attachedFile);
    setImagePreview(previewUrl);
  };

  useEffect(() => {
    if (formValues?.s3_image_url) {
      setImagePreview(formValues?.s3_image_url);
    }
  }, [formValues?.s3_image_url]);

  return (
    <Card style={{ padding: 20 }}>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <FormInput
            type="text"
            label="Name"
            value={formValues?.name}
            onChange={(value) => setFormValues({ ...formValues, name: value })}
          />
        </Grid>
        <Grid item xs={4}>
          <FormInput
            type="text"
            label="Job Title"
            value={formValues?.job_title}
            onChange={(value) => setFormValues({ ...formValues, job_title: value })}
          />
        </Grid>
        <Grid item xs={4}>
          <SelectInput
            label="Status"
            value={formValues?.status}
            onChange={(value) => setFormValues({ ...formValues, status: value })}
            choices={[
              { label: 'Active', value: 'active' },
              { label: 'Inactive', value: 'inactive' },
            ]}
          />
        </Grid>
        <Grid item xs={12}>
          <FormInput
            type="text"
            label="Quote"
            value={formValues?.quote}
            onChange={(value) => setFormValues({ ...formValues, quote: value })}
          />
        </Grid>
        <Grid item xs={4} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {imagePreview && <img src={imagePreview} alt="Preview" style={{ width: '50%', height: 'auto' }} />}
        </Grid>
        <Grid item xs={8} style={{ margin: 'auto' }}>
          <Card title="Image">
            <DropZone type="image" label="" onDrop={handleAttachFile} allowMultiple={false}>
              <DropZone.FileUpload />
            </DropZone>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <p style={{ color: 'red' }}>{formErrors}</p>
        </Grid>
      </Grid>
    </Card>
  );
};

export default TestimonialForm;
