import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles, createStyles } from '@material-ui/core';

interface BackButtonProps {
  pageAction: boolean;
  url?: string;
}

const BackButton: React.FC<BackButtonProps> = (props) => {
  const { pageAction, url } = props;
  const history = useHistory();

  const useStyles = pageAction
    ? makeStyles((theme) =>
        createStyles({
          container: {
            marginLeft: '32px',
            marginTop: '16px',
            zIndex: 1,
            [theme.breakpoints.down(985)]: {
              marginLeft: '20px',
            },
            [theme.breakpoints.down(500)]: {
              marginLeft: '16px',
              position: 'absolute',
            },
          },
        }),
      )
    : makeStyles((theme) =>
        createStyles({
          container: {
            marginLeft: '32px',
            marginTop: '16px',
            zIndex: 1,
            [theme.breakpoints.down(985)]: {
              marginLeft: '20px',
            },
            [theme.breakpoints.down(500)]: {
              marginLeft: '16px',
            },
          },
        }),
      );
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className="Polaris-Page-Header__BreadcrumbWrapper" style={{ width: '34px' }}>
        <nav role="navigation">
          <button
            type="button"
            className="Polaris-Breadcrumbs__Breadcrumb"
            onClick={() => (url ? history.push(url) : history.goBack())}
          >
            <span className="Polaris-Breadcrumbs__ContentWrapper">
              <span className="Polaris-Breadcrumbs__Icon">
                <span className="Polaris-Icon">
                  <svg className="Polaris-Icon__Svg">
                    <path d="M17 9H5.414l3.293-3.293a.999.999 0 1 0-1.414-1.414l-5 5a.999.999 0 0 0 0 1.414l5 5a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414L5.414 11H17a1 1 0 1 0 0-2z" />
                  </svg>
                </span>
              </span>
            </span>
          </button>
        </nav>
      </div>
    </div>
  );
};

export default BackButton;
