import React from 'react';
import { Grid } from '@material-ui/core';
import { FormLayout } from '@shopify/polaris';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import FormInput from '../../form/components/FormInput';
import SelectInput from '../../form/components/SelectInput';
import { RespondentsContext } from '../contexts/RespondentsContext';
import { Respondent, RespondentFormValues } from '../model';
import ModalButton from '../../modal/components/ModalButton';
import { qualtricsStatuses } from '../constants/qualtricsStatuses';

interface RespondentFormProps {
  respondent?: Respondent;
  afterAction?: () => void;
}

const RespondentForm: React.FC<RespondentFormProps> = (props) => {
  const { respondent, afterAction } = props;
  const { updateRespondent, createRespondent, respondentSubmitting, formOptions } =
    React.useContext(RespondentsContext);

  const [formValues, setFormValues] = React.useState<RespondentFormValues>({});
  const [editorState, setEditorState] = React.useState();

  React.useEffect(() => {
    if (respondent?.segment) {
      const blocksFromHtml = htmlToDraft(respondent.segment);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
      const initialState = EditorState.createWithContent(contentState);
      setEditorState(initialState);
    }
  }, [respondent]);

  React.useEffect(() => {
    setFormValues({
      first_name: respondent?.first_name,
      last_name: respondent?.last_name,
      email: respondent?.email,
      project_id: respondent?.project_id,
      qualtrics_status: respondent?.qualtrics_status,
      segment: respondent?.segment,
      homework_status: respondent?.homework_status,
      phase_id: respondent?.phase_id,
    });
  }, [respondent]);

  const handleUpdate = () => {
    if (afterAction) {
      updateRespondent(respondent.id, formValues, afterAction);
    } else {
      updateRespondent(respondent.id, formValues);
    }
  };

  const handleCreate = () => {
    if (afterAction) {
      createRespondent(formValues, afterAction);
    } else {
      createRespondent(formValues);
    }
  };

  return (
    <FormLayout>
      <h1 className="Polaris-Heading">Respondent Details</h1>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <FormInput
            label="First Name"
            value={formValues.first_name}
            onChange={(value) => setFormValues({ ...formValues, first_name: value })}
            disabled={respondent?.id !== undefined}
          />
        </Grid>
        <Grid item xs={6}>
          <FormInput
            label="Last Name"
            value={formValues.last_name}
            onChange={(value) => setFormValues({ ...formValues, last_name: value })}
            disabled={respondent?.id !== undefined}
          />
        </Grid>
        <Grid item xs={6}>
          <FormInput
            label="Email"
            value={formValues.email}
            onChange={(value) => setFormValues({ ...formValues, email: value })}
            disabled={respondent?.id !== undefined}
          />
        </Grid>
        <Grid item xs={6}>
          <SelectInput
            label="Qualtrics Status"
            choices={qualtricsStatuses}
            value={formValues.qualtrics_status}
            onChange={(value) => setFormValues({ ...formValues, qualtrics_status: value })}
          />
        </Grid>
        <Grid item xs={6}>
          <SelectInput
            label="Homework"
            choices={[
              { label: 'Unsent', value: 'Unsent' },
              { label: 'Sent', value: 'Sent' },
              { label: 'Received', value: 'Received' },
            ]}
            value={formValues.homework_status}
            onChange={(value) => setFormValues({ ...formValues, homework_status: value })}
          />
        </Grid>
        <Grid>
          <Editor
            editorState={editorState}
            wrapperClassName="demo-wrapper"
            editorClassName="demo-editor"
            onEditorStateChange={(value) => {
              setEditorState(value);
              setFormValues({
                ...formValues,
                segment: draftToHtml(convertToRaw(value.getCurrentContent())).replaceAll('<p></p>', '<br />'),
              });
            }}
            toolbar={{
              inline: { inDropdown: false },
              list: {
                inDropdown: false,
                className: undefined,
                component: undefined,
                dropdownClassName: undefined,
                options: ['unordered', 'ordered'],
              },
              textAlign: { inDropdown: true },
              history: { inDropdown: false },
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <SelectInput
            label="User Type"
            choices={[
              { label: 'HCP', value: 'hcp' },
              { label: 'HCC', value: 'hcc' },
              { label: 'Caregiver', value: 'caregiver' },
            ]}
            value={formValues.user_type}
            onChange={(value) => setFormValues({ ...formValues, user_type: value })}
          />
        </Grid>
        <Grid item xs={6}>
          <SelectInput
            label="Conditions"
            choices={formOptions?.conditions}
            value={formValues.condition_id}
            onChange={(value) => setFormValues({ ...formValues, condition_id: value })}
          />
        </Grid>
      </Grid>
      <>
        {respondent?.id ? (
          <ModalButton theme="primary" onClick={handleUpdate} text="Update Respondent" loading={respondentSubmitting} />
        ) : (
          <ModalButton theme="primary" onClick={handleCreate} text="Create Respondent" loading={respondentSubmitting} />
        )}
      </>
    </FormLayout>
  );
};

export default RespondentForm;
