import React from 'react';
import { Card } from '@shopify/polaris';

interface ExpandedInterviewListProps {
  data: any;
}

const ExpandedInterviewList: React.FC<ExpandedInterviewListProps> = ({ data }) => (
  <Card.Section title="Additional Interview Details">
    {data?.email && (
      <div style={{ marginBottom: '8px' }}>
        <p>
          <strong>Email</strong>
        </p>
        <p>{data.email}</p>
      </div>
    )}
    {data?.alternate_email1 && (
      <div style={{ marginBottom: '8px' }}>
        <p>
          <strong>Alternate Email</strong>
        </p>
        <p>{data.alternate_email1}</p>
      </div>
    )}
    {data?.mobile_phone && (
      <div style={{ marginBottom: '8px' }}>
        <p>
          <strong>Mobile Phone</strong>
        </p>
        <p>{data.mobile_phone}</p>
      </div>
    )}
    {data?.billing_phone && (
      <div style={{ marginBottom: '8px' }}>
        <p>
          <strong>Billing Phone</strong>
        </p>
        <p>{data.billing_phone}</p>
      </div>
    )}
    {data?.alternate_phone_numbers && (
      <div style={{ marginBottom: '8px' }}>
        <p>
          <strong>Alternate Phone</strong>
        </p>
        <p>{data.alternate_phone_numbers}</p>
      </div>
    )}
    {data?.note && (
      <div style={{ marginBottom: '8px' }}>
        <p>
          <strong>Interview Note</strong>
        </p>
        <p>{data.note}</p>
      </div>
    )}
    {data?.last_updated && (
      <div style={{ marginBottom: '8px' }}>
        <p>
          <strong>Last Updated By</strong>
        </p>
        <p>{data.last_updated}</p>
      </div>
    )}
    {data?.interview_changes.map(({ id, previous_time }) => (
      <div key={id} style={{ marginBottom: '8px' }}>
        <p>
          <strong>Previously Scheduled Time</strong>
        </p>
        <p>{previous_time}</p>
      </div>
    ))}
  </Card.Section>
);

export default ExpandedInterviewList;
