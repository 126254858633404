import React from 'react';
import { useHistory } from 'react-router-dom';
import axios from '../../utils/axios.utils';
import { Insighter, InsighterResponse } from '../../insighters/model';
import { Payment } from '../../payments/model';

interface InsighterPaymentsContextInterface {
  fetchInsighterPayments?: (impersonatedUserId?: string) => Promise<void>;
  fetchInsighter?: (impersonatedUserId?: string) => Promise<void>;
  payments?: any[];
  insighter?: Insighter;
  isLoading?: boolean;
  paymentError?: string;
  missingPaymentFields?: string;

  isSubmitting?: boolean;
}

const InsighterPaymentsContext = React.createContext<InsighterPaymentsContextInterface>({});

const InsighterPaymentsContextConsumer = InsighterPaymentsContext.Consumer;
const InsighterPaymentsContextProvider: React.FC = ({ children }) => {
  const history = useHistory();

  const [paymentError, setPaymentError] = React.useState<string>('');
  const [missingPaymentFields, setMissingPaymentFields] = React.useState<string>('');
  const [insighter, setInsighter] = React.useState<Insighter>({});
  const [payments, setPayments] = React.useState<any[]>([]);
  const [isLoading, setLoading] = React.useState<boolean>(false);
  const [isSubmitting, setSubmitting] = React.useState<boolean>(false);

  const fetchInsighter = async (impersonatedUserId?: string) => {
    setLoading(true);
    let response: InsighterResponse;

    if (impersonatedUserId) {
      response = await axios.get<string, InsighterResponse>(`insighters/${impersonatedUserId}`, {
        headers: { 'Content-Type': 'application/json' },
      });
    } else {
      response = await axios.get<string, InsighterResponse>(`insighter_payments/0`, {
        headers: { 'Content-Type': 'application/json' },
      });
    }

    setLoading(false);
    setInsighter(response?.data?.result);
  };

  const fetchInsighterPayments = async (impersonatedUserId?: string) => {
    setLoading(true);

    let link;

    if (impersonatedUserId) {
      link = `impersonations/${impersonatedUserId}/payments`;
    } else {
      link = `insighter_payments`;
    }

    let response: { data: { result: Payment[] } } = await axios.get(link);
    setPayments(response?.data?.result);
    setLoading(false);
  };

  return (
    <InsighterPaymentsContext.Provider
      value={{
        fetchInsighterPayments,
        fetchInsighter,
        payments,
        insighter,
        isLoading,
        isSubmitting,
        paymentError,
        missingPaymentFields,
      }}
    >
      {children}
    </InsighterPaymentsContext.Provider>
  );
};

export { InsighterPaymentsContextProvider, InsighterPaymentsContextConsumer, InsighterPaymentsContext };
