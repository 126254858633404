import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from '../../utils/axios.utils';
import { Insighter } from '../model';
import { SelectOption } from '../../form/model';

interface InsightersContextInterface {
  insighterLoading?: boolean;
  fetchInsighters?: (params?: string) => Promise<void>;
  totalRecords?: number;
  insighters?: Insighter[];
  fetchInsighter?: (insighter_id: string) => Promise<void>;
  insighter?: Insighter;

  fetchFormOptions?: () => Promise<void>;
  formOptions?: Record<string, SelectOption[]>;
  insighterSubmitting?: boolean;
  setInsighterSubmitting?: React.Dispatch<React.SetStateAction<boolean>>;

  insighterRoleFilterLabel?: string;
  setInsighterRoleFilterLabel?: React.Dispatch<React.SetStateAction<string>>;

  insighterTagFilterLabel?: string;
  setInsighterTagFilterLabel?: React.Dispatch<React.SetStateAction<string>>;

  signUpDateFilterLabel?: SignUpDateFilterLabel;
  setSignUpDateFilterLabel?: React.Dispatch<SignUpDateFilterLabel>;

  fetchParams?: string;
  setFetchParams?: React.Dispatch<React.SetStateAction<string>>;

  sortParams?: string;
  setSortParams?: React.Dispatch<React.SetStateAction<string>>;

  selectedDates?: any;
  setSelectedDates?: React.Dispatch<React.SetStateAction<any>>;

  formError?: string;
  setFormError?: React.Dispatch<React.SetStateAction<string>>;
}

interface SignUpDateFilterLabel {
  start: string;
  end: string;
}

const InsightersContext = React.createContext<InsightersContextInterface>({});

const InsightersContextConsumer = InsightersContext.Consumer;
const InsightersContextProvider: React.FC = ({ children }) => {
  const history = useHistory();
  const [insighters, setInsighters] = useState<Insighter[]>([]);
  const [totalRecords, setTotalRecords] = useState<number>();
  const [insighter, setInsighter] = useState<Insighter>({});
  const [insighterLoading, setInsighterLoading] = useState<boolean>(false);
  const [insighterSubmitting, setInsighterSubmitting] = useState(false);
  const [formOptions, setFormOptions] = useState<Record<string, SelectOption[]>>();
  const [insighterRoleFilterLabel, setInsighterRoleFilterLabel] = useState<string>('');
  const [insighterTagFilterLabel, setInsighterTagFilterLabel] = useState<string>('');
  const [signUpDateFilterLabel, setSignUpDateFilterLabel] = useState<SignUpDateFilterLabel>({ start: '', end: '' });
  const [fetchParams, setFetchParams] = useState<string>();
  const [sortParams, setSortParams] = useState<string>('sort=');
  const [formError, setFormError] = useState<string>(undefined);

  const fetchInsighters = async (params: string) => {
    setInsighterLoading(true);

    const response = await axios.get<string, any>(`insighters?${params}`);

    setTotalRecords(response?.data?.total_records);
    setInsighterRoleFilterLabel(response?.data?.applied_filters?.insighter_role?.value);
    setInsighterTagFilterLabel(response?.data?.applied_filters?.insighter_tag?.value);
    setSignUpDateFilterLabel({
      start: response?.data?.applied_filters?.sign_up_start_date?.value,
      end: response?.data?.applied_filters?.sign_up_end_date?.value,
    });
    setInsighterLoading(false);
    setInsighters(response?.data?.result);
  };

  const fetchInsighter = async (insighter_id: string) => {
    setInsighterLoading(true);

    const response = await axios.get<string, any>(`insighters/${insighter_id}.json`, {
      headers: { 'Content-Type': 'application/json' },
    });

    setInsighterLoading(false);
    setInsighter(response?.data?.result);
  };

  const fetchFormOptions = async () => {
    const response = await axios.get<string, any>(`insighters/new.json`);

    setFormOptions(response?.data);
  };

  const [selectedDates, setSelectedDates] = useState<Record<string, any>>({
    start: new Date(1900, 0, 1),
    end: new Date(2100, 0, 1),
  });

  return (
    <InsightersContext.Provider
      value={{
        insighterLoading,
        fetchInsighters,
        totalRecords,
        insighters,
        fetchInsighter,
        insighter,

        fetchFormOptions,
        formOptions,

        insighterSubmitting,
        setInsighterSubmitting,

        insighterRoleFilterLabel,
        setInsighterRoleFilterLabel,

        insighterTagFilterLabel,
        setInsighterTagFilterLabel,

        signUpDateFilterLabel,
        setSignUpDateFilterLabel,

        fetchParams,
        setFetchParams,

        sortParams,
        setSortParams,

        selectedDates,
        setSelectedDates,

        formError,
        setFormError,
      }}
    >
      {children}
    </InsightersContext.Provider>
  );
};

export { InsightersContextProvider, InsightersContextConsumer, InsightersContext };
