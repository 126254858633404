import React from 'react';

import HeroPageHeader from '../../common/HeroPageHeader';

const AboutLandingSection: React.FC = () => {
  return (
    <HeroPageHeader
      heroImageUrl="https://cdn.theinsighters.com/GotAQuestionHero.png"
      ornamentText="Insightful Assistance Awaits"
      titleText="Have A Question?"
      subtitleText="We're Here to Help"
    />
  );
};

export default AboutLandingSection;
