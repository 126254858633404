import React, { FunctionComponent, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';

import CalendlyEventForm from '../components/CalendlyEventForm';
import AdminPageHeader from '../../adminTools/components/AdminPageHeader';

import axios from '../../utils/axios.utils';

const EditCalendlyEvent: FunctionComponent = () => {
  let { calendlyEventId } = useParams<Record<string, string>>();

  let [calendlyEvent, setCalendlyEvent] = useState<any>({});

  const fetchCalendlyEvent = async (calendly_event_id: string) => {
    const response = await axios.get<string, any>(`calendly_events/${calendly_event_id}`);
    setCalendlyEvent(response?.data?.result);
  };

  useEffect(() => {
    fetchCalendlyEvent(calendlyEventId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container style={{ padding: '1% 2%' }}>
      <AdminPageHeader
        title={calendlyEvent?.name}
        backButton={{ label: 'Calendly Events', link: '/admin/calendly-events' }}
      />
      <Grid item xs={6}>
        <Card style={{ padding: '2%' }}>
          <CalendlyEventForm calendlyEvent={calendlyEvent} />
        </Card>
      </Grid>
    </Grid>
  );
};

export default EditCalendlyEvent;
