import React from 'react';
import { Checkbox, FormLayout, TextContainer, Button } from '@shopify/polaris';
import { Grid } from '@material-ui/core';
import FormInput from '../../form/components/FormInput';
import { InterviewsContext } from '../contexts/InterviewsContext';
import { Interview } from '../model';

interface MarkCompleteFormProps {
  interview: Interview;
}

const MarkCompleteForm: React.FC<MarkCompleteFormProps> = (props) => {
  const { interview } = props;

  const [adminNotes, setAdminNotes] = React.useState<string>();
  const [recommended, setRecommended] = React.useState<boolean>();
  const [suspiciousFlag, setSuspiciousFlag] = React.useState<boolean>();
  const [lhlUnconfirmedTag, setLhlUnconfirmedTag] = React.useState<boolean>();
  const [lhlNvsConfirmedTag, setLhlNvsConfirmedTag] = React.useState<boolean>();
  const [paymentAmount, setPaymentAmount] = React.useState<string>();
  const [paymentNotes, setPaymentNotes] = React.useState<string>();

  React.useEffect(() => {
    setPaymentAmount(interview?.payment_amount);
    setPaymentNotes(interview?.payment_note);
  }, [interview]);

  const { completeInterview, interviewSubmitting, formError } = React.useContext(InterviewsContext);

  return (
    <FormLayout>
      <Grid container spacing={3}>
        <Grid item xs={11}>
          <TextContainer>
            <h2 className="Polaris-Heading">Respondent Notes</h2>
            {interview?.insighter_account ? (
              <p>
                Please submit any notes you have about the respondent and your session with them. Only other moderators
                and admins will be able to see these notes.
              </p>
            ) : (
              <p style={{ color: 'red' }}>This respondent has no Insighter account to attatch notes to.</p>
            )}
          </TextContainer>
          <br />
          <FormInput
            disabled={!interview?.insighter_account}
            label="Admin Notes"
            value={adminNotes}
            onChange={(value: string) => setAdminNotes(value)}
            multiline
            rows={4}
          />
          <br />
          <hr />
          <TextContainer>
            <h2 className="Polaris-Heading">Add Insighter Tags</h2>
            {!interview?.insighter_account && (
              <p style={{ color: 'red' }}>This respondent has no Insighter account to add tags to.</p>
            )}
          </TextContainer>
          <br />
          <Checkbox
            disabled={!interview?.insighter_account}
            checked={recommended}
            label="I would recommend this Insighter for a future project"
            onChange={(value) => setRecommended(value)}
          />
          <br />
          <Checkbox
            disabled={!interview?.insighter_account}
            checked={suspiciousFlag}
            label="Flag this Insighter as suspicious"
            onChange={(value) => setSuspiciousFlag(value)}
          />
          <br />
          <Checkbox
            disabled={!interview?.insighter_account}
            checked={lhlUnconfirmedTag}
            label="Add LHL (unconfirmed) tag to Insighter"
            onChange={(value) => setLhlUnconfirmedTag(value)}
          />
          <br />
          <Checkbox
            disabled={!interview?.insighter_account}
            checked={lhlNvsConfirmedTag}
            label="Add LHL (NVS-confirmed) tag to Insighter"
            onChange={(value) => setLhlNvsConfirmedTag(value)}
          />
          <br />
          {lhlNvsConfirmedTag && (
            <p style={{ color: 'red', paddingLeft: '30px' }}>
              If you administered the NVS, include their score plus answers in the Admin Notes box.
            </p>
          )}
          <hr />
          <TextContainer>
            <h2 className="Polaris-Heading">Payment Notes</h2>
            <p>Please submit any honorarium amount changes and notes.</p>
          </TextContainer>
          <br />
          <FormInput
            label="Honorarium Amount"
            type="money"
            value={paymentAmount}
            onChange={(value: string) => {
              const formattedValue = value.charAt(0) === '$' ? value.slice(1) : value;
              setPaymentAmount(formattedValue);
            }}
          />
          <FormInput
            label="Payment Notes"
            value={paymentNotes}
            onChange={(value: string) => setPaymentNotes(value)}
            multiline
            rows={4}
          />
        </Grid>
      </Grid>
      {formError && <p style={{ color: 'red', marginBottom: '8px' }}>{formError}</p>}
      <Button
        primary
        onClick={() =>
          completeInterview(interview.id, {
            adminNotes,
            recommended,
            suspiciousFlag,
            lhlUnconfirmedTag,
            lhlNvsConfirmedTag,
            paymentAmount,
            paymentNotes,
          })
        }
        loading={interviewSubmitting}
      >
        Mark Complete
      </Button>
    </FormLayout>
  );
};

export default MarkCompleteForm;
