import React, { useContext } from 'react';
import { FilterBuilder } from 'devextreme-react';
import { InsighterFinderContext } from '../contexts/InsighterFinderContext';
import axios from '../../utils/axios.utils';

const InsighterFinderFilterBuilder: React.FC = () => {
  const { formOptions, filterBuilderDisabled, filterBuilderValue, setFilterBuilderValue } =
    useContext(InsighterFinderContext);

  const fields: any[] = [
    {
      caption: 'Is a HCP',
      dataField: 'is_hcp_eq',
      dataType: 'string',
      filterOperations: ['='],
      lookup: {
        dataSource: [
          { label: 'False', value: 'false' },
          { label: 'True', value: 'true' },
        ],
        valueExpr: 'value',
        displayExpr: 'label',
      },
    },
    {
      caption: 'Insighter Role',
      dataField: 'insighter_roles_name_eq',
      dataType: 'string',
      filterOperations: ['='],
      lookup: {
        dataSource: formOptions?.insighter_role_options,
        valueExpr: 'value',
        displayExpr: 'label',
      },
    },
    {
      caption: 'Insighter Role Does Not Contain',
      dataField: 'insighter_roles_name__not_in',
      dataType: 'string',
      filterOperations: ['<>'],
      lookup: {
        dataSource: formOptions?.insighter_role_options,
        valueExpr: 'value',
        displayExpr: 'label',
      },
    },
    {
      caption: 'First Name (Exact)',
      dataField: 'user_first_name_eq',
      dataType: 'string',
      filterOperations: ['='],
    },
    {
      caption: 'First Name',
      dataField: 'user_first_name_i_cont',
      dataType: 'string',
      filterOperations: ['contains'],
    },
    {
      caption: 'Last Name (Exact)',
      dataField: 'user_last_name_eq',
      dataType: 'string',
      filterOperations: ['='],
    },
    {
      caption: 'Last Name',
      dataField: 'user_last_name_i_cont',
      dataType: 'string',
      filterOperations: ['contains'],
    },
    {
      caption: 'Email',
      dataField: 'user_email_i_cont',
      dataType: 'string',
      filterOperations: ['contains'],
    },
    {
      caption: 'Caregiver Status',
      dataField: 'caregiver_statuses_name_eq',
      dataType: 'string',
      filterOperations: ['='],
      lookup: {
        dataSource: formOptions?.caregiver_status_options,
        valueExpr: 'value',
        displayExpr: 'label',
      },
    },
    {
      caption: 'Condition',
      dataField: 'conditions_name_eq',
      dataType: 'string',
      filterOperations: ['='],
      lookup: {
        dataSource: formOptions?.condition_options,
        valueExpr: 'value',
        displayExpr: 'label',
      },
    },
    {
      caption: 'Condition Category',
      dataField: 'condition_category_name_in',
      dataType: 'string',
      filterOperations: ['='],
      lookup: {
        dataSource: formOptions?.condition_category_options,
        valueExpr: 'value',
        displayExpr: 'label',
      },
    },
    {
      caption: 'Minimum Age',
      dataField: 'age_geq',
      dataType: 'numeric',
      filterOperations: ['>='],
    },
    {
      caption: 'Maximum Age',
      dataField: 'age_leq',
      dataType: 'numeric',
      filterOperations: ['<='],
    },
    {
      caption: 'Race/Ethnicity',
      dataField: 'ethnicities_name_eq',
      dataType: 'string',
      filterOperations: ['='],
      lookup: {
        dataSource: formOptions?.ethnicity_options,
        valueExpr: 'value',
        displayExpr: 'label',
      },
    },
    {
      caption: 'Gender',
      dataField: 'gender_in',
      dataType: 'string',
      filterOperations: ['='],
      lookup: {
        dataSource: formOptions?.gender_options,
        valueExpr: 'value',
        displayExpr: 'label',
      },
    },
    {
      caption: 'Transgender Identity',
      dataField: 'transgender_identity_in',
      dataType: 'string',
      filterOperations: ['='],
      lookup: {
        dataSource: formOptions?.transgender_identity_options,
        valueExpr: 'value',
        displayExpr: 'label',
      },
    },
    {
      caption: 'Sexual Orientation',
      dataField: 'sexuality_in',
      dataType: 'string',
      filterOperations: ['='],
      lookup: {
        dataSource: formOptions?.sexuality_options,
        valueExpr: 'value',
        displayExpr: 'label',
      },
    },
    {
      caption: 'City',
      dataField: 'city_i_cont',
      dataType: 'string',
      filterOperations: ['='],
      lookup: {
        dataSource: {
          load(loadOptions) {
            if (!loadOptions.searchValue) {
              return Promise.resolve({ data: [], totalCount: 0 });
            }
            return axios.get(`/cities?search=${loadOptions.searchValue}`).then((response) => ({
              data: response.data.city_options,
              totalCount: response.data.length,
            }));
          },
          byKey(key) {
            const selectedItem = {
              value: key,
              label: key,
            };
            return Promise.resolve(selectedItem);
          },
          paginate: true,
          pageSize: 10,
        },
        valueExpr: 'value',
        displayExpr: 'label',
      },
    },
    {
      caption: 'State',
      dataField: 'state_in',
      dataType: 'string',
      filterOperations: ['='],
      lookup: {
        dataSource: formOptions?.state_options,
        valueExpr: 'value',
        displayExpr: 'label',
      },
    },
    {
      caption: 'Insighter Tag',
      dataField: 'insighter_tags_name_eq',
      dataType: 'string',
      filterOperations: ['='],
      lookup: {
        dataSource: formOptions?.insighter_tag_options,
        valueExpr: 'value',
        displayExpr: 'label',
      },
    },
    {
      caption: 'Insighter Tag Does Not Contain',
      dataField: 'insighter_tags_name_not_in',
      dataType: 'string',
      filterOperations: ['<>'],
      lookup: {
        dataSource: formOptions?.insighter_tag_options,
        valueExpr: 'value',
        displayExpr: 'label',
      },
    },
    {
      caption: 'Sign Up Date',
      dataField: 'sign_up_time',
      dataType: 'date',
      filterOperations: ['between'],
    },
    {
      caption: 'Blocked from Marketing Emails',
      dataField: 'exclude_from_mailchimp_eq',
      dataType: 'string',
      filterOperations: ['='],
      lookup: {
        dataSource: [
          { label: 'False', value: 'false' },
          { label: 'True', value: 'true' },
        ],
        valueExpr: 'value',
        displayExpr: 'label',
      },
    },
    {
      caption: 'Specialty',
      dataField: 'specialties_name_eq',
      dataType: 'string',
      filterOperations: ['='],
      lookup: {
        dataSource: formOptions?.specialty_options,
        valueExpr: 'value',
        displayExpr: 'label',
      },
    },
    {
      caption: 'Not a lead in selected phase',
      dataField: 'already_in_phase',
      dataType: 'string',
      filterOperations: ['='],
      lookup: {
        dataSource: [{ label: 'Not a lead', value: 'false' }],
        valueExpr: 'value',
        displayExpr: 'label',
      },
    },
    {
      caption: 'Not already in project (lead, respondent, or interview)',
      dataField: 'already_in_project',
      dataType: 'string',
      filterOperations: ['='],
      lookup: {
        dataSource: [{ label: 'Not in Project', value: 'false' }],
        valueExpr: 'value',
        displayExpr: 'label',
      },
    },
  ];

  return (
    <FilterBuilder
      fields={fields}
      disabled={filterBuilderDisabled}
      maxGroupLevel={0}
      groupOperations={['and']}
      value={filterBuilderValue}
      onValueChanged={(filter: any) => {
        setFilterBuilderValue(filter.value);
        sessionStorage.setItem('filterBuilderValue', JSON.stringify(filter.value));
      }}
    />
  );
};

export default InsighterFinderFilterBuilder;
