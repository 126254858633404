import React, { FC, useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router';
import { Grid, Typography, Hidden } from '@material-ui/core';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import axios from '../../utils/axios.utils';
import Logo from '../../../assets/logo.png';
import { HomepageResource } from '../../redesignedFrontend/resourcesPage/model';
import UserMenu from './UserMenu';
import LogInButton from './LogInButton';
import siteWideStyles from '../../styles/siteWideStyles';

import { UserContext } from '../../auth/contexts/UserContext';

import MegaMenu from './MegaMenu';

interface RedesignTopNavigationProps {
  setMobileNavigationActive: any;
}

let localStyles = {
  paidOpportunityText: {
    textDecoration: 'none',
    color: '#000',
    fontWeight: 600,
    fontFamily: 'Rethink Sans',
    fontSize: 10,
    letterSpacing: '0.5px',
    width: 'auto',
    padding: 0,
    textTransform: 'uppercase' as const,
  },
};

const RedesignTopNavigation: FC<RedesignTopNavigationProps> = (props) => {
  let { setMobileNavigationActive } = props;
  let history = useHistory();

  let { user } = useContext(UserContext);

  let [megaMenuVisible, setMegaMenuVisible] = useState(false);
  let [featuredResources, setFeaturedResources] = useState<HomepageResource[]>([]);

  const fetchInitialHomepageResources = async (category?: string, query?: string) => {
    try {
      const response = await axios.get('/homepage_resources', { params: { category, query } });
      let allResources = response.data?.result;
      setFeaturedResources(allResources.filter((resource: HomepageResource) => resource.featured));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchInitialHomepageResources();
  }, []);

  return (
    <Grid
      container
      className="Polaris-TopBar"
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        textAlign: 'center',
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 10,
        boxShadow: 'none',
        background: 'inherit',
      }}
    >
      {megaMenuVisible && (
        <MegaMenu
          megaMenuVisible={megaMenuVisible}
          setMegaMenuVisible={setMegaMenuVisible}
          featuredResources={featuredResources}
        />
      )}
      <Grid item>
        <button
          type="button"
          className="Polaris-TopBar__NavigationIcon"
          aria-label="Toggle menu"
          onClick={() => setMobileNavigationActive(true)}
          style={{ marginTop: 7 }}
        >
          <span className="Polaris-Icon">
            <svg viewBox="0 0 20 20" className="Polaris-Icon__Svg" focusable="false" aria-hidden="true">
              <path d="M19 11H1a1 1 0 0 1 0-2h18a1 1 0 1 1 0 2zm0-7H1a1 1 0 0 1 0-2h18a1 1 0 1 1 0 2zm0 14H1a1 1 0 0 1 0-2h18a1 1 0 0 1 0 2z" />
            </svg>
          </span>
        </button>
      </Grid>
      <Grid item md={3}>
        <button
          type="button"
          style={{ ...siteWideStyles?.buttonLink, float: 'left' }}
          onClick={() => history.push('/')}
        >
          <img alt="logo" src={Logo} style={siteWideStyles?.logo} />
        </button>
      </Grid>
      <Grid item md={9}>
        <Grid container justifyContent="space-between">
          <Hidden smDown>
            <Grid item style={{ maxWidth: '600px', width: '80%' }}>
              <Grid container spacing={2} justifyContent="space-between">
                <Grid item>
                  <button
                    type="button"
                    onClick={() => history.push('/opportunities/general')}
                    className="Polaris-Heading Polaris-TopBar__ContextControl"
                    style={siteWideStyles.navMenuButton}
                  >
                    <Typography style={localStyles.paidOpportunityText}>Paid Opportunities</Typography>
                    <Typography style={siteWideStyles.navMenuText}>For Everyone</Typography>
                  </button>
                </Grid>
                <Grid item>
                  <button
                    type="button"
                    onClick={() => history.push('/opportunities/professionals')}
                    className="Polaris-Heading Polaris-TopBar__ContextControl"
                    style={siteWideStyles.navMenuButton}
                  >
                    <Typography style={localStyles.paidOpportunityText}>Paid Opportunities</Typography>
                    <Typography style={siteWideStyles.navMenuText}>For Healthcare Professionals</Typography>
                  </button>
                </Grid>
                <Grid item>
                  <button
                    type="button"
                    onClick={() => history.push('/about')}
                    className="Polaris-Heading Polaris-TopBar__ContextControl"
                    style={siteWideStyles.navMenuButton}
                  >
                    <Typography style={siteWideStyles.navMenuText}>About</Typography>
                  </button>
                </Grid>
                <Grid item>
                  <button
                    type="button"
                    onClick={() => setMegaMenuVisible(true)}
                    className="Polaris-Heading Polaris-TopBar__ContextControl"
                    style={siteWideStyles.navMenuButton}
                  >
                    <Typography style={siteWideStyles.navMenuText}>
                      Resources
                      <KeyboardArrowDownIcon
                        style={{
                          transform: 'rotate(0deg)',
                          transition: 'transform 0.3s ease-in-out',
                          fontWeight: 'bold',
                          position: 'relative',
                          top: 4,
                          left: 2,
                        }}
                      />
                    </Typography>
                  </button>
                </Grid>
              </Grid>
            </Grid>
          </Hidden>
          <Grid item>{user?.id ? <UserMenu /> : <LogInButton textColor="black" />}</Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RedesignTopNavigation;
