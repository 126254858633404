import React, { useEffect, Dispatch, SetStateAction } from 'react';
import { Grid } from '@material-ui/core';
import { Banner } from '@shopify/polaris';
import { Check, HighlightOff } from '@material-ui/icons';

const lowercaseRegex = /[a-z]/;
const uppercaseRegex = /[A-Z]/;
const digitRegex = /\d/;
const specialCharRegex = /[\W_]/;
const minLengthRegex = /.{8,}/;

interface PasswordBannerProps {
  password: string;
}

function PasswordBanner(props: PasswordBannerProps): JSX.Element {
  const { password } = props;

  return (
    <Grid style={{ marginTop: '10px' }}>
      <Banner title="Password must include the following:" status="info">
        <p>
          {/* Must check that password is defined for the length check and the lowercase letter check. */}
          {/* Otherwise, the value is string-converted, and "undefined" passes those checks */}
          {password && minLengthRegex.test(password) ? (
            <Check style={{ color: 'green', verticalAlign: 'middle', marginBottom: '3px' }} />
          ) : (
            <HighlightOff style={{ color: 'red', verticalAlign: 'middle', marginBottom: '3px' }} />
          )}
          &nbsp; At least 8 total characters
        </p>
        <p>
          {uppercaseRegex.test(password) ? (
            <Check style={{ color: 'green', verticalAlign: 'middle', marginBottom: '3px' }} />
          ) : (
            <HighlightOff style={{ color: 'red', verticalAlign: 'middle', marginBottom: '3px' }} />
          )}
          &nbsp; An uppercase letter
        </p>
        <p>
          {password && lowercaseRegex.test(password) ? (
            <Check style={{ color: 'green', verticalAlign: 'middle', marginBottom: '3px' }} />
          ) : (
            <HighlightOff style={{ color: 'red', verticalAlign: 'middle', marginBottom: '3px' }} />
          )}
          &nbsp; A lowercase letter
        </p>
        <p>
          {digitRegex.test(password) ? (
            <Check style={{ color: 'green', verticalAlign: 'middle', marginBottom: '3px' }} />
          ) : (
            <HighlightOff style={{ color: 'red', verticalAlign: 'middle', marginBottom: '3px' }} />
          )}
          &nbsp; A number
        </p>
        <p>
          {specialCharRegex.test(password) ? (
            <Check style={{ color: 'green', verticalAlign: 'middle', marginBottom: '3px' }} />
          ) : (
            <HighlightOff style={{ color: 'red', verticalAlign: 'middle', marginBottom: '3px' }} />
          )}
          &nbsp; A special character
        </p>
      </Banner>
    </Grid>
  );
}

export default PasswordBanner;
