import React from 'react';
import axios from '../../utils/axios.utils';
import { Expense } from '../model';

interface ExpensesContextInterface {
  fetchExpenses?: (params?: string) => Promise<void>;
  expenses?: Expense[];
  expenseLoading?: boolean;

  statusFilter?: string;
  setStatusFilter?: React.Dispatch<React.SetStateAction<string>>;

  totalRecords?: number;

  expensesSum?: string;
  setExpensesSum?: React.Dispatch<React.SetStateAction<string>>;

  expensesDownload?: Record<string, any>[];

  sortParams?: string;
  setSortParams?: React.Dispatch<React.SetStateAction<string>>;

  fetchParams?: string;
  setFetchParams?: React.Dispatch<React.SetStateAction<string>>;
}

const ExpensesContext = React.createContext<ExpensesContextInterface>({
  fetchExpenses: undefined,
  expenses: [],
});

const ExpensesContextConsumer = ExpensesContext.Consumer;
const ExpensesContextProvider: React.FC = ({ children }) => {
  const [expenseLoading, setExpenseLoading] = React.useState(false);
  const [expenses, setExpenses] = React.useState<Expense[]>([]);
  const [expensesSum, setExpensesSum] = React.useState(null);
  const [statusFilter, setStatusFilter] = React.useState<string>('');
  const [sortParams, setSortParams] = React.useState<string>('sort=');
  const [totalRecords, setTotalRecords] = React.useState<number>();
  const [fetchParams, setFetchParams] = React.useState<string>('');
  const [expensesDownload, setExpensesDownload] = React.useState<Record<string, any>[]>();

  const fetchExpenses = async (params = '') => {
    setExpenseLoading(true);

    const response = await axios.get<string, any>(`expenses?sort=created_at asc&${params}`);

    setExpenses(response?.data?.result);
    setExpensesSum(response?.data?.expenses_sum);
    setStatusFilter(response?.data?.applied_filters?.status?.value);
    setTotalRecords(response?.data?.total_records);

    const expensesDownloadResponse = await axios.get<string, any>(`expenses_download?sort=created_at asc&${params}`);
    setExpensesDownload(expensesDownloadResponse?.data?.expense_csv_data);

    setExpenseLoading(false);
  };

  return (
    <ExpensesContext.Provider
      value={{
        fetchExpenses,
        expenses,

        expenseLoading,

        expensesSum,
        setExpensesSum,

        expensesDownload,

        statusFilter,
        setStatusFilter,

        sortParams,
        setSortParams,
        fetchParams,
        setFetchParams,
        totalRecords,
      }}
    >
      {children}
    </ExpensesContext.Provider>
  );
};

export { ExpensesContextProvider, ExpensesContextConsumer, ExpensesContext };
