import React from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';
// src/assets/redesignAssets/staticImages/ValuesRect.png
import ValuesRect from '../../../../assets/redesignAssets/staticImages/ValuesRect.png';
import ValuesDivider from '../../../../assets/redesignAssets/staticImages/ValuesDivider.png';
import ExcellenceIcon from '../../../../assets/redesignAssets/staticImages/ExcellenceIcon.png';
import DiversityIcon from '../../../../assets/redesignAssets/staticImages/DiversityIcon.png';
import ExpertiseIcon from '../../../../assets/redesignAssets/staticImages/ExpertiseIcon.png';
import useStyles from '../../constants/redesignStyles';

const useValueStyles = makeStyles((theme) => ({
  valueGrid: {
    width: '30%',
    height: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  dividerGrid: {
    width: 'auto',
    height: '30%',
  },
  dividerPic: {
    width: '70%',
    height: 'auto',
  },
  iconPic: {
    width: '40%',
    height: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '60%', // Larger icons on small screens
    },
  },
}));

const ValuesBox: React.FC = () => {
  const classes = useStyles();
  const valueClasses = useValueStyles();

  return (
    <div
      style={{
        position: 'relative',
        display: 'inline-block',
        width: '100%',
        padding: '20px',
        top: 20,
        left: -200,
      }}
    >
      <div
        style={{
          backgroundColor: 'white',
          position: 'absolute',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
          zIndex: 0,
          borderRadius: 20,
          objectFit: 'cover',
          boxShadow: '10px 10px 20px rgba(0, 0, 0, 0.2)',
        }}
      />
      <Grid
        container
        spacing={2}
        style={{ position: 'relative', zIndex: 1, display: 'flex', justifyContent: 'center' }}
      >
        <Grid item className={valueClasses.valueGrid}>
          <Grid container justifyContent="center">
            <Grid item xs={4} md={12}>
              <img src={ExcellenceIcon} alt="Excellence" className={valueClasses.iconPic} />
            </Grid>
            <Grid item xs={8} md={12}>
              <Typography style={{ color: 'black', fontFamily: 'Crimson Text', fontSize: 24, fontWeight: 700 }}>
                Excellence
              </Typography>
              <Typography className={classes.smallText} style={{ textAlign: 'center' }}>
                40 years in business
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item className={valueClasses.dividerGrid}>
          <img src={ValuesDivider} alt="Values" className={valueClasses.dividerPic} />
        </Grid>
        <Grid item className={valueClasses.valueGrid}>
          <Grid container justifyContent="center">
            <Grid item xs={4} md={12}>
              <img src={DiversityIcon} alt="Diversity" className={valueClasses.iconPic} />
            </Grid>
            <Grid item xs={8} md={12}>
              <Typography style={{ color: 'black', fontFamily: 'Crimson Text', fontSize: 24, fontWeight: 700 }}>
                Diversity
              </Typography>
              <Typography className={classes.smallText} style={{ textAlign: 'center' }}>
                Women and
                <br />
                Minority-Owned
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item className={valueClasses.dividerGrid}>
          <img src={ValuesDivider} alt="Values" className={valueClasses.dividerPic} />
        </Grid>
        <Grid item className={valueClasses.valueGrid}>
          <Grid container justifyContent="center">
            <Grid item xs={4} md={12}>
              <img src={ExpertiseIcon} alt="Diversity" className={valueClasses.iconPic} />
            </Grid>
            <Grid item xs={8} md={12}>
              <Typography style={{ color: 'black', fontFamily: 'Crimson Text', fontSize: 24, fontWeight: 700 }}>
                Expertise
              </Typography>
              <Typography className={classes.smallText} style={{ textAlign: 'center' }}>
                50,000+ Interviews &<br />
                Surveys Completed
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default ValuesBox;
