import React from 'react';
import { Button, Card, Filters } from '@shopify/polaris';
import { ConditionsContext } from '../contexts/ConditionsContext';

const ProjectFilterBar: React.FunctionComponent = () => {
  const [filterValues, setFilterValues] = React.useState<Record<string, string>>({ query: '' });
  const { setFetchParams, fetchConditions } = React.useContext(ConditionsContext);

  const applyFilters = () => {
    const nameQueryFilter = `ransack[name_cont]=${filterValues.query}`;
    const conditionCategoryQueryFilter = `ransack[condition_categories_name_cont]=${filterValues.query}`;
    setFetchParams(`${nameQueryFilter}&${conditionCategoryQueryFilter}`);
    fetchConditions(`${nameQueryFilter}&${conditionCategoryQueryFilter}`);
  };

  const handleFiltersKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      applyFilters();
    }
  };

  return (
    <Card.Section>
      <div role="form" onKeyDown={handleFiltersKeyPress}>
        <Filters
          queryValue={filterValues.query}
          queryPlaceholder="Filter conditions by name or category..."
          filters={[]}
          appliedFilters={[]}
          onQueryChange={(value) => setFilterValues({ ...filterValues, query: value })}
          onQueryClear={() => {
            setFetchParams('ransack[query]=');
            setFilterValues({ ...filterValues, query: '' });
          }}
          onClearAll={() => null}
        >
          <div style={{ paddingLeft: '8px' }}>
            <Button onClick={applyFilters}>Apply Filter</Button>
          </div>
        </Filters>
      </div>
    </Card.Section>
  );
};

export default ProjectFilterBar;
