/* eslint-disable react-hooks/exhaustive-deps */

import React, { useContext, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { Button } from '@shopify/polaris';
import LoadingSpinner from '../../loading/components/LoadingSpinner';
import SelectInput from '../../form/components/SelectInput';
import FormInput from '../../form/components/FormInput';
import { InsighterFinderContext } from '../contexts/InsighterFinderContext';
import { SavedSearchesContext } from '../contexts/SavedSearchesContext';

const SavedSearchRow: React.FC = () => {
  const { filterBuilderValue, setFilterBuilderDisabled } = useContext(InsighterFinderContext);

  const {
    fetchSingleSavedSearch,
    selectedSavedSearchId,
    savedSearchesLoading,
    fetchSavedSearches,
    setDeleteSavedSearchModalOpen,
    savedSearchOptions,
    setSelectedSavedSearchId,
    nextSaveNum,
    nameForSavedSearch,
    setNameForSavedSearch,
    editNameFieldOpen,
    setEditNameFieldOpen,
    saveButtonOpen,
    setSaveButtonOpen,
    handleSaveSearch,
    handleUpdateSearch,
    loadedSavedSearch,
  } = useContext(SavedSearchesContext);

  const [showCreateButton, setShowCreateButton] = React.useState(true);
  const [showEditButton, setShowEditButton] = React.useState(false);

  useEffect(() => {
    fetchSavedSearches();
  }, []);

  useEffect(() => {
    if (selectedSavedSearchId) {
      fetchSingleSavedSearch();
    }
  }, [selectedSavedSearchId]);

  const filterIsCompound = (input) => {
    return input && Array.isArray(input[0]);
  };

  const filterHasChanged = JSON.stringify(filterBuilderValue) !== JSON.stringify(loadedSavedSearch.search_filters);

  useEffect(() => {
    setShowCreateButton(filterHasChanged && filterIsCompound(filterBuilderValue) && !saveButtonOpen);
  }, [filterBuilderValue, loadedSavedSearch, saveButtonOpen]);

  useEffect(() => {
    setShowEditButton(!filterHasChanged && !editNameFieldOpen);
  }, [filterHasChanged, editNameFieldOpen]);

  useEffect(() => {
    setFilterBuilderDisabled(saveButtonOpen || editNameFieldOpen);
  }, [saveButtonOpen, editNameFieldOpen]);

  return savedSearchesLoading ? (
    <LoadingSpinner />
  ) : (
    <Grid style={{ display: 'flex', justifyContent: 'space-between' }}>
      <Grid item xs={4} sm={4} md={4} lg={4}>
        <SelectInput
          label="Saved Search"
          choices={savedSearchOptions}
          onChange={(value) => {
            setEditNameFieldOpen(false);
            setSelectedSavedSearchId(value);
            setSaveButtonOpen(false);
          }}
          value={selectedSavedSearchId}
        />
      </Grid>
      <Grid item xs={4} sm={4} md={4} lg={4} style={{ textAlign: 'right' }}>
        {showCreateButton && (
          <Button
            primary
            onClick={() => {
              setShowCreateButton(false);
              setSaveButtonOpen(true);
              setNameForSavedSearch(`Saved Search ${nextSaveNum}`);
            }}
          >
            Create a New Saved Search
          </Button>
        )}
        {showEditButton && (
          <>
            <Button
              onClick={() => {
                setEditNameFieldOpen(true);
              }}
            >
              Edit Name
            </Button>
            &emsp;
            <Button
              destructive
              onClick={() => {
                setDeleteSavedSearchModalOpen(true);
              }}
            >
              Delete Saved Search
            </Button>
          </>
        )}
        {saveButtonOpen && (
          <Grid>
            <FormInput
              label="Name"
              type="text"
              value={nameForSavedSearch}
              onChange={(value) => setNameForSavedSearch(value)}
            />
            <Button primary onClick={handleSaveSearch}>
              Save
            </Button>
            &emsp;
            <Button onClick={() => setSaveButtonOpen(false)}>Cancel</Button>
          </Grid>
        )}
        {editNameFieldOpen && (
          <Grid>
            <FormInput
              label="Name"
              type="text"
              value={nameForSavedSearch}
              onChange={(value) => setNameForSavedSearch(value)}
            />
            <Button primary onClick={handleUpdateSearch}>
              Update
            </Button>
            &emsp;
            <Button onClick={() => setEditNameFieldOpen(false)}>Cancel</Button>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default SavedSearchRow;
