import React, { useContext, useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import MediumOrnament from '../../common/MediumOrnament';
import { BlogAuthor } from '../../../blogPosts/model';
import siteWideStyles from '../../../styles/siteWideStyles';

const useStyles = makeStyles((theme) => ({
  sectionGrid: {
    maxWidth: '1200px',
    margin: 'auto',
    [theme.breakpoints.down(960)]: {
      marginTop: 30,
    },
  },
  authorTitle: {
    fontFamily: 'Crimson Text',
    marginBottom: 10,
    fontWeight: 'bold',
    textAlign: 'left',

    [theme.breakpoints.down(960)]: {
      textAlign: 'center',
    },
  },
  authorBio: {
    fontSize: 18,
    fontFamily: 'Crimson Text',
    width: '100%',
    textAlign: 'left',

    [theme.breakpoints.down(960)]: {
      textAlign: 'center',
    },
  },
}));

interface BlogAuthorViewerProps {
  blogAuthor: BlogAuthor;
}

const BlogAuthorViewer: React.FC<BlogAuthorViewerProps> = (props) => {
  let { blogAuthor } = props;

  const classes = useStyles();

  const [isLargeScreen, setIsLargeScreen] = React.useState<boolean>(window.innerWidth >= 960);

  React.useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 960);
    };

    window.addEventListener('resize', handleResize);

    // Initial check on mount
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Grid container className={classes.sectionGrid} justifyContent="space-between">
      <Grid item xs={12} md={6} style={{ alignItems: 'center', display: 'flex' }}>
        <Grid container>
          <Grid item xs={12}>
            <MediumOrnament text="About the Author" width="200px" centerComponent={!isLargeScreen} centerText />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h3" className={classes.authorTitle}>
              Meet {blogAuthor?.name}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <div
              className={classes.authorBio}
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: blogAuthor?.bio }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={6} style={{ textAlign: 'right' }}>
        <img
          src={blogAuthor?.s3_image_url}
          alt="author"
          style={{ width: 'auto', height: '500px', objectFit: 'cover', display: 'inline-block' }}
        />
      </Grid>
    </Grid>
  );
};

export default BlogAuthorViewer;
