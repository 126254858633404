import React from 'react';
import { Grid } from '@material-ui/core';
import InformationField from '../../form/components/InformationField';

interface RecipientNameLineProps {
  user?: any;
}

const RecipientNameLine: React.FC<RecipientNameLineProps> = (props) => {
  const { user } = props;

  return (
    <Grid container style={{ padding: 15, width: '60%' }}>
      <Grid xs={2}>
        <InformationField label="ID" value={user?.uuid} />
      </Grid>
      <Grid xs={3}>
        <InformationField label="Name" value={`${user?.last_name}, ${user?.first_name}`} />
      </Grid>
      <Grid xs={5}>
        <InformationField label="Email" value={user?.email} />
      </Grid>
    </Grid>
  );
};

export default RecipientNameLine;
