import React from 'react';

import Grid from '@material-ui/core/Grid';

import Calendly from '../assets/Calendly.png';
import Tremendous from '../assets/Tremendous.png';
import Qualtrics from '../assets/Qualtrics.png';

let pics = [Calendly, Tremendous, Qualtrics];

const TechnologyPartners: React.FC = () => {
  return (
    <Grid container style={{ display: 'flex', justifyContent: 'center', padding: '2%' }}>
      {pics.map((cardDetail, index) => (
        <Grid
          item
          xs={4}
          md={4}
          key={`tech-partner-${index}`}
          style={{ display: 'flex', alignItems: 'center', padding: 10 }}
        >
          <img src={cardDetail} alt={`Pic-${index}`} style={{ height: 'auto', width: '80%' }} />
        </Grid>
      ))}
    </Grid>
  );
};

export default TechnologyPartners;
