import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TitleBackgroundImage from '../../../assets/redesignAssets/TitleBackgroundImage.png';
import TitleSubBackgroundImage from '../../../assets/redesignAssets/TitleSubBackground.png';
import SectionOrnament from '../../redesignedFrontend/common/SectionOrnament';
import useStyles from '../../redesignedFrontend/constants/redesignStyles';
import { CaseStudy } from '../model';

interface CaseStudyTitleSectionProps {
  caseStudy: CaseStudy;
}

const CaseStudyTitleSection: React.FC<CaseStudyTitleSectionProps> = (props) => {
  const classes = useStyles();

  let { caseStudy } = props;

  return (
    <Grid container>
      <Grid item xs={12} md={7} style={{ position: 'relative' }}>
        <img
          src={TitleBackgroundImage}
          alt="Title Background"
          style={{ width: '100%', height: 'auto', position: 'absolute' }}
        />
        <Grid container style={{ zIndex: 1, position: 'relative', paddingLeft: '17%', paddingTop: '12%' }}>
          <Grid item xs={12} style={{ paddingRight: '50%' }}>
            <SectionOrnament text={caseStudy?.ornament_text} centerText={false} />
          </Grid>
          <Grid item xs={12} style={{ marginBottom: 15 }}>
            <Typography className={classes.h1}>{caseStudy?.title}</Typography>
          </Grid>
          <Grid item xs={8}>
            <div style={{ position: 'relative' }}>
              <img
                src={TitleSubBackgroundImage}
                alt="Accent Line"
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: 'auto',
                  zIndex: 1,
                }}
              />
              <div
                style={{
                  position: 'relative',
                  zIndex: 2,
                  padding: '20px 0',
                }}
              >
                <Typography className={classes.h2} style={{ zIndex: 3 }}>
                  {caseStudy?.subtitle}
                </Typography>
              </div>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={5}>
        <img
          src={caseStudy?.case_study_images[1]?.s3_image_url}
          alt="Case Study Title"
          style={{ width: '100%', height: 'auto', borderRadius: 25, marginTop: -30 }}
        />
      </Grid>
    </Grid>
  );
};

export default CaseStudyTitleSection;
