import React from 'react';
import { Button, Grid, Link, CircularProgress } from '@material-ui/core';
import { FormLayout } from '@shopify/polaris';
import { UserContext } from '../contexts/UserContext';
import FormInput from '../../form/components/FormInput';

const PasswordResetForm: React.FC = () => {
  const [email, setEmail] = React.useState('');
  const { isLoading, resetPassword, passwordResetError } = React.useContext(UserContext);

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      resetPassword(email);
    }
  };

  return (
    <FormLayout>
      <div role="form" onKeyDown={handleKeyPress}>
        <FormInput type="email" label="Email" value={email} onChange={(value) => setEmail(value)} />
        {passwordResetError && <p>{passwordResetError}</p>}
        <Grid container spacing={3}>
          <Grid item>
            <Button
              variant="contained"
              style={{
                backgroundColor: '#E7AC34',
                height: 40,
                width: 'auto',
                fontSize: '16px',
                borderRadius: '4px',
                marginTop: 8,
                textTransform: 'capitalize',
                fontFamily: 'Cooper',
                minWidth: '120px',
              }}
              onClick={() => resetPassword(email)}
              disabled={isLoading}
            >
              {isLoading ? (
                <CircularProgress style={{ width: '30px', height: '30px', marginTop: 0 }} />
              ) : (
                'Send Reset Instructions'
              )}
            </Button>
          </Grid>
          <Grid item>
            <Link style={{ lineHeight: '60px', fontFamily: 'Cooper' }} href="/login">
              Return to Login
            </Link>
          </Grid>
        </Grid>
      </div>
    </FormLayout>
  );
};

export default PasswordResetForm;
