const degreeChoices: any[] = [
  { label: 'Doctor of Medicine (MD)', value: 'Doctor of Medicine (MD)' },
  { label: 'Doctor of Osteopathic Medicine (DO)', value: 'Doctor of Osteopathic Medicine (DO)' },
  { label: 'Doctor of Pharmacy (PharmD)', value: 'Doctor of Pharmacy (PharmD)' },
  { label: 'Licensed Practical Nurse (LPN)', value: 'Licensed Practical Nurse (LPN)' },
  { label: 'Medical Assistant (MA)', value: 'Medical Assistant (MA)' },
  { label: 'Registered Nurse (RN)', value: 'Registered Nurse (RN)' },
  { label: 'Nurse Practitioner (NP)', value: 'Nurse Practitioner (NP)' },
  { label: 'Physician Assistant-Certified (PA-C)', value: 'Physician Assistant-Certified (PA-C)' },
  { label: 'Doctor of Nursing Practice (DNP)', value: 'Doctor of Nursing Practice (DNP)' },
  { label: 'Other (please specify)', value: 'Other (please specify)' },
];

export default degreeChoices;
