/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from 'react';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import { Card, Button } from '@shopify/polaris';

import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { PaymentFormValues } from '../model';
import { PaymentsContext } from '../contexts/PaymentsContext';
import InsighterLookup from '../components/InsighterLookup';
import PaymentForm from '../components/PaymentForm';
import AdminPageHeader from '../../adminTools/components/AdminPageHeader';

import { IDataTableColumn } from '../../dataTable/DataTable/model';
import DataTable from '../../dataTable';

import axios from '../../utils/axios.utils';

type Props = Record<string, unknown>;

const foundUserColumns: IDataTableColumn<any>[] = [
  { name: 'Email', selector: 'email', sortable: true },
  { name: 'UUID', selector: 'uuid', sortable: true },
];

const NewPaymentContainer: React.FC<Props> = (props) => {
  let history = useHistory();
  const { search } = useLocation();
  const { projectId = '' } = useParams<Record<string, string>>();
  let { phaseId = '' } = useParams<Record<string, string>>();
  if (!phaseId) phaseId = new URLSearchParams(search).get('phase_id') || '';
  const email = new URLSearchParams(search).get('email') || '';

  const { setPayment } = useContext(PaymentsContext);

  const [formValues, setFormValues] = useState<PaymentFormValues>({ project_id: projectId, phase_id: phaseId });
  const [formError, setFormError] = useState<string>('');
  const [paymentSubmitting, setPaymentSubmitting] = useState<boolean>(false);
  const [disableCreateButton, setDisableCreateButton] = useState<boolean>(true);
  const [users, setUsers] = useState<any>([]);
  const [notFoundInfo, setNotFoundInfo] = useState<string[]>([]);

  const shouldDisableCreateButton = () => {
    if (!formValues?.category) return true;
    if (!formValues?.status) return true;
    return false;
  };

  useEffect(() => {
    setDisableCreateButton(shouldDisableCreateButton());
  }, [formValues]);

  const handleCreate = async () => {
    try {
      setPaymentSubmitting(true);
      await axios.post<string, any>(`payments`, {
        user_ids: users.map((user: any) => user.id),
        payment: { ...formValues },
      });
      history.push('/admin/payments');
    } catch {
      setPaymentSubmitting(false);
      setFormError('Something went wrong.');
    }
  };

  return (
    <Grid container style={{ padding: '1% 2%' }}>
      <AdminPageHeader title="Create Payments" />
      {users?.length > 0 ? (
        <Grid container>
          <Grid item xs={12} style={{ marginBottom: 15 }}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon style={{ fontSize: 24 }} />} style={{ paddingLeft: 10 }}>
                {notFoundInfo.length} Entries Not Found
              </AccordionSummary>
              <AccordionDetails>
                {notFoundInfo.map((info) => (
                  <p key={`not_found_${info}`}>{info}</p>
                ))}
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12} style={{ marginBottom: 15 }}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon style={{ fontSize: 24 }} />} style={{ paddingLeft: 10 }}>
                {users?.length} Recipients
              </AccordionSummary>
              <AccordionDetails>
                <DataTable columns={foundUserColumns} data={users} noHeader striped />
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12} style={{ marginBottom: 10 }}>
            <Button primary onClick={() => setUsers([])}>
              Change Recipients
            </Button>
          </Grid>
          <Grid item xs={12}>
            <PaymentForm formValues={formValues} setFormValues={setFormValues} />
          </Grid>
          <Grid item xs={12}>
            {formError && <p style={{ color: 'red', fontSize: '14px', marginTop: 20 }}>{formError}</p>}
            <Grid item xs={12} style={{ padding: '20px' }}>
              <Button primary onClick={handleCreate} loading={paymentSubmitting} disabled={disableCreateButton}>
                Create Payments
              </Button>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid item xs={12}>
          <InsighterLookup setUsers={setUsers} setNotFoundInfo={setNotFoundInfo} initialLookupValue={email} />
        </Grid>
      )}
    </Grid>
  );
};

export default NewPaymentContainer;
