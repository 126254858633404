import React from 'react';
import { useParams } from 'react-router-dom';
import { Button, Card, Filters } from '@shopify/polaris';
import { Grid } from '@material-ui/core';
import { InvitationPartnersContext } from '../contexts/InvitationPartnersContext';

const InvitationPartnersFilterBar: React.FunctionComponent = () => {
  const { invitationOpportunityId } = useParams<Record<string, string>>();

  const { fetchInvitationPartners, setFetchParams, filterValues, setFilterValues } =
    React.useContext(InvitationPartnersContext);

  const applyFilters = () => {
    const invitationOpportunityFilter = `ransack[invitation_opportunity_id_in]=${invitationOpportunityId}`;
    const queryValue = `ransack[query]=${filterValues.query}`;
    const fullFetchParams = `&${queryValue}&${invitationOpportunityFilter}`;

    setFetchParams(fullFetchParams);

    sessionStorage.setItem(`invitationPartnerFilters-${invitationOpportunityId}`, fullFetchParams);
    sessionStorage.setItem(`invitationPartnerFilterQuery-${invitationOpportunityId}`, filterValues.query);

    fetchInvitationPartners(fullFetchParams);
  };

  const clearFilters = () => {
    setFilterValues({ query: '' });
    setFetchParams('ransack[query]=');
    sessionStorage.removeItem(`invitationPartnerFilters-${invitationOpportunityId}`);
    sessionStorage.removeItem(`invitationPartnerFilterQuery-${invitationOpportunityId}`);
    fetchInvitationPartners(`ransack[invitation_opportunity_id_in]=${invitationOpportunityId}`);
  };

  const handleFiltersKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      applyFilters();
    }
  };

  return (
    <Card.Section>
      <div role="form" onKeyDown={handleFiltersKeyPress}>
        <Filters
          queryValue={filterValues.query}
          queryPlaceholder="Filter by name..."
          filters={[]}
          onQueryChange={(value) => setFilterValues({ ...filterValues, query: value })}
          onQueryClear={() => setFilterValues({ query: '' })}
          onClearAll={() => null}
        >
          <Grid container>
            <Grid item>
              <div style={{ paddingLeft: '8px' }}>
                <Button primary onClick={applyFilters}>
                  Apply Filter
                </Button>
              </div>
            </Grid>
            <Grid item>
              <div style={{ paddingLeft: '8px' }}>
                <Button onClick={clearFilters}>Clear Filter</Button>
              </div>
            </Grid>
          </Grid>
        </Filters>
      </div>
    </Card.Section>
  );
};

export default InvitationPartnersFilterBar;
