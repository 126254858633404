import React from 'react';
import { Button } from '@shopify/polaris';
import { IDataTableColumn } from '../../dataTable/DataTable/model';
import getStatusColor from './getStatusColor';

const interviewColumns: IDataTableColumn<any>[] = [
  {
    name: 'Project Number',
    selector: 'project_number',
    sortable: true,
  },
  {
    name: 'Phase',
    selector: 'phase_name',
    sortable: true,
  },
  {
    name: 'Respondent',
    selector: 'user_name',
    sortable: true,
    cell: (interview) => (
      <>
        {interview.insighter_id ? (
          <div>
            <a style={{ color: '#1D1D1D' }} href={`/admin/insighters/${interview.insighter_id}`}>
              {interview.user_name}
            </a>
          </div>
        ) : (
          <div>{interview.user_name}</div>
        )}
      </>
    ),
  },
  {
    name: 'Segment',
    selector: 'segment',
    sortable: false,
    cell: (interview) => (
      <>
        {/* eslint-disable-next-line */}
        <div dangerouslySetInnerHTML={{ __html: interview?.segment }} />
      </>
    ),
  },
  {
    name: 'Account Status',
    selector: 'account_status',
    sortable: false,
    cell: ({ account_status }) => (
      <div
        style={{
          fontWeight: 700,
          textTransform: 'uppercase',
          fontSize: 14,
          color: getStatusColor(account_status?.toLowerCase()),
        }}
      >
        {account_status}
      </div>
    ),
  },
  {
    name: 'Scheduled Time',
    selector: 'scheduled_time',
    sortable: true,
  },
  {
    name: 'Recruiter',
    selector: 'recruiter',
    sortable: true,
  },
  {
    name: 'Moderator',
    selector: 'moderator',
    sortable: true,
  },
  {
    name: 'Project Manager',
    selector: 'project_manager',
    sortable: true,
  },
  {
    name: 'Status',
    selector: 'status',
    sortable: true,
    cell: ({ status }) => (
      <div
        style={{
          fontWeight: 700,
          textTransform: 'uppercase',
          fontSize: 14,
          color: getStatusColor(status?.toLowerCase()),
        }}
      >
        {status}
      </div>
    ),
  },
  {
    name: 'Actions',
    selector: 'actions',
    sortable: false,
    grow: 2,
    cell: (row) => (
      <>
        {row.status !== 'complete' && (
          <Button primary url={`/admin/interviews/mark-complete/${row.id}`}>
            Mark Complete
          </Button>
        )}
      </>
    ),
  },
];

export default interviewColumns;
