import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';

import $ from 'jquery';
import 'jquery-ui-bundle';
import DataTable from '../../dataTable/DataTable/DataTable';
import { Project } from '../model';
import { Phase } from '../../projectPhases/model';
import { ProjectPhasesContext } from '../../projectPhases/contexts/ProjectPhasesContext';
import phaseColumns from '../../projectPhases/constants/phaseColumns';
import { UserContext } from '../../auth/contexts/UserContext';

interface ProjectPhasesProps {
  phases: Phase[];
  project: Project;
}

const ProjectPhases: React.FC<ProjectPhasesProps> = (props) => {
  let { project, phases } = props;
  let history = useHistory();
  let [showArchived, setShowArchived] = React.useState(false);
  let { sortPhases } = useContext(ProjectPhasesContext);
  let { currentUserHasRole } = useContext(UserContext);
  let [isSorting, setIsSorting] = useState(false);

  let toggleSorting = (): void => {
    if (isSorting) {
      sortPhases($('.rdt_TableBody:first').sortable('serialize'));
      $('.rdt_TableBody:first').sortable('destroy');
    } else {
      $('.rdt_TableBody:first').sortable();
    }

    setIsSorting(!isSorting);
  };

  return (
    <Grid container>
      <Card style={{ padding: '1% 2%' }}>
        <div style={{ marginBottom: 15 }}>
          <div className="Polaris-Stack Polaris-Stack--alignmentBaseline">
            <div className="Polaris-Stack__Item Polaris-Stack__Item--fill">
              <h2 className="Polaris-Heading">Project Phases</h2>
            </div>
            {currentUserHasRole('admin') && (
              <div className="Polaris-Stack__Item">
                <div className="Polaris-ButtonGroup">
                  <div className="Polaris-ButtonGroup__Item Polaris-ButtonGroup__Item--plain">
                    {phases?.length > 0 && (
                      <Button className={isSorting ? 'primary-btn' : 'secondary-btn'} onClick={() => toggleSorting()}>
                        {isSorting ? 'Save Order' : 'Sort Phases'}
                      </Button>
                    )}
                    <span style={{ marginRight: 7 }} />
                    <Button className="primary-btn" onClick={() => setShowArchived(true)}>
                      Show Archived Phases
                    </Button>
                    <span style={{ marginRight: 7 }} />
                    <Button
                      className="primary-btn"
                      onClick={() => history.push(`/admin/projects/${project.id}/phases/new`)}
                    >
                      Add New Phase
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <DataTable
          pointerOnHover
          onRowClicked={(row: Record<string, string>) => {
            history.push(`/admin/phases/${row.id}`);
          }}
          columns={phaseColumns}
          data={showArchived ? phases : phases.filter((phase) => phase.status !== 'archived')}
          striped
          highlightOnHover
          noHeader
        />
      </Card>
    </Grid>
  );
};

export default ProjectPhases;
