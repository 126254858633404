import React from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import USMap from '../components/USMap';
import MediumOrnament from '../../common/MediumOrnament';
import useStyles from '../../constants/redesignStyles';
import MapRectangle from '../../../../assets/redesignAssets/staticImages/MapRectangle.png';

const useCustomStyles = makeStyles((theme) => ({
  container: {
    backgroundImage: `url(${MapRectangle})`,
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    padding: '10%',
    [theme.breakpoints.down('md')]: {
      backgroundImage: 'none',
    },
  },
  textContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  heading: {
    fontWeight: 600,
    fontSize: '48px',
    [theme.breakpoints.down('md')]: {
      fontSize: '36px',
      textAlign: 'center',
    },
  },
  statesText: {
    fontWeight: 600,
    marginBottom: 20,
    '& span': {
      color: '#4D8A79',
    },
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
    },
  },
}));

const MapSection: React.FC = () => {
  const classes = useStyles();
  const customClasses = useCustomStyles();

  const [isCentered, setIsCentered] = React.useState<boolean>(false);

  React.useEffect(() => {
    const handleResize = () => {
      setIsCentered(window.innerWidth < 960); // Set to true for screens smaller than 960px
    };

    window.addEventListener('resize', handleResize);

    // Call the handler on mount to set the initial value
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Grid container className={customClasses.container}>
      <Grid item xs={12} md={5}>
        <Grid container className={customClasses.textContainer}>
          <Grid item xs={12} md={8}>
            <MediumOrnament text="The Insighters Coverage" width="250px" centerComponent={isCentered} centerText />
          </Grid>
          <Grid item xs={8} style={{ marginTop: 10 }}>
            <Typography className={`${classes.h2} ${customClasses.heading}`}>Explore Our</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography className={`${classes.h2} ${customClasses.heading}`}>Nationwide</Typography>
          </Grid>
          <Grid item xs={8} style={{ marginBottom: 20 }}>
            <Typography className={`${classes.h2} ${customClasses.heading}`}>Network</Typography>
          </Grid>
          <Grid item xs={8}>
            <p className={customClasses.statesText}>
              All <span> 50 </span> states represented
            </p>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} md={7}>
        <USMap />
      </Grid>
    </Grid>
  );
};

export default MapSection;
