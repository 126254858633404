import React, { useState, useEffect, useContext } from 'react';
import Button from '@material-ui/core/Button';
import { Checkbox, FormControl, FormControlLabel, FormGroup, Grid, Typography, makeStyles } from '@material-ui/core';
import FormInput from '../../form/components/FormInput';
import SelectInput from '../../form/components/SelectInput';
import DeleteInternalUserConfirmModal from './DeleteInternalUserConfirmModal';

import { InternalUsersContext } from '../contexts/InternalUsersContext';
import { InternalUser, InternalUserFormValues } from '../model';

interface InternalUserFormProps {
  internalUser?: InternalUser;
  afterCreate?: () => void;
}

const useStyles = makeStyles({
  root: {
    display: 'inline-block',
  },
  checkboxLabel: {
    textTransform: 'capitalize',
    fontWeight: 700,
    fontSize: '18px',
  },
  roleSubtitle: {
    fontSize: '14px',
    fontWeight: 500,
    marginBottom: '6px',
  },
  roleDescription: {
    fontSize: '14px',
    marginBottom: '12px',
  },
});

const InternalUserForm: React.FC<InternalUserFormProps> = (props) => {
  let { internalUser, afterCreate } = props;
  let classes = useStyles();
  let { updateInternalUser, createInternalUser, formError } = useContext(InternalUsersContext);

  let [formValues, setFormValues] = useState<InternalUserFormValues>({});
  let [deleteInternalUserModalOpen, setDeleteInternalUserModalOpen] = useState<boolean>(false);

  useEffect(() => {
    setFormValues({
      email: internalUser.email,
      first_name: internalUser.first_name,
      last_name: internalUser.last_name,
      timezone: internalUser.timezone,
      role_ids: internalUser.role_ids,
    });
  }, [internalUser]);

  const handleUpdate = () => {
    updateInternalUser(internalUser.id, formValues);
  };

  const handleCreate = () => {
    if (afterCreate) {
      createInternalUser(formValues, afterCreate);
    } else {
      createInternalUser(formValues);
    }
  };

  const handleRoleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let options = [];

    if (formValues?.role_ids) {
      options = formValues?.role_ids;
    }

    if (event.target.checked) {
      options = [...options, event.target.value.toString()];
    } else {
      const index = options.indexOf(event.target.value);
      options.splice(index, 1);
    }

    setFormValues({ ...formValues, role_ids: options });
  };

  return (
    <Grid container spacing={3}>
      <DeleteInternalUserConfirmModal
        userId={internalUser.id}
        deleteInternalUserModalOpen={deleteInternalUserModalOpen}
        setDeleteInternalUserModalOpen={setDeleteInternalUserModalOpen}
      />
      <Grid item xs={12}>
        <h2 className="Polaris-Heading">Account Information</h2>
      </Grid>
      <Grid item xs={12}>
        <FormInput
          label="Email"
          value={formValues.email}
          onChange={(value) => setFormValues({ ...formValues, email: value })}
        />
      </Grid>
      <Grid item xs={12}>
        <SelectInput
          label="Timezone"
          choices={[
            { label: 'America/New_York', value: 'America/New_York' },
            { label: 'America/Chicago', value: 'America/Chicago' },
            { label: 'America/Denver', value: 'America/Denver' },
            { label: 'America/Boise', value: 'America/Boise' },
            { label: 'America/Phoenix', value: 'America/Phoenix' },
            { label: 'America/Los_Angeles', value: 'America/Los_Angeles' },
            { label: 'Pacific/Honolulu', value: 'Pacific/Honolulu' },
          ]}
          value={formValues.timezone}
          onChange={(value) => setFormValues({ ...formValues, timezone: value })}
        />
      </Grid>
      <Grid item xs={6}>
        <FormInput
          label="First Name"
          value={formValues.first_name}
          onChange={(value) => setFormValues({ ...formValues, first_name: value })}
        />
      </Grid>
      <Grid item xs={6}>
        <FormInput
          label="Last Name"
          value={formValues.last_name}
          onChange={(value) => setFormValues({ ...formValues, last_name: value })}
        />
      </Grid>
      {formValues.role_ids?.length >= 0 && (
        <>
          <Grid item xs={12}>
            <h2 className="Polaris-Heading">Permissions</h2>
          </Grid>
          <Grid item xs={12}>
            <FormControl component="fieldset">
              <FormGroup>
                <>
                  <FormControlLabel
                    classes={{ label: classes.checkboxLabel }}
                    checked={formValues.role_ids?.includes('1')}
                    control={
                      <Checkbox onChange={handleRoleChange} name="Read Only" value="1" style={{ color: 'blue' }} />
                    }
                    label="Read Only"
                  />
                  <div style={{ marginLeft: '25px' }}>
                    <Typography className={classes.roleSubtitle}>CAN READ</Typography>
                    <Typography className={classes.roleDescription}>
                      All Projects, All Opportunites, All Interviews, All Payments, All Internal Users
                    </Typography>
                  </div>
                  <hr />
                </>
              </FormGroup>
              <FormGroup>
                <>
                  <FormControlLabel
                    classes={{ label: classes.checkboxLabel }}
                    checked={formValues.role_ids?.includes('2')}
                    control={
                      <Checkbox onChange={handleRoleChange} name="Superadmin" value="2" style={{ color: 'blue' }} />
                    }
                    label="Superadmin"
                  />
                  <div style={{ marginLeft: '25px' }}>
                    <Typography className={classes.roleSubtitle}>CAN READ / CREATE / UPDATE / DELETE</Typography>
                    <Typography className={classes.roleDescription}>
                      All Projects, All Opportunites, All Interviews, All Payments, All Internal Users
                    </Typography>
                  </div>
                  <div style={{ marginLeft: '25px' }}>
                    <Typography className={classes.roleSubtitle}>CAN MANAGE</Typography>
                    <Typography className={classes.roleDescription}>Other User Permissions</Typography>
                  </div>
                  <hr />
                </>
              </FormGroup>
              <FormGroup>
                <>
                  <FormControlLabel
                    classes={{ label: classes.checkboxLabel }}
                    checked={formValues.role_ids?.includes('3')}
                    control={<Checkbox onChange={handleRoleChange} name="Admin" value="3" style={{ color: 'blue' }} />}
                    label="Admin"
                  />
                  <div style={{ marginLeft: '25px' }}>
                    <Typography className={classes.roleSubtitle}>CAN READ / CREATE / UPDATE</Typography>
                    <Typography className={classes.roleDescription}>All Projects, All Interviews</Typography>
                  </div>
                  <div style={{ marginLeft: '25px' }}>
                    <Typography className={classes.roleSubtitle}>CAN READ</Typography>
                    <Typography className={classes.roleDescription}>All Payments, All Opporunities</Typography>
                  </div>
                  <div style={{ marginLeft: '25px' }}>
                    <Typography className={classes.roleSubtitle}>CANNOT MANAGE</Typography>
                    <Typography className={classes.roleDescription}>Other User Permissions</Typography>
                  </div>
                  <hr />
                </>
              </FormGroup>
              <FormGroup>
                <>
                  <FormControlLabel
                    classes={{ label: classes.checkboxLabel }}
                    checked={formValues.role_ids?.includes('4')}
                    control={
                      <Checkbox
                        onChange={handleRoleChange}
                        name="Platform Manager"
                        value="4"
                        style={{ color: 'blue' }}
                      />
                    }
                    label="Platform Manager"
                  />
                  <div style={{ marginLeft: '25px' }}>
                    <Typography className={classes.roleSubtitle}>CAN READ / CREATE / UPDATE</Typography>
                    <Typography className={classes.roleDescription}>
                      All Projects, All Opportunites, All Interviews
                    </Typography>
                  </div>
                  <div style={{ marginLeft: '25px' }}>
                    <Typography className={classes.roleSubtitle}>CAN READ</Typography>
                    <Typography className={classes.roleDescription}>All Payments, All Insighters</Typography>
                  </div>
                  <hr />
                </>
              </FormGroup>
              <FormGroup>
                <>
                  <FormControlLabel
                    classes={{ label: classes.checkboxLabel }}
                    checked={formValues.role_ids?.includes('5')}
                    control={
                      <Checkbox onChange={handleRoleChange} name="Recruiter" value="5" style={{ color: 'blue' }} />
                    }
                    label="recruiter"
                  />
                  <div style={{ marginLeft: '25px' }}>
                    <Typography className={classes.roleSubtitle}>CAN READ / CREATE / UPDATE</Typography>
                    <Typography className={classes.roleDescription}>All Insighters, All Interviews</Typography>
                  </div>
                  <div style={{ marginLeft: '25px' }}>
                    <Typography className={classes.roleSubtitle}>CAN READ</Typography>
                    <Typography className={classes.roleDescription}>
                      All Projects, All Opportunities, All Payments
                    </Typography>
                  </div>
                  <hr />
                </>
              </FormGroup>
              <FormGroup>
                <>
                  <FormControlLabel
                    classes={{ label: classes.checkboxLabel }}
                    checked={formValues.role_ids?.includes('6')}
                    control={
                      <Checkbox onChange={handleRoleChange} name="Moderator" value="6" style={{ color: 'blue' }} />
                    }
                    label="Project Manager / Moderator"
                  />
                  <div style={{ marginLeft: '25px' }}>
                    <Typography className={classes.roleSubtitle}>CAN READ / CREATE / UPDATE</Typography>
                    <Typography className={classes.roleDescription}>All Projects</Typography>
                  </div>
                  <div style={{ marginLeft: '25px' }}>
                    <Typography className={classes.roleSubtitle}>CAN READ</Typography>
                    <Typography className={classes.roleDescription}>
                      All Interviews, All Opportunities, All Payments
                    </Typography>
                  </div>
                  <hr />
                </>
              </FormGroup>
            </FormControl>
          </Grid>
        </>
      )}

      {formError && <p style={{ marginBottom: '8px', color: 'red' }}>{formError}</p>}
      {internalUser.id ? (
        <Grid container>
          <Grid item xs={2}>
            <Button onClick={handleUpdate} className="primary-btn">
              Update Account
            </Button>
          </Grid>
          <Grid item xs={2}>
            <Button onClick={() => setDeleteInternalUserModalOpen(true)} className="destructive-btn">
              Delete Account
            </Button>
          </Grid>
        </Grid>
      ) : (
        <Button onClick={handleCreate} className="primary-btn">
          Create Account
        </Button>
      )}
    </Grid>
  );
};

export default InternalUserForm;
