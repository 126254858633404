import React from 'react';

interface ModalButtonProps {
  text?: string;
  onClick?: () => void;
  theme?: string;
  loading?: boolean;
}

const ModalButton: React.FC<ModalButtonProps> = (props) => {
  const { text, onClick, theme = 'primary', loading } = props;

  const buttonStyles = () => {
    if (theme === 'primary') {
      return {
        backgroundColor: 'rgb(0, 128, 96)',
        color: '#fff',
        borderRadius: 4,
      };
    }

    return {
      backgroundColor: 'rgb(255, 255, 255)',
      border: '1px solid #333',
      color: '#333',
      borderRadius: 4,
    };
  };

  return (
    <button
      className={`Polaris-Button ${theme === 'primary' ? 'Polaris-Button--primary' : ''}`}
      type="button"
      style={buttonStyles()}
      onClick={onClick}
      disabled={loading}
    >
      <span className="Polaris-Button__Content">
        <span className="Polaris-Button__Text">{text}</span>
      </span>
    </button>
  );
};

export default ModalButton;
