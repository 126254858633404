import React, { useCallback, useState } from 'react';
import { Loading, Toast } from '@shopify/polaris';
import { format } from 'date-fns';
import DataTable from '../../dataTable/DataTable/DataTable';
import { IDataTableColumn } from '../../dataTable/DataTable/model';
import { InterviewsContext } from '../contexts/InterviewsContext';

const getStatusColor = (status: string) => {
  if (!status) {
    return '';
  }

  if (status.toLowerCase() === 'do not reschedule' || status.toLowerCase() === 'canceled') {
    return 'red';
  }

  if (
    status.toLowerCase() === 'past due' ||
    status.toLowerCase() === 'rescheduling needed' ||
    status.toLowerCase() === 'no show'
  ) {
    return 'orange';
  }

  if (status.toLowerCase() === 'complete') {
    return 'green';
  }

  return '#4ba9c8';
};

const InterviewsList: React.FunctionComponent = () => {
  const {
    fetchInterviews,
    interviews,
    interviewLoading,
    filterValues,
    setFilterValues,
    statusFilters,
    selectedDates,
    totalRecords,
  } = React.useContext(InterviewsContext);

  const [interviewsFetched, setInterviewsFetched] = React.useState(false);

  const [dateFilters] = React.useState({
    start: new Date(new Date().getTime() - 14 * 24 * 60 * 60 * 1000),
    end: new Date(new Date().getTime() + 365 * 24 * 60 * 60 * 1000),
  });

  React.useEffect(() => {
    if (!interviewsFetched) {
      setInterviewsFetched(true);
      fetchInterviews(`ransack[status_eq]=upcoming&sort=scheduled_time asc`);
    }
  }, [interviewsFetched, setInterviewsFetched, fetchInterviews, dateFilters]);

  const [toastActive, setToastActive] = useState(false);
  const loadingMarkup = interviewLoading ? <Loading /> : null;

  const toggleToastActive = useCallback(() => setToastActive((toastActive) => !toastActive), []);

  const toastMarkup = toastActive ? <Toast onDismiss={toggleToastActive} content="Changes saved" /> : null;

  const handleColumnSort = async (column: Record<string, string>, sortDirection: string) => {
    const columnNamesToRansack = {
      project_number: 'project_project_number',
      phase_name: 'phase_name',
      user_name: 'user_last_name',
      scheduled_time: 'scheduled_time',
      recruiter: 'recruiter_last_name',
      project_manager: 'project_manager_last_name',
      primary_moderator: 'project_primary_moderator_last_name',
      secondary_moderator: 'project_secondary_moderator_last_name',
      status: 'status',
    };

    const queryFilter = `ransack[query]=${filterValues.query}`;
    const statusFilter = `ransack[status_eq]=${statusFilters[0]}`;
    const startDateFilter = `ransack[scheduled_time_gteq]=${format(selectedDates?.start, 'yyyy-MM-dd')}`;
    const endDateFilter = `ransack[scheduled_time_lteq]=${format(selectedDates?.end, 'yyyy-MM-dd')}`;

    setFilterValues({ ...filterValues, sort: `${columnNamesToRansack[column.selector]} ${sortDirection}` });

    const sort = `sort=${columnNamesToRansack[column.selector]} ${sortDirection}`;

    fetchInterviews(`${queryFilter}&${statusFilter}&${startDateFilter}&${endDateFilter}&${sort}`);
  };

  const handlePageChange = (page: number) => {
    fetchInterviews(
      `page=${page}&ransack[query]=${filterValues.query}&ransack[status_eq]=${
        statusFilters[0]
      }&ransack[scheduled_time_gteq]=${format(
        selectedDates?.start,
        'yyyy-MM-dd',
      )}&ransack[scheduled_time_lteq]=${format(selectedDates?.end, 'yyyy-MM-dd')}&sort=${filterValues.sort}`,
    );
  };

  const columns: IDataTableColumn<any>[] = [
    {
      name: 'Project Name',
      selector: 'project_name',
      sortable: true,
    },
    {
      name: 'Project Number',
      selector: 'project_number',
      sortable: true,
    },
    {
      name: 'Scheduled Time',
      selector: 'scheduled_time',
      sortable: true,
    },
    {
      name: 'Respondent',
      selector: 'user_name',
      sortable: true,
      cell: (row) => (
        <>
          {row.insighter_id ? (
            <div>
              <a style={{ color: '#1D1D1D' }} href={`/admin/insighters/${row.insighter_id}`}>
                {row.user_name}
              </a>
            </div>
          ) : (
            <div>{row.user_name}</div>
          )}
        </>
      ),
    },
    {
      name: 'Segment',
      selector: 'segment',
      sortable: false,
    },
    {
      name: 'Project Manager',
      selector: 'project_manager',
      sortable: true,
    },
    {
      name: 'Recruiter',
      selector: 'recruiter',
      sortable: true,
    },
    {
      name: 'Primary Moderator',
      selector: 'primary_moderator',
      sortable: true,
    },
    {
      name: 'Secondary Moderator',
      selector: 'secondary_moderator',
      sortable: true,
    },
    {
      name: 'Status',
      selector: 'status',
      sortable: true,
      cell: ({ status }) => (
        <div
          style={{
            fontWeight: 700,
            textTransform: 'uppercase',
            fontSize: 14,
            color: getStatusColor(status?.toLowerCase()),
          }}
        >
          {status}
        </div>
      ),
    },
  ];

  const pageMarkup = (
    <DataTable
      columns={columns}
      data={interviews}
      progressPending={interviewLoading}
      striped
      highlightOnHover
      pointerOnHover
      pagination
      paginationPerPage={25}
      paginationRowsPerPageOptions={[25]}
      paginationServer
      paginationTotalRows={totalRecords}
      onChangePage={handlePageChange}
      noHeader
      sortServer
      onSort={handleColumnSort}
    />
  );

  return (
    <>
      {loadingMarkup}
      {pageMarkup}
      {toastMarkup}
    </>
  );
};

export default InterviewsList;
