import React, { useState, Dispatch, SetStateAction } from 'react';
import { useHistory } from 'react-router';
import { Grid, Button, InputAdornment, TextField, makeStyles } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import FilterBarPaintSmear from '../assets/FilterBarPaintSmear.png';

const filterLinks = [
  { label: 'All', category: 'all' },
  { label: 'Blogs', category: 'blog_post' },
  { label: 'Events', category: 'event' },
  { label: 'Case Studies', category: 'case_study' },
];

const useStyles = makeStyles((theme) => ({
  root: {
    float: 'right',
    '& .MuiFilledInput-root': {
      borderRadius: '20px',
      backgroundColor: '#407667',
      fontWeight: 700,
      fontSize: 14,
      color: 'white',
      '&:before': {
        borderBottom: 'none',
      },
      '&:after': {
        borderBottom: 'none',
      },
      '&:hover:not(.Mui-disabled):before': {
        borderBottom: 'none',
      },
    },
  },
}));

const buttonStyles = {
  backgroundColor: '#F3C66B',
};

const textStyle = (isSelected) => ({
  color: isSelected ? 'black' : 'white',
  fontSize: 14,
  fontFamily: 'Rethink Sans, sans-serif',
  textTransform: 'capitalize' as const,
  fontWeight: 700,
});

interface HomepageResourcesFilterBarProps {
  fetchGalleryResources: (category?: string, query?: string) => void;
  selectedCategory: string;
  setSelectedCategory: Dispatch<SetStateAction<string | undefined>>;
}

const HomepageResourcesFilterBar: React.FC<HomepageResourcesFilterBarProps> = (props) => {
  const history = useHistory();
  const { fetchGalleryResources, selectedCategory, setSelectedCategory } = props;
  const classes = useStyles();

  const [searchQuery, setSearchQuery] = useState<string>('');

  const handleSearchIconClicked = () => {
    fetchGalleryResources(selectedCategory, searchQuery);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      fetchGalleryResources(selectedCategory, searchQuery);
    }
  };

  return (
    <div
      style={{
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        backgroundImage: `url(${FilterBarPaintSmear})`,
        backgroundPosition: 'center center',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        height: 80,
        maxWidth: 1200,
        margin: 'auto',
      }}
      onKeyDown={handleKeyPress}
      role="button"
      tabIndex={0}
    >
      <Grid
        container
        spacing={2}
        style={{
          display: 'flex',
          justifyContent: 'space-around',
          position: 'relative',
          paddingLeft: '5%',
          paddingRight: '5%',
        }}
      >
        {window.innerWidth >= 960 &&
          filterLinks.map((filterLink) => (
            <Grid
              item
              key={filterLink.label}
              style={{
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Button
                style={selectedCategory === filterLink.category ? buttonStyles : {}}
                onClick={() => history.push(`/resources/${filterLink.category}`)}
              >
                <p style={textStyle(selectedCategory === filterLink.category)}>{filterLink.label}</p>
              </Button>
            </Grid>
          ))}
        <Grid item xs={1} />
        {window.innerWidth >= 960 && (
          <Grid item xs={3}>
            <TextField
              hiddenLabel
              id="filled-hidden-label-small"
              defaultValue=""
              variant="filled"
              size="small"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className={classes.root}
              InputProps={{
                disableUnderline: true,
                endAdornment: (
                  <InputAdornment position="end" onClick={handleSearchIconClicked} style={{ cursor: 'pointer' }}>
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default HomepageResourcesFilterBar;
