import React from 'react';
import { Button, Card, ChoiceList, Filters } from '@shopify/polaris';
import { Grid } from '@material-ui/core';
import { InvitationsContext } from '../contexts/InvitationsContext';

const InvitationFilterBar: React.FunctionComponent = () => {
  const [filterValues, setFilterValues] = React.useState<Record<string, string>>({ query: '' });
  const { statusFilter, setStatusFilter } = React.useContext(InvitationsContext);
  const [appliedFiltersDisplay, setAppliedFiltersDisplay] = React.useState<any[]>();

  React.useEffect(() => {
    const appliedFiltersArray = [];

    if (statusFilter) {
      appliedFiltersArray.push({
        key: 'status',
        label: `Filtered by ${statusFilter}`,
        onRemove: () => {
          setStatusFilter('');
          setFilterValues({ ...filterValues, status: null });
        },
      });
    }

    setAppliedFiltersDisplay([...appliedFiltersArray]);
  }, [statusFilter, setStatusFilter, filterValues]);

  const applyFilters = () => {
    // const queryFilter = `ransack[project_number_cont]=${filterValues.query}`;
    // const statusFilter = filterValues.status ? `ransack[status_eq]=${filterValues.status}` : '';
  };

  const clearFilters = () => {
    setFilterValues({ query: '', status: '' });
  };

  const handleFiltersKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      applyFilters();
    }
  };

  return (
    <Card.Section>
      <div role="form" onKeyDown={handleFiltersKeyPress}>
        <Filters
          queryValue={filterValues.query}
          queryPlaceholder="Filter by inviter, invitee, project name, or phase name..."
          filters={[
            {
              key: 'status',
              label: 'Status',
              filter: (
                <ChoiceList
                  title="Status"
                  titleHidden
                  choices={[
                    { label: 'Active', value: 'active' },
                    { label: 'Inactive', value: 'inactive' },
                  ]}
                  selected={[filterValues.status]}
                  onChange={(value) => setFilterValues({ ...filterValues, status: value[0] })}
                />
              ),
              shortcut: true,
            },
          ]}
          appliedFilters={appliedFiltersDisplay}
          onQueryChange={(value) => setFilterValues({ ...filterValues, query: value })}
          onQueryClear={() => setFilterValues({ query: '' })}
          onClearAll={() => null}
        >
          <Grid container>
            <Grid item>
              <div style={{ paddingLeft: '8px' }}>
                <Button primary onClick={applyFilters}>
                  Apply Filters
                </Button>
              </div>
            </Grid>
            <Grid item>
              <div style={{ paddingLeft: '8px' }}>
                <Button onClick={clearFilters}>Clear Filters</Button>
              </div>
            </Grid>
          </Grid>
        </Filters>
      </div>
    </Card.Section>
  );
};

export default InvitationFilterBar;
