import React from 'react';
import { Grid } from '@material-ui/core';
import { FormLayout } from '@shopify/polaris';
import FormInput from '../../form/components/FormInput';
import SelectInput from '../../form/components/SelectInput';
import { LeadGroupsContext } from '../contexts/LeadGroupsContext';
import { InsighterFinderContext } from '../../insighterFinder/contexts/InsighterFinderContext';
import { LeadGroup, LeadGroupFormValues } from '../model';
import ModalButton from '../../modal/components/ModalButton';

interface LeadGroupFormProps {
  leadGroup?: LeadGroup;
  phaseId?: string;
  afterAction?: () => void;
}

const LeadGroupForm: React.FC<LeadGroupFormProps> = (props) => {
  const { leadGroup, phaseId, afterAction } = props;
  const { createLeadGroup, updateLeadGroup, leadGroupSubmitting } = React.useContext(LeadGroupsContext);
  const { phaseOptions } = React.useContext(InsighterFinderContext);

  const [formValues, setFormValues] = React.useState<LeadGroupFormValues>({});

  React.useEffect(() => {
    setFormValues({
      phase_id: phaseId || leadGroup?.phase_id,
      name: leadGroup?.name,
      notes: leadGroup?.notes,
    });
  }, [leadGroup, phaseId]);

  const handleUpdate = () => {
    if (afterAction) {
      updateLeadGroup(leadGroup.id, formValues, afterAction);
    } else {
      updateLeadGroup(leadGroup.id, formValues);
    }
  };

  const handleCreate = () => {
    if (afterAction) {
      createLeadGroup(formValues, afterAction);
    } else {
      createLeadGroup(formValues);
    }
  };

  return (
    <FormLayout>
      <h1 className="Polaris-Heading">New Lead Group</h1>
      <Grid container spacing={3}>
        {phaseOptions?.phase_options?.length > 0 && (
          <Grid item xs={12}>
            <SelectInput
              label="Phase"
              value={formValues.phase_id}
              choices={phaseOptions?.phase_options}
              onChange={(value) => setFormValues({ ...formValues, name: value })}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <FormInput
            label="Name"
            value={formValues.name}
            onChange={(value) => setFormValues({ ...formValues, name: value })}
          />
        </Grid>
        <Grid item xs={12}>
          <FormInput
            label="Notes"
            value={formValues.notes}
            multiline
            rows={10}
            onChange={(value) => setFormValues({ ...formValues, notes: value })}
          />
        </Grid>
      </Grid>
      <>
        {leadGroup?.id ? (
          <ModalButton theme="primary" onClick={handleUpdate} text="Update Lead Group" loading={leadGroupSubmitting} />
        ) : (
          <ModalButton theme="primary" onClick={handleCreate} text="Create Lead Group" loading={leadGroupSubmitting} />
        )}
      </>
    </FormLayout>
  );
};

export default LeadGroupForm;
