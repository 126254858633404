import React, { useState, useEffect, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Grid, Card, Typography } from '@mui/material';
import { EthnicitiesContext } from '../contexts/EthnicitiesContext';
import BackButton from '../../navigation/components/BackButton';
import ColumnSelector from '../../shared/components/ColumnSelector';
import DataTable from '../../dataTable/DataTable/DataTable';
import { Insighter } from '../../insighters/model';
import insighterPossibleColumns from '../../shared/constants/insighterPossibleColumns';
import PrepareDownloadButton from '../../shared/components/PrepareDownloadButton';

const EthnicityDetail: React.FunctionComponent = () => {
  const history = useHistory();
  const { ethnicityId } = useParams<Record<string, string>>();
  const [ethnicityFetched, setEthnciityFetched] = React.useState(false);
  const { ethnicity, fetchEthnicity, ethnicitiesLoading } = React.useContext(EthnicitiesContext);
  const [insighters, setInsighters] = useState<Insighter[]>([]);

  const [visibleColumns, setVisibleColumns] = useState([]);

  useEffect(() => {
    if (!ethnicityFetched) {
      fetchEthnicity(ethnicityId);
      setEthnciityFetched(true);
    }
  }, [ethnicityFetched, fetchEthnicity, ethnicityId]);

  useEffect(() => {
    if (ethnicity?.insighters) {
      setInsighters(ethnicity.insighters);
    }
  }, [ethnicity]);

  return (
    <>
      <Grid style={{ display: 'flex', justifyContent: 'space-between' }}>
        <BackButton pageAction={false} />
        <PrepareDownloadButton
          filename={`Insighters with Ethnicity ${ethnicity?.name}.csv`}
          visibleColumns={visibleColumns}
          link={`/ethnicities/${ethnicityId}/download`}
          includeResult
        />
      </Grid>
      <Grid style={{ margin: 10 }}>
        <Typography className="title" variant="h3">
          All Insighters with {ethnicity.name}
        </Typography>
        <br />
        <ColumnSelector setVisibleColumns={setVisibleColumns} columnChoices={insighterPossibleColumns} />
        <br />
        <Card>
          <DataTable
            onRowClicked={(row: Record<string, string>) => history.push(`/admin/insighters/${row.id}`)}
            columns={visibleColumns}
            data={insighters}
            pagination
            progressPending={ethnicitiesLoading}
            striped
            highlightOnHover
            pointerOnHover
            noHeader
            // paginationServer
            paginationPerPage={25}
            paginationRowsPerPageOptions={[25]}
            paginationTotalRows={insighters?.length}
            // onChangePage={handlePageChange}
          />
        </Card>
      </Grid>
    </>
  );
};

export default EthnicityDetail;
