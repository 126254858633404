import React, { useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { NavigateBefore, NavigateNext } from '@material-ui/icons';
import useStyles from '../constants/redesignStyles';
import HoverButton from './HoverButton';
import { Opportunity } from '../../opportunities/model';

interface OpportunityScrollerProps {
  hoverButtonText: string;
  hoverButtonLink: string;
  opportunities: Opportunity[];
}

const OpportunityScroller: React.FC<OpportunityScrollerProps> = (props) => {
  const classes = useStyles();

  const { hoverButtonText, hoverButtonLink, opportunities } = props;

  const [imageIndex, setImageIndex] = useState(0);

  const handlePrevImage = () => {
    setImageIndex((prevIndex) => (prevIndex === 0 ? opportunities.length - 1 : prevIndex - 1));
  };

  const handleNextImage = () => {
    setImageIndex((prevIndex) => (prevIndex === opportunities.length - 1 ? 0 : prevIndex + 1));
  };

  let bottomPosition = window.innerWidth <= 960 ? '15px' : '0px';

  return (
    <Grid container className={classes.opportunityScroller}>
      <Grid
        item
        xs={12}
        style={{
          position: 'relative',
          width: '500px',
          height: '274px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-end',
          borderRadius: 30,
          overflow: 'hidden',
          backgroundImage: `url(${opportunities[imageIndex]?.thumbnail_image_url})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}
      >
        {/* Gradient Div */}
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: 'linear-gradient(0deg, rgba(84,93,120,0.8) 20%, rgba(59,66,85,0) 35%)',
            borderRadius: 30,
            zIndex: 1,
          }}
        />

        {/* Buttons and Text */}
        <Grid
          container
          style={{
            height: '60px',
            alignItems: 'center',
            justifyContent: 'space-between',
            margin: '0 10px',
            position: 'relative', // Ensure relative positioning
            zIndex: 2, // Bring above the gradient
          }}
        >
          <Grid item xs={8} md={10}>
            <a href={`/opportunities/${opportunities[imageIndex]?.id}`} style={{ textDecoration: 'none' }}>
              <Typography
                style={{
                  fontSize: '18px',
                  color: 'white',
                  fontFamily: 'Crimson Text',
                  fontWeight: 600,
                  lineHeight: '1em',
                  zIndex: 2,
                  textDecoration: 'none',
                  bottom: bottomPosition,
                  position: 'relative',
                }}
              >
                {opportunities[imageIndex]?.name}
              </Typography>
            </a>
          </Grid>
          <Grid item xs={4} md={2}>
            <Grid className={classes.arrowButtons} style={{ zIndex: 3 }}>
              <button
                type="button"
                onClick={handlePrevImage}
                style={{ border: '5px solid #EFEFEF', borderRadius: 4, marginRight: 2 }}
              >
                <NavigateBefore />
              </button>
              <button type="button" onClick={handleNextImage} style={{ border: '5px solid #EFEFEF', borderRadius: 4 }}>
                <NavigateNext />
              </button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid style={{ display: 'flex', justifyContent: 'space-between', marginTop: 10 }}>
        <Grid item xs={9} md={10}>
          <HoverButton text={hoverButtonText} link={hoverButtonLink} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OpportunityScroller;
