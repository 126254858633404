import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { Button } from '@shopify/polaris';
import { ProjectPhasesContext } from '../../projectPhases/contexts/ProjectPhasesContext';
import { RespondentsContext } from '../contexts/RespondentsContext';
import { PhaseRespondentsContext } from '../../projectPhases/contexts/PhaseRespondentsContext';
import SelectInput from '../../form/components/SelectInput';
import FormInput from '../../form/components/FormInput';
import { Respondent, RespondentFormValues } from '../model';
import axios from '../../utils/axios.utils';
import InformationField from '../../form/components/InformationField';

type CopyToOtherPhaseFormProps = {
  respondent: Respondent;
};

const CopyToOtherPhaseForm: React.FC<CopyToOtherPhaseFormProps> = (props) => {
  const { respondent } = props;
  const { phase } = React.useContext(ProjectPhasesContext);
  const [projectOptions, setProjectOptions] = React.useState();
  const [phaseOptions, setPhaseOptions] = React.useState();

  const { copyRespondentToPhase } = React.useContext(RespondentsContext);
  const { fetchRespondents } = React.useContext(PhaseRespondentsContext);
  const [formValues, setFormValues] = useState<RespondentFormValues>({});
  const [respondentSubmitting, setRespondentSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    setFormValues({
      first_name: respondent?.first_name,
      last_name: respondent?.last_name,
      email: respondent?.email,
      project_id: respondent?.project_id,
      qualtrics_status: respondent?.original_qualtrics_status,
      segment: respondent?.segment,
      homework_status: respondent?.homework_status,
      applied_at: respondent?.applied_at,
      copied_from_id: phase.id,
    });

    axios.get<string, any>(`invitation_opportunities/new.json`).then((response) => {
      setProjectOptions(response?.data?.project_options);

      axios.get<string, any>(`invitation_opportunities/new?project_id=${respondent?.project_id}`).then((response) => {
        setPhaseOptions(response?.data?.phase_options);
      });
    });
  }, [respondent, phase]);

  useEffect(() => {
    if (respondent?.copied_to_id) {
      setErrorMessage('This respondent has already been copied');
    } else if (!formValues?.phase_id) {
      setErrorMessage('Phase is a Required Field');
    } else if (parseInt(formValues?.phase_id, 10) === parseInt(respondent?.phase_id, 10)) {
      setErrorMessage('Cannot copy respondent to the phase it already belongs to.');
    } else {
      setErrorMessage('');
    }
  }, [formValues, respondent]);

  const handleProjectChange = async (value: string) => {
    setFormValues({ ...formValues, project_id: value });
    await axios.get<string, any>(`invitation_opportunities/new?project_id=${value}`).then((response) => {
      setPhaseOptions(response?.data?.phase_options);
    });
  };

  const handleCopy = () => {
    setRespondentSubmitting(true);

    const afterCreate = () => {
      fetchRespondents(`phase_id=${phase.id}`);
    };

    copyRespondentToPhase(respondent.id, formValues, afterCreate);
  };

  return (
    <div style={{ marginTop: -40 }}>
      <Grid container spacing={4}>
        <Grid item>
          <InformationField label="Respondent Name" value={respondent.name} />
        </Grid>
        <Grid item>
          <InformationField label="User ID" value={respondent.uuid} />
        </Grid>
        <Grid item>
          <InformationField label="Current Phase" value={phase.name} />
        </Grid>
      </Grid>
      <br />
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <SelectInput
            label="Project"
            value={formValues?.project_id}
            choices={projectOptions}
            onChange={handleProjectChange}
          />
        </Grid>
        <Grid item xs={6}>
          <SelectInput
            label="Phase"
            choices={phaseOptions}
            value={formValues?.phase_id}
            onChange={(value) => setFormValues({ ...formValues, phase_id: value })}
          />
        </Grid>
        <Grid item xs={6}>
          <FormInput
            label="Original Qualtrics Status"
            value={formValues?.qualtrics_status}
            onChange={(value) => null}
            disabled
          />
        </Grid>
        <Grid item xs={6}>
          <FormInput label="Interview Status" value="No Interview" onChange={(value) => null} disabled />
        </Grid>
      </Grid>

      {!respondentSubmitting && (
        <Button primary onClick={handleCopy} disabled={errorMessage?.length > 0}>
          Copy To Phase
        </Button>
      )}

      <p style={{ color: 'red', marginBottom: 7 }}>{errorMessage}</p>
    </div>
  );
};

export default CopyToOtherPhaseForm;
