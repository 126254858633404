import React, { useCallback, useState, useContext } from 'react';
import { Loading, Toast, Button } from '@shopify/polaris';
import { Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import $ from 'jquery';
import 'jquery-ui-bundle';
import DataTable from '../../dataTable/DataTable/DataTable';
import { ProjectsContext } from '../contexts/ProjectsContext';

import projectColumns from '../constants/projectColumns';

const InternalProjectsList: React.FunctionComponent = () => {
  const [projectsFetched, setProjectsFetched] = React.useState(false);

  const { projects, fetchProjects, projectLoading, totalRecords, sortParams, setSortParams, filterParams } =
    React.useContext(ProjectsContext);

  const { sortProjects } = useContext(ProjectsContext);
  const [isSorting, setIsSorting] = useState(false);

  const toggleSorting = (): void => {
    if (isSorting) {
      sortProjects($('.rdt_TableBody:first').sortable('serialize'));
      $('.rdt_TableBody:first').sortable('destroy');
    } else {
      $('.rdt_TableBody:first').sortable();
    }

    setIsSorting(!isSorting);
  };

  React.useEffect(() => {
    const internalProjectFilters = sessionStorage.getItem('internalProjectFilters');

    if (!projectsFetched) {
      if (internalProjectFilters) {
        fetchProjects(internalProjectFilters);
      } else {
        fetchProjects('ransack[client_name_eq]=Sommer Consulting');
      }
      setProjectsFetched(true);
    }
  }, [fetchProjects, projectsFetched]);

  const loadingMarkup = projectLoading ? <Loading /> : null;

  const history = useHistory();
  const [toastActive, setToastActive] = useState(false);

  const toggleToastActive = useCallback(() => setToastActive((toastActive) => !toastActive), []);
  const toastMarkup = toastActive ? <Toast onDismiss={toggleToastActive} content="Changes saved" /> : null;

  const handleColumnSort = async (column: Record<string, string>, sortDirection: string) => {
    const columnNamesToRansack = {
      project_number: 'project_number',
      name: 'name',
      client_name: 'client_name',
      start_date: 'project_timeline_overall_start_date',
      end_date: 'project_timeline_overall_end_date',
      project_manager_name: 'project_manager_last_name',
      recruiter_name: 'recruiter_last_name',
      status: 'status',
    };

    const sort = `sort=${columnNamesToRansack[column.selector]} ${sortDirection}`;

    setSortParams(sort);
    fetchProjects(`ransack[client_name_eq]=Sommer Consulting&${sort}`);
  };

  const handlePageChange = (page: number) => {
    fetchProjects(`page=${page}&ransack[client_name_eq]=Sommer Consulting&${filterParams}&${sortParams}`);
  };

  const collectUniqueProjects = () => {
    // This is some hacky javascript to make sure we do not show duplicate appointments in the datatable.
    // Projects will be duplicated if they have multiple phases because of how nested filters work with Rails ransack
    return [...(new Set(projects.map((project) => JSON.stringify(project))) as any)].map((s) => JSON.parse(s));
  };

  const pageMarkup = (
    <>
      <Grid style={{ display: 'flex', justifyContent: 'flex-end', marginRight: 20 }}>
        <Button primary={!isSorting} onClick={() => toggleSorting()}>
          {isSorting ? 'Save Order' : 'Sort Projects'}
        </Button>
      </Grid>

      <DataTable
        onRowClicked={(row: Record<string, string>) => history.push(`/admin/projects/${row.id}`)}
        columns={projectColumns()}
        data={collectUniqueProjects()}
        progressPending={projectLoading}
        striped
        highlightOnHover
        pointerOnHover
        noHeader
        pagination
        paginationPerPage={25}
        paginationRowsPerPageOptions={[25]}
        paginationServer
        paginationTotalRows={totalRecords}
        onChangePage={handlePageChange}
        sortServer
        onSort={handleColumnSort}
      />
    </>
  );

  // ---- Loading ----

  return (
    <>
      {loadingMarkup}
      {pageMarkup}
      {toastMarkup}
    </>
  );
};

export default InternalProjectsList;
