import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Grid, Card, Typography } from '@mui/material';
import { ConditionsContext } from '../contexts/ConditionsContext';
import BackButton from '../../navigation/components/BackButton';
import ColumnSelector from '../../shared/components/ColumnSelector';
import DataTable from '../../dataTable/DataTable/DataTable';
import { Insighter } from '../../insighters/model';
import insighterPossibleColumns from '../../shared/constants/insighterPossibleColumns';
import PrepareDownloadButton from '../../shared/components/PrepareDownloadButton';

const ConditionsList: React.FunctionComponent = () => {
  const history = useHistory();
  const { conditionId } = useParams<Record<string, string>>();
  const [conditionFetched, setConditionFetched] = React.useState(false);
  const { condition, fetchCondition, conditionsLoading } = React.useContext(ConditionsContext);
  const [insighters, setInsighters] = useState<Insighter[]>([]);

  const [visibleColumns, setVisibleColumns] = useState([]);

  useEffect(() => {
    if (!conditionFetched) {
      fetchCondition(conditionId);
      setConditionFetched(true);
    }
  }, [conditionFetched, fetchCondition, conditionId]);

  useEffect(() => {
    if (condition?.insighters) {
      setInsighters(condition.insighters);
    }
  }, [condition]);

  return (
    <>
      <Grid style={{ display: 'flex', justifyContent: 'space-between' }}>
        <BackButton pageAction={false} />
        <PrepareDownloadButton
          filename={`Insighters with Condition ${condition?.name}.csv`}
          visibleColumns={visibleColumns}
          link={`/conditions/${conditionId}/download`}
          includeResult
        />
      </Grid>
      <Grid style={{ margin: 10 }}>
        <Typography className="title" variant="h3">
          All Insighters with {condition.name}
        </Typography>
        <br />
        <ColumnSelector setVisibleColumns={setVisibleColumns} columnChoices={insighterPossibleColumns} />
        <br />
        <Card>
          <DataTable
            onRowClicked={(row: Record<string, string>) => history.push(`/admin/insighters/${row.id}`)}
            columns={visibleColumns}
            data={insighters}
            pagination
            progressPending={conditionsLoading}
            striped
            highlightOnHover
            pointerOnHover
            noHeader
            // paginationServer
            paginationPerPage={25}
            paginationRowsPerPageOptions={[25]}
            paginationTotalRows={insighters?.length}
            // onChangePage={handlePageChange}
          />
        </Card>
      </Grid>
    </>
  );
};

export default ConditionsList;
