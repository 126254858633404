import React from 'react';
import { IDataTableColumn } from '../../dataTable/DataTable/model';

const invitationColumns = (): IDataTableColumn[] => [
  {
    name: 'Inviter Name',
    selector: 'inviter_name',
    sortable: true,
  },
  {
    name: 'Invitee Name',
    selector: 'invitee_name',
    sortable: true,
  },
  {
    name: 'Sign Up Date',
    selector: 'signup_date',
    sortable: true,
    sortFunction: (rowA, rowB) => new Date(rowA.start_date).getTime() - new Date(rowB.start_date).getTime(),
  },
  {
    name: 'Project Name',
    selector: 'project_name',
    sortable: true,
  },
  {
    name: 'Phase Name',
    selector: 'phase_name',
    sortable: true,
  },
  {
    name: 'Invitation Amount',
    selector: 'amount',
    sortable: true,
  },
  {
    name: 'Status',
    selector: 'status',
    sortable: true,
    cell: ({ status }): React.ReactNode => (
      <div
        style={{
          fontWeight: 700,
          textTransform: 'uppercase',
          fontSize: 14,
          color: status === 'paid' ? 'green' : 'red',
        }}
      >
        {status}
      </div>
    ),
  },
];

export default invitationColumns;
