import React from 'react';
import { Card, Page, Button } from '@shopify/polaris';
import { CSVLink } from 'react-csv';
import { Grid } from '@mui/material';
import ExpensesList from '../components/ExpensesList';
import ExpensesFilterBar from '../components/ExpensesFilterBar';
import { ExpensesContext } from '../contexts/ExpensesContext';
import todaysDate from '../../utils/dateFunctions';

const ExpensesListContainer: React.FunctionComponent = () => {
  const { expenseLoading, expensesSum, expensesDownload } = React.useContext(ExpensesContext);

  return (
    <Page fullWidth>
      <Grid container justifyContent="space-between">
        <Grid item>
          <h1 className="Polaris-Header-Title">All Expenses</h1>
        </Grid>
        {expensesDownload && (
          <Grid item>
            <CSVLink
              data={expensesDownload}
              filename={`EXPENSES-${todaysDate()}.csv`}
              style={{ textDecoration: 'none' }}
            >
              <Button primary>Download Expenses</Button>
            </CSVLink>
          </Grid>
        )}
      </Grid>
      <br />
      <Card>
        <ExpensesFilterBar />
        <ExpensesList />
        {!expenseLoading && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              padding: '10px 20px 20px 20px',
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div>
              <p>
                <strong>Expense Total:</strong> ${expensesSum || 0}
              </p>
            </div>
          </div>
        )}
      </Card>
    </Page>
  );
};

export default ExpensesListContainer;
