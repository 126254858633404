import React from 'react';
import { useHistory } from 'react-router-dom';
import axios from '../../utils/axios.utils';
import { LeadGroup, LeadGroupFormValues, PhaseLead } from '../model';

interface LeadGroupsContextInterface {
  leadGroupsLoading?: boolean;
  leadGroupLoading?: boolean;
  fetchLeadGroup?: (params: string) => Promise<void>;
  fetchLeadGroups?: (params: string) => Promise<void>;
  leadGroup?: LeadGroup;
  leadGroups?: LeadGroup[];

  leads?: PhaseLead[];
  leadDownloadData?: Record<string, any>[];
  fetchLeads?: (params: string) => Promise<void>;
  leadsLoading?: boolean;
  totalRecords?: number;

  createLeadGroup?: (formValues: LeadGroupFormValues, onSuccess?: () => void) => Promise<void>;
  updateLeadGroup?: (phase_id: string, formValues: LeadGroupFormValues, afterUpdate?: () => void) => Promise<void>;
  deleteLeadGroup?: (leadGroupId: string) => Promise<void>;

  leadGroupSubmitting?: boolean;

  formError?: string;

  deleteLead?: (leadId: string) => Promise<void>;
  removeLeadError?: string;
}

const LeadGroupsContext = React.createContext<LeadGroupsContextInterface>({});

const LeadGroupsContextConsumer = LeadGroupsContext.Consumer;

const LeadGroupsContextProvider: React.FC = ({ children }) => {
  const history = useHistory();

  const [leadGroups, setLeadGroups] = React.useState<LeadGroup[]>([]);
  const [leadGroup, setLeadGroup] = React.useState<LeadGroup>(null);
  const [leadGroupsLoading, setLeadGroupsLoading] = React.useState<boolean>(false);
  const [leadGroupLoading, setLeadGroupLoading] = React.useState<boolean>(false);
  const [leadGroupSubmitting, setLeadGroupSubmitting] = React.useState(false);

  const [leads, setLeads] = React.useState<PhaseLead[]>([]);
  const [leadsLoading, setLeadsLoading] = React.useState<boolean>(false);
  const [totalRecords, setTotalRecords] = React.useState(0);

  const [leadDownloadData, setLeadDownloadData] = React.useState<Record<string, any>[]>([{}]);
  const [removeLeadError, setRemoveLeadError] = React.useState<string>('');
  const [formError, setFormError] = React.useState<string>(undefined);

  const fetchLeadGroups = async (params?: string) => {
    setLeadGroupsLoading(true);

    const response = await axios.get<string, any>(`lead_groups?${params}`);

    setLeadGroupsLoading(false);
    setLeadGroups(response?.data?.result);
  };

  const fetchLeadGroup = async (leadGroupId?: string) => {
    setLeadGroupLoading(true);
    const response = await axios.get<string, any>(`lead_groups/${leadGroupId}`);
    setLeadGroupLoading(false);
    setLeadGroup(response?.data?.result);
  };

  const fetchLeads = async (params?: string) => {
    setLeadsLoading(true);

    const response = await axios.get<string, any>(`leads?${params}`);

    setLeadsLoading(false);
    setTotalRecords(response?.data?.total_records);
    setLeads(response?.data?.result);
    setLeadDownloadData(response?.data?.lead_download_data);
  };

  const createLeadGroup = async (formValues: LeadGroupFormValues) => {
    setLeadGroupSubmitting(false);

    const response = await axios.post<string, any>(`lead_groups.json`, {
      lead_group: { ...formValues },
    });

    const newLead = response?.data?.result;

    setLeadGroupSubmitting(false);

    history.push(`/admin/insighter_finder/${newLead?.id}`);
  };

  const updateLeadGroup = async (lead_group_id: string, formValues: LeadGroupFormValues, afterUpdate?: () => void) => {
    setLeadGroupSubmitting(true);

    await axios.put<string, any>(`lead_groups/${lead_group_id}.json`, {
      lead_group: { ...formValues },
    });

    setLeadGroupSubmitting(false);
    if (afterUpdate) {
      afterUpdate();
    } else {
      history.push(`/admin/phases/${formValues?.phase_id}`);
    }
  };

  const deleteLeadGroup = async (leadGroupId: string) => {
    await axios
      .delete<string, any>(`lead_groups/${leadGroupId}`)
      .then(() => {
        const filteredLeadGroups = leadGroups.filter((group) => group.id !== leadGroupId);
        setLeadGroups(filteredLeadGroups);
      })
      .catch((error) => {
        setRemoveLeadError(error.response.data.error);
      });
  };

  const deleteLead = async (leadId: string) => {
    await axios
      .delete<string, any>(`leads/${leadId}`)
      .then(() => {
        const filteredLeads = leads.filter((lead) => lead.id !== leadId);
        setLeads(filteredLeads);
        history.push(`/admin/lead_groups/${leadGroup?.id}`);
      })
      .catch((error) => {
        setRemoveLeadError(error.response.data.error);
      });
  };

  return (
    <LeadGroupsContext.Provider
      value={{
        leadGroupsLoading,
        leadGroupLoading,
        fetchLeadGroup,
        fetchLeadGroups,
        leadGroups,
        leadGroup,
        leads,
        leadDownloadData,
        fetchLeads,
        leadsLoading,
        totalRecords,

        createLeadGroup,
        updateLeadGroup,
        deleteLeadGroup,

        deleteLead,
        removeLeadError,
      }}
    >
      {children}
    </LeadGroupsContext.Provider>
  );
};

export { LeadGroupsContextProvider, LeadGroupsContextConsumer, LeadGroupsContext };
