import React from 'react';
import { useParams } from 'react-router-dom';
import { Loading } from '@shopify/polaris';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import ProjectPhaseForm from '../components/ProjectPhaseForm';
import { ProjectPhasesContext } from '../contexts/ProjectPhasesContext';
import FormSkeleton from '../../loading/components/FormSkeleton';

import AdminPageHeader from '../../adminTools/components/AdminPageHeader';

const NewPhase: React.FunctionComponent = () => {
  const [phaseOptionsFetched, setPhaseOptionsFetched] = React.useState<boolean>(false);
  const { phase, fetchPhase, phaseLoading, fetchFormOptions } = React.useContext(ProjectPhasesContext);
  const { projectId } = useParams<Record<string, string>>();

  React.useEffect(() => {
    if (!phaseOptionsFetched) {
      fetchFormOptions();
      setPhaseOptionsFetched(true);
    }
  }, [phaseOptionsFetched, fetchPhase, fetchFormOptions]);

  return phaseLoading ? (
    <>
      <Loading />
      <FormSkeleton />
    </>
  ) : (
    <Grid container style={{ padding: '2% ' }}>
      <AdminPageHeader
        title="Create New Phase"
        backButton={{ label: 'Project', link: `/admin/projects/${projectId}` }}
      />
      <Grid item xs={12}>
        <Card style={{ padding: '2%' }}>
          <ProjectPhaseForm phase={phase} />
        </Card>
      </Grid>
    </Grid>
  );
};

export default NewPhase;
