import React from 'react';
import { useParams } from 'react-router-dom';
import { Card, Layout, Loading } from '@shopify/polaris';
import EthnicityForm from '../components/EthnicityForm';
import { EthnicitiesContext } from '../contexts/EthnicitiesContext';
import FormSkeleton from '../../loading/components/FormSkeleton';
import BackButton from '../../navigation/components/BackButton';
import Page from '../../shared/components/Page';

const EditEthnicity: React.FunctionComponent = () => {
  const { ethnicityId } = useParams<Record<string, string>>();
  const [ethnicityFetched, setEthnicityFetched] = React.useState<boolean>(false);
  const { ethnicity, fetchEthnicity, ethnicitiesLoading } = React.useContext(EthnicitiesContext);

  React.useEffect(() => {
    if (!ethnicityFetched) {
      fetchEthnicity(ethnicityId);
      setEthnicityFetched(true);
    }
  }, [ethnicityFetched, fetchEthnicity, ethnicityId]);

  const loadingMarkup = ethnicitiesLoading ? <Loading /> : null;

  // ---- Page markup ----
  const actualPageMarkup = (
    <>
      <BackButton pageAction={false} />
      <Page fullWidth>
        <Layout>
          <Layout.Section>
            <Card sectioned>
              <EthnicityForm ethnicity={ethnicity} />
            </Card>
          </Layout.Section>
          <Layout.Section secondary />
        </Layout>
      </Page>
    </>
  );

  // ---- Loading ----
  const loadingPageMarkup = <FormSkeleton />;

  const pageMarkup = ethnicitiesLoading ? loadingPageMarkup : actualPageMarkup;

  return (
    <>
      {loadingMarkup}
      {pageMarkup}
    </>
  );
};

export default EditEthnicity;
