import React from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import useStyles from '../../constants/redesignStyles';

const GetPaidForResearchBanner: React.FC = () => {
  let baseClasses = useStyles();

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography className={`${baseClasses.h2}`} style={{ fontWeight: 600, textAlign: 'center' }}>
          Get Paid for Research - <span style={{ color: '#488C7A' }}>Your Insights Matter</span>
        </Typography>
      </Grid>
      <Grid item xs={12} md={12} style={{ display: 'flex', justifyContent: 'center' }}>
        <Typography
          className={baseClasses.smallText}
          style={{ lineHeight: '1.5em', fontWeight: 500, textAlign: 'center', marginTop: 20 }}
        >
          Discover how your stories can spark positive change in healthcare.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default GetPaidForResearchBanner;
