import React from 'react';
import { Button, Card, Filters, ChoiceList } from '@shopify/polaris';
import { InsighterRolesContext } from '../contexts/InsighterRolesContext';

const InsighterRoleFilterBar: React.FunctionComponent = () => {
  const [filterValues, setFilterValues] = React.useState<Record<string, string>>({ query: '', archived: 'false' });
  const { fetchInsighterRoles } = React.useContext(InsighterRolesContext);

  const applyFilters = () => {
    fetchInsighterRoles(`ransack[name_cont]=${filterValues.query}&ransack[archived_eq]=${filterValues.archived}`);
  };

  const handleFiltersKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      applyFilters();
    }
  };

  const filters = [
    {
      key: 'archived',
      label: 'Archived',
      filter: (
        <ChoiceList
          title="Archived"
          titleHidden
          allowMultiple
          choices={[
            { label: 'Visible', value: 'false' },
            { label: 'Archived', value: 'true' },
          ]}
          selected={[filterValues?.archived]}
          onChange={(value) => setFilterValues({ ...filterValues, archived: value[1] })}
        />
      ),
      shortcut: true,
    },
  ];

  return (
    <Card.Section>
      <div role="form" onKeyDown={handleFiltersKeyPress}>
        <Filters
          queryValue={filterValues.query}
          queryPlaceholder="Filter roles by name..."
          filters={filters}
          appliedFilters={[]}
          onQueryChange={(value) => setFilterValues({ ...filterValues, query: value })}
          onQueryClear={() => setFilterValues({ ...filterValues, query: '' })}
          onClearAll={() => setFilterValues({ ...filterValues, query: '' })}
        >
          <div style={{ paddingLeft: '8px' }}>
            <Button onClick={applyFilters}>Apply Filter</Button>
          </div>
        </Filters>
      </div>
    </Card.Section>
  );
};

export default InsighterRoleFilterBar;
