import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import moment from 'moment';
import Colors from '../../styles/Colors';
import { UserContext } from '../../auth/contexts/UserContext';

interface DashboardNavigationPros {
  currentTab: number;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    headerBackground: {
      display: 'flex',
      borderRadius: 20,
      height: '120px',
    },
    welcomeTextContainer: {
      height: '120px',
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'center',
    },
    welcomeText: {
      fontSize: 36,
      margin: '50px 20px',
      fontWeight: 700,
      lineHeight: '36px',
    },
    dateTextContainer: {
      width: 200,
      display: 'flex',
      flex: 1,
      justifyContent: 'flex-end',
      [theme.breakpoints.down(500)]: {
        display: 'none',
      },
    },
    dateText: {
      fontSize: 36,
      margin: '40px 20px',
      fontWeight: 700,
    },
  }),
);

const DashboardNavigation: React.FC<DashboardNavigationPros> = () => {
  const classes = useStyles();
  const { user } = React.useContext(UserContext);

  return (
    <>
      <div className={classes.headerBackground} style={{ backgroundColor: Colors.LightNavy, color: '#fff' }}>
        <div className={classes.welcomeTextContainer}>
          <h2 className={classes.welcomeText}>Welcome back, {user?.first_name}!</h2>
        </div>
        <div className={classes.dateTextContainer}>
          <div style={{ textAlign: 'center', marginRight: '50px' }}>
            <p style={{ fontSize: 16, marginTop: 15, fontWeight: 500 }}>{moment().format('MMMM')}</p>
            <p style={{ fontSize: 48, margin: '15px 0', fontWeight: 700 }}>{moment().format('DD')}</p>
            <p style={{ fontSize: 16, fontWeight: 600 }}>{moment().format('YYYY')}</p>
          </div>
        </div>
      </div>
      <br />
    </>
  );
};

export default DashboardNavigation;
