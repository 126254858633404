const siteWideStyles = {
  buttonLink: {
    background: 'none',
    border: 'none',
    padding: 0,
    cursor: 'pointer',
  },
  hoverButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative' as const,
    height: '45px',
    minHeight: '45px',
    transition: 'background-color 0.3s ease',
    background: '#F3C66B',
    borderRadius: '120px',
    border: 'none',
    cursor: 'pointer',
    padding: '0 20px',
  },
  hoverButtonText: {
    textDecoration: 'none !important',
    fontFamily: 'Rethink Sans',
    color: '#3B4255',
    fontWeight: 800,
    fontSize: '17px',
    letterSpacing: '0.5px',
  },
  logo: {
    height: '40px',
    margin: '10px 20px',
    lineHeight: '28px',
    float: 'left' as const,
  },
  navMenuButton: {
    cursor: 'pointer',
    lineHeight: '60px',
    width: 'auto',
    background: 'none',
    border: 'none',
    padding: 0,
    display: 'flex',
    flexDirection: 'column' as const,
    justifyContent: 'flex-end',
    height: '100%',
  },
  navMenuText: {
    textDecoration: 'none',
    color: '#000',
    fontWeight: 700,
    fontFamily: 'Crimson Text',
    fontSize: 20,
    letterSpacing: '-0.2px',
    width: 'auto',
    padding: 0,
    marginTop: '-4px',
  },
  subheader: {
    fontSize: '20px',
    fontFamily: 'Cooper',
    lineHeight: '28px',
  },
};

export default siteWideStyles;
