import React from 'react';
import { useHistory } from 'react-router-dom';
import axios from '../../utils/axios.utils';
import ConfirmCancelModal from '../../modal/components/MaterialModal';

interface ModalProps {
  teamBioId?: string;
  deleteTeamBioModalOpen?: boolean;
  setDeleteTeamBioConfirmModalOpen?: any;
}

const DeleteTeamBioModal: React.FC<ModalProps> = (props) => {
  const history = useHistory();

  const { teamBioId, deleteTeamBioModalOpen, setDeleteTeamBioConfirmModalOpen } = props;

  const handleDeleteTeamBio = async () => {
    try {
      await axios.delete(`team_bios/${teamBioId}`);
      history.push('/admin/team-bios');
    } catch (error: any) {
      console.log(error);
    }
  };

  return (
    <ConfirmCancelModal
      isOpen={deleteTeamBioModalOpen}
      title="Delete Team Bio"
      onCancel={() => setDeleteTeamBioConfirmModalOpen(false)}
      onWarning={() => handleDeleteTeamBio()}
      warningLabel="Delete"
      cancelLabel="Cancel"
      hasWarningButton
      hasCancelButton
      hasFooterBar={false}
      height="250px"
    >
      <div style={{ marginTop: '20px', marginBottom: '20px' }}>
        <p>Are you sure you want to delete this bio?</p>
      </div>
    </ConfirmCancelModal>
  );
};

export default DeleteTeamBioModal;
