import React, { useState, useEffect } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { TextField } from '@mui/material';
import { Grid, Typography } from '@material-ui/core';
import { Card, Button } from '@shopify/polaris';
import Page from '../shared/components/Page';
import ConfirmCancelModal from '../modal/components/MaterialModal';
import axios from '../utils/axios.utils';
import LoadingSpinner from '../loading/components/LoadingSpinner';

const defaultMergeForm = {
  user1: { value: '', label: '', email: '' },
  user2: { value: '', label: '', email: '' },
};

const defaultMergeAccount = {
  uuid: '',
  last_name: '',
  first_name: '',
  email: '',
  insighter_alternate_email1: '',
};

const AccountMerger: React.FunctionComponent = () => {
  const [formValues, setFormValues] = useState(defaultMergeForm);
  const [loginEmail, setLoginEmail] = useState('');
  const [altEmail, setAltEmail] = useState('');
  const [mergeButtonDisabled, setMergeButtonDisabled] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [mergedAccount, setMergedAccount] = useState(defaultMergeAccount);
  const [mergedAltEmail, setMergedAltEmail] = useState('');
  const [mergeSubmitting, setMergeSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [userOptions1, setUserOptions1] = useState([]);
  const [userOptions2, setUserOptions2] = useState([]);
  const [inputValue1, setInputValue1] = useState('');
  const [inputValue2, setInputValue2] = useState('');

  useEffect(() => {
    if (inputValue1?.length >= 3) {
      console.log(inputValue1);

      axios.get(`merge_accounts?query=${inputValue1}`).then((response) => {
        setUserOptions1(response?.data?.user_choices);
      });
    }
  }, [inputValue1]);

  useEffect(() => {
    if (inputValue2?.length >= 3) {
      axios.get(`merge_accounts?query=${inputValue2}`).then((response) => {
        setUserOptions2(response?.data?.user_choices);
      });
    }
  }, [inputValue2]);

  useEffect(() => {
    if (formValues?.user1?.label) {
      if (JSON.stringify(formValues?.user1) === JSON.stringify(formValues?.user2)) {
        setErrorMessage('Please choose two different users');
        setMergeButtonDisabled(true);
      } else if (formValues?.user2?.label) {
        setErrorMessage('');
        setMergeButtonDisabled(false);
      }
    } else {
      setMergeButtonDisabled(true);
    }

    setLoginEmail(formValues?.user2?.email);
    setAltEmail(formValues?.user1?.email);
  }, [formValues]);

  const handleMerge = () => {
    setMergedAccount(defaultMergeAccount);
    setMergedAltEmail('');
    setMergeButtonDisabled(true);
    setModalOpen(false);
    setMergeSubmitting(true);

    axios.post(`merge_accounts`, { formValues, loginEmail, altEmail }).then((response) => {
      setMergedAccount(response?.data?.result);
      setMergedAltEmail(response?.data?.insighter_alternate_email1);
      setFormValues(defaultMergeForm);
      setLoginEmail('');
      setAltEmail('');
      setMergeSubmitting(false);
    });
  };

  const mergeAnotherClicked = () => {
    setMergedAccount(defaultMergeAccount);
    setFormValues(defaultMergeForm);
  };

  const handleEmailSwitch = () => {
    const temp = altEmail;
    setAltEmail(loginEmail);
    setLoginEmail(temp);
  };

  return (
    <Page fullWidth title="Account Merger">
      <ConfirmCancelModal
        isOpen={modalOpen}
        title="Merge these Users"
        onCancel={() => setModalOpen(false)}
        onConfirm={handleMerge}
        confirmLabel="Merge"
        cancelLabel="Cancel"
        hasCancelButton
        hasConfirmButton
        hasFooterBar={false}
        height="250px"
      >
        <div style={{ marginTop: '20px', marginBottom: '20px' }}>
          <p>Are you sure you want to merge </p>
          <p style={{ fontWeight: 'bold' }}>{formValues?.user1?.label}</p>
          <p>into</p>
          <p style={{ fontWeight: 'bold' }}>{formValues?.user2?.label}</p>
          <p>and use {loginEmail} as the log-in email?</p>
        </div>
      </ConfirmCancelModal>
      <Card sectioned>
        <Grid container>
          <br />
          <Grid xs={2} style={{ marginTop: 10, textAlign: 'right' }}>
            <Typography variant="h4">User 1 &emsp;</Typography>
          </Grid>
          <Grid xs={7}>
            <Autocomplete
              style={{ backgroundColor: '#e8e8e8' }}
              id="MergeAccount-Autocomplete-Input"
              options={userOptions1 || []}
              renderOption={(props, option) => (
                <li key={option.value} style={{ fontSize: '1.4rem' }} {...props}>
                  {option.label}
                </li>
              )}
              value={formValues?.user1}
              inputValue={inputValue1}
              onInputChange={(event, newInputValue) => {
                setInputValue1(newInputValue);
              }}
              onChange={(_event, value) => setFormValues({ ...formValues, user1: value })}
              renderInput={(params) => <TextField {...params} />}
            />
          </Grid>
          <Grid xs={3} />
          <br />
          <Grid xs={2} style={{ marginTop: 40, textAlign: 'right' }}>
            <Typography variant="h4">User 2 &emsp;</Typography>
          </Grid>

          <Grid xs={7} style={{ marginTop: 30 }}>
            <Autocomplete
              style={{ backgroundColor: '#e8e8e8' }}
              id="MergeAccount-Autocomplete-Input"
              options={userOptions2 || []}
              renderOption={(props, option) => (
                <li key={option.value} style={{ fontSize: '1.4rem' }} {...props}>
                  {option.label}
                </li>
              )}
              value={formValues?.user2}
              inputValue={inputValue2}
              onInputChange={(event, newInputValue) => {
                setInputValue2(newInputValue);
              }}
              onChange={(_event, value) => setFormValues({ ...formValues, user2: value })}
              renderInput={(params) => <TextField {...params} />}
            />
          </Grid>
          {errorMessage?.length > 0 && (
            <Grid xs={12} style={{ marginTop: 20, marginLeft: 50 }}>
              <p style={{ color: 'red' }}>{errorMessage}</p>
            </Grid>
          )}
          <Grid xs={12} style={{ marginTop: 40, display: 'flex', justifyContent: 'center' }}>
            <Grid xs={3} style={{ textAlign: 'right' }}>
              Log-in Email: &emsp;
            </Grid>
            <Grid xs={7}>
              <span style={{ fontWeight: 'bold' }}>{loginEmail}</span>
            </Grid>
          </Grid>
          <Grid xs={12} style={{ marginTop: 10, display: 'flex', justifyContent: 'center' }}>
            <Grid xs={3} style={{ textAlign: 'right' }}>
              Alternate Email: &emsp;
            </Grid>
            <Grid xs={7}>
              <span style={{ fontWeight: 'bold' }}>{altEmail}</span>
            </Grid>
          </Grid>
          <Grid xs={12} style={{ marginTop: 10, display: 'flex', justifyContent: 'center' }}>
            <Button onClick={handleEmailSwitch}>Switch Emails</Button>
          </Grid>

          <Grid xs={7} style={{ marginTop: 30 }}>
            <Button primary disabled={mergeButtonDisabled} onClick={() => setModalOpen(true)}>
              Merge
            </Button>
          </Grid>
        </Grid>
      </Card>
      <br />
      {mergeSubmitting && (
        <Card title="Merging ...">
          <LoadingSpinner />
        </Card>
      )}
      {mergedAccount.uuid ? (
        <Card sectioned title="Merged User">
          <Grid container>
            <Grid item xs={4}>
              <Card.Section title="uuid">
                <p>{mergedAccount?.uuid}</p>
              </Card.Section>
            </Grid>
            <Grid item xs={4}>
              <Card.Section title="Last Name">
                <p>{mergedAccount?.last_name}</p>
              </Card.Section>
            </Grid>
            <Grid item xs={4}>
              <Card.Section title="First Name">
                <p>{mergedAccount?.first_name}</p>
              </Card.Section>
            </Grid>
            <Grid item xs={4}>
              <Card.Section title="Email">
                <p>{mergedAccount?.email}</p>
              </Card.Section>
            </Grid>
            <Grid item xs={4}>
              <Card.Section title="Alternate Email">
                <p>{mergedAltEmail}</p>
              </Card.Section>
            </Grid>
            <Grid xs={7} style={{ marginTop: 30 }}>
              <Button primary onClick={() => mergeAnotherClicked()}>
                Merge Another
              </Button>
            </Grid>
          </Grid>
        </Card>
      ) : (
        <Card sectioned title="Notes">
          <p>All associated records, such as interviews, notes, and payments, will be moved from User 1 to User 2.</p>
          <br />
          <p>
            Exception: If User 1 has an insighter profile but User 2 does not, then the data will move in the other
            direction (from User 2 to User 1).
          </p>
          <br />

          <p>After the merge, the user account with no records remaining will be deleted.</p>
        </Card>
      )}
    </Page>
  );
};

export default AccountMerger;
