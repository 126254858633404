/* eslint-disable jsx-a11y/click-events-have-key-events */

import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { SelectOption } from '../../../form/model';

interface OpportunitiesFilterBarMobileProps {
  fetchOpportunities: (category?: string, query?: string) => void;
  topicOptions: SelectOption[];
}

const OpportunitiesFilterBarMobile: React.FC<OpportunitiesFilterBarMobileProps> = (props) => {
  const { fetchOpportunities, topicOptions } = props;

  const [selectedCategory, setSelectedCategory] = useState<string | undefined>('0');
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleCategoryClicked = (category: string | undefined) => {
    setSelectedCategory(category);
    fetchOpportunities(category);
    setDropdownOpen(false);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <Grid
      container
      spacing={2}
      style={{
        display: 'flex',
        backgroundColor: '#3B4255',
        justifyContent: 'center',
        paddingTop: 15,
        paddingBottom: 15,
        alignItems: 'center',
      }}
    >
      <Grid item xs={2} />
      <Grid item xs={8}>
        <div
          style={{
            width: '100%',
            backgroundColor: '#407667',
            borderRadius: '20px',
            paddingTop: 10,
            paddingBottom: 10,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            position: 'relative',
          }}
          role="button"
          tabIndex={0}
          onClick={toggleDropdown}
        >
          <p
            style={{
              fontSize: 20,
              fontWeight: 600,
              color: 'white',
              marginRight: '20px',
            }}
          >
            {selectedCategory === '0'
              ? 'All Topics'
              : topicOptions.find((option) => option.value === selectedCategory)?.label}
          </p>

          {/* Arrow indicator */}
          <div
            style={{
              width: 0,
              height: 0,
              borderLeft: '6px solid transparent',
              borderRight: '6px solid transparent',
              borderTop: dropdownOpen ? 'none' : '6px solid white', // Arrow points down when closed
              borderBottom: dropdownOpen ? '6px solid white' : 'none', // Arrow points up when open
              position: 'absolute',
              right: '20px',
              top: '50%',
              transform: 'translateY(-50%)',
            }}
          />
        </div>

        {/* Dropdown */}
        <div
          style={{
            height: dropdownOpen ? 'auto' : '0',
            overflow: 'hidden',
            transition: 'height 0.3s ease',
            backgroundColor: '#ffffff',
            borderRadius: '10px',
            marginTop: '10px',
            padding: dropdownOpen ? '10px' : '0',
          }}
        >
          {dropdownOpen && (
            <ul style={{ listStyleType: 'none', padding: 0 }}>
              {topicOptions.map((option) => (
                <li
                  key={option.value}
                  onClick={() => handleCategoryClicked(option.value)}
                  style={{
                    padding: '8px 0',
                    borderBottom: '1px solid #ddd',
                    color: selectedCategory === option.value ? 'blue' : 'black',
                    fontSize: 20,
                  }}
                >
                  {option.label}
                </li>
              ))}
            </ul>
          )}
        </div>
      </Grid>
      <Grid item xs={2} />
    </Grid>
  );
};

export default OpportunitiesFilterBarMobile;
