import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import useStyles from '../../constants/redesignStyles';
import GotAQuestionImage from '../assets/GotAQuestionImage.png';
import ReachOutToEmail from '../../common/ReachOutToEmail';

const styleOverrides = makeStyles((theme) => ({
  ...useStyles,
  h1Override: {
    color: '#3B4255',
    fontFamily: 'Crimson Text',
    fontSize: '64px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '100%',
    letterSpacing: '-2.25px',
    width: '100%',
    margin: 'auto 15% auto auto',
  },
  imageContainer: {
    marginTop: -50,
    textAlign: 'left',

    [theme.breakpoints.down(960)]: {
      marginTop: 0,
      textAlign: 'center',
    },
  },
}));

const AboutLandingSection: React.FC = () => {
  const classes = styleOverrides();

  return (
    <Grid container style={{ margin: '50px auto 20px', maxWidth: 1200 }}>
      <Grid
        item
        xs={12}
        md={6}
        style={{
          marginTop: 30,
          padding: 30,
          display: 'flex',
          alignItems: 'center',
          position: 'relative',
          justifyContent: 'center',
          flexDirection: 'row',
          marginBottom: 40,
        }}
      >
        <div>
          <h2 className={classes.h1Override} style={{ position: 'relative' }}>
            Got a Question?
          </h2>
          <h2 className={classes.h1Override} style={{ position: 'relative' }}>
            Let&apos;s Connect!
          </h2>
          <br />
          <ReachOutToEmail />
        </div>
      </Grid>
      <Grid item xs={12} md={6} className={classes.imageContainer}>
        <img
          src={GotAQuestionImage}
          alt="Hero"
          style={{ width: '100%', height: 'auto', maxHeight: 400, maxWidth: 400, display: 'inline-block' }}
        />
      </Grid>
    </Grid>
  );
};

export default AboutLandingSection;
