import React from 'react';
import { Button, FormLayout } from '@shopify/polaris';
import { Grid } from '@material-ui/core';
import UngroupedAutocompleteInput from '../../form/components/UngroupedAutocompleteInput';
import FormInput from '../../form/components/FormInput';
import SelectInput from '../../form/components/SelectInput';
import { ConditionsContext } from '../contexts/ConditionsContext';
import { Condition, ConditionFormValues } from '../model';

interface ConditionFormProps {
  condition?: Condition;
}

const ConditionForm: React.FC<ConditionFormProps> = (props) => {
  const { condition } = props;
  const { formOptions, formError, updateCondition, createCondition, deleteCondition, conditionSubmitting } =
    React.useContext(ConditionsContext);

  const [formValues, setFormValues] = React.useState<ConditionFormValues>({ condition_category_names: [] });

  React.useEffect(() => {
    if (condition) {
      setFormValues({
        name: condition?.name,
        condition_category_names: condition?.condition_category_names,
        status: condition?.status,
      });
    }
  }, [condition]);

  const handleUpdate = () => {
    updateCondition(condition.id, formValues);
  };

  const handleCreate = () => {
    createCondition(formValues);
  };

  const handleDelete = () => {
    deleteCondition(condition.id);
  };

  return (
    <FormLayout>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <h2 className="Polaris-Heading">Condition Information</h2>
        </Grid>
        <Grid item xs={12}>
          <FormInput
            label="Name"
            type="text"
            value={formValues.name}
            onChange={(value) => setFormValues({ ...formValues, name: value })}
          />
        </Grid>
        {formValues?.condition_category_names && (
          <Grid item xs={12}>
            <UngroupedAutocompleteInput
              label="Condition Categories"
              choices={formOptions?.category_options}
              onChange={(value) => {
                setFormValues({ ...formValues, condition_category_names: value });
              }}
              value={formValues.condition_category_names}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <SelectInput
            label="Status"
            choices={formOptions?.status_options}
            value={formValues.status}
            onChange={(value) => setFormValues({ ...formValues, status: value })}
          />
        </Grid>
      </Grid>

      <>
        {condition?.id ? (
          <>
            <Button primary onClick={handleUpdate} loading={conditionSubmitting}>
              Update Condition
            </Button>
            <span style={{ marginLeft: '8px' }}>
              <Button destructive onClick={handleDelete} loading={conditionSubmitting}>
                Delete Condition
              </Button>
            </span>
          </>
        ) : (
          <Button primary onClick={handleCreate} loading={conditionSubmitting}>
            Create Condition
          </Button>
        )}
        {formError && <p style={{ color: 'red', fontSize: '14px', marginTop: 20 }}>{formError}</p>}
      </>
    </FormLayout>
  );
};

export default ConditionForm;
