import React, { useCallback, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Card, Loading, Toast, DropZone, Modal, TextContainer } from '@shopify/polaris';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import axios from '../../utils/axios.utils';
import InvitationPartners from '../../invitationPartners/components/InvitationPartners';
import InvitationsCreated from '../components/InvitationsCreated';
import { InvitationOpportunitiesContext } from '../contexts/InvitationOpportunitiesContext';
import { InvitationsContext } from '../../invitations/contexts/InvitationsContext';
import { UserContext } from '../../auth/contexts/UserContext';
import SocialShareCard from '../../invitations/components/SocialShareCard';
import DetailPageSkeleton from '../../loading/components/DetailPageSkeleton';
import AdminPageHeader from '../../adminTools/components/AdminPageHeader';

const InvitationOpportunityDetailPage: React.FunctionComponent = () => {
  const history = useHistory();
  const {
    invitationOpportunity,
    fetchInvitationOpportunity,
    invitationOpportunityLoading,
    updateInvitationOpportunity,
    archiveInvitationOpportunity,
    invitationOpportunitySubmitting,
  } = React.useContext(InvitationOpportunitiesContext);
  const { currentUserHasRole } = React.useContext(UserContext);
  const { fetchFormOptions } = React.useContext(InvitationsContext);
  const { invitationOpportunityId } = useParams<Record<string, string>>();
  const [invitationOpportunityFetched, setInvitationOpportunityFetched] = React.useState<boolean>(false);
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [sendEmailModalOpen, setSendEmailModalOpen] = useState(false);
  const [emailsSending, setEmailsSending] = useState(false);

  React.useEffect(() => {
    if (!invitationOpportunityFetched) {
      fetchInvitationOpportunity(invitationOpportunityId);
      setInvitationOpportunityFetched(true);
      fetchFormOptions(invitationOpportunityId);
    }
  }, [
    invitationOpportunityFetched,
    fetchInvitationOpportunity,
    invitationOpportunityId,
    setInvitationOpportunityFetched,
    fetchFormOptions,
  ]);

  const [toastActive, setToastActive] = useState(false);
  const toggleToastActive = useCallback(() => setToastActive((toastActive) => !toastActive), []);
  const toastMarkup = toastActive ? (
    <Toast onDismiss={toggleToastActive} content="Emails successfully delivered" />
  ) : null;
  const loadingMarkup = !invitationOpportunityFetched ? <Loading /> : null;

  const handleUpload = (files: File[]) => {
    const data = new FormData();
    const attachedFile = files[0];

    data.append(`file`, attachedFile, attachedFile.name);

    axios.post(`/invitation_opportunity_images?id=${invitationOpportunity.id}`, data).then(() => {
      history.push(`/admin/invitation-opportunities/${invitationOpportunity.id}`);
    });
  };

  const sendPartnerEmail = () => {
    setSendEmailModalOpen(false);
    setEmailsSending(true);
    axios.post(`/invitation_opportunities/${invitationOpportunity.id}/send_emails`, {}).then(() => {
      setToastActive(true);
    });
  };

  const InvitationOpportunityAdminButtons: React.FunctionComponent = () => {
    return (
      <>
        <Button
          className="secondary-btn"
          onClick={() => {
            history.push(`/admin/invitation-opportunities/${invitationOpportunity?.id}/duplicate`);
          }}
        >
          Duplicate
        </Button>
        <Button
          className="primary-btn"
          onClick={() => {
            history.push(`/admin/invitation-opportunities/${invitationOpportunity?.id}/edit`);
          }}
        >
          Edit
        </Button>
      </>
    );
  };

  const pageMarkup = invitationOpportunityLoading ? (
    <DetailPageSkeleton />
  ) : (
    <Grid container style={{ padding: '1% 2%' }}>
      <AdminPageHeader
        title={invitationOpportunity?.name}
        badge={{ label: invitationOpportunity?.status }}
        adminButtons={<InvitationOpportunityAdminButtons />}
      />
      <Grid item xs={12} md={7} style={{ marginBottom: 15, marginRight: 5 }}>
        <Card sectioned title="Invitation Details">
          <Grid container>
            <Grid item xs={12}>
              <Card.Section title="Invitation Name">
                <p>{invitationOpportunity?.name}</p>
              </Card.Section>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Card.Section title="Description">
                <p>{invitationOpportunity?.description}</p>
              </Card.Section>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Card.Section title="Learn More">
                {/* eslint-disable-next-line react/no-danger */}
                <div dangerouslySetInnerHTML={{ __html: invitationOpportunity.learn_more }} />
              </Card.Section>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Card.Section title="Sample Message">
                <p style={{ whiteSpace: 'break-spaces' }}>{invitationOpportunity?.sample_message}</p>
              </Card.Section>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Card.Section title="Invitation Type">
                <p>{invitationOpportunity?.invitation_type}</p>
              </Card.Section>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Card.Section title="Project Name">
                <p>{invitationOpportunity?.project_name}</p>
              </Card.Section>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Card.Section title="Phases">
                <p>{invitationOpportunity?.phase_names}</p>
              </Card.Section>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Card.Section title="Opportunity">
                <p>{invitationOpportunity?.opportunity_name}</p>
              </Card.Section>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Card.Section title="Invitations Completed">
                <p>{invitationOpportunity?.invitations_completed}</p>
              </Card.Section>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Card.Section title="Invitation Quota">
                <p>{invitationOpportunity?.invitation_quota}</p>
              </Card.Section>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Card.Section title="Default Invitation Amount">
                <p>${invitationOpportunity?.default_inviter_amount}</p>
              </Card.Section>
            </Grid>
            <Grid item xs={12} sm={6} md={6} style={{ textTransform: 'capitalize' }}>
              <Card.Section title="Inviter Award Type">
                <p>{invitationOpportunity?.inviter_award_type}</p>
              </Card.Section>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Card.Section title="Default invitee Amount">
                <p>${invitationOpportunity?.default_invitee_amount}</p>
              </Card.Section>
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid item xs={12} md={4}>
        <Card sectioned title="Invitation Status">
          <Grid container>
            <Grid item xs={12} sm={6} md={6}>
              <Card.Section title="Invitation Start Date">
                <p>{invitationOpportunity?.start_date}</p>
              </Card.Section>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Card.Section title="Invitation End Date">
                <p>{invitationOpportunity?.end_date}</p>
              </Card.Section>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Card.Section title="Status">
                <p style={{ textTransform: 'capitalize' }}>{invitationOpportunity?.status}</p>
              </Card.Section>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Card.Section title="Automatically Update Status">
                <p>{invitationOpportunity?.update_status ? 'Yes' : 'No'}</p>
              </Card.Section>
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid item xs={12} style={{ marginBottom: 15 }}>
        <InvitationPartners invitationOpportunity={invitationOpportunity} />
      </Grid>
      <Grid item xs={12} style={{ marginBottom: 15 }}>
        <InvitationsCreated invitationOpportunity={invitationOpportunity} />
      </Grid>
      {currentUserHasRole('admin') && (
        <Grid item xs={12} style={{ marginBottom: 15 }}>
          <Card title="Invitation Opportunity Image" sectioned>
            <DropZone type="image" label="" onDrop={handleUpload} allowMultiple={false}>
              <DropZone.FileUpload />
            </DropZone>
          </Card>
        </Grid>
      )}
      <Grid item xs={12} style={{ marginBottom: 15 }}>
        <Card title="Invitation Opportunity Preview" sectioned>
          <SocialShareCard invitationOpportunity={invitationOpportunity} />
        </Card>
      </Grid>
      {currentUserHasRole('superadmin') && (
        <>
          <Modal
            instant
            open={showArchiveModal}
            onClose={() => setShowArchiveModal(false)}
            title="Archive Confirmation"
            primaryAction={{
              content: 'Archive Invitation Opportunity',
              destructive: true,
              onAction: () => archiveInvitationOpportunity(invitationOpportunity?.id),
            }}
            secondaryActions={[
              {
                content: 'Cancel',
                onAction: () => setShowArchiveModal(false),
              },
            ]}
          >
            <Modal.Section>
              <TextContainer>
                <p>
                  Are you sure you want to archive this invitation opportunity? This will hide the invitation
                  opportunity by default. It will also set all of its associated invitations to &quot;Opportunity
                  Closed&quot;
                </p>
              </TextContainer>
            </Modal.Section>
          </Modal>
          <Modal
            instant
            open={sendEmailModalOpen}
            onClose={() => setSendEmailModalOpen(false)}
            title="Send Partner Emails"
            primaryAction={{
              content: 'Send Emails',
              onAction: sendPartnerEmail,
            }}
            secondaryActions={[
              {
                content: 'Cancel',
                onAction: () => setSendEmailModalOpen(false),
              },
            ]}
          >
            <Modal.Section>
              <TextContainer>
                <p>Send Emails to all newly-added Invitation Partners?</p>
              </TextContainer>
            </Modal.Section>
          </Modal>
          <Grid container spacing={3}>
            <Grid item xs={2}>
              <Button onClick={() => setSendEmailModalOpen(true)} disabled={emailsSending}>
                {emailsSending ? 'Emails Sent' : 'Send Emails'}
              </Button>
            </Grid>
            <Grid xs={5} style={{ marginTop: 12 }}>
              {invitationOpportunity?.status === 'archived' ? (
                <Grid container>
                  <Grid item xs={6}>
                    <Button
                      className="secondary-btn"
                      onClick={() => {
                        updateInvitationOpportunity(invitationOpportunity?.id, { status: 'inactive' });
                      }}
                    >
                      Unarchive Invitation Opportunity
                    </Button>
                  </Grid>
                </Grid>
              ) : (
                <Grid container>
                  <Grid item xs={6}>
                    <Button
                      className="destructive-btn"
                      onClick={() => {
                        setShowArchiveModal(!showArchiveModal);
                      }}
                    >
                      Archive
                    </Button>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
          <br />
        </>
      )}
    </Grid>
  );

  return (
    <>
      {loadingMarkup}
      {pageMarkup}
      {toastMarkup}
    </>
  );
};

export default InvitationOpportunityDetailPage;
