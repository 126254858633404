import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { Card } from '@shopify/polaris';
import { Dashboard } from '../model';

const useStyles = makeStyles({
  largeNumber: {
    fontSize: 72,
    textAlign: 'center',
    display: 'block',
    margin: '20px auto',
    fontWeight: 700,
  },
  largeNumberDescription: {
    fontSize: 16,
    textAlign: 'center',
    display: 'block',
    margin: '30px auto 0',
    fontWeight: 700,
  },
});

interface DashboardProps {
  dashboard: Dashboard;
}

const SignupCountBoxes: React.FC<DashboardProps> = (props) => {
  const { signupsWeekCount, signupsMonthCount, signupsDayCount } = props.dashboard;
  const history = useHistory();
  const classes = useStyles();

  return (
    <Grid container spacing={4}>
      <Grid item xs={4}>
        <Card
          title="Last 7 Days"
          sectioned
          actions={[
            {
              content: 'View All',
              onAction: () => {
                history.push('/admin/insighters?signups=7');
              },
            },
          ]}
        >
          <div style={{ color: 'rgba(33,150,243,1)' }}>
            <span className={classes.largeNumber}>{signupsWeekCount}</span>
            <p className={classes.largeNumberDescription}>
              {parseInt(signupsWeekCount, 10) === 1 ? 'Insighter' : 'Insighters'}
            </p>
          </div>
        </Card>
      </Grid>
      <Grid item xs={4}>
        <Card
          title="Last 30 Days"
          sectioned
          actions={[
            {
              content: 'View All',
              onAction: () => {
                history.push('/admin/insighters?signups=30');
              },
            },
          ]}
        >
          <div style={{ color: 'rgb(34, 139, 34)' }}>
            <span className={classes.largeNumber}>{signupsMonthCount}</span>
            <p className={classes.largeNumberDescription}>
              {parseInt(signupsMonthCount, 10) === 1 ? 'Insighter' : 'Insighters'}
            </p>
          </div>
        </Card>
      </Grid>
      <Grid item xs={4}>
        <Card
          title="Last 24 Hours"
          sectioned
          actions={[
            {
              content: 'View All',
              onAction: () => {
                history.push('/admin/insighters?signups=1');
              },
            },
          ]}
        >
          <div style={{ color: 'rgba(142, 68, 173 ,1)' }}>
            <span className={classes.largeNumber}>{signupsDayCount}</span>
            <p className={classes.largeNumberDescription}>
              {parseInt(signupsDayCount, 10) === 1 ? 'Insighter' : 'Insighters'}
            </p>
          </div>
        </Card>
      </Grid>
      <Grid style={{ width: '90%', margin: 'auto', marginBottom: '10px' }}>
        <em>
          &emsp; The above counts include Insighters who deleted or archived their profiles after the count was saved.
        </em>
      </Grid>
    </Grid>
  );
};

export default SignupCountBoxes;
