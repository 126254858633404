/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, Dispatch, SetStateAction, useEffect, useRef } from 'react';
import { Loading, Toast, Card } from '@shopify/polaris';
import DataTable from '../../dataTable/DataTable/DataTable';
import { PhaseRespondentsContext } from '../contexts/PhaseRespondentsContext';
import respondentColumns from '../constants/respondentColumns';
import { Phase } from '../model';

interface PhaseRespondentsListProps {
  phase: Phase;
}

const PhaseRespondentsList: React.FunctionComponent<PhaseRespondentsListProps> = (props) => {
  const { phase } = props;
  const [toastActive, setToastActive] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const {
    respondents,
    fetchRespondents,
    respondentLoading,
    totalRecords,
    sortParams,
    setSortParams,
    fetchParams,
    selectedRespondentIds,
    setSelectedRespondentIds,
  } = React.useContext(PhaseRespondentsContext);

  useEffect(() => {
    const respondentFilters = sessionStorage.getItem(`respondentFilters-${phase?.id}`);

    if (respondentFilters) {
      fetchRespondents(`phase_id=${phase?.id}&${respondentFilters}`);
    } else {
      fetchRespondents(`phase_id=${phase?.id}`);
    }
  }, [phase]);

  const loadingMarkup = respondentLoading ? <Loading /> : null;
  const toggleToastActive = React.useCallback(() => setToastActive((toastActive) => !toastActive), []);
  const toastMarkup = toastActive ? <Toast onDismiss={toggleToastActive} content="Changes saved" /> : null;

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    fetchRespondents(`phase_id=${phase?.id}&page=${page}&${fetchParams}&${sortParams}`);
  };

  const handleUpdateResponse = () => {
    const filterParams = sessionStorage.getItem(`respondentFilters-${phase?.id}`);
    const queryInputValue = sessionStorage.getItem(`respondentFilterQuery-${phase?.id}`);
    fetchRespondents(`phase_id=${phase?.id}&page=${currentPage}&${filterParams}&${sortParams}`);
  };

  const handleColumnSort = async (column: Record<string, string>, sortDirection: string) => {
    const columnNamesToRansack = {
      uuid: 'user_uuid',
      name: 'user_last_name',
      applied_at: 'applied_at',
      account_status: 'account_status',
      qualtrics_status: 'qualtrics_status',
      interview_status: 'interview_status',
      homework_status: 'homework_status',
      segment: 'segment',
    };

    const sort = `sort=${columnNamesToRansack[column.selector]} ${sortDirection}`;

    setSortParams(sort);
    fetchRespondents(`phase_id=${phase?.id}&${fetchParams}&${sort}`);
  };

  const handleSelectedRows = ({ selectedRows }) => {
    setSelectedRespondentIds(selectedRows.map((row) => row.uuid));
  };

  const rowSelectCritera = (row) => {
    return selectedRespondentIds?.includes(row.user_id);
  };

  const customStyles = {
    cells: {
      style: {
        padding: '8px',
        wordBreak: 'break-word',
      },
    },
  };

  const ExpandedComponent = ({ data }) => (
    <>
      <Card.Section title="Admin Notes">
        {data?.insighter_notes?.length > 0 ? (
          <>
            {data.insighter_notes.map((note: Record<string, string>, index: number) => (
              <div key={index}>
                {index !== 0 && (
                  <hr
                    style={{ margin: '20px 0', width: '100%', border: 'none', height: '1px', backgroundColor: '#ddd' }}
                  />
                )}
                <p>
                  <strong>From:</strong> {note.author}
                  <span style={{ float: 'right' }}>
                    <strong>Date:</strong> {note.created_at}
                  </span>
                </p>
                <p style={{ marginTop: 10, width: '80%' }}>
                  <strong>Note:</strong> {note.description}
                </p>
              </div>
            ))}
          </>
        ) : (
          <p>This insighter has no admin notes.</p>
        )}
      </Card.Section>
      {data?.copied_to_name?.length > 0 ? <Card.Section title="Copied To">{data?.copied_to_name}</Card.Section> : ''}
      {data?.copied_from_name?.length > 0 ? (
        <Card.Section title="Copied From">{data?.copied_from_name}</Card.Section>
      ) : (
        ''
      )}
    </>
  );

  const pageMarkup = (
    <div className="respondents-table-wrapper">
      <DataTable
        columns={respondentColumns(phase, handleUpdateResponse)}
        data={respondents}
        pagination
        progressPending={respondentLoading}
        highlightOnHover
        pointerOnHover
        noHeader
        customStyles={customStyles}
        expandableRows
        expandableRowsComponent={<ExpandedComponent data />}
        paginationServer
        paginationPerPage={25}
        paginationRowsPerPageOptions={[25]}
        paginationTotalRows={totalRecords}
        onChangePage={handlePageChange}
        sortServer
        onSort={handleColumnSort}
        selectableRows
        onSelectedRowsChange={handleSelectedRows}
        selectableRowSelected={rowSelectCritera}
      />
    </div>
  );

  return (
    <>
      {loadingMarkup}
      {pageMarkup}
      {toastMarkup}
    </>
  );
};

export default PhaseRespondentsList;
