import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FilledInput, FormControl, FormHelperText, Select, Tooltip } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import HelpIcon from '@material-ui/icons/Help';
import { InputElementEvent, SelectOption } from '../model';

interface SelectInputProps {
  label: string;
  value: string;
  onChange: (value: string) => void;
  tooltip?: string;
  styleOverrides?: string;
  errorMessage?: string;
  isFilled?: boolean;
  type?: string;
  choices: SelectOption[];
  defaultChoice?: SelectOption;
  textColor?: string;
}

const useStyles = makeStyles(() => ({
  textField: {
    minHeight: '30px',
    width: '100%',
    padding: 0,
  },
  helperText: {
    fontSize: '12px',
  },
}));

const SelectInput: React.FC<SelectInputProps> = (props) => {
  const { label, value = '', onChange, tooltip, styleOverrides, errorMessage, choices = [], defaultChoice } = props;

  const classes = useStyles();

  const handleChange = (event: InputElementEvent): void => {
    onChange(event.target.value);
  };

  const createMenuItem = (choice: SelectOption) =>
    choice ? (
      <MenuItem value={choice.value} key={choice.value}>
        <span style={{ fontSize: '1.4rem', padding: '4px 0' }}>{choice.label}</span>
      </MenuItem>
    ) : null;

  const defaultMenuItem = createMenuItem(defaultChoice);
  const menuItemChoices = choices.map((choice) => createMenuItem(choice));

  return (
    <FormControl variant="filled" error={errorMessage?.length > 0} className={`${styleOverrides} ${classes.textField}`}>
      <div className="Polaris-Labelled__LabelWrapper">
        <div className="Polaris-Label">
          <label className="Polaris-Label__Text" htmlFor="formInput">
            {label}
            {tooltip && (
              <Tooltip title={<p style={{ fontSize: '1.4rem', padding: 5 }}>{tooltip}</p>}>
                <HelpIcon style={{ marginLeft: 5 }} />
              </Tooltip>
            )}
          </label>
        </div>
      </div>
      <Select
        value={value}
        input={<FilledInput onChange={handleChange} />}
        SelectDisplayProps={{ style: { fontSize: '1.4rem', padding: '18px 12px 12px' } }}
      >
        {defaultMenuItem}
        {menuItemChoices}
      </Select>
      {errorMessage && <FormHelperText className={classes.helperText}>{`${label} ${errorMessage}`}</FormHelperText>}
    </FormControl>
  );
};

export default SelectInput;
