import React from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import MediumOrnament from '../../common/MediumOrnament';
import SectionOrnament from '../../common/SectionOrnament';
import useStyles from '../../constants/redesignStyles';

import CollaborationRectangle from '../assets/CollaborationRectangle.png';

import MissionIcon from '../assets/MissionIcon.png';
import ValuesIcon from '../assets/ValuesIcon.png';
import GoalsIcon from '../assets/GoalsIcon.png';

import PartnerLogosClickthrough from '../components/PartnerLogosClickthrough';

const useStyleOverrides = makeStyles((theme) => ({
  partnershipsContainer: {
    position: 'absolute',
    bottom: -280,

    [theme.breakpoints.down(1200)]: {
      bottom: -240,
    },

    [theme.breakpoints.down(1100)]: {
      bottom: -200,
    },

    [theme.breakpoints.down(960)]: {
      position: 'relative',
      bottom: 0,
      display: 'inherit',
      alignItems: 'center',
      flexDirection: 'column',
    },
  },
  collabHeading: {
    [theme.breakpoints.down(960)]: {
      marginTop: 20,
      textAlign: 'center',
    },
  },
  genericText: {
    [theme.breakpoints.down(960)]: {
      textAlign: 'center',
    },
  },
  collaborationWrapper: {
    paddingLeft: '10%',
    paddingRight: '5%',
    marginBottom: 20,

    [theme.breakpoints.down(960)]: {
      padding: 0,
    },
  },
}));

const styles = {
  collabHeading: {
    color: 'white',
    fontSize: 36,
    letterSpacing: -2,
    fontWeight: 600,
    fontFamily: 'Crimson Text',
    lineHeight: 1.1,
  },
  collabHeading2: {
    color: 'white',
    fontSize: 28,
    letterSpacing: -2,
    fontWeight: 500,
    fontFamily: 'Crimson Text',
    lineHeight: 1.1,
  },
  collabBody: {
    color: 'white',
    fontSize: 16,
    fontFamily: 'Rethink Sans',
  },
};

const values = [
  {
    title: 'Mission',
    icon: MissionIcon,
    description:
      'We match patients, caregivers, and healthcare professionals (HCPs) with research opportunities to ensure healthcare is made more accessible & user-friendly.',
  },
  {
    title: 'Values',
    icon: ValuesIcon,
    description:
      'We value excellence, integrity, attention to detail, and ensuring our patients, caregivers, and HCPs feel fully heard and satisfied with their research opportunity experience.',
  },
  {
    title: 'Goals',
    icon: GoalsIcon,
    description:
      'Together, we work to bridge the gaps in the healthcare industry. The expertise of our patients, caregivers, and HCPs is vital to making a difference in how medications are studied and introduced!',
  },
];

const ValueBand: React.FC<{ value: any }> = ({ value }) => {
  const customClasses = useStyleOverrides();

  return (
    <Grid container style={{ marginBottom: 20 }}>
      <Grid item xs={12} md={2} style={{ display: 'flex', justifyContent: 'center', marginBottom: 20 }}>
        <img src={value?.icon} alt={value?.title} style={{ width: '75px', height: '75px' }} />
      </Grid>
      <Grid item xs={12} md={10}>
        <Grid container>
          <Grid item xs={12}>
            <Typography className={customClasses.genericText} variant="h5" style={styles.collabHeading2}>
              {value?.title}
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', marginTop: -5 }}>
            <div className={useStyles().hrLine} style={{ width: '100%' }} />
          </Grid>
          <Grid item xs={12} style={{ marginTop: -10 }}>
            <Typography className={customClasses.genericText} variant="body1" style={styles.collabBody}>
              {value?.description}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const ContentSection: React.FC = () => {
  const customClasses = useStyleOverrides();
  const [isMobile, setIsMobile] = React.useState(window.innerWidth <= 960);

  const handleResize = () => {
    if (window.innerWidth <= 960) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  // Add event listener for resize on component mount
  React.useEffect(() => {
    window.addEventListener('resize', handleResize);
    // Clean up event listener on unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Grid
      container
      style={{
        position: 'relative',
        padding: '2%',
        display: 'flex',
        justifyContent: 'center',
        maxWidth: 1400,
        margin: 'auto',
      }}
    >
      {/* Background Image */}
      {isMobile ? (
        <div
          style={{
            backgroundColor: '#3B4255',
            marginLeft: '-10%',
            marginRight: '-10%',
            width: '120%',
            height: '1850px',
          }}
        />
      ) : (
        <img
          src={CollaborationRectangle}
          alt="Collaboration Rectangle"
          style={{ width: '100%', height: '100%', objectFit: 'contain' }}
        />
      )}

      <Grid item xs={12} style={{ position: 'absolute', top: isMobile ? '4%' : '12%' }}>
        <Grid container>
          {/* Top band of content */}
          <Grid item xs={12} md={5} className={customClasses.collaborationWrapper}>
            {isMobile ? (
              <MediumOrnament text="Excellence Every Step of the Way" width="300px" centerText centerComponent />
            ) : (
              <SectionOrnament text="Excellence Every Step of the Way" />
            )}
            <Typography className={customClasses.collabHeading} style={{ ...styles.collabHeading, marginBottom: 15 }}>
              Collaboration is at the Heart of What We Do
            </Typography>
            <Typography className={customClasses.genericText} style={styles.collabBody}>
              Explore how our dedication to integrity, collaboration, and innovation drives everything we do.
            </Typography>
          </Grid>
          <Grid item xs={12} md={7} style={{ paddingLeft: '5%', paddingRight: '15%' }}>
            {values.map((value, index) => (
              <ValueBand key={index} value={value} />
            ))}
          </Grid>
          {/* Bottom band of content */}
          <Grid item xs={12} style={{ marginTop: 30 }}>
            <Grid container className={customClasses.partnershipsContainer}>
              <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                <MediumOrnament text="Working Together" centerText />
              </Grid>
              <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                <Typography style={{ ...styles.collabHeading, marginBottom: 15 }}>Our Trusted Partnerships</Typography>
              </Grid>
              <Grid
                item
                xs={12}
                style={{ display: 'flex', justifyContent: 'center', marginBottom: 20, paddingLeft: '5%' }}
              >
                <Typography style={styles.collabBody}>
                  See how The Insighters® network of partners unlocks innovation and illuminates change in healthcare.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <PartnerLogosClickthrough />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ContentSection;
