import React from 'react';
import { Button, Card, Loading } from '@shopify/polaris';
import { useHistory } from 'react-router-dom';
import DataTableSkeleton from '../../loading/components/DataTableSkeleton';
import DataTable from '../../dataTable/DataTable/DataTable';
import { ConditionCategoriesContext } from '../contexts/ConditionCategoriesContext';
import { IDataTableColumn } from '../../dataTable/DataTable/model';
import Page from '../../shared/components/Page';

const categoryColumns = (): IDataTableColumn[] => [
  {
    name: 'Category',
    selector: 'name',
    sortable: true,
  },
  {
    name: 'Condition Counter',
    selector: 'condition_count',
    sortable: true,
  },
  {
    name: 'Insighter Counter',
    selector: 'insighter_count',
    sortable: true,
  },
  {
    name: 'Actions',
    selector: 'status',
    cell: (row): React.ReactNode => (
      <div style={{ marginRight: 10 }}>
        <Button url={`/admin/conditions?filters=ransack[condition_categories_id_in]=${row.id}`}>View Conditions</Button>
        <Button url={`/admin/condition_categories/${row.id}/edit`}>Edit</Button>
      </div>
    ),
  },
];

const ConditionCategoriesList: React.FunctionComponent = () => {
  const history = useHistory();
  const [conditionCategoriesFetched, setConditionCategoriesFetched] = React.useState<boolean>(false);

  const { conditionCategories, fetchConditionCategories, conditionCategoriesLoading } =
    React.useContext(ConditionCategoriesContext);

  React.useEffect(() => {
    if (!conditionCategoriesFetched) {
      setConditionCategoriesFetched(true);
      fetchConditionCategories();
    }
  }, [conditionCategoriesFetched, setConditionCategoriesFetched, fetchConditionCategories]);

  const loadingMarkup = conditionCategoriesLoading ? <Loading /> : null;

  const actualPageMarkup = (
    <Page
      fullWidth
      title="Condition Categories"
      primaryAction={{
        content: 'Create Condition Category',
        onAction: () => history.push('/admin/conditions/categories/new'),
      }}
    >
      <Card>
        <DataTable
          onRowClicked={(row: Record<string, string>) =>
            history.push(`/admin/conditions?filters=ransack[condition_categories_id_in]=${row.id}`)
          }
          columns={categoryColumns()}
          progressPending={conditionCategoriesLoading}
          data={conditionCategories}
          pagination
          striped
          highlightOnHover
          pointerOnHover
          noHeader
          paginationPerPage={25}
        />
      </Card>
    </Page>
  );

  // ---- Loading ----
  const loadingPageMarkup = <DataTableSkeleton />;

  // const pageMarkup = isLoading ? loadingPageMarkup : actualPageMarkup;
  const pageMarkup = conditionCategoriesLoading ? loadingPageMarkup : actualPageMarkup;

  return (
    <>
      {loadingMarkup}
      {pageMarkup}
    </>
  );
};

export default ConditionCategoriesList;
