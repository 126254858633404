import React from 'react';
import { useParams } from 'react-router-dom';
import { Badge, Loading, Page, Toast, Icon } from '@shopify/polaris';
import { CircleTickMajor, CircleAlertMajor, CircleDisabledMajor } from '@shopify/polaris-icons';
import { ProjectPhasesContext } from '../contexts/ProjectPhasesContext';
import DetailPageSkeleton from '../../loading/components/DetailPageSkeleton';
import BackButton from '../../navigation/components/BackButton';
import PhaseChecklist from '../components/PhaseChecklist';
import { PhaseChecklistFormValues } from '../model';
import sharedChecklistCards from '../../launchChecklists/constants/sharedChecklistCards';

const PhaseLaunchChecklist: React.FunctionComponent = () => {
  const [phaseFetched, setPhaseFetched] = React.useState(false);

  const { phaseId } = useParams<Record<string, string>>();
  const {
    phase,
    fetchPhase,
    phaseLoading,
    activatePhase,
    errorMessage,
    fetchPhaseLaunchChecklist,
    phaseLaunchChecklist,
    updatePhaseLaunchChecklist,
    phaseSubmitting,
  } = React.useContext(ProjectPhasesContext);

  const [formValues, setFormValues] = React.useState<PhaseChecklistFormValues>({});

  React.useEffect(() => {
    if (!phaseFetched) {
      fetchPhase(phaseId);
      fetchPhaseLaunchChecklist(phaseId);
      setPhaseFetched(true);
    }
  }, [phaseFetched, fetchPhase, phaseId, fetchPhaseLaunchChecklist]);

  const [toastActive, setToastActive] = React.useState(false);
  const toggleToastActive = React.useCallback(() => setToastActive((toastActive) => !toastActive), []);
  const loadingMarkup = phaseLoading ? <Loading /> : null;
  const toastMarkup = toastActive ? <Toast onDismiss={toggleToastActive} content="Changes saved" /> : null;

  React.useEffect(() => {
    setFormValues({
      phase_id: phaseLaunchChecklist?.phase_id,
      leads_status: phaseLaunchChecklist?.leads_status,
      invitation_opportunity_status: phaseLaunchChecklist?.invitation_opportunity_status,
      calendly_status: phaseLaunchChecklist?.calendly_status,
      mailchimp_status: phaseLaunchChecklist?.mailchimp_status,
      qualtrics_status: phaseLaunchChecklist?.qualtrics_status,
      tinyurl_status: phaseLaunchChecklist?.tinyurl_status,
      sprout_status: phaseLaunchChecklist?.sprout_status,
      linktree_status: phaseLaunchChecklist?.linktree_status,
      leads_count: phaseLaunchChecklist?.leads_count,
      invitation_opportunities_count: phaseLaunchChecklist?.invitation_opportunities_count,

      opportunities_status: phaseLaunchChecklist?.opportunities_status,
      opportunities_description_status: phaseLaunchChecklist?.opportunities_description_status,
      opportunities_link_status: phaseLaunchChecklist?.opportunities_link_status,
      leads_manual_marketing_status: phaseLaunchChecklist?.leads_manual_marketing_status,
      leads_mailchimp_marketing_status: phaseLaunchChecklist?.leads_mailchimp_marketing_status,
      invitation_opportunity_partners_quota_status: phaseLaunchChecklist?.invitation_opportunity_partners_quota_status,
      invitation_opportunity_description_status: phaseLaunchChecklist?.invitation_opportunity_description_status,
      invitation_opportunity_link_status: phaseLaunchChecklist?.invitation_opportunity_link_status,
      invitation_opportunity_email_status: phaseLaunchChecklist?.invitation_opportunity_email_status,
      calendly_moderator_times_status: phaseLaunchChecklist?.calendly_moderator_times_status,
      calendly_booking_questions_status: phaseLaunchChecklist?.calendly_booking_questions_status,
      calendly_workflows_status: phaseLaunchChecklist?.calendly_workflows_status,
      manual_email_status: phaseLaunchChecklist?.manual_email_status,
      qualtrics_theme_status: phaseLaunchChecklist?.qualtrics_theme_status,
      qualtrics_name_status: phaseLaunchChecklist?.qualtrics_name_status,
      qualtrics_workflows_status: phaseLaunchChecklist?.qualtrics_workflows_status,
      qualtrics_delete_tests_status: phaseLaunchChecklist?.qualtrics_delete_tests_status,
      qualtrics_security_status: phaseLaunchChecklist?.qualtrics_security_status,
      qualtrics_update_feedback_form_status: phaseLaunchChecklist?.qualtrics_update_feedback_form_status,
      tinyurl_link_tested_status: phaseLaunchChecklist?.tinyurl_link_tested_status,
      sprout_caption_check_status: phaseLaunchChecklist?.sprout_caption_check_status,
      linktree_link_tested_status: phaseLaunchChecklist?.linktree_link_tested_status,
      opportunities_image_created: phaseLaunchChecklist?.opportunities_image_created,
      opportunities_active: phaseLaunchChecklist?.opportunities_active,
      invitation_opportunity_active: phaseLaunchChecklist?.invitation_opportunity_active,
      mailchimp_draft_started: phaseLaunchChecklist?.mailchimp_draft_started,
      mailchimp_test_email_sent: phaseLaunchChecklist?.mailchimp_test_email_sent,
      qualtrics_check_mappings: phaseLaunchChecklist?.qualtrics_check_mappings,
      sprout_instagram_image_created: phaseLaunchChecklist?.sprout_instagram_image_created,
      sprout_facebook_twitter_image_created: phaseLaunchChecklist?.sprout_facebook_twitter_image_created,
      linktree_link_turned_on: phaseLaunchChecklist?.linktree_link_turned_on,
      remove_contractor_access: phaseLaunchChecklist?.remove_contractor_access,
    });
  }, [phaseLaunchChecklist, setFormValues]);

  const statusBadge = () => {
    if (phase?.status === 'active') {
      return <Badge status="success">Active</Badge>;
    }
    return <Badge status="critical">Inactive</Badge>;
  };

  const successIndicator = (showSuccess: boolean, showDisabled: boolean) => {
    if (showSuccess) {
      return <Icon source={CircleTickMajor} color="success" />;
    }

    if (showDisabled) {
      return <Icon source={CircleDisabledMajor} color="success" />;
    }

    return <Icon source={CircleAlertMajor} color="critical" />;
  };

  const updateChecklist = () => {
    updatePhaseLaunchChecklist(phaseId, formValues);
  };

  const submitChecklistCard = {
    title: 'Set Phase to Active',
    description: 'After completing the tasks above, you may set the phase to active.',
    errorMessage,
    button: {
      label: 'Set Active',
      action: () => activatePhase(phase?.id),
    },
    loading: phaseSubmitting,
  };

  const checklistCards = [
    {
      genericCard: false,
      title: 'Phase Information',
      description: 'Ensure all required fields are added for internal tracking purposes and analytics.',
      checkIcons: [{ label: 'Phase Name', icon: successIndicator(phase?.name !== null, false) }],
      customLinkActive: !phase?.name || !phase?.location,
      customLinkLabel: 'Edit Phase',
      customLinkUrl: `/admin/phases/${phase?.id}/edit`,
    },
    {
      genericCard: false,
      title: 'Phase Timeline',
      description: 'Add a start and end date to automatically activate and deactivate the phase.',
      checkIcons: [
        {
          label: 'Overall Start Date',
          icon: successIndicator(phase?.start_date_form !== null, false),
        },
        {
          label: 'Overall End Date',
          icon: successIndicator(phase?.end_date_form !== null, false),
        },
      ],
      customLinkActive: !phase?.start_date_form || !phase?.end_date_form,
      customLinkLabel: 'Edit Timeline',
      customLinkUrl: `/admin/phases/${phase?.id}/edit`,
    },
    ...sharedChecklistCards('Phase', formValues),
  ];

  const actualPageMarkup = (
    <>
      <BackButton pageAction />
      <Page
        fullWidth
        title={`${phase?.name} Launch Checklist`}
        titleMetadata={statusBadge()}
        primaryAction={{
          content: 'Edit Phase',
          url: `/admin/phases/${phase?.id}/edit`,
        }}
        additionalMetadata={`Created on ${phase?.created_at}`}
      >
        <PhaseChecklist
          checklistCards={checklistCards}
          submitChecklistCard={submitChecklistCard}
          updateChecklist={updateChecklist}
          formValues={formValues}
          setFormValues={setFormValues}
        />
      </Page>
    </>
  );

  // ---- Loading ----
  const loadingPageMarkup = <DetailPageSkeleton />;

  const pageMarkup = phaseLoading || !phase?.id ? loadingPageMarkup : actualPageMarkup;

  return (
    <>
      {loadingMarkup}
      {pageMarkup}
      {toastMarkup}
    </>
  );
};

export default PhaseLaunchChecklist;
