import React from 'react';
import { useHistory } from 'react-router';
import { Grid, Typography } from '@material-ui/core';
import useStyles from '../../redesignedFrontend/constants/redesignStyles';
import ResourceAccordionPaint from '../../../assets/redesignAssets/staticImages/resource-accordion-paint.png';

const AccordionSeparatorBar: React.FC = () => {
  return (
    <Grid
      item
      xs={12}
      style={{
        display: 'flex',
        justifyContent: 'center',
        paddingLeft: '20px',
        marginTop: '5px',
        zIndex: 2,
      }}
    >
      <img
        src="https://cdn.theinsighters.com/KeyDetailsBorder.png"
        alt=""
        style={{ width: '240px', height: 'auto', margin: 10 }}
      />
    </Grid>
  );
};

const ResourcesAccordion: React.FC = () => {
  let history = useHistory();
  let classes = useStyles();

  return (
    <Grid
      container
      style={{
        position: 'relative',
        width: '100%',
        height: 'auto',
        display: 'flex',
        justifyContent: 'center',
        marginTop: 20,
      }}
    >
      <img
        src={ResourceAccordionPaint}
        alt="Resource Accordion Paint"
        style={{ position: 'absolute', width: '380px', height: '310px', zIndex: 0 }}
      />
      <Grid
        item
        xs={12}
        style={{ marginTop: 18, display: 'flex', justifyContent: 'center', zIndex: 1 }}
        onClick={() => history.push('/resources/blog_post')}
      >
        <Typography variant="h6" className={classes.navHeader}>
          Articles
        </Typography>
      </Grid>
      <AccordionSeparatorBar />
      <Grid
        item
        xs={12}
        style={{ cursor: 'pointer', marginTop: 10, display: 'flex', justifyContent: 'center', zIndex: 1 }}
        onClick={() => history.push('/resources/case_study')}
      >
        <Typography variant="h6" className={classes.navHeader}>
          Case Studies
        </Typography>
      </Grid>
      <AccordionSeparatorBar />
      <Grid
        item
        xs={12}
        style={{ cursor: 'pointer', marginTop: 10, display: 'flex', justifyContent: 'center', zIndex: 1 }}
        onClick={() => history.push('/resources/event')}
      >
        <Typography variant="h6" className={classes.navHeader}>
          Events
        </Typography>
      </Grid>
      <AccordionSeparatorBar />
      <Grid
        item
        xs={12}
        style={{ cursor: 'pointer', marginTop: 10, display: 'flex', justifyContent: 'center', zIndex: 1 }}
        onClick={() => history.push('/frequently-asked-questions')}
      >
        <Typography variant="h6" className={classes.navHeader}>
          Got a Question?
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ResourcesAccordion;
