import React, { useCallback, useState } from 'react';
import { Button, Card, Loading, Page, Toast } from '@shopify/polaris';
import { useHistory } from 'react-router-dom';
import DataTableSkeleton from '../../loading/components/DataTableSkeleton';
import DataTable from '../../dataTable/DataTable/DataTable';
import CalendlyEventFilterBar from '../components/CalendlyEventFilterBar';
import { CalendlyEventContext } from '../contexts/CalendlyEventContext';
import { IDataTableColumn } from '../../dataTable/DataTable/model';

const projectColumns = (): IDataTableColumn[] => [
  {
    name: 'Calendly Event Name',
    selector: 'name',
    sortable: true,
  },
  {
    name: 'Status',
    selector: 'status',
    sortable: true,
    cell: ({ status }): React.ReactNode => (
      <div
        style={{
          fontWeight: 700,
          textTransform: 'uppercase',
          fontSize: 14,
          color: status === 'active' ? 'green' : 'red',
        }}
      >
        {status}
      </div>
    ),
  },
  {
    name: 'Actions',
    selector: 'status',
    sortable: true,
    cell: (row): React.ReactNode => (
      <div style={{ marginRight: 10 }}>
        <Button url={`/admin/calendly-events/${row.id}/edit`}>Edit</Button>
      </div>
    ),
  },
];

interface CalendlyEventsListProps {
  isLoading: boolean;
}

const CalendlyEventsList: React.FunctionComponent<CalendlyEventsListProps> = (props) => {
  const { isLoading } = props;
  const [calendlyEventsFetched, setSpecialtiesFetched] = React.useState<boolean>(false);

  const { calendlyEvents, fetchCalendlyEvents, calendlyEventsLoading } = React.useContext(CalendlyEventContext);

  React.useEffect(() => {
    if (!calendlyEventsFetched) {
      setSpecialtiesFetched(true);
      fetchCalendlyEvents();
    }
  }, [calendlyEventsFetched, setSpecialtiesFetched, fetchCalendlyEvents]);

  const [toastActive, setToastActive] = useState(false);
  const loadingMarkup = isLoading ? <Loading /> : null;

  const toggleToastActive = useCallback(() => setToastActive((toastActive) => !toastActive), []);
  const toastMarkup = toastActive ? <Toast onDismiss={toggleToastActive} content="Changes saved" /> : null;

  const actualPageMarkup = (
    <Page fullWidth title="Calendly Events">
      <Card>
        <br />
        <CalendlyEventFilterBar />
        <DataTable
          columns={projectColumns()}
          progressPending={calendlyEventsLoading}
          data={calendlyEvents}
          pagination
          striped
          highlightOnHover
          pointerOnHover
          paginationPerPage={25}
          paginationRowsPerPageOptions={[25]}
          noHeader
        />
      </Card>
    </Page>
  );

  // ---- Loading ----
  const loadingPageMarkup = <DataTableSkeleton />;

  const pageMarkup = isLoading ? loadingPageMarkup : actualPageMarkup;

  return (
    <>
      {loadingMarkup}
      {pageMarkup}
      {toastMarkup}
    </>
  );
};

export default CalendlyEventsList;
