import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Grid, makeStyles } from '@material-ui/core';
import axios from '../../utils/axios.utils';

import { TeamBio } from '../model';

import DeleteTeamBioModal from '../components/DeleteTeamBioModal';
import BioCard from '../../redesignedFrontend/aboutPage/components/BioCard';
import BioModal from '../../redesignedFrontend/aboutPage/components/BioModal';

import ContentManagementButtons from '../../shared/components/ContentManagementButtons';

const useStyles = makeStyles({
  sectionGrid: {
    margin: 15,
  },
});

const TeamBioDetailPage: React.FC = () => {
  let { teamBioId } = useParams<{ teamBioId: string }>();

  let [teamBio, setTeamBio] = useState<TeamBio>();
  let [deleteTeamBioConfirmModalOpen, setDeleteTeamBioConfirmModalOpen] = useState(false);
  let [bioModalOpen, setBioModalOpen] = useState(false);

  const fetchTeamBio = async () => {
    try {
      const response = await axios.get(`team_bios/${teamBioId}`);
      setTeamBio(response?.data?.result);
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchTeamBio();
    // eslint-disable-next-line
  }, []);

  return (
    <Grid container style={{ maxWidth: '1600px', margin: 'auto', padding: '2%' }}>
      <BioModal bioModalOpen={bioModalOpen} setBioModalOpen={setBioModalOpen} bioModalInfo={teamBio} />
      <DeleteTeamBioModal
        teamBioId={teamBioId}
        deleteTeamBioModalOpen={deleteTeamBioConfirmModalOpen}
        setDeleteTeamBioConfirmModalOpen={setDeleteTeamBioConfirmModalOpen}
      />
      <Grid item xs={12} style={{ marginBottom: 20 }}>
        <ContentManagementButtons
          recordId={teamBioId}
          recordLink="team-bios"
          recordLabelPlural="Team Bios"
          recordLabelSingular="Team Bio"
          deleteModalOpen={setDeleteTeamBioConfirmModalOpen}
        />
      </Grid>
      <Grid item xs={12} style={{ marginBottom: 20 }}>
        <Grid container style={{ display: 'flex', justifyContent: 'center' }}>
          <Grid item xs={5}>
            <BioCard setBioModalOpen={setBioModalOpen} setBioModalInfo={setTeamBio} biodata={teamBio} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={4} style={{ marginBottom: 20 }}>
        <ContentManagementButtons
          recordId={teamBioId}
          recordLink="team-bios"
          recordLabelPlural="Team Bios"
          recordLabelSingular="Team Bio"
          deleteModalOpen={setDeleteTeamBioConfirmModalOpen}
        />
      </Grid>
    </Grid>
  );
};

export default TeamBioDetailPage;
