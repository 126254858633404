import React from 'react';
import { Card, Layout } from '@shopify/polaris';
import Page from '../../shared/components/Page';
import InsighterTagForm from '../components/InsighterTagForm';
import BackButton from '../../navigation/components/BackButton';

const NewInsighterTag: React.FunctionComponent = () => {
  return (
    <>
      <BackButton pageAction={false} />
      <Page fullWidth title="Create New Insighter Tag">
        <Layout>
          <Layout.Section>
            <Card sectioned>
              <InsighterTagForm />
            </Card>
          </Layout.Section>
          <Layout.Section secondary />
        </Layout>
      </Page>
    </>
  );
};

export default NewInsighterTag;
