import React from 'react';
import { useParams } from 'react-router-dom';
import { Badge, Loading, Toast, Icon } from '@shopify/polaris';
import Grid from '@material-ui/core/Grid';
import { CircleTickMajor, CircleAlertMajor, CircleDisabledMajor } from '@shopify/polaris-icons';
import { ProjectsContext } from '../contexts/ProjectsContext';
import DetailPageSkeleton from '../../loading/components/DetailPageSkeleton';
import BackButton from '../../navigation/components/BackButton';
import ProjectChecklist from '../components/ProjectChecklist';
import { ProjectChecklistFormValues } from '../model';
import sharedChecklistCards from '../../launchChecklists/constants/sharedChecklistCards';

import AdminPageHeader from '../../adminTools/components/AdminPageHeader';

const ProjectLaunchChecklist: React.FunctionComponent = () => {
  const [projectFetched, setProjectFetched] = React.useState(false);

  const { projectId } = useParams<Record<string, string>>();
  const {
    project,
    fetchProject,
    projectLoading,
    publishProject,
    errorMessage,
    fetchProjectLaunchChecklist,
    projectLaunchChecklist,
    updateProjectLaunchChecklist,
    projectSubmitting,
  } = React.useContext(ProjectsContext);

  const [formValues, setFormValues] = React.useState<ProjectChecklistFormValues>({});

  React.useEffect(() => {
    if (!projectFetched) {
      fetchProject(projectId);
      fetchProjectLaunchChecklist(projectId);
      setProjectFetched(true);
    }
  }, [projectFetched, fetchProject, projectId, fetchProjectLaunchChecklist]);

  const [toastActive, setToastActive] = React.useState(false);
  const toggleToastActive = React.useCallback(() => setToastActive((toastActive) => !toastActive), []);
  const loadingMarkup = projectLoading ? <Loading /> : null;
  const toastMarkup = toastActive ? <Toast onDismiss={toggleToastActive} content="Changes saved" /> : null;

  React.useEffect(() => {
    setFormValues({
      project_id: projectLaunchChecklist?.project_id,
      leads_status: projectLaunchChecklist?.leads_status,
      invitation_opportunity_status: projectLaunchChecklist?.invitation_opportunity_status,
      calendly_status: projectLaunchChecklist?.calendly_status,
      mailchimp_status: projectLaunchChecklist?.mailchimp_status,
      qualtrics_status: projectLaunchChecklist?.qualtrics_status,
      tinyurl_status: projectLaunchChecklist?.tinyurl_status,
      sprout_status: projectLaunchChecklist?.sprout_status,
      linktree_status: projectLaunchChecklist?.linktree_status,
      leads_count: projectLaunchChecklist?.leads_count,
      invitation_opportunities_count: projectLaunchChecklist?.invitation_opportunities_count,

      opportunities_status: projectLaunchChecklist?.opportunities_status,
      opportunities_description_status: projectLaunchChecklist?.opportunities_description_status,
      opportunities_link_status: projectLaunchChecklist?.opportunities_link_status,
      leads_manual_marketing_status: projectLaunchChecklist?.leads_manual_marketing_status,
      leads_mailchimp_marketing_status: projectLaunchChecklist?.leads_mailchimp_marketing_status,
      invitation_opportunity_partners_quota_status:
        projectLaunchChecklist?.invitation_opportunity_partners_quota_status,
      invitation_opportunity_description_status: projectLaunchChecklist?.invitation_opportunity_description_status,
      invitation_opportunity_link_status: projectLaunchChecklist?.invitation_opportunity_link_status,
      invitation_opportunity_email_status: projectLaunchChecklist?.invitation_opportunity_email_status,
      calendly_moderator_times_status: projectLaunchChecklist?.calendly_moderator_times_status,
      calendly_booking_questions_status: projectLaunchChecklist?.calendly_booking_questions_status,
      calendly_workflows_status: projectLaunchChecklist?.calendly_workflows_status,
      manual_email_status: projectLaunchChecklist?.manual_email_status,
      qualtrics_theme_status: projectLaunchChecklist?.qualtrics_theme_status,
      qualtrics_name_status: projectLaunchChecklist?.qualtrics_name_status,
      qualtrics_workflows_status: projectLaunchChecklist?.qualtrics_workflows_status,
      qualtrics_delete_tests_status: projectLaunchChecklist?.qualtrics_delete_tests_status,
      qualtrics_security_status: projectLaunchChecklist?.qualtrics_security_status,
      qualtrics_update_feedback_form_status: projectLaunchChecklist?.qualtrics_update_feedback_form_status,
      tinyurl_link_tested_status: projectLaunchChecklist?.tinyurl_link_tested_status,
      sprout_caption_check_status: projectLaunchChecklist?.sprout_caption_check_status,
      linktree_link_tested_status: projectLaunchChecklist?.linktree_link_tested_status,
      opportunities_image_created: projectLaunchChecklist?.opportunities_image_created,
      opportunities_active: projectLaunchChecklist?.opportunities_active,
      invitation_opportunity_active: projectLaunchChecklist?.invitation_opportunity_active,
      mailchimp_draft_started: projectLaunchChecklist?.mailchimp_draft_started,
      mailchimp_test_email_sent: projectLaunchChecklist?.mailchimp_test_email_sent,
      qualtrics_check_mappings: projectLaunchChecklist?.qualtrics_check_mappings,
      sprout_instagram_image_created: projectLaunchChecklist?.sprout_instagram_image_created,
      sprout_facebook_twitter_image_created: projectLaunchChecklist?.sprout_facebook_twitter_image_created,
      linktree_link_turned_on: projectLaunchChecklist?.linktree_link_turned_on,
      remove_contractor_access: projectLaunchChecklist?.remove_contractor_access,
    });
  }, [projectLaunchChecklist, setFormValues]);

  const statusBadge = () => {
    if (project?.status === 'draft') {
      return <Badge status="warning">Draft</Badge>;
    }
    if (project?.status === 'published') {
      return <Badge status="success">Published</Badge>;
    }
    return <Badge status="critical">Inactive</Badge>;
  };

  const successIndicator = (showSuccess: boolean, showDisabled: boolean) => {
    if (showSuccess) {
      return <Icon source={CircleTickMajor} color="success" />;
    }

    if (showDisabled) {
      return <Icon source={CircleDisabledMajor} color="success" />;
    }

    return <Icon source={CircleAlertMajor} color="critical" />;
  };

  const updateChecklist = () => {
    updateProjectLaunchChecklist(projectId, formValues);
  };

  const submitChecklistCard = {
    title: 'Publish Project',
    description: 'After completing the tasks above, you may publish the project.',
    errorMessage,
    button: {
      label: 'Publish',
      action: () => publishProject(project?.id),
    },
    loading: projectSubmitting,
  };

  const checklistCards = [
    {
      genericCard: false,
      title: 'Project Information',
      description: 'Ensure all required fields are added for internal tracking purposes and analytics.',
      checkIcons: [
        { label: 'Project Number', icon: successIndicator(project.project_number !== null, false) },
        { label: 'Project Name', icon: successIndicator(project.name !== null, false) },
        { label: 'Project Manager', icon: successIndicator(project.project_manager !== null, false) },
        { label: 'Client Name', icon: successIndicator(project.client_name !== null, false) },
        { label: 'Recruiter', icon: successIndicator(project.recruiter !== null, false) },
        { label: 'Primary Moderator', icon: successIndicator(project.primary_moderator !== null, false) },
      ],
      customLinkActive:
        !project?.project_number ||
        !project?.name ||
        !project?.client_name ||
        !project?.project_manager ||
        !project?.recruiter ||
        !project?.primary_moderator,
      customLinkLabel: 'Edit Project',
      customLinkUrl: `/admin/projects/${project?.id}/edit`,
    },
    {
      genericCard: false,
      title: 'Project Timeline',
      description: 'Add a start and end date to automatically activate and deactivate the project.',
      checkIcons: [
        {
          label: 'Overall Start Date',
          icon: successIndicator(project?.project_timeline?.overall_start_date_form !== null, false),
        },
        {
          label: 'Overall End Date',
          icon: successIndicator(project?.project_timeline?.overall_end_date_form !== null, false),
        },
      ],
      customLinkActive:
        !project?.project_timeline?.overall_start_date_form || !project?.project_timeline?.overall_end_date_form,
      customLinkLabel: 'Edit Timeline',
      customLinkUrl: `/admin/projects/${project?.id}/edit`,
    },
    {
      genericCard: false,
      title: 'Phases',
      description: 'Add a phase to the project.',
      checkIcons: [{ label: 'Phase', icon: successIndicator(project?.phases?.length > 0, false) }],
      customLinkActive: project?.phases?.length === 0,
      customLinkLabel: 'Add Phase',
      customLinkUrl: `/admin/projects/${project?.id}/phases/new`,
    },
    {
      genericCard: true,
      title: 'Opportunities',
      description: 'Add an opportunity to display to Insighters.',
      checkIcons: [
        {
          label: 'Image Created',
          checked: formValues?.opportunities_image_created === 'complete',
          disabled: formValues?.opportunities_image_created === 'not needed',
          databaseKey: 'opportunities_image_created',
        },
        {
          label: 'Project has at least one opportunity',
          checked: project?.opportunities?.length > 0 || formValues?.opportunities_status === 'complete',
          disabled: formValues?.opportunities_status === 'not needed',
          databaseKey: 'opportunities_status',
        },
        {
          label: 'Check description = Qualtrics intro',
          checked: formValues?.opportunities_description_status === 'complete',
          disabled: formValues?.opportunities_description_status === 'not needed',
          databaseKey: 'opportunities_description_status',
        },
        {
          label: 'Link tested',
          checked: formValues?.opportunities_link_status === 'complete',
          disabled: formValues?.opportunities_link_status === 'not needed',
          databaseKey: 'opportunities_link_status',
        },
        {
          label: 'Opportunity Active',
          checked: formValues?.opportunities_active === 'complete',
          disabled: formValues?.opportunities_active === 'not needed',
          databaseKey: 'opportunities_active',
        },
      ],
      customLinkActive: project?.opportunities?.length === 0,
      customLinkLabel: 'Add Opportunity',
      customLinkUrl: `/admin/projects/${project?.id}/opportunities/new`,
    },
    ...sharedChecklistCards('Project', formValues),
  ];

  // ---- Page markup ----
  const actualPageMarkup = (
    <Grid container style={{ padding: '1% 2%' }}>
      <AdminPageHeader
        title={`${project?.name} Launch Checklist`}
        badge={{ label: project?.status }}
        backButton={{ label: project?.name, link: `/admin/projects/${project?.id}` }}
      />
      <Grid item xs={12}>
        <ProjectChecklist
          checklistCards={checklistCards}
          submitChecklistCard={submitChecklistCard}
          updateChecklist={updateChecklist}
          formValues={formValues}
          setFormValues={setFormValues}
        />
      </Grid>
    </Grid>
  );

  // ---- Loading ----
  const loadingPageMarkup = <DetailPageSkeleton />;

  const pageMarkup = projectLoading || !project?.id ? loadingPageMarkup : actualPageMarkup;

  return (
    <>
      {loadingMarkup}
      {pageMarkup}
      {toastMarkup}
    </>
  );
};

export default ProjectLaunchChecklist;
