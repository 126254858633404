import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';

// import Balasa from '../assets/Balasa.png';
import BWICR from '../assets/BWICR.png';
import Cervivor from '../assets/Cervivor.png';
import DrAlexea from '../assets/DrAlexea.png';
import ELLCIA from '../assets/ELLCIA.png';
import ForBreast from '../assets/ForBreast.png';
import Lilmesican from '../assets/Lilmesican.png';
import MissingPink from '../assets/MissingPink.png';
import MLW from '../assets/MLW.png';

// Balasa consulting left out for now because its so much wider than the other images.
// Will probably need to do some image editing to make it work.

const pics = [
  // Balasa,
  BWICR,
  Cervivor,
  DrAlexea,
  ELLCIA,
  ForBreast,
  Lilmesican,
  MissingPink,
  MLW,
];

const useStyles = makeStyles({
  '@keyframes slide': {
    '0%': {
      transform: 'translateX(0%)',
    },
    '100%': {
      transform: 'translateX(-100%)',
    },
  },
  slideContainer: {
    overflow: 'hidden',
    display: 'flex',
    width: '100%', // Adjust to the width of the parent container
  },
  slideTrack: {
    display: 'flex',
    animation: '$slide 30s linear infinite',
    width: `${pics.length * 100}%`, // Adjust width based on the number of slides
  },
  slideCard: {
    width: `${100 / pics.length}%`, // Divide the width evenly by the number of images
    flexShrink: 0,
    display: 'flex',
    justifyContent: 'center',
  },
});

const doubledSlides = Object.values(pics)?.concat(Object.values(pics));

const TechPartnersCarousel: React.FC = () => {
  const classes = useStyles();

  return (
    <Grid container className={classes.slideContainer}>
      <div className={classes.slideTrack}>
        {doubledSlides.map((cardDetail, index) => (
          <Grid item className={classes.slideCard} key={`word-${index}`}>
            <img src={cardDetail} alt={`Pic-${index}`} style={{ height: 100, width: 'auto' }} />
          </Grid>
        ))}
      </div>
    </Grid>
  );
};

export default TechPartnersCarousel;
