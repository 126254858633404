import React, { useState, useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import $ from 'jquery';
import 'jquery-ui-bundle';
import DataTable from '../../dataTable/DataTable/DataTable';
import LoadingSpinner from '../../loading/components/LoadingSpinner';
import { ProjectsContext } from '../contexts/ProjectsContext';
import { UserContext } from '../../auth/contexts/UserContext';
import projectColumns from '../constants/projectColumns';

const ClientProjectsList: React.FunctionComponent = () => {
  let [projectsFetched, setProjectsFetched] = React.useState(false);

  let {
    projects,
    fetchProjects,
    projectLoading,
    totalRecords,
    sortParams,
    setSortParams,
    filterParams,
    projectsDownload,
  } = useContext(ProjectsContext);
  let { currentUserHasRole } = useContext(UserContext);

  let { sortProjects } = useContext(ProjectsContext);
  let [isSorting, setIsSorting] = useState(false);

  let toggleSorting = (): void => {
    if (isSorting) {
      sortProjects($('.rdt_TableBody:first').sortable('serialize'));
      $('.rdt_TableBody:first').sortable('destroy');
    } else {
      $('.rdt_TableBody:first').sortable();
    }

    setIsSorting(!isSorting);
  };

  React.useEffect(() => {
    const clientProjectFilters = sessionStorage.getItem('clientProjectFilters');

    if (!projectsFetched) {
      if (clientProjectFilters) {
        fetchProjects(clientProjectFilters);
      } else {
        fetchProjects('ransack[client_name_not_eq]=Sommer Consulting');
      }
      setProjectsFetched(true);
    }
  }, [projectsFetched, fetchProjects]);

  const history = useHistory();

  const handleColumnSort = async (column: Record<string, string>, sortDirection: string) => {
    const columnNamesToRansack = {
      project_number: 'project_number',
      name: 'name',
      client_name: 'client_name',
      start_date: 'project_timeline_overall_start_date',
      end_date: 'project_timeline_overall_end_date',
      project_manager_name: 'project_manager_last_name',
      recruiter_name: 'recruiter_last_name',
      status: 'status',
    };

    const sort = `sort=${columnNamesToRansack[column.selector]} ${sortDirection}`;

    setSortParams(sort);
    fetchProjects(`ransack[client_name_not_eq]=Sommer Consulting&${filterParams}&${sort}`);
  };

  const handlePageChange = (page: number) => {
    fetchProjects(`page=${page}&ransack[client_name_not_eq]=Sommer Consulting&${filterParams}&${sortParams}`);
  };

  if (projectLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Grid container>
      {currentUserHasRole('admin') && (
        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button className={isSorting ? 'secondary-btn' : 'primary-btn'} onClick={() => toggleSorting()}>
            {isSorting ? 'Save Order' : 'Sort Projects'}
          </Button>
        </Grid>
      )}
      <Grid item xs={12}>
        <DataTable
          onRowClicked={(row: Record<string, string>) => history.push(`/admin/projects/${row.id}`)}
          columns={projectColumns()}
          data={projects}
          progressPending={projectLoading}
          striped
          highlightOnHover
          pointerOnHover
          noHeader
          pagination
          paginationPerPage={25}
          paginationRowsPerPageOptions={[25]}
          paginationServer
          paginationTotalRows={totalRecords}
          onChangePage={handlePageChange}
          sortServer
          onSort={handleColumnSort}
        />
      </Grid>
    </Grid>
  );
};

export default ClientProjectsList;
