const Colors = {
  DarkNavy: '#3b4255',
  LightNavy: '#545d78',
  Gray: '#b2b2b2',
  Yellow: '#e8ac34',
  Green: '#72a795',
  Red: '#f48574',
};

export default Colors;
