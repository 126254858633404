import React from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';

import InternalUserForm from '../components/InternalUserForm';

import AdminPageHeader from '../../adminTools/components/AdminPageHeader';

const NewInternalUser: React.FunctionComponent = () => {
  return (
    <Grid container style={{ padding: '1% 2%' }}>
      <AdminPageHeader title="New Internal User" backButton={{ label: 'Internal Users', link: '/admin/users' }} />
      <Card style={{ padding: '2%' }}>
        <InternalUserForm internalUser={{}} />
      </Card>
    </Grid>
  );
};

export default NewInternalUser;
