import React, { useCallback, useState, useContext, useEffect } from 'react';
import { Loading, Toast, Button } from '@shopify/polaris';
import { Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import DataTable from '../../dataTable/DataTable/DataTable';
import { ProjectsContext } from '../contexts/ProjectsContext';
import { projectColumnsFinancial } from '../constants/projectColumns';
import ColumnSelector from '../../shared/components/ColumnSelector';
import PrepareDownloadButton from '../../shared/components/PrepareDownloadButton';
import todaysDate from '../../utils/dateFunctions';

const ClientProjectsListFinancial: React.FunctionComponent = () => {
  const { projects, fetchProjects, projectLoading, totalRecords, sortParams, setSortParams, filterParams } =
    useContext(ProjectsContext);

  const [projectsFetched, setProjectsFetched] = React.useState(false);
  const [visibleColumns, setVisibleColumns] = useState([]);
  const [linkForDownload, setLinkForDownload] = useState('');

  useEffect(() => {
    let clientProjectFilters = sessionStorage.getItem('clientProjectFilters');

    if (clientProjectFilters) {
      clientProjectFilters += '&financial=true';
    } else {
      clientProjectFilters = 'ransack[client_name_not_eq]=Sommer Consulting&financial=true';
    }

    if (!projectsFetched) {
      fetchProjects(clientProjectFilters);
      setProjectsFetched(true);
    }
  }, [projectsFetched, fetchProjects]);

  const history = useHistory();
  const [toastActive, setToastActive] = useState(false);
  const loadingMarkup = projectLoading ? <Loading /> : null;

  const toggleToastActive = useCallback(() => setToastActive((toastActive) => !toastActive), []);
  const toastMarkup = toastActive ? <Toast onDismiss={toggleToastActive} content="Changes saved" /> : null;

  useEffect(() => {
    setLinkForDownload(`/projects?ransack[client_name_not_eq]=Sommer Consulting&${filterParams}&financial=true`);
  }, [filterParams]);

  const handleColumnSort = async (column: Record<string, string>, sortDirection: string) => {
    const columnNamesToRansack = {
      project_number: 'project_number',
      name: 'name',
      client_name: 'client_name',
      start_date: 'project_timeline_overall_start_date',
      end_date: 'project_timeline_overall_end_date',
      project_manager_name: 'project_manager_last_name',
      recruiter_name: 'recruiter_last_name',
      status: 'status',
    };

    const sort = `sort=${columnNamesToRansack[column.selector]} ${sortDirection}`;

    setSortParams(sort);
    fetchProjects(`ransack[client_name_not_eq]=Sommer Consulting&${filterParams}&${sort}&financial=true`);
  };

  const handlePageChange = (page: number) => {
    fetchProjects(
      `page=${page}&ransack[client_name_not_eq]=Sommer Consulting&${filterParams}&${sortParams}&financial=true`,
    );
  };

  const pageMarkup = (
    <>
      <Grid style={{ display: 'flex', justifyContent: 'flex-end', marginRight: 20 }}>
        <PrepareDownloadButton
          filename={`Projects ${todaysDate()}.csv`}
          visibleColumns={visibleColumns}
          link={linkForDownload}
        />
      </Grid>

      <ColumnSelector setVisibleColumns={setVisibleColumns} columnChoices={projectColumnsFinancial} />
      <DataTable
        onRowClicked={(row: Record<string, string>) => history.push(`/admin/projects/${row.id}`)}
        columns={visibleColumns}
        data={projects}
        progressPending={projectLoading}
        striped
        highlightOnHover
        pointerOnHover
        noHeader
        pagination
        paginationPerPage={25}
        paginationRowsPerPageOptions={[25]}
        paginationServer
        paginationTotalRows={totalRecords}
        onChangePage={handlePageChange}
        sortServer
        onSort={handleColumnSort}
      />
    </>
  );

  return (
    <>
      {loadingMarkup}
      {pageMarkup}
      {toastMarkup}
    </>
  );
};

export default ClientProjectsListFinancial;
