import React from 'react';
import { useParams } from 'react-router-dom';
import { Card, Layout, Loading } from '@shopify/polaris';
import MarkCompleteForm from '../components/MarkCompleteForm';
import Page from '../../shared/components/Page';
import { InterviewsContext } from '../contexts/InterviewsContext';
import FormSkeleton from '../../loading/components/FormSkeleton';
import BackButton from '../../navigation/components/BackButton';

const MarkComplete: React.FunctionComponent = () => {
  const { interviewId } = useParams<Record<string, string>>();
  const [interviewFetched, setInterviewFetched] = React.useState<boolean>(false);
  const { interview, fetchInterview, interviewLoading, fetchFormOptions } = React.useContext(InterviewsContext);

  React.useEffect(() => {
    if (!interviewFetched) {
      fetchFormOptions();
      fetchInterview(interviewId);
      setInterviewFetched(true);
    }
  }, [interviewFetched, fetchInterview, interviewId, fetchFormOptions]);

  const loadingMarkup = interviewLoading ? <Loading /> : null;

  // ---- Page markup ----
  const actualPageMarkup = (
    <>
      <BackButton pageAction={false} />
      <Page
        fullWidth
        title={`${interview.project_name} Interview for ${interview.user_name}`}
        additionalMetadata={`Created on ${interview?.created_at}`}
      >
        <Layout>
          <Layout.Section>
            <Card sectioned>
              <MarkCompleteForm interview={interview} />
            </Card>
          </Layout.Section>
          <Layout.Section secondary />
        </Layout>
      </Page>
    </>
  );

  // ---- Loading ----
  const loadingPageMarkup = <FormSkeleton />;

  const pageMarkup = interviewLoading ? loadingPageMarkup : actualPageMarkup;

  return (
    <>
      {loadingMarkup}
      {pageMarkup}
    </>
  );
};

export default MarkComplete;
