import React from 'react';
import { useParams } from 'react-router-dom';
import { Loading } from '@shopify/polaris';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import ApprovePaymentForm from '../components/ApprovePaymentForm';
import { PaymentsContext } from '../contexts/PaymentsContext';
import InformationField from '../../form/components/InformationField';

import AdminPageHeader from '../../adminTools/components/AdminPageHeader';

const ApprovePayment: React.FunctionComponent = () => {
  const { paymentId } = useParams<Record<string, string>>();
  const [paymentFetched, setPaymentFetched] = React.useState<boolean>(false);
  const { payment, fetchPayment, paymentsLoading } = React.useContext(PaymentsContext);

  React.useEffect(() => {
    if (!paymentFetched) {
      fetchPayment(paymentId);
      setPaymentFetched(true);
    }
  }, [paymentFetched, fetchPayment, paymentId]);

  return (
    !paymentsLoading && (
      <Grid container style={{ padding: '1% 2%' }}>
        <AdminPageHeader title={payment.description} backButton={{ label: 'Payments', link: '/admin/payments' }} />
        <Grid item xs={12} style={{ marginBottom: 10, padding: '1%' }}>
          <Grid container spacing={2}>
            <Grid item>
              <InformationField label="Created On" value={payment.created_at} />
            </Grid>
            <Grid item>
              <InformationField label="Project Number" value={payment.project_number} />
            </Grid>
            <Grid item>
              <InformationField label="Project Name" value={payment.project_name} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Card style={{ padding: '2%' }}>{payment.id ? <ApprovePaymentForm payment={payment} /> : <Loading />}</Card>
        </Grid>
      </Grid>
    )
  );
};

export default ApprovePayment;
