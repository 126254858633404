import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { Grid, Typography } from '@material-ui/core';
import StayConnectedRect from '../../../assets/redesignAssets/staticImages/StayConnectedRect.png';
import useStyles from '../constants/redesignStyles';
import ShortHoverButton from './ShortHover';

const StayConnected: React.FC = () => {
  let classes = useStyles();
  let history = useHistory();

  let [enteredEmail, setEnteredEmail] = useState('');

  let sendToSignUp = () => {
    history.push(`/sign-up?email=${enteredEmail}`);
  };

  return (
    <Grid container style={{ position: 'relative' }}>
      <div style={{ position: 'absolute', width: '80%', zIndex: 0, display: 'flex', justifyContent: 'center' }}>
        <img
          src={StayConnectedRect}
          alt="Stay Connected"
          style={{ width: '100%', height: 'auto', position: 'relative', zIndex: 0 }}
        />
      </div>
      <Grid item xs={12} style={{ position: 'relative', zIndex: 2, paddingLeft: '10%', paddingTop: '10%' }}>
        <Typography className={classes.h2} style={{ color: 'white' }}>
          Stay Informed,
        </Typography>
        <Typography className={classes.h2} style={{ color: 'white' }}>
          Stay Connected
        </Typography>
        <p style={{ marginTop: 5, fontSize: 11, fontWeight: 400, color: 'white' }}>
          Get the Latest Opportunities, Insights & Events
        </p>
        <Grid
          container
          style={{
            backgroundColor: 'white',
            borderRadius: '20px',
            marginTop: 15,
            alignItems: 'center',
            padding: '3px 3px 3px 20px',
            width: '60%',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Grid item xs={5}>
            <input
              type="text"
              style={{ width: '100%', border: 'none', outline: 'none', fontFamily: 'Rethink Sans', fontSize: 16 }}
              placeholder="Enter your email"
              value={enteredEmail}
              onChange={(e) => setEnteredEmail(e.target.value)}
            />
          </Grid>
          <Grid item xs={7} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <ShortHoverButton text="Sign Me Up" onSubmit={sendToSignUp} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default StayConnected;
