import React, { useContext } from 'react';
import { useHistory } from 'react-router';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import { badgeColorByStatus } from '../../projectPhases/constants/phaseColumns';

import { UserContext } from '../../auth/contexts/UserContext';

// TODO: Can remove backButton, primaryButton, and secondaryButton props after converting them all to userButtons and adminButtons

interface AdminPageHeaderProps {
  title: string;
  backButton?: {
    label: string;
    link: string;
  };
  primaryButton?: {
    label: string;
    link: string;
  };
  secondaryButton?: {
    label: string;
    link: string;
  };
  badge?: {
    label: string;
  };
  userButtons?: React.ReactNode;
  adminButtons?: React.ReactNode;
}

const AdminPageHeader: React.FC<AdminPageHeaderProps> = (props) => {
  let history = useHistory();

  let { title, backButton, secondaryButton, primaryButton, badge, userButtons, adminButtons } = props;
  let { currentUserHasRole } = useContext(UserContext);

  return (
    <Grid container spacing={2} style={{ padding: 10 }}>
      <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          {backButton && (
            <Button onClick={() => history.push(backButton?.link)} className="secondary-btn">
              {backButton?.label}
            </Button>
          )}
        </div>
        <div style={{ display: 'flex', gap: 10 }}>
          {secondaryButton && (
            <Button onClick={() => history.push(secondaryButton?.link)} className="secondary-btn">
              {secondaryButton.label}
            </Button>
          )}
          {primaryButton && (
            <Button onClick={() => history.push(primaryButton?.link)} className="primary-btn">
              {primaryButton.label}
            </Button>
          )}
          {React.Children.map(userButtons, (child) => child)}
          {currentUserHasRole('admin') && React.Children.map(adminButtons, (child) => child)}
        </div>
      </Grid>
      <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
        <Typography style={{ fontSize: 24, fontWeight: 400 }}>{title}</Typography>
        {badge?.label && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginLeft: 35,
              backgroundColor: badgeColorByStatus[badge?.label],
              borderRadius: 15,
              paddingTop: 3,
              paddingBottom: 3,
              paddingLeft: 7,
              paddingRight: 7,
              minWidth: 75,
              fontWeight: 500,
              fontSize: 12,
            }}
          >
            {badge?.label?.charAt(0)?.toUpperCase() + badge?.label?.slice(1)}
          </div>
        )}
      </Grid>
    </Grid>
  );
};

export default AdminPageHeader;
