import React from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import useStyles from '../../constants/redesignStyles';
import TitleBackgroundImage from '../../../../assets/redesignAssets/TitleBackgroundImage.png';
import TitleSubBackgroundImage from '../../../../assets/redesignAssets/TitleSubBackground.png';
import MediumOrnament from '../../common/MediumOrnament';

const useCustomStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: 800,
    margin: 'auto',
    width: '80%',
  },
  titleBackground: {
    width: '100%',
    height: 'auto',
    top: 0,
    left: 0,
    zIndex: 0,
    objectFit: 'cover',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingRight: '30%',
    [theme.breakpoints.down(960)]: {
      padding: 0,
      textAlign: 'center',
    },
  },
  textWrapper: {
    paddingLeft: '10%',
    position: 'absolute',
    [theme.breakpoints.down(960)]: {
      padding: 0,
      textAlign: 'center',
      position: 'relative',
    },
  },
  h1Text: {
    position: 'relative',

    [theme.breakpoints.down(1300)]: {
      fontSize: '48px',
    },

    [theme.breakpoints.down(960)]: {
      textAlign: 'center',
      fontSize: '48px',
      marginTop: 0,
      lineHeight: '1em',
    },
  },
}));

const HowWeBecameCloud: React.FC = () => {
  const classes = useStyles();
  const customClasses = useCustomStyles();

  const [isLargeScreen, setIsLargeScreen] = React.useState<boolean>(window.innerWidth >= 960);

  React.useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 960);
    };

    window.addEventListener('resize', handleResize);

    // Initial check on mount
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Grid container className={customClasses.container}>
      {isLargeScreen && (
        <img src={TitleBackgroundImage} className={customClasses.titleBackground} alt="title background" />
      )}
      <Grid item xs={12} className={customClasses.textWrapper}>
        <div className={customClasses.content}>
          <MediumOrnament text="Uncover Our Story" centerText centerComponent={!isLargeScreen} />
        </div>
        <h1 className={`${classes.h1} ${customClasses.h1Text}`}>Bridging Your Stories Into</h1>
        <div style={{ position: 'relative', display: 'inline-block' }}>
          <img
            src={TitleSubBackgroundImage}
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              top: 0,
              left: 0,
              zIndex: 0,
              objectFit: 'cover',
            }}
            alt="title background"
          />
          <h1 className={`${classes.h1} ${customClasses.h1Text}`}>
            <span
              style={{
                background: 'linear-gradient(to right, #3B4255, #3D7C6B)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                paddingRight: 3,
              }}
            >
              Impactful Insights
            </span>
          </h1>
        </div>
      </Grid>
    </Grid>
  );
};

export default HowWeBecameCloud;
