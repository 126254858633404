import React from 'react';
import { FormLayout, TextContainer, Button, Banner } from '@shopify/polaris';
import { Grid } from '@material-ui/core';
import FormInput from '../../form/components/FormInput';
import SelectInput from '../../form/components/SelectInput';
import { PaymentsContext } from '../contexts/PaymentsContext';
import { Payment } from '../model';
import { payoutTypeChoices } from '../constants/paymentChoices';

interface ApprovePaymentFormProps {
  payment: Payment;
}

const ApprovePaymentForm: React.FC<ApprovePaymentFormProps> = (props) => {
  const { payment } = props;

  const [formValues, setFormValues] = React.useState<any>({});

  const { approvePayment, paymentSubmitting, formError, paymentsLoading } = React.useContext(PaymentsContext);

  React.useEffect(() => {
    setFormValues({
      payout_category: payment?.payout_category,
      notes: payment?.notes,
      number_amount: payment?.number_amount,
    });
  }, [payment]);

  // TODO: Should we handle this in the backend?
  const displayInsighterAddress = (): string => {
    // If insighter has no address, send error message
    if (!payment.insighter_address && !payment.survey_address) {
      return 'Insighter&apos;s profile address and Qualtrics address are blank.';
    }

    // If insighter has no qualtrics survey address, use billing address
    if (payment.insighter_address && !payment.survey_address) {
      return payment.insighter_address;
    }

    // If insighter has no billing address, use qualtrics survey address
    if (payment.survey_address && !payment.insighter_address) {
      return payment.survey_address;
    }

    // If both values are equal, show survey address
    return payment.survey_address;
  };

  const payoutCategoryLabel = () => {
    const selectedPayoutCategory = payoutTypeChoices.find((choice) => choice.value === formValues.payout_category);
    return selectedPayoutCategory?.label;
  };

  return (
    !paymentsLoading && (
      <FormLayout>
        <Grid container spacing={3}>
          <Grid item xs={11}>
            <TextContainer>
              <h2 className="Polaris-Heading">Payment Notes</h2>
              <p style={{ fontWeight: 700 }}>{`This user is getting paid by ${payoutCategoryLabel()}.`}</p>

              {formValues.payout_category === 'mail' && (
                <>
                  {payment.survey_address &&
                  payment.insighter_address &&
                  payment.survey_address !== payment.insighter_address ? (
                    <div>
                      <Banner title="Insighter has multiple addresses." status="critical">
                        <p>
                          <strong>Address from Profile:</strong> {payment.insighter_address}
                        </p>
                        <p>
                          <strong>Address from Qualtrics:</strong> {payment.survey_address}
                        </p>
                      </Banner>
                    </div>
                  ) : (
                    <p>{displayInsighterAddress()}</p>
                  )}
                </>
              )}

              {(formValues.payout_category === 'in-person' || formValues.payout_category === 'giftcard') && (
                <p>User email: {payment.user_email}</p>
              )}

              <p>Please submit any honorarium amount changes and notes.</p>
            </TextContainer>
            <br />
            <Grid container>
              <Grid item xs={12}>
                <SelectInput
                  label="Payout Category"
                  choices={payoutTypeChoices}
                  value={formValues.payout_category}
                  onChange={(value: string) => setFormValues({ ...formValues, payout_category: value })}
                />
              </Grid>
              <Grid item xs={12} style={{ marginTop: 10 }}>
                <FormInput
                  label="Honorarium Amount"
                  type="money"
                  value={formValues.number_amount}
                  onChange={(value: string) => {
                    const formattedValue = value.charAt(0) === '$' ? value.slice(1) : value;
                    setFormValues({ ...formValues, number_amount: formattedValue });
                  }}
                />
              </Grid>
              <Grid item xs={12} style={{ marginTop: 10 }}>
                <FormInput
                  label="Payment Notes"
                  value={formValues.notes}
                  onChange={(value: string) => setFormValues({ ...formValues, notes: value })}
                  multiline
                  rows={4}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {formError && <p style={{ color: 'red' }}>{formError}</p>}
        <Button primary onClick={() => approvePayment(payment.id, formValues)} loading={paymentSubmitting}>
          Approve
        </Button>
      </FormLayout>
    )
  );
};

export default ApprovePaymentForm;
