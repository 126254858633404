import React from 'react';

const BasicFooter: React.FunctionComponent = () => {
  return (
    <footer
      id="main-footer"
      style={{
        color: '#000',
        paddingTop: 80,
        paddingBottom: 20,
        position: 'absolute',
        bottom: 'auto',
        width: '100%',
      }}
    >
      <div style={{ textAlign: 'center' }}>
        <ul style={{ display: 'inline', textAlign: 'center', padding: 0 }}>
          <li style={{ display: 'inline-block' }}>
            <a
              style={{
                marginRight: 20,
                marginLeft: -10,
                fontFamily: 'Montserrat',
                color: '#000',
                textDecoration: 'none',
              }}
              href="/privacy-policy"
            >
              Privacy Policy
            </a>
          </li>
          <li style={{ display: 'inline-block' }}>
            <a
              style={{
                fontFamily: 'Montserrat',
                color: '#000',
                textDecoration: 'none',
                marginRight: 10,
                marginLeft: -10,
              }}
              href="/cookie-policy"
            >
              Cookie Policy
            </a>
          </li>
          <li style={{ display: 'inline-block' }}>
            <a
              style={{ fontFamily: 'Montserrat', color: '#000', textDecoration: 'none', marginRight: 10 }}
              href="/terms-of-use"
            >
              Terms of Use
            </a>
          </li>
          <li style={{ display: 'inline-block' }}>
            <a style={{ fontFamily: 'Montserrat', color: '#000', textDecoration: 'none' }} href="/pay-portal-terms">
              Pay Portal Terms
            </a>
          </li>
        </ul>
      </div>
      <div id="footer-bottom">
        <div className="container clearfix">
          <div id="footer-info" style={{ textAlign: 'center', fontFamily: 'Montserrat', fontSize: 12, marginTop: 10 }}>
            © SOMMER CONSULTING 2022 - ALL RIGHTS RESERVED.
          </div>
        </div>
      </div>
    </footer>
  );
};

export default BasicFooter;
