import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Typography } from '@mui/material';
import FormInput from '../../form/components/FormInput';
import ConfirmCancelModal from '../../modal/components/MaterialModal';
import axios from '../../utils/axios.utils';

// Just a small comment to test Slack notifications

type TerminateInsighterConfirmModalProps = {
  terminateModalOpen: boolean;
  setTerminateModalOpen: (value: boolean) => void;
  setInsighterSubmitting: (value: boolean) => void;
  insighterId: string;
};

function TerminateInsighterConfirmModal(props: TerminateInsighterConfirmModalProps): JSX.Element {
  const history = useHistory();

  const { terminateModalOpen, setTerminateModalOpen, setInsighterSubmitting, insighterId } = props;

  const [terminateReason, setTerminateReason] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [insighter, setInsighter] = useState<any>();

  const handleTerminate = () => {
    try {
      setInsighterSubmitting(true);
      axios
        .put<string, any>(`insighters_terminations/${insighterId}`, { terminate_reason: terminateReason })
        .then(() => {
          setInsighterSubmitting(false);
          history.push(`/admin/insighters/${insighterId}`);
        })
        .catch((error) => {
          setInsighterSubmitting(false);
          const errorMessages = Object.values(error.response.data.error);
          setErrorMessage(errorMessages.join(', '));
        });
    } catch {
      setInsighterSubmitting(false);
      setErrorMessage('Something went wrong, please reload the page and try again');
    }
  };

  useEffect(() => {
    axios.get(`insighters_terminations/${insighterId}`).then((response) => {
      setInsighter(response.data.result);
    });
  }, [insighterId]);

  return (
    <ConfirmCancelModal
      isOpen={terminateModalOpen}
      title="Merge these Users"
      onCancel={() => setTerminateModalOpen(false)}
      onConfirm={handleTerminate}
      confirmLabel="Terminate"
      confirmDisabled={terminateReason === ''}
      cancelLabel="Cancel"
      hasCancelButton
      hasConfirmButton
      hasFooterBar={false}
      height="600px"
    >
      <>
        <Typography variant="h4" style={{ marginTop: 20 }}>
          The following Insighters have used a common IP Address with this user. They will be tagged as &quot;Shared IP
          with Terminated User&quot;.
        </Typography>
        <br />
        {insighter?.associated_users?.map((user) => (
          <Typography key={user.id} variant="h5">
            {user.uuid} {user.name}
          </Typography>
        ))}

        <br />
        <Typography variant="h5" style={{ marginTop: 20 }}>
          Please enter an explanation for terminating this insighter
        </Typography>
        <FormInput type="text" label="" value={terminateReason} onChange={(value) => setTerminateReason(value)} />
        <br />
        <p style={{ color: 'red' }}>{errorMessage}</p>
      </>
    </ConfirmCancelModal>
  );
}

export default TerminateInsighterConfirmModal;
