import React, { useState, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ListItemIcon, MenuItem, makeStyles } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import AssignmentIcon from '@mui/icons-material/Assignment';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import RespondentForm from './RespondentForm';
import CopyToOtherPhaseForm from './CopyToOtherPhaseForm';
import CopyToInvOppForm from './CopyToInvOppForm';
import ConfirmCancelModal from '../../modal/components/MaterialModal';
import CustomMaterialMenu from '../../dataTable/rowMenu/CustomMaterialMenu';
import { Respondent } from '../model';
import { UserContext } from '../../auth/contexts/UserContext';

const useStyles = makeStyles(() => ({
  listItemIcon: {
    minWidth: '24px',
  },
  modalText: {
    fontSize: '20px',
    marginLeft: '20%',
    marginBottom: '20px',
    width: '60%',
  },
}));

interface RespondentRowMenuProps {
  respondent: Respondent;
  handleUpdateRespondent: any;
}

const RespondentRowMenu: React.FC<RespondentRowMenuProps> = (props) => {
  const { respondent, handleUpdateRespondent } = props;
  const classes = useStyles();
  const history = useHistory();
  const { phaseId } = useParams<Record<string, string>>();
  const [detailsModalOpen, setDetailsModalOpen] = useState(false);
  const [copyToOtherPhaseModalOpen, setCopyToOtherPhaseModalOpen] = useState(false);
  const [copyToInvOppModalOpen, setCopyToInvOppModalOpen] = useState(false);
  const { currentUserHasRole } = useContext(UserContext);

  return (
    <>
      <ConfirmCancelModal
        isOpen={detailsModalOpen}
        onCancel={() => setDetailsModalOpen(false)}
        title="Update Respondent"
      >
        <div style={{ margin: '40px 20px' }}>
          <RespondentForm
            respondent={respondent}
            afterAction={() => {
              setDetailsModalOpen(false);
              handleUpdateRespondent();
            }}
          />
        </div>
      </ConfirmCancelModal>
      <ConfirmCancelModal
        isOpen={copyToOtherPhaseModalOpen}
        onCancel={() => setCopyToOtherPhaseModalOpen(false)}
        title="Copy to Phase"
        height="450px"
      >
        <div style={{ margin: '40px 20px' }}>
          <CopyToOtherPhaseForm respondent={respondent} />
        </div>
      </ConfirmCancelModal>
      <ConfirmCancelModal
        isOpen={copyToInvOppModalOpen}
        onCancel={() => setCopyToInvOppModalOpen(false)}
        title="Copy to Invitation Opportunity"
        height="450px"
      >
        <div style={{ margin: '40px 20px' }}>
          <CopyToInvOppForm respondent={respondent} />
        </div>
      </ConfirmCancelModal>

      <CustomMaterialMenu size="small">
        <MenuItem style={{ fontSize: '1.4rem' }}>
          <ListItemIcon className={classes.listItemIcon}>
            <ContactMailIcon fontSize="small" color="primary" style={{ color: '#72A295', fontSize: '1.2em' }} />
          </ListItemIcon>
          {respondent.qualtrics_responses_url ? (
            <a
              href={`/admin/phases/${phaseId}/qualtrics_metadata/${respondent.id}`}
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: 'none' }}
            >
              View Qualtrics Metadata
            </a>
          ) : (
            <p>No Qualtrics metadata available.</p>
          )}
        </MenuItem>
        <MenuItem style={{ fontSize: '1.4rem' }}>
          <ListItemIcon className={classes.listItemIcon}>
            <AssignmentIcon fontSize="small" color="primary" style={{ color: '#72A295', fontSize: '1.2em' }} />
          </ListItemIcon>
          {respondent.qualtrics_responses_url ? (
            <a
              href={respondent.qualtrics_responses_url}
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: 'none' }}
            >
              View Qualtrics Responses
            </a>
          ) : (
            <p>No survey responses available.</p>
          )}
        </MenuItem>
        {currentUserHasRole('superadmin') && (
          <>
            <MenuItem onClick={() => setDetailsModalOpen(!detailsModalOpen)} style={{ fontSize: '1.4rem' }}>
              <ListItemIcon className={classes.listItemIcon}>
                <EditIcon fontSize="small" color="primary" style={{ color: '#72A295', fontSize: '1.2em' }} />
              </ListItemIcon>
              Edit Respondent Details
            </MenuItem>
            <MenuItem
              onClick={() => setCopyToOtherPhaseModalOpen(!copyToOtherPhaseModalOpen)}
              style={{ fontSize: '1.4rem' }}
            >
              <ListItemIcon className={classes.listItemIcon}>
                <ContentCopyIcon fontSize="small" color="primary" style={{ color: '#72A295', fontSize: '1.2em' }} />
              </ListItemIcon>
              Copy to a different Phase
            </MenuItem>
            <MenuItem onClick={() => setCopyToInvOppModalOpen(!copyToInvOppModalOpen)} style={{ fontSize: '1.4rem' }}>
              <ListItemIcon className={classes.listItemIcon}>
                <FileCopyIcon fontSize="small" color="primary" style={{ color: '#72A295', fontSize: '1.2em' }} />
              </ListItemIcon>
              Copy to an Invitation Opportunity
            </MenuItem>
          </>
        )}
        {currentUserHasRole('superadmin') && (
          <MenuItem
            onClick={() => {
              sessionStorage.setItem('selectedRespondentIds', JSON.stringify([respondent.uuid]));
              const url = `/admin/payments/new?projectId=${respondent.project_id}&phaseId=${phaseId}`; //
              window.open(url, '_blank'); // '_blank' opens the URL in a new tab/window
            }}
            style={{ fontSize: '1.4rem' }}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <LocalAtmIcon fontSize="small" color="primary" style={{ color: '#72A295', fontSize: '1.2em' }} />
            </ListItemIcon>
            Create Payment for Respondent
          </MenuItem>
        )}
      </CustomMaterialMenu>
    </>
  );
};

export default RespondentRowMenu;
