import React, { useEffect } from 'react';
import FormInput from '../../form/components/FormInput';
import { InsighterNotesContext } from '../contexts/InsighterNotesContext';
import { Insighter } from '../model';
import ConfirmCancelModal from '../../modal/components/MaterialModal';

interface InsighterNoteEditModalProps {
  isOpen: boolean;
  setOpen: any;
  insighter: Insighter;
  activeNote: {
    author: string;
    created_at: string;
    description: string;
    id: string;
  } | null;
  setActiveNote: any;
}

const InsighterNoteEditModal: React.FC<InsighterNoteEditModalProps> = (props) => {
  const { isOpen, setOpen, insighter, activeNote } = props;
  const { updateInsighterNote, deleteInsighterNote } = React.useContext(InsighterNotesContext);
  const [description, setDescription] = React.useState<string>();

  useEffect(() => {
    if (activeNote) {
      setDescription(activeNote.description);
    } else {
      setDescription('');
    }
  }, [activeNote]);

  return (
    <ConfirmCancelModal
      isOpen={isOpen}
      title={`Submit Note for ${insighter?.name}`}
      onCancel={() => setOpen(false)}
      onConfirm={() => updateInsighterNote(activeNote.id, { insighter_id: insighter.id, description })}
      onWarning={() => deleteInsighterNote(activeNote.id, insighter.id)}
      confirmLabel="Submit"
      warningLabel="Delete"
      cancelLabel="Cancel"
      hasConfirmButton
      hasWarningButton
      hasCancelButton
      hasFooterBar={false}
      height="350px"
    >
      <>
        <p style={{ marginTop: '20px' }}>
          Please submit any notes you have about the respondent and your session with them. Only other moderators and
          admins will be able to see these notes.
        </p>
        <br />
        <FormInput
          label={null}
          value={description}
          onChange={(value: string) => setDescription(value)}
          multiline
          rows={4}
        />
      </>
    </ConfirmCancelModal>
  );
};

export default InsighterNoteEditModal;
