import React from 'react';
import axios from '../../utils/axios.utils';

interface AdminNavigationContextInterface {
  fetchAdminNavigation?: () => Promise<void>;
  adminNavigationLoading?: boolean;
  actionablePayments?: string;
}

const AdminNavigationContext = React.createContext<AdminNavigationContextInterface>({});

const AdminNavigationContextConsumer = AdminNavigationContext.Consumer;

const AdminNavigationContextProvider: React.FC = ({ children }) => {
  const [adminNavigationLoading, setAdminNavigationLoading] = React.useState<boolean>(true);
  const [actionablePayments, setActionablePayments] = React.useState<string>('');

  const fetchAdminNavigation = async () => {
    setAdminNavigationLoading(true);
    const response = await axios.get<string, any>(`admin_navigation.json`);
    setActionablePayments(response?.data?.actionable_payments);
    setAdminNavigationLoading(false);
  };

  return (
    <AdminNavigationContext.Provider
      value={{
        fetchAdminNavigation,
        adminNavigationLoading,
        actionablePayments,
      }}
    >
      {children}
    </AdminNavigationContext.Provider>
  );
};

export { AdminNavigationContextProvider, AdminNavigationContextConsumer, AdminNavigationContext };
