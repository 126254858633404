/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Card, Filters } from '@shopify/polaris';
import { Grid } from '@material-ui/core';
import { InvitationsContext } from '../contexts/InvitationsContext';

const InvitationsFilterBar: React.FunctionComponent = () => {
  const { invitationOpportunityId } = useParams<Record<string, string>>();
  const { fetchInvitationsForOpportunityDetail, setFetchParams, filterValues, setFilterValues, updateFilterValues } =
    React.useContext(InvitationsContext);

  useEffect(() => {
    updateFilterValues(invitationOpportunityId);
  }, [invitationOpportunityId]);

  const applyFilters = () => {
    const invitationOpportunityFilter = `ransack[invitation_opportunity_id_in]=${invitationOpportunityId}`;
    const queryValue = `ransack[query]=${filterValues.query}`;
    const fullFetchParams = `&${queryValue}&${invitationOpportunityFilter}`;

    setFetchParams(fullFetchParams);

    sessionStorage.setItem(`invitationFilters-${invitationOpportunityId}`, fullFetchParams);
    sessionStorage.setItem(`invitationFilterQuery-${invitationOpportunityId}`, filterValues.query);

    fetchInvitationsForOpportunityDetail(fullFetchParams);
  };

  const clearFilters = () => {
    setFilterValues({ query: '' });
    setFetchParams('ransack[query]=');
    sessionStorage.removeItem(`invitationFilters-${invitationOpportunityId}`);
    sessionStorage.removeItem(`invitationFilterQuery-${invitationOpportunityId}`);
    fetchInvitationsForOpportunityDetail(`ransack[invitation_opportunity_id_in]=${invitationOpportunityId}`);
  };

  const handleFiltersKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      applyFilters();
    }
  };

  return (
    <Card.Section>
      <div role="form" onKeyDown={handleFiltersKeyPress}>
        <Filters
          queryValue={filterValues.query}
          queryPlaceholder="Filter by Invitee or Inviter Name..."
          filters={[]}
          onQueryChange={(value) => setFilterValues({ ...filterValues, query: value })}
          onQueryClear={() => setFilterValues({ query: '' })}
          onClearAll={() => null}
        >
          <Grid container>
            <Grid item>
              <div style={{ paddingLeft: '8px' }}>
                <Button primary onClick={applyFilters}>
                  Apply Filter
                </Button>
              </div>
            </Grid>
            <Grid item>
              <div style={{ paddingLeft: '8px' }}>
                <Button onClick={clearFilters}>Clear Filter</Button>
              </div>
            </Grid>
          </Grid>
        </Filters>
      </div>
    </Card.Section>
  );
};

export default InvitationsFilterBar;
