import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { Card } from '@shopify/polaris';
import { Dashboard } from '../model';

const useStyles = makeStyles({
  largeNumber: {
    fontSize: 72,
    textAlign: 'center',
    display: 'block',
    margin: '20px auto',
    fontWeight: 700,
  },
  largeNumberDescription: {
    fontSize: 16,
    textAlign: 'center',
    display: 'block',
    margin: '30px auto 0',
    fontWeight: 700,
  },
});

interface DashboardProps {
  dashboard: Dashboard;
}

const ProjectCountBoxes: React.FC<DashboardProps> = (props) => {
  const { activePhasesCount, inactivePhasesCount, upcomingPhasesCount } = props.dashboard;
  const classes = useStyles();

  const handleAction = () => {};

  return (
    <Grid container spacing={4}>
      <Grid item xs={4}>
        <Card title="Active" sectioned actions={[{ content: 'View All', onAction: handleAction }]}>
          <div style={{ color: '#2D87BB' }}>
            <span className={classes.largeNumber}>{activePhasesCount}</span>
            <p className={classes.largeNumberDescription}>
              {parseInt(activePhasesCount, 10) === 1 ? 'Phase' : 'Phases'}
            </p>
          </div>
        </Card>
      </Grid>
      <Grid item xs={4}>
        <Card title="Ending Soon" sectioned actions={[{ content: 'View All', onAction: handleAction }]}>
          <div style={{ color: '#EC6B56' }}>
            <span className={classes.largeNumber}>{inactivePhasesCount}</span>
            <p className={classes.largeNumberDescription}>
              {parseInt(inactivePhasesCount, 10) === 1 ? 'Phase' : 'Phases'}
            </p>
          </div>
        </Card>
      </Grid>
      <Grid item xs={4}>
        <Card title="Upcoming" sectioned actions={[{ content: 'View All', onAction: handleAction }]}>
          <div style={{ color: '#FEAE65' }}>
            <span className={classes.largeNumber}>{upcomingPhasesCount}</span>
            <p className={classes.largeNumberDescription}>
              {parseInt(upcomingPhasesCount, 10) === 1 ? 'Phase' : 'Phases'}
            </p>
          </div>
        </Card>
      </Grid>
    </Grid>
  );
};

export default ProjectCountBoxes;
