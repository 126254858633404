import React, { useState, Dispatch, SetStateAction, useEffect } from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import FormInput from '../../form/components/FormInput';
import { User } from '../../auth/model';
import LoadingSpinner from '../../loading/components/LoadingSpinner';
import AdminPageHeader from '../../adminTools/components/AdminPageHeader';

import axios from '../../utils/axios.utils';

type InsighterLookupProps = {
  setUsers?: Dispatch<SetStateAction<User[]>>;
  setNotFoundInfo?: Dispatch<SetStateAction<any>>;
  initialLookupValue: string;
};

const InsighterLookup: React.FC<InsighterLookupProps> = (props) => {
  const { setUsers, setNotFoundInfo, initialLookupValue } = props;

  const [lookupValue, setLookupValue] = useState<string>(initialLookupValue);
  const [lookupSubmitting, setLookupSubmitting] = useState<boolean>(false);
  const [lookupError, setLookupError] = useState<string>('');

  let storedUserIds = sessionStorage.getItem('selectedRespondentIds');

  const handleLookup = async (lookupToUse = lookupValue) => {
    setLookupSubmitting(true);

    await axios
      .get(`payments/lookup`, { params: { lookup_value: lookupToUse } })
      .then((response) => {
        setUsers(response?.data?.users);
        setNotFoundInfo(response?.data?.not_found_info);
      })
      .catch((error) => {
        setLookupError(error.response.data.error);
      })
      .finally(() => setLookupSubmitting(false));
  };

  useEffect(() => {
    if (storedUserIds) {
      let userIdsArray = JSON.parse(storedUserIds);
      let formattedUserIds = userIdsArray.join(', ');
      handleLookup(formattedUserIds);
    }
  }, []);

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      handleLookup();
    }
  };

  return (
    <Grid container onKeyDown={handleKeyPress}>
      <Grid item xs={12} style={{ marginBottom: 10 }}>
        <Typography variant="h5">
          Enter a list of up to 100 emails or uuids, separated by commas or line breaks.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <FormInput
          label=""
          value={lookupValue}
          onChange={(value) => {
            setLookupValue(value);
          }}
          multiline
          rows={4}
        />
      </Grid>
      {lookupError && (
        <Grid>
          <p style={{ color: 'red', fontSize: '14px', marginTop: 20 }}>{lookupError}</p>
        </Grid>
      )}
      <Grid item xs={12} style={{ padding: '20px' }}>
        {lookupSubmitting ? (
          <LoadingSpinner />
        ) : (
          <Button onClick={() => handleLookup()} disabled={!lookupValue} className="primary-btn">
            Lookup
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

export default InsighterLookup;
