import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Edit from '@material-ui/icons/Edit';
import DataTable from '../../dataTable';
import AdminPageHeader from '../../adminTools/components/AdminPageHeader';

import { TeamBio } from '../model';

import axios from '../../utils/axios.utils';

const TeamBiosListPage: React.FC = () => {
  const history = useHistory();

  const [teamBios, setTeamBios] = useState<TeamBio[]>([]);

  const fetchTeamBios = async () => {
    try {
      const response = await axios.get('team_bios');
      setTeamBios(response?.data?.result);
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchTeamBios();
  }, []);

  const teamBioColumns = [
    {
      name: 'Pic',
      selector: 'pic',
      sortable: true,
      grow: 0.5,
      cell: (row: any) => <img src={row.main_image} alt={row.name} style={{ width: '50px', height: '50px' }} />,
    },
    { name: 'Name', selector: 'name', sortable: true },
    { name: 'Title', selector: 'title', sortable: true },
    {
      name: 'Edit',
      selector: 'edit',
      grow: 0.5,
      cell: (row: any) => (
        <Edit
          style={{ cursor: 'pointer' }}
          onClick={(e) => {
            e.stopPropagation();
            history.push(`/admin/team-bios/${row.id}/edit`);
          }}
        />
      ),
    },
  ];

  return (
    <Grid container spacing={3} style={{ padding: '1% 2%' }}>
      <AdminPageHeader
        title="Team Bios"
        primaryButton={{ label: 'Add Team Bio', link: '/admin/team-bios/new' }}
        backButton={{ label: 'Admin Tools', link: '/admin/tools' }}
      />
      <Grid item xs={12}>
        <Card>
          <DataTable
            data={teamBios}
            columns={teamBioColumns}
            onRowClicked={(rowData: any) => history.push(`/admin/team-bios/${rowData.id}`)}
            striped
            highlightOnHover
            pointerOnHover
            noHeader
          />
        </Card>
      </Grid>
    </Grid>
  );
};

export default TeamBiosListPage;
