import React from 'react';

import { useHistory } from 'react-router-dom';

import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import Page from '../../shared/components/Page';
import DataTable from '../../dataTable/DataTable/DataTable';
import PhaseFilterBar from '../components/PhaseFilterBar';
import AdminPageHeader from '../../adminTools/components/AdminPageHeader';

import { ProjectPhasesContext } from '../contexts/ProjectPhasesContext';
import phaseColumns from '../constants/phaseColumns';

const PhasesList: React.FunctionComponent = () => {
  const { phases, fetchPhases, phaseLoading } = React.useContext(ProjectPhasesContext);
  const [phasesFetched, setPhasesFetched] = React.useState(false);

  React.useEffect(() => {
    const phaseFilters = sessionStorage.getItem('phaseFilters');

    if (!phasesFetched) {
      if (phaseFilters) {
        fetchPhases(phaseFilters);
      } else {
        fetchPhases('');
      }
      setPhasesFetched(true);
    }
  }, [fetchPhases, phasesFetched]);

  const history = useHistory();

  const CreatePhaseButton = () => (
    <Button className="primary-btn" onClick={() => history.push('/admin/phases/new')}>
      Create Phase
    </Button>
  );

  return (
    <Grid container style={{ padding: '1% 2%' }}>
      <AdminPageHeader title="Project Phases" adminButtons={<CreatePhaseButton />} />
      <Card style={{ padding: '1% 2%' }}>
        <PhaseFilterBar />
        <DataTable
          onRowClicked={(row: Record<string, string>) => history.push(`/admin/phases/${row.id}`)}
          columns={phaseColumns}
          data={phases}
          striped
          highlightOnHover
          pointerOnHover
          noHeader
          progressPending={phaseLoading}
        />
      </Card>
    </Grid>
  );
};

export default PhasesList;
