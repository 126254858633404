import React from 'react';
import { useParams } from 'react-router-dom';
import { Card, Layout, Loading } from '@shopify/polaris';
import Page from '../../shared/components/Page';
import InsighterTagForm from '../components/InsighterTagForm';
import { InsighterTagsContext } from '../contexts/InsighterTagsContext';
import FormSkeleton from '../../loading/components/FormSkeleton';
import BackButton from '../../navigation/components/BackButton';

const EditInsighterTag: React.FunctionComponent = () => {
  const { insighterTagId } = useParams<Record<string, string>>();
  const [insighterTagFetched, setSpecialtyFetched] = React.useState<boolean>(false);
  const { insighterTag, fetchInsighterTag, fetchFormOptions, insighterTagsLoading } =
    React.useContext(InsighterTagsContext);

  React.useEffect(() => {
    if (!insighterTagFetched) {
      fetchFormOptions();
      fetchInsighterTag(insighterTagId);
      setSpecialtyFetched(true);
    }
  }, [insighterTagFetched, fetchInsighterTag, insighterTagId, fetchFormOptions]);

  const loadingMarkup = insighterTagsLoading ? <Loading /> : null;

  // ---- Page markup ----
  const actualPageMarkup = (
    <>
      <BackButton pageAction={false} />
      <Page fullWidth title={`${insighterTag?.name} `}>
        <Layout>
          <Layout.Section>
            <Card sectioned>
              <InsighterTagForm insighterTag={insighterTag} />
            </Card>
          </Layout.Section>
          <Layout.Section secondary />
        </Layout>
      </Page>
    </>
  );

  // ---- Loading ----
  const loadingPageMarkup = <FormSkeleton />;

  const pageMarkup = insighterTagsLoading ? loadingPageMarkup : actualPageMarkup;

  return (
    <>
      {loadingMarkup}
      {pageMarkup}
    </>
  );
};

export default EditInsighterTag;
