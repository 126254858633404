import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
  },
  footerText: {
    color: 'white',
    fontSize: '10px',
    fontFamily: 'Rethink Sans, sans-serif',
    fontWeight: 400,
    wordWrap: 'break-word',
    textDecoration: 'none',
  },
  h1: {
    fontSize: '60px', // Default font size for larger screens
    position: 'relative',
    fontStyle: 'normal',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '30px', // Smaller font size for mobile screens
    },
    lineHeight: '100%',
    letterSpacing: '-3px',
    fontFamily: 'Crimson Text',
  },
  smallText: {
    color: '#545D78',
    fontSize: 14,
    fontFamily: 'Rethink Sans, sans-serif',
    fontWeight: 400,
    lineHeight: 1,
    wordWrap: 'break-word',
  },
  h2: {
    fontFamily: 'Crimson Text',
    fontSize: '36px',
    fontStyle: 'normal',
    fontWeight: 300,
    lineHeight: '100%',
    letterSpacing: '-1px',
  },
  h3: {
    fontFamily: 'Crimson Text',
    fontSize: '22px',
    fontStyle: 'normal',
    fontWeight: 300,
    lineHeight: '100%',
    letterSpacing: '-1px',
  },
  hrLine: {
    height: 1,
    color: 'gray',
    backgroundColor: 'gray',
    margin: '5px 0 15px 0',
  },
  navHeader: {
    color: 'white',
    fontFamily: 'Crimson Text',
    fontWeight: 'bold',
    fontSize: '24px',
  },
  navHeaderPaidOpportunities: {
    color: 'lightgray',
    fontFamily: 'Crimson Text',
    fontWeight: 'bold',
    fontSize: '18px',
    marginBottom: -10,
  },
  sectionBlurbText: {
    color: 'var(--3, #FFF)',
    fontFeatureSettings: 'clig off, liga off',
    fontFamily: 'Rethink Sans, sans-serif',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 800,
    letterSpacing: '1.5px',
    textTransform: 'uppercase',
    position: 'absolute',
    [theme.breakpoints.down(1100)]: {
      fontSize: '11px',
    },
    zIndex: 1,
  },
  opportunityScroller: {
    position: 'relative',
    display: 'inline-block',
  },
  imageContainer: {
    position: 'relative',
  },
  arrowButtons: {
    display: 'flex',
  },
  arrowButtonsButton: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    color: 'white',
    border: 'none',
    padding: '5px 10px',
    cursor: 'pointer',
    fontSize: '12px',
  },

  arrowButtonsButtonHover: {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
  },
}));

export default useStyles;
