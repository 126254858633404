import React, { useCallback, useState, useEffect, useContext } from 'react';
import { Button, Card, Loading, Page, Toast } from '@shopify/polaris';
import { Grid } from '@mui/material';
import { useHistory, useLocation } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import DataTableSkeleton from '../../loading/components/DataTableSkeleton';
import DataTable from '../../dataTable/DataTable/DataTable';
import ConditionFilterBar from '../components/ConditionFilterBar';
import { ConditionsContext } from '../contexts/ConditionsContext';
import { IDataTableColumn } from '../../dataTable/DataTable/model';
import todaysDate from '../../utils/dateFunctions';

const projectColumns = (): IDataTableColumn[] => [
  {
    name: 'Condition Name',
    selector: 'name',
    sortable: true,
  },
  {
    name: 'Category',
    selector: 'category',
    sortable: false,
  },
  {
    name: 'Insighter Counter',
    selector: 'insighter_count',
    sortable: true,
  },
  {
    name: 'Status',
    selector: 'status',
    sortable: true,
    cell: ({ status }): React.ReactNode => (
      <div
        style={{
          fontWeight: 700,
          textTransform: 'uppercase',
          fontSize: 14,
          color: status === 'active' ? 'green' : 'red',
        }}
      >
        {status}
      </div>
    ),
  },
  {
    name: 'Actions',
    selector: 'status',
    sortable: true,
    cell: (row): React.ReactNode => (
      <div style={{ marginRight: 10 }}>
        <Button url={`/admin/conditions/${row.id}/edit`}>Edit</Button>
      </div>
    ),
  },
];

interface ConditionsListProps {
  isLoading: boolean;
}

const ConditionsList: React.FunctionComponent<ConditionsListProps> = (props) => {
  const { isLoading } = props;
  const [conditionsFetched, setConditionsFetched] = useState<boolean>(false);

  const {
    conditions,
    fetchConditions,
    conditionsLoading,
    totalRecords,
    fetchParams,
    sortParams,
    setSortParams,
    indexCategoryName,
    conditionsCSV,
  } = useContext(ConditionsContext);

  const query = new URLSearchParams(useLocation().search);
  const filterParams = query.get('filters')?.split('__')?.join('&');

  useEffect(() => {
    if (!conditionsFetched) {
      setConditionsFetched(true);
      fetchConditions(filterParams);
    }
  }, [conditionsFetched, setConditionsFetched, fetchConditions, filterParams]);

  const history = useHistory();
  const [toastActive, setToastActive] = useState(false);
  const loadingMarkup = isLoading ? <Loading /> : null;

  const toggleToastActive = useCallback(() => setToastActive((toastActive) => !toastActive), []);
  const toastMarkup = toastActive ? <Toast onDismiss={toggleToastActive} content="Changes saved" /> : null;

  const handleColumnSort = async (column: Record<string, string>, sortDirection: string) => {
    const columnNamesToRansack = {
      name: 'name',
      insighter_count: 'insighter_count',
      status: 'status',
    };

    const sort = `sort=${columnNamesToRansack[column.selector]} ${sortDirection}`;

    setSortParams(sort);
    fetchConditions(`${fetchParams}&${sort}&${filterParams}`);
  };

  const handlePageChange = (page: number) => {
    fetchConditions(`page=${page}&${filterParams}&${fetchParams}&${sortParams}`);
  };

  const actualPageMarkup = (
    <Page
      fullWidth
      title={`${indexCategoryName || ''} Conditions`}
      primaryAction={{
        content: 'Create Condition',
        onAction: () => history.push('/admin/conditions/new'),
      }}
      secondaryActions={[
        {
          content: 'Merge Conditions',
          onAction: () => history.push('/admin/conditions/merge'),
        },
      ]}
    >
      {conditionsCSV && (
        <Grid style={{ marginBottom: 20, display: 'flex', justifyContent: 'flex-end' }}>
          <CSVLink
            data={conditionsCSV}
            filename={`${indexCategoryName || ''} Conditions-${todaysDate()}.csv`}
            style={{ textDecoration: 'none' }}
          >
            <Button primary>Download Conditions</Button>
          </CSVLink>
        </Grid>
      )}
      <Card>
        <br />
        <ConditionFilterBar />
        <DataTable
          onRowClicked={(row: Record<string, string>) => history.push(`/admin/conditions/${row.id}`)}
          columns={projectColumns()}
          progressPending={conditionsLoading}
          data={conditions}
          pagination
          striped
          highlightOnHover
          pointerOnHover
          paginationPerPage={25}
          paginationRowsPerPageOptions={[25]}
          noHeader
          paginationServer
          paginationTotalRows={totalRecords}
          onChangePage={handlePageChange}
          sortServer
          onSort={handleColumnSort}
        />
      </Card>
    </Page>
  );

  // ---- Loading ----
  const loadingPageMarkup = <DataTableSkeleton />;

  const pageMarkup = isLoading ? loadingPageMarkup : actualPageMarkup;

  return (
    <>
      {loadingMarkup}
      {pageMarkup}
      {toastMarkup}
    </>
  );
};

export default ConditionsList;
