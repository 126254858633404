import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import axios from '../../utils/axios.utils';
import OpportunityDetailHero from './sections/OpportunityDetailHero';
import OpportunityOverview from './sections/OpportunityOverview';
import ApplicationProcess from './sections/ApplicationProcess';
import MeetTheInsighters from './sections/MeetTheInsighters';
import RedesignFooter from '../common/RedesignFooter';

const useStyles = makeStyles((theme) => ({
  sectionGrid: {
    margin: 15,

    [theme.breakpoints.down(960)]: {
      marginLeft: 0,
      marginRight: 0,
    },
  },
}));

const OpportunitiesListPage: React.FC = () => {
  const classes = useStyles();
  const { opportunityId } = useParams<Record<string, string>>();

  const [insighterOpportunityLoading, setInsighterOpportunityLoading] = React.useState(true);
  const [insighterOpportunity, setInsighterOpportunity] = React.useState();

  const fetchInsighterOpportunity = async (opportunityId: string) => {
    setInsighterOpportunityLoading(true);

    const response = await axios.get<string, any>(`insighter_opportunities/${opportunityId}.json`, {
      headers: { 'Content-Type': 'application/json' },
    });

    setInsighterOpportunityLoading(false);
    setInsighterOpportunity(response?.data?.result);
  };

  React.useEffect(() => {
    fetchInsighterOpportunity(opportunityId);
  }, []);

  return insighterOpportunityLoading ? (
    <></>
  ) : (
    <Grid container>
      <Grid item xs={12} className={classes.sectionGrid}>
        <OpportunityDetailHero insighterOpportunity={insighterOpportunity} />
      </Grid>
      <Grid item xs={12} className={classes.sectionGrid}>
        <OpportunityOverview insighterOpportunity={insighterOpportunity} />
      </Grid>
      <Grid item xs={12} className={classes.sectionGrid}>
        <ApplicationProcess insighterOpportunity={insighterOpportunity} />
      </Grid>
      <Grid item xs={12} className={classes.sectionGrid}>
        <MeetTheInsighters />
      </Grid>
      <Grid item xs={12} className={classes.sectionGrid}>
        <RedesignFooter />
      </Grid>
    </Grid>
  );
};

export default OpportunitiesListPage;
