import '@shopify/polaris/dist/styles.css';
import './App.css';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import enTranslations from '@shopify/polaris/locales/en.json';
import { AppProvider } from '@shopify/polaris';
import ContextsProvider from './contexts';
import { UserContext } from './modules/auth/contexts/UserContext';
import Routes from './routes';
import { APPLICATION_URL, AUTH_ZERO_DOMAIN, AUTH_ZERO_CLIENT_ID, AUTH_ZERO_AUDIENCE } from './modules/utils/env';

const App: React.FC = () => {
  const { user } = React.useContext(UserContext);

  return (
    <AppProvider i18n={enTranslations}>
      <BrowserRouter forceRefresh>
        <ContextsProvider>
          <Routes key={user ? user.id : null} />
        </ContextsProvider>
      </BrowserRouter>
    </AppProvider>
  );
};

export default App;
