import React from 'react';
import { Button, FormLayout } from '@shopify/polaris';
import { Grid } from '@material-ui/core';
import FormInput from '../../form/components/FormInput';
import SelectInput from '../../form/components/SelectInput';
import { SpecialtiesContext } from '../contexts/SpecialtiesContext';
import { Specialty, SpecialtyFormValues } from '../model';
import ConfirmCancelModal from '../../modal/components/MaterialModal';

interface SpecialtyFormProps {
  specialty?: Specialty;
}

const SpecialtyForm: React.FC<SpecialtyFormProps> = (props) => {
  const { specialty } = props;
  const { formOptions, formError, updateSpecialty, createSpecialty, specialtySubmitting } =
    React.useContext(SpecialtiesContext);

  const [formValues, setFormValues] = React.useState<SpecialtyFormValues>({});
  const [confirmModalOpen, setConfirmModalOpen] = React.useState(false);

  React.useEffect(() => {
    setFormValues({
      name: specialty?.name,
      status: specialty?.status,
    });
  }, [specialty]);

  const handleUpdate = () => {
    updateSpecialty(specialty.id, formValues);
  };

  const handleArchive = () => {
    updateSpecialty(specialty.id, { ...formValues, archived: !specialty.archived });
  };

  const handleCreate = () => {
    createSpecialty(formValues);
  };

  return (
    <FormLayout>
      <ConfirmCancelModal
        isOpen={confirmModalOpen}
        title="Archive Specialty"
        onCancel={() => setConfirmModalOpen(false)}
        onWarning={() => handleArchive()}
        warningLabel="Archive"
        cancelLabel="Cancel"
        hasWarningButton
        hasCancelButton
        hasFooterBar={false}
        height="250px"
      >
        <div style={{ marginTop: '20px', marginBottom: '20px' }}>
          <p>Are you sure you want to archive this Specialty?</p>
        </div>
      </ConfirmCancelModal>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <h2 className="Polaris-Heading">Specialty Information</h2>
        </Grid>
        <Grid item xs={12}>
          <FormInput
            label="Name"
            type="text"
            value={formValues.name}
            onChange={(value) => setFormValues({ ...formValues, name: value })}
          />
        </Grid>
        <Grid item xs={12}>
          <SelectInput
            label="Status"
            choices={[
              { label: 'Active', value: 'active' },
              { label: 'Inactive', value: 'inactive' },
            ]}
            value={formValues.status}
            onChange={(value) => setFormValues({ ...formValues, status: value })}
          />
        </Grid>
      </Grid>

      <>
        {specialty?.id ? (
          <Grid>
            <Button primary onClick={handleUpdate} loading={specialtySubmitting}>
              Update Specialty
            </Button>
            &emsp;
            {specialty?.archived ? (
              <Button onClick={handleArchive} loading={specialtySubmitting}>
                Unarchive
              </Button>
            ) : (
              <Button destructive onClick={() => setConfirmModalOpen(true)} loading={specialtySubmitting}>
                Archive
              </Button>
            )}
          </Grid>
        ) : (
          <Button primary onClick={handleCreate} loading={specialtySubmitting}>
            Create Specialty
          </Button>
        )}
        {formError && <p style={{ color: 'red', fontSize: '14px', marginTop: 20 }}>{formError}</p>}
      </>
    </FormLayout>
  );
};

export default SpecialtyForm;
