import React, { useCallback, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Card, Loading } from '@shopify/polaris';
import { Grid, Typography } from '@material-ui/core';
import DataTableSkeleton from '../../loading/components/DataTableSkeleton';
import DataTable from '../../dataTable/DataTable/DataTable';
import { IDataTableColumn } from '../../dataTable/DataTable/model';
import axios from '../../utils/axios.utils';

const tremendousCampaignColumns = (): IDataTableColumn[] => [
  {
    name: 'Name',
    selector: 'name',
    sortable: true,
  },
  {
    name: 'Campaign ID',
    selector: 'campaign_id',
    sortable: true,
  },
  {
    name: 'Default',
    selector: 'default',
    sortable: true,
  },
];

const TremendousCampaignsListPage: React.FunctionComponent = () => {
  const history = useHistory();

  const [campaignsFetched, setCampaignsFetched] = useState<boolean>(false);
  const [campaigns, setCampaigns] = useState<any[]>([]);
  const [campaignsLoading, setCampaignsLoading] = useState<boolean>(false);

  const fetchTremendousCampaigns = async () => {
    setCampaignsLoading(true);
    await axios.get('tremendous_campaigns').then((response) => {
      setCampaigns(response.data?.result);
      setCampaignsLoading(false);
      setCampaignsFetched(true);
    });
  };

  useEffect(() => {
    if (!campaignsFetched) {
      fetchTremendousCampaigns();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignsFetched]);

  const loadingMarkup = campaignsLoading ? <Loading /> : null;

  const actualPageMarkup = (
    <Grid container spacing={3} style={{ padding: 10 }}>
      <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button primary onClick={() => history.push('/admin/tremendous-campaigns/new')}>
          Create Campaign
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h4">
          <span style={{ fontWeight: 'bold' }}>Reminder: </span>
          Updating Tremendous Campaign info is disabled because several background processes depend on finding campaigns
          by their name. Please contact NextLink if you would like to make a change to a Tremendous campaign in the TI
          database.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <br />
          <DataTable
            columns={tremendousCampaignColumns()}
            progressPending={campaignsLoading}
            data={campaigns}
            pagination
            striped
            highlightOnHover
            paginationPerPage={25}
            paginationRowsPerPageOptions={[25]}
            noHeader
          />
        </Card>
      </Grid>
    </Grid>
  );

  // ---- Loading ----
  const loadingPageMarkup = <DataTableSkeleton />;

  const pageMarkup = campaignsLoading ? loadingPageMarkup : actualPageMarkup;

  return (
    <>
      {loadingMarkup}
      {pageMarkup}
    </>
  );
};

export default TremendousCampaignsListPage;
