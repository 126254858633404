import React from 'react';
import { Button, Card, ChoiceList, Filters } from '@shopify/polaris';
import { Grid } from '@material-ui/core';
import { InvitationOpportunitiesContext } from '../contexts/InvitationOpportunitiesContext';

const InvitationOpportunityFilterBar: React.FunctionComponent = () => {
  const { fetchInvitationOpportunities, statusFilter, setStatusFilter, queryValue, setQueryValue } =
    React.useContext(InvitationOpportunitiesContext);
  const [appliedFiltersDisplay, setAppliedFiltersDisplay] = React.useState<any[]>();
  const [selectedStatusFilter, setSelectedStatusFilter] = React.useState<string[]>(['active']);

  React.useEffect(() => {
    const appliedFiltersArray = [];

    if (statusFilter && JSON.stringify(statusFilter) !== JSON.stringify([''])) {
      appliedFiltersArray.push({
        key: 'category',
        label: `Filtered by ${statusFilter}`,
        onRemove: () => {
          setStatusFilter(['']);
          setSelectedStatusFilter(['']);
        },
      });
    }

    setAppliedFiltersDisplay([...appliedFiltersArray]);
  }, [statusFilter, setStatusFilter]);

  const applyFilters = () => {
    const queryFilter = `ransack[name_cont]=${queryValue}`;
    setStatusFilter(selectedStatusFilter);
    const statusFilterParam = `ransack[status_eq]=${selectedStatusFilter}`;

    fetchInvitationOpportunities(`${queryFilter}&${statusFilterParam}`);
  };

  const clearFilters = () => {
    setStatusFilter(['']);
    setSelectedStatusFilter(['']);
    fetchInvitationOpportunities();
  };

  const handleFiltersKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      applyFilters();
    }
  };

  return (
    <Card.Section>
      <div role="form" onKeyDown={handleFiltersKeyPress}>
        <Filters
          queryValue={queryValue}
          queryPlaceholder="Filter by invitation opportunity name..."
          filters={[
            {
              key: 'status',
              label: 'Status',
              filter: (
                <ChoiceList
                  title="Status"
                  titleHidden
                  choices={[
                    { label: 'Active', value: 'active' },
                    { label: 'Inactive', value: 'inactive' },
                    { label: 'Archived', value: 'archived' },
                  ]}
                  selected={selectedStatusFilter}
                  onChange={(value) => setSelectedStatusFilter(value)}
                />
              ),
              shortcut: true,
            },
          ]}
          appliedFilters={appliedFiltersDisplay}
          onQueryChange={(value) => setQueryValue(value)}
          onQueryClear={() => setQueryValue('')}
          onClearAll={() => null}
        >
          <Grid container>
            <Grid item>
              <div style={{ paddingLeft: '8px' }}>
                <Button primary onClick={applyFilters}>
                  Apply Filters
                </Button>
              </div>
            </Grid>
            <Grid item>
              <div style={{ paddingLeft: '8px' }}>
                <Button onClick={clearFilters}>Clear Filters</Button>
              </div>
            </Grid>
          </Grid>
        </Filters>
      </div>
    </Card.Section>
  );
};

export default InvitationOpportunityFilterBar;
