import React, { Dispatch, SetStateAction, useState } from 'react';
import { Card, Grid } from '@material-ui/core';
import { DropZone } from '@shopify/polaris';
import FormInput from '../../form/components/FormInput';
import { EventResource } from '../model';
import SelectInput from '../../form/components/SelectInput';

interface EventResourceFormProps {
  formValues: EventResource;
  setFormValues: Dispatch<SetStateAction<EventResource>>;
  formErrors: string;
  setImageFileData: Dispatch<SetStateAction<FormData>>;
}

const EventResourceForm: React.FC<EventResourceFormProps> = (props: EventResourceFormProps) => {
  const { formValues, setFormValues, formErrors, setImageFileData } = props;

  const [imagePreview, setImagePreview] = useState<string | null>(formValues?.s3_image_url || null);

  const handleAttachFile = (files: File[]) => {
    const newFormData = new FormData();
    const attachedFile = files[0];
    newFormData.append('file', attachedFile, attachedFile.name);
    setImageFileData(newFormData);

    const previewUrl = URL.createObjectURL(attachedFile);
    setImagePreview(previewUrl);
  };

  React.useEffect(() => {
    if (formValues?.s3_image_url) {
      setImagePreview(formValues?.s3_image_url);
    }
  }, [formValues?.s3_image_url]);

  return (
    <Card style={{ padding: 20 }}>
      <Grid container spacing={3}>
        <Grid item xs={9}>
          <FormInput
            type="text"
            label="Title"
            value={formValues?.title}
            onChange={(value) => setFormValues({ ...formValues, title: value })}
          />
        </Grid>
        <Grid item xs={4}>
          <SelectInput
            label="Status"
            value={formValues?.status}
            onChange={(value) => setFormValues({ ...formValues, status: value })}
            choices={[
              { label: 'Active', value: 'active' },
              { label: 'Inactive', value: 'inactive' },
            ]}
          />
        </Grid>
        <Grid item xs={4}>
          <SelectInput
            label="Featured"
            value={formValues?.featured}
            onChange={(value) => setFormValues({ ...formValues, featured: value })}
            choices={[
              { label: 'Featured', value: 'true' },
              { label: 'Not Featured', value: 'false' },
            ]}
          />
        </Grid>
        <Grid item xs={4}>
          <SelectInput
            label="Show on Homepage"
            value={formValues?.show_on_homepage}
            onChange={(value) => setFormValues({ ...formValues, show_on_homepage: value })}
            choices={[
              { label: 'Show', value: 'show' },
              { label: 'Hide', value: 'hide' },
            ]}
          />
        </Grid>
        <Grid item xs={12}>
          <FormInput
            type="text"
            label="Description"
            value={formValues?.thumbnail_description}
            onChange={(value) => setFormValues({ ...formValues, thumbnail_description: value })}
          />
        </Grid>
        <Grid item xs={4}>
          <FormInput
            type="text"
            label="Arrow Message"
            value={formValues?.arrow_message}
            onChange={(value) => setFormValues({ ...formValues, arrow_message: value })}
          />
        </Grid>
        <Grid item xs={8}>
          <FormInput
            type="text"
            label="Link"
            value={formValues?.link}
            onChange={(value) => setFormValues({ ...formValues, link: value })}
          />
        </Grid>
        <Grid item xs={12} />
        <Grid item xs={8} style={{ margin: 'auto' }}>
          <Card title="Image">
            <DropZone type="image" label="" onDrop={handleAttachFile} allowMultiple={false}>
              <DropZone.FileUpload />
            </DropZone>
          </Card>
        </Grid>
        <Grid item xs={4} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {imagePreview && <img src={imagePreview} alt="Preview" style={{ width: '50%', height: 'auto' }} />}
        </Grid>
        <Grid item xs={12}>
          <p style={{ color: 'red' }}>{formErrors}</p>
        </Grid>
      </Grid>
    </Card>
  );
};

export default EventResourceForm;
