import React from 'react';
import {
  Card,
  Layout,
  Loading,
  Page,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPage,
  TextContainer,
} from '@shopify/polaris';
import { Doughnut } from 'react-chartjs-2';
import DashboardNavigation from '../components/DashboardNavigation';
import { DashboardsContext } from '../contexts/DashboardsContext';

interface DemographicsDashboardProps {
  isLoading: boolean;
}

const DemographicsDashboard: React.FunctionComponent<DemographicsDashboardProps> = () => {
  const { fetchDemographicsAnalytics, dashboardLoading, demographicAnalytics } = React.useContext(DashboardsContext);
  const [dashboardFetched, setdashboardFetched] = React.useState(false);

  React.useEffect(() => {
    if (!dashboardFetched) {
      fetchDemographicsAnalytics();
      setdashboardFetched(true);
    }
  }, [dashboardFetched, fetchDemographicsAnalytics]);

  const loadingMarkup = dashboardLoading ? <Loading /> : null;

  const actualPageMarkup = (
    <Page fullWidth>
      <DashboardNavigation currentTab={4} />
      <div
        key="donut"
        style={{
          width: '40%',
          marginLeft: '5%',
          marginRight: '5%',
          marginTop: '15px',
          marginBottom: '50px',
          display: 'inline-block',
        }}
      >
        <div>
          <h2 style={{ textAlign: 'center', fontSize: 24 }}>Gender</h2>
        </div>
        <br />
        <Doughnut
          data={{
            labels: demographicAnalytics?.gender?.labels,
            datasets: [
              {
                label: '# of Insighters',
                data: demographicAnalytics?.gender?.data,
                backgroundColor: [
                  '#FEAE65',
                  '#545D78',
                  '#2D87BB',
                  '#64C2A6',
                  '#ec5677',
                  '#8156ec',
                  '#EC6B56',
                  '#d3778b',
                  '#8dd377',
                  '#a94835',
                ],
                borderColor: [
                  '#FEAE65',
                  '#545D78',
                  '#2D87BB',
                  '#64C2A6',
                  '#ec5677',
                  '#8156ec',
                  '#EC6B56',
                  '#d3778b',
                  '#8dd377',
                  '#a94835',
                ],
                borderWidth: 1,
              },
            ],
          }}
        />
      </div>

      <div
        key="donut"
        style={{
          width: '40%',
          marginLeft: '5%',
          marginRight: '5%',
          marginTop: '15px',
          marginBottom: '50px',
          display: 'inline-block',
        }}
      >
        <div>
          <h2 style={{ textAlign: 'center', fontSize: 24 }}>Transgender Identity</h2>
        </div>
        <br />
        <Doughnut
          data={{
            labels: demographicAnalytics?.transgender_identity?.labels,
            datasets: [
              {
                label: '# of Insighters',
                data: demographicAnalytics?.transgender_identity?.data,
                backgroundColor: [
                  '#FEAE65',
                  '#545D78',
                  '#2D87BB',
                  '#64C2A6',
                  '#ec5677',
                  '#8156ec',
                  '#EC6B56',
                  '#d3778b',
                  '#8dd377',
                  '#a94835',
                ],
                borderColor: [
                  '#FEAE65',
                  '#545D78',
                  '#2D87BB',
                  '#64C2A6',
                  '#ec5677',
                  '#8156ec',
                  '#EC6B56',
                  '#d3778b',
                  '#8dd377',
                  '#a94835',
                ],
                borderWidth: 1,
              },
            ],
          }}
        />
      </div>

      <div
        key="donut"
        style={{
          width: '40%',
          marginLeft: '5%',
          marginRight: '5%',
          marginTop: '15px',
          marginBottom: '50px',
          display: 'inline-block',
        }}
      >
        <div>
          <h2 style={{ textAlign: 'center', fontSize: 24 }}>Sexual Orientation</h2>
        </div>
        <br />
        <Doughnut
          data={{
            labels: demographicAnalytics?.sexuality?.labels,
            datasets: [
              {
                label: '# of Insighters',
                data: demographicAnalytics?.sexuality?.data,
                backgroundColor: [
                  '#FEAE65',
                  '#545D78',
                  '#2D87BB',
                  '#64C2A6',
                  '#ec5677',
                  '#8156ec',
                  '#EC6B56',
                  '#d3778b',
                  '#8dd377',
                  '#a94835',
                ],
                borderColor: [
                  '#FEAE65',
                  '#545D78',
                  '#2D87BB',
                  '#64C2A6',
                  '#ec5677',
                  '#8156ec',
                  '#EC6B56',
                  '#d3778b',
                  '#8dd377',
                  '#a94835',
                ],
                borderWidth: 1,
              },
            ],
          }}
        />
      </div>

      <div
        key="donut"
        style={{
          width: '40%',
          marginLeft: '5%',
          marginRight: '5%',
          marginTop: '15px',
          marginBottom: '50px',
          display: 'inline-block',
        }}
      >
        <div>
          <h2 style={{ textAlign: 'center', fontSize: 24 }}>Race/Ethnicity</h2>
        </div>
        <br />
        <Doughnut
          data={{
            labels: demographicAnalytics?.ethnicity?.labels,
            datasets: [
              {
                label: '# of Insighters',
                data: demographicAnalytics?.ethnicity?.data,
                backgroundColor: [
                  '#FEAE65',
                  '#545D78',
                  '#2D87BB',
                  '#64C2A6',
                  '#ec5677',
                  '#8156ec',
                  '#EC6B56',
                  '#d3778b',
                  '#8dd377',
                  '#a94835',
                ],
                borderColor: [
                  '#FEAE65',
                  '#545D78',
                  '#2D87BB',
                  '#64C2A6',
                  '#ec5677',
                  '#8156ec',
                  '#EC6B56',
                  '#d3778b',
                  '#8dd377',
                  '#a94835',
                ],
                borderWidth: 1,
              },
            ],
          }}
        />
      </div>

      <div
        key="donut"
        style={{
          width: '40%',
          marginLeft: '5%',
          marginRight: '5%',
          marginTop: '15px',
          marginBottom: '50px',
          display: 'inline-block',
        }}
      >
        <div>
          <h2 style={{ textAlign: 'center', fontSize: 24 }}>Veteran Status</h2>
        </div>
        <br />
        <Doughnut
          data={{
            labels: demographicAnalytics?.veteran_status?.labels,
            datasets: [
              {
                label: '# of Insighters',
                data: demographicAnalytics?.veteran_status?.data,
                backgroundColor: [
                  '#FEAE65',
                  '#545D78',
                  '#2D87BB',
                  '#64C2A6',
                  '#ec5677',
                  '#8156ec',
                  '#EC6B56',
                  '#d3778b',
                  '#8dd377',
                  '#a94835',
                ],
                borderColor: [
                  '#FEAE65',
                  '#545D78',
                  '#2D87BB',
                  '#64C2A6',
                  '#ec5677',
                  '#8156ec',
                  '#EC6B56',
                  '#d3778b',
                  '#8dd377',
                  '#a94835',
                ],
                borderWidth: 1,
              },
            ],
          }}
        />
      </div>
    </Page>
  );

  // ---- Loading ----
  const loadingPageMarkup = (
    <SkeletonPage>
      <Layout>
        <Layout.Section>
          <Card sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText lines={9} />
            </TextContainer>
          </Card>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );

  const pageMarkup = dashboardLoading ? loadingPageMarkup : actualPageMarkup;

  return (
    <>
      {loadingMarkup}
      {pageMarkup}
    </>
  );
};

export default DemographicsDashboard;
