import React from 'react';
import { useParams } from 'react-router-dom';
import { Button, FormLayout, Card } from '@shopify/polaris';
import { Grid } from '@material-ui/core';
import FormInput from '../../form/components/FormInput';
import { SurveyMappingContext } from '../contexts/SurveyMappingContext';
import { SurveyMapping, SurveyMappingFormValues } from '../model';

interface SurveyMappingFormProps {
  surveyMapping?: SurveyMapping;
}

const SurveyMappingForm: React.FC<SurveyMappingFormProps> = (props) => {
  const { surveyMapping } = props;
  const { phaseId } = useParams<Record<string, string>>();
  const { updateSurveyMapping, surveyMappingSubmitting } = React.useContext(SurveyMappingContext);

  const [formValues, setFormValues] = React.useState<SurveyMappingFormValues>({});

  const surveyMappingAttributes = [
    'affiliation',
    'age_range',
    'become_an_insighter',
    'board_eligible',
    'caregiver_status',
    'conditions',
    'date_of_birth',
    'email',
    'first_name',
    'gender',
    'healthcare_certification_or_degree',
    'healthcare_first_specialty',
    'overall_healthcare_role',
    'specific_healthcare_role',
    'healthcare_second_specialty',
    'homework',
    'insighters_discovery_method',
    'last_name',
    'license_start_year',
    'med_school_graduation_year',
    'mobile_phone',
    'npi_number',
    'payout_category',
    'percent_patient_care',
    'physical_location',
    'preferred_contact_method',
    'race',
    'residency_end_year',
    'screen_status',
    'sexual_preference',
    'states_practicing',
    'transgender_identity',
    'veteran_status',
    'address1',
    'address2',
    'city',
    'state',
    'zip',
    'conditions_allergy',
    'conditions_autoimmune',
    'conditions_blood',
    'conditions_cancers',
    'conditions_brain',
    'conditions_breathing',
    'conditions_ear',
    'conditions_eye',
    'conditions_gi',
    'conditions_heart',
    'conditions_kidney',
    'conditions_liver',
    'conditions_mental_health',
    'conditions_metabolism',
    'conditions_muscle',
    'conditions_rare',
    'conditions_sexual',
    'conditions_skin',
    'conditions_vaccinations',
    'conditions_other',
    'conditions_client',
  ];

  React.useEffect(() => {
    setFormValues({
      affiliation: surveyMapping?.affiliation || '',
      age_range: surveyMapping?.age_range || '',
      become_an_insighter: surveyMapping?.become_an_insighter || '',
      caregiver_status: surveyMapping?.caregiver_status || '',
      conditions: surveyMapping?.conditions || '',
      date_of_birth: surveyMapping?.date_of_birth || '',
      email: surveyMapping?.email || '',
      first_name: surveyMapping?.first_name || '',
      gender: surveyMapping?.gender || '',
      healthcare_certification_or_degree: surveyMapping?.healthcare_certification_or_degree || '',
      healthcare_first_specialty: surveyMapping?.healthcare_first_specialty || '',
      overall_healthcare_role: surveyMapping?.overall_healthcare_role || '',
      specific_healthcare_role: surveyMapping?.specific_healthcare_role || '',
      healthcare_second_specialty: surveyMapping?.healthcare_second_specialty || '',
      homework: surveyMapping?.homework || '',
      insighters_discovery_method: surveyMapping?.insighters_discovery_method || '',
      last_name: surveyMapping?.last_name || '',
      license_start_year: surveyMapping?.license_start_year || '',
      med_school_graduation_year: surveyMapping?.med_school_graduation_year || '',
      mobile_phone: surveyMapping?.mobile_phone || '',
      npi_number: surveyMapping?.npi_number || '',
      payout_category: surveyMapping?.payout_category || '',
      percent_patient_care: surveyMapping?.percent_patient_care || '',
      physical_location: surveyMapping?.physical_location || '',
      preferred_contact_method: surveyMapping?.preferred_contact_method || '',
      race: surveyMapping?.race || '',
      residency_end_year: surveyMapping?.residency_end_year || '',
      screen_status: surveyMapping?.screen_status || '',
      sexual_preference: surveyMapping?.sexual_preference || '',
      states_practicing: surveyMapping?.states_practicing || '',
      transgender_identity: surveyMapping?.transgender_identity || '',
      veteran_status: surveyMapping?.veteran_status || '',
      address1: surveyMapping?.address1 || '',
      address2: surveyMapping?.address2 || '',
      city: surveyMapping?.city || '',
      state: surveyMapping?.state || '',
      zip: surveyMapping?.zip || '',
      conditions_allergy: surveyMapping?.conditions_allergy || '',
      conditions_autoimmune: surveyMapping?.conditions_autoimmune || '',
      conditions_blood: surveyMapping?.conditions_blood || '',
      conditions_cancers: surveyMapping?.conditions_cancers || '',
      conditions_brain: surveyMapping?.conditions_brain || '',
      conditions_breathing: surveyMapping?.conditions_breathing || '',
      conditions_ear: surveyMapping?.conditions_ear || '',
      conditions_eye: surveyMapping?.conditions_eye || '',
      conditions_gi: surveyMapping?.conditions_gi || '',
      conditions_heart: surveyMapping?.conditions_heart || '',
      conditions_kidney: surveyMapping?.conditions_kidney || '',
      conditions_liver: surveyMapping?.conditions_liver || '',
      conditions_mental_health: surveyMapping?.conditions_mental_health || '',
      conditions_metabolism: surveyMapping?.conditions_metabolism || '',
      conditions_muscle: surveyMapping?.conditions_muscle || '',
      conditions_rare: surveyMapping?.conditions_rare || '',
      conditions_sexual: surveyMapping?.conditions_sexual || '',
      conditions_skin: surveyMapping?.conditions_skin || '',
      conditions_vaccinations: surveyMapping?.conditions_vaccinations || '',
      conditions_other: surveyMapping?.conditions_other || '',
      conditions_client: surveyMapping?.conditions_client || '',
    });
  }, [surveyMapping, setFormValues]);

  const handleUpdate = () => {
    updateSurveyMapping(phaseId, formValues);
  };

  return (
    <FormLayout>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card sectioned title="Qualtrics Question IDs">
            <Grid container spacing={3}>
              {surveyMappingAttributes.map((attribute) => (
                <Grid key={attribute} item xs={5}>
                  <FormInput
                    label={attribute.replaceAll('_', ' ')}
                    value={formValues?.[attribute]}
                    onChange={(value) => setFormValues({ ...formValues, [attribute]: value })}
                  />
                </Grid>
              ))}
            </Grid>
          </Card>
        </Grid>
      </Grid>
      <>
        <Button primary onClick={handleUpdate} loading={surveyMappingSubmitting}>
          Update Survey Mapping
        </Button>
      </>
    </FormLayout>
  );
};

export default SurveyMappingForm;
