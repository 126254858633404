import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from '../../utils/axios.utils';
import { Payment, PaymentFormValues } from '../model';

interface SelectedDatesFilterInterface {
  start: Date | '';
  end: Date | '';
}

interface PaymentsContextInterface {
  fetchPayments?: (params?: string) => Promise<void>;
  payments?: Payment[];
  fetchPayment?: (payment_id: string) => Promise<void>;

  payment?: Payment;
  setPayment?: React.Dispatch<React.SetStateAction<Payment>>;

  paymentsLoading?: boolean;
  paymentsDownload?: Record<string, any>[];

  selectedDatesFilter?: SelectedDatesFilterInterface;
  setSelectedDatesFilter?: React.Dispatch<React.SetStateAction<SelectedDatesFilterInterface>>;

  filterValues?: Record<string, string>;
  setFilterValues?: React.Dispatch<React.SetStateAction<Record<string, string>>>;

  selectedDates?: any;
  setSelectedDates?: React.Dispatch<React.SetStateAction<any>>;

  statusFilters?: string[];
  setStatusFilters?: React.Dispatch<React.SetStateAction<string[]>>;

  knownAmountsFilterToUse?: string;
  setKnownAmountsFilterToUse?: React.Dispatch<React.SetStateAction<string>>;

  knownAmountsFilterUsed?: string;
  setKnownAmountsFilterUsed?: React.Dispatch<React.SetStateAction<string>>;

  totalRecords?: number;
  incompletePayments?: number;

  fetchFormOptions?: () => Promise<void>;
  formOptions?: Record<string, any[]>;
  updatePayment?: (payment_id: string, formValues: PaymentFormValues) => Promise<void>;
  updateExpense?: (payment_id: string, formValues: PaymentFormValues) => Promise<void>;
  deletePayment?: (payment_id: string) => Promise<void>;
  approvePayment?: (payment_id: string, formValues: any) => Promise<void>;
  // Don't need this any more
  approvePaymentForTremendous?: (payment_id: string, formValues: any) => Promise<void>;
  createPayment?: (formValues: PaymentFormValues, onSuccess?: () => void) => Promise<void>;

  formError?: string;
  paymentSubmitting?: boolean;
}

const PaymentsContext = React.createContext<PaymentsContextInterface>({});

const PaymentsContextConsumer = PaymentsContext.Consumer;
const PaymentsContextProvider: React.FC = ({ children }) => {
  const history = useHistory();

  const [payments, setPayments] = useState<Payment[]>([]);
  const [paymentsLoading, setPaymentsLoading] = useState<boolean>(false);
  const [selectedDatesFilter, setSelectedDatesFilter] = useState<SelectedDatesFilterInterface>({
    start: '',
    end: '',
  });
  const [formOptions, setFormOptions] = useState<Record<string, any[]>>({});
  const [paymentSubmitting, setPaymentSubmitting] = useState<boolean>(false);
  const [payment, setPayment] = useState<Payment>({});
  const [totalRecords, setTotalRecords] = useState<number>();
  const [formError, setFormError] = useState<string>(undefined);
  const [paymentsDownload, setPaymentsDownload] = React.useState<Record<string, any>[]>();
  const [statusFilters, setStatusFilters] = useState<string[]>(['incomplete']);
  const [knownAmountsFilterUsed, setKnownAmountsFilterUsed] = useState('Known');
  const [knownAmountsFilterToUse, setKnownAmountsFilterToUse] = useState('ransack[number_amount_not_null]=true');

  const [filterValues, setFilterValues] = useState<Record<string, string>>({ query: '', sort: '' });

  const [selectedDates, setSelectedDates] = useState<Record<string, any>>({
    start: new Date(1900, 0, 1),
    end: new Date(2100, 0, 1),
  });

  const toDate = (dateStr) => {
    const [year, month, day] = dateStr.split('-');
    return new Date(year, month - 1, day);
  };

  const fetchPayments = async (params = '') => {
    setPaymentsLoading(true);
    const response = await axios.get<string, any>(`payments.json?${params}`);

    setPayments(response?.data?.result);
    setTotalRecords(response?.data?.total_records);
    setPaymentsDownload(response?.data?.payment_csv_data);

    response?.data?.applied_filters?.forEach((filter) => {
      switch (filter.label) {
        case 'status':
          setStatusFilters(filter.value);
          break;
        case 'known_amounts':
          setKnownAmountsFilterUsed(filter.value);
          break;
        case 'processed_at':
          setSelectedDatesFilter({ start: toDate(filter.start), end: toDate(filter.end) });
          break;
        default:
          break;
      }
    });

    setPaymentsLoading(false);
  };

  const fetchPayment = async (payment_id: string) => {
    setPaymentsLoading(true);
    const response = await axios.get<string, any>(`payments/${payment_id}.json`);
    setPaymentsLoading(false);
    setPayment(response?.data?.result);
  };

  const fetchFormOptions = async () => {
    const response = await axios.get<string, any>(`payments/new.json`);

    setFormOptions(response?.data);
  };

  const updatePayment = async (payment_id: string, formValues: PaymentFormValues) => {
    try {
      setPaymentSubmitting(true);
      await axios
        .put<string, any>(`payments/${payment_id}.json`, {
          payment: { ...formValues },
        })
        .then(() => {
          setPaymentSubmitting(false);
          history.push(`/admin/payments`);
        })
        .catch((error) => {
          setPaymentSubmitting(false);
          setFormError(error.response.data.message.map((message: string) => `${message}\n`));
        });
    } catch {
      setPaymentSubmitting(false);
      setFormError('Something went wrong, please reload the page and try again.');
    }
  };

  const updateExpense = async (payment_id: string, formValues: PaymentFormValues) => {
    try {
      setPaymentSubmitting(true);
      await axios
        .put<string, any>(`payments/${payment_id}.json`, {
          payment: { ...formValues },
        })
        .then(() => {
          setPaymentSubmitting(false);
          history.push(`/admin/expenses`);
        })
        .catch((error) => {
          setPaymentSubmitting(false);
          setFormError(error.response.data.message.map((message: string) => `${message}\n`));
        });
    } catch {
      setPaymentSubmitting(false);
      setFormError('Something went wrong, please reload the page and try again.');
    }
  };

  const deletePayment = async (payment_id: string) => {
    setPaymentSubmitting(true);

    axios.delete<string, any>(`payments/${payment_id}`).then((response) => {
      setPaymentSubmitting(false);

      if (response.data.result.project_id) {
        history.push(`/admin/projects/${response.data.result.project_id}`);
      } else {
        history.push(`/admin/payments`);
      }
    });
  };

  const approvePayment = async (payment_id: string, formValues: any) => {
    try {
      setPaymentSubmitting(true);
      await axios
        .put<string, any>(`payments/${payment_id}/approve`, {
          payment: { ...formValues },
        })
        .then(() => {
          setPaymentSubmitting(false);
          history.push(`/admin/payments`);
        })
        .catch((error) => {
          setPaymentSubmitting(false);
          setFormError(error.response.data.message.map((message: string) => `${message}\n`));
        });
    } catch {
      setPaymentSubmitting(false);
      setFormError('Something went wrong, please reload the page and try again.');
    }
  };

  const approvePaymentForTremendous = async (payment_id: string, formValues: any) => {
    try {
      setPaymentSubmitting(true);
      await axios
        .put<string, any>(`payments/${payment_id}/approve_for_tremendous`, {
          payment: { ...formValues },
        })
        .then(() => {
          setPaymentSubmitting(false);
          history.push(`/admin/payments`);
        })
        .catch((error) => {
          setPaymentSubmitting(false);
          setFormError(error.response.data.message.map((message: string) => `${message}\n`));
        });
    } catch {
      setPaymentSubmitting(false);
      setFormError('Something went wrong, please reload the page and try again.');
    }
  };

  const createPayment = async (formValues: PaymentFormValues) => {
    try {
      setPaymentSubmitting(true);
      axios
        .post<string, any>(`payments.json`, {
          payment: { ...formValues },
        })
        .then(() => {
          setPaymentSubmitting(false);
        })
        .catch((error) => {
          setPaymentSubmitting(false);
          setFormError(error.response.data.error);
        });
    } catch {
      setPaymentSubmitting(false);
      setFormError('Something went wrong, please reload the page and try again.');
    }
  };

  return (
    <PaymentsContext.Provider
      value={{
        fetchPayments,
        payments,
        fetchPayment,

        payment,
        setPayment,

        paymentsLoading,
        paymentsDownload,

        fetchFormOptions,
        formOptions,

        selectedDatesFilter,
        setSelectedDatesFilter,

        filterValues,
        setFilterValues,

        selectedDates,
        setSelectedDates,

        statusFilters,
        setStatusFilters,

        knownAmountsFilterToUse,
        setKnownAmountsFilterToUse,

        knownAmountsFilterUsed,
        setKnownAmountsFilterUsed,

        totalRecords,

        updatePayment,
        updateExpense,
        deletePayment,
        approvePayment,
        approvePaymentForTremendous,
        createPayment,

        formError,
        paymentSubmitting,
      }}
    >
      {children}
    </PaymentsContext.Provider>
  );
};

export { PaymentsContextProvider, PaymentsContextConsumer, PaymentsContext };
