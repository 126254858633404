import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import AboutLandingSection from './sections/AboutLandingSection';
import LightbulbContentSection from './sections/LightbulbContentSection';
import InsightersTeamBios from './sections/InsightersTeamBios';
import OurValuesContent from './sections/OurValuesContent';
import GotAQuestion from './sections/GotAQuestion';
import RedesignFooter from '../common/RedesignFooter';

const useStyles = makeStyles({
  sectionGrid: {
    margin: 30,
    marginBottom: 50,
  },
});

const RedesignedHomepage: React.FC = () => {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item xs={12}>
        <AboutLandingSection />
      </Grid>
      <Grid item xs={12} className={classes.sectionGrid}>
        <LightbulbContentSection />
      </Grid>
      <Grid item xs={12} className={classes.sectionGrid}>
        <InsightersTeamBios />
      </Grid>
      <Grid item xs={12}>
        <OurValuesContent />
      </Grid>
      <Grid item xs={12} className={classes.sectionGrid}>
        <GotAQuestion />
      </Grid>
      <Grid item xs={12} className={classes.sectionGrid}>
        <RedesignFooter />
      </Grid>
    </Grid>
  );
};

export default RedesignedHomepage;
