import React from 'react';
import { useHistory } from 'react-router-dom';
import axios from '../../utils/axios.utils';
import { Respondent, RespondentFormValues } from '../model';
import { SelectOption } from '../../form/model';

interface RespondentsContextInterface {
  respondentLoading?: boolean;
  fetchRespondent?: (respondent_id: string) => Promise<void>;
  respondent?: Respondent;
  updateRespondent?: (
    opportunity_id: string,
    formValues: RespondentFormValues,
    afterUpdate?: () => void,
  ) => Promise<void>;
  createLeads?: (formValues: RespondentFormValues, afterCreate?: () => void) => Promise<void>;
  createRespondent?: (formValues: RespondentFormValues, afterCreate?: () => void) => Promise<void>;
  copyRespondentToPhase?: (
    respondentId: string,
    formValues: RespondentFormValues,
    afterCreate?: () => void,
  ) => Promise<void>;
  copyRespondentToInvOpp?: (
    respondentId: string,
    formValues: RespondentFormValues,
    afterCreate?: () => void,
  ) => Promise<void>;
  fetchFormOptions?: () => Promise<void>;
  formOptions?: Record<string, SelectOption[]>;
  respondentSubmitting?: boolean;
  sortParams?: string;
  setSortParams?: React.Dispatch<React.SetStateAction<string>>;
}

const RespondentsContext = React.createContext<RespondentsContextInterface>({});

const RespondentsContextConsumer = RespondentsContext.Consumer;
const RespondentsContextProvider: React.FC = ({ children }) => {
  const history = useHistory();
  const [respondent, setRespondent] = React.useState<Respondent>({});
  const [respondentLoading, setRespondentLoading] = React.useState<boolean>(false);
  const [respondentSubmitting, setRespondentSubmitting] = React.useState(false);
  const [formOptions, setFormOptions] = React.useState<Record<string, SelectOption[]>>();
  const [sortParams, setSortParams] = React.useState<string>('sort=');

  const fetchRespondent = async (respondent_id: string) => {
    setRespondentLoading(true);

    const response = await axios.get<string, any>(`respondents/${respondent_id}.json`);

    setRespondentLoading(false);
    setRespondent(response?.data?.result);
  };

  const fetchFormOptions = async () => {
    const response = await axios.get<string, any>(`respondents/new.json`);

    setFormOptions(response?.data);
  };

  const updateRespondent = async (
    respondent_id: string,
    formValues: RespondentFormValues,
    afterUpdate?: () => void,
  ) => {
    setRespondentSubmitting(true);

    await axios.put<string, any>(`respondents/${respondent_id}.json`, {
      respondent: { ...formValues },
    });

    setRespondentSubmitting(false);
    if (afterUpdate) {
      afterUpdate();
    } else {
      history.push(`/admin/respondents/${respondent_id}`);
    }
  };

  const createRespondent = async (formValues: RespondentFormValues, afterCreate?: () => void) => {
    setRespondentSubmitting(true);

    const response = await axios.post<string, any>(`respondents.json`, {
      respondent: { ...formValues },
    });

    setRespondentSubmitting(false);

    if (afterCreate) {
      afterCreate();
    } else {
      history.push(`/admin/respondents/${response.data.result.id}`);
    }
  };

  const copyRespondentToPhase = async (
    respondentId: string,
    formValues: RespondentFormValues,
    afterCreate?: () => void,
  ) => {
    setRespondentSubmitting(true);

    const response = await axios.post<string, any>(`respondents/${respondentId}/copy_to_phase.json`, {
      respondent: { ...formValues },
    });

    setRespondentSubmitting(false);

    if (afterCreate) {
      afterCreate();
    } else {
      history.push(`/admin/respondents/${response.data.result.id}`);
    }
  };

  const copyRespondentToInvOpp = async (
    respondentId: string,
    formValues: RespondentFormValues,
    afterCreate?: () => void,
  ) => {
    setRespondentSubmitting(true);

    const response = await axios.post<string, any>(`respondents/${respondentId}/copy_to_inv_opp.json`, {
      respondent: { ...formValues },
    });

    setRespondentSubmitting(false);

    if (afterCreate) {
      afterCreate();
    } else {
      history.push(`/admin/respondents/${response.data.result.id}`);
    }
  };

  const createLeads = async (formValues: RespondentFormValues, afterCreate?: () => void) => {
    setRespondentSubmitting(true);

    const response = await axios.post<string, any>(`leads.json`, {
      respondent: { ...formValues },
    });

    setRespondentSubmitting(false);

    if (afterCreate) {
      afterCreate();
    } else {
      history.push(`/admin/respondents/${response.data.result.id}`);
    }
  };

  return (
    <RespondentsContext.Provider
      value={{
        respondentLoading,
        fetchRespondent,
        respondent,
        createRespondent,
        copyRespondentToPhase,
        copyRespondentToInvOpp,

        createLeads,
        updateRespondent,
        fetchFormOptions,
        formOptions,
        respondentSubmitting,
        sortParams,
        setSortParams,
      }}
    >
      {children}
    </RespondentsContext.Provider>
  );
};

export { RespondentsContextProvider, RespondentsContextConsumer, RespondentsContext };
