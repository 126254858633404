import React from 'react';
import { PieChart, Pie, Cell } from 'recharts';

interface ProfileCompleteChartProps {
  profileCompleteChartData: any[];
  percentComplete?: number;
}

const ProfileCompleteChart: React.FunctionComponent<ProfileCompleteChartProps> = (props) => {
  const { percentComplete, profileCompleteChartData } = props;

  // ---- Page markup ----

  return (
    <PieChart width={200} height={180}>
      <text
        className="Polaris-Heading"
        style={{ fontSize: 20 }}
        x={108}
        y={88}
        textAnchor="middle"
        dominantBaseline="middle"
      >
        {`${percentComplete}%`}
      </text>
      <text className="Polaris-Heading" x={106} y={108} textAnchor="middle" dominantBaseline="middle">
        complete
      </text>
      <Pie
        dataKey="value"
        data={profileCompleteChartData}
        cx={100}
        cy={90}
        innerRadius={45}
        outerRadius={85}
        startAngle={90}
        endAngle={450}
        fill="#82ca9d"
      >
        {profileCompleteChartData?.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={entry.color} />
        ))}
      </Pie>
      {/* <Tooltip formatter={() => null} separator="" /> */}
    </PieChart>
  );
};

export default ProfileCompleteChart;
