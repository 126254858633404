import React from 'react';
import { IDataTableColumn } from '../../dataTable/DataTable/model';
import LeadGroupRowMenu from '../components/LeadGroupRowMenu';
import { LeadGroup } from '../model';
import { Phase } from '../../projectPhases/model';

const leadGroupColumns = (phase: Phase, handleUpdateLeadGroup: unknown): IDataTableColumn[] => {
  return [
    {
      name: 'Name',
      selector: 'name',
      sortable: true,
      grow: 0.7,
    },
    {
      name: 'Notes',
      selector: 'notes',
      sortable: true,
    },
    {
      name: 'Leads',
      selector: 'lead_count',
      sortable: true,
      grow: 0.7,
    },
    {
      cell: (leadGroup: LeadGroup) => (
        <LeadGroupRowMenu leadGroup={leadGroup} handleUpdateLeadGroup={handleUpdateLeadGroup} />
      ),
      name: 'ACTIONS',
      allowOverflow: true,
      button: true,
      selector: 'actions',
    },
  ];
};

export default leadGroupColumns;
