import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import ArrowIcon from '../../../assets/redesignAssets/staticImages/ArrowIcon.png';
import { HomepageResource } from '../model';
import ReadMoreArrow from '../../../shared/components/ReadMoreArrow';

interface HomepageResourceProps {
  homepageResource: HomepageResource;
}

const HomepageResourceViewer: React.FC<HomepageResourceProps> = (props) => {
  const { homepageResource } = props;

  return (
    <Grid container style={{ height: '200px' }}>
      <Grid
        item
        xs={12}
        md={6}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'row',
        }}
      >
        <img
          src={homepageResource?.s3_image_url}
          alt="Event"
          style={{
            height: '200px',
            width: '200px',
            borderRadius: 15,
          }}
        />
      </Grid>
      <Grid item xs={12} md={6} style={{ padding: 15, display: 'flex', alignItems: 'center' }}>
        <div>
          <p style={{ fontWeight: 600, marginBottom: 5, fontFamily: 'Crimson Text', fontSize: 18 }}>
            {homepageResource?.title}
          </p>
          <p style={{ fontFamily: 'Rethink Sans, sans-serif' }}>{homepageResource?.thumbnail_description}</p>
          <ReadMoreArrow message="Read more" link={homepageResource?.link} />
        </div>
      </Grid>
    </Grid>
  );
};

export default HomepageResourceViewer;
