export const qualtricsStatuses = [
  { label: 'Unknown', value: 'Unknown' },
  { label: 'Screened (ok to invite)', value: 'Screened (ok to invite)' },
  { label: 'Screened (invited)', value: 'Screened (invited)' },
  { label: 'Screened (needs follow-up screening)', value: 'Screened (needs follow-up screening)' },
  { label: 'Screened (and hold)', value: 'Screened (and hold)' },
  { label: 'Screened (and terminate)', value: 'Screened (and terminate)' },
  { label: 'Screened (over quota)', value: 'Screened (over quota)' },
  { label: 'Screened (standby)', value: 'Screened (standby)' },
  { label: 'Unscheduled', value: 'unscheduled' },
  { label: 'Scheduled', value: 'Scheduled' },
  { label: 'Reschedule', value: 'Reschedule' },
  { label: 'Complete', value: 'Complete' },
  { label: 'Unpaid', value: 'unpaid' },
  { label: 'Paid', value: 'paid' },
];

export const qualtricsStatusesWithUpdating = [...qualtricsStatuses, { label: 'Updating...', value: 'updating' }];

export default qualtricsStatuses;
