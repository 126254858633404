import React from 'react';

interface WarningIconProps {
  label?: string;
}

const WarningIcon: React.FC<WarningIconProps> = (props) => {
  const { label = 'Missing Field' } = props;

  return (
    <>
      <span
        style={{ float: 'left', marginRight: 5 }}
        className="Polaris-Icon Polaris-Icon--colorBase Polaris-Icon--applyColor"
      >
        <svg
          style={{ fill: '#D2042D' }}
          viewBox="0 0 20 20"
          className="Polaris-Icon__Svg"
          focusable="false"
          aria-hidden="true"
        >
          <path
            fillRule="evenodd"
            d="M9 11a1 1 0 1 0 2 0V9a1 1 0 1 0-2 0v2zm0 4a1 1 0 1 0 2 0 1 1 0 0 0-2 0zm8.895 1.549-7-14.04c-.339-.679-1.45-.679-1.79 0l-7 14.04A1.004 1.004 0 0 0 3 18h14a1 1 0 0 0 .895-1.451z"
          />
        </svg>
      </span>
      <p style={{ color: 'red', fontWeight: 700 }}>{label}</p>
    </>
  );
};

export default WarningIcon;
