import React from 'react';
import { useParams } from 'react-router-dom';
import { Button, Card, ChoiceList, Filters } from '@shopify/polaris';
import { Grid } from '@material-ui/core';
import { PhaseRespondentsContext } from '../contexts/PhaseRespondentsContext';
import interviewStatuses from '../../interviews/constants/interviewStatuses';
import { qualtricsStatuses } from '../../respondents/constants/qualtricsStatuses';

const PhaseRespondentsFilterBar: React.FunctionComponent = () => {
  const { phaseId } = useParams<Record<string, string>>();

  const [filterValues, setFilterValues] = React.useState<Record<string, string>>({
    query: sessionStorage.getItem(`respondentFilterQuery-${phaseId}`) || '',
  });
  const {
    fetchRespondents,
    qualtricsStatusFilter,
    setQualtricsStatusFilter,
    interviewStatusFilter,
    setInterviewStatusFilter,
    sortParams,
    setFetchParams,
  } = React.useContext(PhaseRespondentsContext);

  const [appliedFiltersDisplay, setAppliedFiltersDisplay] = React.useState<any[]>();

  React.useEffect(() => {
    const appliedFiltersArray = [];

    if (qualtricsStatusFilter) {
      appliedFiltersArray.push({
        key: 'qualtrics_status',
        label: `Filtered by Qualtrics: ${qualtricsStatusFilter}`,
        onRemove: () => {
          setQualtricsStatusFilter('');
          setFilterValues({ ...filterValues, qualtrics_status: null });
        },
      });
    }

    if (interviewStatusFilter) {
      appliedFiltersArray.push({
        key: 'interview_status',
        label: `Filtered by Interview: ${interviewStatusFilter}`,
        onRemove: () => {
          setInterviewStatusFilter('');
          setFilterValues({ ...filterValues, interview_status: null });
        },
      });
    }

    setAppliedFiltersDisplay([...appliedFiltersArray]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qualtricsStatusFilter, interviewStatusFilter]);

  const applyFilters = () => {
    const queryValue = `ransack[query]=${filterValues.query}`;
    const qualtricsStatusValue = filterValues.qualtrics_status
      ? `ransack[qualtrics_status_eq]=${filterValues.qualtrics_status}`
      : '';
    const interviewStatusValue = filterValues.interview_status
      ? `ransack[interview_status_eq]=${filterValues.interview_status}`
      : '';

    setFetchParams(`${queryValue}&${qualtricsStatusValue}&${interviewStatusValue}`);

    sessionStorage.setItem(
      `respondentFilters-${phaseId}`,
      `${queryValue}&${qualtricsStatusValue}&${interviewStatusValue}&${sortParams}`,
    );
    sessionStorage.setItem(`respondentFilterQuery-${phaseId}`, filterValues.query);

    fetchRespondents(`phase_id=${phaseId}&${queryValue}&${qualtricsStatusValue}&${interviewStatusValue}&${sortParams}`);
  };

  const clearFilters = () => {
    setFilterValues({ query: '', qualtrics_status: '' });
    setFetchParams('ransack[query]=');
    sessionStorage.removeItem(`respondentFilters-${phaseId}`);
    sessionStorage.removeItem(`respondentFilterQuery-${phaseId}`);
    fetchRespondents(`phase_id=${phaseId}&${sortParams}`);
  };

  const handleFiltersKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      applyFilters();
    }
  };

  return (
    <Card.Section>
      <div role="form" onKeyDown={handleFiltersKeyPress}>
        <Filters
          queryValue={filterValues.query}
          queryPlaceholder="Filter by name, user ID, or segment..."
          filters={[
            {
              key: 'qualtrics_status',
              label: 'Qualtrics Status',
              filter: (
                <ChoiceList
                  title="Qualtrics Status"
                  titleHidden
                  choices={qualtricsStatuses}
                  selected={[filterValues.qualtrics_status]}
                  onChange={(value) => setFilterValues({ ...filterValues, qualtrics_status: value[0] })}
                />
              ),
              shortcut: true,
            },
            {
              key: 'interview_status',
              label: 'Interview Status',
              filter: (
                <ChoiceList
                  title="Interview Status"
                  titleHidden
                  choices={interviewStatuses}
                  selected={[filterValues.interview_status]}
                  onChange={(value) => setFilterValues({ ...filterValues, interview_status: value[0] })}
                />
              ),
              shortcut: true,
            },
          ]}
          appliedFilters={appliedFiltersDisplay}
          onQueryChange={(value) => setFilterValues({ ...filterValues, query: value })}
          onQueryClear={() => setFilterValues({ query: '' })}
          onClearAll={() => null}
        >
          <Grid container>
            <Grid item>
              <div style={{ paddingLeft: '8px' }}>
                <Button primary onClick={applyFilters}>
                  Apply Filters
                </Button>
              </div>
            </Grid>
            <Grid item>
              <div style={{ paddingLeft: '8px' }}>
                <Button onClick={clearFilters}>Clear Filters</Button>
              </div>
            </Grid>
          </Grid>
        </Filters>
      </div>
    </Card.Section>
  );
};

export default PhaseRespondentsFilterBar;
