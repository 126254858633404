import React from 'react';
import { Button, Card, Filters } from '@shopify/polaris';
import { CalendlyEventContext } from '../contexts/CalendlyEventContext';

const CalendlyEventFilterBar: React.FunctionComponent = () => {
  const [filterValues, setFilterValues] = React.useState<Record<string, string>>({ query: '' });
  const { fetchCalendlyEvents } = React.useContext(CalendlyEventContext);

  const applyFilters = () => {
    fetchCalendlyEvents(`ransack[name_cont]=${filterValues.query}`);
  };

  const handleFiltersKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      applyFilters();
    }
  };

  return (
    <Card.Section>
      <div role="form" onKeyDown={handleFiltersKeyPress}>
        <Filters
          queryValue={filterValues.query}
          queryPlaceholder="Filter Calendly events by name..."
          filters={[]}
          appliedFilters={[]}
          onQueryChange={(value) => setFilterValues({ ...filterValues, query: value })}
          onQueryClear={() => setFilterValues({ ...filterValues, query: '' })}
          onClearAll={() => null}
        >
          <div style={{ paddingLeft: '8px' }}>
            <Button onClick={applyFilters}>Apply Filter</Button>
          </div>
        </Filters>
      </div>
    </Card.Section>
  );
};

export default CalendlyEventFilterBar;
