import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Card, FormLayout, Layout } from '@shopify/polaris';
import { Grid, Typography } from '@material-ui/core';

import FormInput from '../../form/components/FormInput';

import axios from '../../utils/axios.utils';

const CreateTremendousCampaign: React.FunctionComponent = () => {
  const history = useHistory();

  const [formValues, setFormValues] = useState<any>({});
  const [campaignSubmitting, setCampaignSubmitting] = useState<boolean>(false);

  const handleCreate = async () => {
    setCampaignSubmitting(true);
    await axios.post('tremendous_campaigns', formValues).then((response) => {
      history.push('/admin/tremendous-campaigns');
    });
  };

  return (
    <Layout>
      <Layout.Section>
        <Typography variant="h3" gutterBottom>
          Create Tremendous Campaign
        </Typography>
        <Card sectioned>
          <FormLayout>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <FormInput
                  label="Name"
                  type="text"
                  value={formValues.name}
                  onChange={(value) => setFormValues({ ...formValues, name: value })}
                />
              </Grid>
              <Grid item xs={12}>
                <FormInput
                  label="Campaign ID"
                  type="text"
                  value={formValues.campaign_id}
                  onChange={(value) => setFormValues({ ...formValues, campaign_id: value })}
                />
              </Grid>
            </Grid>
            <Button primary onClick={handleCreate} loading={campaignSubmitting} disabled={campaignSubmitting}>
              Create Campaign
            </Button>
            {/* {formError && <p style={{ color: 'red', fontSize: '14px', marginTop: 20 }}>{formError}</p>} */}
          </FormLayout>
        </Card>
      </Layout.Section>
      <Layout.Section secondary />
    </Layout>
  );
};

export default CreateTremendousCampaign;
