import React from 'react';
import { Bar } from 'react-chartjs-2';

const options = {
  plugins: {
    title: {
      display: false,
    },
  },
  responsive: true,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
};

interface DemographicsStackedBarChartProps {
  data?: Record<string, any>;
}

const DemographicsStackedBarChart: React.FC<DemographicsStackedBarChartProps> = (props) => {
  const { data } = props;

  return (
    <>
      <Bar data={data} options={options} />
    </>
  );
};

export default DemographicsStackedBarChart;
