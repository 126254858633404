import React from 'react';
import { useParams } from 'react-router-dom';
import { Card } from '@shopify/polaris';
import { Grid } from '@material-ui/core';
import Page from '../../shared/components/Page';
import BackButton from '../../navigation/components/BackButton';
import { SurveyResponsesContext } from '../contexts/SurveyResponsesContext';
import InformationField from '../../form/components/InformationField';

const QualtricsMetadataDetail: React.FunctionComponent = () => {
  const { phaseId, respondentId } = useParams<Record<string, string>>();
  const [qualtricsMetadataFetched, setQualtricsMetadataFetched] = React.useState(false);

  const { fetchQualtricsMetadata, qualtricsMetadata, metadataError } = React.useContext(SurveyResponsesContext);

  React.useEffect(() => {
    if (!qualtricsMetadataFetched) {
      fetchQualtricsMetadata(respondentId, phaseId);
      setQualtricsMetadataFetched(true);
    }
  }, [respondentId, phaseId, fetchQualtricsMetadata, qualtricsMetadataFetched, setQualtricsMetadataFetched]);

  // ---- Page markup ----

  return (
    <>
      <BackButton pageAction />
      <Page fullWidth title="Qualtrics Metadata">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {qualtricsMetadata.qualtrics_metadata && (
              <Grid container>
                <Grid item xs={3}>
                  <InformationField label="Respondent Name" value={qualtricsMetadata.respondent_name} />
                </Grid>
                <Grid item xs={3}>
                  <InformationField label="User ID" value={qualtricsMetadata.respondent_uuid} />
                </Grid>
              </Grid>
            )}
            <br />
            <Card sectioned>
              {metadataError && <p style={{ color: 'red' }}>{metadataError}</p>}
              {qualtricsMetadata.qualtrics_metadata && (
                <Grid container>
                  {Object?.keys(qualtricsMetadata.qualtrics_metadata)?.map((key) => (
                    <Grid item key={key}>
                      <Card.Section title={key.replaceAll('_', ' ')}>
                        {qualtricsMetadata.qualtrics_metadata[key]}
                      </Card.Section>
                    </Grid>
                  ))}
                </Grid>
              )}
            </Card>
          </Grid>
        </Grid>
      </Page>
    </>
  );
};

export default QualtricsMetadataDetail;
