import React from 'react';
import { useHistory } from 'react-router-dom';
import DataTable from '../../dataTable/DataTable/DataTable';
import { IDataTableColumn } from '../../dataTable/DataTable/model';
import { ExpensesContext } from '../contexts/ExpensesContext';
import { Expense } from '../model';

const ExpensesList: React.FunctionComponent = () => {
  const history = useHistory();
  const [expensesFetched, setExpensesFetched] = React.useState(false);
  const [dateFilters] = React.useState({
    start: new Date(new Date().getTime() - 14 * 24 * 60 * 60 * 1000),
    end: new Date(new Date().getTime() + 365 * 24 * 60 * 60 * 1000),
  });

  const getStatusColor = (status: string) => {
    if (!status) {
      return '';
    }

    if (status.toLowerCase() === 'missing details' || status.toLowerCase() === 'failed') {
      return 'red';
    }

    if (status.toLowerCase() === 'incomplete' || status.toLowerCase() === 'hold') {
      return 'orange';
    }

    if (status.toLowerCase() === 'complete') {
      return 'green';
    }

    return '#4ba9c8';
  };

  const columns: IDataTableColumn<any>[] = [
    {
      name: 'Description',
      selector: 'description',
      sortable: true,
    },
    {
      name: 'Project Number',
      selector: 'project_number',
      sortable: true,
    },
    {
      name: 'Created By',
      selector: 'created_by',
      sortable: true,
      grow: 1.2,
    },
    {
      name: 'Created At',
      selector: 'created_at',
      sortable: true,
      grow: 1.2,
    },
    {
      name: 'Amount',
      selector: 'number_amount',
      sortable: true,
      cell: ({ number_amount }) => <div>${number_amount}</div>,
    },
    {
      name: 'Status',
      selector: 'status',
      sortable: true,
      cell: ({ status }) => (
        <div
          style={{
            fontWeight: 700,
            textTransform: 'uppercase',
            fontSize: 12,
            color: getStatusColor(status),
          }}
        >
          {status}
        </div>
      ),
    },
  ];

  const { expenses, fetchExpenses, expenseLoading, totalRecords, sortParams, setSortParams, fetchParams } =
    React.useContext(ExpensesContext);

  React.useEffect(() => {
    const expenseFilters = sessionStorage.getItem('expenseFilters');

    if (!expensesFetched) {
      if (expenseFilters) {
        fetchExpenses(expenseFilters);
      } else {
        fetchExpenses();
      }
      setExpensesFetched(true);
    }
  }, [expensesFetched, setExpensesFetched, fetchExpenses, dateFilters]);

  const handleColumnSort = async (column: Record<string, string>, sortDirection: string) => {
    const columnNamesToRansack = {
      description: 'description',
      project_number: 'project_project_number',
      created_by: 'user_last_name',
      created_at: 'created_at',
      number_amount: 'number_amount',
      status: 'status',
    };

    const sort = `sort=${columnNamesToRansack[column.selector]} ${sortDirection}`;

    setSortParams(sort);
    fetchExpenses(`${fetchParams}&${sort}`);
  };

  const handlePageChange = (page: number) => {
    fetchExpenses(`page=${page}&${fetchParams}&${sortParams}`);
  };

  return (
    <DataTable
      onRowClicked={(expense: Expense) => history.push(`/admin/expenses/${expense?.id}`)}
      columns={columns}
      data={expenses}
      striped
      progressPending={expenseLoading}
      highlightOnHover
      pointerOnHover
      noHeader
      pagination
      paginationPerPage={25}
      paginationRowsPerPageOptions={[25]}
      paginationServer
      paginationTotalRows={totalRecords}
      onChangePage={handlePageChange}
      sortServer
      onSort={handleColumnSort}
    />
  );
};

export default ExpensesList;
