import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Card } from '@shopify/polaris';
import { Grid, makeStyles, createStyles } from '@material-ui/core';
import CheckIcon from '@mui/icons-material/Check';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import ClearIcon from '@mui/icons-material/Clear';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { ProfileCompletePercentages } from '../model';
import ProfileCompleteChart from './ProfileCompleteChart';

const useStyles = makeStyles((theme) =>
  createStyles({
    profileCompleteChartContainer: {
      [theme.breakpoints.up(985)]: {
        borderRight: '1px solid rgba(0, 0, 0, 0.2)',
        paddingRight: '10px',
      },
      [theme.breakpoints.down(985)]: {
        borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
        paddingBottom: '10px',
      },
    },
    profileCompleteDetailsContainer: {
      padding: '20px 0 10px 20px',
      [theme.breakpoints.down(500)]: {
        padding: '10px 0 10px 0',
      },
    },
  }),
);

interface ProfileCompletionTrackerProps {
  profileCompletePercentages: ProfileCompletePercentages;
}

const ProfileCompletionTracker: React.FunctionComponent<ProfileCompletionTrackerProps> = (props) => {
  const { profileCompletePercentages } = props;
  const history = useHistory();
  const classes = useStyles();

  const percentCompleteDisplayText = {
    missing_date_of_birth: { text: 'Date of Birth', color: '#f48574' },
    missing_conditions: { text: 'Conditions', color: '#f48574' },
    missing_healthcare_role: { text: 'Healthcare Roles', color: '#f48574' },
    missing_demographic_info: { text: 'Demographic Information', color: '#f48574' },
  };

  return (
    <Card sectioned title="">
      <Grid container>
        <Grid item xs={12} md={4} className={classes.profileCompleteChartContainer}>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <ProfileCompleteChart
              percentComplete={profileCompletePercentages?.percent_complete}
              profileCompleteChartData={profileCompletePercentages?.percent_complete_chart_data}
            />
          </div>
        </Grid>
        <Grid item xs={12} md={8} className={classes.profileCompleteDetailsContainer}>
          <Grid container>
            {Object.keys(percentCompleteDisplayText).map((key) => (
              <Grid
                key={key}
                item
                xs={12}
                md={5}
                style={{ cursor: 'pointer' }}
                onClick={() => history.push('/profile')}
              >
                <div style={{ display: 'flex' }}>
                  <Grid item xs={1} md={2}>
                    {profileCompletePercentages?.[key] ? (
                      <ClearIcon fontSize="large" style={{ color: 'red' }} />
                    ) : (
                      <CheckBoxIcon fontSize="large" style={{ color: `${percentCompleteDisplayText[key].color}` }} />
                    )}
                  </Grid>
                  <Grid item xs={9} md={12}>
                    <h2 className="Polaris-Heading">{percentCompleteDisplayText[key].text}</h2>
                  </Grid>
                </div>
              </Grid>
            ))}
          </Grid>
          <Grid container spacing={1} style={{ paddingTop: '20px' }}>
            <Grid item>
              <Button primary onClick={() => history.push('/profile')}>
                Complete Profile
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

export default ProfileCompletionTracker;
