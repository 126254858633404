import React from 'react';
import { IDataTableColumn } from '../../dataTable/DataTable/model';

const invitationColumns = (): IDataTableColumn[] => [
  {
    name: 'Invitation Opportunity Name',
    selector: 'name',
    sortable: true,
  },
  {
    name: 'Project Number',
    selector: 'project_number',
    sortable: true,
  },
  {
    name: 'Number of Insighters With Invitation Opportunity',
    selector: 'invitation_partners_count',
    sortable: true,
  },
  {
    name: 'Number of Invitations Received',
    selector: 'invitations_count',
    sortable: true,
  },
  {
    name: 'Default Invitation Amount',
    selector: 'default_inviter_amount',
    sortable: true,
    cell: ({ default_inviter_amount }): React.ReactNode => <div>${default_inviter_amount}</div>,
  },
  {
    name: 'Inviter Award Type',
    selector: 'inviter_award_type',
    sortable: true,
    cell: ({ inviter_award_type }): React.ReactNode => (
      <div style={{ textTransform: 'capitalize' }}>{inviter_award_type}</div>
    ),
  },
  {
    name: 'Start Date',
    selector: 'start_date',
    sortable: true,
    sortFunction: (rowA, rowB) => new Date(rowA.start_date).getTime() - new Date(rowB.start_date).getTime(),
  },
  {
    name: 'End Date',
    selector: 'end_date',
    sortable: true,
    sortFunction: (rowA, rowB) => new Date(rowA.start_date).getTime() - new Date(rowB.start_date).getTime(),
  },
  {
    name: 'Status',
    selector: 'status',
    sortable: true,
    cell: (row): React.ReactNode => (
      <div
        style={{
          fontWeight: 700,
          textTransform: 'uppercase',
          fontSize: 14,
          color: row.status === 'active' ? 'green' : 'red',
        }}
      >
        {row.status}
      </div>
    ),
  },
];

export default invitationColumns;
