import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from '../../utils/axios.utils';
import { InsighterFinderFormValues, InvitationPartnerFormValues } from '../model';
import { Insighter } from '../../insighters/model';
import defaultFilter from '../constants/filters';

interface InsighterFinderContextInterface {
  createLeads?: (formValues: InsighterFinderFormValues, afterCreate?: () => void) => Promise<void>;
  leadsSubmitting?: boolean;

  fetchFormOptions?: () => Promise<void>;
  formOptions?: Record<string, any[]>;

  fetchPhaseOptions?: (projectId: number | string) => Promise<void>;
  phaseOptions?: Record<string, any[]>;

  fetchLeadGroupOptions?: (phaseId: number | string) => Promise<void>;
  leadGroupOptions?: Record<string, any[]>;

  fetchInsighters?: (params?: string, operator?: string) => Promise<void>;
  insighterLoading?: boolean;
  insighters?: Insighter[];
  totalRecords?: number;

  leadsDownload?: Record<string, any>[];

  filteredInsighterIDs?: string[];

  selectedLeads?: Record<string, any>[];
  setSelectedLeads?: React.Dispatch<React.SetStateAction<Record<string, any>[]>>;

  addBulkInvitationPartners?: (formValues: InvitationPartnerFormValues) => Promise<void>;
  invitationPartnersSubmitting?: boolean;

  filterBuilderValue?: any;
  setFilterBuilderValue?: React.Dispatch<React.SetStateAction<any>>;

  filterBuilderDisabled?: boolean;
  setFilterBuilderDisabled?: React.Dispatch<React.SetStateAction<boolean>>;

  projectOptionsFetched?: boolean;

  handleProjectChange?: (value: string) => void;
  handlePhaseChange?: (value: string) => void;

  selectedProject?: string;
  setSelectedProject?: React.Dispatch<React.SetStateAction<string>>;

  selectedPhase?: string;
  setSelectedPhase?: React.Dispatch<React.SetStateAction<string>>;

  showPhasePicker?: boolean;
  setShowPhasePicker?: React.Dispatch<React.SetStateAction<boolean>>;
}

const InsighterFinderContext = React.createContext<InsighterFinderContextInterface>({});
const InsighterFinderContextConsumer = InsighterFinderContext.Consumer;

const InsighterFinderContextProvider: React.FC = ({ children }) => {
  const history = useHistory();
  const [leadsSubmitting, setLeadsSubmitting] = useState(false);
  const [formOptions, setFormOptions] = useState<Record<string, any[]>>();
  const [phaseOptions, setPhaseOptions] = useState();
  const [leadGroupOptions, setLeadGroupOptions] = useState();
  const [insighters, setInsighters] = useState<Insighter[]>([]);
  const [totalRecords, setTotalRecords] = useState<number>();
  const [insighterLoading, setInsighterLoading] = useState<boolean>(false);
  const [filteredInsighterIDs, setFilteredInsighterIDs] = useState<string[]>([]);
  const [selectedLeads, setSelectedLeads] = useState<Record<string, any>[]>([]);
  const [leadsDownload, setLeadsDownload] = useState<Record<string, any>[]>();
  const [invitationPartnersSubmitting, setInvitationPartnersSubmitting] = useState(false);
  const [filterBuilderDisabled, setFilterBuilderDisabled] = useState(false);
  const [projectOptionsFetched, setProjectOptionsFetched] = useState(false);
  const [selectedProject, setSelectedProject] = useState<string>();
  const [selectedPhase, setSelectedPhase] = useState<string>();
  const [showPhasePicker, setShowPhasePicker] = useState(false);

  const [filterBuilderValue, setFilterBuilderValue] = useState(
    JSON.parse(sessionStorage.getItem('filterBuilderValue')) || defaultFilter,
  );

  const fetchInsighters = async (params: string, operator: string) => {
    setInsighterLoading(true);

    const response = await axios.get<string, any>(`insighters/search?operator=${operator}&${params}`);

    setFilteredInsighterIDs(response?.data?.filtered_insighter_ids);
    setTotalRecords(response?.data?.total_records);
    setInsighters(response?.data?.result);

    setLeadsDownload(response?.data?.insighters_csv_data);

    setInsighterLoading(false);
  };

  const createLeads = async (formValues: InsighterFinderFormValues, afterCreate?: () => void) => {
    setLeadsSubmitting(true);

    const response = await axios.post<string, any>(`leads`, {
      lead_finder: { ...formValues },
    });

    setLeadsSubmitting(false);

    if (afterCreate) {
      afterCreate();
    } else {
      history.push(`/admin/respondents/${response.data.result.id}`);
    }
  };

  const fetchFormOptions = async () => {
    const response = await axios.get<string, any>(`insighters/new.json`);
    setFormOptions(response?.data);
  };

  const fetchPhaseOptions = async (projectId: number | string) => {
    const response = await axios.get<string, any>(`leads/new?project_id=${projectId}`);
    setPhaseOptions(response?.data);
  };

  const fetchLeadGroupOptions = async (phaseId: number | string) => {
    const response = await axios.get<string, any>(`leads/new?phase_id=${phaseId}`);
    setLeadGroupOptions(response?.data);
  };

  const handleProjectChange = async (value: string) => {
    fetchPhaseOptions(value);
    setSelectedProject(value);
  };

  const handlePhaseChange = async (value: string) => {
    fetchLeadGroupOptions(value);
    setSelectedPhase(value);
  };

  const addBulkInvitationPartners = async (formValues: InvitationPartnerFormValues) => {
    setInvitationPartnersSubmitting(true);

    await axios.post<string, any>(`bulk_invitation_partners`, {
      invitation_partner: { ...formValues },
    });

    setInvitationPartnersSubmitting(false);
    history.push(`/admin/invitation-opportunities/${formValues.invitation_opportunity_id}`);
  };

  return (
    <InsighterFinderContext.Provider
      value={{
        createLeads,
        leadsSubmitting,
        fetchFormOptions,
        formOptions,
        fetchPhaseOptions,
        phaseOptions,
        fetchLeadGroupOptions,
        leadGroupOptions,
        fetchInsighters,
        insighterLoading,
        insighters,
        totalRecords,
        leadsDownload,
        filteredInsighterIDs,
        selectedLeads,
        setSelectedLeads,
        addBulkInvitationPartners,
        invitationPartnersSubmitting,
        filterBuilderValue,
        setFilterBuilderValue,
        filterBuilderDisabled,
        setFilterBuilderDisabled,

        projectOptionsFetched,

        handleProjectChange,
        handlePhaseChange,

        selectedProject,
        setSelectedProject,
        selectedPhase,
        setSelectedPhase,

        showPhasePicker,
        setShowPhasePicker,
      }}
    >
      {children}
    </InsighterFinderContext.Provider>
  );
};

export { InsighterFinderContextProvider, InsighterFinderContextConsumer, InsighterFinderContext };
