import React, { useState, Dispatch, SetStateAction } from 'react';
import { Typography, Grid } from '@mui/material';
import ConfirmCancelModal from '../../modal/components/MaterialModal';

interface CheckPaymentModalProps {
  checkPaymentModalOpen: boolean;
  setCheckPaymentModalOpen: Dispatch<SetStateAction<boolean>>;
  userEmail: string;
}

const NotSeenPaymentModal: React.FC<CheckPaymentModalProps> = (props) => {
  const { checkPaymentModalOpen, setCheckPaymentModalOpen, userEmail } = props;

  return (
    <ConfirmCancelModal
      isOpen={checkPaymentModalOpen}
      title="Check on Missing Payment"
      onConfirm={() => setCheckPaymentModalOpen(false)}
      confirmLabel="OK"
      hasConfirmButton
      hasFooterBar={false}
      height="350px"
    >
      <Grid container style={{ padding: 30 }}>
        <Grid item xs={12}>
          <Typography variant="h4">Haven&apos;t seen your payment?</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" style={{ marginTop: 20 }}>
            You should have received an email from our payment partner, Tremendous. It was sent to the {userEmail}{' '}
            address. Please search your inbox for &quot;Tremendous&quot;. You might also want to check your spam and
            junk folders. If you still cannot find the email, please reach out to us at{' '}
            <a href="mailto:hello@theinsighters.com" style={{ color: '#ebac34', fontWeight: 600 }}>
              hello@theinsighters.com
            </a>
          </Typography>
        </Grid>
      </Grid>
    </ConfirmCancelModal>
  );
};

export default NotSeenPaymentModal;
