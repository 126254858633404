import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router';
import { Grid, Card, Button } from '@material-ui/core';
import { Opportunity, OpportunityKeyDetail } from '../model';
import { Icon } from '../../icons/model';
import ChooseIconModal from '../components/ChooseIconModal';
import KeyDetailsComboBox from '../components/KeyDetailComboBox';
import axios from '../../utils/axios.utils';

import AdminPageHeader from '../../adminTools/components/AdminPageHeader';

let emptyKeyDetail = {
  id: null,
  position_index: 0,
  content: '',
  icon_id: null,
  s3_image_url: null,
};

const OpportunityEditKeyDetailsPage: React.FC = (props) => {
  let { opportunityId } = useParams<{ opportunityId: string }>();
  let history = useHistory();

  let [opportunity, setOpportunity] = useState<Opportunity>();
  let [icons, setIcons] = useState<Icon[]>([]);
  let [chooseIconModalOpen, setChooseIconModalOpen] = useState<boolean>(false);
  let [keyDetailIndexToEdit, setKeyDetailIndexToEdit] = useState<number>(null);

  let fetchIcons = async () => {
    try {
      let response = await axios.get('icons');
      setIcons(response?.data?.result);
    } catch {
      console.log('Error fetching icons');
    }
  };

  const fetchOpportunity = async () => {
    try {
      const response = await axios.get(`opportunities/${opportunityId}`);
      let fetchedOpportunity = response?.data?.result;
      if (fetchedOpportunity.opportunity_key_details.length === 0) {
        fetchedOpportunity.opportunity_key_details.push(emptyKeyDetail);
      }
      setOpportunity(response?.data?.result);
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchIcons();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetchOpportunity();
    // eslint-disable-next-line
  }, [opportunityId]);

  let handleSaveOpportunity = async () => {
    try {
      await axios.put(`opportunities/${opportunityId}/key_details`, {
        key_details: opportunity?.opportunity_key_details,
      });
      history.push(`/admin/opportunities/${opportunityId}`);
    } catch (error: any) {
      console.log(error);
    }
  };

  let handleAddNewKeyDetail = () => {
    let newKeyDetail: OpportunityKeyDetail = { ...emptyKeyDetail };
    newKeyDetail.position_index = opportunity.opportunity_key_details.length;

    let keyDetails = [...opportunity.opportunity_key_details];
    keyDetails.push(newKeyDetail);

    let updatedOpportunity = { ...opportunity, opportunity_key_details: keyDetails };
    setOpportunity(updatedOpportunity);
  };

  return (
    <Grid container>
      <ChooseIconModal
        isOpen={chooseIconModalOpen}
        setOpen={setChooseIconModalOpen}
        icons={icons}
        keyDetailIndexToEdit={keyDetailIndexToEdit}
        opportunity={opportunity}
        setOpportunity={setOpportunity}
      />
      <AdminPageHeader
        title={`Edit Key Details for ${opportunity?.name}`}
        backButton={{ label: 'Edit Opportunity', link: `/admin/opportunities/${opportunityId}/edit` }}
      />
      <Grid item xs={12}>
        <Card style={{ marginTop: 10, padding: '2%' }}>
          <Grid container>
            {opportunity?.opportunity_key_details?.map((keyDetail, index) => (
              <Grid item xs={12} key={`keyDetail-${index}`} style={{ marginBottom: 10 }}>
                <KeyDetailsComboBox
                  opportunity={opportunity}
                  setOpportunity={setOpportunity}
                  keyDetail={keyDetail}
                  index={index}
                  setChooseIconModalOpen={setChooseIconModalOpen}
                  setKeyDetailIndexToEdit={setKeyDetailIndexToEdit}
                />
              </Grid>
            ))}
          </Grid>
        </Card>
        <Grid item xs={12} style={{ marginTop: 10 }}>
          <Button className="secondary-btn" onClick={handleAddNewKeyDetail}>
            + Key Detail
          </Button>
        </Grid>
        <Grid item xs={12} style={{ marginTop: 10 }}>
          <Button className="primary-btn" onClick={handleSaveOpportunity}>
            Save Key Details
          </Button>
          <Button
            className="secondary-btn"
            style={{ marginLeft: 15 }}
            onClick={() => history.push(`/admin/opportunities/${opportunity.id}`)}
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OpportunityEditKeyDetailsPage;
