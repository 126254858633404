import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@material-ui/core';
import DataTable from '../../dataTable/DataTable/DataTable';
import { IDataTableColumn } from '../../dataTable/DataTable/model';
import { Interview } from '../../interviews/model';

const getStatusColor = (status: string) => {
  if (
    status === 'past due' ||
    status?.toLowerCase() === 'rescheduling needed' ||
    status?.toLowerCase() === 'do not reschedule'
  ) {
    return 'red';
  }

  if (status === 'complete') {
    return 'green';
  }

  return '#4ba9c8';
};

export const sectionColumns: IDataTableColumn<any>[] = [
  {
    name: 'Project',
    selector: 'project_name',
    sortable: true,
  },
  {
    name: 'Project Number',
    selector: 'project_number',
    sortable: true,
    cell: (row) => (
      <div>
        <a href={`/admin/projects/${row.project_id}`}>{row.project_number}</a>
      </div>
    ),
  },
  {
    name: 'Respondent',
    selector: 'user_name',
    sortable: true,
  },
  {
    name: 'Scheduled Time',
    selector: 'scheduled_time',
    sortable: true,
    sortFunction: (rowA: Interview, rowB: Interview) =>
      new Date(`${rowA.scheduled_date_form} ${rowA.scheduled_time_form}`).getTime() -
      new Date(`${rowB.scheduled_date_form} ${rowB.scheduled_time_form}`).getTime(),
  },
  {
    name: 'Recruiter',
    selector: 'recruiter',
    sortable: true,
  },
  {
    name: 'Project Manager',
    selector: 'project_manager',
    sortable: true,
  },
  {
    name: 'Interview Status',
    selector: 'status',
    sortable: true,
    cell: ({ status }) => (
      <div
        style={{
          fontWeight: 700,
          textTransform: 'uppercase',
          fontSize: 14,
          color: getStatusColor(status?.toLowerCase()),
        }}
      >
        {status}
      </div>
    ),
  },
];

interface ProjectHistoryProps {
  interviews: any;
}

const ProjectHistory: React.FC<ProjectHistoryProps> = (props) => {
  const { interviews } = props;

  return (
    <>
      <Accordion>
        <AccordionSummary>
          <Typography variant="h5" style={{ fontSize: 14, fontWeight: 'bold' }}>
            Project History ({interviews?.length})
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <DataTable
            onRowClicked={() => null}
            columns={sectionColumns}
            data={interviews}
            striped
            highlightOnHover
            pointerOnHover
            noHeader
          />
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default ProjectHistory;
