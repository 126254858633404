import React, { useCallback, useState } from 'react';
import { Button, Card, Loading, Page, Toast } from '@shopify/polaris';
import { useHistory } from 'react-router-dom';
import DataTableSkeleton from '../../loading/components/DataTableSkeleton';
import DataTable from '../../dataTable/DataTable/DataTable';
import InsighterRoleFilterBar from '../components/InsighterRoleFilterBar';
import { InsighterRolesContext } from '../contexts/InsighterRolesContext';
import { IDataTableColumn } from '../../dataTable/DataTable/model';

const insighterRoleColumns = (): IDataTableColumn[] => [
  {
    name: 'Name',
    selector: 'name',
    sortable: true,
  },
  {
    name: 'Insighter Counter',
    selector: 'insighter_count',
    sortable: true,
  },
  {
    name: 'HCP Role',
    selector: 'hcp_role',
    sortable: true,
    cell: ({ hcp_role }): React.ReactNode => <div>{hcp_role ? 'Yes' : 'No'}</div>,
  },
  {
    name: 'Status',
    selector: 'status',
    sortable: true,
    cell: ({ status }): React.ReactNode => (
      <div
        style={{
          fontWeight: 700,
          textTransform: 'uppercase',
          fontSize: 14,
          color: status === 'active' ? 'green' : 'red',
        }}
      >
        {status}
      </div>
    ),
  },
  {
    name: 'Actions',
    selector: 'status',
    sortable: true,
    cell: (row): React.ReactNode => (
      <div style={{ marginRight: 10 }}>
        <Button url={`/admin/insighter-roles/${row.id}/edit`}>Edit</Button>
      </div>
    ),
  },
];

interface InsighterRolesListProps {
  isLoading: boolean;
}

const InsighterRolesList: React.FunctionComponent<InsighterRolesListProps> = (props) => {
  const { isLoading } = props;
  const [insighterRolesFetched, setInsighterRolesFetched] = React.useState<boolean>(false);

  const { insighterRoles, fetchInsighterRoles, insighterRolesLoading } = React.useContext(InsighterRolesContext);

  React.useEffect(() => {
    if (!insighterRolesFetched) {
      setInsighterRolesFetched(true);
      fetchInsighterRoles('ransack[archived_eq]=false');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insighterRolesFetched]);

  const history = useHistory();
  const [toastActive, setToastActive] = useState(false);
  const loadingMarkup = isLoading ? <Loading /> : null;

  const toggleToastActive = useCallback(() => setToastActive((toastActive) => !toastActive), []);
  const toastMarkup = toastActive ? <Toast onDismiss={toggleToastActive} content="Changes saved" /> : null;

  const actualPageMarkup = (
    <Page
      fullWidth
      title="Insighter Roles"
      primaryAction={{
        content: 'Create Insighter Role',
        onAction: () => history.push('/admin/insighter-roles/new'),
      }}
    >
      <Card>
        <br />
        <InsighterRoleFilterBar />
        <DataTable
          onRowClicked={(row: Record<string, string>) => history.push(`/admin/insighter-roles/${row.id}`)}
          columns={insighterRoleColumns()}
          progressPending={insighterRolesLoading}
          data={insighterRoles}
          pagination
          striped
          highlightOnHover
          pointerOnHover
          paginationPerPage={25}
          paginationRowsPerPageOptions={[25]}
          noHeader
        />
      </Card>
    </Page>
  );

  // ---- Loading ----
  const loadingPageMarkup = <DataTableSkeleton />;

  const pageMarkup = isLoading ? loadingPageMarkup : actualPageMarkup;

  return (
    <>
      {loadingMarkup}
      {pageMarkup}
      {toastMarkup}
    </>
  );
};

export default InsighterRolesList;
