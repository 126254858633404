import React, { useCallback, useState } from 'react';
import { Button, Card, Loading, Page, Toast } from '@shopify/polaris';
import { useHistory } from 'react-router-dom';
import DataTableSkeleton from '../../loading/components/DataTableSkeleton';
import DataTable from '../../dataTable/DataTable/DataTable';
import InsighterTagFilterBar from '../components/InsighterTagFilterBar';
import { InsighterTagsContext } from '../contexts/InsighterTagsContext';
import { IDataTableColumn } from '../../dataTable/DataTable/model';

const projectColumns = (): IDataTableColumn[] => [
  {
    name: 'Specialty Name',
    selector: 'name',
    sortable: true,
  },
  {
    name: 'Insighter Counter',
    selector: 'insighter_count',
    sortable: true,
  },
  {
    name: 'Badge Color',
    selector: 'badge_color',
    sortable: true,
    cell: ({ badge_color }): React.ReactNode => (
      <div
        style={{
          fontWeight: 700,
          textTransform: 'uppercase',
          fontSize: 14,
          color: badge_color,
        }}
      >
        {badge_color}
      </div>
    ),
  },
  {
    name: 'Status',
    selector: 'status',
    sortable: true,
    cell: ({ status }): React.ReactNode => (
      <div
        style={{
          fontWeight: 700,
          textTransform: 'uppercase',
          fontSize: 14,
          color: status === 'active' ? 'green' : 'red',
        }}
      >
        {status}
      </div>
    ),
  },
  {
    name: 'Actions',
    selector: 'status',
    sortable: true,
    cell: (row): React.ReactNode => (
      <div style={{ marginRight: 10 }}>
        <Button url={`/admin/insighter-tags/${row.id}/edit`}>Edit</Button>
      </div>
    ),
  },
];

interface InsighterTagsListProps {
  isLoading: boolean;
}

const InsighterTagsList: React.FunctionComponent<InsighterTagsListProps> = (props) => {
  const { isLoading } = props;
  const [insighterTagsFetched, setSpecialtiesFetched] = React.useState<boolean>(false);

  const { insighterTags, fetchInsighterTags, insighterTagsLoading } = React.useContext(InsighterTagsContext);

  React.useEffect(() => {
    if (!insighterTagsFetched) {
      setSpecialtiesFetched(true);
      fetchInsighterTags('ransack[archived_eq]=false');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insighterTagsFetched]);

  const history = useHistory();
  const [toastActive, setToastActive] = useState(false);
  const loadingMarkup = isLoading ? <Loading /> : null;

  const toggleToastActive = useCallback(() => setToastActive((toastActive) => !toastActive), []);
  const toastMarkup = toastActive ? <Toast onDismiss={toggleToastActive} content="Changes saved" /> : null;

  const actualPageMarkup = (
    <Page
      fullWidth
      title="Insighter Tags"
      primaryAction={{
        content: 'Create Insighter Tag',
        onAction: () => history.push('/admin/insighter-tags/new'),
      }}
    >
      <Card>
        <br />
        <InsighterTagFilterBar />
        <DataTable
          onRowClicked={(row: Record<string, string>) => history.push(`/admin/insighter-tags/${row.id}`)}
          columns={projectColumns()}
          progressPending={insighterTagsLoading}
          data={insighterTags}
          pagination
          striped
          highlightOnHover
          pointerOnHover
          paginationPerPage={25}
          paginationRowsPerPageOptions={[25]}
          noHeader
        />
      </Card>
    </Page>
  );

  // ---- Loading ----
  const loadingPageMarkup = <DataTableSkeleton />;

  const pageMarkup = isLoading ? loadingPageMarkup : actualPageMarkup;

  return (
    <>
      {loadingMarkup}
      {pageMarkup}
      {toastMarkup}
    </>
  );
};

export default InsighterTagsList;
