import React, { useState } from 'react';
import { Loading, Toast } from '@shopify/polaris';
import { format } from 'date-fns';
import DataTable from '../../dataTable/DataTable/DataTable';
import { PaymentsContext } from '../contexts/PaymentsContext';
import paymentColumns from '../constants/paymentColumns';
import { Payment } from '../model';
import { useHistory } from 'react-router-dom';

const PaymentsList: React.FunctionComponent = () => {
  const {
    fetchPayments,
    payments,
    paymentsLoading,
    filterValues,
    setFilterValues,
    statusFilters,
    selectedDates,
    totalRecords,
    knownAmountsFilterToUse,
  } = React.useContext(PaymentsContext);

  const history = useHistory();

  const handleColumnSort = async (column: Record<string, string>, sortDirection: string) => {
    const columnNamesToRansack = {
      description: 'description',
      payout_category: 'payout_category',
      respondent: 'user_last_name',
      user_type: 'user_insighter_user_type',
      project_number: 'project_project_number',
      created_at: 'created_at',
      processed_at: 'processed_at',
      number_amount: 'number_amount',
      notes: 'notes',
      status: 'status',
      tremendous_order_id: 'tremendous_order_id',
    };

    const queryFilter = `ransack[query]=${filterValues.query}`;

    let formattedStatusFilter = '';

    statusFilters?.forEach((filter, index) => {
      if (index === 0) {
        formattedStatusFilter += `ransack[status_in][]=${filter}`;
      } else {
        formattedStatusFilter += `&ransack[status_in][]=${filter}`;
      }
    });

    let formattedStartDate = `${selectedDates.start}`;
    let formattedEndDate = `${selectedDates.end}`;

    try {
      formattedStartDate = `${format(selectedDates?.start, 'dd/MM/yyyy')}`;
      formattedEndDate = `${format(selectedDates?.end, 'dd/MM/yyyy')}`;
    } catch {
      formattedStartDate = `${selectedDates?.start}`;
      formattedEndDate = `${selectedDates?.end}`;
    }

    setFilterValues({ ...filterValues, sort: `${columnNamesToRansack[column.selector]} ${sortDirection}` });

    const sort = `sort=${columnNamesToRansack[column.selector]} ${sortDirection}`;
    fetchPayments(
      `${queryFilter}&${knownAmountsFilterToUse}&${formattedStatusFilter}&ransack[processed_at_gteq]=${formattedStartDate}&ransack[processed_at_lteq]=${formattedEndDate}&${sort}`,
    );
  };

  const handlePageChange = (page: number) => {
    let formattedStatusFilter = '';

    statusFilters?.forEach((filter, index) => {
      if (index === 0) {
        formattedStatusFilter += `ransack[status_in][]=${filter}`;
      } else {
        formattedStatusFilter += `&ransack[status_in][]=${filter}`;
      }
    });

    let formattedStartDate = `${selectedDates.start}`;
    let formattedEndDate = `${selectedDates.end}`;

    try {
      formattedStartDate = `${format(selectedDates?.start, 'dd/MM/yyyy')}`;
      formattedEndDate = `${format(selectedDates?.end, 'dd/MM/yyyy')}`;
    } catch {
      formattedStartDate = `${selectedDates?.start}`;
      formattedEndDate = `${selectedDates?.end}`;
    }

    fetchPayments(
      `page=${page}&ransack[query]=${filterValues.query}&${knownAmountsFilterToUse}&${formattedStatusFilter}&ransack[processed_at_gteq]=${formattedStartDate}&ransack[processed_at_lteq]=${formattedEndDate}&sort=${filterValues.sort}`,
    );
  };

  return (
    <DataTable
      onRowClicked={(payment: Payment) => history.push(`/admin/payments/${payment?.id}`)}
      columns={paymentColumns}
      data={payments}
      progressPending={paymentsLoading}
      striped
      highlightOnHover
      pointerOnHover
      pagination
      paginationPerPage={25}
      paginationRowsPerPageOptions={[25]}
      paginationServer
      paginationTotalRows={totalRecords}
      onChangePage={handlePageChange}
      noHeader
      sortServer
      onSort={handleColumnSort}
    />
  );
};

export default PaymentsList;
