import React from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import useStyles from '../constants/redesignStyles';
import HorizontalSmear from '../../../assets/redesignAssets/staticImages/HorizontalSmear.png';
import TallOrnamentSmear from '../../../assets/redesignAssets/staticImages/TallOrnamentSmear.png';

interface SectionOrnamentProps {
  text: string;
  centerComponent?: boolean;
  centerText?: boolean;
  maxWidth?: string;
}

let styles = {
  ornamentText: {
    color: 'white',
    textTransform: 'uppercase' as const,
    textAlign: 'center' as const,
  },
};

const SectionOrnament: React.FC<SectionOrnamentProps> = (props) => {
  const { text, centerComponent = true, centerText = false, maxWidth } = props;
  const classes = useStyles();

  const [imageToUse, setImageToUse] = React.useState(window.innerWidth <= 960 ? TallOrnamentSmear : HorizontalSmear);

  // Use useEffect to handle window resize and dynamically update the image
  React.useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 960) {
        setImageToUse(TallOrnamentSmear);
      } else {
        setImageToUse(HorizontalSmear);
      }
    };

    // Add event listener to listen for window resize
    window.addEventListener('resize', handleResize);

    // Call handleResize once on mount to make sure the image is correct
    handleResize();

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Grid container style={{ position: 'relative' }}>
      <Grid item xs={12} style={{ position: 'relative' }}>
        <img
          src={imageToUse}
          alt=""
          style={{
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            zIndex: 0,
            objectFit: 'contain',
          }}
        />
        <Grid
          container
          item
          xs={12}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1,
          }}
        >
          <Typography className={classes.sectionBlurbText} style={styles.ornamentText}>
            {text}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SectionOrnament;
