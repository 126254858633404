import React from 'react';
import { Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import OpportunityViewer from '../components/OpportunityViewer';
import { Opportunity } from '../../../opportunities/model';

interface OpportunitiesGalleryProps {
  opportunities: Opportunity[];
}

const OpportunitiesGallery: React.FC<OpportunitiesGalleryProps> = (props) => {
  const { opportunities } = props;
  const history = useHistory();

  return (
    <Grid container spacing={4} style={{ maxWidth: 1600, margin: 'auto' }}>
      {opportunities?.map((opportunity) => (
        <Grid
          item
          xs={12}
          md={6}
          lg={4}
          key={opportunity?.id}
          style={{ marginBottom: 30, cursor: 'pointer', display: 'flex', justifyContent: 'center' }}
          onClick={() => history.push(`/opportunities/${opportunity.id}`)}
        >
          <OpportunityViewer opportunity={opportunity} />
        </Grid>
      ))}
    </Grid>
  );
};

export default OpportunitiesGallery;
