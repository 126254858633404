import React from 'react';
import { Grid } from '@material-ui/core';
import Page from '../../shared/components/Page';

const ConfirmEmail: React.FC = () => {
  return (
    <Page fullWidth title="">
      <div style={{ marginTop: 100, marginLeft: 20, marginRight: 20 }}>
        <Grid container justifyContent="center">
          <Grid item xs={12} sm={10} md={6} lg={4}>
            <h1
              className="Polaris-Header-Title"
              style={{ fontFamily: 'Cooper', fontSize: '36px', marginBottom: 10, lineHeight: '40px' }}
            >
              You&apos;re Almost There!
            </h1>
            <br />
            <p style={{ fontFamily: 'Cooper', fontSize: '20px', lineHeight: '28px' }}>
              Please check your email inbox for a confirmation email.
            </p>
            <br />
            <p style={{ fontFamily: 'Cooper', fontSize: '20px', lineHeight: '28px' }}>
              Once you click the verification button in the email, you will be redirected to your account where you will
              be prompted to enter a password.
            </p>
            <br />
            <p style={{ fontFamily: 'Cooper', fontSize: '20px', lineHeight: '28px' }}>
              We&apos;re so excited to welcome you to The Insighters® new & improved platform!
            </p>
          </Grid>
        </Grid>
      </div>
    </Page>
  );
};

export default ConfirmEmail;
