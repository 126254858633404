import React from 'react';
import { Layout } from '@shopify/polaris';
import Page from '../../shared/components/Page';
import InvitationOpportunityForm from '../components/InvitationOpportunityForm';
import BackButton from '../../navigation/components/BackButton';

const NewInvitationOpportunity: React.FunctionComponent = () => {
  const invitationOpportunity = {
    id: '',
    name: '',
    invitationQuota: '',
    invitationsCompleted: '',
    defaultInvitationAmount: '',
    defaultinviteeAmount: '',
  };

  return (
    <>
      <BackButton pageAction={false} />
      <Page fullWidth title="New Invitation Opportunity">
        <Layout>
          <Layout.Section>
            <InvitationOpportunityForm invitationOpportunity={invitationOpportunity} />
          </Layout.Section>
          <Layout.Section secondary />
        </Layout>
      </Page>
    </>
  );
};

export default NewInvitationOpportunity;
