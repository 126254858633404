import React, { useState } from 'react';
import axios from '../../utils/axios.utils';
import { Respondent } from '../../respondents/model';

interface PhaseRespondentsContextInterface {
  respondentLoading?: boolean;
  fetchRespondents?: (params: string) => Promise<void>;
  respondents?: Respondent[];
  setRespondents?: React.Dispatch<React.SetStateAction<Respondent[]>>;
  totalRecords?: number;

  sortParams?: string;
  setSortParams?: React.Dispatch<React.SetStateAction<string>>;

  qualtricsStatusFilter?: string;
  setQualtricsStatusFilter?: React.Dispatch<React.SetStateAction<string>>;

  interviewStatusFilter?: string;
  setInterviewStatusFilter?: React.Dispatch<React.SetStateAction<string>>;

  editAllOpen?: boolean;
  setEditAllOpen?: React.Dispatch<React.SetStateAction<boolean>>;

  fetchParams?: string;
  setFetchParams?: React.Dispatch<React.SetStateAction<string>>;

  selectedRespondentIds?: any;
  setSelectedRespondentIds?: any;
}

const PhaseRespondentsContext = React.createContext<PhaseRespondentsContextInterface>({});

const PhaseRespondentsContextConsumer = PhaseRespondentsContext.Consumer;

const PhaseRespondentsContextProvider: React.FC = ({ children }) => {
  const [respondents, setRespondents] = React.useState<Respondent[]>([]);
  const [respondentLoading, setRespondentLoading] = React.useState<boolean>(false);
  const [totalRecords, setTotalRecords] = React.useState<number>();
  const [sortParams, setSortParams] = React.useState<string>('sort=');
  const [qualtricsStatusFilter, setQualtricsStatusFilter] = React.useState('');
  const [interviewStatusFilter, setInterviewStatusFilter] = React.useState('');
  const [fetchParams, setFetchParams] = React.useState<string>('ransack[query]=');
  const [editAllOpen, setEditAllOpen] = useState(false);
  const [selectedRespondentIds, setSelectedRespondentIds] = useState([]);

  const fetchRespondents = async (params?: string) => {
    setRespondentLoading(true);

    const response = await axios.get<string, any>(`respondents?${params}`);

    setRespondentLoading(false);
    setTotalRecords(response?.data?.total_records);
    setQualtricsStatusFilter(response?.data?.applied_filters?.qualtrics_status?.value);
    setInterviewStatusFilter(response?.data?.applied_filters?.interview_status?.value);
    setRespondents(response?.data?.result);
  };

  return (
    <PhaseRespondentsContext.Provider
      value={{
        respondentLoading,
        fetchRespondents,
        respondents,
        totalRecords,
        sortParams,
        setSortParams,
        qualtricsStatusFilter,
        interviewStatusFilter,
        setQualtricsStatusFilter,
        setInterviewStatusFilter,
        fetchParams,
        setFetchParams,
        setRespondents,
        editAllOpen,
        setEditAllOpen,
        selectedRespondentIds,
        setSelectedRespondentIds,
      }}
    >
      {children}
    </PhaseRespondentsContext.Provider>
  );
};

export { PhaseRespondentsContextProvider, PhaseRespondentsContextConsumer, PhaseRespondentsContext };
