import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Loading, Button } from '@shopify/polaris';
import { Grid } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import SelectInput from '../../form/components/SelectInput';
import FormInput from '../../form/components/FormInput';
import AdminPageHeader from '../../adminTools/components/AdminPageHeader';

import axios from '../../utils/axios.utils';

import { PaymentsContext } from '../contexts/PaymentsContext';
import FormSkeleton from '../../loading/components/FormSkeleton';

const EditPaymentPage: React.FunctionComponent = () => {
  const { paymentId } = useParams<Record<string, string>>();

  const [paymentFetched, setPaymentFetched] = useState(false);
  const [formValues, setFormValues] = useState<any>({});
  const [phaseOptions, setPhaseOptions] = useState<any[]>([
    { value: '', label: 'Please select a project before selecting a phase.' },
  ]);

  const {
    payment,
    fetchPayment,
    paymentsLoading,
    formOptions,
    fetchFormOptions,
    updateExpense,
    formError,
    paymentSubmitting,
  } = useContext(PaymentsContext);

  useEffect(() => {
    if (!paymentFetched) {
      fetchPayment(paymentId);
      fetchFormOptions();
      setPaymentFetched(true);
    }
  }, [paymentFetched, fetchPayment, paymentId, fetchFormOptions]);

  const handleProjectChange = async (value: string) => {
    await axios.get<string, any>(`payments/new?project_id=${value}`).then((response) => {
      setPhaseOptions(response?.data?.phase_options);
      setFormValues({ ...formValues, project_id: value });
    });
  };

  useEffect(() => {
    setFormValues(payment);
    if (payment?.project_id) {
      handleProjectChange(payment?.project_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payment]);

  const getProfileLink = () => {
    return {
      label: 'View Insighter Profile',
      link: `/admin/insighters/${payment?.insighter_id}`,
    };
  };

  const loadingMarkup = paymentsLoading ? <Loading /> : null;

  const actualPageMarkup = (
    <Grid container style={{ padding: '1% 2%' }}>
      <AdminPageHeader
        title={payment?.description}
        backButton={{ label: 'Project', link: `/admin/projects/${payment?.project_id}` }}
        badge={{ label: payment?.status }}
        primaryButton={payment?.insighter_id ? getProfileLink() : undefined}
      />
      <Grid item xs={12}>
        <Card style={{ padding: '2%' }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <SelectInput
                label="Category"
                choices={[
                  { label: 'Ad', value: 'ad' },
                  { label: 'Other Expense', value: 'expense' },
                ]}
                onChange={(value) => {
                  setFormValues({ ...formValues, category: value });
                }}
                value={formValues?.category}
              />
            </Grid>

            <Grid item xs={12}>
              <FormInput
                label="Description"
                value={formValues?.description}
                onChange={(value) => setFormValues({ ...formValues, description: value })}
              />
            </Grid>
            <Grid item xs={12}>
              <FormInput
                label="Amount"
                value={formValues?.number_amount}
                type="money"
                onChange={(value) => {
                  const formattedValue = value.charAt(0) === '$' ? value.slice(1) : value;
                  setFormValues({ ...formValues, number_amount: formattedValue });
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <SelectInput
                label="Project (Optional)"
                choices={formOptions?.project_options}
                onChange={handleProjectChange}
                value={formValues?.project_id}
              />
            </Grid>
            <Grid item xs={6}>
              <SelectInput
                label="Project Phase (Optional)"
                choices={phaseOptions}
                onChange={(value) => setFormValues({ ...formValues, phase_id: value })}
                value={formValues?.phase_id}
              />
            </Grid>
            <Grid item xs={6}>
              <SelectInput
                label="Status"
                choices={[
                  { label: 'Hold', value: 'hold' },
                  { label: 'Incomplete', value: 'incomplete' },
                  { label: 'Complete', value: 'complete' },
                ]}
                onChange={(value) => setFormValues({ ...formValues, status: value })}
                value={formValues?.status}
              />
            </Grid>
            <Grid item xs={12}>
              <FormInput
                label="Notes"
                multiline
                rows={4}
                onChange={(value) => setFormValues({ ...formValues, notes: value })}
                value={formValues?.notes}
              />
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid item xs={12} style={{ marginTop: 15 }}>
        {formError && <p style={{ color: 'red', fontSize: '14px', marginTop: 20 }}>{formError}</p>}
        <Button primary onClick={() => updateExpense(payment.id, formValues)} loading={paymentSubmitting}>
          Update Expense
        </Button>
      </Grid>
    </Grid>
  );

  const loadingPageMarkup = <FormSkeleton />;

  const pageMarkup = paymentsLoading ? loadingPageMarkup : actualPageMarkup;

  return (
    <>
      {loadingMarkup}
      {pageMarkup}
    </>
  );
};

export default EditPaymentPage;
