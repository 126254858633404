import React from 'react';
import {
  BlogMajor,
  ChecklistAlternateMajor,
  CustomerPlusMajor,
  DomainNewMajor,
  CashDollarMajor,
  CustomersMajor,
  DataVisualizationMajor,
  OrdersMajor,
  CalendarTickMajor,
  ReferralMajor,
  BillingStatementDollarMajor,
} from '@shopify/polaris-icons';

import { Navigation } from '@shopify/polaris';
import { UserContext } from '../../auth/contexts/UserContext';
import { AdminNavigationContext } from '../contexts/AdminNavigationContext';
import Logo from '../../../assets/logo.png';

const AdminSideNavigation: React.FC = () => {
  const [navItemActive] = React.useState<string>('all');

  const { currentUserHasRole } = React.useContext(UserContext);
  const { fetchAdminNavigation, adminNavigationLoading, actionablePayments } = React.useContext(AdminNavigationContext);
  const [adminNavigationFetched, setAdminNavigationFetched] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (!adminNavigationFetched) {
      fetchAdminNavigation();
      setAdminNavigationFetched(true);
    }
  }, [adminNavigationFetched, setAdminNavigationFetched, fetchAdminNavigation]);

  const secondaryRoutes = [
    {
      label: 'Invitation Opportunities',
      icon: ReferralMajor,
      matches: navItemActive === '',
      url: '/admin/invitation-opportunities',
      badge: null,
    },
  ];

  const adminSecondaryRoutes = [
    {
      label: 'Invitation Opportunities',
      icon: ReferralMajor,
      matches: navItemActive === '',
      url: '/admin/invitation-opportunities',
      badge: null,
    },
    {
      label: 'Payments',
      icon: CashDollarMajor,
      matches: navItemActive === '',
      url: '/admin/payments',
      badge: `${actionablePayments}`,
      onClick: () => {
        sessionStorage.removeItem('paymentFilters');
        sessionStorage.removeItem('paymentFilterQuery');
      },
    },
    {
      label: 'Expenses',
      icon: BillingStatementDollarMajor,
      matches: navItemActive === '',
      url: '/admin/expenses',
      badge: null,
    },
    {
      label: 'Internal Users',
      icon: CustomerPlusMajor,
      matches: navItemActive === '',
      url: '/admin/users',
      badge: null,
    },
  ];

  const financialSecondaryRoutes = [
    {
      label: 'Project Budgets',
      url: '/admin/client-projects-financial',
      icon: BlogMajor,
      matches: navItemActive === '',
      onClick: () => {
        sessionStorage.removeItem('clientProjectFilters');
        sessionStorage.removeItem('clientProjectFilterQuery');
      },
    },
  ];

  return (
    <Navigation location="/">
      <a href="/admin/client-projects" className="Polaris-TopBar__NavigationIcon">
        <img alt="logo" src={Logo} style={{ height: '40px', margin: '10px 20px' }} />
      </a>
      <Navigation.Section
        items={[
          {
            label: 'Dashboard',
            url: '/admin/dashboard',
            icon: DataVisualizationMajor,
            matches: navItemActive === 'home',
          },
          {
            label: 'Client Projects',
            url: '/admin/client-projects',
            icon: BlogMajor,
            matches: navItemActive === '',
            onClick: () => {
              sessionStorage.removeItem('clientProjectFilters');
              sessionStorage.removeItem('clientProjectFilterQuery');
            },
          },
          {
            label: 'Internal Projects',
            url: '/admin/internal-projects',
            icon: DomainNewMajor,
            matches: navItemActive === '',
            onClick: () => {
              sessionStorage.removeItem('internalProjectFilters');
              sessionStorage.removeItem('internalProjectFilterQuery');
            },
          },
          {
            label: 'Project Phases',
            url: '/admin/phases',
            icon: ChecklistAlternateMajor,
            matches: navItemActive === '',
            onClick: () => {
              sessionStorage.removeItem('phaseFilters');
              sessionStorage.removeItem('phaseFilterQuery');
              sessionStorage.removeItem('respondentFilters');
              sessionStorage.removeItem('respondentFilterQuery');
            },
          },
          {
            label: 'Opportunities',
            url: '/admin/opportunities',
            icon: OrdersMajor,
            matches: navItemActive === '',
            onClick: () => {
              sessionStorage.removeItem('opportunityFilters');
              sessionStorage.removeItem('opportunityFilterQuery');
            },
          },
          {
            label: 'Interviews',
            url: window.screen.width < 500 ? '/admin/interview-schedule' : '/admin/interviews',
            icon: CalendarTickMajor,
            matches: navItemActive === '',
            onClick: () => {
              sessionStorage.removeItem('interviewFilters');
              sessionStorage.removeItem('interviewFilterQuery');
            },
            badge: null,
          },
          {
            label: 'Insighter Finder',
            url: '/admin/insighter_finder',
            icon: CustomersMajor,
            matches: navItemActive === '',
            onClick: () => {
              sessionStorage.removeItem('filterBuilderValue');
            },
          },
          {
            label: 'Insighters',
            url: '/admin/insighters',
            icon: CustomersMajor,
            matches: navItemActive === '',
            onClick: () => {
              sessionStorage.removeItem('insighterFilters');
              sessionStorage.removeItem('insighterFilterQuery');
            },
          },
        ]}
      />
      {!adminNavigationLoading && (
        <Navigation.Section
          items={currentUserHasRole('superadmin') ? adminSecondaryRoutes : secondaryRoutes}
          separator
        />
      )}
      {currentUserHasRole('financial') && <Navigation.Section items={financialSecondaryRoutes} separator />}
    </Navigation>
  );
};

export default AdminSideNavigation;
