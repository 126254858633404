import { Insighter } from '../../insighters/model';

const insighterPossibleColumns = [
  {
    name: 'Name',
    selector: 'name',
    sortable: true,
    grow: 0.6,
  },
  {
    name: 'User ID',
    selector: 'uuid',
    sortable: true,
    grow: 0.6,
  },
  {
    name: 'User Type',
    selector: 'user_type',
    sortable: true,
  },
  {
    name: 'State',
    selector: 'state',
    sortable: true,
  },
  {
    name: 'City',
    selector: 'city',
    sortable: true,
  },
  {
    name: 'Gender',
    selector: 'gender',
    sortable: true,
    grow: 0.8,
  },
  {
    name: 'NPI Number',
    selector: 'npi_number',
    sortable: true,
  },
  {
    name: 'Ethnicity',
    selector: 'ethnicity_names',
    grow: 2,
  },
  {
    name: 'Email',
    selector: 'email',
    sortable: true,
    grow: 1.5,
  },
  {
    name: 'Conditions',
    selector: 'diagnosis',
    grow: 2,
  },
  {
    name: 'Specialty',
    selector: 'specialty_names',
    grow: 2,
  },
  {
    name: 'Insighter Roles',
    selector: 'insighter_role_names',
    grow: 2,
  },
  {
    name: 'Insighter Tags',
    selector: 'insighter_tag_names',
    grow: 2,
  },
  {
    name: 'Sign Up Date',
    selector: 'sign_up_time',
    sortFunction: (rowA: Insighter, rowB: Insighter): number =>
      new Date(rowA.sign_up_time).getTime() - new Date(rowB.sign_up_time).getTime(),
  },
  {
    name: 'Last Active',
    selector: 'last_active_date',
    sortFunction: (rowA: Insighter, rowB: Insighter): number =>
      new Date(rowA.last_active_date).getTime() - new Date(rowB.last_active_date).getTime(),
  },
  {
    name: 'Past Projects',
    selector: 'past_projects',
  },
];

export default insighterPossibleColumns;
