import React from 'react';
import { IDataTableColumn } from '../../dataTable/DataTable/model';
import { Opportunity } from '../../opportunities/model';
import { Project } from '../../projects/model';

const getStatusColor = (status: string) => {
  if (status === 'published' || status === 'active') {
    return 'green';
  }
  if (status === 'draft') {
    return 'orange';
  }
  return 'red';
};

export const projectColumns: IDataTableColumn<any>[] = [
  {
    name: 'Project',
    selector: 'name',
    sortable: true,
  },
  {
    name: 'Project Number',
    selector: 'project_number',
    sortable: true,
  },
  {
    name: 'Current Status',
    selector: 'status',
    sortable: true,
    cell: ({ status }): React.ReactNode => (
      <div
        style={{
          fontWeight: 700,
          textTransform: 'uppercase',
          fontSize: 14,
          color: getStatusColor(status),
        }}
      >
        {status}
      </div>
    ),
  },
  {
    name: 'Start Date',
    selector: 'start_date',
    sortable: true,
    sortFunction: (rowA: Project, rowB: Project) =>
      new Date(rowA.start_date).getTime() - new Date(rowB.start_date).getTime(),
    cell: (project): React.ReactNode => (
      <div>
        <p>{project.start_date}</p>
      </div>
    ),
  },
  {
    name: 'End Date',
    selector: 'end_date',
    sortable: true,
    sortFunction: (rowA: Project, rowB: Project) =>
      new Date(rowA.end_date).getTime() - new Date(rowB.end_date).getTime(),
    cell: (project): React.ReactNode => (
      <div>
        <p>{project.end_date}</p>
      </div>
    ),
  },
];

export const phaseColumns: IDataTableColumn<any>[] = [
  {
    name: 'Phase Name',
    selector: 'name',
    sortable: true,
  },
  {
    name: 'Project Name',
    selector: 'project_name',
    sortable: true,
  },
  {
    name: 'Project Number',
    selector: 'project_number',
    sortable: true,
  },
  {
    name: 'Insighter Category',
    selector: 'insighter_category',
    sortable: true,
  },
  {
    name: 'Interview Category',
    selector: 'interview_category',
    sortable: true,
  },
  {
    name: 'Start Date',
    selector: 'start_date_human',
    sortable: true,
  },
  {
    name: 'End Date',
    selector: 'end_date_human',
    sortable: true,
  },
  {
    name: 'Status',
    selector: 'status',
    sortable: true,
    cell: ({ status }) => (
      <div
        style={{
          fontWeight: 700,
          textTransform: 'uppercase',
          fontSize: 14,
          color: status === 'active' ? 'green' : 'red',
        }}
      >
        {status}
      </div>
    ),
  },
];

export const opportunityColumns: IDataTableColumn<any>[] = [
  {
    name: 'Opportunity Name',
    selector: 'name',
    sortable: true,
  },
  {
    name: 'Project Number',
    selector: 'project_number',
    sortable: true,
  },
  {
    name: 'Current Status',
    selector: 'status',
    sortable: true,
    cell: ({ status }): React.ReactNode => (
      <div
        style={{
          fontWeight: 700,
          textTransform: 'uppercase',
          fontSize: 14,
          color: status === 'active' ? 'green' : 'red',
        }}
      >
        {status}
      </div>
    ),
  },
  {
    name: 'Start Date',
    selector: 'start_date',
    sortable: true,
    sortFunction: (rowA: Opportunity, rowB: Opportunity) =>
      new Date(rowA.start_date).getTime() - new Date(rowB.start_date).getTime(),
    cell: (opportunity): React.ReactNode => (
      <div>
        <p>{opportunity.start_date}</p>
      </div>
    ),
  },
  {
    name: 'End Date',
    selector: 'end_date',
    sortable: true,
    sortFunction: (rowA: Opportunity, rowB: Opportunity) =>
      new Date(rowA.end_date).getTime() - new Date(rowB.end_date).getTime(),
    cell: (opportunity): React.ReactNode => (
      <div>
        <p>{opportunity.end_date}</p>
      </div>
    ),
  },
];

export const invitationOpportunityColumns: IDataTableColumn<any>[] = [
  {
    name: 'Invitation Opportunity Name',
    selector: 'name',
    sortable: true,
  },
  {
    name: 'Invitation Quota',
    selector: 'invitation_quota',
    sortable: true,
  },
  {
    name: 'Invitations Count',
    selector: 'invitations_count',
    sortable: true,
  },
  {
    name: 'Current Status',
    selector: 'status',
    sortable: true,
    cell: ({ status }): React.ReactNode => (
      <div
        style={{
          fontWeight: 700,
          textTransform: 'uppercase',
          fontSize: 14,
          color: status === 'active' ? 'green' : 'red',
        }}
      >
        {status}
      </div>
    ),
  },
  {
    name: 'Start Date',
    selector: 'start_date',
    sortable: true,
    sortFunction: (rowA: Opportunity, rowB: Opportunity) =>
      new Date(rowA.start_date).getTime() - new Date(rowB.start_date).getTime(),
    cell: (opportunity): React.ReactNode => (
      <div>
        <p>{opportunity.start_date}</p>
      </div>
    ),
  },
  {
    name: 'End Date',
    selector: 'end_date',
    sortable: true,
    sortFunction: (rowA: Opportunity, rowB: Opportunity) =>
      new Date(rowA.end_date).getTime() - new Date(rowB.end_date).getTime(),
    cell: (opportunity): React.ReactNode => (
      <div>
        <p>{opportunity.end_date}</p>
      </div>
    ),
  },
];
