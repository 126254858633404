import React from 'react';
import styled from 'styled-components';
import { Button, Icon, Modal, Typography, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ModalHeader from './ModalHeader';
import ModalFooter from './ModalFooter';
import ModalBody from './ModalBody';
import { BaseModalProps, DEFAULT_MODAL_HEIGHT, DEFAULT_MODAL_WIDTH } from '../model';

export const DEFAULT_CONFIRM_LABEL = 'Confirm';
export const DEFAULT_CANCEL_LABEL = 'Cancel';

export interface ConfirmCancelModalProps extends BaseModalProps {
  confirmLabel?: string;
  warningLabel?: string;
  cancelLabel?: string;
  hasConfirmButton?: boolean;
  hasCancelButton?: boolean;
  confirmDisabled?: boolean;
  cancelDisabled?: boolean;
  hasTitleBar?: boolean;
  hasFooterBar?: boolean;
  hasButtons?: boolean;
  closeOnConfirm?: boolean;
  closeOnCancel?: boolean;
  closeOnWarning?: boolean;
  hasWarningButton?: boolean;
  onConfirm?: () => void;
  onWarning?: () => void;
  onCancel?: () => void;
}

interface ModalContentProps {
  width: string;
  height: string;
  hasTitleBar: boolean;
  hasFooterBar: boolean;
}

const useStyles = makeStyles({
  modalContainer: {
    overflow: 'scroll',
  },
});

const ModalContent = styled.div<ModalContentProps>`
  position: absolute;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  border-radius: 2px;
  background-color: #fff;
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.5);
  margin-bottom: 150px;

  ${(props) => `
    left: calc( (100vw - ${props.width}) / 2 );
    top: 118px;
  `};

  @media (max-width: 960px) {
    width: 100%;
    left: 0;
  }

  display: flex;
  flex-direction: column;

  .modal-body {
    overflow: auto;
  }

  ${(props) =>
    !props.hasTitleBar &&
    `.modal-header {
      border-bottom: none;
    }`}

  ${(props) =>
    !props.hasFooterBar &&
    `.modal-body {
      text-align: center;
      padding-top: 0;
    }

    .buttons {
      text-align: center;

      button {
        font-size: 12px;
        width: 160px;
        margin: 7px;
      }
    }
    
    .warning-button {
      text-align: center;

      button {
        font-size: 12px;
        width: 160px;
        margin: 7px;
        background-color: #cc0000;
        color: white
      }
    }`}
`;

const ConfirmCancelModal: React.FC<ConfirmCancelModalProps> = (props: ConfirmCancelModalProps) => {
  const {
    isOpen,
    title,
    hasTitleBar = true,
    hasFooterBar = true,
    hasButtons = true,
    confirmLabel = DEFAULT_CONFIRM_LABEL,
    cancelLabel = DEFAULT_CANCEL_LABEL,
    warningLabel = 'Delete',
    hasConfirmButton = false,
    hasCancelButton = false,
    hasWarningButton = false,
    confirmDisabled = false,
    cancelDisabled = false,
    width = DEFAULT_MODAL_WIDTH,
    height = DEFAULT_MODAL_HEIGHT,
    closeOnConfirm = false,
    closeOnCancel = true,
    closeOnWarning = true,
    openStatusChanged = () => null,
    onConfirm = () => null,
    onWarning = () => null,
    onCancel = () => null,
    children,
  } = props;

  const classes = useStyles();

  const handleClose = () => {
    openStatusChanged(false);
  };

  const headerCloseButton = (
    <Icon fontSize="large" onClick={onCancel} style={{ cursor: 'pointer' }}>
      <CloseIcon />
    </Icon>
  );

  const confirmButton = hasConfirmButton ? (
    <Button
      variant="contained"
      disabled={confirmDisabled}
      onClick={() => {
        onConfirm();
        if (closeOnConfirm) handleClose();
      }}
      style={{ backgroundColor: '#72a295', color: 'White' }}
    >
      {confirmLabel}
    </Button>
  ) : null;

  const warningButton = hasWarningButton ? (
    <Button
      variant="contained"
      disabled={confirmDisabled}
      onClick={() => {
        onWarning();
        if (closeOnWarning) handleClose();
      }}
    >
      {warningLabel}
    </Button>
  ) : null;

  const cancelButton = hasCancelButton ? (
    <Button
      variant="contained"
      disabled={cancelDisabled}
      onClick={() => {
        onCancel();
        if (closeOnCancel) handleClose();
      }}
    >
      {cancelLabel}
    </Button>
  ) : null;

  return (
    <Modal open={isOpen} onClose={handleClose} title={title} className={classes.modalContainer}>
      <ModalContent width={width} height={height} hasTitleBar={hasTitleBar} hasFooterBar={hasFooterBar}>
        <ModalHeader className="modal-header">
          {hasTitleBar && (
            <Typography className="title" variant="h2">
              {title}
            </Typography>
          )}
          {headerCloseButton}
        </ModalHeader>

        <ModalBody className="modal-body">
          {children}
          {!hasFooterBar && (
            <>
              <div className="buttons" style={{ display: 'flex', justifyContent: 'center' }}>
                {cancelButton}
                <div className="warning-button">{warningButton}</div>
                {confirmButton}
              </div>
            </>
          )}
        </ModalBody>

        {hasFooterBar && !hasButtons && (
          <ModalFooter>
            <div className="buttons">
              {cancelButton}
              {confirmButton}
            </div>
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
};

export default ConfirmCancelModal;
