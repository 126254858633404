import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { SurveyMappingContext } from '../contexts/SurveyMappingContext';
import WarningIcon from '../../insighterPayments/components/WarningIcon';
import DataTable from '../../dataTable/DataTable/DataTable';
import { IDataTableColumn } from '../../dataTable/DataTable/model';
import AdminPageHeader from '../../adminTools/components/AdminPageHeader';

import axios from '../../utils/axios.utils';

const SurveyMappingDetail: React.FunctionComponent = () => {
  let { phaseId } = useParams<Record<string, string>>();

  let [surveyMappingFetched, setSurveyMappingFetched] = useState(false);
  let [surveyResponseSubmitting, setSurveyResponseSubmitting] = useState(false);

  const {
    fetchSurveyMapping,
    surveyMapping,
    exampleSurveyResponses,
    addToHiddenAttributes,
    removeFromHiddenAttributes,
  } = React.useContext(SurveyMappingContext);

  React.useEffect(() => {
    if (!surveyMappingFetched) {
      fetchSurveyMapping(`${phaseId}`);
      setSurveyMappingFetched(true);
    }
  }, [phaseId, fetchSurveyMapping, surveyMappingFetched, setSurveyMappingFetched]);

  const excludeFields = (key: string) => {
    if (['id', 'hidden_attributes'].includes(key)) {
      return false;
    }
    return true;
  };

  const isHiddenField = (key: string) => {
    if (surveyMapping.hidden_attributes.includes(key)) {
      return true;
    }
    return false;
  };

  const handleAddToHiddenAttributes = (attribute: string) => {
    addToHiddenAttributes(phaseId, attribute);
  };

  const handleRemoveFromHiddenAttributes = (attribute: string) => {
    removeFromHiddenAttributes(phaseId, attribute);
  };

  const examplesForKey = (key: string) => {
    return exampleSurveyResponses.map(
      (response): React.ReactNode => (
        <li key={key + response.id}>{response[key] || <span style={{ opacity: 0.5 }}>No Value</span>}</li>
      ),
    );
  };

  const actionButtonForKey = (key: string) => {
    if (isHiddenField(key)) {
      return (
        <AddIcon
          onClick={() => handleRemoveFromHiddenAttributes(key)}
          style={{ fontSize: 'large', cursor: 'pointer', float: 'right' }}
        />
      );
    }
    return (
      <DeleteIcon
        onClick={() => handleAddToHiddenAttributes(key)}
        style={{ fontSize: 'large', cursor: 'pointer', float: 'right' }}
      />
    );
  };

  const formatQID = (key: string, value: string) => {
    if (isHiddenField(key)) {
      return <p>Ignored</p>;
    }
    return value ? <p>{value}</p> : <WarningIcon />;
  };

  const createManualImport = async (phase_id: string) => {
    setSurveyResponseSubmitting(true);
    await axios.post<string, any>(`survey_responses`, { phase_id });
    setSurveyResponseSubmitting(false);
  };

  const surveyMappingColumns: IDataTableColumn[] = [
    {
      name: 'Field Name',
      selector: 'key',
      sortable: true,
      cell: ({ key }): React.ReactNode => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            opacity: isHiddenField(key) ? 0.5 : 1,
            width: '100%',
          }}
        >
          {key.replaceAll('_', ' ')}
        </div>
      ),
    },
    {
      name: 'Qualtrics QID',
      selector: 'key',
      cell: ({ key }): React.ReactNode => (
        <div
          style={{
            fontWeight: 700,
            textTransform: 'uppercase',
            fontSize: 14,
            color: surveyMapping[key] || isHiddenField(key) ? 'rgb(32, 34, 35)' : 'red',
            opacity: isHiddenField(key) ? 0.5 : 1,
          }}
        >
          {formatQID(key, surveyMapping[key])}
        </div>
      ),
    },
    {
      name: 'Example Values',
      selector: 'key',
      cell: ({ key }): React.ReactNode => (
        <div
          style={{
            color: 'rgb(32, 34, 35)',
            fontSize: '1.4rem',
            opacity: isHiddenField(key) ? 0.5 : 1,
          }}
        >
          <ol
            style={{
              paddingLeft: 0,
            }}
          >
            {examplesForKey(key)}
          </ol>
        </div>
      ),
    },
    {
      name: 'Actions',
      selector: 'key',
      style: { justifyContent: 'right' },
      cell: ({ key }): React.ReactNode => (
        <div
          style={{
            opacity: isHiddenField(key) ? 0.5 : 1,
          }}
        >
          {actionButtonForKey(key)}
        </div>
      ),
    },
  ];

  const surveyMappingValidKeys = () => {
    return Object?.keys(surveyMapping)
      ?.filter((key) => excludeFields(key))
      ?.map((key) => {
        let value = surveyMapping[key];
        return { key, value };
      });
  };

  return (
    <Grid container style={{ padding: '1% 2%' }}>
      <AdminPageHeader
        title="Survey Mapping"
        backButton={{ label: `Phase ${phaseId}`, link: `/admin/phases/${phaseId}` }}
        primaryButton={{ label: 'Edit Survey Mapping', link: `/admin/phases/${phaseId}/edit-survey-mapping` }}
      />
      <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 5 }}>
        <Button onClick={() => createManualImport(phaseId)} disabled={surveyResponseSubmitting} className="primary-btn">
          {surveyResponseSubmitting ? <CircularProgress /> : 'Refresh Survey Responses'}
        </Button>
      </Grid>
      <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 10 }}>
        <small>
          <em>
            For phases with many surveys (over 500), the process takes about ten minutes, and the button won&apos;t stop
            spinning to let you know when it&apos;s finished.
          </em>
        </small>
      </Grid>
      <Grid item xs={12}>
        <Card>
          {surveyMapping && (
            <Grid container className="survey-mapping-table-wrapper">
              <DataTable columns={surveyMappingColumns} data={surveyMappingValidKeys()} striped />
            </Grid>
          )}
          <p>
            Note: All of the &apos;Condition&apos; Questions have their responses pulled-in separately from Qualtrics
            Surveys. The saved survey response will include a separate answer for each question. When the survey
            response is used to create an insighter account, the answers from all of the &apos;Conditions&apos;
            questions will be combined into one single field in the Insighter&apos;s profile.
          </p>
        </Card>
      </Grid>
    </Grid>
  );
};

export default SurveyMappingDetail;
