import React, { useState, useContext } from 'react';
import { Button, Card } from '@shopify/polaris';
import { Grid, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import ConfirmCancelModal from '../../modal/components/MaterialModal';
import { Phase } from '../model';
import RespondentForm from '../../respondents/components/RespondentForm';
import PhaseRespondentsList from './PhaseResponentsList';
import PhaseRespondentsFilterBar from './PhaseRespondentsFilterBar';
import { PhaseRespondentsContext } from '../contexts/PhaseRespondentsContext';
import { UserContext } from '../../auth/contexts/UserContext';

// TODO: Convert shopify components to material-ui

interface PhaseRespondentsProps {
  phase: Phase;
}

const PhaseRespondents: React.FC<PhaseRespondentsProps> = (props) => {
  let { phase } = props;
  let history = useHistory();

  let { currentUserHasRole } = useContext(UserContext);
  let { editAllOpen, setEditAllOpen, selectedRespondentIds } = useContext(PhaseRespondentsContext);

  let [modalOpen, setModalOpen] = useState(false);

  let handleBulkPaymentsPressed = () => {
    sessionStorage.setItem('selectedRespondentIds', JSON.stringify(selectedRespondentIds));
    // history.push(`/admin/payments/new?projectId=${phase?.project_id}&phaseId=${phase?.id}`);
    let url = `/admin/payments/new?projectId=${phase?.project_id}&phaseId=${phase?.id}`;
    window.open(url, '_blank');
  };

  return (
    <>
      <ConfirmCancelModal isOpen={modalOpen} onCancel={() => setModalOpen(false)} title="Add Outside Respondent">
        <div style={{ margin: '40px 20px' }}>
          <RespondentForm
            respondent={{ phase_id: phase?.id }}
            afterAction={() => history.push(`/admin/phases/${phase?.id}`)}
          />
        </div>
      </ConfirmCancelModal>
      <Card sectioned>
        <Grid container>
          <Grid item xs={4}>
            <Typography variant="h4">Respondents</Typography>
          </Grid>
          <Grid item xs={8}>
            {currentUserHasRole('admin') && (
              <Grid container spacing={1} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Grid item>
                  <Button
                    primary={selectedRespondentIds.length > 0}
                    disabled={selectedRespondentIds.length === 0}
                    onClick={handleBulkPaymentsPressed}
                  >
                    Bulk Payments {selectedRespondentIds.length.toString()}
                  </Button>
                </Grid>
                <Grid item>
                  {editAllOpen ? (
                    <Button primary onClick={() => setEditAllOpen(false)}>
                      Save
                    </Button>
                  ) : (
                    <Button primary onClick={() => setEditAllOpen(true)}>
                      Edit All
                    </Button>
                  )}
                </Grid>
                <Grid item>
                  <Button primary url={`/admin/phases/${phase?.id}/survey-mapping`}>
                    Qualtrics
                  </Button>
                </Grid>
                <Grid item>
                  <Button primary onClick={() => setModalOpen(!modalOpen)}>
                    Add Respondents
                  </Button>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
        <PhaseRespondentsFilterBar />
        <PhaseRespondentsList phase={phase} />
      </Card>
    </>
  );
};

export default PhaseRespondents;
