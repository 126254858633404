import React from 'react';
import { useHistory } from 'react-router-dom';
import ConfirmCancelModal from '../../../modal/components/MaterialModal';
import axios from '../../../utils/axios.utils';

interface DeleteBlogPostConfirmModalProps {
  blogPostId?: string;
  deleteBlogPostConfirmModalOpen?: boolean;
  setDeleteBlogPostConfirmModalOpen?: any;
}

const DeleteBlogPostConfirmModal: React.FC<DeleteBlogPostConfirmModalProps> = (props) => {
  const history = useHistory();

  const { blogPostId, deleteBlogPostConfirmModalOpen, setDeleteBlogPostConfirmModalOpen } = props;

  const handleDeleteBlogPost = async () => {
    try {
      await axios.delete(`blog_posts/${blogPostId}`);
      history.push('/admin/blog-posts');
    } catch (error: any) {
      console.log(error);
    }
  };

  return (
    <ConfirmCancelModal
      isOpen={deleteBlogPostConfirmModalOpen}
      title="Delete Blog"
      onCancel={() => setDeleteBlogPostConfirmModalOpen(false)}
      onWarning={() => handleDeleteBlogPost()}
      warningLabel="Delete"
      cancelLabel="Cancel"
      hasWarningButton
      hasCancelButton
      hasFooterBar={false}
      height="250px"
    >
      <div style={{ marginTop: '20px', marginBottom: '20px' }}>
        <p>Are you sure you want to delete this blog?</p>
      </div>
    </ConfirmCancelModal>
  );
};

export default DeleteBlogPostConfirmModal;
