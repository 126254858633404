import React from 'react';
import { useHistory } from 'react-router-dom';
import axios from '../../utils/axios.utils';
import { Specialty, SpecialtyFormValues } from '../model';
import { SelectOption } from '../../form/model';

interface SpecialtiesContextInterface {
  fetchSpecialties?: (params?: string) => void;
  fetchSpecialty?: (specialty_id: string) => void;
  specialtiesLoading?: boolean;
  specialty?: Specialty;
  specialties?: Specialty[];

  fetchFormOptions?: () => void;
  formOptions?: Record<string, SelectOption[]>;
  updateSpecialty?: (specialty_id: string, formValues: SpecialtyFormValues) => void;
  createSpecialty?: (formValues: SpecialtyFormValues) => void;
  specialtySubmitting?: boolean;
  formError?: string;
}

const SpecialtiesContext = React.createContext<SpecialtiesContextInterface>({});

const SpecialtiesContextConsumer = SpecialtiesContext.Consumer;
const SpecialtiesContextProvider: React.FC = ({ children }) => {
  const history = useHistory();

  const [specialties, setSpecialties] = React.useState<Specialty[]>([]);
  const [specialty, setSpecialty] = React.useState<Specialty>({});
  const [formOptions, setFormOptions] = React.useState<Record<string, SelectOption[]>>({});
  const [specialtiesLoading, setSpecialtiesLoading] = React.useState<boolean>(false);
  const [specialtySubmitting, setSpecialtySubmitting] = React.useState(false);

  const fetchSpecialties = async (params = '') => {
    setSpecialtiesLoading(true);

    const response = await axios.get<string, any>(`specialties?${params}`);

    setSpecialtiesLoading(false);
    setSpecialties(response?.data?.result);
  };

  const fetchSpecialty = async (specialty_id: string) => {
    setSpecialtiesLoading(true);

    const response = await axios.get<string, any>(`specialties/${specialty_id}`);

    setSpecialtiesLoading(false);
    setSpecialty(response?.data?.result);
  };

  const createSpecialty = async (formValues: SpecialtyFormValues) => {
    setSpecialtySubmitting(true);

    await axios.post<string, any>(`specialties`, {
      specialty: { ...formValues },
    });

    setSpecialtySubmitting(false);
    history.push('/admin/specialties');
  };

  const updateSpecialty = async (specialty_id: string, formValues: SpecialtyFormValues) => {
    setSpecialtySubmitting(true);

    await axios.put<string, any>(`specialties/${specialty_id}`, {
      specialty: { ...formValues },
    });

    setSpecialtySubmitting(false);
    history.push('/admin/specialties');
  };

  const fetchFormOptions = async () => {
    const response = await axios.get<string, any>(`specialties/new`);

    setFormOptions(response?.data);
  };

  return (
    <SpecialtiesContext.Provider
      value={{
        fetchSpecialties,
        fetchSpecialty,
        specialtiesLoading,
        specialty,
        specialties,

        fetchFormOptions,
        formOptions,
        updateSpecialty,
        createSpecialty,
        specialtySubmitting,
      }}
    >
      {children}
    </SpecialtiesContext.Provider>
  );
};

export { SpecialtiesContextProvider, SpecialtiesContextConsumer, SpecialtiesContext };
