import React from 'react';
import { useHistory } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';

import { CSVLink } from 'react-csv';
import InterviewsList from '../components/InterviewsList';
import InterviewFilterBar from '../components/InterviewFilterBar';
import { UserContext } from '../../auth/contexts/UserContext';
import { InterviewsContext } from '../contexts/InterviewsContext';
import todaysDate from '../../utils/dateFunctions';
import AdminPageHeader from '../../adminTools/components/AdminPageHeader';

const InterviewsListContainer: React.FunctionComponent = () => {
  const history = useHistory();
  const { currentUserHasRole } = React.useContext(UserContext);
  const { upcomingPayments, interviewsDownload, interviewLoading } = React.useContext(InterviewsContext);

  const CreateInterviewButton = () => (
    <Button className="primary-btn" onClick={() => history.push('/admin/interviews/new')}>
      Create Interview
    </Button>
  );

  const DownloadInterviewButton = () => (
    <>
      {interviewsDownload && (
        <CSVLink
          data={interviewsDownload}
          filename={`INTERVIEWS-${todaysDate()}.csv`}
          style={{ textDecoration: 'none' }}
          className="secondary-btn"
        >
          <Button className="secondary-btn">Download Interviews</Button>
        </CSVLink>
      )}
    </>
  );

  return (
    <Grid container style={{ padding: '1% 2%' }}>
      <AdminPageHeader
        title="All Interviews"
        userButtons={<DownloadInterviewButton />}
        adminButtons={<CreateInterviewButton />}
      />
      <Card style={{ padding: '1% 2%' }}>
        <InterviewFilterBar />
        <InterviewsList />
        {currentUserHasRole('financial') && !interviewLoading && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              padding: '10px 20px 20px 20px',
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <p>
              <strong>Upcoming Tremendous Payments:</strong> ${upcomingPayments || 0}
            </p>
          </div>
        )}
      </Card>
    </Grid>
  );
};

export default InterviewsListContainer;
