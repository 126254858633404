import React, { FC, useState, useContext, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Layout, Loading, Page, Toast } from '@shopify/polaris';
import { Grid, Typography, Button } from '@mui/material';
import PaymentImage from '../../../assets/payment-icon.png';
import DataTableSkeleton from '../../loading/components/DataTableSkeleton';
import DashboardNavigation from '../components/DashboardNavigation';
import DataTable from '../../dataTable/DataTable/DataTable';
import { IDataTableColumn } from '../../dataTable/DataTable/model';
import { InsighterPaymentsContext } from '../contexts/InsighterPaymentsContext';
import NotSeenPaymentModal from '../components/NotSeenPaymentModal';

const getStatusColor = (status: string) => {
  if (status === 'complete') {
    return 'green';
  }

  return '#4ba9c8';
};

const ProjectsDashboard: FC = () => {
  const { impersonatedUserId } = useParams<Record<string, string>>();
  const history = useHistory();

  const { insighter, fetchInsighter, fetchInsighterPayments, isLoading, payments } =
    useContext(InsighterPaymentsContext);

  const [insighterFetched, setInsighterFetched] = useState<boolean>(false);
  const [checkPaymentModalOpen, setCheckPaymentModalOpen] = useState<boolean>(false);

  const insighterPaymentColumns: IDataTableColumn<any>[] = [
    {
      name: 'Payment ID',
      selector: 'payment_id',
      sortable: true,
    },
    {
      name: 'Amount',
      selector: 'amount',
      sortable: true,
      grow: 0.5,
      cell: ({ amount }) => <p>${amount}</p>,
    },
    {
      name: 'Payment Date',
      selector: 'payment_created',
      sortable: true,
    },
    {
      name: 'Status',
      selector: 'status',
      sortable: true,
      cell: ({ status }) => (
        <div
          style={{
            fontWeight: 700,
            textTransform: 'uppercase',
            fontSize: 14,
            color: getStatusColor(status),
          }}
        >
          {status}
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (!insighterFetched) {
      fetchInsighter(impersonatedUserId);
      fetchInsighterPayments(impersonatedUserId);
      setInsighterFetched(true);
    }
  }, [insighterFetched, fetchInsighter, insighter, fetchInsighterPayments, impersonatedUserId]);

  const loadingMarkup = isLoading ? <Loading /> : null;

  const actualPageMarkup = (
    <Page fullWidth>
      <NotSeenPaymentModal
        checkPaymentModalOpen={checkPaymentModalOpen}
        setCheckPaymentModalOpen={setCheckPaymentModalOpen}
        userEmail={insighter?.email}
      />
      <DashboardNavigation currentTab={0} />
      <Layout>
        <Layout.Section>
          <br />
          <Grid container>
            <Grid item md={1} sx={{ display: { xs: 'none', md: 'block' } }} />
            <Grid item md={6} xs={12}>
              <Typography variant="h4" style={{ marginTop: 30 }}>
                We’re using <a href="https://www.tremendous.com/terms/">Tremendous</a> which makes it easy for you to
                choose your payment type—Paypal, Venmo, direct deposit, gift cards, and more! You will receive an email
                from The Insighters® by Tremendous once your payment is available to you.
              </Typography>
              <Typography variant="h5" style={{ marginTop: 10 }}>
                <em>
                  Important! Certain payment methods have different expiration dates. You must collect your full payment
                  before your chosen payment&apos;s expiration date. Please see our updated{' '}
                  <a href="https://theinsighters.com/terms-of-use">Terms of Service</a> for full details.
                </em>
              </Typography>
            </Grid>
            <Grid item md={4} sx={{ display: { xs: 'none', md: 'block' } }} style={{ margin: 'auto' }}>
              <img alt="scheduling" src={PaymentImage} />
            </Grid>
          </Grid>
          <br />
          <div style={{ margin: 20 }}>
            <h2 className="Polaris-Heading" style={{ margin: '0 0 25px', fontSize: 28 }}>
              Your Payment History:
            </h2>
            <DataTable
              onRowClicked={() => null}
              columns={insighterPaymentColumns}
              data={payments}
              striped
              highlightOnHover
              pointerOnHover
              noHeader
            />
          </div>
          {payments.some((payment) => payment.status === 'complete') && (
            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
              <Button className="primary-btn" onClick={() => setCheckPaymentModalOpen(true)}>
                Haven’t seen your payment?
              </Button>
            </Grid>
          )}
        </Layout.Section>
      </Layout>
      <br />
    </Page>
  );

  // ---- Loading ----
  const loadingPageMarkup = <DataTableSkeleton />;

  const pageMarkup = isLoading ? loadingPageMarkup : actualPageMarkup;

  return (
    <>
      {loadingMarkup}
      {pageMarkup}
      {impersonatedUserId && (
        <Toast
          onDismiss={() => null}
          content={`Viewing page as ${insighter?.name}`}
          action={{
            content: 'Back',
            onAction: () => history.push(`/admin/insighters/${impersonatedUserId}/view`),
          }}
        />
      )}
    </>
  );
};

export default ProjectsDashboard;
