import React from 'react';
import { useHistory } from 'react-router-dom';
import { UserContext } from '../contexts/UserContext';

// TODO: This 'page' immediately logs the user out, then redirects to the login page
// Would be better not to have this page at all, but the MenuBar components used for the SignOut
// offer a url redirect, but not a function to call to log out
// Aim to refactor this to remove the need for this page

const LogOutPage: React.FC = () => {
  const { logout } = React.useContext(UserContext);
  const history = useHistory();

  logout();
  history.push('/login');

  return <></>;
};

export default LogOutPage;
