import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@shopify/polaris';
import { Grid } from '@material-ui/core';
import Page from '../../shared/components/Page';
import FormInput from '../../form/components/FormInput';
import { RegistrationsContext } from '../contexts/RegistrationsContext';
import { UserContext } from '../../auth/contexts/UserContext';
import BasicFooter from '../../navigation/components/BasicFooter';
import { Insighter } from '../../insighters/model';

interface AdminProfileFormProps {
  profile?: Insighter;
}

const AdminProfileForm: React.FunctionComponent<AdminProfileFormProps> = (props) => {
  const { profile } = props;
  const history = useHistory();

  const { updateProfile, formError, profileSubmitting } = React.useContext(RegistrationsContext);
  const { user } = React.useContext(UserContext);

  const [formValues, setFormValues] = React.useState<any>({});

  React.useEffect(() => {
    setFormValues({
      email: profile?.email,
      first_name: profile?.first_name,
      last_name: profile?.last_name,
    });
  }, [profile]);

  return (
    <Page fullWidth>
      <Grid container>
        <Grid item xs={1} />
        <Grid item xs={10} style={{ marginTop: 25 }}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <h2 className="Polaris-Heading" style={{ margin: '0 10px 10px 0', fontSize: 28, lineHeight: '40px' }}>
                Admin Profile
              </h2>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={1} />
        <Grid item xs={10}>
          <Grid container>
            <h2 className="Polaris-Heading" style={{ marginTop: 10 }}>
              Account Information
            </h2>
          </Grid>
        </Grid>
      </Grid>
      <br />
      <Grid container>
        <Grid item xs={1} />
        <Grid item xs={10}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <FormInput
                type="text"
                label="Email"
                value={formValues.email}
                onChange={(value) => setFormValues({ ...formValues, email: value })}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <FormInput
                type="text"
                label="First Name"
                value={formValues.first_name}
                onChange={(value) => setFormValues({ ...formValues, first_name: value })}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <FormInput
                type="text"
                label="Last Name"
                value={formValues.last_name}
                onChange={(value) => setFormValues({ ...formValues, last_name: value })}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <br />
      <Grid container>
        <Grid item xs={1} />
        <Grid item xs={10}>
          <br />
          <div style={{ textAlign: 'left' }}>
            {formError && <p style={{ color: 'red', marginBottom: '8px' }}>{formError}</p>}
            <Button primary loading={profileSubmitting} onClick={() => updateProfile(user.id, formValues, true)}>
              Update Profile
            </Button>
          </div>
          <br />
          <Grid container>
            <Grid item xs={10}>
              <h2 className="Polaris-Heading" style={{ marginTop: 40 }}>
                Change Password
              </h2>
              <br />
              <Button primary onClick={() => history.push(`/profile/password`)}>
                Change Password
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <br />
      <BasicFooter />
    </Page>
  );
};

export default AdminProfileForm;
