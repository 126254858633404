import React from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import useBaseStyles from '../../constants/redesignStyles';
import HoverButton from '../../common/HoverButton';
import TitleSubBackgroundImage from '../../../../assets/redesignAssets/TitleSubBackground.png';
import SectionOrnament from '../../common/SectionOrnament';

const useStyleOverrides = makeStyles((theme) => ({
  h1: {
    fontSize: '5rem', // default font size
    [theme.breakpoints.up('md')]: {
      // approximately 960px and up
      fontSize: '4rem',
    },
    [theme.breakpoints.up(1100)]: {
      fontSize: '5rem',
    },
    [theme.breakpoints.up(1350)]: {
      fontSize: '7rem',
    },
    [theme.breakpoints.up(1600)]: {
      fontSize: '8rem',
    },
    [theme.breakpoints.down(960)]: {
      textAlign: 'center',
    },
  },
  heroTitleContainer: {
    [theme.breakpoints.down(960)]: {
      alignItems: 'center',
      flexDirection: 'column',
    },
  },
  heroTitleWrapperItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '5%',
    textAlign: 'left',
    [theme.breakpoints.down(960)]: {
      alignItems: 'center',
    },
    [theme.breakpoints.up(961)]: {
      alignItems: 'flex-start',
    },
  },
}));

const HomeLandingSection: React.FC = () => {
  const baseClasses = useBaseStyles();
  const overrideClasses = useStyleOverrides();

  return (
    <Grid container className={overrideClasses.heroTitleContainer}>
      <Grid item md={6} className={overrideClasses.heroTitleWrapperItem}>
        <SectionOrnament text="Transforming Healthcare Through Shared Experiences" maxWidth="500px" />
        <Typography component="h1" className={`${baseClasses.h1} ${overrideClasses.h1}`}>
          Your Story.
          <br />
          Your Experience.
        </Typography>
        <div style={{ position: 'relative', display: 'inline-block' }}>
          <img
            src={TitleSubBackgroundImage}
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              top: 0,
              left: 0,
              zIndex: 0,
              objectFit: 'cover',
            }}
            alt="title background"
          />
          <h1 className={`${baseClasses.h1} ${overrideClasses.h1}`} style={{ position: 'relative' }}>
            <span
              style={{
                background: 'linear-gradient(to right, #3B4255, #3D7C6B)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                paddingRight: 3,
              }}
            >
              Your Insights.
            </span>
          </h1>
        </div>
        <br />
        <HoverButton text="Find a Paid Opportunity" link="/opportunities/general" />
      </Grid>
      <Grid item md={6}>
        <img
          src="https://cdn.theinsighters.com/HeroImageUpdated.png"
          alt="Hero"
          style={{ width: '100%', height: 'auto' }}
        />
      </Grid>
    </Grid>
  );
};

export default HomeLandingSection;
