import React, { useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';

import { SurveyMappingContext } from '../contexts/SurveyMappingContext';
import SurveyMappingForm from '../components/SurveyMappingForm';

import LoadingSpinner from '../../loading/components/LoadingSpinner';

import AdminPageHeader from '../../adminTools/components/AdminPageHeader';

const EditSurveyMapping: React.FunctionComponent = () => {
  const { phaseId } = useParams<Record<string, string>>();

  const { surveyMapping, fetchSurveyMapping, surveyMappingsLoading } = useContext(SurveyMappingContext);

  useEffect(() => {
    if (phaseId) {
      fetchSurveyMapping(phaseId);
    }
  }, [phaseId]);

  if (surveyMappingsLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Grid container style={{ padding: '1% 2%' }}>
      <AdminPageHeader
        title="Edit Survey Mapping"
        backButton={{ label: `Qualtrics Info`, link: `/admin/phases/${phaseId}/survey-mapping` }}
      />
      <SurveyMappingForm surveyMapping={surveyMapping} />
    </Grid>
  );
};

export default EditSurveyMapping;
