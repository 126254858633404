import React, { useState, useContext } from 'react';
import axios from '../../utils/axios.utils';
import { InsighterFinderContext } from './InsighterFinderContext';

interface SavedSearchesContextInterface {
  fetchSavedSearches?: () => Promise<void>;
  savedSearchesLoading?: boolean;
  fetchSingleSavedSearch?: () => Promise<void>;
  setSavedSearchesLoading?: React.Dispatch<React.SetStateAction<boolean>>;
  savedSearchOptions?: any[];
  setSavedSearchOptions?: React.Dispatch<React.SetStateAction<any[]>>;
  selectedSavedSearchId?: string;
  setSelectedSavedSearchId?: React.Dispatch<React.SetStateAction<string>>;
  nextSaveNum?: number;
  setNextSaveNum?: React.Dispatch<React.SetStateAction<number>>;
  nameForSavedSearch?: string;
  setNameForSavedSearch?: React.Dispatch<React.SetStateAction<string>>;
  deleteSavedSearchModalOpen?: boolean;
  setDeleteSavedSearchModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  editNameFieldOpen?: boolean;
  setEditNameFieldOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  saveButtonOpen?: boolean;
  setSaveButtonOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  handleSaveSearch?: () => Promise<void>;
  handleUpdateSearch?: () => Promise<void>;
  loadedSavedSearch?: any;
  setLoadedSavedSearch?: React.Dispatch<React.SetStateAction<any>>;
}

const SavedSearchesContext = React.createContext<SavedSearchesContextInterface>({});
const SavedSearchesContextConsumer = SavedSearchesContext.Consumer;

const SavedSearchesContextProvider: React.FC = ({ children }) => {
  const { filterBuilderValue, setFilterBuilderValue } = useContext(InsighterFinderContext);

  const [savedSearchesLoading, setSavedSearchesLoading] = useState(false);
  const [savedSearchOptions, setSavedSearchOptions] = useState<any[]>([]);
  const [selectedSavedSearchId, setSelectedSavedSearchId] = useState<string>();
  const [nextSaveNum, setNextSaveNum] = useState<number>();
  const [nameForSavedSearch, setNameForSavedSearch] = useState<string>();
  const [deleteSavedSearchModalOpen, setDeleteSavedSearchModalOpen] = useState<boolean>(false);
  const [editNameFieldOpen, setEditNameFieldOpen] = useState<boolean>(false);
  const [loadedSavedSearch, setLoadedSavedSearch] = useState({ search_filters: '' });
  const [saveButtonOpen, setSaveButtonOpen] = useState<boolean>(false);

  const fetchSavedSearches = async () => {
    setSavedSearchesLoading(true);
    await axios.get<string, any>(`saved_searches`).then((response) => {
      const searchOptions = response?.data?.saved_search_options;
      const firstSavedSearch = searchOptions[0];
      setSavedSearchOptions(searchOptions);
      setSelectedSavedSearchId(firstSavedSearch?.value);
      setNextSaveNum(searchOptions?.length + 1);
      setNameForSavedSearch(firstSavedSearch?.label);
    });
    setSavedSearchesLoading(false);
  };

  const fetchSingleSavedSearch = async () => {
    setSavedSearchesLoading(true);
    await axios.get(`saved_searches/${selectedSavedSearchId}`).then((response) => {
      const responseSearch = response?.data?.result;
      setLoadedSavedSearch(responseSearch);
      setFilterBuilderValue(responseSearch?.search_filters);
      setNameForSavedSearch(responseSearch?.name);
    });

    setSavedSearchesLoading(false);
  };

  const handleSaveSearch = async () => {
    setSaveButtonOpen(false);
    setSavedSearchesLoading(true);

    await axios.post(`saved_searches`, { name: nameForSavedSearch, filterBuilderValue }).then((response) => {
      const newOptions = [...savedSearchOptions];
      const newestSavedSearch = { value: response?.data?.result?.id, label: response?.data?.result?.name };
      newOptions.push(newestSavedSearch);
      setSavedSearchOptions(newOptions);
      setSelectedSavedSearchId(newestSavedSearch.value);
      setNextSaveNum(nextSaveNum + 1);
    });

    setSavedSearchesLoading(false);
  };

  const handleUpdateSearch = async () => {
    setEditNameFieldOpen(false);

    await axios.put(`saved_searches/${selectedSavedSearchId}`, { saved_search: { name: nameForSavedSearch } });

    fetchSavedSearches();
  };

  return (
    <SavedSearchesContext.Provider
      value={{
        fetchSavedSearches,
        fetchSingleSavedSearch,
        savedSearchesLoading,
        setSavedSearchesLoading,
        savedSearchOptions,
        setSavedSearchOptions,
        selectedSavedSearchId,
        setSelectedSavedSearchId,
        nextSaveNum,
        setNextSaveNum,
        nameForSavedSearch,
        setNameForSavedSearch,
        deleteSavedSearchModalOpen,
        setDeleteSavedSearchModalOpen,
        handleSaveSearch,
        handleUpdateSearch,
        editNameFieldOpen,
        setEditNameFieldOpen,
        loadedSavedSearch,
        setLoadedSavedSearch,
        saveButtonOpen,
        setSaveButtonOpen,
      }}
    >
      {children}
    </SavedSearchesContext.Provider>
  );
};

export { SavedSearchesContext, SavedSearchesContextProvider, SavedSearchesContextConsumer };
