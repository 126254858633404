import React, { useContext } from 'react';
import ConfirmCancelModal from '../../modal/components/MaterialModal';
import { SavedSearchesContext } from '../contexts/SavedSearchesContext';
import axios from '../../utils/axios.utils';

const SavedSearchDeleteModal: React.FC = () => {
  const {
    selectedSavedSearchId,
    savedSearchesLoading,
    setSavedSearchesLoading,
    fetchSavedSearches,
    deleteSavedSearchModalOpen,
    setDeleteSavedSearchModalOpen,
  } = useContext(SavedSearchesContext);

  const handleDeleteSavedSearch = async () => {
    setSavedSearchesLoading(true);
    await axios.delete<string, any>(`saved_searches/${selectedSavedSearchId}`);

    setDeleteSavedSearchModalOpen(false);
    fetchSavedSearches();
    setSavedSearchesLoading(false);
  };

  return (
    <ConfirmCancelModal
      isOpen={deleteSavedSearchModalOpen}
      title="Delete Saved Search"
      onCancel={() => setDeleteSavedSearchModalOpen(false)}
      confirmDisabled={savedSearchesLoading}
      onWarning={() => handleDeleteSavedSearch()}
      warningLabel="Delete"
      cancelLabel="Cancel"
      hasWarningButton
      hasCancelButton
      hasFooterBar={false}
      height="250px"
    >
      <div style={{ marginTop: '20px', marginBottom: '20px' }}>
        <p>Are you sure you want to delete this saved search?</p>
      </div>
    </ConfirmCancelModal>
  );
};

export default SavedSearchDeleteModal;
