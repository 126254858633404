import React from 'react';
import { Button, FormLayout } from '@shopify/polaris';
import { Grid } from '@material-ui/core';
import FormInput from '../../form/components/FormInput';
import SelectInput from '../../form/components/SelectInput';
import { InsighterTagsContext } from '../contexts/InsighterTagsContext';
import { InsighterTag, InsighterTagFormValues } from '../model';
import ConfirmCancelModal from '../../modal/components/MaterialModal';

interface InsighterTagFormProps {
  insighterTag?: InsighterTag;
}

const InsighterTagForm: React.FC<InsighterTagFormProps> = (props) => {
  const { insighterTag } = props;
  const { formError, updateInsighterTag, createInsighterTag, insighterTagSubmitting } =
    React.useContext(InsighterTagsContext);

  const [formValues, setFormValues] = React.useState<InsighterTagFormValues>({});
  const [confirmModalOpen, setConfirmModalOpen] = React.useState(false);

  React.useEffect(() => {
    setFormValues({
      name: insighterTag?.name,
      badge_color: insighterTag?.badge_color,
      status: insighterTag?.status,
    });
  }, [insighterTag]);

  const handleUpdate = () => {
    updateInsighterTag(insighterTag.id, formValues);
  };

  const handleArchive = () => {
    updateInsighterTag(insighterTag.id, { ...formValues, archived: !insighterTag.archived });
  };

  const handleCreate = () => {
    createInsighterTag(formValues);
  };

  return (
    <FormLayout>
      <ConfirmCancelModal
        isOpen={confirmModalOpen}
        title="Archive Insighter Tag"
        onCancel={() => setConfirmModalOpen(false)}
        onWarning={() => handleArchive()}
        warningLabel="Archive"
        cancelLabel="Cancel"
        hasWarningButton
        hasCancelButton
        hasFooterBar={false}
        height="250px"
      >
        <div style={{ marginTop: '20px', marginBottom: '20px' }}>
          <p>Are you sure you want to archive this Insighter Tag?</p>
        </div>
      </ConfirmCancelModal>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <h2 className="Polaris-Heading">Insighter Tag Information</h2>
        </Grid>
        <Grid item xs={12}>
          <FormInput
            label="Name"
            type="text"
            value={formValues.name}
            onChange={(value) => setFormValues({ ...formValues, name: value })}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectInput
            label="Badge Color"
            choices={[
              { label: 'Green', value: 'green' },
              { label: 'Orange', value: 'orange' },
              { label: 'Red', value: 'red' },
              { label: 'Grey', value: 'grey' },
            ]}
            value={formValues.badge_color}
            onChange={(value) => setFormValues({ ...formValues, badge_color: value })}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectInput
            label="Status"
            choices={[
              { label: 'Active', value: 'active' },
              { label: 'Inactive', value: 'inactive' },
            ]}
            value={formValues.status}
            onChange={(value) => setFormValues({ ...formValues, status: value })}
          />
        </Grid>
      </Grid>

      <>
        {insighterTag?.id ? (
          <Grid>
            <Button primary onClick={handleUpdate} loading={insighterTagSubmitting}>
              Update Insighter Tag
            </Button>
            &emsp;
            {insighterTag?.archived ? (
              <Button onClick={handleArchive} loading={insighterTagSubmitting}>
                Unarchive
              </Button>
            ) : (
              <Button destructive onClick={() => setConfirmModalOpen(true)} loading={insighterTagSubmitting}>
                Archive
              </Button>
            )}
          </Grid>
        ) : (
          <Button primary onClick={handleCreate} loading={insighterTagSubmitting}>
            Create Insighter Tag
          </Button>
        )}
        {formError && <p style={{ color: 'red', fontSize: '14px', marginTop: 20 }}>{formError}</p>}
      </>
    </FormLayout>
  );
};

export default InsighterTagForm;
