import React from 'react';

interface ButtonWrapperProps {
  link?: string;
  label?: string;
  theme?: string;
  onClick?: () => void;
}

const ButtonWrapper: React.FunctionComponent<ButtonWrapperProps> = (props) => {
  const { link, label, theme, onClick } = props;

  const buttonClass = () => {
    if (theme === 'primary') {
      return 'Polaris-Button Polaris-Button--primary';
    }

    return 'Polaris-Button';
  };

  return (
    <a className={buttonClass()} href={link} rel="noreferrer" onClick={onClick} target="_blank">
      <span className="Polaris-Button__Content">
        <span className="Polaris-Button__Text">{label}</span>
      </span>
    </a>
  );
};

export default ButtonWrapper;
