import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@shopify/polaris';
import { styled } from '@mui/material/styles';
import HelpIcon from '@mui/icons-material/Help';
import { Grid, IconButton } from '@material-ui/core';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import Page from '../../shared/components/Page';
import ConditionAutocompleteInput from '../../form/components/ConditionAutocompleteInput';
import UngroupedAutocompleteInput from '../../form/components/UngroupedAutocompleteInput';
import FormInput from '../../form/components/FormInput';
import SelectInput from '../../form/components/SelectInput';
import { RegistrationsContext } from '../contexts/RegistrationsContext';
import { UserContext } from '../../auth/contexts/UserContext';
import BasicFooter from '../../navigation/components/BasicFooter';
import { Insighter } from '../../insighters/model';
import states from '../../insighters/constants/states';
import degreeChoices from '../../insighters/constants/degreeChoices';
import ConfirmCancelModal from '../../modal/components/MaterialModal';

interface ProfileFormProps {
  profile?: Insighter;
}

const ProfileForm: React.FunctionComponent<ProfileFormProps> = (props) => {
  const { profile } = props;
  const history = useHistory();

  const {
    updateProfile,
    archiveProfile,
    deleteProfile,
    formOptions,
    formError,
    profileSubmitting,
    deleteProfileErrors,
    deleteAccountModalOpen,
    setDeleteAccountModalOpen,
  } = useContext(RegistrationsContext);
  const { user } = useContext(UserContext);

  const [formValues, setFormValues] = useState<any>({});
  const [archiveAccountModalOpen, setArchiveAccountModalOpen] = useState(false);

  const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      fontSize: '1em',
    },
  }));

  const displayHealthcareProviderFields = () => {
    const usedRoles = formOptions?.insighter_role_options.filter((option) => {
      return formValues?.insighter_role_names?.includes(option.label);
    });
    const isHCP = (role) => role.hcp_role === true;
    return usedRoles?.some(isHCP);
  };

  useEffect(() => {
    setFormValues({
      email: profile?.email,
      first_name: profile?.first_name,
      last_name: profile?.last_name,
      mobile_phone: profile?.mobile_phone,
      zip: profile?.zip,
      date_of_birth: profile?.date_of_birth,
      preferred_contact_method: profile?.preferred_contact_method,
      gender: profile?.gender,
      transgender_identity: profile?.transgender_identity,
      sexuality: profile?.sexuality,
      ethnicity_names: profile?.ethnicity_names,
      veteran_status: profile?.veteran_status,
      insighter_role_names: profile?.insighter_role_names,
      specialty_names: profile?.specialty_names,
      condition_names: profile?.condition_names,
      npi_number: profile?.npi_number,
      percent_patient_care: profile?.percent_patient_care,
      states_practicing: profile?.states_practicing,
      residency_end_year: profile?.residency_end_year,
      license_start_year: profile?.license_start_year,
      board_certified: profile?.board_certified,
      board_eligible: profile?.board_eligible,
      healthcare_certification_or_degree: profile?.healthcare_certification_or_degree,
      other_certifications: profile?.other_certifications,
      med_school_graduation_year: profile?.med_school_graduation_year,
    });
  }, [profile]);

  return (
    <>
      <ConfirmCancelModal
        isOpen={archiveAccountModalOpen}
        title="Archive Account"
        onCancel={() => setArchiveAccountModalOpen(false)}
        onConfirm={() => archiveProfile(user.id)}
        warningLabel="Archive Account"
        cancelLabel="Cancel"
        hasCancelButton
        hasWarningButton
        hasFooterBar={false}
        height="250px"
      >
        <div style={{ marginTop: '20px', marginBottom: '20px' }}>
          <p>
            Are you sure you want to archive your account? Account information will be saved and can be reactivated at
            any time by logging back in to your Insighters account.
          </p>
        </div>
      </ConfirmCancelModal>
      <ConfirmCancelModal
        isOpen={deleteAccountModalOpen}
        title="Delete Account"
        onCancel={() => setDeleteAccountModalOpen(false)}
        onWarning={() => deleteProfile(user.id)}
        warningLabel="Delete Account"
        cancelLabel="Cancel"
        hasWarningButton
        hasCancelButton
        hasFooterBar={false}
        height="250px"
      >
        <div style={{ marginTop: '20px', marginBottom: '20px' }}>
          <p>
            Are you sure you want to delete your account? All account information will be deleted. This cannot be
            undone.
          </p>
          {deleteProfileErrors && <p style={{ color: 'red', marginTop: '20px' }}>{deleteProfileErrors}</p>}
        </div>
      </ConfirmCancelModal>
      <Page fullWidth>
        <Grid container>
          <Grid item xs={1} />
          <Grid item xs={10} style={{ marginTop: 25 }}>
            <Grid container justifyContent="space-between">
              <Grid item>
                <h2 className="Polaris-Heading" style={{ margin: '0 10px 10px 0', fontSize: 28, lineHeight: '40px' }}>
                  My Insighter Profile
                </h2>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={1} />
          <Grid item xs={10}>
            <Grid container>
              <h2 className="Polaris-Heading" style={{ marginTop: 10 }}>
                Account Information
              </h2>
            </Grid>
          </Grid>
        </Grid>
        <br />
        <Grid container>
          <Grid item xs={1} />
          <Grid item xs={10}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <FormInput
                  type="text"
                  label="Email"
                  value={formValues.email}
                  onChange={(value) => setFormValues({ ...formValues, email: value })}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <FormInput
                  type="text"
                  label="First Name"
                  value={formValues.first_name}
                  onChange={(value) => setFormValues({ ...formValues, first_name: value })}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <FormInput
                  type="text"
                  label="Last Name"
                  value={formValues.last_name}
                  onChange={(value) => setFormValues({ ...formValues, last_name: value })}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <br />
        <Grid container>
          <Grid item xs={1} />
          <Grid item xs={10}>
            <Grid container>
              <h2 className="Polaris-Heading" style={{ margin: '40px 0 10px' }}>
                Other Profile Information
                <StyledTooltip
                  title="We use this information to decide if you're a good fit for opportunities"
                  style={{ padding: 5, marginLeft: 5 }}
                >
                  <IconButton>
                    <HelpIcon style={{ fontSize: '1.4em' }} />
                  </IconButton>
                </StyledTooltip>
              </h2>
            </Grid>
          </Grid>
        </Grid>
        <br />
        <Grid container>
          <Grid item xs={1} />
          <Grid item xs={10}>
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <FormInput
                  type="text"
                  label="Phone"
                  value={formValues.mobile_phone}
                  onChange={(value) => setFormValues({ ...formValues, mobile_phone: value })}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormInput
                  type="text"
                  label="Zipcode"
                  value={formValues.zip}
                  onChange={(value) => setFormValues({ ...formValues, zip: value })}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormInput
                  type="text"
                  label="Date of Birth"
                  value={formValues.date_of_birth}
                  onChange={(value) => setFormValues({ ...formValues, date_of_birth: value })}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <SelectInput
                  label="Contact Method"
                  choices={[
                    { label: 'Email', value: 'Email' },
                    { label: 'Phone Call', value: 'Phone Call' },
                    { label: 'Text Message', value: 'Text Message' },
                  ]}
                  onChange={(value) => setFormValues({ ...formValues, preferred_contact_method: value })}
                  value={formValues.preferred_contact_method}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <br />
        <Grid container>
          <Grid item xs={1} />
          <Grid item xs={10}>
            <Grid container>
              <h2 className="Polaris-Heading" style={{ marginTop: 10 }}>
                Health Information
              </h2>
            </Grid>
          </Grid>
        </Grid>
        <br />
        <Grid container>
          <Grid item xs={1} />
          <Grid item xs={10}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                {formValues.insighter_role_names && (
                  <UngroupedAutocompleteInput
                    label="Healthcare Role"
                    choices={formOptions?.insighter_role_options}
                    onChange={(value) => {
                      setFormValues({ ...formValues, insighter_role_names: value });
                    }}
                    value={formValues.insighter_role_names as any[]}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <br />
        {displayHealthcareProviderFields() && (
          <>
            <Grid container>
              <Grid item xs={1} />
              <Grid item xs={10}>
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <Grid container>
                      <h2 className="Polaris-Heading" style={{ margin: '40px 0 10px' }}>
                        Health Care Provider Information
                      </h2>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    {formValues.specialty_names && (
                      <UngroupedAutocompleteInput
                        label="Specialties"
                        choices={formOptions?.specialty_options}
                        onChange={(value) => {
                          setFormValues({ ...formValues, specialty_names: value });
                        }}
                        value={formValues.specialty_names as any[]}
                      />
                    )}
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container spacing={4}>
                      <Grid item xs={6}>
                        <FormInput
                          type="text"
                          label="NPI Number"
                          value={formValues.npi_number}
                          onChange={(value) => setFormValues({ ...formValues, npi_number: value })}
                        />
                        {formValues?.npi_number && formValues?.npi_number.length !== 10 && (
                          <p style={{ color: 'red' }}>NPI Number must be 10 digits.</p>
                        )}
                      </Grid>
                      <Grid item xs={6} />
                      <Grid item xs={6}>
                        <SelectInput
                          label="Healthcare Certification or Degree"
                          choices={degreeChoices}
                          onChange={(value) =>
                            setFormValues({ ...formValues, healthcare_certification_or_degree: value })
                          }
                          value={formValues.healthcare_certification_or_degree}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        {formValues.healthcare_certification_or_degree === 'Other (please specify)' && (
                          <FormInput
                            type="text"
                            label="Specify Other Degree"
                            value={formValues.other_certifications}
                            onChange={(value) => setFormValues({ ...formValues, other_certifications: value })}
                          />
                        )}
                      </Grid>
                      <Grid item xs={6}>
                        <UngroupedAutocompleteInput
                          label="Practicing In"
                          name="practicing"
                          choices={states}
                          onChange={(value) => {
                            setFormValues({ ...formValues, states_practicing: value });
                          }}
                          value={formValues?.states_practicing}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FormInput
                          type="text"
                          label="Med School Graduation Year"
                          value={formValues.med_school_graduation_year}
                          onChange={(value) => setFormValues({ ...formValues, med_school_graduation_year: value })}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <SelectInput
                          label="% of Patient Care"
                          choices={[
                            { label: '0%', value: '0' },
                            { label: '20%', value: '20' },
                            { label: '40%', value: '40' },
                            { label: '60%', value: '60' },
                            { label: '80%', value: '80' },
                            { label: '100%', value: '100' },
                          ]}
                          onChange={(value) => setFormValues({ ...formValues, percent_patient_care: value })}
                          value={formValues.percent_patient_care}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <FormInput
                          type="text"
                          label="Residency End Year"
                          value={formValues.residency_end_year}
                          onChange={(value) => setFormValues({ ...formValues, residency_end_year: value })}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FormInput
                          type="text"
                          label="License Start Year"
                          value={formValues.license_start_year}
                          onChange={(value) => setFormValues({ ...formValues, license_start_year: value })}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <SelectInput
                          label="Board Certified"
                          choices={[
                            { label: 'Yes', value: 'Yes' },
                            { label: 'No', value: 'No' },
                            { label: 'N/A', value: 'N/A' },
                          ]}
                          onChange={(value) => setFormValues({ ...formValues, board_certified: value })}
                          value={formValues.board_certified}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <SelectInput
                          label="Board Eligible"
                          choices={[
                            { label: 'Yes', value: 'Yes' },
                            { label: 'No', value: 'No' },
                            { label: 'N/A', value: 'N/A' },
                          ]}
                          onChange={(value) => setFormValues({ ...formValues, board_eligible: value })}
                          value={formValues.board_eligible}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <br />
          </>
        )}
        <Grid container>
          <Grid item xs={1} />

          <Grid item xs={10}>
            <Grid item xs={10}>
              <h2 className="Polaris-Heading" style={{ marginTop: 40 }}>
                Conditions
              </h2>
              {formValues.condition_names && (
                <ConditionAutocompleteInput
                  label={null}
                  onChange={(value) => {
                    setFormValues({ ...formValues, condition_names: value });
                  }}
                  value={formValues.condition_names as any[]}
                />
              )}
            </Grid>

            <Grid container>
              <h2 className="Polaris-Heading" style={{ marginTop: 40 }}>
                Diversity, Equity, and Inclusion
              </h2>
              <br />
              <p style={{ marginTop: 15 }}>
                At The Insighters®, we believe that diverse research is better research. We believe that research is
                more trustworthy when we work together to understand & represent different cultures & backgrounds, & we
                are committed to cultivating an inclusive & diverse community. This is where you come in! If you&apos;re
                comfortable disclosing, please let us know your following information.
              </p>
            </Grid>
          </Grid>
        </Grid>
        <br />
        <Grid container>
          <Grid item xs={1} />
          <Grid item xs={10}>
            <Grid container spacing={4}>
              <Grid item md={6} xs={12}>
                <SelectInput
                  label="Gender"
                  choices={[
                    { label: 'Female', value: 'Female' },
                    { label: 'Male', value: 'Male' },
                    { label: 'Non-binary', value: 'Non-binary' },
                    { label: 'Not Listed', value: 'Not Listed' },
                    { label: 'Other', value: 'Other' },
                    { label: 'Withhold / Decline to Disclose', value: 'Withhold / Decline to Disclose' },
                  ]}
                  onChange={(value) => setFormValues({ ...formValues, gender: value })}
                  value={formValues.gender}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <SelectInput
                  label="Do you identify as transgender?"
                  choices={[
                    { label: 'Yes', value: 'Yes' },
                    { label: 'No', value: 'No' },
                    { label: 'Not Sure', value: 'Not Sure' },
                    { label: 'Prefer Not to Say', value: 'Prefer Not to Say' },
                  ]}
                  onChange={(value) => setFormValues({ ...formValues, transgender_identity: value })}
                  value={formValues.transgender_identity}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <SelectInput
                  label="Sexual Orientation"
                  choices={[
                    { label: 'Gay', value: 'Gay' },
                    { label: 'Lesbian', value: 'Lesbian' },
                    { label: 'Bisexual', value: 'Bisexual' },
                    { label: 'Heterosexual', value: 'Heterosexual' },
                    { label: 'Queer', value: 'Queer' },
                    { label: 'Other', value: 'Other' },
                    { label: 'Withhold / Decline to Disclose', value: 'Withhold / Decline to Disclose' },
                  ]}
                  onChange={(value) => setFormValues({ ...formValues, sexuality: value })}
                  value={formValues.sexuality}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                {formValues.ethnicity_names && (
                  <UngroupedAutocompleteInput
                    label="Race/Ethnicity"
                    choices={formOptions?.ethnicity_options}
                    onChange={(value) => {
                      setFormValues({ ...formValues, ethnicity_names: value });
                    }}
                    value={formValues.ethnicity_names}
                  />
                )}
              </Grid>
              <Grid item md={6} xs={12}>
                <SelectInput
                  label="Veteran Status"
                  choices={[
                    { label: 'I am Active Duty in the U.S. Military', value: 'I am Active Duty in the U.S. Military' },
                    { label: 'I have served in the U.S. Military', value: 'I have served in the U.S. Military' },
                    { label: 'I am a U.S. Military Spouse', value: 'I am a U.S. Military Spouse' },
                    {
                      label: 'I have not served in the U.S. Military',
                      value: 'I have not served in the U.S. Military',
                    },
                    { label: 'Withhold / Decline to Disclose', value: 'Withhold / Decline to Disclose' },
                  ]}
                  onChange={(value) => setFormValues({ ...formValues, veteran_status: value })}
                  value={formValues.veteran_status}
                />
              </Grid>
            </Grid>
            <br />
            <div style={{ textAlign: 'left' }}>
              {formError && <p style={{ color: 'red', marginBottom: '8px' }}>{formError}</p>}
              <Button primary loading={profileSubmitting} onClick={() => updateProfile(user.id, formValues, false)}>
                Update Profile
              </Button>
            </div>
            <br />
            <Grid container>
              <Grid item xs={10}>
                <h2 className="Polaris-Heading" style={{ marginTop: 40 }}>
                  Change Password
                </h2>
                <br />
                <Button primary onClick={() => history.push(`/profile/password`)}>
                  Change Password
                </Button>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={10}>
                <Grid container>
                  <h2 className="Polaris-Heading" style={{ marginTop: 40 }}>
                    Archive or Delete Account
                  </h2>
                  <br />
                  <p style={{ marginTop: 15 }}>
                    If you would like to archive or delete your account, we can take care of this for you. Keep in mind
                    that you will not be able to reactivate your account if you choose to delete it.
                  </p>
                </Grid>
                <br />
                <div style={{ textAlign: 'left' }}>
                  <Button onClick={() => setArchiveAccountModalOpen(true)}>Archive Account</Button>
                  <span style={{ marginLeft: '8px' }}>
                    <Button destructive onClick={() => setDeleteAccountModalOpen(true)}>
                      Delete Account
                    </Button>
                  </span>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <br />
        <BasicFooter />
      </Page>
    </>
  );
};

export default ProfileForm;
