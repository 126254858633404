import React, { useState, useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';
import MediumOrnament from '../../common/MediumOrnament';
import useStyles from '../../constants/redesignStyles';
import BioCard from '../components/BioCard';
import BioModal from '../components/BioModal';

import { TeamBio } from '../../../teamBios/model';

import axios from '../../../utils/axios.utils';

const InsightersTeamBios: React.FC = () => {
  const classes = useStyles();

  let [bioModalOpen, setBioModalOpen] = useState(false);
  let [bioModalInfo, setBioModalInfo] = useState<TeamBio | null>(null);
  let [teamBios, setTeamBios] = useState<TeamBio[]>([]);

  const fetchTeamBios = async () => {
    try {
      const response = await axios.get('team_bios');
      setTeamBios(response?.data?.result);
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchTeamBios();
    // eslint-disable-next-line
  }, []);

  return (
    <Grid container justifyContent="center" style={{ maxWidth: 1200, margin: 'auto' }}>
      <BioModal bioModalOpen={bioModalOpen} setBioModalOpen={setBioModalOpen} bioModalInfo={bioModalInfo} />
      <MediumOrnament text="Meet Our Team" centerComponent centerText />
      <Typography className={classes.h2} style={{ textAlign: 'center' }}>
        The Real People Behind The Insighters
      </Typography>
      <br />
      <Grid container style={{ marginTop: 15, display: 'flex', justifyContent: 'center' }} spacing={2}>
        {teamBios.map((biodata, index) => (
          <Grid item md={6} xs={12} key={`biodata-${index}`}>
            <BioCard biodata={biodata} setBioModalOpen={setBioModalOpen} setBioModalInfo={setBioModalInfo} />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default InsightersTeamBios;
