import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import HoverButton from './HoverButton';
import TitleBackgroundImage from '../../../assets/redesignAssets/TitleBackgroundImage.png';
import TitleSubBackgroundImage from '../../../assets/redesignAssets/TitleSubBackground.png';
import SectionOrnament from './SectionOrnament';
import useStyles from '../constants/redesignStyles';

const styleOverrides = makeStyles((theme) => ({
  ...useStyles,
  h1Override: {
    color: '#3B4255',
    fontFamily: 'Crimson Text',
    fontSize: '5rem',
    [theme.breakpoints.up(1350)]: {
      fontSize: '7rem',
    },
    [theme.breakpoints.up(1600)]: {
      fontSize: '8rem',
    },
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '100%',
    letterSpacing: '-4.25px',
    width: '100%',
    margin: 'auto 15% auto auto',
  },
}));

interface HeroPageHeaderProps {
  heroImageUrl: string;
  ornamentText?: string;
  titleText?: string;
  subtitleText?: string;
  buttonText?: string;
}

const HeroPageHeader: React.FC<HeroPageHeaderProps> = (props) => {
  const classes = styleOverrides();
  const { heroImageUrl, ornamentText, titleText, subtitleText, buttonText } = props;

  return (
    <Grid container>
      <Grid item xs={12} md={7}>
        <div
          style={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            maxWidth: 800,
            margin: 'auto',
            height: '50vh',
          }}
        >
          {window.innerWidth >= 960 && (
            <img
              src={TitleBackgroundImage}
              style={{
                position: 'absolute',
                width: '100%',
                height: 'auto',
                top: 0,
                left: 0,
                zIndex: 0,
                objectFit: 'cover',
              }}
              alt="title background"
            />
          )}
          <div style={{ paddingLeft: '10%', paddingTop: '5%' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                paddingRight: '40%',
              }}
            >
              <SectionOrnament text={ornamentText} centerText={false} />
            </div>
            <h1 className={classes.h1Override} style={{ position: 'relative' }}>
              {titleText}
            </h1>
            <div style={{ position: 'relative', display: 'inline-block', marginBottom: 15 }}>
              <img
                src={TitleSubBackgroundImage}
                style={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  top: 0,
                  left: 0,
                  zIndex: 0,
                  objectFit: 'cover',
                }}
                alt="title background"
              />
              <h1 className={classes.h1Override} style={{ position: 'relative' }}>
                <span
                  style={{
                    background: 'linear-gradient(to right, #3B4255, #3D7C6B)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                    paddingRight: 3,
                  }}
                >
                  {subtitleText}
                </span>
              </h1>
            </div>
            <br />
            {buttonText && <HoverButton text={buttonText} link="/sign-up" />}
          </div>
        </div>
      </Grid>
      <Grid item xs={12} md={5}>
        <img src={heroImageUrl} alt="Hero" style={{ width: '100%', height: 'auto' }} />
      </Grid>
    </Grid>
  );
};

export default HeroPageHeader;
