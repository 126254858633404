import React, { useContext } from 'react';
import { FormLayout, Checkbox } from '@shopify/polaris';
import { Grid } from '@material-ui/core';
import FormGroup from '@material-ui/core/FormGroup';
import ConditionAutocompleteInput from '../../form/components/ConditionAutocompleteInput';
import UngroupedAutocompleteInput from '../../form/components/UngroupedAutocompleteInput';
import { InsightersContext } from '../contexts/InsightersContext';
import { Insighter, InsighterFormValues } from '../model';
import FormInput from '../../form/components/FormInput';
import SelectInput from '../../form/components/SelectInput';
import states from '../constants/states';
import degreeChoices from '../constants/degreeChoices';

interface InsighterFormProps {
  insighter?: Insighter;
  afterCreate?: () => void;
  formValues?: InsighterFormValues;
  setFormValues?: (formValues: InsighterFormValues) => void;
  insighterSubmitting?: boolean;
}

const InsighterForm: React.FC<InsighterFormProps> = (props) => {
  const { formValues, setFormValues } = props;

  const { formOptions } = useContext(InsightersContext);

  const contactMethods = ['Email', 'Phone Call', 'Text Message'];

  const displayHealthcareProviderFields = () => {
    const usedRoles = formOptions?.insighter_role_options.filter((option) => {
      return formValues?.insighter_role_names?.includes(option.label);
    });
    const isHCP = (role) => role.hcp_role === true;
    return usedRoles?.some(isHCP);
  };

  const handleCheck = (value) => {
    const oldValues = formValues.preferred_contact_method || [];

    if (oldValues.includes(value)) {
      const newValues = oldValues.filter((v) => v !== value);
      setFormValues({ ...formValues, preferred_contact_method: newValues });
    } else {
      const newValues = [...oldValues, value];
      setFormValues({ ...formValues, preferred_contact_method: newValues });
    }
  };

  return (
    <FormLayout>
      <Grid container>
        <Grid item xs={12}>
          <Grid container>
            <h2 className="Polaris-Heading" style={{ margin: '10px 0' }}>
              Account Information
            </h2>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <FormInput
                type="text"
                label="Email"
                value={formValues.email}
                onChange={(value) => setFormValues({ ...formValues, email: value })}
              />
            </Grid>
            <Grid item xs={6}>
              <SelectInput
                label="Email Valid?"
                choices={[
                  { label: 'Yes', value: 'true' },
                  { label: 'No', value: 'false' },
                ]}
                onChange={(value) => setFormValues({ ...formValues, email_valid: value })}
                value={formValues.email_valid}
              />
            </Grid>
            <Grid item xs={6}>
              <FormInput
                type="text"
                label="First Name"
                value={formValues.first_name}
                onChange={(value) => setFormValues({ ...formValues, first_name: value })}
              />
            </Grid>
            <Grid item xs={6}>
              <FormInput
                type="text"
                label="Last Name"
                value={formValues.last_name}
                onChange={(value) => setFormValues({ ...formValues, last_name: value })}
              />
            </Grid>
            <Grid item xs={12}>
              {formValues?.insighter_role_names && (
                <UngroupedAutocompleteInput
                  label="Insighter Roles"
                  choices={formOptions?.insighter_role_options}
                  onChange={(value) => {
                    setFormValues({ ...formValues, insighter_role_names: value });
                  }}
                  value={formValues.insighter_role_names}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              {formValues?.insighter_tag_names && (
                <UngroupedAutocompleteInput
                  label="Insighter Tags"
                  choices={formOptions?.insighter_tag_options}
                  onChange={(value) => {
                    setFormValues({ ...formValues, insighter_tag_names: value });
                  }}
                  value={formValues.insighter_tag_names}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              {formValues?.condition_names && (
                <ConditionAutocompleteInput
                  label="Conditions"
                  onChange={(value) => {
                    setFormValues({ ...formValues, condition_names: value });
                  }}
                  value={formValues.condition_names}
                />
              )}
            </Grid>
          </Grid>
        </Grid>

        {displayHealthcareProviderFields() && (
          <>
            <Grid item xs={12}>
              <Grid item xs={12}>
                <Grid container>
                  <h2 className="Polaris-Heading" style={{ margin: '40px 0 10px' }}>
                    Health Care Provider Information
                  </h2>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={4}>
                {formValues?.specialty_names && (
                  <Grid item xs={12}>
                    <UngroupedAutocompleteInput
                      label="Specialties"
                      choices={formOptions?.specialty_options}
                      onChange={(value) => {
                        setFormValues({ ...formValues, specialty_names: value });
                      }}
                      value={formValues.specialty_names}
                    />
                  </Grid>
                )}
                <Grid item xs={6}>
                  <FormInput
                    type="text"
                    label="NPI Number"
                    value={formValues.npi_number}
                    onChange={(value) => setFormValues({ ...formValues, npi_number: value })}
                  />
                  {formValues?.npi_number && formValues?.npi_number.length !== 10 && (
                    <p style={{ color: 'red' }}>NPI Number must be 10 digits.</p>
                  )}
                </Grid>
                <Grid item xs={6} />
                <Grid item xs={6}>
                  <SelectInput
                    label="Healthcare Certification or Degree"
                    choices={degreeChoices}
                    onChange={(value) => setFormValues({ ...formValues, healthcare_certification_or_degree: value })}
                    value={formValues.healthcare_certification_or_degree}
                  />
                </Grid>
                <Grid item xs={6}>
                  {formValues.healthcare_certification_or_degree === 'Other (please specify)' && (
                    <FormInput
                      type="text"
                      label="Specify Other Degree"
                      value={formValues.other_certifications}
                      onChange={(value) => setFormValues({ ...formValues, other_certifications: value })}
                    />
                  )}
                </Grid>
                <Grid item xs={6}>
                  <UngroupedAutocompleteInput
                    label="Practicing In"
                    choices={states}
                    onChange={(value) => {
                      setFormValues({ ...formValues, states_practicing: value });
                    }}
                    value={formValues?.states_practicing}
                  />
                </Grid>
                <Grid item xs={6}>
                  <SelectInput
                    label="% of Patient Care"
                    choices={[
                      { label: '0%', value: '0' },
                      { label: '20%', value: '20' },
                      { label: '40%', value: '40' },
                      { label: '60%', value: '60' },
                      { label: '80%', value: '80' },
                      { label: '100%', value: '100' },
                    ]}
                    onChange={(value) => setFormValues({ ...formValues, percent_patient_care: value })}
                    value={formValues.percent_patient_care}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormInput
                    type="text"
                    label="Med School Graduation Year"
                    value={formValues.med_school_graduation_year}
                    onChange={(value) => setFormValues({ ...formValues, med_school_graduation_year: value })}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormInput
                    type="text"
                    label="License Start Year"
                    value={formValues.license_start_year}
                    onChange={(value) => setFormValues({ ...formValues, license_start_year: value })}
                  />
                </Grid>
                <Grid item xs={6}>
                  <SelectInput
                    label="Board Certified"
                    choices={[
                      { label: 'Yes', value: 'Yes' },
                      { label: 'No', value: 'No' },
                      { label: 'N/A', value: 'N/A' },
                    ]}
                    onChange={(value) => setFormValues({ ...formValues, board_certified: value })}
                    value={formValues.board_certified}
                  />
                </Grid>
                <Grid item xs={6}>
                  <SelectInput
                    label="Board Eligible"
                    choices={[
                      { label: 'Yes', value: 'Yes' },
                      { label: 'No', value: 'No' },
                      { label: 'N/A', value: 'N/A' },
                    ]}
                    onChange={(value) => setFormValues({ ...formValues, board_eligible: value })}
                    value={formValues.board_eligible}
                  />
                </Grid>
              </Grid>
            </Grid>
          </>
        )}

        <Grid item xs={12}>
          <Grid container>
            <h2 className="Polaris-Heading" style={{ margin: '40px 0 10px' }}>
              Address Information
            </h2>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <FormInput
                type="text"
                label="Address Line 1"
                value={formValues.address_line1}
                onChange={(value) => setFormValues({ ...formValues, address_line1: value })}
              />
            </Grid>
            <Grid item xs={6}>
              <FormInput
                type="text"
                label="Address Line 2"
                value={formValues.address_line2}
                onChange={(value) => setFormValues({ ...formValues, address_line2: value })}
              />
            </Grid>
            <Grid item xs={6}>
              <FormInput
                type="text"
                label="City"
                value={formValues.city}
                onChange={(value) => setFormValues({ ...formValues, city: value })}
              />
            </Grid>
            <Grid item xs={6}>
              <FormInput
                type="text"
                label="State"
                value={formValues.state}
                onChange={(value) => setFormValues({ ...formValues, state: value })}
              />
            </Grid>
            <Grid item xs={6}>
              <FormInput
                type="text"
                label="Zip Code"
                value={formValues.zip}
                onChange={(value) => setFormValues({ ...formValues, zip: value })}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <h2 className="Polaris-Heading" style={{ margin: '40px 0 10px' }}>
              Other Profile Information
            </h2>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <FormInput
                type="text"
                label="Primary Phone Number"
                value={formValues.mobile_phone}
                onChange={(value) => setFormValues({ ...formValues, mobile_phone: value })}
              />
            </Grid>
            <Grid item xs={6}>
              <FormInput
                type="text"
                label="Date of Birth"
                value={formValues.date_of_birth}
                onChange={(value) => setFormValues({ ...formValues, date_of_birth: value })}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid>
                <h3 className="Polaris-Heading" style={{ margin: '40px 0 10px' }}>
                  Preferred Contact Method
                </h3>
              </Grid>
              <FormGroup>
                {contactMethods.map((method) => (
                  <Checkbox
                    key={method}
                    label={method}
                    checked={formValues.preferred_contact_method?.includes(method)}
                    onChange={() => handleCheck(method)}
                    value={method}
                  />
                ))}
              </FormGroup>
            </Grid>
            <Grid item xs={12}>
              <SelectInput
                label="Block From All Email?"
                choices={[
                  { label: 'Yes', value: 'true' },
                  { label: 'No', value: 'false' },
                ]}
                onChange={(value) => setFormValues({ ...formValues, exclude_from_mailchimp: value })}
                value={formValues.exclude_from_mailchimp}
              />
            </Grid>
            <Grid item xs={12}>
              <FormInput
                type="text"
                label="Alternate Email"
                value={formValues.alternate_email1}
                onChange={(value) => setFormValues({ ...formValues, alternate_email1: value })}
              />
            </Grid>
            <Grid item xs={12}>
              <FormInput
                type="text"
                label="Alternate Phone Numbers  (separate values by comma)"
                value={formValues.alternate_phone_numbers}
                onChange={(value) => setFormValues({ ...formValues, alternate_phone_numbers: value })}
              />
            </Grid>
          </Grid>
        </Grid>
        <br />
        <Grid item xs={12}>
          <Grid container>
            <h2 className="Polaris-Heading" style={{ margin: '40px 0 10px' }}>
              Diversity, Equity, and Inclusion Details
            </h2>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              {formValues?.ethnicity_names && (
                <UngroupedAutocompleteInput
                  label="Race/Ethnicity"
                  choices={formOptions?.ethnicity_options}
                  onChange={(value) => {
                    setFormValues({ ...formValues, ethnicity_names: value });
                  }}
                  value={formValues.ethnicity_names}
                />
              )}
            </Grid>
            <Grid item xs={6}>
              <SelectInput
                label="Gender"
                choices={[
                  { label: 'Female', value: 'Female' },
                  { label: 'Male', value: 'Male' },
                  { label: 'Non-binary', value: 'Non-binary' },
                  { label: 'Not Listed', value: 'Not Listed' },
                  { label: 'Other', value: 'Other' },
                  { label: 'Withhold / Decline to Disclose', value: 'Withhold / Decline to Disclose' },
                ]}
                onChange={(value) => setFormValues({ ...formValues, gender: value })}
                value={formValues.gender}
              />
            </Grid>
            <Grid item xs={6}>
              <SelectInput
                label="Do you identify as transgender?"
                choices={[
                  { label: 'Yes', value: 'Yes' },
                  { label: 'No', value: 'No' },
                  { label: 'Not Sure', value: 'Not Sure' },
                  { label: 'Prefer Not to Say', value: 'Prefer Not to Say' },
                ]}
                onChange={(value) => setFormValues({ ...formValues, transgender_identity: value })}
                value={formValues.transgender_identity}
              />
            </Grid>

            <Grid item xs={6}>
              <SelectInput
                label="Sexual Orientation"
                choices={[
                  { label: 'Gay', value: 'Gay' },
                  { label: 'Lesbian', value: 'Lesbian' },
                  { label: 'Bisexual', value: 'Bisexual' },
                  { label: 'Heterosexual', value: 'Heterosexual' },
                  { label: 'Queer', value: 'Queer' },
                  { label: 'Other', value: 'Other' },
                  { label: 'Withhold / Decline to Disclose', value: 'Withhold / Decline to Disclose' },
                ]}
                onChange={(value) => setFormValues({ ...formValues, sexuality: value })}
                value={formValues.sexuality}
              />
            </Grid>
            <Grid item xs={6}>
              <SelectInput
                label="Veteran Status"
                choices={[
                  { label: 'I am Active Duty in the U.S. Military', value: 'I am Active Duty in the U.S. Military' },
                  { label: 'I have served in the U.S. Military', value: 'I have served in the U.S. Military' },
                  { label: 'I am a U.S. Military Spouse', value: 'I am a U.S. Military Spouse' },
                  { label: 'I have not served in the U.S. Military', value: 'I have not served in the U.S. Military' },
                  { label: 'Withhold / Decline to Disclose', value: 'Withhold / Decline to Disclose' },
                ]}
                onChange={(value) => setFormValues({ ...formValues, veteran_status: value })}
                value={formValues.veteran_status}
              />
            </Grid>
          </Grid>
          <br />
        </Grid>
      </Grid>
      <br />
    </FormLayout>
  );
};

export default InsighterForm;
