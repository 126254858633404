import React from 'react';
import { ActionList, TopBar } from '@shopify/polaris';
import UserMenu from './UserMenu';
import Logo from '../../../assets/logo.png';

interface TopNavigationProps {
  mobileNavigationActive: boolean;
  setMobileNavigationActive: any;
}

const TopNavigation: React.FC<TopNavigationProps> = (props) => {
  const [searchActive, setSearchActive] = React.useState<boolean>(false);
  const [searchValue, setSearchValue] = React.useState('');
  const { mobileNavigationActive, setMobileNavigationActive } = props;

  const toggleMobileNavigationActive = () => {
    setMobileNavigationActive(!mobileNavigationActive);
  };

  const handleSearchResultsDismiss = React.useCallback(() => {
    setSearchActive(false);
    setSearchValue('');
  }, []);

  const handleSearchFieldChange = React.useCallback((value) => {
    setSearchValue(value);
    setSearchActive(value.length > 0);
  }, []);

  const searchResultsMarkup = (
    <ActionList items={[{ content: 'Frequently Asked Questions' }, { content: 'Projects' }]} />
  );

  const contextControlMarkup = (
    <div>
      <img alt="logo" style={{ height: 40, margin: '10px 30px' }} src={Logo} />
    </div>
  );

  const searchFieldMarkup = (
    <>{/* <TopBar.SearchField onChange={handleSearchFieldChange} value={searchValue} placeholder="Search" /> */}</>
  );

  return (
    <TopBar
      showNavigationToggle
      userMenu={<UserMenu />}
      searchResultsVisible={searchActive}
      searchField={searchFieldMarkup}
      searchResults={searchResultsMarkup}
      onSearchResultsDismiss={handleSearchResultsDismiss}
      onNavigationToggle={toggleMobileNavigationActive}
      contextControl={contextControlMarkup}
      searchResultsOverlayVisible
    />
  );
};

export default TopNavigation;
