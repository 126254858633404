import React from 'react';
import { useHistory } from 'react-router-dom';
import ConfirmCancelModal from '../../modal/components/MaterialModal';
import axios from '../../utils/axios.utils';

interface DeleteEventResourceConfirmModalProps {
  eventResourceToDelete?: string;
  deleteEventResourceConfirmModalOpen?: boolean;
  setDeleteEventResourceConfirmModalOpen?: any;
}

const DeleteEventResourceConfirmModal: React.FC<DeleteEventResourceConfirmModalProps> = (props) => {
  const history = useHistory();

  const { eventResourceToDelete, deleteEventResourceConfirmModalOpen, setDeleteEventResourceConfirmModalOpen } = props;

  const handleDeleteEventResource = async () => {
    try {
      await axios.delete(`event_resources/${eventResourceToDelete}`);
      history.push('/admin/event-resources');
    } catch (error: any) {
      console.log(error);
    }
  };

  return (
    <ConfirmCancelModal
      isOpen={deleteEventResourceConfirmModalOpen}
      title="Delete EventResource"
      onCancel={() => setDeleteEventResourceConfirmModalOpen(false)}
      onWarning={() => handleDeleteEventResource()}
      warningLabel="Delete"
      cancelLabel="Cancel"
      hasWarningButton
      hasCancelButton
      hasFooterBar={false}
      height="250px"
    >
      <div style={{ marginTop: '20px', marginBottom: '20px' }}>
        <p>Are you sure you want to delete this event?</p>
      </div>
    </ConfirmCancelModal>
  );
};

export default DeleteEventResourceConfirmModal;
