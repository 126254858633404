import React from 'react';
import { Grid } from '@material-ui/core';
import HowWeBecameCloud from '../components/HowWeBecameCloud';

const AboutLandingSection: React.FC = () => {
  return (
    <Grid container>
      <Grid item xs={12} md={7} style={{ marginTop: 80, display: 'block', alignItems: 'center' }}>
        <HowWeBecameCloud />
      </Grid>
      <Grid item xs={12} md={5} style={{ textAlign: 'right' }}>
        <img
          src="https://cdn.theinsighters.com/AboutHero.png"
          alt="Hero"
          style={{ width: '100%', height: 'auto', maxHeight: 650, maxWidth: 650, display: 'inline-block' }}
        />
      </Grid>
    </Grid>
  );
};

export default AboutLandingSection;
