import React from 'react';
import { Card, DropZone, Layout } from '@shopify/polaris';
import Page from '../../shared/components/Page';
import OpportunityForm from '../components/OpportunityForm';
import BackButton from '../../navigation/components/BackButton';

const EditOpportunity: React.FunctionComponent = () => {
  return (
    <>
      <BackButton pageAction={false} />
      <Page fullWidth title="Create New Opportunity">
        <Layout>
          <Layout.Section>
            <Card sectioned>
              <OpportunityForm opportunity={{}} />
            </Card>
            <Card title="Media" sectioned>
              <DropZone onDrop={() => null}>
                <DropZone.FileUpload />
              </DropZone>
            </Card>
          </Layout.Section>
          <Layout.Section secondary />
        </Layout>
      </Page>
    </>
  );
};

export default EditOpportunity;
