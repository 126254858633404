import React from 'react';
import { useHistory } from 'react-router-dom';
import axios from '../../utils/axios.utils';
import ConfirmCancelModal from '../../modal/components/MaterialModal';

interface CopyOpportunityConfirmModalProps {
  opportunityId?: string;
  copyOpportunityConfirmModalOpen?: boolean;
  setCopyOpportunityConfirmModalOpen?: any;
}

const CopyOpportunityConfirmModal: React.FC<CopyOpportunityConfirmModalProps> = (props) => {
  const history = useHistory();

  const { opportunityId, copyOpportunityConfirmModalOpen, setCopyOpportunityConfirmModalOpen } = props;

  const copyOpportunity = async () => {
    let response = await axios.put<string, any>(`opportunities/${opportunityId}/copy`);
    let newResponseId = response.data.result.id;
    history.push(`/admin/opportunities/${newResponseId}`);
  };

  return (
    <ConfirmCancelModal
      isOpen={copyOpportunityConfirmModalOpen}
      title="Copy Opportunity"
      onCancel={() => setCopyOpportunityConfirmModalOpen(false)}
      onWarning={() => copyOpportunity()}
      warningLabel="Copy"
      cancelLabel="Cancel"
      hasWarningButton
      hasCancelButton
      hasFooterBar={false}
      height="250px"
    >
      <div style={{ marginTop: '20px', marginBottom: '20px' }}>
        <p>Are you sure you want to copy this opportunity?</p>
      </div>
    </ConfirmCancelModal>
  );
};

export default CopyOpportunityConfirmModal;
