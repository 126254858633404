import React from 'react';
import { Card } from '@shopify/polaris';
import { Grid, Typography } from '@mui/material';

const HiringInfo: React.FunctionComponent = () => {
  return (
    <>
      <Grid container>
        <Grid xs={10} style={{ marginLeft: 30, marginTop: 30 }}>
          <Card>
            <Grid style={{ padding: 10 }}>
              <Typography variant="h3">Recruiting & Engagement Manager (Remote, U.S.)</Typography>
              <br />
              You’re experienced in healthcare insights research recruitment—both for HCPs and consumers. But you’re
              also so much more than that. You are able to marry strategic thinking and creative aptitude in order to
              create meaningful, memorable, and measurably impactful engagement strategies for health/online
              communities. You set a high bar for the quality of your work, & you’re able to pivot on a dime for
              fast-moving projects. You’re already familiar with current industry tools like Qualtrics or Calendly,
              while always being up for trying out new tech & techniques.
            </Grid>
          </Card>
          <Card>
            <Grid style={{ padding: 10 }}>
              <Typography variant="h4">Job Summary </Typography>
              Sommer Consulting is seeking a Recruiting & Engagement Manager to join The Insighters® platform team. As a
              Sommer Consulting Recruiting & Engagement Manager on the The Insighters® team, you will be responsible for
              identifying and recruiting top respondents for our market research projects in the healthcare industry via
              our proprietary platform TheInsighters.com. Your primary goal will be to attract and retain the gamut of
              healthcare stakeholders—from hospital CEOs & physicians to pharmacy directors & nurses, to hard-to-reach
              patients & communities. You will join The Insighters® team to identify and develop sourcing strategies for
              respondents who might be a good fit for our current & future insights research projects.
            </Grid>
          </Card>
          <Card>
            <Grid style={{ padding: 10 }}>
              <Typography variant="h4">Key Responsibilities</Typography>
              <ul>
                <li>
                  Join The Insighters® team to develop and implement recruitment strategies to support insights research
                  projects in the healthcare industry
                </li>
                <li>
                  Build a network of HCP & consumer communities via various sourcing channels such as LinkedIn, industry
                  conferences, online communities, and referrals, & maintain via creative engagement strategies
                </li>
                <li>
                  Build, launch, manage, & wrap up your recruitment projects from an operations perspective in our
                  proprietary platform
                </li>
                <li>
                  Build, launch, & manage screeners to potential respondents, evaluating their responses to ensure they
                  meet the requirements of each research project
                </li>
                <li>
                  Stay up to date with recruitment trends and developments to ensure we remain competitive in the health
                  insights recruitment market
                </li>
                <li>
                  Build and maintain strong relationships with communities represented by our platform, as well as grow
                  new communities to join our platform.
                </li>
              </ul>
            </Grid>
          </Card>

          <Card>
            <Grid style={{ padding: 10 }}>
              <Typography variant="h4">Qualifications</Typography>
              <ul>
                <li>A bachelor&apos;s degree in business administration, marketing, or related field</li>
                <li>3+ years of experience in recruitment in the insights/market research industry</li>

                <li>1+ years of experience in Qualtrics programming and management</li>

                <li>Speed & ability to juggle multiple projects in a 100% remote role</li>

                <li>
                  Strong knowledge of healthcare market research and the skills required for recruitment success in this
                  field
                </li>
                <li>
                  Demonstrated ability to build and maintain a network across the gamut of healthcare stakeholders
                </li>
                <li>
                  Proven record of crafting engagement strategies that keep respondents in your pipeline engaged and
                  ready for the next insights research project
                </li>
                <li>Excellent communication and interpersonal skills</li>
                <li>Enthusiasm to learn & implement new technologies</li>
                <li>
                  The ability to work remotely, independently and in a team environment—we’re a very social bunch over
                  here!
                </li>

                <li>Strong attention to detail and organizational skills.</li>
              </ul>
            </Grid>
          </Card>
          <Card>
            <Grid style={{ padding: 10 }}>
              <span style={{ fontWeight: 'bold' }}>
                The salary range for this position is $60,000 up to $80,000. The position is 100% remote, but will be
                based in the U.S. As a result, you must be a U.S. Citizen or authorized to work in the United States.
                Company business is generally conducted in Eastern Standard Time.
              </span>
              <br />
              <br />
              Sommer Consulting is an equal opportunity employer. We deeply value diversity and believe that a diverse
              workforce brings unique perspectives and ideas that are essential for success. We are committed to
              creating an inclusive environment where all employees feel valued and respected regardless of their race,
              national origin, gender, sexual orientation or identity, religion, age, disability, background, or any
              other personal characteristic or familial relationship protected by applicable law. We actively seek to
              hire individuals from diverse backgrounds and are dedicated to providing equal opportunities for all.
              <br />
              <br />
              We offer a competitive salary and benefits package, including health insurance, 401K, generous PTO,
              parental leave, & other fun perks. If you have a passion for healthcare market research recruitment and
              are looking for a dynamic and challenging role, please apply today.
              <br />
              <br />
              <a href="https://sommer.iad1.qualtrics.com/jfe/form/SV_br9veTTh98NC3n8">
                Please securely upload your resume and optional materials here
              </a>
              <br />
              <br />
              <span style={{ fontStyle: 'italic' }}>
                About Us: The Insighters® is a healthcare research recruitment platform owned by Sommer Consulting, a
                minority and woman-owned small business with 40 years of experience in healthcare & life sciences
                insights research. The Insighters® platform matches you with paid research opportunities that are
                tailored to your insights. We make it easier for you to be at the forefront of industry, share your
                expertise, & get paid. Learn more at theinsighters.com or follow us on social at @theinsighters_.
              </span>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default HiringInfo;
