import React, { FC, ReactNode, useState, useEffect, useContext } from 'react';
import { Button, Card } from '@shopify/polaris';
import { useHistory } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import DataTable from '../../dataTable/DataTable/DataTable';
import { IDataTableColumn } from '../../dataTable/DataTable/model';
import { InvitationOpportunity } from '../../invitations/model';
import InvitationPartnersFilterBar from './InvitationPartnersFilterBar';
import { InvitationPartnersContext } from '../contexts/InvitationPartnersContext';
import { UserContext } from '../../auth/contexts/UserContext';

const getStatusColor = (status: string) => {
  if (status === 'failed') {
    return 'red';
  }

  if (status === 'complete') {
    return 'green';
  }

  return '#4ba9c8';
};

export const invitationPartnerColumns: IDataTableColumn<any>[] = [
  {
    name: 'Insighter Name',
    selector: 'insighter_name',
    sortable: true,
    cell: (row) => (
      <div>
        <a style={{ color: '#1D1D1D' }} href={`/admin/insighters/${row.insighter_id}`}>
          {row.insighter_name}
        </a>
      </div>
    ),
  },
  {
    name: 'Insighter Email',
    selector: 'email',
    sortable: true,
  },
  {
    name: 'Amount Per Invitation',
    selector: 'inviter_amount',
    sortable: true,
    cell: ({ inviter_amount }): ReactNode => <div>${inviter_amount}</div>,
  },
  {
    name: 'Invitations Completed',
    selector: 'invitations_completed',
    sortable: true,
  },
  {
    name: 'Invitation Quota',
    selector: 'invitation_quota',
    sortable: true,
  },
  {
    name: 'Invitations Remaining',
    selector: 'invitations_remaining',
    // sortable: true,
  },
  {
    name: 'Total Invitation Payout',
    selector: 'total_invitation_payout',
    // sortable: true,
    cell: ({ total_invitation_payout }): ReactNode => <div>${total_invitation_payout}</div>,
  },
  {
    name: 'Email Delivered',
    selector: 'email_sent',
    sortable: true,
  },
  {
    name: 'Status',
    selector: 'status',
    // sortable: true,
    cell: ({ status }) => (
      <div
        style={{
          fontWeight: 700,
          textTransform: 'uppercase',
          fontSize: 14,
          color: getStatusColor(status),
        }}
      >
        {status}
      </div>
    ),
  },
];

interface InvitationPartnersProps {
  invitationOpportunity: InvitationOpportunity;
}

const InvitationPartners: FC<InvitationPartnersProps> = (props) => {
  const {
    invitationPartners,
    fetchInvitationPartners,
    invitationPartnerLoading,
    totalRecords,
    invitationPartnersDownload,
    filterValues,
    setFilterValues,
    updateFilterValues,
  } = useContext(InvitationPartnersContext);
  let { currentUserHasRole } = useContext(UserContext);
  const [invitationPartnersFetched, setinvitationPartnersFetched] = useState(false);
  const { invitationOpportunity } = props;
  const history = useHistory();

  useEffect(() => {
    updateFilterValues(invitationOpportunity?.id);

    if (!invitationPartnersFetched && invitationOpportunity?.id) {
      let invitationPartnerFilters = sessionStorage.getItem(`invitationPartnerFilters-${invitationOpportunity?.id}`);

      if (!invitationPartnerFilters) {
        invitationPartnerFilters = `ransack[invitation_opportunity_id_in]=${invitationOpportunity.id}`;
      }
      fetchInvitationPartners(invitationPartnerFilters);
      setinvitationPartnersFetched(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invitationPartnersFetched, invitationOpportunity]);

  const handlePageChange = (page: number) => {
    const pageParams = {
      page: `page=${page}`,
      queryFilter: `ransack[query]=${filterValues.query}`,
      invitationOpportunityFilter: `ransack[invitation_opportunity_id_in]=${invitationOpportunity.id}`,
      sort: `sort=${filterValues.sort}`,
    };

    fetchInvitationPartners(Object.values(pageParams).join('&'));
  };

  const handleColumnSort = async (column: Record<string, string>, sortDirection: string) => {
    const columnNamesToRansack = {
      insighter_name: 'insighter_user_last_name',
      email: 'insighter_user_email',
      invitations_completed: 'number_of_invitations',
      inviter_amount: 'inviter_amount',
      invitation_quota: 'invitation_quota',
      email_sent: 'email_sent',
    };

    const sortKey = columnNamesToRansack[column.selector];
    setFilterValues({ ...filterValues, sort: `${sortKey} ${sortDirection}` });

    const filterParams = {
      queryFilter: `ransack[query]=${filterValues.query}`,
      invitationOpportunityFilter: `ransack[invitation_opportunity_id_in]=${invitationOpportunity.id}`,
      sort: `sort=${sortKey} ${sortDirection}`,
    };

    fetchInvitationPartners(Object.values(filterParams).join('&'));
  };

  return (
    <>
      <Card sectioned>
        <div style={{ marginBottom: 15 }}>
          <div className="Polaris-Stack Polaris-Stack--alignmentBaseline">
            <div className="Polaris-Stack__Item Polaris-Stack__Item--fill">
              <h2 className="Polaris-Heading">Invitation Partners</h2>
            </div>
            <div className="Polaris-Stack__Item">
              <div className="Polaris-ButtonGroup">
                <div className="Polaris-ButtonGroup__Item Polaris-ButtonGroup__Item--plain">
                  {currentUserHasRole('admin') && (
                    <Button
                      primary
                      url={`/admin/invitation-opportunities/${invitationOpportunity.id}/invitation-partners/new`}
                    >
                      Add New
                    </Button>
                  )}
                  &emsp;
                  {invitationPartnersDownload && (
                    <CSVLink
                      data={invitationPartnersDownload}
                      filename={`Invitation Partners for ${invitationOpportunity.name}`}
                      style={{ textDecoration: 'none', marginTop: 25, marginRight: 15 }}
                    >
                      <Button primary>Download Partners</Button>
                    </CSVLink>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <InvitationPartnersFilterBar />
        <DataTable
          columns={invitationPartnerColumns}
          data={invitationPartners}
          pagination
          striped
          highlightOnHover
          pointerOnHover
          noHeader
          onRowClicked={(row: Record<string, string>) =>
            history.push(
              `/admin/invitation-opportunities/${invitationOpportunity.id}/invitation-partners/${row.id}/edit`,
            )
          }
          paginationServer
          progressPending={invitationPartnerLoading}
          paginationPerPage={10}
          paginationRowsPerPageOptions={[10]}
          paginationTotalRows={totalRecords}
          onChangePage={handlePageChange}
          sortServer
          onSort={handleColumnSort}
        />
      </Card>
    </>
  );
};

export default InvitationPartners;
