import React, { useState } from 'react';
import { Grid, Typography, Accordion, AccordionSummary, AccordionDetails, makeStyles } from '@material-ui/core';
import { ArrowForwardIosSharp } from '@mui/icons-material';
import MediumOrnament from '../../common/MediumOrnament';
import useStyles from '../../constants/redesignStyles';
import QuestionMark from '../../../../assets/redesignAssets/staticImages/QuestionMark.png';
import HoverButton from '../../common/HoverButton';

// Tried to add the You have questions text as an image, but it turns out pixellated, with low quality.

const FAQs = [
  {
    question: 'What is The Insighters®?',
    answer:
      'The Insighters® is a platform that connects you to paid opportunities sponsored by companies in healthcare, life sciences, & technology.',
    backgroundColor: '#C2D9D3',
    circleColor: '#488C7A',
  },
  {
    question: 'Who am I helping when I join The Insighters ®?',
    answer:
      'Others like you! You will directly impact the next generation of healthcare treatment, technology, & information development.',
    backgroundColor: '#C2D9D3',
    circleColor: '#488C7A',
  },
  {
    question: 'Why should I fill out an Insighter profile?',
    answer:
      'Your profile is how we connect you to paid opportunities that might be a good fit. Plus, completely filling out your profile with The Insighters® gives you special access to more paid opportunities!',
    backgroundColor: '#BADEE9',
    circleColor: '#6CAEC3',
  },
  {
    question: 'How is my information kept safe?',
    answer:
      'When it comes to privacy, we take your data protection very seriously & uphold strict industry standards—both through The Insighters® platform and during your entire research experience.',
    backgroundColor: '#BADEE9',
    circleColor: '#6CAEC3',
  },
  {
    question: 'How do I get paid?',
    answer: 'We offer your payment choice of Paypal, Venmo, direct deposit, gift cards, and more!',
    backgroundColor: '#F0D7D3',
    circleColor: '#F48574',
  },
  {
    question: 'When do I get paid?',
    answer: 'After you complete a paid opportunity, we move fast—payments are usually sent in 2-3 business days.',
    backgroundColor: '#F0D7D3',
    circleColor: '#F48574',
  },
];

const useCustomStyles = makeStyles((theme) => ({
  faqQuestion: {
    [theme.breakpoints.down(960)]: {
      fontSize: '18px',
    },
  },
}));

const HowItWorksAlt: React.FC = () => {
  const classes = useStyles();
  const customClasses = useCustomStyles();

  const [expanded, setExpanded] = useState<string>('panel1');

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} lg={1} />
      <Grid item xs={12} md={6} lg={4} style={{ maxWidth: 300, margin: '4% auto' }}>
        <Grid container style={{ display: 'flex', alignItems: 'flex-start' }} direction="column">
          <Grid item xs={12}>
            <MediumOrnament text="How It Works" centerComponent={false} width="200px" centerText />
          </Grid>
          <Grid item xs={12}>
            <Typography style={{ fontWeight: 600 }} className={classes.h2}>
              You have questions?
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography style={{ color: '#488C7A', fontWeight: 600 }} className={classes.h2}>
              We have answers.
            </Typography>
          </Grid>
          <Grid item style={{ marginTop: 15 }}>
            <HoverButton text="Got a Question?" link="/frequently-asked-questions" />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={6} lg={5} style={{ margin: 10 }}>
        <div>
          {FAQs.map((faq, index) => (
            <Accordion
              expanded={expanded === `panel${index + 1}`}
              onChange={() => setExpanded(expanded === `panel${index + 1}` ? null : `panel${index + 1}`)}
              style={{
                marginTop: 0,
                marginBottom: 0,
                backgroundColor: faq.backgroundColor,
                borderRadius: (() => {
                  if (index === 0) return '20px 20px 0 0';
                  if (index === FAQs.length - 1) return '0 0 20px 20px';
                  return '0';
                })(),
              }}
              key={faq.question}
            >
              <AccordionSummary
                expandIcon={
                  <ArrowForwardIosSharp
                    sx={{
                      transform: expanded === `panel${index + 1}` ? 'rotate(90deg)' : 'rotate(0deg)',
                      transition: 'transform 0.3s ease',
                    }}
                  />
                }
              >
                <Grid container style={{ display: 'flex', alignItems: 'center' }}>
                  <Grid
                    item
                    style={{
                      backgroundColor: faq.circleColor,
                      height: '50px',
                      width: '50px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      position: 'relative',
                      borderRadius: '50%',
                      marginLeft: 15,
                      marginRight: 15,
                    }}
                  >
                    <img src={QuestionMark} alt="Question Mark" style={{ width: '24px', height: '32px' }} />
                  </Grid>
                  <Grid item>
                    <Typography className={`${classes.h3} ${customClasses.faqQuestion}`}>{faq.question}</Typography>
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails style={{ padding: 20 }}>
                <Typography variant="h4" style={{ fontSize: '16px', maxWidth: '80%', margin: '20px auto' }}>
                  {faq.answer}
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      </Grid>
      <Grid item xs={12} lg={2} />
    </Grid>
  );
};

export default HowItWorksAlt;
