import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from '../../utils/axios.utils';
import { InsighterRole, InsighterRoleFormValues } from '../model';

interface InsighterRolesContextInterface {
  fetchInsighterRoles?: (params?: string) => void;
  fetchInsighterRole?: (insighterRole_id: string) => void;
  insighterRolesLoading?: boolean;
  insighterRole?: InsighterRole;
  insighterRoles?: InsighterRole[];

  updateInsighterRole?: (insighterRole_id: string, formValues: InsighterRoleFormValues) => void;
  createInsighterRole?: (formValues: InsighterRoleFormValues) => void;
  insighterRoleSubmitting?: boolean;
  formError?: string;
}

const InsighterRolesContext = React.createContext<InsighterRolesContextInterface>({});

const InsighterRolesContextConsumer = InsighterRolesContext.Consumer;
const InsighterRolesContextProvider: React.FC = ({ children }) => {
  const history = useHistory();

  const [insighterRoles, setInsighterRoles] = useState<InsighterRole[]>([]);
  const [insighterRole, setInsighterRole] = useState<InsighterRole>({});
  const [insighterRolesLoading, setInsighterRolesLoading] = useState<boolean>(false);
  const [insighterRoleSubmitting, setInsighterRoleSubmitting] = useState(false);
  const [formError, setFormError] = useState<string>(undefined);

  const fetchInsighterRoles = async (params = '') => {
    setInsighterRolesLoading(true);

    const response = await axios.get<string, any>(`insighter_roles?${params}`);

    setInsighterRolesLoading(false);
    setInsighterRoles(response?.data?.result);
  };

  const fetchInsighterRole = async (insighterRole_id: string) => {
    setInsighterRolesLoading(true);

    const response = await axios.get<string, any>(`insighter_roles/${insighterRole_id}`);

    setInsighterRolesLoading(false);
    setInsighterRole(response?.data?.result);
  };

  const createInsighterRole = async (formValues: InsighterRoleFormValues) => {
    try {
      setInsighterRoleSubmitting(true);
      axios
        .post<string, any>(`insighter_roles`, {
          insighter_role: { ...formValues },
        })
        .then(() => {
          setInsighterRoleSubmitting(false);
          history.push('/admin/insighter-roles');
        })
        .catch((error) => {
          setInsighterRoleSubmitting(false);
          setFormError(error.response.data.message);
        });
    } catch {
      setInsighterRoleSubmitting(false);
      setFormError('Something went wrong, please reload the page and try again.');
    }
  };

  const updateInsighterRole = async (insighterRole_id: string, formValues: InsighterRoleFormValues) => {
    setInsighterRoleSubmitting(true);

    await axios.put<string, any>(`insighter_roles/${insighterRole_id}`, {
      insighter_role: { ...formValues },
    });

    setInsighterRoleSubmitting(false);
    history.push('/admin/insighter-roles');
  };

  return (
    <InsighterRolesContext.Provider
      value={{
        fetchInsighterRoles,
        fetchInsighterRole,
        insighterRolesLoading,
        insighterRole,
        insighterRoles,
        updateInsighterRole,
        createInsighterRole,
        insighterRoleSubmitting,
        formError,
      }}
    >
      {children}
    </InsighterRolesContext.Provider>
  );
};

export { InsighterRolesContextProvider, InsighterRolesContextConsumer, InsighterRolesContext };
