import React from 'react';
import FormInput from '../../form/components/FormInput';
import { InsighterNotesContext } from '../contexts/InsighterNotesContext';
import { Insighter } from '../model';
import ConfirmCancelModal from '../../modal/components/MaterialModal';

interface InsighterNoteNewModalProps {
  isOpen: boolean;
  setOpen: any;
  insighter: Insighter;
}

const InsighterNoteNewModal: React.FC<InsighterNoteNewModalProps> = (props) => {
  const { isOpen, setOpen, insighter } = props;
  const { createInsighterNote } = React.useContext(InsighterNotesContext);
  const [description, setDescription] = React.useState<string>();

  return (
    <ConfirmCancelModal
      isOpen={isOpen}
      title={`Submit Note for ${insighter?.name}`}
      onCancel={() => setOpen(false)}
      onConfirm={() => createInsighterNote({ insighter_id: insighter.id, description })}
      confirmLabel="Submit"
      cancelLabel="Cancel"
      hasConfirmButton
      hasCancelButton
      hasFooterBar={false}
      height="350px"
    >
      <>
        <p style={{ marginTop: '20px' }}>
          Please submit any notes you have about the respondent and your session with them. Only other moderators and
          admins will be able to see these notes.
        </p>
        <br />
        <FormInput
          label={null}
          value={description}
          onChange={(value: string) => setDescription(value)}
          multiline
          rows={4}
        />
      </>
    </ConfirmCancelModal>
  );
};

export default InsighterNoteNewModal;
