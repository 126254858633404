import React, { useState, useContext, useEffect } from 'react';
import { Button, FormLayout } from '@shopify/polaris';
import { Grid, Typography } from '@material-ui/core';
import FormInput from '../../form/components/FormInput';
import { EthnicitiesContext } from '../contexts/EthnicitiesContext';
import { Ethnicity } from '../model';

interface EthnicityFormProps {
  ethnicity?: Ethnicity;
}

const EthnicityForm: React.FC<EthnicityFormProps> = (props) => {
  const { ethnicity } = props;
  const { formError, updateEthnicity, ethnicitySubmitting } = useContext(EthnicitiesContext);
  const [inputList, setInputList] = useState(['Label 1', 'Label 2']);

  useEffect(() => {
    if (ethnicity) {
      const newAltLabels = [];
      ethnicity.alternate_labels?.forEach((label) => newAltLabels.push(label)); // Iterate manually because spread notation [...] can't be used on an imported object attribute
      newAltLabels.push(' '); // Add blank entry at the end

      setInputList(newAltLabels);
    }
  }, [ethnicity]);

  const handleUpdate = () => {
    updateEthnicity(ethnicity.id, inputList);
  };

  const handleInputChange = (e, index) => {
    const { value } = e.target;
    const list = [...inputList];
    list[index] = value;
    setInputList(list);
  };

  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  const handleAddClick = () => {
    setInputList([...inputList, '']);
  };

  return (
    <FormLayout>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h3">{ethnicity.name}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4">Descriptions</Typography>
        </Grid>
        <p>If an Insighter chooses any of the following descriptions in Qualtrics, </p>
        <p>they will be associated with this ethnicity.</p>

        <p>The primary label of the ethnicity (above) will also work.</p>
        <br />
        {inputList.map((x, i) => {
          return (
            // Using a dummy key for now because using a proper key causes the form to lose focus after every character input.
            <Grid item xs={12} key="Yes">
              <input
                name="firstName"
                placeholder="Enter First Name"
                value={x}
                onChange={(e) => handleInputChange(e, i)}
              />
              <div className="btn-box">
                {inputList.length !== 1 && (
                  <button type="button" className="mr10" onClick={() => handleRemoveClick(i)}>
                    Remove
                  </button>
                )}
              </div>
            </Grid>
          );
        })}
      </Grid>
      <Button onClick={handleAddClick}>Add Another Description</Button>

      <Button primary onClick={handleUpdate} loading={ethnicitySubmitting}>
        Update Descriptions
      </Button>
      {formError && <p style={{ color: 'red', fontSize: '14px', marginTop: 20 }}>{formError}</p>}
    </FormLayout>
  );
};

export default EthnicityForm;
