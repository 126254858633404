import React, { FunctionComponent, useState, useEffect, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Badge, Card, Loading } from '@shopify/polaris';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import PaymentHistory from '../components/PaymentHistory';
import ProjectHistory from '../components/ProjectHistory';
import AssociatedAccounts from '../components/AssociatedAccounts';
import DetailPageSkeleton from '../../loading/components/DetailPageSkeleton';
import { InsightersContext } from '../contexts/InsightersContext';
import InsighterNoteForm from '../components/InsighterNoteNewModal';
import InsighterNoteEditModal from '../components/InsighterNoteEditModal';
import AdminPageHeader from '../../adminTools/components/AdminPageHeader';

import { UserContext } from '../../auth/contexts/UserContext';

const InsighterDetail: FunctionComponent = () => {
  const { insighterId } = useParams<Record<string, string>>();
  const { insighter, fetchInsighter, insighterLoading } = useContext(InsightersContext);
  let { currentUserHasRole } = useContext(UserContext);
  const [insighterFetched, setInsighterFetched] = useState(false);
  const [healthcareProviderStatus, setHealthCareProviderStatus] = useState(false);
  const [healthcareProviderStatusVerified, setHealthCareProviderStatusVerified] = useState(false);
  const [noteModalOpen, setNoteModalOpen] = useState(false);
  const [editNoteModalOpen, setEditNoteModalOpen] = useState(false);
  const [activeNote, setActiveNote] = useState(null);
  const history = useHistory();

  useEffect(() => {
    if (!insighterFetched) {
      fetchInsighter(insighterId);
      setInsighterFetched(true);
    }
  }, [insighterFetched, fetchInsighter, insighterId]);

  useEffect(() => {
    if (insighter?.insighter_roles?.length > 0 && !healthcareProviderStatusVerified) {
      insighter?.insighter_roles?.forEach((role) => {
        if (role.hcp_role) {
          setHealthCareProviderStatus(true);
        }
      });
      setHealthCareProviderStatusVerified(true);
    }
  }, [insighter, healthcareProviderStatusVerified]);

  const loadingMarkup = insighterLoading ? <Loading /> : null;

  const getBadgeStatus = {
    green: 'success',
    orange: 'warning',
    red: 'critical',
    grey: 'default',
  };

  const badges = insighter?.insighter_tags?.map((insighterTag, index) => (
    <Badge key={index} status={getBadgeStatus[insighterTag?.badge_color]}>
      {insighterTag.name}
    </Badge>
  ));

  const BadgeContainer: FunctionComponent = () => {
    if (badges?.length > 0) {
      return <div style={{ display: 'flex', gap: '7px' }}>{badges}</div>;
    }
    return <Badge>None</Badge>;
  };

  const InsighterProfileEditButtons = () => {
    return (
      <>
        <Button className="secondary-btn" onClick={() => history.push(`/admin/insighters/${insighter?.id}/view`)}>
          View as Insighter
        </Button>
        <Button className="primary-btn" onClick={() => history.push(`/admin/insighters/${insighter?.id}/edit`)}>
          Edit
        </Button>
      </>
    );
  };

  // ---- Page markup ----
  const actualPageMarkup = (
    <Grid container style={{ padding: '1% 2%' }}>
      <AdminPageHeader
        title={`${insighter?.name} (${insighter?.uuid})`}
        adminButtons={<InsighterProfileEditButtons />}
      />
      <BadgeContainer />
      <Grid item xs={12} style={{ marginBottom: 15 }} />
      <InsighterNoteForm isOpen={noteModalOpen} setOpen={setNoteModalOpen} insighter={insighter} />
      <InsighterNoteEditModal
        isOpen={editNoteModalOpen}
        setOpen={setEditNoteModalOpen}
        insighter={insighter}
        activeNote={activeNote}
        setActiveNote={setActiveNote}
      />
      {insighter?.archived_profile ? (
        <Grid container spacing={3}>
          <Grid item xs={8}>
            <Card sectioned title="Basic Information">
              <Grid container>
                <Grid item xs={6} lg={3}>
                  <Card.Section title="Name">
                    <p>{insighter?.name}</p>
                  </Card.Section>
                </Grid>
                <Grid item xs={6} lg={3}>
                  <Card.Section title="User ID">
                    <p>{insighter?.uuid}</p>
                  </Card.Section>
                </Grid>
                <Grid item xs={6} lg={3}>
                  <Card.Section title="Account Status">
                    <p style={{ fontWeight: 700, color: 'red' }}>OUTSIDE RESPONDENT</p>
                  </Card.Section>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={3} style={{ marginBottom: 15 }}>
          <Grid item xs={8}>
            <Card sectioned title="Basic Information">
              <Grid container>
                <Grid item xs={6} lg={3}>
                  <Card.Section title="Name">
                    <p>{insighter?.name}</p>
                  </Card.Section>
                </Grid>
                <Grid item xs={6} lg={3}>
                  <Card.Section title="User ID">
                    <p>{insighter?.uuid}</p>
                  </Card.Section>
                </Grid>
                <Grid item xs={6} lg={3}>
                  <Card.Section title="Role">
                    <p>{insighter?.insighter_role_names?.join(', ')}</p>
                  </Card.Section>
                </Grid>
                <Grid item xs={6} lg={3} />
                <Grid item xs={6} lg={3}>
                  <Card.Section title="Email">
                    <p style={{ wordWrap: 'break-word' }}>{insighter?.email}</p>
                  </Card.Section>
                </Grid>
                <Grid item xs={6} lg={3}>
                  <Card.Section title="Email Valid?">
                    <p>{insighter?.email_valid ? 'Yes' : 'No'}</p>
                  </Card.Section>
                </Grid>
                <Grid item xs={6} lg={3}>
                  <Card.Section title="Block From Marketing Emails">
                    <p>{insighter?.exclude_from_mailchimp ? 'Yes' : 'No'}</p>
                  </Card.Section>
                </Grid>
                <Grid item xs={6} lg={3} />
                <Grid item xs={6} lg={3}>
                  <Card.Section title="Preferred Contact Method">
                    <div style={{ whiteSpace: 'pre-line' }}>{insighter?.preferred_contact_method?.join(',\n')}</div>
                  </Card.Section>
                </Grid>
                <Grid item xs={6} lg={3}>
                  <Card.Section title="Mobile Phone">
                    <p>{insighter?.mobile_phone}</p>
                  </Card.Section>
                </Grid>
                <Grid item xs={6} lg={3}>
                  <Card.Section title="Last Active">
                    <p>{insighter?.last_active_date}</p>
                  </Card.Section>
                </Grid>
                <Grid item xs={6} lg={3}>
                  <Card.Section title="Created At">
                    <p>{insighter?.sign_up_time}</p>
                  </Card.Section>
                </Grid>
                <Grid item xs={6} />
                <Grid item xs={12}>
                  <Card.Section title="Address">
                    <p>{insighter?.address_line1 ? insighter?.address_line1 : ''}</p>
                    <p>{insighter?.address_line2 ? insighter?.address_line2 : ''}</p>
                    <p>{insighter?.address}</p>
                  </Card.Section>
                </Grid>
                {insighter?.alternate_email1 && (
                  <Grid item xs={12}>
                    <Card.Section title="Alternate Email">
                      <p>{insighter?.alternate_email1}</p>
                    </Card.Section>
                  </Grid>
                )}
                {insighter?.alternate_phone_numbers && (
                  <Grid item xs={12}>
                    <Card.Section title="Alternate Phone Numbers">
                      <p>{insighter?.alternate_phone_numbers}</p>
                    </Card.Section>
                  </Grid>
                )}
              </Grid>
            </Card>
            <Card sectioned title="Insighter Profile">
              <Grid container>
                <Grid item xs={6} lg={3}>
                  <Card.Section title="Gender">
                    <p>{insighter?.gender}</p>
                  </Card.Section>
                </Grid>
                <Grid item xs={6} lg={3}>
                  <Card.Section title="Transgender Identity">
                    <p style={{ textTransform: 'capitalize' }}>{insighter?.transgender_identity}</p>
                  </Card.Section>
                </Grid>
                <Grid item xs={6} lg={3}>
                  <Card.Section title="Ethnicities">
                    <div style={{ whiteSpace: 'pre-line' }}>{insighter?.ethnicity_names?.join(',\n')}</div>
                  </Card.Section>
                </Grid>
                <Grid item xs={6} lg={3}>
                  <Card.Section title="Sexuality">
                    <p style={{ textTransform: 'capitalize' }}>{insighter?.sexuality}</p>
                  </Card.Section>
                </Grid>
                <Grid item xs={6} lg={3}>
                  <Card.Section title="Veteran Status">
                    <p style={{ textTransform: 'capitalize' }}>{insighter?.veteran_status}</p>
                  </Card.Section>
                </Grid>
                <Grid item xs={6} lg={3}>
                  <Card.Section title="Caregiver Statuses">
                    <div style={{ whiteSpace: 'pre-line' }}>{insighter?.caregiver_statuses?.join(',\n')}</div>
                  </Card.Section>
                </Grid>
                <Grid item xs={6} lg={3}>
                  <Card.Section title="Conditions">
                    <div style={{ whiteSpace: 'pre-line' }}>{insighter?.condition_names?.join(',\n')}</div>
                  </Card.Section>
                </Grid>
              </Grid>
            </Card>
            {healthcareProviderStatus && (
              <>
                <Card sectioned title="Health Care Provider Profile">
                  <Grid container>
                    <Grid item xs={6} lg={3}>
                      <Card.Section title="NPI Number">
                        <p>{insighter?.npi_number || 'N/A'}</p>
                      </Card.Section>
                    </Grid>
                    <Grid item xs={6} lg={3}>
                      <Card.Section title="Degree">
                        {insighter?.healthcare_certification_or_degree || 'N/A'}
                      </Card.Section>
                    </Grid>
                    <Grid item xs={6} lg={3}>
                      <Card.Section title="Other Degree Response">
                        {insighter?.other_certifications || 'N/A'}
                      </Card.Section>
                    </Grid>
                    <Grid item xs={6} lg={3}>
                      <Card.Section title="States Practicing">
                        <p>{insighter?.states_practicing?.join(', ') || 'N/A'}</p>
                      </Card.Section>
                    </Grid>
                    <Grid item xs={6} lg={3}>
                      <Card.Section title="Specialties">
                        <p>{insighter?.specialty_names || 'N/A'}</p>
                      </Card.Section>
                    </Grid>
                    <Grid item xs={6} lg={3}>
                      <Card.Section title="% of Patient Care">
                        <p>{insighter?.percent_patient_care ? `${insighter?.percent_patient_care}%` : 'N/A'}</p>
                      </Card.Section>
                    </Grid>
                    <Grid item xs={6} lg={3}>
                      <Card.Section title="Residency End Year">
                        <p>{insighter?.residency_end_year || 'N/A'}</p>
                      </Card.Section>
                    </Grid>
                    <Grid item xs={6} lg={3}>
                      <Card.Section title="License Start Year">
                        <p>{insighter?.license_start_year || 'N/A'}</p>
                      </Card.Section>
                    </Grid>
                    <Grid item xs={6} lg={3}>
                      <Card.Section title="Board Certified">
                        <p>{insighter?.board_certified || 'N/A'}</p>
                      </Card.Section>
                    </Grid>
                    <Grid item xs={6} lg={3}>
                      <Card.Section title="Board Eligible">
                        <p>{insighter?.board_eligible || 'N/A'}</p>
                      </Card.Section>
                    </Grid>
                  </Grid>
                </Card>
              </>
            )}
          </Grid>
          <Grid item xs={4}>
            <Card title="Additional Information">
              <Card.Section title="Admin Notes">
                {insighter?.notes ? (
                  <>
                    {insighter?.notes.map((note) => (
                      <Box
                        key={note.id}
                        className="insighterNote"
                        onClick={() => {
                          setActiveNote(note);
                          setEditNoteModalOpen(true);
                        }}
                      >
                        <p>
                          From: <strong>{note.author}</strong>
                          <span style={{ float: 'right' }}>
                            Date: <strong>{note.created_at}</strong>
                          </span>
                        </p>
                        <p style={{ marginTop: 10 }}>
                          <strong>Note:</strong>
                          {note.description}
                        </p>
                        <br />
                      </Box>
                    ))}
                  </>
                ) : (
                  <p>No notes for this insighter.</p>
                )}
                {currentUserHasRole('admin') && (
                  <Button
                    className="primary-btn"
                    onClick={() => {
                      setNoteModalOpen(true);
                    }}
                  >
                    Add New Note
                  </Button>
                )}
              </Card.Section>
              <Card.Section title="Tags">
                <BadgeContainer />
              </Card.Section>
            </Card>
          </Grid>
        </Grid>
      )}
      <Grid item xs={12} style={{ marginBottom: 15 }}>
        <ProjectHistory interviews={insighter?.interviews} />
      </Grid>
      <Grid item xs={12} style={{ marginBottom: 15 }}>
        <PaymentHistory payments={insighter?.payments} />
      </Grid>
      <Grid item xs={12} style={{ marginBottom: 15 }}>
        <AssociatedAccounts associatedAccounts={insighter?.associated_accounts} />
      </Grid>
    </Grid>
  );

  // ---- Loading ----
  const loadingPageMarkup = <DetailPageSkeleton />;
  const pageMarkup = insighterLoading ? loadingPageMarkup : actualPageMarkup;

  return (
    <>
      {loadingMarkup}
      {pageMarkup}
    </>
  );
};

export default InsighterDetail;
