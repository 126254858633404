import React from 'react';
import { Grid, Typography, Hidden, makeStyles } from '@material-ui/core';
import HoverButton from '../../common/HoverButton';
import useStyles from '../../constants/redesignStyles';
import MediumOrnament from '../../common/MediumOrnament';
import ValuesBox from '../components/ValuesBox';

const useCustomStyles = makeStyles((theme) => ({
  centeredContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    [theme.breakpoints.down(960)]: {
      alignItems: 'flex-start',
      textAlign: 'left',
    },
  },
  h2Text: {
    fontWeight: 600,
    fontSize: '40px',
    [theme.breakpoints.down(960)]: {
      textAlign: 'center',
    },
  },
  smallText: {
    maxWidth: 450,
    lineHeight: '1.5em',
    fontWeight: 500,
    textAlign: 'left',

    [theme.breakpoints.down(960)]: {
      textAlign: 'center', // Center text on smaller screens
    },
  },
  centeredButton: {
    [theme.breakpoints.down(960)]: {
      display: 'flex',
      justifyContent: 'center',
    },
  },
  smallTextContainer: {
    [theme.breakpoints.down(960)]: {
      display: 'flex',
      justifyContent: 'center',
    },
  },
}));

const DrivingMarketResearchSection: React.FC = () => {
  const classes = useStyles();
  const customClasses = useCustomStyles();

  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    const handleResize = () => {
      const newWidth = window.innerWidth;
      if (newWidth !== windowWidth) {
        setWindowWidth(newWidth);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [windowWidth]);

  return (
    <Grid container style={{ paddingLeft: '2%', paddingRight: '2%' }}>
      <Grid item md={6}>
        <img src="https://cdn.theinsighters.com/FocusGroup1.png" alt="Focus Group" style={{ width: '100%' }} />
      </Grid>
      <Grid item md={6} className={customClasses.centeredContent}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <MediumOrnament
              text="Insights Into Excellence"
              centerComponent={windowWidth <= 960}
              width="250px"
              centerText
            />
          </Grid>
          <Grid item xs={12}>
            <Typography
              className={classes.h2}
              style={{ fontWeight: 600, fontSize: '40px', textAlign: windowWidth <= 960 ? 'center' : 'left' }}
            >
              How We&apos;re Driving
              <br /> Healthcare Market Research
            </Typography>
          </Grid>
          <Grid item xs={12} className={`${customClasses.smallTextContainer}`}>
            <Typography className={`${classes.smallText} ${customClasses.smallText}`}>
              The Insighters matches you with paid research opportunities. We are part of an established research
              company, Sommer Consulting, that unlocks your insights to make healthcare more accessible for all.
            </Typography>
          </Grid>
          <Grid item xs={12} className={customClasses.centeredButton}>
            <HoverButton text="Discover Our Story" link="/about" />
          </Grid>
          <Hidden smDown>
            <Grid item xs={12}>
              <ValuesBox />
            </Grid>
          </Hidden>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DrivingMarketResearchSection;
