import React from 'react';
import { useParams } from 'react-router-dom';
import { Card, Layout, Loading } from '@shopify/polaris';
import Page from '../../shared/components/Page';
import InterviewForm from '../components/InterviewForm';
import { InterviewsContext } from '../contexts/InterviewsContext';
import FormSkeleton from '../../loading/components/FormSkeleton';
import BackButton from '../../navigation/components/BackButton';

const NewInterview: React.FunctionComponent = () => {
  const { interviewId } = useParams<Record<string, string>>();
  const [interviewFetched, setInterviewFetched] = React.useState<boolean>(false);
  const { interviewLoading, fetchFormOptions } = React.useContext(InterviewsContext);

  React.useEffect(() => {
    if (!interviewFetched) {
      fetchFormOptions();
      setInterviewFetched(true);
    }
  }, [interviewFetched, interviewId, fetchFormOptions]);

  const loadingMarkup = interviewLoading ? <Loading /> : null;

  // ---- Page markup ----
  const actualPageMarkup = (
    <>
      <BackButton pageAction={false} />
      <Page fullWidth title="New Interview">
        <Layout>
          <Layout.Section>
            <Card sectioned>
              <InterviewForm interview={{}} />
            </Card>
          </Layout.Section>
          <Layout.Section secondary />
        </Layout>
      </Page>
    </>
  );

  // ---- Loading ----
  const loadingPageMarkup = <FormSkeleton />;

  const pageMarkup = interviewLoading ? loadingPageMarkup : actualPageMarkup;

  return (
    <>
      {loadingMarkup}
      {pageMarkup}
    </>
  );
};

export default NewInterview;
