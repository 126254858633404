/* eslint-disable */
import React from 'react';
import { Typography } from '@material-ui/core';
import EventSmear from '../../../assets/redesignAssets/staticImages/EventSmear.png';
import { EventResource } from '../model';
import ReadMoreArrow from '../../shared/components/ReadMoreArrow';

interface EventViewerProps {
  eventResource: EventResource;
}

const EventViewer: React.FC<EventViewerProps> = ({ eventResource }) => {
  return (
    <div style={{ position: 'relative', display: 'inline-block' }}>
      <img
        src={eventResource?.s3_image_url}
        alt="Event"
        style={{
          display: 'block',
          width: '300px',
          height: '225px',
          borderRadius: '15px',
          overflow: 'hidden',
          objectFit: 'cover',
        }}
      />
      <div
        style={{
          backgroundImage: `url(${EventSmear})`,
          position: 'absolute',
          bottom: '-20%',
          width: '80%',
          height: 'auto',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          marginLeft: '10%',
          marginRight: '10%',
          padding: '6%',
          paddingRight: '12%',
        }}
      >
        <Typography
          style={{
            color: 'black',
            fontSize: 13,
            fontFamily: 'Crimson Text',
            fontWeight: 'bold',
            lineHeight: '1.1em',
            wordWrap: 'break-word',
            marginBottom: 10,
          }}
          dangerouslySetInnerHTML={{ __html: eventResource?.thumbnail_description }}
        ></Typography>
        <ReadMoreArrow message={eventResource?.arrow_message} link={eventResource?.link} />
      </div>
    </div>
  );
};

export default EventViewer;
