import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Page } from '@shopify/polaris';
import ArrowIcon from '../../../assets/redesignAssets/staticImages/ArrowIcon.png';

interface PageProps {
  fullWidth?: boolean;
  title?: string;
  primaryAction?: Record<string, unknown>;
  secondaryActions?: Record<string, unknown>[];
  additionalMetadata?: any;
  titleMetadata?: any;
  pagination?: Record<string, unknown>;
}

const PageWrapper: React.FC<PageProps> = (props) => {
  const {
    fullWidth,
    title = '',
    primaryAction,
    secondaryActions,
    additionalMetadata,
    titleMetadata,
    pagination,
    children,
  } = props;

  return (
    <Page
      fullWidth={fullWidth}
      title={title}
      primaryAction={primaryAction}
      secondaryActions={secondaryActions}
      additionalMetadata={additionalMetadata}
      titleMetadata={titleMetadata}
      pagination={pagination}
    >
      {children}
    </Page>
  );
};

export default PageWrapper;
