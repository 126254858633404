import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Card, Layout } from '@shopify/polaris';
import Page from '../../shared/components/Page';
import OpportunityForm from '../components/OpportunityForm';
import BackButton from '../../navigation/components/BackButton';

const NewProjectOpportunity: React.FunctionComponent = () => {
  const history = useHistory();
  const { projectId } = useParams<Record<string, string>>();

  return (
    <>
      <BackButton pageAction={false} />
      <Page fullWidth title="Create New Opportunity">
        <Layout>
          <Layout.Section>
            <Card sectioned>
              <OpportunityForm
                opportunity={{ project_id: projectId }}
                afterCreate={() => history.push(`/admin/projects/${projectId}`)}
              />
            </Card>
          </Layout.Section>
          <Layout.Section secondary />
        </Layout>
      </Page>
    </>
  );
};

export default NewProjectOpportunity;
