import React, { useState } from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import { NavigateBefore, NavigateNext } from '@material-ui/icons';
import useStyles from '../../constants/redesignStyles';
import HoverButton from '../../common/HoverButton';
import SectionOrnament from '../../common/SectionOrnament';
import MediumOrnament from '../../common/MediumOrnament';
import LayeredRectangle from '../../../../assets/redesignAssets/staticImages/LayeredRectangle.png';
import TestimonialQuotationMarks from '../assets/TestimonialQuotationMarks.png';
import { Testimonial } from '../../../testimonials/model';

interface TestimonialsViewerPageProps {
  testimonials: Testimonial[];
}

const useTestimonialStyles = makeStyles((theme) => ({
  whatInsightersSay: {
    color: 'var(--3, #FFF)',
    fontFamily: 'Crimson Text',
    fontSize: 32,
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: '100%',
    letterSpacing: -2,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      fontSize: 28,
      marginTop: 15,
    },
  },
  actualQuoteTextContainer: {
    height: 'auto',
    paddingTop: '25px',
    marginBottom: 25,
    position: 'relative',

    [theme.breakpoints.down(960)]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  quotationsImage: {
    position: 'absolute',
    left: -80,
    top: 0,

    [theme.breakpoints.down(960)]: {
      display: 'none',
    },
  },
  actualQuoteText: {
    color: 'var(--3, #FFF)',
    fontFamily: 'Crimson Text',
    fontSize: 40,
    fontStyle: 'italic',
    fontWeight: 400,
    lineHeight: '100%' /* 70px */,
    letterSpacing: -2,
    maxWidth: '500px',

    [theme.breakpoints.down(960)]: {
      textAlign: 'center',
      fontSize: 32,
      justifyContent: 'center',
      maxWidth: '80%',
    },
  },
  nameText: {
    color: 'var(--3, #FFF)',
    fontFamily: 'Crimson Text',
    fontSize: 22,
    fontStyle: 'normal',
    fontWeight: 700,
    letterSpacing: -1,
    borderBottom: '1px solid #767676',

    [theme.breakpoints.down('sm')]: {
      borderBottom: 'none',
      textAlign: 'center',
      fontSize: 18,
    },
  },
  jobTitleText: {
    color: '#BEC3D4',
    fontFamily: 'Rethink Sans, sans-serif',
    fontSize: 18,
    fontStyle: 'normal',
    fontWeight: 200,
    letterSpacing: -1,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      fontSize: 16,
      marginBottom: 20,
    },
  },
  buttonContainer: {
    marginTop: 15,
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: theme.spacing(2),
    },
  },
  layeredRectangle: {
    width: '100%',
    height: '550px',
    display: 'block',
  },
  testimonialImage: {
    width: '50%',
    height: 'auto',
    marginBottom: 15,
  },
  navButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 10,

    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
  },
}));

const TestimonialViewer: React.FC<TestimonialsViewerPageProps> = (props: TestimonialsViewerPageProps) => {
  const classes = useStyles();
  const testimonialClasses = useTestimonialStyles();

  const { testimonials } = props;

  const [imageIndex, setImageIndex] = useState(0);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 960);

  // Function to handle screen resize and update the state
  React.useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 960);
    };

    // Add event listener for screen resizing
    window.addEventListener('resize', handleResize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handlePrevImage = () => {
    setImageIndex((prevIndex) => (prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1));
  };

  const handleNextImage = () => {
    setImageIndex((prevIndex) => (prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1));
  };

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      {isMobileView ? (
        <div
          style={{
            display: 'block',
            width: '200%',
            marginLeft: '-50%',
            height: '800px',
            backgroundColor: '#3B4255',
          }}
        />
      ) : (
        <img
          src={LayeredRectangle}
          alt="Layered Rectangle"
          style={{ width: '100%', height: '550px', display: 'block' }}
        />
      )}
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Grid container>
          <Grid item xs={12} md={6} style={{ display: 'flex', justifyContent: 'center' }}>
            <img
              src={testimonials[imageIndex]?.s3_image_url}
              alt="Hero"
              className={testimonialClasses.testimonialImage}
            />
          </Grid>
          <Grid item xs={12} md={6} style={{ display: 'flex', alignItems: 'center' }}>
            <Grid container>
              <Grid item xs={12} md={6}>
                <MediumOrnament
                  text="Real Stories, Real Impact"
                  width="300px"
                  centerText
                  centerComponent={isMobileView}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography className={testimonialClasses.whatInsightersSay}>What Insighters Say</Typography>
              </Grid>
              <Grid item xs={12} className={testimonialClasses.actualQuoteTextContainer}>
                <img
                  src={TestimonialQuotationMarks}
                  alt="Quotations Mark"
                  className={testimonialClasses.quotationsImage}
                />
                <Typography className={testimonialClasses.actualQuoteText}>
                  {testimonials[imageIndex]?.quote}
                </Typography>
              </Grid>
              <Grid item xs={12} md={9}>
                <Grid container>
                  <Grid item xs={12} md={7}>
                    <Typography className={testimonialClasses.nameText}>{testimonials[imageIndex]?.name}</Typography>
                    <Typography className={testimonialClasses.jobTitleText}>
                      {testimonials[imageIndex]?.job_title}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4} className={testimonialClasses.navButtonContainer}>
                    <button
                      type="button"
                      style={{ border: '5px solid #EFEFEF', borderRadius: 4, marginRight: 2 }}
                      onClick={handlePrevImage}
                    >
                      <NavigateBefore />
                    </button>
                    <button
                      type="button"
                      style={{ border: '5px solid #EFEFEF', borderRadius: 4 }}
                      onClick={handleNextImage}
                    >
                      <NavigateNext />
                    </button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} className={testimonialClasses.buttonContainer}>
                <HoverButton text="Explore Our Stories" link="/about" />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default TestimonialViewer;
