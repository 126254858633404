import React, { useCallback, useState } from 'react';
import { Button, Card, Loading, Page, Toast } from '@shopify/polaris';
import { useHistory } from 'react-router-dom';
import DataTableSkeleton from '../../loading/components/DataTableSkeleton';
import DataTable from '../../dataTable/DataTable/DataTable';
import RecruitmentPartnerFilterBar from '../components/RecruitmentPartnerFilterBar';
import { RecruitmentPartnersContext } from '../contexts/RecruitmentPartnersContext';
import { IDataTableColumn } from '../../dataTable/DataTable/model';

const recruitmentPartnerColumns = (): IDataTableColumn[] => [
  {
    name: 'Name',
    selector: 'name',
    sortable: true,
  },
  {
    name: 'Dummy Email',
    selector: 'dummy_email',
    sortable: true,
  },
  {
    name: 'Email Domain',
    selector: 'email_domain',
    sortable: true,
  },
  {
    name: 'Status',
    selector: 'status',
    sortable: true,
    cell: ({ status }): React.ReactNode => (
      <div
        style={{
          fontWeight: 700,
          textTransform: 'uppercase',
          fontSize: 14,
          color: status === 'active' ? 'green' : 'red',
        }}
      >
        {status}
      </div>
    ),
  },
  {
    name: 'Actions',
    selector: 'status',
    sortable: true,
    cell: (row): React.ReactNode => (
      <div style={{ marginRight: 10 }}>
        <Button url={`/admin/recruitment-partners/${row.id}/edit`}>Edit</Button>
      </div>
    ),
  },
];

interface RecruitmentPartnersListProps {
  isLoading: boolean;
}

const RecruitmentPartnersList: React.FunctionComponent<RecruitmentPartnersListProps> = (props) => {
  const { isLoading } = props;
  const [recruitmentPartnersFetched, setRecruitmentPartnersFetched] = React.useState<boolean>(false);

  const { recruitmentPartners, fetchRecruitmentPartners, recruitmentPartnersLoading } =
    React.useContext(RecruitmentPartnersContext);

  React.useEffect(() => {
    if (!recruitmentPartnersFetched) {
      setRecruitmentPartnersFetched(true);
      fetchRecruitmentPartners();
    }
  }, [recruitmentPartnersFetched, setRecruitmentPartnersFetched, fetchRecruitmentPartners]);

  const history = useHistory();
  const [toastActive, setToastActive] = useState(false);
  const loadingMarkup = isLoading ? <Loading /> : null;

  const toggleToastActive = useCallback(() => setToastActive((toastActive) => !toastActive), []);
  const toastMarkup = toastActive ? <Toast onDismiss={toggleToastActive} content="Changes saved" /> : null;

  const actualPageMarkup = (
    <Page
      fullWidth
      title="Recruitment Partners"
      primaryAction={{
        content: 'Create Recruitment Partner',
        onAction: () => history.push('/admin/recruitment-partners/new'),
      }}
    >
      <Card>
        <br />
        <RecruitmentPartnerFilterBar />
        <DataTable
          columns={recruitmentPartnerColumns()}
          progressPending={recruitmentPartnersLoading}
          data={recruitmentPartners}
          pagination
          striped
          highlightOnHover
          pointerOnHover
          paginationPerPage={25}
          paginationRowsPerPageOptions={[25]}
          noHeader
        />
      </Card>
    </Page>
  );

  // ---- Loading ----
  const loadingPageMarkup = <DataTableSkeleton />;

  const pageMarkup = isLoading ? loadingPageMarkup : actualPageMarkup;

  return (
    <>
      {loadingMarkup}
      {pageMarkup}
      {toastMarkup}
    </>
  );
};

export default RecruitmentPartnersList;
