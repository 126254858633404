import React from 'react';
import { PhaseChecklistFormValues } from '../../projectPhases/model';

const sharedChecklistCards = (pageCategory: string, formValues: PhaseChecklistFormValues): any[] => {
  return [
    {
      genericCard: true,
      title: 'Leads',
      description: `Use Lead Finder to add leads to this ${pageCategory}.`,
      checkIcons: [
        {
          label: 'Leads added',
          checked: formValues?.leads_count > 0 || formValues?.leads_status === 'complete',
          disabled: formValues?.leads_status === 'not needed',
          databaseKey: 'leads_status',
        },
        {
          label: 'Filter Unsubscribed = true for manual marketing emails',
          checked: formValues?.leads_manual_marketing_status === 'complete',
          disabled: formValues?.leads_manual_marketing_status === 'not needed',
          databaseKey: 'leads_manual_marketing_status',
        },
      ],
    },
    {
      genericCard: true,
      title: 'Add Invitation Opportunity',
      description: 'Add an Invitation Opportunity to the project.',
      checkIcons: [
        {
          label: 'Project has at least one invitation opportunity',
          checked:
            formValues?.invitation_opportunities_count > 0 || formValues?.invitation_opportunity_status === 'complete',
          disabled: formValues?.invitation_opportunity_status === 'not needed',
          databaseKey: 'invitation_opportunity_status',
        },
        {
          label: 'Update description',
          checked: formValues?.invitation_opportunity_description_status === 'complete',
          disabled: formValues?.invitation_opportunity_description_status === 'not needed',
          databaseKey: 'invitation_opportunity_description_status',
        },
        {
          label: 'Confirm accurate link & payment amount',
          checked: formValues?.invitation_opportunity_link_status === 'complete',
          disabled: formValues?.invitation_opportunity_link_status === 'not needed',
          databaseKey: 'invitation_opportunity_link_status',
        },
        {
          label: 'Invitation Opportunity active',
          checked: formValues?.invitation_opportunity_active === 'complete',
          disabled: formValues?.invitation_opportunity_active === 'not needed',
          databaseKey: 'invitation_opportunity_active',
        },
        {
          label: 'Email partners personally (on top of automated invitation email)',
          checked: formValues?.invitation_opportunity_email_status === 'complete',
          disabled: formValues?.invitation_opportunity_email_status === 'not needed',
          databaseKey: 'invitation_opportunity_email_status',
        },
      ],
    },
    {
      genericCard: true,
      title: 'Calendly',
      checkIcons: [
        {
          label: 'Moderator times',
          checked: formValues?.calendly_moderator_times_status === 'complete',
          disabled: formValues?.calendly_moderator_times_status === 'not needed',
          databaseKey: 'calendly_moderator_times_status',
        },
        {
          label: 'Booking questions',
          checked: formValues?.calendly_booking_questions_status === 'complete',
          disabled: formValues?.calendly_booking_questions_status === 'not needed',
          databaseKey: 'calendly_booking_questions_status',
        },
        {
          label: 'Workflows',
          checked: formValues?.calendly_workflows_status === 'complete',
          disabled: formValues?.calendly_workflows_status === 'not needed',
          databaseKey: 'calendly_workflows_status',
        },
        {
          label: 'Turn on Calendly Link',
          checked: formValues?.calendly_status === 'complete',
          disabled: formValues?.calendly_status === 'not needed',
          databaseKey: 'calendly_status',
        },
      ],
    },
    {
      genericCard: true,
      title: 'Promotional Emails',
      checkIcons: [
        {
          label: 'Mailchimp draft started',
          checked: formValues?.mailchimp_draft_started === 'complete',
          disabled: formValues?.mailchimp_draft_started === 'not needed',
          databaseKey: 'mailchimp_draft_started',
        },
        {
          label: 'Mailchimp test email sent',
          checked: formValues?.mailchimp_test_email_sent === 'complete',
          disabled: formValues?.mailchimp_test_email_sent === 'not needed',
          databaseKey: 'mailchimp_test_email_sent',
        },
        {
          label: 'Send Mailchimp email announcing opportunity',
          checked: formValues?.mailchimp_status === 'complete',
          disabled: formValues?.mailchimp_status === 'not needed',
          databaseKey: 'mailchimp_status',
        },
        {
          label: 'Send manual (Outlook) emails announcing opportunity (to HCPs, lead lists, unsubscribed, etc.)',
          checked: formValues?.manual_email_status === 'complete',
          disabled: formValues?.manual_email_status === 'not needed',
          databaseKey: 'manual_email_status',
        },
      ],
    },
    {
      genericCard: true,
      title: 'Qualtrics',
      checkIcons: [
        {
          label: 'Theme',
          checked: formValues?.qualtrics_theme_status === 'complete',
          disabled: formValues?.qualtrics_theme_status === 'not needed',
          databaseKey: 'qualtrics_theme_status',
        },
        {
          label: 'Name/description',
          checked: formValues?.qualtrics_name_status === 'complete',
          disabled: formValues?.qualtrics_name_status === 'not needed',
          databaseKey: 'qualtrics_name_status',
        },
        {
          label: 'Workflows',
          checked: formValues?.qualtrics_workflows_status === 'complete',
          disabled: formValues?.qualtrics_workflows_status === 'not needed',
          databaseKey: 'qualtrics_workflows_status',
        },
        {
          label: 'Delete Tests',
          checked: formValues?.qualtrics_delete_tests_status === 'complete',
          disabled: formValues?.qualtrics_delete_tests_status === 'not needed',
          databaseKey: 'qualtrics_delete_tests_status',
        },
        {
          label: 'Turn on security (after done testing)',
          checked: formValues?.qualtrics_security_status === 'complete',
          disabled: formValues?.qualtrics_security_status === 'not needed',
          databaseKey: 'qualtrics_security_status',
        },
        {
          label: 'Update Feedback Form',
          checked: formValues?.qualtrics_update_feedback_form_status === 'complete',
          disabled: formValues?.qualtrics_update_feedback_form_status === 'not needed',
          databaseKey: 'qualtrics_update_feedback_form_status',
        },
        {
          label: 'Turn on Qualtrics Link',
          checked: formValues?.qualtrics_status === 'complete',
          disabled: formValues?.qualtrics_status === 'not needed',
          databaseKey: 'qualtrics_status',
        },
        {
          label: 'Check Mappings (Manually input key for client conditions question)',
          checked: formValues?.qualtrics_check_mappings === 'complete',
          disabled: formValues?.qualtrics_check_mappings === 'not needed',
          databaseKey: 'qualtrics_check_mappings',
        },
        {
          label: "Remove Contractor's Access to the Screener",
          checked: formValues?.remove_contractor_access === 'complete',
          disabled: formValues?.remove_contractor_access === 'not needed',
          databaseKey: 'remove_contractor_access',
        },
      ],
    },
    {
      genericCard: true,
      title: 'TinyURL',
      checkIcons: [
        {
          label: 'Link Created',
          checked: formValues?.tinyurl_status === 'complete',
          disabled: formValues?.tinyurl_status === 'not needed',
          databaseKey: 'tinyurl_status',
        },
        {
          label: 'Link Tested',
          checked: formValues?.tinyurl_link_tested_status === 'complete',
          disabled: formValues?.tinyurl_link_tested_status === 'not needed',
          databaseKey: 'tinyurl_link_tested_status',
        },
      ],
    },
    {
      genericCard: true,
      title: 'Sprout',
      description: 'Build social media ads in Sprout.',
      checkIcons: [
        {
          label: 'Instagram Image Created',
          checked: formValues?.sprout_instagram_image_created === 'complete',
          disabled: formValues?.sprout_instagram_image_created === 'not needed',
          databaseKey: 'sprout_instagram_image_created',
        },
        {
          label: 'Facebook/Twitter Image Created',
          checked: formValues?.sprout_facebook_twitter_image_created === 'complete',
          disabled: formValues?.sprout_facebook_twitter_image_created === 'not needed',
          databaseKey: 'sprout_facebook_twitter_image_created',
        },
        {
          label: 'Caption sanity check',
          checked: formValues?.sprout_caption_check_status === 'complete',
          disabled: formValues?.sprout_caption_check_status === 'not needed',
          databaseKey: 'sprout_caption_check_status',
        },
        {
          label: 'Sprout Ads Built',
          checked: formValues?.sprout_status === 'complete',
          disabled: formValues?.sprout_status === 'not needed',
          databaseKey: 'sprout_status',
        },
        {
          label: 'Caption sanity check',
          checked: formValues?.sprout_caption_check_status === 'complete',
          disabled: formValues?.sprout_caption_check_status === 'not needed',
          databaseKey: 'sprout_caption_check_status',
        },
      ],
    },
    {
      genericCard: true,
      title: 'Linktree',
      description: `Create a Linktree page for the ${pageCategory}.`,
      checkIcons: [
        {
          label: 'Linktree Created',
          checked: formValues?.linktree_status === 'complete',
          disabled: formValues?.linktree_status === 'not needed',
          databaseKey: 'linktree_status',
        },
        {
          label: 'Link Tested',
          checked: formValues?.linktree_link_tested_status === 'complete',
          disabled: formValues?.linktree_link_tested_status === 'not needed',
          databaseKey: 'linktree_link_tested_status',
        },
        {
          label: 'Link Turned On',
          checked: formValues?.linktree_link_turned_on === 'complete',
          disabled: formValues?.linktree_link_turned_on === 'not needed',
          databaseKey: 'linktree_link_turned_on',
        },
      ],
    },
  ];
};

export default sharedChecklistCards;
