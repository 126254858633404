import React from 'react';
import { IDataTableColumn } from '../../dataTable/DataTable/model';

const getStatusColor = (status: string) => {
  if (status === 'failed') {
    return 'red';
  }

  if (status === 'complete') {
    return 'green';
  }

  return '#4ba9c8';
};

export const projectPaymentColumns: IDataTableColumn<any>[] = [
  {
    name: 'Description',
    selector: 'description',
    sortable: true,
    grow: 2,
  },
  {
    name: 'Category',
    selector: 'payment_category',
    sortable: true,
    grow: 2,
  },
  {
    name: 'Amount',
    selector: 'number_amount',
    sortable: true,
    cell: ({ number_amount }) => <div>${number_amount}</div>,
  },
  {
    name: 'Notes',
    selector: 'notes',
    sortable: true,
  },
  {
    name: 'Processed At',
    selector: 'processed_at',
    sortable: true,
  },
  {
    name: 'Status',
    selector: 'status',
    sortable: true,
    cell: ({ status }) => (
      <div
        style={{
          fontWeight: 700,
          textTransform: 'uppercase',
          fontSize: 14,
          color: getStatusColor(status),
        }}
      >
        {status}
      </div>
    ),
  },
];

export default projectPaymentColumns;
