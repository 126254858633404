import React from 'react';
import { useHistory } from 'react-router-dom';
import axios from '../../utils/axios.utils';
import ConfirmCancelModal from '../../modal/components/MaterialModal';

interface ModalProps {
  userId?: string;
  deleteInternalUserModalOpen?: boolean;
  setDeleteInternalUserModalOpen?: any;
}

const DeleteInternalUserConfirmModal: React.FC<ModalProps> = (props) => {
  const history = useHistory();

  const { userId, deleteInternalUserModalOpen, setDeleteInternalUserModalOpen } = props;

  const handleDeleteUser = async () => {
    try {
      await axios.delete(`internal_users/${userId}`);
      history.push('/admin/users');
    } catch (error: any) {
      console.log(error);
    }
  };

  return (
    <ConfirmCancelModal
      isOpen={deleteInternalUserModalOpen}
      title="Delete User"
      onCancel={() => setDeleteInternalUserModalOpen(false)}
      onWarning={() => handleDeleteUser()}
      warningLabel="Delete"
      cancelLabel="Cancel"
      hasWarningButton
      hasCancelButton
      hasFooterBar={false}
      height="250px"
    >
      <div style={{ marginTop: '20px', marginBottom: '20px' }}>
        <p>Are you sure you want to delete this user?</p>
      </div>
    </ConfirmCancelModal>
  );
};

export default DeleteInternalUserConfirmModal;
