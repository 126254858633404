import React from 'react';
import { Grid } from '@material-ui/core';
import { Card, Button, Filters, ChoiceList, DatePicker } from '@shopify/polaris';
import { format } from 'date-fns';
import { InsightersContext } from '../contexts/InsightersContext';

const InsighterFilterBar: React.FunctionComponent = () => {
  const [queryValue, setQueryValue] = React.useState<string>(sessionStorage.getItem('insighterFilterQuery') || '');
  const {
    fetchInsighters,
    fetchFormOptions,
    formOptions,
    insighterRoleFilterLabel,
    setInsighterRoleFilterLabel,
    insighterTagFilterLabel,
    setInsighterTagFilterLabel,
    signUpDateFilterLabel,
    setSignUpDateFilterLabel,
    setFetchParams,
    sortParams,
  } = React.useContext(InsightersContext);
  const [formOptionsFetched, setFormOptionsFetched] = React.useState(false);
  const [selectedInsighterRoleFilter, setSelectedInsighterRoleFilter] = React.useState<string[]>(['']);
  const [selectedInsighterTagFilter, setSelectedInsighterTagFilter] = React.useState<string[]>(['']);
  const [appliedFiltersDisplayed, setAppliedFiltersDisplayed] = React.useState<any[]>([]);
  const [monthYearDisplayed, setMonthYearDisplayed] = React.useState<any>({
    month: parseInt(format(new Date(), 'MM'), 10) - 1,
    year: parseInt(format(new Date(), 'yyyy'), 10),
  });
  const [selectedDates, setSelectedDates] = React.useState({
    start: new Date(1900, 0, 1),
    end: new Date(2100, 0, 1),
  });

  React.useEffect(() => {
    if (!formOptionsFetched) {
      fetchFormOptions();
      setFormOptionsFetched(true);
    }
  }, [formOptionsFetched, setFormOptionsFetched, fetchFormOptions]);

  React.useEffect(() => {
    const appliedFiltersArray = [];

    if (insighterRoleFilterLabel) {
      appliedFiltersArray.push({
        key: 'role',
        label: `Filtered by ${insighterRoleFilterLabel}`,
        onRemove: () => {
          setInsighterRoleFilterLabel('');
          setSelectedInsighterRoleFilter(['']);
        },
      });
    }

    if (insighterTagFilterLabel) {
      appliedFiltersArray.push({
        key: 'flagged',
        label: `Filtered by ${insighterTagFilterLabel}`,
        onRemove: () => {
          setInsighterTagFilterLabel('');
          setSelectedInsighterTagFilter(['']);
        },
      });
    }

    if (signUpDateFilterLabel?.start && signUpDateFilterLabel?.end && signUpDateFilterLabel?.start !== '01/01/1900') {
      appliedFiltersArray.push({
        key: 'selectedDates',
        label: `Filtering from ${signUpDateFilterLabel.start} to ${signUpDateFilterLabel.end}`,
        onRemove: () => {
          setSignUpDateFilterLabel({ start: '', end: '' });
          setSelectedDates({
            start: new Date(1900, 0, 1),
            end: new Date(2100, 0, 1),
          });
        },
      });
    }

    setAppliedFiltersDisplayed([...appliedFiltersArray]);
  }, [
    insighterRoleFilterLabel,
    setInsighterRoleFilterLabel,
    setSelectedInsighterRoleFilter,
    insighterTagFilterLabel,
    setInsighterTagFilterLabel,
    signUpDateFilterLabel.start,
    signUpDateFilterLabel.end,
    setSignUpDateFilterLabel,
  ]);

  const applyFilters = () => {
    const nameFilter = `ransack[user_first_name_cont]=${queryValue}&ransack[user_last_name_cont]=${queryValue}`;
    const emailFilter = `ransack[email_cont]=${queryValue}`;
    const uuidFilter = `ransack[user_uuid_cont]=${queryValue}`;
    const roleFilter = `ransack[insighter_roles_name_cont]=${selectedInsighterRoleFilter[0]}`;
    const accountFlagFilter = `ransack[insighter_tags_name_cont]=${selectedInsighterTagFilter[0]}`;
    const startDateFilter = `ransack[user_sign_up_time_gteq]=${selectedDates?.start}`;
    const endDateFilter = `ransack[user_sign_up_time_lteq]=${selectedDates?.end
      .toString()
      .replace('00:00:00', '23:59:59')}`;

    setFetchParams(
      `${nameFilter}&${emailFilter}&${uuidFilter}&${roleFilter}&${accountFlagFilter}&${startDateFilter}&${endDateFilter}`,
    );

    sessionStorage.setItem('insighterFilterQuery', queryValue);
    sessionStorage.setItem(
      'insighterFilters',
      `${nameFilter}&${emailFilter}&${roleFilter}&${accountFlagFilter}&${startDateFilter}&${endDateFilter}&${sortParams}`,
    );

    fetchInsighters(
      `${nameFilter}&${emailFilter}&${uuidFilter}&${roleFilter}&${accountFlagFilter}&${startDateFilter}&${endDateFilter}&${sortParams}`,
    );
  };

  const handleFiltersKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      applyFilters();
    }
  };

  const clearFilters = () => {
    setFetchParams('');
    setInsighterRoleFilterLabel('');
    setSelectedInsighterRoleFilter(['']);
    setInsighterTagFilterLabel('');
    setSelectedInsighterTagFilter(['']);
    setSignUpDateFilterLabel({ start: '', end: '' });
    setSelectedDates({
      start: new Date(1900, 0, 1),
      end: new Date(2100, 0, 1),
    });
    setQueryValue('');
    sessionStorage.removeItem('insighterFilters');
    sessionStorage.removeItem('insighterFilterQuery');
    fetchInsighters();
  };

  return (
    <Card.Section>
      <div role="form" onKeyDown={handleFiltersKeyPress}>
        <Filters
          queryValue={queryValue}
          queryPlaceholder="Filter insighters by name, email, or user ID..."
          filters={[
            {
              key: 'insighter_roles',
              label: 'Roles',
              filter: (
                <ChoiceList
                  title="Role"
                  choices={formOptions?.insighter_role_options}
                  selected={selectedInsighterRoleFilter}
                  onChange={(value) => setSelectedInsighterRoleFilter(value)}
                />
              ),
              shortcut: true,
            },
            {
              key: 'insighter_tags',
              label: 'Insighter Tags',
              filter: (
                <ChoiceList
                  title="Insighter Tag"
                  choices={formOptions?.insighter_tag_options}
                  selected={selectedInsighterTagFilter}
                  onChange={(value) => setSelectedInsighterTagFilter(value)}
                />
              ),
              shortcut: true,
            },
            {
              key: 'sign_up_time',
              label: 'Sign Up Date',
              filter: (
                <DatePicker
                  month={monthYearDisplayed?.month}
                  year={monthYearDisplayed?.year}
                  onChange={setSelectedDates}
                  onMonthChange={(month, year) => setMonthYearDisplayed({ month, year })}
                  selected={selectedDates}
                  allowRange
                />
              ),
              shortcut: true,
            },
          ]}
          appliedFilters={appliedFiltersDisplayed}
          onQueryChange={setQueryValue}
          onQueryClear={() => setQueryValue('')}
          onClearAll={() => null}
        >
          <Grid container>
            <Grid item>
              <div style={{ paddingLeft: '8px' }}>
                <Button primary onClick={applyFilters}>
                  Apply Filters
                </Button>
              </div>
            </Grid>
            <Grid item>
              <div style={{ paddingLeft: '8px' }}>
                <Button onClick={clearFilters}>Clear Filters</Button>
              </div>
            </Grid>
          </Grid>
        </Filters>
        <small>
          <em>Searching a single word will collect all matches by first name, last name, email, and user id</em>
        </small>
        <br />
        <small>
          <em>Searching two words will only display users who have both words as their first and last name.</em>
        </small>
      </div>
    </Card.Section>
  );
};

export default InsighterFilterBar;
