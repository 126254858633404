import React from 'react';
import { Grid } from '@material-ui/core';
import PasswordResetForm from '../components/PasswordResetForm';
import Page from '../../shared/components/Page';

const LoginPage: React.FC = () => {
  return (
    <Page fullWidth title="">
      <div style={{ marginTop: 100 }}>
        <Grid container>
          <Grid item xs={12} md={3} />
          <Grid item xs={12} md={6} style={{ marginLeft: 20, marginRight: 20 }}>
            <h1
              className="Polaris-Header-Title"
              style={{ fontFamily: 'Cooper', fontSize: '36px', marginBottom: 10, lineHeight: '40px' }}
            >
              Forgot Your Password?
            </h1>
            <br />
            <PasswordResetForm />
          </Grid>
        </Grid>
      </div>
    </Page>
  );
};

export default LoginPage;
