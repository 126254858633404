import React, { useCallback, useState } from 'react';
import { Loading, Toast } from '@shopify/polaris';
import { useHistory, useLocation } from 'react-router-dom';
import DataTable from '../../dataTable/DataTable/DataTable';
import { IDataTableColumn } from '../../dataTable/DataTable/model';
import { InsightersContext } from '../contexts/InsightersContext';
import getStatusColor from '../../interviews/constants/getStatusColor';

const columns: IDataTableColumn<any>[] = [
  {
    name: 'Name',
    selector: 'name',
    sortable: true,
    grow: 0.6,
  },
  {
    name: 'User ID',
    selector: 'uuid',
    sortable: true,
    grow: 0.6,
  },
  {
    name: 'Email',
    selector: 'email',
    sortable: true,
    grow: 1.5,
  },
  {
    name: 'Roles',
    selector: 'insighter_roles',
    sortable: false,
  },
  {
    name: 'Past Projects',
    selector: 'past_projects',
    sortable: false,
    cell: (row) => <>{row?.past_projects?.length > 0 ? <p>{row.past_projects}</p> : <p>None</p>}</>,
  },
  {
    name: 'Condition',
    selector: 'diagnosis',
    sortable: false,
    grow: 2,
  },
  {
    name: 'Created At',
    selector: 'created_at',
    sortable: true,
  },
  {
    name: 'Last Active',
    selector: 'last_active_date',
    sortable: true,
  },
  {
    name: 'Flag',
    selector: 'flag',
    sortable: true,
    cell: (row) => (
      <div
        style={{
          fontWeight: 700,
          fontSize: 14,
          color: getStatusColor(row.flag),
        }}
      >
        {row.flag}
      </div>
    ),
  },
];

const InsightersList: React.FunctionComponent = () => {
  const history = useHistory();
  const [toastActive, setToastActive] = useState(false);
  const [insightersFetched, setInsightersFetched] = React.useState(false);
  const {
    insighters,
    fetchInsighters,
    insighterLoading,
    totalRecords,
    fetchParams,
    sortParams,
    setSortParams,
    selectedDates,
    setSelectedDates,
  } = React.useContext(InsightersContext);

  const { search } = useLocation();
  const signupParams = new URLSearchParams(search).get('signups');

  const buildDateFiltersBySignupParams = () => {
    const prevDays = parseInt(signupParams, 10);
    const builtDates = {
      start: new Date(new Date().setDate(new Date().getDate() - prevDays)),
      end: new Date(),
    };

    setSelectedDates(builtDates);

    const startDateFilter = `ransack[user_sign_up_time_gteq]=${builtDates.start}`;
    const endDateFilter = `ransack[user_sign_up_time_lteq]=${builtDates.end}`;

    fetchInsighters(`${startDateFilter}&${endDateFilter}&sort=user_sign_up_time asc`);
  };

  React.useEffect(() => {
    const insighterFilters = sessionStorage.getItem('insighterFilters');

    if (!insightersFetched) {
      if (signupParams) {
        buildDateFiltersBySignupParams();
      } else if (insighterFilters) {
        fetchInsighters(insighterFilters);
      } else {
        fetchInsighters();
      }
      setInsightersFetched(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insightersFetched, fetchInsighters, signupParams]);

  const loadingMarkup = insighterLoading ? <Loading /> : null;
  const toggleToastActive = useCallback(() => setToastActive((toastActive) => !toastActive), []);
  const toastMarkup = toastActive ? <Toast onDismiss={toggleToastActive} content="Changes saved" /> : null;

  const handlePageChange = (page: number) => {
    const startDate = selectedDates?.start;
    const endDate = selectedDates?.end;

    fetchInsighters(
      `page=${page}&${fetchParams}&ransack[user_sign_up_time_gteq]=${startDate}&ransack[user_sign_up_time_lteq]=${endDate}&${sortParams}`,
    );
  };

  const handleColumnSort = async (column: Record<string, string>, sortDirection: string) => {
    const columnNamesToRansack = {
      name: 'user_last_name',
      uuid: 'user_uuid',
      email: 'user_email',
      sign_up_time: 'user_sign_up_time',
      last_active_date: 'user_last_active_date',
    };
    const startDate = selectedDates?.start;
    const endDate = selectedDates?.end;

    const sort = `sort=${columnNamesToRansack[column.selector]} ${sortDirection}`;

    setSortParams(sort);
    fetchInsighters(
      `${fetchParams}&ransack[user_sign_up_time_gteq]=${startDate}&ransack[user_sign_up_time_lteq]=${endDate}&${sort}`,
    );
  };

  const pageMarkup = (
    <DataTable
      onRowClicked={(row: Record<string, string>) => history.push(`/admin/insighters/${row.id}`)}
      columns={columns}
      data={insighters}
      pagination
      progressPending={insighterLoading}
      striped
      highlightOnHover
      pointerOnHover
      noHeader
      paginationServer
      paginationPerPage={25}
      paginationRowsPerPageOptions={[25]}
      paginationTotalRows={totalRecords}
      onChangePage={handlePageChange}
      sortServer
      onSort={handleColumnSort}
    />
  );

  return (
    <>
      {loadingMarkup}
      {pageMarkup}
      {toastMarkup}
    </>
  );
};

export default InsightersList;
