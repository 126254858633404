import React from 'react';
import { useParams } from 'react-router-dom';
import { Card, Layout, Loading } from '@shopify/polaris';
import ConditionCategoryForm from '../components/ConditionCategoryForm';
import { ConditionCategoriesContext } from '../contexts/ConditionCategoriesContext';
import FormSkeleton from '../../loading/components/FormSkeleton';
import BackButton from '../../navigation/components/BackButton';
import Page from '../../shared/components/Page';

const EditConditionCategory: React.FunctionComponent = () => {
  const { conditionCategoryId } = useParams<Record<string, string>>();
  const [conditionCategoryFetched, setConditionCategoryFetched] = React.useState<boolean>(false);
  const { conditionCategory, fetchConditionCategory, conditionCategoriesLoading } =
    React.useContext(ConditionCategoriesContext);

  React.useEffect(() => {
    if (!conditionCategoryFetched) {
      fetchConditionCategory(conditionCategoryId);
      setConditionCategoryFetched(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conditionCategoryFetched]);

  const loadingMarkup = conditionCategoriesLoading ? <Loading /> : null;

  // ---- Page markup ----
  const actualPageMarkup = (
    <>
      <BackButton pageAction={false} />
      <Page fullWidth>
        <Layout>
          <Layout.Section>
            <Card sectioned>
              <ConditionCategoryForm conditionCategory={conditionCategory} />
            </Card>
          </Layout.Section>
          <Layout.Section secondary />
        </Layout>
      </Page>
    </>
  );

  // ---- Loading ----
  const loadingPageMarkup = <FormSkeleton />;

  const pageMarkup = conditionCategoriesLoading ? loadingPageMarkup : actualPageMarkup;

  return (
    <>
      {loadingMarkup}
      {pageMarkup}
    </>
  );
};

export default EditConditionCategory;
