import React, { useState, useEffect } from 'react';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';

import { DropZone } from '@shopify/polaris';

import FormInput from '../../form/components/FormInput';

import { Icon } from '../model';

interface IconFormProps {
  formValues: Icon;
  setFormValues: (icon: Icon) => void;
  formErrors: string;
  setImageFileData: (imageFileData: FormData) => void;
}

const IconForm: React.FC<IconFormProps> = (props) => {
  const { formValues, setFormValues, formErrors, setImageFileData } = props;

  let [imagePreview, setImagePreview] = useState<string | null>(formValues?.s3_image_url || null);

  const handleAttachFile = (files: File[]) => {
    const newFormData = new FormData();
    const attachedFile = files[0];
    newFormData.append('file', attachedFile, attachedFile.name);
    setImageFileData(newFormData);

    const previewUrl = URL.createObjectURL(attachedFile);
    setImagePreview(previewUrl);
  };

  useEffect(() => {
    setImagePreview(formValues?.s3_image_url || null);
  }, [formValues]);

  return (
    <Grid container style={{ display: 'flex', alignItems: 'center' }}>
      <Grid item xs={7} style={{ marginBottom: 10 }}>
        <FormInput
          type="text"
          label="Name"
          value={formValues?.name}
          onChange={(value) => setFormValues({ ...props.formValues, name: value })}
        />
      </Grid>
      <br />
      <Grid item xs={6} style={{ margin: 'auto' }}>
        <Card title="Image">
          <DropZone type="image" label="" onDrop={handleAttachFile} allowMultiple={false}>
            <DropZone.FileUpload />
          </DropZone>
        </Card>
      </Grid>
      <Grid item xs={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {imagePreview && <img src={imagePreview} alt="Preview" style={{ width: '50px', height: 'auto' }} />}
      </Grid>
      <Grid item xs={12}>
        <p style={{ color: 'red' }}>{formErrors}</p>
      </Grid>
    </Grid>
  );
};

export default IconForm;
