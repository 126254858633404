import React from 'react';
import { useHistory } from 'react-router-dom';
import axios from '../../utils/axios.utils';
import { PhaseLead, LeadFormValues, Lead } from '../model';

interface LeadsContextInterface {
  leads?: PhaseLead[];

  sortParams?: string;
  setSortParams?: React.Dispatch<React.SetStateAction<string>>;

  updateLead?: (lead: Lead, formValues: LeadFormValues) => Promise<void>;
  leadSubmitting?: boolean;

  removeLead?: (leadId: string) => Promise<void>;
  removeLeadError?: string;
}

const LeadsContext = React.createContext<LeadsContextInterface>({});

const LeadsContextConsumer = LeadsContext.Consumer;

const LeadsContextProvider: React.FC = ({ children }) => {
  const history = useHistory();

  const [leads, setLeads] = React.useState<PhaseLead[]>([]);
  const [leadSubmitting, setLeadSubmitting] = React.useState<boolean>(false);
  const [sortParams, setSortParams] = React.useState<string>('sort=');
  const [removeLeadError, setRemoveLeadError] = React.useState<string>('');

  const updateLead = async (lead: Lead, formValues: LeadFormValues) => {
    setLeadSubmitting(true);

    await axios
      .put<string, any>(`leads/${lead.id}`, { lead: { ...formValues } })
      .then(() => {
        history.push(`/admin/lead_groups/${lead.lead_group_id}`);
        setLeadSubmitting(false);
      })
      .catch((error) => {
        setLeadSubmitting(false);
      });
  };

  const removeLead = async (leadId: string) => {
    await axios
      .delete<string, any>(`leads/${leadId}`)
      .then(() => {
        const filteredLeads = leads.filter((lead) => lead.id !== leadId);
        setLeads(filteredLeads);
      })
      .catch((error) => {
        setRemoveLeadError(error.response.data.error);
      });
  };

  return (
    <LeadsContext.Provider
      value={{
        leads,
        sortParams,
        setSortParams,

        updateLead,
        leadSubmitting,

        removeLead,
        removeLeadError,
      }}
    >
      {children}
    </LeadsContext.Provider>
  );
};

export { LeadsContextProvider, LeadsContextConsumer, LeadsContext };
