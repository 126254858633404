import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Grid, makeStyles } from '@material-ui/core';
import axios from '../../utils/axios.utils';
import BlogPostDetailHero from './sections/BlogPostDetailHero';
import RedesignFooter from '../common/RedesignFooter';
import DeleteBlogPostConfirmModal from './components/DeleteBlogPostConfirmModal';
import BlogAuthorViewer from './components/BlogAuthorViewer';
import ContentManagementButtons from '../../shared/components/ContentManagementButtons';
import { BlogPost } from '../../blogPosts/model';

const useStyles = makeStyles((theme) => ({
  sectionGrid: {
    margin: 15,
    [theme.breakpoints.down(960)]: {
      margin: 0,
    },
  },
  rootGrid: {
    maxWidth: '1600px',
    margin: 'auto',
    padding: '2%',
    [theme.breakpoints.down(960)]: {
      padding: 0,
    },
  },
  contentGrid: {
    padding: 10,
    marginBottom: 20,
    [theme.breakpoints.down(960)]: {
      padding: 0,
    },
  },
  uploadedContent: {
    display: 'flex',
    flexDirection: 'column',
    padding: '100px',
    fontFamily: 'Crimson Text',
    color: 'white',
    [theme.breakpoints.down('sm')]: {
      padding: '20px',
    },
  },
  backgroundDiv: {
    backgroundSize: 'cover',
    minHeight: 600,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    backgroundPosition: 'top center',
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.up('md')]: {
      backgroundImage: `url(https://cdn.theinsighters.com/BlogBackground.png)`,
    },
    [theme.breakpoints.down(960)]: {
      backgroundColor: '#3B4255',
    },
  },
  contentContainer: {
    marginTop: -80,
    width: '100%',

    [theme.breakpoints.down(960)]: {
      marginTop: 40,
    },
  },
}));

const BlogDetailPage: React.FC = () => {
  const classes = useStyles();

  const { blogPostId } = useParams<{ blogPostId: string }>();

  const [blogPost, setBlogPost] = useState<BlogPost | null>(null);
  const [deleteBlogPostConfirmModalOpen, setDeleteBlogPostConfirmModalOpen] = useState(false);

  const fetchBlogPost = async () => {
    try {
      const response = await axios.get(`blog_posts/${blogPostId}`);
      setBlogPost(response?.data?.result);
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchBlogPost();
    // eslint-disable-next-line
  }, []);

  return (
    <Grid container className={classes.rootGrid}>
      <Grid item xs={12} className={classes.contentGrid}>
        <ContentManagementButtons
          recordId={blogPostId}
          recordLink="blog-posts"
          recordLabelPlural="Blog Posts"
          recordLabelSingular="Blog Post"
          deleteModalOpen={setDeleteBlogPostConfirmModalOpen}
        />
      </Grid>
      <DeleteBlogPostConfirmModal
        blogPostId={blogPostId}
        deleteBlogPostConfirmModalOpen={deleteBlogPostConfirmModalOpen}
        setDeleteBlogPostConfirmModalOpen={setDeleteBlogPostConfirmModalOpen}
      />
      <Grid item xs={12} className={classes.sectionGrid} style={{ marginTop: -30 }}>
        <BlogPostDetailHero blogPost={blogPost} />
      </Grid>
      <Grid item md={1} />
      <Grid item md={10} className={classes.contentContainer}>
        <div className={classes.backgroundDiv}>
          <div className={`${classes.uploadedContent} uploaded-content`}>
            {/* eslint-disable-next-line */}
            <div dangerouslySetInnerHTML={{ __html: blogPost?.content }} />
          </div>
        </div>
      </Grid>
      <Grid item xs={12} className={classes.sectionGrid}>
        <BlogAuthorViewer blogAuthor={blogPost?.blog_author} />
      </Grid>
      <Grid item xs={12} style={{ padding: 10 }}>
        <ContentManagementButtons
          recordId={blogPostId}
          recordLink="blog-posts"
          recordLabelPlural="Blog Posts"
          recordLabelSingular="Blog Post"
          deleteModalOpen={setDeleteBlogPostConfirmModalOpen}
        />
      </Grid>
      <Grid item xs={12} className={classes.sectionGrid}>
        <RedesignFooter />
      </Grid>
    </Grid>
  );
};

export default BlogDetailPage;
