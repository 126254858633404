import React, { useState, useEffect, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Grid, Card, Typography } from '@mui/material';
import { SpecialtiesContext } from '../contexts/SpecialtiesContext';
import BackButton from '../../navigation/components/BackButton';
import ColumnSelector from '../../shared/components/ColumnSelector';
import DataTable from '../../dataTable/DataTable/DataTable';
import { Insighter } from '../../insighters/model';
import insighterPossibleColumns from '../../shared/constants/insighterPossibleColumns';
import PrepareDownloadButton from '../../shared/components/PrepareDownloadButton';

const SpecialtyDetail: React.FunctionComponent = () => {
  const history = useHistory();
  const { specialtyId } = useParams<Record<string, string>>();
  const [specialtyFetched, setSpecialtyFetched] = useState(false);
  const { specialty, fetchSpecialty, specialtiesLoading } = useContext(SpecialtiesContext);
  const [insighters, setInsighters] = useState<Insighter[]>([]);

  const [visibleColumns, setVisibleColumns] = useState([]);

  useEffect(() => {
    if (!specialtyFetched) {
      fetchSpecialty(specialtyId);
      setSpecialtyFetched(true);
    }
  }, [specialtyFetched, fetchSpecialty, specialtyId]);

  useEffect(() => {
    if (specialty?.insighters) {
      setInsighters(specialty.insighters);
    }
  }, [specialty]);

  return (
    <>
      <Grid style={{ display: 'flex', justifyContent: 'space-between' }}>
        <BackButton pageAction={false} />
        <PrepareDownloadButton
          filename={`Insighters with Specialty ${specialty?.name}.csv`}
          visibleColumns={visibleColumns}
          link={`/specialties/${specialtyId}/download`}
          includeResult
        />
      </Grid>
      <Grid style={{ margin: 10 }}>
        <Typography className="title" variant="h3">
          All Insighters with {specialty.name}
        </Typography>
        <br />
        <ColumnSelector setVisibleColumns={setVisibleColumns} columnChoices={insighterPossibleColumns} />
        <br />
        <Card>
          <DataTable
            onRowClicked={(row: Record<string, string>) => history.push(`/admin/insighters/${row.id}`)}
            columns={visibleColumns}
            data={insighters}
            pagination
            progressPending={specialtiesLoading}
            striped
            highlightOnHover
            pointerOnHover
            noHeader
            // paginationServer
            paginationPerPage={25}
            paginationRowsPerPageOptions={[25]}
            paginationTotalRows={insighters?.length}
            // onChangePage={handlePageChange}
          />
        </Card>
      </Grid>
    </>
  );
};

export default SpecialtyDetail;
