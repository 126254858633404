import React, { useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import { PaymentsContext } from '../contexts/PaymentsContext';
import NewExpenseForm from '../components/NewExpenseForm';
import NewExpenseConfirmation from '../components/NewExpenseConfirmation';
import AdminPageHeader from '../../adminTools/components/AdminPageHeader';

const NewExpensePage: React.FunctionComponent = () => {
  const { payment } = useContext(PaymentsContext);

  return (
    <Grid container style={{ padding: '1% 2%' }}>
      <AdminPageHeader title="New Expense" />
      <NewExpenseForm />
    </Grid>
  );
};

export default NewExpensePage;
