import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import HomeLandingSection from './sections/HomeLandingSection';
import ExplorePaidOpportunitiesSection from './sections/ExplorePaidOpportunitiesSection';
import DrivingMarketResearchSection from './sections/DrivingMarketResearchSection';
import QuotesSection from './sections/QuotesSection';
import HowItWorksSection from './sections/HowItWorksSection';
import TrustedPartnersSection from './sections/TrustedPartnersSection';
import Footer from '../common/RedesignFooter';
import MapSection from './sections/MapSection';
import EventSection from './sections/EventsSection';

const useStyles = makeStyles((theme) => ({
  sectionGrid: {
    margin: 30,

    [theme.breakpoints.down(960)]: {
      marginLeft: 0,
      marginRight: 0,
    },
  },
}));

const sections = [
  HomeLandingSection,
  ExplorePaidOpportunitiesSection,
  DrivingMarketResearchSection,
  QuotesSection,
  HowItWorksSection,
  MapSection,
  EventSection,
  TrustedPartnersSection,
  Footer,
];

const RedesignedHomepage: React.FC = () => {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item xs={12} className={classes.sectionGrid}>
        <HomeLandingSection />
      </Grid>
      <Grid item xs={12} className={classes.sectionGrid} style={{ marginTop: -10 }}>
        <ExplorePaidOpportunitiesSection />
      </Grid>
      <Grid item xs={12}>
        <DrivingMarketResearchSection />
      </Grid>
      <Grid item xs={12} className={classes.sectionGrid}>
        <QuotesSection />
      </Grid>
      <Grid item xs={12} className={classes.sectionGrid}>
        <HowItWorksSection />
      </Grid>
      <Grid item xs={12} className={classes.sectionGrid} style={{ marginTop: -10 }}>
        <MapSection />
      </Grid>
      <Grid item xs={12} className={classes.sectionGrid} style={{ marginTop: -60 }}>
        <EventSection />
      </Grid>
      <Grid item xs={12} className={classes.sectionGrid} style={{ marginTop: -20 }}>
        <TrustedPartnersSection />
      </Grid>
      <Grid item xs={12} className={classes.sectionGrid}>
        <Footer />
      </Grid>
    </Grid>
  );
};

export default RedesignedHomepage;
