import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from '../../utils/axios.utils';
import ConfirmCancelModal from '../../modal/components/MaterialModal';

interface DeleteTestimonialConfirmModalProps {
  testimonialToDelete?: string;
  deleteTestimonialConfirmModalOpen?: boolean;
  setDeleteTestimonialConfirmModalOpen?: any;
}

const DeleteTestimonialConfirmModal: React.FC<DeleteTestimonialConfirmModalProps> = (props) => {
  const history = useHistory();

  const { testimonialToDelete, deleteTestimonialConfirmModalOpen, setDeleteTestimonialConfirmModalOpen } = props;

  const handleDeleteTestimonial = async () => {
    try {
      await axios.delete(`testimonials/${testimonialToDelete}`);
      history.push('/admin/testimonials');
    } catch (error: any) {
      console.log(error);
    }
  };

  return (
    <ConfirmCancelModal
      isOpen={deleteTestimonialConfirmModalOpen}
      title="Delete Testimonial"
      onCancel={() => setDeleteTestimonialConfirmModalOpen(false)}
      onWarning={() => handleDeleteTestimonial()}
      warningLabel="Delete"
      cancelLabel="Cancel"
      hasWarningButton
      hasCancelButton
      hasFooterBar={false}
      height="250px"
    >
      <div style={{ marginTop: '20px', marginBottom: '20px' }}>
        <p>Are you sure you want to delete this testimonial?</p>
      </div>
    </ConfirmCancelModal>
  );
};

export default DeleteTestimonialConfirmModal;
