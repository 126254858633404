import React, { useState } from 'react';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import { NavigateBefore, NavigateNext } from '@material-ui/icons';

import Tremendous from '../../homePage/assets/Tremendous.png';
import Qualtrics from '../../homePage/assets/Qualtrics.png';
import Calendly from '../../homePage/assets/Calendly.png';
import Lilmesican from '../../homePage/assets/Lilmesican.png';
import BWICR from '../../homePage/assets/BWICR.png';
import Cervivor from '../../homePage/assets/Cervivor.png';
import DrAlexea from '../../homePage/assets/DrAlexea.png';
import ELLCIA from '../../homePage/assets/ELLCIA.png';
import ForBreast from '../../homePage/assets/ForBreast.png';
import MissingPink from '../../homePage/assets/MissingPink.png';
import MLW from '../../homePage/assets/MLW.png';
import Balasa from '../../homePage/assets/Balasa.png';

const logos = [
  Lilmesican,
  BWICR,
  Cervivor,
  Tremendous,
  Qualtrics,
  Calendly,
  DrAlexea,
  ELLCIA,
  ForBreast,
  MissingPink,
  MLW,
  Balasa,
];
const doubledLogos = [...logos, ...logos];

const PartnerLogosClickthrough: React.FC = () => {
  let [imageIndex, setImageIndex] = useState(0);

  let handlePrevImage = () => {
    setImageIndex((prevIndex) => (prevIndex === 0 ? logos.length - 1 : prevIndex - 1));
  };

  let handleNextImage = () => {
    setImageIndex((prevIndex) => (prevIndex === logos.length - 1 ? 0 : prevIndex + 1));
  };

  const logosToDisplay = doubledLogos.slice(imageIndex, imageIndex + 6);

  return (
    <Grid container style={{ display: 'flex', justifyContent: 'center' }}>
      {window.innerWidth <= 960 && (
        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            onClick={handlePrevImage}
            style={{ border: '5px solid #EFEFEF', backgroundColor: '#EFEFEF', borderRadius: 4, marginRight: 2 }}
          >
            <NavigateBefore style={{ transform: 'rotate(90deg)' }} />
          </Button>
        </Grid>
      )}
      <Grid item xs={12}>
        <Grid container style={{ display: 'flex', justifyContent: 'center' }}>
          {logosToDisplay.map((logo, index) => (
            <Grid
              item
              xs={6}
              md={1}
              key={`logo-${index}`}
              style={{
                position: 'relative',
                backgroundColor: 'white',
                borderRadius: 15,
                overflow: 'hidden',
                width: '50%',
                height: '75px',
                margin: 10,
              }}
            >
              <img
                src={logo}
                alt=""
                style={{
                  width: '90%',
                  height: '100%',
                  objectFit: 'contain',
                  borderRadius: 15,
                  position: 'absolute',
                  top: 0,
                  left: 7,
                  right: 7,
                }}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
      {window.innerWidth <= 960 && (
        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            onClick={handleNextImage}
            style={{ border: '5px solid #EFEFEF', backgroundColor: '#EFEFEF', borderRadius: 4, marginRight: 2 }}
          >
            <NavigateNext style={{ transform: 'rotate(90deg)' }} />
          </Button>
        </Grid>
      )}
      {window.innerWidth > 960 && (
        <Grid xs={12} style={{ paddingLeft: '20%' }}>
          <Button
            onClick={handlePrevImage}
            style={{ border: '5px solid #EFEFEF', backgroundColor: '#EFEFEF', borderRadius: 4, marginRight: 2 }}
          >
            <NavigateBefore />
          </Button>
          <Button
            onClick={handleNextImage}
            style={{ border: '5px solid #EFEFEF', backgroundColor: '#EFEFEF', borderRadius: 4, marginRight: 2 }}
          >
            <NavigateNext />
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default PartnerLogosClickthrough;
