import React from 'react';
import { useHistory } from 'react-router-dom';
import siteWideStyles from '../../styles/siteWideStyles';

interface HoverButtonProps {
  text: string;
  link?: string;
  onClick?: () => void;
  extraLong?: boolean;
  externalLink?: boolean;
}

const HoverButton: React.FC<HoverButtonProps> = (props) => {
  let { text, link, onClick, externalLink = true } = props;

  const history = useHistory();

  return (
    <button
      type="button"
      style={siteWideStyles.hoverButton}
      onClick={() => {
        if (externalLink) {
          window.location.href = link;
        } else if (onClick) {
          onClick();
        } else {
          history.push(link);
        }
      }}
    >
      <span style={siteWideStyles.hoverButtonText}>{text}</span>
    </button>
  );
};

export default HoverButton;
