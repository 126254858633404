/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { Button, Card, ChoiceList, DatePicker, Filters } from '@shopify/polaris';
import { Grid } from '@material-ui/core';
import { format } from 'date-fns';
import { PaymentsContext } from '../contexts/PaymentsContext';

const PaymentFilterBar: React.FunctionComponent = () => {
  const {
    fetchPayments,
    paymentsLoading,
    filterValues,
    setFilterValues,
    selectedDates,
    setSelectedDates,
    statusFilters,
    setStatusFilters,
    knownAmountsFilterToUse,
    setKnownAmountsFilterToUse,
    knownAmountsFilterUsed,
    setKnownAmountsFilterUsed,
  } = React.useContext(PaymentsContext);

  const [processedAtActive, setProcessedAtActive] = useState<any>({
    month: parseInt(format(new Date(), 'MM'), 10) - 1,
    year: parseInt(format(new Date(), 'yyyy'), 10),
  });

  const [queryValue, setQueryValue] = useState<string>(sessionStorage.getItem('paymentFilterQuery') || '');
  const [tempSelectedDates, setTempSelectedDates] = useState<any>({});
  const [tempStatusFilters, setTempStatusFilters] = useState<string[]>();
  const [knownAmountsFilterChoice, setKnownAmountsFilterChoice] = useState(['known']);
  const [errorMessage, setErrorMessage] = useState('');

  React.useEffect(() => {
    setTempSelectedDates({
      start: selectedDates?.start,
      end: selectedDates?.end,
    });
  }, [selectedDates]);

  React.useEffect(() => {
    setTempStatusFilters(statusFilters);
  }, [statusFilters]);

  React.useEffect(() => {
    if (knownAmountsFilterChoice?.length > 1) {
      setKnownAmountsFilterToUse('');
      setErrorMessage('');
    } else if (knownAmountsFilterChoice[0] === 'unknown') {
      setKnownAmountsFilterToUse('ransack[number_amount_null]=true');
      setErrorMessage('');
    } else if (knownAmountsFilterChoice[0] === 'known') {
      setKnownAmountsFilterToUse('ransack[number_amount_not_null]=true');
      setErrorMessage('');
    } else {
      setErrorMessage('Please choose at least one option from the "Known Amounts" filter');
    }
  }, [knownAmountsFilterChoice]);

  const applyFilters = () => {
    const queryFilter = `ransack[query]=${queryValue}`;

    let startDateFilter = `ransack[processed_at_gteq]=${tempSelectedDates?.start}`;
    let endDateFilter = `ransack[processed_at_lteq]=${tempSelectedDates?.end}`;

    try {
      startDateFilter = `ransack[processed_at_gteq]=${format(tempSelectedDates?.start, 'dd/MM/yyyy')}`;
      endDateFilter = `ransack[processed_at_lteq]=${format(tempSelectedDates?.end, 'dd/MM/yyyy')}`;
    } catch {
      startDateFilter = `ransack[processed_at_gteq]=${tempSelectedDates?.start}`;
      endDateFilter = `ransack[processed_at_lteq]=${tempSelectedDates?.end}`;
    }

    let formattedStatusFilter = '';

    tempStatusFilters?.forEach((filter, index) => {
      if (index === 0) {
        formattedStatusFilter += `ransack[status_in][]=${filter}`;
      } else {
        formattedStatusFilter += `&ransack[status_in][]=${filter}`;
      }
    });

    setFilterValues({ ...filterValues, query: queryValue });
    setStatusFilters(tempStatusFilters);
    setSelectedDates({ start: tempSelectedDates.start, end: tempSelectedDates.end });

    sessionStorage.setItem(
      'paymentFilters',
      `${queryFilter}&${formattedStatusFilter}&${startDateFilter}&${endDateFilter}&sort=${filterValues.sort}`,
    );
    sessionStorage.setItem('paymentFilterQuery', queryValue);

    fetchPayments(
      `${queryFilter}&${formattedStatusFilter}&${startDateFilter}&${endDateFilter}&${knownAmountsFilterToUse}&sort=${filterValues.sort}`,
    );
  };

  const clearFilters = () => {
    setQueryValue('');

    setTempSelectedDates({
      start: new Date(1900, 0, 1),
      end: new Date(2100, 0, 1),
    });

    setSelectedDates({
      start: new Date(1900, 0, 1),
      end: new Date(2100, 0, 1),
    });

    setTempStatusFilters(null);
    setStatusFilters(null);
    setKnownAmountsFilterToUse('');

    setFilterValues({ query: '' });

    sessionStorage.removeItem('paymentFilters');
    sessionStorage.removeItem('paymentFilterQuery');
    fetchPayments();
  };

  const handleFiltersKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      applyFilters();
    }
  };

  const appliedFiltersArray = [];

  if (statusFilters) {
    appliedFiltersArray.push({
      key: 'status',
      label: `Filtered by ${statusFilters}`,
      onRemove: () => {
        setTempStatusFilters(null);
        setStatusFilters(null);
      },
    });
  }

  if (knownAmountsFilterUsed) {
    appliedFiltersArray.push({
      key: 'known_amounts',
      label: `Showing ${knownAmountsFilterUsed} Amounts`,
      onRemove: () => {
        setKnownAmountsFilterChoice(['known', 'unknown']);
        setKnownAmountsFilterUsed('');
      },
    });
  }

  if (selectedDates.start && selectedDates.end) {
    let formattedStartDate = `${selectedDates.start}`;
    let formattedEndDate = `${selectedDates.end}`;

    try {
      formattedStartDate = `${format(selectedDates?.start, 'MM/dd/yyyy')}`;
      formattedEndDate = `${format(selectedDates?.end, 'MM/dd/yyyy')}`;
    } catch {
      formattedStartDate = `${selectedDates?.start}`;
      formattedEndDate = `${selectedDates?.end}`;
    }

    if (!(formattedStartDate.includes('1900') && formattedEndDate.includes('2100'))) {
      appliedFiltersArray.push({
        key: 'selectedDates',
        label: `Filtering from ${formattedStartDate} to ${formattedEndDate}`,
        onRemove: () => {
          setTempSelectedDates({
            start: new Date(1900, 0, 1),
            end: new Date(2100, 0, 1),
          });
          setSelectedDates({
            start: new Date(1900, 0, 1),
            end: new Date(2100, 0, 1),
          });
        },
      });
    }
  }

  const filters = [
    {
      key: 'status',
      label: 'Status',
      filter: (
        <ChoiceList
          title="Status"
          titleHidden
          allowMultiple
          choices={[
            { label: 'Incomplete', value: 'incomplete' },
            { label: 'Complete', value: 'complete' },
            { label: 'Processing', value: 'processing' },
            { label: 'Hold', value: 'hold' },
            { label: 'Failed', value: 'failed' },
          ]}
          selected={tempStatusFilters || []}
          onChange={(value) => {
            setTempStatusFilters(value);
          }}
        />
      ),
      shortcut: true,
    },
    {
      key: 'processed_at',
      label: 'Date Processed',
      filter: (
        <DatePicker
          month={processedAtActive?.month}
          year={processedAtActive?.year}
          onChange={setTempSelectedDates}
          onMonthChange={(month, year) => setProcessedAtActive({ month, year })}
          selected={tempSelectedDates}
          allowRange
        />
      ),
      shortcut: true,
    },
    {
      key: 'show_unknown_amounts',
      label: 'Known Amounts',
      filter: (
        <ChoiceList
          title="Show Known Amounts"
          titleHidden
          allowMultiple
          choices={[
            { label: 'Show Payments with Known Amounts', value: 'known' },
            { label: 'Show Payments with Unknown Amounts', value: 'unknown' },
          ]}
          selected={knownAmountsFilterChoice || []}
          onChange={(value) => {
            setKnownAmountsFilterChoice(value);
          }}
        />
      ),
      shortcut: true,
    },
  ];

  return (
    <Card.Section>
      <div role="form" onKeyDown={handleFiltersKeyPress}>
        {!paymentsLoading && (
          <Filters
            queryValue={queryValue}
            queryPlaceholder="Filter by project number, insighter name, or email..."
            filters={filters}
            appliedFilters={appliedFiltersArray}
            onQueryChange={(value) => setQueryValue(value)}
            onQueryClear={() => setQueryValue('')}
            onClearAll={() => null}
          >
            <Grid container>
              <Grid item>
                <div style={{ paddingLeft: '8px' }}>
                  <Button primary disabled={errorMessage?.length > 0} onClick={applyFilters}>
                    Apply Filters
                  </Button>
                </div>
              </Grid>
              <Grid item>
                <div style={{ paddingLeft: '8px' }}>
                  <Button onClick={clearFilters}>Clear Filters</Button>
                </div>
              </Grid>
            </Grid>
          </Filters>
        )}
        {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
      </div>
    </Card.Section>
  );
};

export default PaymentFilterBar;
