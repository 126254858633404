import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from '../../utils/axios.utils';
import { InvitationPartner, InvitationPartnerFormValues } from '../model';

interface InvitationPartnersContextInterface {
  fetchInvitationPartners?: (params?: string) => Promise<void>;
  invitationPartners?: InvitationPartner[];
  invitationPartnerSubmitting?: boolean;
  createInvitationPartner?: (formValues: InvitationPartnerFormValues, onSuccess?: () => void) => Promise<void>;
  invitationPartnerLoading?: boolean;

  fetchInvitationPartner?: (invitation_partner_id: string) => Promise<void>;
  invitationPartner?: InvitationPartner;

  updateInvitationPartner?: (invitation_partner_id: string, formValues: InvitationPartnerFormValues) => Promise<void>;
  deleteInvitationPartner?: (invitation_partner_id: string, formValues: InvitationPartnerFormValues) => Promise<void>;

  totalRecords?: number;
  errorMessage?: string;

  fetchParams?: string;
  setFetchParams?: React.Dispatch<React.SetStateAction<string>>;
  invitationsDownload?: Record<string, any>[];

  invitationPartnersDownload?: Record<string, any>[];
  filterValues?: Record<string, string>;
  setFilterValues?: React.Dispatch<React.SetStateAction<Record<string, string>>>;

  updateFilterValues?: (string) => void;
}

const InvitationPartnersContext = React.createContext<InvitationPartnersContextInterface>({
  fetchInvitationPartners: undefined,
  invitationPartnerSubmitting: false,
  fetchInvitationPartner: undefined,
});

const InvitationPartnersContextConsumer = InvitationPartnersContext.Consumer;
const InvitationPartnersContextProvider: React.FC = ({ children }) => {
  const history = useHistory();

  const [invitationPartner, setInvitationPartner] = useState<InvitationPartner>({});
  const [invitationPartners, setInvitationPartners] = useState<InvitationPartner[]>([]);
  const [invitationsDownload, setInvitationsDownload] = useState<Record<string, any>[]>();
  const [invitationPartnerLoading, setInvitationPartnerLoading] = useState<boolean>(false);
  const [totalRecords, setTotalRecords] = useState<number>();
  const [errorMessage, setErrorMessage] = useState('');
  const [invitationPartnerSubmitting, setInvitationPartnerSubmitting] = useState<boolean>(false);
  const [fetchParams, setFetchParams] = useState<string>('ransack[query]=');
  const [invitationPartnersDownload, setInvitationPartnersDownload] = useState([]);
  const [filterValues, setFilterValues] = useState<Record<string, string>>({});

  const updateFilterValues = (invitationOpportunityId) => {
    const newFilter = {
      query: sessionStorage.getItem(`invitationPartnerFilterQuery-${invitationOpportunityId}`) || '',
    };
    setFilterValues(newFilter);
  };

  const fetchInvitationPartners = async (params?: string) => {
    setInvitationPartnerLoading(true);
    const response = await axios.get<string, any>(`invitation_partners.json?${params}`).then((response) => {
      setInvitationPartners(response?.data?.result);
      setTotalRecords(response?.data?.total_records);
      setInvitationPartnersDownload(response?.data?.invitation_partners_csv_data);
      setInvitationPartnerLoading(false);
    });
  };

  const createInvitationPartner = async (formValues: InvitationPartnerFormValues, onSuccess?: () => void) => {
    setInvitationPartnerSubmitting(true);

    try {
      const response = await axios.post<string, any>(`invitation_partners.json`, {
        invitation_partner: { ...formValues },
      });

      if (onSuccess) {
        onSuccess();
      } else {
        // redirect to invitation opportunity
        history.push(`/admin/invitation-opportunities/${response.data.result.invitation_opportunity_id}`);
      }
    } catch (error: any) {
      setErrorMessage(error.response.data.message.map((message: string) => `${message}\n`));
    } finally {
      setInvitationPartnerSubmitting(false);
    }
  };

  const fetchInvitationPartner = async (invitation_partner_id: string) => {
    setInvitationPartnerLoading(true);

    const response = await axios.get<string, any>(`invitation_partners/${invitation_partner_id}.json`);

    setInvitationPartner(response?.data?.result);
    setInvitationsDownload(response?.data?.result?.invitation_csv_data);
    setInvitationPartnerLoading(false);
  };

  const updateInvitationPartner = async (invitation_partner_id: string, formValues: InvitationPartnerFormValues) => {
    setInvitationPartnerSubmitting(true);

    await axios.put<string, any>(`invitation_partners/${invitation_partner_id}.json`, {
      invitation_partner: { ...formValues },
    });

    setInvitationPartnerSubmitting(false);
    history.push(`/admin/invitation-opportunities/${formValues.invitation_opportunity_id}`);
  };

  const deleteInvitationPartner = async (invitation_partner_id: string, formValues: InvitationPartnerFormValues) => {
    setInvitationPartnerSubmitting(true);

    const response = await axios.delete<string, any>(`invitation_partners/${invitation_partner_id}.json`);

    setInvitationPartnerSubmitting(false);
    history.push(`/admin/invitation-opportunities/${formValues.invitation_opportunity_id}`);
  };

  return (
    <InvitationPartnersContext.Provider
      value={{
        fetchInvitationPartners,
        invitationPartnerLoading,
        invitationPartners,
        invitationsDownload,
        createInvitationPartner,
        invitationPartnerSubmitting,
        totalRecords,
        fetchInvitationPartner,
        invitationPartner,
        updateInvitationPartner,
        errorMessage,
        deleteInvitationPartner,
        fetchParams,
        setFetchParams,
        invitationPartnersDownload,
        filterValues,
        setFilterValues,
        updateFilterValues,
      }}
    >
      {children}
    </InvitationPartnersContext.Provider>
  );
};

export { InvitationPartnersContextProvider, InvitationPartnersContextConsumer, InvitationPartnersContext };
