import React from 'react';
import { Icon, TopBar } from '@shopify/polaris';
import { ChevronRightMinor } from '@shopify/polaris-icons';
import { UserContext } from '../../auth/contexts/UserContext';

const UserMenu: React.FC = () => {
  const [userMenuActive, setUserMenuActive] = React.useState<boolean>(false);
  const { user } = React.useContext(UserContext);
  const toggleUserMenuActive = React.useCallback(() => setUserMenuActive((userMenuActive) => !userMenuActive), []);
  let userMenuActions = [];

  if (user?.roles?.includes('admin')) {
    userMenuActions = [
      {
        items: [
          {
            content: 'Dashboard',
            url: '/admin/client-projects',
          },
          {
            content: 'Admin Tools',
            url: '/admin/tools',
          },
          {
            content: 'Admin Profile',
            url: '/profile',
          },
        ],
      },
      {
        items: [
          {
            content: 'Sign Out',
            suffix: <Icon source={ChevronRightMinor} />,
            url: '/logout',
          },
        ],
      },
    ];
  } else {
    userMenuActions = [
      {
        items: [
          {
            content: 'Profile',
            url: '/opportunities',
          },
          {
            content: 'Sign Out',
            suffix: <Icon source={ChevronRightMinor} />,
            url: '/logout',
          },
        ],
      },
    ];
  }

  return (
    <TopBar.UserMenu
      actions={userMenuActions}
      name={user?.name}
      detail={user?.menu_role}
      initials={`${user?.first_name?.charAt(0)} ${user?.last_name?.charAt(0)}`}
      open={userMenuActive}
      onToggle={toggleUserMenuActive}
      colorScheme="dark"
      accessibilityLabel="User menu"
    />
  );
};

export default UserMenu;
