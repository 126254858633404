/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { Card, Checkbox } from '@shopify/polaris';
import insighterPossibleColumns from '../constants/insighterPossibleColumns';

type ColumnSelectorProps = {
  columnChoices: any;
  setVisibleColumns: any;
};

const ColumnSelector: React.FC<ColumnSelectorProps> = (props) => {
  const { columnChoices, setVisibleColumns } = props;

  // First we load the column names into columnsToUse.  Those are pushed into visibleColumns in the given order.
  // We could skip the columnsToUse step, but then columns would appear in the order they were clicked.
  const [columnsToUse, setColumnsToUse] = React.useState(columnChoices.slice(0, 5).map((col) => col.name));

  const changeColumnsToUse = (value, item) => {
    if (value) {
      const newArr = [...columnsToUse, item];
      setColumnsToUse(newArr);
    } else {
      const newArr = columnsToUse.filter((oldItem) => {
        return oldItem !== item;
      });
      setColumnsToUse(newArr);
    }
  };

  useEffect(() => {
    const newArr = [];
    columnChoices.forEach((col) => {
      if (columnsToUse.includes(col.name)) {
        newArr.push(col);
      }
    });
    setVisibleColumns(newArr);
  }, [columnsToUse]);

  return (
    <Grid container style={{ marginTop: 20 }}>
      <Card title="Columns to Show" sectioned>
        {columnChoices.map((col) => (
          <>
            <Checkbox
              key={col.name}
              disabled={false}
              label={col.name}
              checked={columnsToUse.includes(col.name)}
              onChange={(value) => changeColumnsToUse(value, col.name)}
            />
            &emsp;
          </>
        ))}
      </Card>
    </Grid>
  );
};

export default ColumnSelector;
