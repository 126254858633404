import React from 'react';

interface InformationFieldProps {
  label: string;
  value?: string | number | null;
}

const InformationField: React.FunctionComponent<InformationFieldProps> = (props) => {
  const { label, value } = props;

  return (
    <div>
      <h6 style={{ fontWeight: 'bold' }}>{label}</h6>
      <p>{value}</p>
    </div>
  );
};

export default InformationField;
