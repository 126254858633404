import React, { useState, useRef } from 'react';
import { Card, DropZone, Button } from '@shopify/polaris';
import { Grid, Typography } from '@material-ui/core';
import { useParams, useHistory } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import Page from '../shared/components/Page';
import axios from '../utils/axios.utils';
import DataTable from '../dataTable/DataTable/DataTable';
import { IDataTableColumn } from '../dataTable/DataTable/model';
import DataTableSkeleton from '../loading/components/DataTableSkeleton';

// Previous versions had a table for Rows that were already a lead.
// Plan to re-implement some version of that.
import leadColumns from '../leadGroups/constants/leadColumns';

const singleDataPoint = (row, col) => {
  let thisColor = 'yellow';
  if (row.match === 'no_match' || !row[col] || row[col] === row[`${col}_from_upload`]) {
    thisColor = '';
  }
  return <span style={{ backgroundColor: thisColor }}>{row[`${col}_from_upload`] || ''}</span>;
};

const ListUploader: React.FunctionComponent = () => {
  const history = useHistory();
  const { leadGroupId } = useParams<Record<string, string>>();
  const [loading, setLoading] = useState(false);
  const [usersLoaded, setUsersLoaded] = useState(false);
  const [errors, setErrors] = useState([]);
  const [fullMatch, setFullMatch] = useState([]);
  const [partialMatch, setPartialMatch] = useState([]);
  const [noMatch, setNoMatch] = useState([]);
  const [leadSubmitting, setLeadSubmitting] = useState(false);

  // Ideally we would use a state instead of a Ref for this purpose.
  // But updating the state during the handleSelectRow function was causing the app to freeze
  const selectedRowsRef = useRef([]);

  const handleSelectRow = (state) => {
    selectedRowsRef.current = state.selectedRows;
  };

  const matchColumns: IDataTableColumn<any>[] = [
    {
      name: 'Name',
      selector: 'name',
      grow: 0.8,
      cell: (row) => `${row.first_name} ${row.last_name}`,
    },
    {
      name: 'Emails',
      selector: 'emails',
      grow: 1.2,
      cell: (row) => (
        <div>
          <p>{row.email1}</p>
          <br />
          <p>{row.email2}</p>
        </div>
      ),
    },
    {
      name: 'State',
      selector: 'state',
      grow: 0.2,
    },

    {
      name: 'NPI #',
      selector: 'npi_number',
      grow: 0.7,
    },
    {
      name: '', // Vertical line between the two halves of the table
      selector: 'spacer',
      grow: 0.05,
      cell: (row) => <p style={{ borderLeft: '4px solid green', height: '20px' }} />,
    },
  ];

  const noMatchColumns: IDataTableColumn<any>[] = [
    {
      name: 'Name',
      selector: 'name_from_upload',
      grow: 0.8,
      cell: (row) => (
        <div>
          {singleDataPoint(row, 'first_name')} &nbsp;
          {singleDataPoint(row, 'last_name')}
        </div>
      ),
    },
    {
      name: 'Emails',
      selector: 'emails_from_upload',
      grow: 1.2,
      cell: (row) => (
        <div>
          <p>{singleDataPoint(row, 'email1')}</p>
          <br />
          <p>{singleDataPoint(row, 'email2')}</p>
        </div>
      ),
    },
    {
      name: 'State',
      selector: 'state',
      grow: 0.2,
      cell: (row) => <p>{singleDataPoint(row, 'state')}</p>,
    },

    {
      name: 'NPI #',
      selector: 'npi_number_from_upload',
      grow: 0.7,
      cell: (row) => <p>{singleDataPoint(row, 'npi_number')}</p>,
    },
  ];

  const allColumns = matchColumns.concat(noMatchColumns);

  const handleUpload = async (files: File[]) => {
    setLoading(true);
    const data = new FormData();
    const attachedFile = files[0];
    data.append(`file`, attachedFile, attachedFile.name);
    await axios.post(`/list_uploader/upload_list`, data).then((response) => {
      setFullMatch(response?.data?.full_match);
      setPartialMatch(response?.data?.partial_match);
      setNoMatch(response?.data?.no_match);
      setErrors(response?.data?.errors);
      setLoading(false);
      setUsersLoaded(true);
    });
  };

  const data = 'first_name,last_name,email1,email2, state, npi number';

  const handleAddLeads = async () => {
    const ids = selectedRowsRef.current.map((row) => row.id);
    await axios.post<string, any>(`list_uploader`, { ids, lead_group_id: leadGroupId });
    history.push(`/admin/lead_groups/${leadGroupId}`);
    setLeadSubmitting(false);
  };

  const PromptCard: React.FC = () => {
    return (
      <Card sectioned>
        <div className="Polaris-ButtonGroup__Item Polaris-ButtonGroup__Item--plain" style={{ textAlign: 'right' }}>
          <CSVLink data={data} filename="Bulk Insighters Template.csv" style={{ textDecoration: 'none' }}>
            <Button primary>Download Template</Button>
          </CSVLink>
        </div>
        <br />

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ alignItems: 'center' }}>
            <p>Drop a .csv or .xlsx file here.</p>
            <p>The list uploader will check which rows are already included in our database.</p>
            <p>
              The columns in your file must be in this order:{' '}
              <strong>First Name, Last Name, Email, Email 2, State, NPI Number</strong>
            </p>
            <p>You may include other columns after &apos;NPI Number&apos;. The rest will be ignored.</p>
          </div>
        </div>

        <br />
        <DropZone type="file" label="" onDrop={handleUpload} allowMultiple={false}>
          <DropZone.FileUpload />
        </DropZone>
      </Card>
    );
  };

  const ListCard = (props) => {
    const { columns, title, data } = props;

    return (
      <>
        <Card>
          <Grid container style={{ padding: '10px' }} justifyContent="space-between">
            <Typography className="title" variant="h4">
              {title}
            </Typography>
            <Grid xs={4} />
            <Grid xs={2} item style={{ justifyContent: 'end' }}>
              <CSVLink data={data} filename={`${title}`} style={{ textDecoration: 'none' }}>
                <Button primary>Download</Button>
              </CSVLink>
            </Grid>
          </Grid>
          <Grid xs={12} container>
            <Grid container justifyContent="space-around">
              {title !== 'No Match' && (
                <Typography className="title" variant="h5">
                  <p>From our Database</p>
                </Typography>
              )}
              <Typography className="title" variant="h5">
                <p>From the Upload</p>
              </Typography>
            </Grid>
            <br />
            <Grid>
              <DataTable
                columns={columns}
                data={data}
                striped
                highlightOnHover
                noHeader
                pagination
                paginationPerPage={25}
                paginationRowsPerPageOptions={[25]}
                selectableRows
                selectableRowsHighlight
                onSelectedRowsChange={(state) => handleSelectRow(state)}
              />
            </Grid>
            <Grid xs={2} item style={{ margin: '10px' }}>
              <Button primary disabled={leadSubmitting} onClick={handleAddLeads}>
                Add Selected Leads
              </Button>
            </Grid>
          </Grid>
        </Card>
      </>
    );
  };

  const ErrorCard: React.FC = () => {
    return (
      <>
        <Grid>
          <Card sectioned>
            <div style={{ marginBottom: 15 }}>
              <div className="Polaris-Stack Polaris-Stack--alignmentBaseline">
                <div className="Polaris-Stack__Item Polaris-Stack__Item--fill">
                  <h2 className="Polaris-Heading">Errors</h2>
                </div>
              </div>
            </div>

            {errors.map((error) => (
              <div className="Polaris-Stack__Item Polaris-Stack__Item--fill" key={`error-div-${error}`}>
                <p>{error}</p>
              </div>
            ))}
          </Card>
        </Grid>
      </>
    );
  };

  const ListCards: React.FC = () => {
    return (
      <Grid>
        <ListCard title="Full Match" columns={allColumns} data={fullMatch} />
        <br />
        <br />
        <ListCard title="Partial Match" columns={allColumns} data={partialMatch} />
        <br />
        <br />
        <ListCard title="No Match" columns={noMatchColumns} data={noMatch} />
      </Grid>
    );
  };

  const ButtonPanel: React.FC = () => {
    return (
      <div className="Polaris-Stack__Item">
        <div className="Polaris-ButtonGroup" style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div
            className="Polaris-ButtonGroup__Item Polaris-ButtonGroup__Item--plain"
            style={{ marginLeft: '32px', marginTop: '24px' }}
          >
            <Button primary onClick={() => setUsersLoaded(false)}>
              Go Back
            </Button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {usersLoaded && <ButtonPanel />}

      <Page fullWidth title="List Uploader">
        {loading && <DataTableSkeleton />}
        {!loading && !usersLoaded && <PromptCard />}
        {usersLoaded && errors?.length > 0 && <ErrorCard />}
        {usersLoaded && errors?.length === 0 && <ListCards />}
      </Page>
    </>
  );
};

export default ListUploader;
