import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from '../../utils/axios.utils';
import { RecruitmentPartner, RecruitmentPartnerFormValues } from '../model';

interface RecruitmentPartnersContextInterface {
  fetchRecruitmentPartners?: (params?: string) => void;
  fetchRecruitmentPartner?: (recruitmentPartner_id: string) => void;
  recruitmentPartnersLoading?: boolean;
  recruitmentPartner?: RecruitmentPartner;
  recruitmentPartners?: RecruitmentPartner[];

  updateRecruitmentPartner?: (recruitmentPartner_id: string, formValues: RecruitmentPartnerFormValues) => void;
  createRecruitmentPartner?: (formValues: RecruitmentPartnerFormValues) => void;
  recruitmentPartnerSubmitting?: boolean;
  formError?: string;
}

const RecruitmentPartnersContext = React.createContext<RecruitmentPartnersContextInterface>({});

const RecruitmentPartnersContextConsumer = RecruitmentPartnersContext.Consumer;
const RecruitmentPartnersContextProvider: React.FC = ({ children }) => {
  const history = useHistory();

  const [recruitmentPartners, setRecruitmentPartners] = useState<RecruitmentPartner[]>([]);
  const [recruitmentPartner, setRecruitmentPartner] = useState<RecruitmentPartner>({});
  const [recruitmentPartnersLoading, setRecruitmentPartnersLoading] = useState<boolean>(false);
  const [recruitmentPartnerSubmitting, setRecruitmentPartnerSubmitting] = useState(false);
  const [formError, setFormError] = useState<string>(undefined);

  const fetchRecruitmentPartners = async (params = '') => {
    setRecruitmentPartnersLoading(true);

    const response = await axios.get<string, any>(`recruitment_partners?${params}`);

    setRecruitmentPartnersLoading(false);
    setRecruitmentPartners(response?.data?.result);
  };

  const fetchRecruitmentPartner = async (recruitmentPartner_id: string) => {
    setRecruitmentPartnersLoading(true);

    const response = await axios.get<string, any>(`recruitment_partners/${recruitmentPartner_id}`);

    setRecruitmentPartnersLoading(false);
    setRecruitmentPartner(response?.data?.result);
  };

  const createRecruitmentPartner = async (formValues: RecruitmentPartnerFormValues) => {
    try {
      setRecruitmentPartnerSubmitting(true);
      axios
        .post<string, any>(`recruitment_partners`, {
          recruitment_partner: { ...formValues },
        })
        .then(() => {
          setRecruitmentPartnerSubmitting(false);
          history.push('/admin/recruitment-partners');
        })
        .catch((error) => {
          setRecruitmentPartnerSubmitting(false);
          setFormError(error.response.data.message);
        });
    } catch {
      setRecruitmentPartnerSubmitting(false);
      setFormError('Something went wrong, please reload the page and try again.');
    }
  };

  const updateRecruitmentPartner = async (recruitmentPartner_id: string, formValues: RecruitmentPartnerFormValues) => {
    setRecruitmentPartnerSubmitting(true);

    await axios.put<string, any>(`recruitment_partners/${recruitmentPartner_id}`, {
      recruitment_partner: { ...formValues },
    });

    setRecruitmentPartnerSubmitting(false);
    history.push('/admin/recruitment-partners');
  };

  return (
    <RecruitmentPartnersContext.Provider
      value={{
        fetchRecruitmentPartners,
        fetchRecruitmentPartner,
        recruitmentPartnersLoading,
        recruitmentPartner,
        recruitmentPartners,
        updateRecruitmentPartner,
        createRecruitmentPartner,
        recruitmentPartnerSubmitting,
        formError,
      }}
    >
      {children}
    </RecruitmentPartnersContext.Provider>
  );
};

export { RecruitmentPartnersContextProvider, RecruitmentPartnersContextConsumer, RecruitmentPartnersContext };
