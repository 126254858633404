import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useStyles from '../../constants/redesignStyles';
import MediumOrnament from '../../common/MediumOrnament';
import ReachOutToEmail from '../../common/ReachOutToEmail';

const styleOverrides = makeStyles((theme) => ({
  ...useStyles,
  heroTextWrapper: {
    marginLeft: '20%',
  },
  h1Override: {
    color: '#3B4255',
    fontFamily: 'Crimson Text',
    fontSize: '64px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '100%',
    letterSpacing: '-2.25px',
    width: '100%',
    margin: 'auto 15% auto auto',
  },
}));

const GotAQuestion: React.FC = () => {
  const classes = styleOverrides();

  return (
    <Grid container style={{ margin: '50px auto 20px', maxWidth: 1200 }}>
      <Grid
        item
        xs={12}
        md={6}
        style={{
          marginTop: 30,
          padding: 30,
          display: 'flex',
          alignItems: 'center',
          position: 'relative',
          justifyContent: 'center',
          flexDirection: 'row',
        }}
      >
        <div>
          <MediumOrnament text="Get in Touch" centerComponent={false} centerText />
          <h2 className={classes.h1Override} style={{ position: 'relative', fontSize: '36px' }}>
            Have More Questions?
          </h2>
          <p style={{ margin: '15px 0' }}>
            <strong>Our Business Hours are Monday-Friday, 9:00am-5:00pm EST</strong>
          </p>
          <p style={{ maxWidth: 400 }}>
            If you didn&apos;t find the answer to your question, drop us a note! Our team will be happy to assist you.
          </p>
          <br />
          <ReachOutToEmail />
        </div>
      </Grid>
      <Grid item xs={12} md={6} style={{ marginTop: -10, display: 'flex', justifyContent: 'center' }}>
        <img
          src="https://cdn.theinsighters.com/GotAQuestionImage.png"
          alt="Hero"
          style={{ width: '100%', height: 'auto', maxHeight: 400, maxWidth: 400, display: 'inline-block' }}
        />
      </Grid>
    </Grid>
  );
};

export default GotAQuestion;
