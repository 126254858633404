import React from 'react';
import { IDataTableColumn } from '../../dataTable/DataTable/model';
import PhaseLeadsRowMenu from '../components/PhaseLeadsRowMenu';
import { Lead } from '../model';

const leadColumns = (): IDataTableColumn[] => [
  {
    name: 'User ID',
    selector: 'uuid',
    sortable: true,
    grow: 0.7,
  },
  {
    name: 'Name',
    selector: 'name',
    sortable: true,
    cell: (row) => (
      <div>
        <a style={{ color: '#1D1D1D' }} href={`/admin/insighters/${row.insighter_id}`}>
          {row.name}
        </a>
      </div>
    ),
  },
  {
    name: 'Created At',
    selector: 'created_at',
    sortable: true,
    grow: 1.5,
  },
  {
    name: 'Account Status',
    selector: 'account_status',
    sortable: true,
    cell: (row) => (
      <div
        style={{
          fontWeight: 700,
          textTransform: 'uppercase',
          fontSize: 12,
          color: row.account_status === 'insighter' ? 'green' : 'red',
        }}
      >
        {row.account_status}
      </div>
    ),
  },
  {
    name: 'Status',
    selector: 'status',
    sortable: true,
    grow: 0.7,
    cell: (row) => (
      <div
        style={{
          fontWeight: 700,
          textTransform: 'uppercase',
          fontSize: 12,
          color: 'black',
        }}
      >
        {row.status}
      </div>
    ),
  },
  {
    cell: (lead: Lead) => <PhaseLeadsRowMenu lead={lead} />,
    name: 'ACTIONS',
    allowOverflow: true,
    button: true,
    selector: 'actions',
  },
];

export default leadColumns;
