import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import SectionOrnament from '../../common/SectionOrnament';
import Person1 from '../assets/Person1.png';
import redesignStyles from '../../constants/redesignStyles';
import FooterRectangle from '../../../../assets/redesignAssets/staticImages/FooterRectangle.png';

let marginTop = window.innerWidth <= 960 ? '18%' : '0%';

const HomepageResourcesHeader: React.FC = () => {
  const classes = redesignStyles();

  return (
    <Grid
      container
      style={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        position: 'relative',
        backgroundImage: `url(${FooterRectangle})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        margin: '15px auto',
      }}
    >
      <Grid item xs={12} md={8} style={{ display: 'flex', marginTop, alignItems: 'center', position: 'relative' }}>
        <Grid container style={{ left: '8%', position: 'relative' }}>
          <Grid item xs={9} md={6}>
            <SectionOrnament text="Unlock Insights, Empower Decisions" centerText={false} />
          </Grid>
          <Grid item xs={10}>
            <Typography className={classes.h2} style={{ color: 'white', fontSize: 60, marginBottom: 10 }}>
              Explore Our Knowledge Hub
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography className={classes.h3} style={{ color: 'white' }}>
              Dive into our resource center, a wealth of content crafted to fuel your decisions and discover how our
              research impacts healthcare.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={4} style={{ display: 'flex', justifyContent: 'center', position: 'relative' }}>
        <img
          src={Person1}
          alt="Person 1"
          style={{ width: '100%', height: '100%', borderRadius: 50, right: '8%', top: '-2%', position: 'relative' }}
        />
      </Grid>
    </Grid>
  );
};

export default HomepageResourcesHeader;
