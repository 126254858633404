import React from 'react';
import { Card, Layout, Page, Loading } from '@shopify/polaris';
import ConditionForm from '../components/ConditionForm';
import { ConditionsContext } from '../contexts/ConditionsContext';
import FormSkeleton from '../../loading/components/FormSkeleton';
import BackButton from '../../navigation/components/BackButton';

const NewCondition: React.FunctionComponent = () => {
  const [conditionFetched, setConditionFetched] = React.useState<boolean>(false);
  const { fetchFormOptions, formOptionsLoading } = React.useContext(ConditionsContext);

  React.useEffect(() => {
    if (!conditionFetched) {
      fetchFormOptions();
      setConditionFetched(true);
    }
  }, [conditionFetched, fetchFormOptions]);

  const loadingMarkup = formOptionsLoading ? <Loading /> : null;

  // ---- Page markup ----
  const actualPageMarkup = (
    <>
      <BackButton pageAction={false} />
      <Page fullWidth title="Create New Condition">
        <Layout>
          <Layout.Section>
            <Card sectioned>
              <ConditionForm />
            </Card>
          </Layout.Section>
          <Layout.Section secondary />
        </Layout>
      </Page>
    </>
  );

  // ---- Loading ----
  const loadingPageMarkup = <FormSkeleton />;

  const pageMarkup = formOptionsLoading ? loadingPageMarkup : actualPageMarkup;

  return (
    <>
      {loadingMarkup}
      {pageMarkup}
    </>
  );
};

export default NewCondition;
