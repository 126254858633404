import React from 'react';
import {
  PaymentsMajor,
  CustomersMajor,
  DataVisualizationMajor,
  OrdersMajor,
  ReferralMajor,
  CircleInformationMajor,
  QuestionMarkInverseMajor,
} from '@shopify/polaris-icons';
import { Navigation } from '@shopify/polaris';
import { NavigationContext } from '../contexts/NavigationContext';
import Logo from '../../../assets/logo.png';

interface SideNavigationProps {
  toggleIsLoading?: () => void;
}

const SideNavigation: React.FC<SideNavigationProps> = (props) => {
  const { toggleIsLoading } = props;
  const { fetchNavigation, displayInvitations, navigationLoading } = React.useContext(NavigationContext);
  const [navigationFetched, setNavigationFetched] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (!navigationFetched) {
      fetchNavigation();
      setNavigationFetched(true);
    }
  }, [fetchNavigation, navigationFetched, setNavigationFetched]);

  const [navItemActive] = React.useState<string>('all');

  const buildNavigationItems = () => {
    const defaultNavigationItems = [
      {
        label: 'My Dashboard',
        url: '/dashboard',
        icon: DataVisualizationMajor,
        onClick: () => {
          toggleIsLoading();
        },
        matches: navItemActive === 'home',
        badge: null,
      },
      {
        label: 'Find Opportunities',
        url: '/opportunities',
        icon: OrdersMajor,
        onClick: () => {
          toggleIsLoading();
        },
        matches: navItemActive === '',
      },

      {
        label: 'My Profile',
        url: '/profile',
        icon: CustomersMajor,
        onClick: () => {
          toggleIsLoading();
        },
        matches: navItemActive === '',
      },
      {
        label: 'Payments',
        url: '/payments',
        icon: PaymentsMajor,
        onClick: () => {
          toggleIsLoading();
        },
        matches: navItemActive === '',
      },
    ];

    if (displayInvitations) {
      return [
        ...defaultNavigationItems,
        {
          label: 'Invitations',
          url: '/invitations',
          icon: ReferralMajor,
          onClick: () => {
            toggleIsLoading();
          },
          matches: navItemActive === '',
        },
      ];
    }

    return defaultNavigationItems;
  };

  const secondaryRoutes = [
    {
      label: 'About',
      icon: CircleInformationMajor,
      matches: navItemActive === '',
      url: '/about',
      badge: null,
    },
    {
      label: 'FAQ',
      icon: QuestionMarkInverseMajor,
      matches: navItemActive === '',
      url: '/frequently-asked-questions',
      badge: null,
    },
  ];

  const signOutRoutes = [
    {
      label: 'Sign Out',
      icon: null,
      matches: navItemActive === '',
      url: '/logout',
      badge: null,
    },
  ];

  return (
    <Navigation location="/">
      <a href="/opportunities" className="Polaris-TopBar__NavigationIcon">
        <img alt="logo" src={Logo} style={{ height: '40px', margin: '10px 20px' }} />
      </a>
      {!navigationLoading && <Navigation.Section items={buildNavigationItems()} />}
      {!navigationLoading && <Navigation.Section items={secondaryRoutes} separator />}
      {!navigationLoading && <Navigation.Section items={signOutRoutes} />}
    </Navigation>
  );
};

export default SideNavigation;
