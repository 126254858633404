import Axios from 'axios';
import API_ENDPOINT from './env';

const API = Axios.create({
  baseURL: API_ENDPOINT,
  timeout: 120000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage?.getItem('token')}`,
  },
});

API.interceptors.response.use(
  (response) => {
    // This response header is included in the AWS production WAF for the Application Load Balancer

    if (response.headers['x-traffic-origin'] && response.headers['x-traffic-origin'] === 'international') {
      window.location.href = 'https://theinsighters.com/403.html';
    }

    return response;
  },
  (error) => {
    if (error.response && error.response.status === 307) {
      const originalRequest = { ...error.config };

      window.location.href = 'https://theinsighters.com/403.html';

      return API.request(originalRequest);
    }

    return Promise.reject(error);
  },
);

export default API;
