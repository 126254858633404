import React from 'react';
import { Button, FormLayout } from '@shopify/polaris';
import { Grid } from '@material-ui/core';
import FormInput from '../../form/components/FormInput';
import SelectInput from '../../form/components/SelectInput';
import { RecruitmentPartnersContext } from '../contexts/RecruitmentPartnersContext';
import { RecruitmentPartner, RecruitmentPartnerFormValues } from '../model';

interface RecruitmentPartnerFormProps {
  recruitmentPartner?: RecruitmentPartner;
}

const RecruitmentPartnerForm: React.FC<RecruitmentPartnerFormProps> = (props) => {
  const { recruitmentPartner } = props;
  const { formError, updateRecruitmentPartner, createRecruitmentPartner, recruitmentPartnerSubmitting } =
    React.useContext(RecruitmentPartnersContext);

  const [formValues, setFormValues] = React.useState<RecruitmentPartnerFormValues>({});

  React.useEffect(() => {
    setFormValues({
      name: recruitmentPartner?.name,
      status: recruitmentPartner?.status,
      dummy_email: recruitmentPartner?.dummy_email,
      email_domain: recruitmentPartner?.email_domain,
    });
  }, [recruitmentPartner]);

  const handleUpdate = () => {
    updateRecruitmentPartner(recruitmentPartner.id, formValues);
  };

  const handleCreate = () => {
    createRecruitmentPartner(formValues);
  };

  return (
    <FormLayout>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <h2 className="Polaris-Heading">Recruitment Partner Information</h2>
        </Grid>
        <Grid item xs={12}>
          <FormInput
            label="Name"
            type="text"
            value={formValues.name}
            onChange={(value) => setFormValues({ ...formValues, name: value })}
          />
        </Grid>
        <Grid item xs={12}>
          <FormInput
            label="Dummy Email"
            type="text"
            value={formValues.dummy_email}
            onChange={(value) => setFormValues({ ...formValues, dummy_email: value })}
          />
        </Grid>
        <Grid item xs={12}>
          <FormInput
            label="Email Domain"
            type="text"
            value={formValues.email_domain}
            onChange={(value) => setFormValues({ ...formValues, email_domain: value })}
          />
        </Grid>
        <Grid item xs={12}>
          <SelectInput
            label="Status"
            choices={[
              { label: 'Active', value: 'active' },
              { label: 'Inactive', value: 'inactive' },
            ]}
            value={formValues.status}
            onChange={(value) => setFormValues({ ...formValues, status: value })}
          />
        </Grid>
      </Grid>

      <>
        {formError && <p style={{ marginBottom: '8px', color: 'red' }}>{formError}</p>}
        {recruitmentPartner?.id ? (
          <Button primary onClick={handleUpdate} loading={recruitmentPartnerSubmitting}>
            Update Recruitment Partner
          </Button>
        ) : (
          <Button primary onClick={handleCreate} loading={recruitmentPartnerSubmitting}>
            Create Recruitment Partner
          </Button>
        )}
      </>
    </FormLayout>
  );
};

export default RecruitmentPartnerForm;
