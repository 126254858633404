import React from 'react';
import { useParams } from 'react-router-dom';
import { Card, Layout, Loading, Page, Toast } from '@shopify/polaris';
import InvitationPartnerForm from '../components/InvitationPartnerForm';
import FormSkeleton from '../../loading/components/FormSkeleton';
import { InvitationOpportunitiesContext } from '../../invitationOpportunities/contexts/InvitationOpportunitiesContext';
import BackButton from '../../navigation/components/BackButton';

const NewInvitationPartner: React.FunctionComponent = () => {
  const { invitationOpportunityId } = useParams<Record<string, string>>();

  const [toastActive, setToastActive] = React.useState(false);
  const toggleToastActive = React.useCallback(() => setToastActive((toastActive) => !toastActive), []);
  const toastMarkup = toastActive ? <Toast onDismiss={toggleToastActive} content="Changes saved" /> : null;
  const { invitationOpportunity, fetchInvitationOpportunity, invitationOpportunityLoading } =
    React.useContext(InvitationOpportunitiesContext);
  const [invitationOpportunityFetched, setInvitationOpportunityFetched] = React.useState<boolean>(false);

  const loadingMarkup = invitationOpportunityLoading ? <Loading /> : null;

  React.useEffect(() => {
    if (!invitationOpportunityFetched) {
      fetchInvitationOpportunity(invitationOpportunityId);
      setInvitationOpportunityFetched(true);
    }
  }, [
    invitationOpportunityFetched,
    fetchInvitationOpportunity,
    invitationOpportunityId,
    setInvitationOpportunityFetched,
  ]);

  const invitationPartner = {
    invitationQuota: '',
    inviterAmount: '',
    inviteeAmount: '',
    status: '',
    name: '',
    id: '',
    email: '',
  };

  const actualPageMarkup = (
    <>
      <BackButton pageAction={false} />
      <Page fullWidth title="Create Invitation Partner">
        <Layout>
          <Layout.Section>
            <Card sectioned>
              <InvitationPartnerForm
                invitationPartner={invitationPartner}
                invitationOpportunity={invitationOpportunity}
              />
            </Card>
          </Layout.Section>
          <Layout.Section secondary />
        </Layout>
      </Page>
    </>
  );

  const loadingPageMarkup = <FormSkeleton />;

  const pageMarkup = invitationOpportunityLoading ? loadingPageMarkup : actualPageMarkup;

  return (
    <>
      {loadingMarkup}
      {pageMarkup}
      {toastMarkup}
    </>
  );
};

export default NewInvitationPartner;
