import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Card, Loading, Page, Toast } from '@shopify/polaris';
import DataTable from '../../dataTable/DataTable/DataTable';
import { IDataTableColumn } from '../../dataTable/DataTable/model';
import DataTableSkeleton from '../../loading/components/DataTableSkeleton';
import { InternalUsersContext } from '../contexts/InternalUsersContext';
import { InternalUser } from '../model';

const InternalUsersList: React.FunctionComponent = () => {
  const history = useHistory();
  const [internalUsersFetched, setInternalUsersFetched] = React.useState(false);

  const { internalUsers, fetchInternalUsers, internalUserLoading } = React.useContext(InternalUsersContext);

  React.useEffect(() => {
    if (!internalUsersFetched) {
      setInternalUsersFetched(true);
      fetchInternalUsers();
    }
  }, [internalUsersFetched, setInternalUsersFetched, fetchInternalUsers]);

  const [toastActive, setToastActive] = useState(false);
  const loadingMarkup = internalUserLoading ? <Loading /> : null;

  const toggleToastActive = useCallback(() => setToastActive((toastActive) => !toastActive), []);
  const toastMarkup = toastActive ? <Toast onDismiss={toggleToastActive} content="Changes saved" /> : null;

  const columns: IDataTableColumn<any>[] = [
    {
      name: 'First Name',
      selector: 'first_name',
      sortable: true,
    },
    {
      name: 'Last Name',
      selector: 'last_name',
      sortable: true,
    },
    {
      name: 'Email',
      selector: 'email',
      sortable: true,
    },
    {
      name: 'Roles',
      selector: 'roles',
      sortable: true,
    },
  ];

  const actualPageMarkup = (
    <Page
      fullWidth
      title="All Internal Users"
      primaryAction={{
        content: 'Create Internal User',
        url: '/admin/users/new',
      }}
    >
      <Card>
        <DataTable
          onRowClicked={(row: InternalUser) => history.push(`/admin/users/${row.id}`)}
          columns={columns}
          data={internalUsers}
          progressPending={internalUserLoading}
          striped
          highlightOnHover
          pointerOnHover
          pagination
          paginationPerPage={25}
          paginationRowsPerPageOptions={[25]}
          noHeader
        />
      </Card>
    </Page>
  );

  // ---- Loading ----
  const loadingPageMarkup = <DataTableSkeleton />;

  const pageMarkup = internalUserLoading ? loadingPageMarkup : actualPageMarkup;

  return (
    <>
      {loadingMarkup}
      {pageMarkup}
      {toastMarkup}
    </>
  );
};

export default InternalUsersList;
