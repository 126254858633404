import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import useStyles from '../constants/redesignStyles';
import ShortHoverButton from './ShortHover';

const useCustomStyles = makeStyles((theme) => ({
  iconStyles: {
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    backgroundColor: '#72A295',
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '2%',
  },
  footerText: {
    color: 'white',
    [theme.breakpoints.down(960)]: {
      textAlign: 'center',
    },
  },
  textContainer: {
    position: 'relative',
    zIndex: 2,
    paddingLeft: '10%',
    paddingTop: '10%',

    [theme.breakpoints.down(960)]: {
      paddingLeft: '0%',
    },
  },
  buttonContainer: {
    backgroundColor: 'white',
    borderRadius: '20px',
    marginTop: 15,
    alignItems: 'center',
    padding: '3px 3px 3px 20px',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',

    [theme.breakpoints.down(960)]: {
      width: '90%',
      margin: '15px auto 0',
    },
  },
}));

const StayConnected: React.FC = () => {
  let classes = useStyles();
  const customClasses = useCustomStyles();
  let history = useHistory();

  let [enteredEmail, setEnteredEmail] = useState('');

  let sendToSignUp = () => {
    history.push(`/sign-up?email=${enteredEmail}`);
  };

  return (
    <Grid container style={{ position: 'relative' }}>
      <Grid item xs={12} className={customClasses.textContainer}>
        <Typography className={`${classes.h2} ${customClasses.footerText}`} style={{ color: 'white' }}>
          Stay Informed,
        </Typography>
        <Typography className={`${classes.h2} ${customClasses.footerText}`} style={{ color: 'white' }}>
          Stay Connected
        </Typography>
        <p className={customClasses.footerText} style={{ marginTop: 5, fontSize: 11, fontWeight: 400, color: 'white' }}>
          Get the Latest Opportunities, Insights & Events
        </p>
        <Grid container className={customClasses.buttonContainer}>
          <Grid item xs={5}>
            <input
              type="text"
              style={{ width: '100%', border: 'none', outline: 'none', fontFamily: 'Rethink Sans', fontSize: 14 }}
              placeholder="Enter your email"
              value={enteredEmail}
              onChange={(e) => setEnteredEmail(e.target.value)}
            />
          </Grid>
          <Grid item xs={7} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <ShortHoverButton text="Sign Me Up" onSubmit={sendToSignUp} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default StayConnected;
