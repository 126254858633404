import React, { useState, Dispatch, SetStateAction } from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import siteWideStyles from '../../../styles/siteWideStyles';
import { TeamBio } from '../../../teamBios/model';

interface BioCardProps {
  biodata: TeamBio;
  setBioModalOpen: Dispatch<SetStateAction<boolean>>;
  setBioModalInfo: Dispatch<SetStateAction<TeamBio | null>>;
}

let styles = {
  imgContainer: {
    position: 'relative' as const,
    width: '100%',
    overflow: 'hidden',
  },
  img: {
    width: '100%',
    display: 'block',
    transition: 'transform 0.5s ease-in-out',
  },
  zoomImage: {
    transform: 'scale(1.3) translate(-20px, 20px)',
  },
  imgOverlay: {
    content: '""',
    position: 'absolute' as const,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    background: 'linear-gradient(to right, rgba(255,255,255, 0) 75%, white 100%)',
    pointerEvents: 'none' as const, // Allows interaction with the underlying image
  },
};

const BioCard: React.FC<BioCardProps> = (props) => {
  let { biodata, setBioModalOpen, setBioModalInfo } = props;

  let [hover, setHover] = useState(false);

  let handleButtonClicked = () => {
    setBioModalInfo(biodata);
    setBioModalOpen(true);
  };

  return (
    <Grid
      container
      style={{ backgroundColor: 'white', borderRadius: 25, boxShadow: '10px 10px 8px #888888' }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Grid item xs={5} style={styles.imgContainer}>
        <img
          src={biodata?.main_image}
          alt="employee-headshot"
          style={hover ? { ...styles.img, ...styles.zoomImage } : styles.img}
        />
        <div style={styles.imgOverlay} />
      </Grid>
      <Grid item xs={7} style={{ display: 'flex', alignItems: 'center', padding: 15 }}>
        <Grid container>
          <Grid item xs={12}>
            <Typography style={{ fontFamily: 'Crimson Text', fontSize: 36, fontWeight: 600, color: '#3B4255' }}>
              {biodata?.name}
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ marginTop: -12 }}>
            <img src="https://cdn.theinsighters.com/BioUnderline.png" alt="" />
          </Grid>
          <Grid item xs={12}>
            <Typography style={{ fontFamily: 'Rethink Sans', fontSize: 16, fontWeight: 600, color: '#3B4255' }}>
              {biodata?.title}
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
            <Grid style={{ ...siteWideStyles.hoverButton, width: 150 }} onClick={handleButtonClicked}>
              <Typography style={siteWideStyles.hoverButtonText}>View Bio</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BioCard;
