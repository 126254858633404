import React, { useEffect, useState, useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import { ProjectsContext } from '../contexts/ProjectsContext';
import ProjectForm from '../components/ProjectForm';

import AdminPageHeader from '../../adminTools/components/AdminPageHeader';

const NewProject: React.FunctionComponent = () => {
  const [projectFetched, setProjectFetched] = useState(false);
  const { project, fetchFormOptions } = useContext(ProjectsContext);

  useEffect(() => {
    if (!projectFetched) {
      fetchFormOptions();
      setProjectFetched(true);
    }
  }, [projectFetched, fetchFormOptions]);

  return (
    <Grid container style={{ padding: '1% 2%' }}>
      <AdminPageHeader title="Create New Project" />
      <Grid item xs={12}>
        <ProjectForm project={project} />
      </Grid>
    </Grid>
  );
};

export default NewProject;
