import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, Typography, Button } from '@material-ui/core';
import { Testimonial } from '../model';
import axios from '../../utils/axios.utils';
import TestimonialForm from '../components/TestimonialForm';

import AdminPageHeader from '../../adminTools/components/AdminPageHeader';

const CreateTestimonialPage: React.FC = () => {
  const history = useHistory();

  const [formValues, setFormValues] = useState<Testimonial>();
  const [formErrors, setFormErrors] = useState<string>('');
  const [imageFileData, setImageFileData] = useState<FormData>();

  const createTestimonial = async () => {
    const combinedFormData = new FormData();

    // Append the image file, then the rest of the formValues
    if (imageFileData) {
      imageFileData.forEach((value, key) => {
        combinedFormData.append(key, value);
      });
    }

    Object.entries(formValues).forEach(([key, value]) => {
      combinedFormData.append(`testimonial[${key}]`, value);
    });

    try {
      await axios.post('testimonials', combinedFormData);
      history.push(`/admin/testimonials`);
    } catch (error: any) {
      setFormErrors(error?.response?.data?.message);
    }
  };

  return (
    <Grid container style={{ padding: 10 }} spacing={3}>
      <AdminPageHeader title="Create Testimonial" backButton={{ link: '/admin/testimonials', label: 'Testimonials' }} />
      <Grid item xs={12}>
        <TestimonialForm
          formValues={formValues}
          setFormValues={setFormValues}
          formErrors={formErrors}
          setImageFileData={setImageFileData}
        />
      </Grid>
      <Grid item xs={3}>
        <Button onClick={() => createTestimonial()} className="primary-btn">
          Create Testimonial
        </Button>
      </Grid>
    </Grid>
  );
};

export default CreateTestimonialPage;
