import React from 'react';
import { useHistory } from 'react-router-dom';
import axios from '../../utils/axios.utils';
import { Condition, ConditionFormValues } from '../model';
import { SelectOption } from '../../form/model';

interface ConditionsContextInterface {
  fetchConditions?: (params?: string) => void;
  fetchCondition?: (condition_id: string) => void;
  conditionsLoading?: boolean;
  formOptionsLoading?: boolean;
  condition?: Condition;
  conditions?: Condition[];
  totalRecords?: number;
  indexCategoryName?: string;

  fetchParams?: string;
  setFetchParams?: React.Dispatch<React.SetStateAction<string>>;

  sortParams?: string;
  setSortParams?: React.Dispatch<React.SetStateAction<string>>;

  fetchFormOptions?: () => void;
  formOptions?: Record<string, SelectOption[]>;
  createCondition?: (formValues: ConditionFormValues) => void;
  updateCondition?: (condition_id: string, formValues: ConditionFormValues) => void;
  deleteCondition?: (condition_id: string) => void;
  conditionSubmitting?: boolean;
  formError?: string;

  conditionsCSV?: Record<string, any>[];
  setConditionsCSV?: React.Dispatch<React.SetStateAction<Record<string, any>[]>>;
}

const ConditionsContext = React.createContext<ConditionsContextInterface>({});

const ConditionsContextConsumer = ConditionsContext.Consumer;
const ConditionsContextProvider: React.FC = ({ children }) => {
  const history = useHistory();

  const [conditions, setConditions] = React.useState<Condition[]>([]);
  const [condition, setCondition] = React.useState<Condition>({});
  const [formOptions, setFormOptions] = React.useState<Record<string, SelectOption[]>>({});
  const [conditionsLoading, setConditionsLoading] = React.useState<boolean>(false);
  const [formOptionsLoading, setFormOptionsLoading] = React.useState<boolean>(false);
  const [conditionSubmitting, setConditionSubmitting] = React.useState(false);
  const [totalRecords, setTotalRecords] = React.useState<number>();
  const [fetchParams, setFetchParams] = React.useState<string>('ransack[query]=');
  const [sortParams, setSortParams] = React.useState<string>('sort=name asc');
  const [indexCategoryName, setIndexCategoryName] = React.useState('');
  const [conditionsCSV, setConditionsCSV] = React.useState<Record<string, any>[]>([]);

  const fetchConditions = async (params = '') => {
    setConditionsLoading(true);

    const response = await axios.get<string, any>(`conditions?${params}`);

    setConditions(response?.data?.result);
    setConditionsCSV(response?.data?.conditions_csv);
    setTotalRecords(response?.data?.total_records);
    setIndexCategoryName(response?.data?.index_category_name);
    setConditionsLoading(false);
  };

  const fetchCondition = async (condition_id: string) => {
    setConditionsLoading(true);

    const response = await axios.get<string, any>(`conditions/${condition_id}`);

    setConditionsLoading(false);
    setCondition(response?.data?.result);
  };

  const createCondition = async (formValues: ConditionFormValues) => {
    setConditionSubmitting(true);

    await axios.post<string, any>(`conditions`, {
      condition: { ...formValues },
    });

    setConditionSubmitting(false);
    history.push('/admin/conditions');
  };

  const updateCondition = async (condition_id: string, formValues: ConditionFormValues) => {
    setConditionSubmitting(true);

    await axios.put<string, any>(`conditions/${condition_id}`, {
      condition: { ...formValues },
    });

    setConditionSubmitting(false);
    history.push('/admin/conditions');
  };

  const deleteCondition = async (condition_id: string) => {
    setConditionSubmitting(true);

    await axios.delete<string, any>(`conditions/${condition_id}`);

    setConditionSubmitting(false);
    history.push('/admin/conditions');
  };

  const fetchFormOptions = async () => {
    setFormOptionsLoading(true);
    const response = await axios.get<string, any>(`conditions/new`);

    setFormOptions(response?.data);
    setFormOptionsLoading(false);
  };

  return (
    <ConditionsContext.Provider
      value={{
        fetchConditions,
        fetchCondition,
        conditionsLoading,
        formOptionsLoading,
        condition,
        conditions,
        totalRecords,
        indexCategoryName,

        fetchParams,
        setFetchParams,

        sortParams,
        setSortParams,

        fetchFormOptions,
        formOptions,
        createCondition,
        updateCondition,
        deleteCondition,
        conditionSubmitting,

        conditionsCSV,
      }}
    >
      {children}
    </ConditionsContext.Provider>
  );
};

export { ConditionsContextProvider, ConditionsContextConsumer, ConditionsContext };
