import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Grid, Card } from '@mui/material';
import { InsighterRolesContext } from '../contexts/InsighterRolesContext';
import ColumnSelector from '../../shared/components/ColumnSelector';
import DataTable from '../../dataTable/DataTable/DataTable';
import { Insighter } from '../../insighters/model';
import insighterPossibleColumns from '../../shared/constants/insighterPossibleColumns';
import PrepareDownloadButton from '../../shared/components/PrepareDownloadButton';

import AdminPageHeader from '../../adminTools/components/AdminPageHeader';

const InsighterRolesDetail: React.FunctionComponent = () => {
  const history = useHistory();
  const { insighterRoleId } = useParams<Record<string, string>>();
  const { insighterRole, fetchInsighterRole, insighterRolesLoading } = React.useContext(InsighterRolesContext);
  const [insighters, setInsighters] = useState<Insighter[]>([]);

  const [visibleColumns, setVisibleColumns] = useState([]);

  useEffect(() => {
    fetchInsighterRole(insighterRoleId);
    // eslint-disable-next-line
  }, [insighterRoleId]);

  useEffect(() => {
    if (insighterRole?.insighters) {
      setInsighters(insighterRole.insighters);
    }
  }, [insighterRole]);

  return (
    <Grid container style={{ padding: '1% 2%' }}>
      <AdminPageHeader title={`All Insighters with ${insighterRole.name}`} />
      <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
        <PrepareDownloadButton
          filename={`Insighters with Insighter Role ${insighterRole?.name}.csv`}
          visibleColumns={visibleColumns}
          link={`/insighter_roles/${insighterRoleId}/download`}
          includeResult
        />
      </Grid>
      <Grid item xs={12} style={{ margin: 10 }}>
        <ColumnSelector setVisibleColumns={setVisibleColumns} columnChoices={insighterPossibleColumns} />
      </Grid>
      <Grid item xs={12}>
        <Card>
          <DataTable
            onRowClicked={(row: Record<string, string>) => history.push(`/admin/insighters/${row.id}`)}
            columns={visibleColumns}
            data={insighters}
            pagination
            progressPending={insighterRolesLoading}
            striped
            highlightOnHover
            pointerOnHover
            noHeader
            paginationPerPage={25}
            paginationRowsPerPageOptions={[25]}
            paginationTotalRows={insighters?.length}
          />
        </Card>
      </Grid>
    </Grid>
  );
};

export default InsighterRolesDetail;
