import React from 'react';
import { Card, Layout } from '@shopify/polaris';
import Page from '../../shared/components/Page';
import RecruitmentPartnerForm from '../components/RecruitmentPartnerForm';
import BackButton from '../../navigation/components/BackButton';

const NewRecruitmentPartner: React.FunctionComponent = () => {
  return (
    <>
      <BackButton pageAction={false} />
      <Page fullWidth title="Create New Recruitment Partner">
        <Layout>
          <Layout.Section>
            <Card sectioned>
              <RecruitmentPartnerForm />
            </Card>
          </Layout.Section>
          <Layout.Section secondary />
        </Layout>
      </Page>
    </>
  );
};

export default NewRecruitmentPartner;
