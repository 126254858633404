import React from 'react';

import { useHistory } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';

import OpportunityFilterBar from '../components/OpportunityFilterBar';
import OpportunitiesList from '../components/OpportunitiesList';
import AdminPageHeader from '../../adminTools/components/AdminPageHeader';

const OpportunitiesListContainer: React.FunctionComponent = () => {
  const history = useHistory();

  const CreateOpportunityButton = () => (
    <Button className="primary-btn" onClick={() => history.push('/admin/opportunities/new')}>
      Create Opportunity
    </Button>
  );

  return (
    <Grid container style={{ padding: '1% 2%' }}>
      <AdminPageHeader title="Opportunities" adminButtons={<CreateOpportunityButton />} />
      <Card style={{ padding: '1% 2%' }}>
        <OpportunityFilterBar />
        <OpportunitiesList />
      </Card>
    </Grid>
  );
};

export default OpportunitiesListContainer;
