/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { TextField, FormControl, Chip } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import axios from '../../utils/axios.utils';

interface MultiSelectInputProps {
  label: string;
  onChange: (value: any) => void;
  value: any[];
}

const MultiSelectInput: React.FC<MultiSelectInputProps> = (props) => {
  const { label, onChange, value } = props;
  const [conditionOptions, setConditionOptions] = React.useState([]);

  React.useEffect(() => {
    axios.get<string, any>(`condition_options`).then((response) => {
      setConditionOptions(response?.data?.condition_options);
    });
  }, []);

  return conditionOptions?.length > 0 ? (
    <div>
      <FormControl sx={{ width: '100%' }}>
        <div className="Polaris-Labelled__LabelWrapper">
          <div className="Polaris-Label">
            <label className="Polaris-Label__Text" htmlFor="formInput">
              {label}
            </label>
          </div>
        </div>
        <Autocomplete
          style={{ backgroundColor: '#e8e8e8' }}
          multiple
          id="conditions-autocomplete"
          value={value}
          onChange={(_event, newValue) => {
            onChange(newValue.map((value) => (typeof value === 'string' ? value : value.label.toString())));
          }}
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              <Chip
                key={index}
                label={typeof option === 'string' ? option : option.label}
                style={{ fontSize: '1.2rem' }}
                {...getTagProps({ index })}
              />
            ))
          }
          options={conditionOptions}
          groupBy={(option) => option.condition_category}
          getOptionLabel={(option) => option.label}
          renderOption={(props, option) => (
            <li key={option.value} style={{ fontSize: '1.4rem' }} {...props}>
              {option.label}
            </li>
          )}
          renderInput={(params) => <TextField {...params} />}
        />
      </FormControl>
    </div>
  ) : (
    <div>Loading...</div>
  );
};

export default MultiSelectInput;
