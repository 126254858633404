import React from 'react';
import { Grid, Typography, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import DataTable from '../../dataTable/DataTable/DataTable';
import { IDataTableColumn } from '../../dataTable/DataTable/model';

// Make the other two cards on the Insighter Detail match this one

const columns: IDataTableColumn<any>[] = [
  {
    name: 'ID',
    selector: 'uuid',
    sortable: true,
  },
  {
    name: 'Name',
    selector: 'name',
    sortable: true,
  },
  {
    name: 'Tags',
    selector: 'insighter_tags',
    sortable: true,
  },
  {
    name: 'Created At',
    selector: 'created_at',
    sortable: true,
    grow: 1.2,
  },
  {
    name: 'Profile',
    selector: 'insighter_id',
    sortable: true,
    cell: (row) => (row.insighter_id ? 'View' : 'X'),
  },
];

interface AssociatedAccountProps {
  associatedAccounts: any;
}

const AssociatedAccounts: React.FC<AssociatedAccountProps> = (props) => {
  const { associatedAccounts } = props;

  const handleRowClicked = (row: any) => {
    if (row.insighter_id) {
      window.open(`/admin/insighters/${row.insighter_id}`, '_blank');
    }
  };

  return (
    <>
      <Accordion>
        <AccordionSummary>
          <Typography variant="h5" style={{ fontSize: 14, fontWeight: 'bold' }}>
            Associated Accounts ({associatedAccounts?.length})
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h6" style={{ fontSize: 14 }}>
                The users below have shared at least one IP address in common with this user
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <DataTable
                onRowClicked={(row) => handleRowClicked(row)}
                columns={columns}
                data={associatedAccounts}
                striped
                highlightOnHover
                pointerOnHover
                noHeader
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default AssociatedAccounts;
