import React, { useState, useEffect, useContext } from 'react';
import { Button, Card, FormLayout } from '@shopify/polaris';
import { Grid } from '@material-ui/core';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import ConditionAutocompleteInput from '../../form/components/ConditionAutocompleteInput';
import { ProjectsContext } from '../contexts/ProjectsContext';
import FormInput from '../../form/components/FormInput';
import SelectInput from '../../form/components/SelectInput';
import { Project, ProjectFormValues } from '../model';

interface ProjectFormProps {
  project?: Project;
}

const ProjectForm: React.FC<ProjectFormProps> = (props) => {
  const { project } = props;
  const { updateProject, createProject, projectSubmitting, formOptions, errorMessage } = useContext(ProjectsContext);

  const [formValues, setFormValues] = useState<ProjectFormValues>({});
  const [editorState, setEditorState] = useState();

  useEffect(() => {
    if (project?.notes) {
      const blocksFromHtml = htmlToDraft(project.notes);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
      const initialState = EditorState.createWithContent(contentState);
      setEditorState(initialState);
    }

    setFormValues({
      ...project,
      condition_names: project.condition_names || [],
      notes: project.notes || '\n',
      overall_start_date: project?.project_timeline?.overall_start_date_form,
      overall_end_date: project?.project_timeline?.overall_end_date_form,
      pre_recruit_start_date: project?.project_timeline?.pre_recruit_start_date_form,
      pre_recruit_end_date: project?.project_timeline?.pre_recruit_end_date_form,
      official_recruit_start_date: project?.project_timeline?.official_recruit_start_date_form,
      official_recruit_end_date: project?.project_timeline?.official_recruit_end_date_form,
      fielding_start_date: project?.project_timeline?.fielding_start_date_form,
      fielding_end_date: project?.project_timeline?.fielding_end_date_form,
    });
  }, [project]);

  const handleUpdate = () => {
    updateProject(project.id, formValues);
  };

  const handleCreate = () => {
    createProject(formValues);
  };

  return (
    <FormLayout>
      <Card title="Project Information" sectioned>
        <Grid container spacing={3}>
          <Grid item xs={8}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <FormInput
                  type="text"
                  label="Project Number"
                  value={formValues.project_number}
                  onChange={(value) => setFormValues({ ...formValues, project_number: value })}
                />
              </Grid>
              <Grid item xs={6}>
                <FormInput
                  type="text"
                  label="Project Name"
                  value={formValues.name}
                  onChange={(value) => setFormValues({ ...formValues, name: value })}
                />
              </Grid>
              <Grid item xs={12}>
                <FormInput
                  type="text"
                  label="Client Name"
                  tooltip='Use "Sommer Consulting" for internal projects.'
                  onChange={(value) => setFormValues({ ...formValues, client_name: value })}
                  value={formValues.client_name}
                />
              </Grid>
              <Grid item xs={6}>
                <SelectInput
                  label="Status"
                  choices={[
                    { label: 'Draft', value: 'draft' },
                    { label: 'Published', value: 'published' },
                    { label: 'Inactive', value: 'inactive' },
                    { label: 'Archived', value: 'archived' },
                  ]}
                  onChange={(value) => setFormValues({ ...formValues, status: value })}
                  value={formValues.status}
                />
              </Grid>
              <Grid item xs={6}>
                <SelectInput
                  label="Automatically Update Status"
                  choices={[
                    { label: 'Yes', value: 'true' },
                    { label: 'No', value: 'false' },
                  ]}
                  value={formValues.update_status}
                  onChange={(value) => setFormValues({ ...formValues, update_status: value })}
                />
              </Grid>
              <Grid item xs={6}>
                <SelectInput
                  label="Project Manager"
                  choices={formOptions.project_manager_options}
                  onChange={(value) => setFormValues({ ...formValues, project_manager_id: value })}
                  value={formValues.project_manager_id}
                />
              </Grid>
              <Grid item xs={6}>
                <SelectInput
                  label="Secondary Project Manager"
                  choices={formOptions.project_manager_options}
                  onChange={(value) => setFormValues({ ...formValues, secondary_project_manager_id: value })}
                  value={formValues.secondary_project_manager_id}
                />
              </Grid>
              <Grid item xs={6}>
                <SelectInput
                  label="Primary Moderator"
                  choices={formOptions.project_manager_options}
                  onChange={(value) => setFormValues({ ...formValues, primary_moderator_id: value })}
                  value={formValues.primary_moderator_id}
                />
              </Grid>
              <Grid item xs={6}>
                <SelectInput
                  label="Secondary Moderator"
                  choices={formOptions.project_manager_options}
                  onChange={(value) => setFormValues({ ...formValues, secondary_moderator_id: value })}
                  value={formValues.secondary_moderator_id}
                />
              </Grid>
              <Grid item xs={6}>
                <SelectInput
                  label="Recruiter"
                  choices={formOptions.project_manager_options}
                  onChange={(value) => setFormValues({ ...formValues, recruiter_id: value })}
                  value={formValues.recruiter_id}
                />
              </Grid>
              <Grid item xs={6}>
                <SelectInput
                  label="TI Recruiter"
                  choices={formOptions.ti_recruiter_options}
                  onChange={(value) => setFormValues({ ...formValues, ti_recruiter_id: value })}
                  value={formValues.ti_recruiter_id}
                />
              </Grid>
              <Grid item xs={6}>
                <FormInput
                  type="text"
                  label="Marketing Budget"
                  value={formValues.marketing_budget}
                  onChange={(value) => setFormValues({ ...formValues, marketing_budget: value })}
                />
              </Grid>

              <Grid item xs={12}>
                {formValues.condition_names && (
                  <>
                    <ConditionAutocompleteInput
                      label="Conditions"
                      onChange={(value) => {
                        setFormValues({ ...formValues, condition_names: value });
                      }}
                      value={formValues.condition_names}
                    />
                    <p style={{ fontSize: 'small', fontStyle: 'italic' }}>Leave blank to allow for any condition</p>
                  </>
                )}
              </Grid>
            </Grid>
            <br />
            <div className="Polaris-Labelled__LabelWrapper">
              <div className="Polaris-Label">
                <p className="Polaris-Label__Text">Notes</p>
              </div>
            </div>
            <Grid>
              <Editor
                editorState={editorState}
                wrapperClassName="demo-wrapper"
                editorClassName="demo-editor"
                onEditorStateChange={(value) => {
                  setEditorState(value);
                  setFormValues({
                    ...formValues,
                    notes: draftToHtml(convertToRaw(value.getCurrentContent())).replaceAll('<p></p>', '<br />'),
                  });
                }}
                toolbar={{
                  inline: { inDropdown: false },
                  list: {
                    inDropdown: false,
                    className: undefined,
                    component: undefined,
                    dropdownClassName: undefined,
                    options: ['unordered', 'ordered'],
                  },
                  textAlign: { inDropdown: true },
                  history: { inDropdown: false },
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Card>
      <Card title="Project Timeline" sectioned>
        <Grid container spacing={3}>
          <Grid item xs={8}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <FormInput
                  type="date"
                  label="Overall Start Date"
                  tooltip="Overall Start Date"
                  value={formValues.overall_start_date}
                  onChange={(value: string) => setFormValues({ ...formValues, overall_start_date: value })}
                />
              </Grid>
              <Grid item xs={6}>
                <FormInput
                  type="date"
                  label="Overall End Date"
                  tooltip="Overall End Date"
                  value={formValues.overall_end_date}
                  onChange={(value: string) => setFormValues({ ...formValues, overall_end_date: value })}
                />
              </Grid>
              <Grid item xs={6}>
                <FormInput
                  type="date"
                  value={formValues?.pre_recruit_start_date}
                  label="Pre-recruit Start Date"
                  onChange={(value: string) => setFormValues({ ...formValues, pre_recruit_start_date: value })}
                />
              </Grid>
              <Grid item xs={6}>
                <FormInput
                  type="date"
                  label="Pre-recruit End Date"
                  tooltip="Pre-recruit End Date"
                  value={formValues.pre_recruit_end_date}
                  onChange={(value: string) => setFormValues({ ...formValues, pre_recruit_end_date: value })}
                />
              </Grid>
              <Grid item xs={6}>
                <FormInput
                  type="date"
                  label="Official Recruit Start Date"
                  tooltip="Official Recruit Start Date"
                  value={formValues.official_recruit_start_date}
                  onChange={(value: string) => setFormValues({ ...formValues, official_recruit_start_date: value })}
                />
              </Grid>
              <Grid item xs={6}>
                <FormInput
                  type="date"
                  label="Official Recruit End Date"
                  tooltip="Official Recruit End Date"
                  value={formValues.official_recruit_end_date}
                  onChange={(value: string) => setFormValues({ ...formValues, official_recruit_end_date: value })}
                />
              </Grid>
              <Grid item xs={6}>
                <FormInput
                  type="date"
                  label="Fielding Start Date"
                  tooltip="Fielding Start Date"
                  value={formValues.fielding_start_date}
                  onChange={(value: string) => setFormValues({ ...formValues, fielding_start_date: value })}
                />
              </Grid>
              <Grid item xs={6}>
                <FormInput
                  type="date"
                  label="Fielding End Date"
                  tooltip="Fielding End Date"
                  value={formValues.fielding_end_date}
                  onChange={(value: string) => setFormValues({ ...formValues, fielding_end_date: value })}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <br />
        {errorMessage && <p style={{ marginBottom: '8px', color: 'red' }}>{errorMessage}</p>}
        {project.id ? (
          <Button primary onClick={handleUpdate} loading={projectSubmitting}>
            Update Project
          </Button>
        ) : (
          <Button primary onClick={handleCreate} loading={projectSubmitting}>
            Create Project
          </Button>
        )}
      </Card>
    </FormLayout>
  );
};

export default ProjectForm;
