import React from 'react';
import { Button, Card, ChoiceList, Filters } from '@shopify/polaris';
import { Grid } from '@material-ui/core';
import { ProjectsContext } from '../contexts/ProjectsContext';

type FilterBarProps = {
  financial: boolean;
};

const ClientProjectfilterBar: React.FunctionComponent<FilterBarProps> = (props) => {
  const { financial } = props;
  const [filterValues, setFilterValues] = React.useState<Record<string, string>>({
    query: sessionStorage.getItem('clientProjectFilterQuery') || '',
  });
  const { fetchProjects, statusFilter, setStatusFilter, setFilterParams, sortParams } =
    React.useContext(ProjectsContext);
  const [appliedFiltersDisplay, setAppliedFiltersDisplay] = React.useState<any[]>();

  React.useEffect(() => {
    const appliedFiltersArray = [];

    if (statusFilter) {
      appliedFiltersArray.push({
        key: 'status',
        label: `Filtered by ${statusFilter}`,
        onRemove: () => {
          setStatusFilter('');
          setFilterValues({ ...filterValues, status: null });
        },
      });
    }

    setAppliedFiltersDisplay([...appliedFiltersArray]);
  }, [statusFilter, setStatusFilter, filterValues]);

  const applyFilters = () => {
    const queryFilter = `ransack[query]=${filterValues.query}`;
    const statusFilter = filterValues.status ? `ransack[status_eq]=${filterValues.status}` : '';

    sessionStorage.setItem(
      'clientProjectFilters',
      `ransack[client_name_not_eq]=Sommer Consulting&${queryFilter}&${statusFilter}&${sortParams}`,
    );
    sessionStorage.setItem('clientProjectFilterQuery', filterValues.query);

    let fetchParams = `ransack[client_name_not_eq]=Sommer Consulting&${queryFilter}&${statusFilter}&${sortParams}`;

    if (financial) {
      fetchParams += '&financial=true';
    }

    setFilterParams(`${queryFilter}&${statusFilter}`);
    fetchProjects(fetchParams);
  };

  const clearFilters = () => {
    setFilterValues({ query: '', status: '' });
    setFilterParams('ransack[query]=');
    sessionStorage.removeItem('clientProjectFilters');
    sessionStorage.removeItem('clientProjectFilterQuery');
    let fetchParams = 'ransack[client_name_not_eq]=Sommer Consulting';

    if (financial) {
      fetchParams += '&financial=true';
    }

    fetchProjects(fetchParams);
  };

  const handleFiltersKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      applyFilters();
    }
  };

  return (
    <Card.Section>
      <div role="form" onKeyDown={handleFiltersKeyPress}>
        <Filters
          queryValue={filterValues.query}
          queryPlaceholder="Filter by project number, project name, or phase name..."
          filters={[
            {
              key: 'status',
              label: 'Status',
              filter: (
                <ChoiceList
                  title="Status"
                  titleHidden
                  choices={[
                    { label: 'Draft', value: 'draft' },
                    { label: 'Published', value: 'published' },
                    { label: 'Inactive', value: 'inactive' },
                    { label: 'Archived', value: 'archived' },
                  ]}
                  selected={[filterValues.status]}
                  onChange={(value) => setFilterValues({ ...filterValues, status: value[0] })}
                />
              ),
              shortcut: true,
            },
          ]}
          appliedFilters={appliedFiltersDisplay}
          onQueryChange={(value) => setFilterValues({ ...filterValues, query: value })}
          onQueryClear={() => setFilterValues({ query: '' })}
          onClearAll={() => null}
        >
          <Grid container>
            <Grid item>
              <div style={{ paddingLeft: '8px' }}>
                <Button primary onClick={applyFilters}>
                  Apply Filters
                </Button>
              </div>
            </Grid>
            <Grid item>
              <div style={{ paddingLeft: '8px' }}>
                <Button onClick={clearFilters}>Clear Filters</Button>
              </div>
            </Grid>
          </Grid>
        </Filters>
      </div>
    </Card.Section>
  );
};

export default ClientProjectfilterBar;
