import React from 'react';
import { Grid } from '@material-ui/core';
import FooterRectangle from '../../../assets/redesignAssets/staticImages/FooterRectangle.png';
import useStyles from '../constants/redesignStyles';
import LogoWhite from '../../../assets/LogoWhite.png';
import MobileFooterBackground from './assets/MobileFooterBackground.png';
import FooterSocialLinks from './FooterSocialLinks';
import FooterLinks from './FooterLinks';
import HoverButton from './HoverButton';
import FooterEmailSubmission from './FooterEmailSubmission';
import FooterEmailSubmissionMobile from './FooterEmailSubmissionMobile';

const Footer: React.FC = () => {
  const classes = useStyles();
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    const handleResize = () => {
      const newWidth = window.innerWidth;
      if (newWidth !== windowWidth) {
        setWindowWidth(newWidth);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [windowWidth]);

  return windowWidth <= 960 ? (
    <div
      style={{
        backgroundImage: `url(${MobileFooterBackground})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        height: 'auto',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        position: 'relative',
        paddingBottom: '5%',
        paddingTop: 50,
        maxWidth: 1400,
      }}
    >
      <Grid container>
        <Grid item xs={12} md={5} style={{ display: 'flex', alignItems: 'center' }}>
          <Grid container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <img src={LogoWhite} alt="Logo White" style={{ width: '50%', height: 'auto' }} />
            </Grid>
            <Grid item xs={4} style={{ marginTop: 15 }}>
              <FooterSocialLinks />
            </Grid>
            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', marginTop: 15 }}>
              <HoverButton text="Got a Question?" link="/frequently-asked-questions" />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={7} style={{ display: 'block', marginBottom: 60 }}>
          <FooterEmailSubmissionMobile />
        </Grid>
        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', marginTop: -40 }}>
          <div className={classes.hrLine} style={{ width: '70%' }} />
        </Grid>
        <Grid item xs={12} style={windowWidth <= 960 ? {} : { position: 'absolute', bottom: '100px', width: '70%' }}>
          <FooterLinks />
        </Grid>
      </Grid>
    </div>
  ) : (
    <Grid
      container
      style={{
        backgroundImage: `url(${FooterRectangle})`,
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        height: '500px',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        position: 'relative',
        paddingBottom: '5%',
        maxWidth: 1400,
        margin: 'auto',
      }}
    >
      <Grid item xs={12} md={5} style={{ display: 'flex', alignItems: 'center', marginBottom: 50 }}>
        <Grid container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '10%' }}>
          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <img src={LogoWhite} alt="Logo White" style={{ width: '50%', height: 'auto' }} />
          </Grid>
          <Grid item xs={3} style={{ marginTop: 15 }}>
            <FooterSocialLinks />
          </Grid>
          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', marginTop: 15 }}>
            <HoverButton text="Got a Question?" link="/frequently-asked-questions" />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={7} style={{ paddingRight: '5%' }}>
        <FooterEmailSubmission />
      </Grid>
      <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
        <div className={classes.hrLine} style={{ width: '80%' }} />
      </Grid>
      <Grid item xs={12} style={{ position: 'absolute', bottom: '75px', width: '70%' }}>
        <FooterLinks />
      </Grid>
    </Grid>
  );
};

export default Footer;
