import React from 'react';

import { useHistory } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';

import InternalProjectFilterBar from '../components/InternalProjectFilterBar';
import InternalProjectsList from '../components/InternalProjectsList';

import AdminPageHeader from '../../adminTools/components/AdminPageHeader';

const InternalProjectsListContainer: React.FunctionComponent = () => {
  const history = useHistory();

  const CreateProjectButton = () => (
    <Button className="primary-btn" onClick={() => history.push('/admin/projects/new')}>
      Create Project
    </Button>
  );

  return (
    <Grid container style={{ padding: '1% 2%' }}>
      <AdminPageHeader title="Internal Projects" adminButtons={<CreateProjectButton />} />
      <Card style={{ padding: '1% 2%' }}>
        <InternalProjectFilterBar />
        <InternalProjectsList />
      </Card>
    </Grid>
  );
};

export default InternalProjectsListContainer;
