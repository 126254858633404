import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import axios from '../../utils/axios.utils';
import { Interview, InterviewFormValues } from '../model';

interface SelectedDatesFilterInterface {
  start: Date | '';
  end: Date | '';
}

interface InterviewsContextInterface {
  fetchInterviews?: (params?: string) => Promise<void>;
  interviews?: Interview[];
  fetchInterview?: (interview_id: string) => Promise<void>;
  interview?: Interview;
  interviewLoading?: boolean;
  interviewsDownload?: Record<string, any>[];

  selectedDatesFilter?: SelectedDatesFilterInterface;
  setSelectedDatesFilter?: React.Dispatch<React.SetStateAction<SelectedDatesFilterInterface>>;

  filterValues?: Record<string, string>;
  setFilterValues?: React.Dispatch<React.SetStateAction<Record<string, string>>>;

  selectedDates?: any;
  setSelectedDates?: React.Dispatch<React.SetStateAction<any>>;

  statusFilters?: string[];
  setStatusFilters?: React.Dispatch<React.SetStateAction<string[]>>;

  totalRecords?: number;
  upcomingPayments?: number;

  fetchFormOptions?: () => Promise<void>;
  formOptions?: Record<string, any[]>;
  updateInterview?: (interview_id: string, formValues: InterviewFormValues, onSuccess?: () => void) => Promise<void>;
  deleteInterview?: (interview_id: string) => Promise<void>;
  completeInterview?: (interview_id: string, formValues: any) => Promise<void>;
  createInterview?: (formValues: InterviewFormValues, onSuccess?: () => void) => Promise<void>;
  formError?: string;
  interviewSubmitting?: boolean;
}

const InterviewsContext = React.createContext<InterviewsContextInterface>({});

const InterviewsContextConsumer = InterviewsContext.Consumer;
const InterviewsContextProvider: React.FC = ({ children }) => {
  const history = useHistory();

  const [interviews, setInterviews] = useState<Interview[]>([]);
  const [interviewLoading, setInterviewLoading] = useState<boolean>(false);
  const [statusFilters, setStatusFilters] = useState<string[]>(['upcoming']);
  const [selectedDatesFilter, setSelectedDatesFilter] = useState<SelectedDatesFilterInterface>({
    start: '',
    end: '',
  });
  const [formOptions, setFormOptions] = useState<Record<string, any[]>>({});
  const [interviewSubmitting, setInterviewSubmitting] = useState<boolean>(false);
  const [interview, setInterview] = useState<Interview>({});
  const [totalRecords, setTotalRecords] = useState<number>();
  const [upcomingPayments, setUpcomingPayments] = useState<number>();
  const [formError, setFormError] = useState<string>(undefined);
  const [interviewsDownload, setInterviewsDownload] = React.useState<Record<string, any>[]>();
  const toDate = (dateStr) => {
    const [year, month, day] = dateStr.split('-');
    return new Date(year, month - 1, day);
  };

  const fetchInterviews = async (params = '') => {
    setInterviewLoading(true);
    const response = await axios.get<string, any>(`interviews.json?${params}`);

    setInterviews(response?.data?.result);
    setTotalRecords(response?.data?.total_records);
    setUpcomingPayments(response?.data?.upcoming_payments);
    setInterviewsDownload(response?.data?.interview_csv_data);

    response?.data?.applied_filters?.forEach((filter) => {
      switch (filter.label) {
        case 'status':
          setStatusFilters(filter.value);
          break;
        case 'scheduled_time':
          setSelectedDatesFilter({ start: toDate(filter.start), end: toDate(filter.end) });
          break;
        default:
          break;
      }
    });

    setInterviewLoading(false);
  };

  const fetchInterview = async (interview_id: string) => {
    setInterviewLoading(true);
    const response = await axios.get<string, any>(`interviews/${interview_id}.json`);

    setInterviewLoading(false);
    setInterview(response?.data?.result);
  };

  const fetchFormOptions = async () => {
    const response = await axios.get<string, any>(`interviews/new.json`);

    setFormOptions(response?.data);
  };

  const updateInterview = async (interview_id: string, formValues: InterviewFormValues, onSuccess?: () => void) => {
    try {
      setInterviewSubmitting(true);
      await axios
        .put<string, any>(`interviews/${interview_id}.json`, {
          interview: { ...formValues },
        })
        .then(() => {
          setInterviewSubmitting(false);

          if (onSuccess) {
            onSuccess();
          } else {
            history.push(`/admin/interviews`);
          }
        })
        .catch((error) => {
          setInterviewSubmitting(false);
          const errorMessage = error.response.data.error || 'An unknown error occurred';
          setFormError(errorMessage);
        });
    } catch (error: any) {
      setInterviewSubmitting(false);
      setFormError('Something went wrong, please reload the page and try again.');
    }
  };

  const deleteInterview = async (interview_id: string) => {
    setInterviewSubmitting(true);

    await axios.delete<string, any>(`interviews/${interview_id}`);

    setInterviewSubmitting(false);
    history.push(`/admin/interviews`);
  };

  const completeInterview = async (interview_id: string, formValues: any) => {
    try {
      setInterviewSubmitting(true);
      await axios
        .put<string, any>(`interviews/${interview_id}/complete`, {
          interview: { ...formValues },
        })
        .then(() => {
          setInterviewSubmitting(false);
          history.push(`/admin/interviews`);
        })
        .catch((error) => {
          setInterviewSubmitting(false);
          setFormError(error.response.data.message.map((message: string) => `${message}\n`));
        });
    } catch {
      setInterviewSubmitting(false);
      setFormError('Something went wrong, please reload the page and try again.');
    }
  };

  const createInterview = async (formValues: InterviewFormValues) => {
    try {
      setInterviewSubmitting(true);
      axios
        .post<string, any>(`interviews.json`, {
          interview: { ...formValues },
        })
        .then(() => {
          setInterviewSubmitting(false);
          history.push(`/admin/interviews`);
        })
        .catch((error) => {
          setInterviewSubmitting(false);
          setFormError(error.response.data.error);
        });
    } catch {
      setInterviewSubmitting(false);
      setFormError('Something went wrong, please reload the page and try again.');
    }
  };

  const [filterValues, setFilterValues] = useState<Record<string, string>>({ query: '', sort: '' });

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const previousDays = queryParams.get('previous_days');

  let initialDates = {
    start: new Date(1900, 0, 1),
    end: new Date(2100, 0, 1),
  };

  if (previousDays) {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);

    initialDates = {
      start: new Date(2023, 0, 1),
      end: yesterday,
    };
  }
  const [selectedDates, setSelectedDates] = useState<Record<string, any>>(initialDates);

  return (
    <InterviewsContext.Provider
      value={{
        fetchInterviews,
        interviews,
        fetchInterview,
        interview,
        interviewLoading,
        interviewsDownload,

        fetchFormOptions,
        formOptions,

        selectedDatesFilter,
        setSelectedDatesFilter,

        filterValues,
        setFilterValues,

        selectedDates,
        setSelectedDates,

        statusFilters,
        setStatusFilters,

        totalRecords,
        upcomingPayments,

        updateInterview,
        deleteInterview,
        completeInterview,
        createInterview,
        formError,
        interviewSubmitting,
      }}
    >
      {children}
    </InterviewsContext.Provider>
  );
};

export { InterviewsContextProvider, InterviewsContextConsumer, InterviewsContext };
