import React from 'react';
import { useParams } from 'react-router-dom';
import { Badge, Card, Loading, Layout } from '@shopify/polaris';
import Page from '../../shared/components/Page';
import ProjectPhaseForm from '../components/ProjectPhaseForm';
import { ProjectPhasesContext } from '../contexts/ProjectPhasesContext';
import FormSkeleton from '../../loading/components/FormSkeleton';
import BackButton from '../../navigation/components/BackButton';

const DuplicatePhase: React.FunctionComponent = () => {
  const { phaseId } = useParams<Record<string, string>>();
  const [phaseFetched, setPhaseFetched] = React.useState<boolean>(false);
  const { phase, phaseAddress, fetchPhase, phaseLoading, fetchFormOptions } = React.useContext(ProjectPhasesContext);

  React.useEffect(() => {
    if (!phaseFetched) {
      fetchFormOptions();
      fetchPhase(phaseId);
      setPhaseFetched(true);
    }
  }, [phaseFetched, fetchPhase, phaseId, fetchFormOptions]);

  return phaseLoading ? (
    <>
      <Loading />
      <FormSkeleton />
    </>
  ) : (
    <>
      <BackButton pageAction={false} />
      <Page
        fullWidth
        title={phase?.name}
        titleMetadata={
          phase?.status === 'active' ? (
            <Badge status="success">Active</Badge>
          ) : (
            <Badge status="critical">Inactive</Badge>
          )
        }
        additionalMetadata={`Created on ${phase?.created_at}`}
      >
        <Layout>
          <Layout.Section>
            <Card sectioned>
              <ProjectPhaseForm phase={{ ...phase, id: undefined }} phaseAddress={phaseAddress} />
            </Card>
          </Layout.Section>
        </Layout>
      </Page>
    </>
  );
};

export default DuplicatePhase;
