import React from 'react';
import { useHistory } from 'react-router-dom';
import axios from '../../utils/axios.utils';
import { Ethnicity } from '../model';
import { Insighter } from '../../insighters/model';

interface EthnicitiesContextInterface {
  fetchEthnicities?: (params?: string) => void;
  fetchEthnicity?: (ethnicity_id: string) => void;
  ethnicitiesLoading?: boolean;
  ethnicity?: Ethnicity;
  ethnicities?: Ethnicity[];
  totalRecords?: number;

  fetchParams?: string;
  setFetchParams?: React.Dispatch<React.SetStateAction<string>>;

  sortParams?: string;
  setSortParams?: React.Dispatch<React.SetStateAction<string>>;

  updateEthnicity?: (ethnicity_id: string, alternate_labels: string[]) => void;
  ethnicitySubmitting?: boolean;
  formError?: string;
}

const EthnicitiesContext = React.createContext<EthnicitiesContextInterface>({});

const EthnicitiesContextConsumer = EthnicitiesContext.Consumer;
const EthnicitiesContextProvider: React.FC = ({ children }) => {
  const history = useHistory();

  const [ethnicities, setEthnicities] = React.useState<Ethnicity[]>([]);
  const [ethnicity, setEthnicity] = React.useState<Ethnicity>({});
  const [ethnicitiesLoading, setEthnicitiesLoading] = React.useState<boolean>(false);
  const [ethnicitySubmitting, setEthnicitySubmitting] = React.useState(false);
  const [fetchParams, setFetchParams] = React.useState<string>('ransack[query]=');
  const [sortParams, setSortParams] = React.useState<string>('sort=name asc');

  const fetchEthnicities = async (params = '') => {
    setEthnicitiesLoading(true);

    const response = await axios.get<string, any>(`ethnicities?${params}`);

    setEthnicities(response?.data?.result);
    setEthnicitiesLoading(false);
  };

  const fetchEthnicity = async (ethnicity_id: string) => {
    setEthnicitiesLoading(true);

    const response = await axios.get<string, any>(`ethnicities/${ethnicity_id}`, {
      params: { columns_to_use: ['Name', 'User ID', 'User Type', 'State', 'City'] },
    });

    setEthnicitiesLoading(false);
    setEthnicity(response?.data?.result);
  };

  const updateEthnicity = async (ethnicity_id: string, alternateLabels: string[]) => {
    setEthnicitySubmitting(true);

    await axios.put<string, any>(`ethnicities/${ethnicity_id}`, {
      ethnicity: { alternateLabels },
    });

    setEthnicitySubmitting(false);
    history.push('/admin/ethnicities');
  };

  return (
    <EthnicitiesContext.Provider
      value={{
        fetchEthnicities,
        fetchEthnicity,
        ethnicitiesLoading,
        ethnicity,
        ethnicities,

        fetchParams,
        setFetchParams,

        sortParams,
        setSortParams,

        updateEthnicity,
        ethnicitySubmitting,
      }}
    >
      {children}
    </EthnicitiesContext.Provider>
  );
};

export { EthnicitiesContextProvider, EthnicitiesContextConsumer, EthnicitiesContext };
