import React from 'react';
import { Bar } from 'react-chartjs-2';

const options = {
  plugins: {
    title: {
      display: false,
    },
  },
  responsive: true,
};

interface DemographicsBarChartProps {
  data?: Record<string, any>;
}

const DemographicsBarChart: React.FC<DemographicsBarChartProps> = (props) => {
  const { data } = props;

  return (
    <>
      <Bar data={data} options={options} />
    </>
  );
};

export default DemographicsBarChart;
