import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Layout, Loading, Page, Toast } from '@shopify/polaris';
import { Grid, Divider, Chip } from '@mui/material';
import { Accordion, AccordionSummary, AccordionDetails, makeStyles, createStyles } from '@material-ui/core';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DataTableSkeleton from '../../loading/components/DataTableSkeleton';
import DashboardNavigation from '../components/DashboardNavigation';
import DataTable from '../../dataTable/DataTable/DataTable';
import completedInvitationsColumns from '../constants/completedInvitationsColumns';
import SocialShareCard from '../components/SocialShareCard';
import InactiveShareCard from '../components/InactiveShareCard';
import { InvitationsContext } from '../contexts/InvitationsContext';
import { InsightersContext } from '../../insighters/contexts/InsightersContext';

const useStyles = makeStyles((theme) =>
  createStyles({
    sections: {
      marginBottom: 20,
    },

    accordionHeader: {
      fontSize: '16px',
      fontWeight: 'bold',
    },

    bulletedList: {
      paddingLeft: '16px',
      paddingRight: '16px',
      margin: 0,
    },

    container: {
      marginBottom: '20px',
    },

    gridItem: {
      padding: '0 7.5px 30px 7.5px',
    },

    paragraph: {
      fontSize: '16px',
      lineHeight: '24px',
      marginBottom: '16px',
    },

    title: {
      fontSize: '40px',
      lineHeight: '40px',
      fontFamily: 'Cooper',
      margin: '30px 0',
      textAlign: 'center',

      [theme.breakpoints.up(500)]: {
        fontSize: '54px',
        lineHeight: '54px',
        margin: '40px 0',
      },
    },

    seperator: {
      borderTopWidth: '1px',
      borderTopStyle: 'solid',
      borderTopColor: '#3b4255',
      maxWidth: '60px',
      margin: '40px auto',
    },

    subheader: {
      fontFamily: 'Cooper',
      fontSize: '20px',
      fontWeight: 'bold',
      lineHeight: '24px',
      marginBottom: '20px',
      textAlign: 'left',

      [theme.breakpoints.up(500)]: {
        fontSize: '32px',
        lineHeight: '32px',
      },
    },
  }),
);

const InvitationsDashboard: React.FunctionComponent = () => {
  const classes = useStyles();
  const history = useHistory();
  const { impersonatedUserId } = useParams<Record<string, string>>();
  const { insighter, fetchInsighter } = React.useContext(InsightersContext);
  const { fetchInvitations, invitationsLoading, invitationOpportunities } = React.useContext(InvitationsContext);
  const [insighterFetched, setInsighterFetched] = React.useState(false);
  const [invitationsFetched, setInivtationsFetched] = React.useState(false);

  React.useEffect(() => {
    if (!invitationsFetched) {
      fetchInvitations(impersonatedUserId);
      setInivtationsFetched(true);
    }
  }, [invitationsFetched, fetchInvitations, impersonatedUserId]);

  React.useEffect(() => {
    if (impersonatedUserId) {
      if (!insighterFetched) {
        fetchInsighter(impersonatedUserId);
        setInsighterFetched(true);
      }
    }
  }, [insighterFetched, fetchInsighter, impersonatedUserId]);

  const loadingMarkup = invitationsLoading ? <Loading /> : null;

  const actualPageMarkup = (
    <Page fullWidth>
      <DashboardNavigation currentTab={0} />
      <Layout>
        <Layout.Section>
          <br />
          {/* Note: These 'Inviting Others' questions are also included on main FAQ page.
            If these are updated here, they should also be updated in the other page. */}
          <Grid item md={12} xs={12} className={classes.gridItem}>
            <div className={classes.container}>
              <h2 className="Polaris-Heading" style={{ margin: '0 0 25px', fontSize: 28 }}>
                Inviting Others FAQ
              </h2>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                  <h3 className={classes.accordionHeader}>
                    I sent out some personalized invitations. What happens next?
                  </h3>
                </AccordionSummary>
                <AccordionDetails>
                  <ol className={classes.bulletedList}>
                    <li>
                      <p className={classes.paragraph}>
                        Your invitees will be invited to book an interview if the research is a good fit.
                      </p>
                    </li>
                    <li>
                      <p className={classes.paragraph}>
                        Their application status will change from PENDING to BOOKED if they schedule an interview.
                      </p>
                    </li>
                    <li>
                      <p className={classes.paragraph}>
                        Their application status will change to COMPLETE if they finish an interview. You will receive
                        an email that a payment is on its way to you.
                      </p>
                    </li>
                    <li>
                      <p className={classes.paragraph}>
                        Their application status will change to INVITATION EXPIRED if they do not book & research has
                        wrapped up.
                      </p>
                    </li>
                  </ol>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                  <h3 className={classes.accordionHeader}>
                    I have invitees that did not complete an interview. What happened?
                  </h3>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container>
                    <Grid xs={12}>
                      <p className={classes.paragraph}>
                        Here are some common reasons why your invitee might not complete an interview.
                      </p>
                    </Grid>

                    <Grid xs={12}>
                      <ul className={classes.bulletedList}>
                        <li>
                          <p className={classes.paragraph}>The opportunity was not quite the right fit.</p>
                        </li>
                        <li>
                          <p className={classes.paragraph}>Availability during research changed.</p>
                        </li>
                        <li>
                          <p className={classes.paragraph}>We were unable to get in touch with them.</p>
                        </li>
                      </ul>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </div>
          </Grid>
          <Grid container justifyContent="center">
            <Grid item xs={12} style={{ marginLeft: 20, marginRight: 20 }}>
              <div className={classes.sections}>
                <h2 className="Polaris-Heading" style={{ margin: '0 0 25px', fontSize: 28 }}>
                  Your Active Invitations:
                </h2>
                {invitationOpportunities.filter((opp) => opp.status === 'active').length === 0 ? (
                  <h4 style={{ margin: '25px 0 25px', fontSize: 20 }}>
                    You have no active invitations available at this time.
                  </h4>
                ) : (
                  <>
                    <Grid container justifyContent="center">
                      <Grid item xs={12} style={{ textAlign: 'center' }}>
                        <div className={classes.sections}>
                          <h4 style={{ margin: '0px', fontSize: 20, lineHeight: '28px' }}>
                            Do you know someone insightful (just like yourself) who might want to join an opportunity?
                          </h4>
                          <h4 style={{ margin: '0px', fontSize: 20, lineHeight: '28px' }}>
                            Invite them to join The Insighters® by sharing your custom links below & get paid!
                          </h4>
                        </div>
                      </Grid>
                    </Grid>

                    {invitationOpportunities
                      .filter((invitationOpportunity) => invitationOpportunity.status === 'active')
                      .map((invitationOpportunity) => (
                        <>
                          <SocialShareCard
                            invitationOpportunity={invitationOpportunity}
                            key={invitationOpportunity.id}
                          />
                          <Accordion>
                            <AccordionSummary>
                              <p>
                                {invitationOpportunity?.invitations?.length}{' '}
                                {invitationOpportunity?.invitations?.length === 1 ? 'Invitation' : 'Invitations'}
                              </p>
                            </AccordionSummary>
                            <AccordionDetails>
                              <DataTable
                                onRowClicked={() => null}
                                columns={completedInvitationsColumns}
                                data={invitationOpportunity.invitations}
                                pagination
                                striped
                                highlightOnHover
                                pointerOnHover
                                noHeader
                              />
                            </AccordionDetails>
                          </Accordion>
                        </>
                      ))}
                  </>
                )}
              </div>
              <hr />
              {invitationOpportunities.filter((invitationOpportunity) => invitationOpportunity.status === 'inactive')
                .length > 0 && (
                <div className={classes.sections}>
                  <h2 className="Polaris-Heading" style={{ margin: '25px 0 25px', fontSize: 28 }}>
                    Your Past Invitations:
                  </h2>
                  <h4 style={{ margin: '25px 0 25px', fontSize: 20 }}>
                    Click on a past invitation to see the list of people you invited.
                  </h4>
                  {invitationOpportunities
                    .filter((invitationOpportunity) => invitationOpportunity.status === 'inactive')
                    .map((invitationOpportunity) => (
                      <>
                        <Accordion>
                          <AccordionSummary>
                            <Grid container>
                              <Grid item xs={12}>
                                <InactiveShareCard
                                  invitationOpportunity={invitationOpportunity}
                                  key={invitationOpportunity.id}
                                />
                              </Grid>
                              <br />
                              <Grid item xs={12} style={{ marginTop: 15 }}>
                                <Divider>
                                  <div style={{ fontSize: '16px' }}>
                                    View Invitations{' '}
                                    <ExpandMoreIcon style={{ fontSize: '18px', position: 'relative', top: 3 }} />
                                  </div>
                                </Divider>
                              </Grid>
                            </Grid>
                          </AccordionSummary>
                          <AccordionDetails>
                            <DataTable
                              onRowClicked={() => null}
                              columns={completedInvitationsColumns}
                              data={invitationOpportunity.invitations}
                              pagination
                              striped
                              highlightOnHover
                              pointerOnHover
                              noHeader
                            />
                          </AccordionDetails>
                        </Accordion>
                        <br />
                      </>
                    ))}
                </div>
              )}
            </Grid>
          </Grid>
        </Layout.Section>
      </Layout>
    </Page>
  );

  // ---- Loading ----
  const loadingPageMarkup = <DataTableSkeleton />;

  const pageMarkup = invitationsLoading ? loadingPageMarkup : actualPageMarkup;

  return (
    <>
      {loadingMarkup}
      {pageMarkup}
      {impersonatedUserId && (
        <Toast
          onDismiss={() => null}
          content={`Viewing page as ${insighter?.name}`}
          action={{
            content: 'Back',
            onAction: () => history.push(`/admin/insighters/${impersonatedUserId}/view`),
          }}
        />
      )}
    </>
  );
};

export default InvitationsDashboard;
