import React from 'react';
import { useHistory } from 'react-router-dom';
import { Card } from '@shopify/polaris';
import DataTable from '../../dataTable/DataTable/DataTable';
import { DashboardsContext } from '../contexts/DashboardsContext';
import { Project } from '../../projects/model';
import { Phase } from '../../projectPhases/model';
import { Opportunity } from '../../opportunities/model';
import { InvitationOpportunity } from '../../invitationOpportunities/model';
import DashboardNavigation from '../components/DashboardNavigation';
import Page from '../../shared/components/Page';
import {
  projectColumns,
  phaseColumns,
  opportunityColumns,
  invitationOpportunityColumns,
} from '../constants/upcomingChangesColumns';

const UpcomingChangesDashboard: React.FunctionComponent = () => {
  const history = useHistory();
  const [dashboardFetched, setDashboardFetched] = React.useState(false);

  const { projects, phases, opportunities, invitationOpportunities, fetchUpcomingChangesDashboard, dashboardLoading } =
    React.useContext(DashboardsContext);

  React.useEffect(() => {
    if (!dashboardFetched) {
      setDashboardFetched(true);
      fetchUpcomingChangesDashboard();
    }
  }, [dashboardFetched, setDashboardFetched, fetchUpcomingChangesDashboard]);

  return (
    <Page fullWidth>
      <DashboardNavigation currentTab={0} />
      <Card>
        <h2 className="Polaris-Header-Title" style={{ margin: 15, paddingTop: 15 }}>
          Projects
        </h2>
        <DataTable
          onRowClicked={(row: Project) => history.push(`/admin/projects/${row.id}`)}
          columns={projectColumns}
          data={projects}
          progressPending={dashboardLoading}
          striped
          highlightOnHover
          pointerOnHover
          noHeader
        />
      </Card>
      <br />
      <Card>
        <h2 className="Polaris-Header-Title" style={{ margin: 15, paddingTop: 15 }}>
          Phases
        </h2>
        <DataTable
          onRowClicked={(row: Phase) => history.push(`/admin/phases/${row.id}`)}
          columns={phaseColumns}
          data={phases}
          progressPending={dashboardLoading}
          striped
          highlightOnHover
          pointerOnHover
          noHeader
        />
      </Card>
      <br />
      <Card>
        <h2 className="Polaris-Header-Title" style={{ margin: 15, paddingTop: 15 }}>
          Opportunities
        </h2>
        <DataTable
          onRowClicked={(row: Opportunity) => history.push(`/admin/opportunities/${row.id}`)}
          columns={opportunityColumns}
          data={opportunities}
          progressPending={dashboardLoading}
          striped
          highlightOnHover
          pointerOnHover
          noHeader
        />
      </Card>
      <Card>
        <h2 className="Polaris-Header-Title" style={{ margin: 15, paddingTop: 15 }}>
          Invitation Opportunities
        </h2>
        <DataTable
          onRowClicked={(row: InvitationOpportunity) => history.push(`/admin/invitation-opportunities/${row.id}`)}
          columns={invitationOpportunityColumns}
          data={invitationOpportunities}
          progressPending={dashboardLoading}
          striped
          highlightOnHover
          pointerOnHover
          noHeader
        />
      </Card>
    </Page>
  );
};

export default UpcomingChangesDashboard;
