import React from 'react';
import { useParams } from 'react-router-dom';
import { Card, Layout, Loading } from '@shopify/polaris';
import ConditionForm from '../components/ConditionForm';
import { ConditionsContext } from '../contexts/ConditionsContext';
import FormSkeleton from '../../loading/components/FormSkeleton';
import BackButton from '../../navigation/components/BackButton';
import Page from '../../shared/components/Page';

const EditCondition: React.FunctionComponent = () => {
  const { conditionId } = useParams<Record<string, string>>();
  const [conditionFetched, setConditionFetched] = React.useState<boolean>(false);
  const { condition, fetchCondition, fetchFormOptions, conditionsLoading, formOptionsLoading } =
    React.useContext(ConditionsContext);

  React.useEffect(() => {
    if (!conditionFetched) {
      fetchFormOptions();
      fetchCondition(conditionId);
      setConditionFetched(true);
    }
  }, [conditionFetched, fetchCondition, conditionId, fetchFormOptions]);

  const loadingMarkup = conditionsLoading ? <Loading /> : null;

  // ---- Page markup ----
  const actualPageMarkup = (
    <>
      <BackButton pageAction={false} />
      <Page fullWidth>
        <Layout>
          <Layout.Section>
            <Card sectioned>
              <ConditionForm condition={condition} />
            </Card>
          </Layout.Section>
          <Layout.Section secondary />
        </Layout>
      </Page>
    </>
  );

  // ---- Loading ----
  const loadingPageMarkup = <FormSkeleton />;

  const pageMarkup = conditionsLoading || formOptionsLoading ? loadingPageMarkup : actualPageMarkup;

  return (
    <>
      {loadingMarkup}
      {pageMarkup}
    </>
  );
};

export default EditCondition;
