import React from 'react';
import { Card, Typography, Grid } from '@material-ui/core';
import { PaymentConfirmation } from '../model';

type PaymentConfirmationCardProps = {
  confirmation: PaymentConfirmation;
};

function titleizeString(snakeCaseString) {
  const words = snakeCaseString.split('_');

  // Capitalize the first letter of each word and remove "response" if present
  const titleizedWords = words.map((word) => {
    if (word.toLowerCase() === 'response') {
      return '';
    }
    return word.charAt(0).toUpperCase() + word.slice(1);
  });

  const titleizedString = titleizedWords.join(' ').trim();

  return titleizedString;
}

const PaymentConfirmationCard: React.FC<PaymentConfirmationCardProps> = (props: PaymentConfirmationCardProps) => {
  const { confirmation } = props;

  return (
    <Card key={confirmation.id} style={{ padding: 20, margin: 10 }}>
      <Typography variant="h4">{confirmation.created_at}</Typography>
      <br />
      <Grid container>
        {Object.keys(confirmation).map(
          (key) =>
            // Skip 'created_at' because it's already used as the title of the card
            key.toLowerCase() !== 'created_at' && (
              <Grid key={key} item xs={6}>
                <strong>{titleizeString(key)}</strong>: {confirmation[key]}
              </Grid>
            ),
        )}
      </Grid>
    </Card>
  );
};

export default PaymentConfirmationCard;
