import React, { useState, useEffect } from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import MediumOrnament from '../../common/MediumOrnament';
import redesignStyles from '../../constants/redesignStyles';
import EventViewerRow from '../../../eventResources/components/EventViewerRow';
import axios from '../../../utils/axios.utils';

const useCustomStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
  title: {
    fontWeight: 600,
    [theme.breakpoints.down(960)]: {
      letterSpacing: '-1.25px',
    },
  },
  subtitle: {
    marginBottom: 15,
    textAlign: 'center',
  },
}));

const EventsSection: React.FC = () => {
  const classes = redesignStyles();
  const customClasses = useCustomStyles();

  const [eventResources, setEventResources] = useState<any>([]);
  const [isCentered, setIsCentered] = React.useState<boolean>(false);

  const fetchEventsForHomepage = async () => {
    let response: any = await axios.get('event_resources');
    setEventResources(response?.data?.events_for_homepage);
  };

  useEffect(() => {
    fetchEventsForHomepage();
  }, []);

  React.useEffect(() => {
    const handleResize = () => {
      setIsCentered(window.innerWidth < 960); // Set to true for screens smaller than 960px
    };

    window.addEventListener('resize', handleResize);

    // Call the handler on mount to set the initial value
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Grid container spacing={2} style={{ display: 'flex', maxWidth: 1400, margin: 'auto' }}>
      <Grid item xs={12} md={12}>
        <MediumOrnament text="Insightful Conversations" width="500px" centerText centerComponent />
      </Grid>
      <Grid item xs={12} className={customClasses.container}>
        <Typography className={`${classes.h1} ${customClasses.title}`}>
          Explore Our <span style={{ color: '#488C7A' }}>Events</span>
        </Typography>
      </Grid>
      <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', marginBottom: 15 }}>
        <Typography className={`${classes.h3} ${customClasses.subtitle}`}>
          Join us and be a catalyst for change in the healthcare industry
        </Typography>
      </Grid>
      <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', marginBottom: 15 }}>
        <EventViewerRow eventResources={eventResources} />
      </Grid>
    </Grid>
  );
};

export default EventsSection;
