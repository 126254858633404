import React from 'react';
import { IDataTableColumn } from '../../dataTable/DataTable/model';
import { Project } from '../../projects/model';

const projectSignupColumns = (): IDataTableColumn[] => [
  {
    name: 'Project Number',
    selector: 'project_number',
    sortable: true,
  },
  {
    name: 'Project Name',
    selector: 'name',
    sortable: true,
  },
  {
    name: 'Client Name',
    selector: 'client_name',
    sortable: true,
  },
  {
    name: 'Start Date',
    selector: 'start_date',
    sortable: true,
    sortFunction: (rowA: Project, rowB: Project) =>
      new Date(rowA.start_date).getTime() - new Date(rowB.start_date).getTime(),
  },
  {
    name: 'End Date',
    selector: 'end_date',
    sortable: true,
    sortFunction: (rowA: Project, rowB: Project) =>
      new Date(rowA.end_date).getTime() - new Date(rowB.end_date).getTime(),
  },
  {
    name: 'Signups',
    selector: 'total_signups',
    sortable: true,
  },
  {
    name: 'Status',
    selector: 'status',
    sortable: true,
    cell: ({ status }): React.ReactNode => (
      <div
        style={{
          fontWeight: 700,
          textTransform: 'uppercase',
          fontSize: 14,
          color: status === 'active' ? 'green' : 'red',
        }}
      >
        {status}
      </div>
    ),
  },
];

export default projectSignupColumns;
