import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Card, Layout, Page, Button } from '@shopify/polaris';
import { InsightersContext } from '../contexts/InsightersContext';
import { InsighterFormValues } from '../model';
import InsighterForm from '../components/InsighterForm';
import axios from '../../utils/axios.utils';

const NewInsighterPage: React.FunctionComponent = () => {
  const history = useHistory();

  const { fetchFormOptions } = useContext(InsightersContext);

  const [insighterFetched, setInsighterFetched] = useState<boolean>(false);
  const [formValues, setFormValues] = useState<InsighterFormValues>({});
  const [insighterSubmitting, setInsighterSubmitting] = useState<boolean>(false);
  const [formError, setFormError] = useState<string>('');

  useEffect(() => {
    if (!insighterFetched) {
      fetchFormOptions();
      setInsighterFetched(true);
    }
  }, [insighterFetched, fetchFormOptions]);

  const createInsighter = async () => {
    try {
      setInsighterSubmitting(true);
      axios
        .post<string, any>(`insighters.json`, {
          insighter: { ...formValues },
        })
        .then((response) => {
          setInsighterSubmitting(false);

          history.push(`/admin/insighters/${response.data?.result?.id}`);
        })
        .catch((error) => {
          setInsighterSubmitting(false);
          setFormError(error.response.data.error);
        });
    } catch {
      setInsighterSubmitting(false);
      setFormError('Something went wrong, please reload the page and try again.');
    }
  };

  return (
    <Page fullWidth title="Create Insighter">
      <Layout>
        <Layout.Section>
          <Card sectioned>
            <InsighterForm
              formValues={formValues}
              setFormValues={setFormValues}
              insighterSubmitting={insighterSubmitting}
            />
          </Card>
        </Layout.Section>
        <Layout.Section>
          {formError && <p style={{ marginBottom: '8px', color: 'red' }}>{formError}</p>}
          <Button primary onClick={() => createInsighter()} loading={insighterSubmitting}>
            Create Insighter
          </Button>
        </Layout.Section>
      </Layout>
    </Page>
  );
};

export default NewInsighterPage;
