import React, { useCallback, useState } from 'react';
import { Loading, Toast } from '@shopify/polaris';
import { useHistory } from 'react-router-dom';
import DataTable from '../../dataTable/DataTable/DataTable';
import { DashboardsContext } from '../contexts/DashboardsContext';
import projectSignupsColumns from '../constants/projectSignupsColumns';

const ClientProjectsList: React.FunctionComponent = () => {
  const [projectsFetched, setProjectsFetched] = React.useState(false);

  const {
    projectSignups,
    fetchProjectSignups,
    projectSignupsLoading,
    totalRecords,
    sortParams,
    setSortParams,
    filterParams,
  } = React.useContext(DashboardsContext);

  React.useEffect(() => {
    const projectSignupFilters = sessionStorage.getItem('projectSignupFilters');

    if (!projectsFetched) {
      if (projectSignupFilters) {
        fetchProjectSignups(projectSignupFilters);
      } else {
        fetchProjectSignups(`${filterParams}&${sortParams}`);
      }
      setProjectsFetched(true);
    }
  }, [fetchProjectSignups, filterParams, projectsFetched, sortParams]);

  const history = useHistory();
  const [toastActive, setToastActive] = useState(false);
  const loadingMarkup = projectSignupsLoading ? <Loading /> : null;

  const toggleToastActive = useCallback(() => setToastActive((toastActive) => !toastActive), []);
  const toastMarkup = toastActive ? <Toast onDismiss={toggleToastActive} content="Changes saved" /> : null;

  const handleColumnSort = async (column: Record<string, string>, sortDirection: string) => {
    const columnNamesToRansack = {
      project_number: 'project_number',
      name: 'name',
      client_name: 'client_name',
      start_date: 'project_timeline_overall_start_date',
      end_date: 'project_timeline_overall_end_date',
    };

    const sort = `sort=${columnNamesToRansack[column.selector]} ${sortDirection}`;

    setSortParams(sort);
    fetchProjectSignups(`${filterParams}&${sort}`);
  };

  const handlePageChange = (page: number) => {
    fetchProjectSignups(`page=${page}&${filterParams}&${sortParams}`);
  };

  const pageMarkup = (
    <DataTable
      onRowClicked={(row: Record<string, string>) => history.push(`/admin/projects/${row.id}`)}
      columns={projectSignupsColumns()}
      data={projectSignups}
      progressPending={projectSignupsLoading}
      striped
      highlightOnHover
      pointerOnHover
      noHeader
      pagination
      paginationPerPage={25}
      paginationRowsPerPageOptions={[25]}
      paginationTotalRows={totalRecords}
      onChangePage={handlePageChange}
    />
  );

  return (
    <>
      {loadingMarkup}
      {pageMarkup}
      {toastMarkup}
    </>
  );
};

export default ClientProjectsList;
