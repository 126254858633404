import React from 'react';
import { Grid, Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Page from '../../shared/components/Page';

const ForgotPasswordConfirmation: React.FC = () => {
  const history = useHistory();

  return (
    <Page fullWidth title="">
      <div style={{ marginTop: 80, marginLeft: 10, marginRight: 10 }}>
        <Grid container justifyContent="center">
          <Grid item xs={12} sm={10} md={6} lg={4}>
            <h1
              className="Polaris-Header-Title"
              style={{ fontFamily: 'Cooper', fontSize: '36px', marginBottom: 10, lineHeight: '40px' }}
            >
              Please Check Your Email
            </h1>
            <br />
            <p style={{ fontFamily: 'Cooper', fontSize: '20px', lineHeight: '28px' }}>
              If the address that you entered is associated with an Insighter account, then we have sent an email with
              instructions for resetting your password.
            </p>
            <br />
            <p style={{ fontFamily: 'Cooper', fontSize: '20px', lineHeight: '28px' }}>
              If you do not find the email in your inbox, please try these solutions
            </p>
            <ul>
              <li style={{ fontFamily: 'Cooper', fontSize: '20px', lineHeight: '28px' }}>Check your spam folder</li>
              <li style={{ fontFamily: 'Cooper', fontSize: '20px', lineHeight: '28px' }}>
                Make sure your email address is spelled correctly
              </li>
              <li style={{ fontFamily: 'Cooper', fontSize: '20px', lineHeight: '28px' }}>
                Try a different email address
              </li>
              <li style={{ fontFamily: 'Cooper', fontSize: '20px', lineHeight: '28px' }}>Register a new account</li>
            </ul>
          </Grid>
          <Grid xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
            <Grid item>
              <Button
                variant="contained"
                style={{
                  backgroundColor: '#E7AC34',
                  height: 40,
                  width: 'auto',
                  fontSize: '16px',
                  borderRadius: '4px',
                  marginTop: 8,
                  textTransform: 'capitalize',
                  fontFamily: 'Cooper',
                  minWidth: '120px',
                }}
                onClick={() => history.push(`/login`)}
              >
                Return to Login
              </Button>
              &emsp;
              <Button
                variant="contained"
                style={{
                  backgroundColor: '#ddd',
                  height: 40,
                  width: 'auto',
                  fontSize: '16px',
                  borderRadius: '4px',
                  marginTop: 8,
                  textTransform: 'capitalize',
                  fontFamily: 'Cooper',
                }}
                onClick={() => history.push('/password-reset')}
              >
                Return to &apos;Forgot Password&apos; screen
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Page>
  );
};

export default ForgotPasswordConfirmation;
