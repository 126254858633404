import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ListItemIcon, MenuItem, makeStyles } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import InvitationForm from './InvitationForm';
import ConfirmCancelModal from '../../modal/components/MaterialModal';
import CustomMaterialMenu from '../../dataTable/rowMenu/CustomMaterialMenu';
import { Invitation } from '../model';
import { InvitationsContext } from '../contexts/InvitationsContext';

const useStyles = makeStyles(() => ({
  listItemIcon: {
    minWidth: '24px',
  },
  modalText: {
    fontSize: '20px',
    marginLeft: '20%',
    marginBottom: '20px',
    width: '60%',
  },
}));

interface InvitationRowMenuProps {
  invitation: Invitation;
}

const InvitationRowMenu: React.FC<InvitationRowMenuProps> = (props) => {
  const { invitation } = props;
  const classes = useStyles();
  const history = useHistory();
  const { invitationOpportunityId } = useParams<Record<string, string>>();
  const [modalOpen, setModalOpen] = React.useState(false);
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const { deleteInvitation } = React.useContext(InvitationsContext);

  const handleDelete = (invitationId) => {
    deleteInvitation(invitationId);
    history.push(`/admin/invitation-opportunities/${invitationOpportunityId}`);
  };

  return (
    <>
      <ConfirmCancelModal isOpen={modalOpen} onCancel={() => setModalOpen(false)} title="Update Invitation">
        <div style={{ margin: '40px 20px' }}>
          <InvitationForm
            invitation={invitation}
            afterAction={() => history.push(`/admin/invitation-opportunities/${invitationOpportunityId}`)}
          />
        </div>
      </ConfirmCancelModal>
      <ConfirmCancelModal
        isOpen={confirmOpen}
        title="Delete Invitation"
        onCancel={() => setConfirmOpen(false)}
        onWarning={() => handleDelete(invitation.id)}
        warningLabel="Delete Invitation"
        cancelLabel="Cancel"
        hasWarningButton
        hasCancelButton
        hasFooterBar={false}
        height="250px"
      >
        <div style={{ marginTop: '20px', marginBottom: '20px' }}>
          <p>Are you sure you want to delete this information? This cannot be undone.</p>
        </div>
      </ConfirmCancelModal>
      <CustomMaterialMenu size="small">
        <MenuItem onClick={() => setModalOpen(!modalOpen)} style={{ fontSize: '1.4rem' }}>
          <ListItemIcon className={classes.listItemIcon}>
            <EditIcon fontSize="small" color="primary" />
          </ListItemIcon>
          Edit Invitation Details
        </MenuItem>
        <MenuItem
          onClick={() => setConfirmOpen(true)}
          style={confirmOpen ? { fontSize: '1.4rem', color: 'gray' } : { fontSize: '1.4rem', color: 'black' }}
        >
          <ListItemIcon className={classes.listItemIcon}>
            <DeleteIcon fontSize="small" color="primary" />
          </ListItemIcon>
          Delete Invitation
        </MenuItem>
      </CustomMaterialMenu>
    </>
  );
};

export default InvitationRowMenu;
