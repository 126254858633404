import React from 'react';
import { Card } from '@shopify/polaris';
import ProjectSignupsDashboardFilterBar from '../components/ProjectSignupsDashboardFilterBar';
import ProjectSignupsDashboardList from '../components/ProjectSignupsDashboardList';
import DashboardNavigation from '../components/DashboardNavigation';
import Page from '../../shared/components/Page';

const ProjectSignupsDashboardContainer: React.FunctionComponent = () => {
  return (
    <Page fullWidth>
      <DashboardNavigation currentTab={3} />
      <Card>
        <ProjectSignupsDashboardFilterBar />
        <ProjectSignupsDashboardList />
      </Card>
    </Page>
  );
};

export default ProjectSignupsDashboardContainer;
