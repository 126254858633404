import React from 'react';
import { Button, FormLayout } from '@shopify/polaris';
import { Grid } from '@material-ui/core';
import UngroupedAutocompleteInput from '../../form/components/UngroupedAutocompleteInput';
import FormInput from '../../form/components/FormInput';
import SelectInput from '../../form/components/SelectInput';
import { ConditionCategoriesContext } from '../contexts/ConditionCategoriesContext';
import { Condition, ConditionCategoryFormValues } from '../model';

interface ConditionFormProps {
  conditionCategory?: Condition;
}

const ConditionForm: React.FC<ConditionFormProps> = (props) => {
  const { conditionCategory } = props;
  const { formError, updateConditionCategory, createConditionCategory, conditionCategorySubmitting } =
    React.useContext(ConditionCategoriesContext);

  const [formValues, setFormValues] = React.useState<ConditionCategoryFormValues>({ name: '' });

  React.useEffect(() => {
    if (conditionCategory) {
      setFormValues({
        name: conditionCategory?.name,
      });
    }
  }, [conditionCategory]);

  const handleUpdate = () => {
    updateConditionCategory(conditionCategory.id, formValues);
  };

  const handleCreate = () => {
    createConditionCategory(formValues);
  };

  // const handleArchive = () => {
  //   deleteCondition(condition.id);
  // };

  return (
    <FormLayout>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <h2 className="Polaris-Heading">Condition Category Information</h2>
        </Grid>
        <Grid item xs={12}>
          <FormInput
            label="Name"
            type="text"
            value={formValues.name}
            onChange={(value) => setFormValues({ ...formValues, name: value })}
          />
        </Grid>
      </Grid>

      <>
        {conditionCategory?.id ? (
          <>
            <Button primary onClick={handleUpdate} loading={conditionCategorySubmitting}>
              Update Condition Category
            </Button>
            {/* Archiving a ConditionCategory doesn't work yet.  But Zee will try to add that in the near-ish future
            
            <span style={{ marginLeft: '8px' }}>
              <Button destructive onClick={handleDelete} loading={conditionCategorySubmitting}>
                Archive Condition Category
              </Button>
            </span> */}
          </>
        ) : (
          <Button primary onClick={handleCreate} loading={conditionCategorySubmitting}>
            Create Condition Category
          </Button>
        )}
        {formError && <p style={{ color: 'red', fontSize: '14px', marginTop: 20 }}>{formError}</p>}
      </>
    </FormLayout>
  );
};

export default ConditionForm;
