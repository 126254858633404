/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { TextField, FormControl } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import axios from '../../utils/axios.utils';

interface InsighterAutocompleteInputProps {
  label: string;
  onChange: (value: any) => void;
  value: string;
}

const InsighterAutocompleteInput: React.FC<InsighterAutocompleteInputProps> = (props) => {
  const { label, onChange, value } = props;
  const [inputValue, setInputValue] = React.useState(value);
  const [insighterOptions, setInsighterOptions] = React.useState([]);

  React.useEffect(() => {
    if (inputValue?.length >= 3) {
      axios.get<string, any>(`insighter_options?query=${inputValue}`).then((response) => {
        setInsighterOptions(response?.data?.insighter_options);
      });
    }
  }, [inputValue]);

  return (
    <div>
      <FormControl sx={{ width: '100%' }}>
        <div className="Polaris-Labelled__LabelWrapper">
          <div className="Polaris-Label">
            <label className="Polaris-Label__Text" htmlFor="formInput">
              {label}
            </label>
          </div>
        </div>
        <Autocomplete
          style={{ backgroundColor: '#e8e8e8' }}
          id="Insighter-Autocomplete-Input"
          options={insighterOptions || []}
          renderOption={(props, option) => (
            <li key={option.value} style={{ fontSize: '1.4rem' }} {...props}>
              {option.label}
            </li>
          )}
          value={value}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          onChange={(_event, newValue) => {
            onChange(newValue?.value);
          }}
          renderInput={(params) => <TextField {...params} />}
        />
      </FormControl>
    </div>
  );
};

export default InsighterAutocompleteInput;
