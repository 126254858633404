import React, { Dispatch, SetStateAction } from 'react';
import { Card, Layout, Button } from '@shopify/polaris';
import { Grid } from '@mui/material';
import ProjectChecklistCard from './ProjectCheckListCard';
import { ProjectChecklistFormValues } from '../model';

interface ProjectChecklistProps {
  checklistCards?: any[];
  submitChecklistCard?: Record<string, any>;
  // updateChecklist?: (key: string, value: string) => void;
  updateChecklist?: () => void;
  formValues?: ProjectChecklistFormValues;
  setFormValues?: Dispatch<SetStateAction<unknown>>;
}

const ProjectChecklist: React.FunctionComponent<ProjectChecklistProps> = (props) => {
  const { checklistCards, submitChecklistCard, updateChecklist, formValues, setFormValues } = props;

  // ---- Page markup ----

  return (
    <Layout>
      <Layout.Section>
        {checklistCards?.map((checklistCard) => (
          <ProjectChecklistCard
            key={checklistCard.title}
            checklistCard={checklistCard}
            formValues={formValues}
            setFormValues={setFormValues}
            updateChecklist={updateChecklist}
          />
        ))}
        <br />
        {/* <div style={{ position: 'fixed', bottom: '20px', zIndex: 100 }}>
          <Button primary onClick={() => updateChecklist()} loading={null}>
            Update Checklist
          </Button>
        </div> */}

        <br />
        <Card sectioned title={submitChecklistCard.title}>
          <Grid container rowSpacing={1}>
            <Grid item xs={12}>
              <p style={{ marginBottom: '8px' }}>{submitChecklistCard.description}</p>
            </Grid>
            {submitChecklistCard.errorMessage && (
              <Grid item xs={12}>
                <p style={{ marginBottom: '8px', color: 'red' }}>{submitChecklistCard.errorMessage}</p>
              </Grid>
            )}
            {submitChecklistCard.check && (
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <div style={{ display: 'flex' }}>
                  <div style={{ textAlign: 'right', marginRight: '20px' }}>{submitChecklistCard.check.icon}</div>
                  <p>{submitChecklistCard.check.label}</p>
                </div>
              </Grid>
            )}
            <Grid item xs={12}>
              <div style={{ marginTop: '8px' }}>
                <Button
                  primary
                  onClick={submitChecklistCard.button.action}
                  loading={submitChecklistCard.button.loading}
                >
                  {submitChecklistCard.button.label}
                </Button>
              </div>
            </Grid>
          </Grid>
        </Card>
      </Layout.Section>
    </Layout>
  );
};

export default ProjectChecklist;
