import React from 'react';
import { Button } from '@shopify/polaris';
import { IDataTableColumn } from '../../dataTable/DataTable/model';

const getStatusColor = (status: string) => {
  if (!status) {
    return '';
  }

  if (status.toLowerCase() === 'do not reschedule' || status.toLowerCase() === 'canceled') {
    return 'red';
  }

  if (
    status.toLowerCase() === 'past due' ||
    status.toLowerCase() === 'rescheduling needed' ||
    status.toLowerCase() === 'no show'
  ) {
    return 'orange';
  }

  if (status.toLowerCase() === 'complete') {
    return 'green';
  }

  return '#4ba9c8';
};

const financialInterviewColumns: IDataTableColumn<any>[] = [
  {
    name: 'Project Number',
    selector: 'project_number',
    sortable: true,
  },
  {
    name: 'Phase',
    selector: 'phase_name',
    sortable: true,
  },
  {
    name: 'Respondent',
    selector: 'user_name',
    sortable: true,
    cell: (row) => (
      <>
        {row.insighter_id ? (
          <div>
            <a style={{ color: '#1D1D1D' }} href={`/admin/insighters/${row.insighter_id}`}>
              {row.user_name}
            </a>
          </div>
        ) : (
          <div>{row.user_name}</div>
        )}
      </>
    ),
  },
  {
    name: 'Payout Category',
    selector: 'payout_category',
    sortable: true,
  },
  {
    name: 'Account Status',
    selector: 'account_status',
    sortable: false,
  },
  {
    name: 'Scheduled Time',
    selector: 'scheduled_time',
    sortable: true,
  },
  {
    name: 'Recruiter',
    selector: 'recruiter',
    sortable: true,
  },
  {
    name: 'Moderator',
    selector: 'moderator',
    sortable: true,
  },
  {
    name: 'Project Manager',
    selector: 'project_manager',
    sortable: true,
  },
  {
    name: 'Status',
    selector: 'status',
    sortable: true,
    cell: ({ status }) => (
      <div
        style={{
          fontWeight: 700,
          textTransform: 'uppercase',
          fontSize: 14,
          color: getStatusColor(status?.toLowerCase()),
        }}
      >
        {status}
      </div>
    ),
  },
  {
    name: 'Actions',
    selector: 'actions',
    sortable: false,
    grow: 2,
    cell: (row) => (
      <>
        {row.status !== 'complete' && (
          <Button primary url={`/admin/interviews/mark-complete/${row.id}`}>
            Mark Complete
          </Button>
        )}
      </>
    ),
  },
];

export default financialInterviewColumns;
