import React, { useState, useEffect } from 'react';
import TestimonialViewer from './TestimonialViewer';
import axios from '../../../utils/axios.utils';

const QuotesSection: React.FC = () => {
  const [testimonials, setTestimonials] = useState<any>([]);

  useEffect(() => {
    axios.get('testimonials', { params: { status: 'active' } }).then((response) => {
      setTestimonials(response?.data?.result);
    });
  }, []);

  return <TestimonialViewer testimonials={testimonials} />;
};

export default QuotesSection;
