import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';

import DataTable from '../../dataTable/DataTable/DataTable';
import InvitationOpportunityFilterBar from '../components/InvitationOpportunityFilterBar';
import AdminPageHeader from '../../adminTools/components/AdminPageHeader';
import { InvitationOpportunitiesContext } from '../contexts/InvitationOpportunitiesContext';
import invitationColumns from '../constants/invitationColumns';

const InvitationOpportunitiesList: React.FunctionComponent = () => {
  const [invitationOpportunitiesFetched, setInvitationOpportunitiesFetched] = useState(false);
  const [sort, setSort] = useState('');

  const {
    invitationOpportunities,
    fetchInvitationOpportunities,
    invitationOpportunityLoading,
    totalRecords,
    queryValue,
    statusFilter,
  } = useContext(InvitationOpportunitiesContext);

  useEffect(() => {
    if (!invitationOpportunitiesFetched) {
      fetchInvitationOpportunities(`ransack[status_eq]=active`);
      setInvitationOpportunitiesFetched(true);
    }
  }, [invitationOpportunitiesFetched, setInvitationOpportunitiesFetched, fetchInvitationOpportunities]);

  const history = useHistory();

  const handlePageChange = (page: number) => {
    let formattedStatusFilter = '';
    if (statusFilter) {
      formattedStatusFilter = `ransack[status_eq]=${statusFilter}`;
    }

    let queryFilter = '';
    if (queryValue) {
      queryFilter = `ransack[name_cont]=${queryValue}`;
    }

    fetchInvitationOpportunities(`page=${page}&${queryFilter}&${sort}&${formattedStatusFilter}`);
  };

  const handleColumnSort = async (column: Record<string, string>, sortDirection: string) => {
    const columnNamesToRansack = {
      name: 'name',
      project_number: 'project_project_number',
      created_at: 'created_at',
      invitation_partners_count: 'invitation_partners_count',
      invitations_count: 'invitations_count',
      default_inviter_amount: 'default_inviter_amount',
      inviter_award_type: 'inviter_award_type',
      start_date: 'start_date',
      status: 'status',
    };

    const sort = `sort=${columnNamesToRansack[column.selector]} ${sortDirection}`;
    setSort(sort);

    let formattedStatusFilter = '';
    if (statusFilter) {
      formattedStatusFilter = `ransack[status_eq]=${statusFilter}`;
    }

    let queryFilter = '';
    if (queryValue) {
      queryFilter = `ransack[name_cont]=${queryValue}`;
    }

    fetchInvitationOpportunities(`${queryFilter}&${sort}&${formattedStatusFilter}`);
  };

  const CreateInvitationOpportunityButton = () => (
    <Button className="primary-btn" onClick={() => history.push('/admin/invitation-opportunities/new')}>
      Create Invitation Opportunity
    </Button>
  );

  return (
    <Grid container style={{ padding: '1% 2%' }}>
      <AdminPageHeader title="All Invitation Opportunities" adminButtons={<CreateInvitationOpportunityButton />} />
      <Card style={{ padding: '1% 2%' }}>
        <InvitationOpportunityFilterBar />
        <DataTable
          onRowClicked={(row: Record<string, string>) => history.push(`/admin/invitation-opportunities/${row.id}`)}
          columns={invitationColumns()}
          data={invitationOpportunities}
          progressPending={invitationOpportunityLoading}
          striped
          highlightOnHover
          pointerOnHover
          noHeader
          pagination
          paginationPerPage={25}
          paginationRowsPerPageOptions={[25]}
          paginationServer
          paginationTotalRows={totalRecords}
          onChangePage={handlePageChange}
          onSort={handleColumnSort}
        />
      </Card>
    </Grid>
  );
};

export default InvitationOpportunitiesList;
