import React from 'react';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';

import InsighterRoleForm from '../components/InsighterRoleForm';

import AdminPageHeader from '../../adminTools/components/AdminPageHeader';

const NewInsighterRole: React.FunctionComponent = () => {
  return (
    <Grid container style={{ padding: '1% 2%' }}>
      <AdminPageHeader
        title="Create Insighter Role"
        backButton={{ label: 'Insighter Roles', link: '/admin/insighter-roles' }}
      />
      <Card style={{ padding: '2%' }}>
        <InsighterRoleForm />
      </Card>
    </Grid>
  );
};

export default NewInsighterRole;
