import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Edit from '@material-ui/icons/Edit';

import axios from '../../utils/axios.utils';
import { CaseStudy } from '../model';
import DataTable from '../../dataTable';
import AdminPageHeader from '../../adminTools/components/AdminPageHeader';

const CaseStudiesListPage: React.FC = () => {
  const history = useHistory();

  const [caseStudies, setCaseStudies] = useState<CaseStudy[]>([]);

  const fetchCaseStudies = async () => {
    try {
      const response = await axios.get('case_studies');
      setCaseStudies(response?.data?.result);
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchCaseStudies();
  }, []);

  const caseStudyColumns = [
    {
      name: 'Pic',
      selector: 'pic',
      sortable: true,
      grow: 0.5,
      cell: (row: any) => (
        <img src={row.s3_image_url} alt={row.title} style={{ width: '50px', height: '50px', borderRadius: '50%' }} />
      ),
    },
    { name: 'Title', selector: 'title', sortable: true },
    { name: 'Subtitle', selector: 'subtitle', sortable: true, grow: 3 },
    { name: 'Status', selector: 'status', sortable: true, grow: 0.5 },
    {
      name: 'Featured',
      selector: 'featured',
      sortable: true,
      grow: 0.5,
      cell: (row: any) => (row.featured ? 'yes' : 'no'),
    },
    {
      name: 'Edit',
      selector: 'edit',
      grow: 0.5,
      cell: (row: any) => (
        <Edit
          style={{ cursor: 'pointer' }}
          onClick={(e) => {
            e.stopPropagation();
            history.push(`/admin/case-studies/${row.id}/edit`);
          }}
        />
      ),
    },
  ];

  return (
    <Grid container style={{ padding: '1% 2%' }}>
      <AdminPageHeader
        title="Case Studies"
        primaryButton={{ label: 'New Case Study', link: '/admin/case-studies/create/' }}
        backButton={{ label: 'Admin Tools', link: '/admin/tools' }}
      />
      <Grid item xs={12} style={{ padding: 15 }}>
        <Card>
          <DataTable
            data={caseStudies}
            columns={caseStudyColumns}
            onRowClicked={(rowData: any) => history.push(`/case-studies/${rowData.id}`)}
            striped
            highlightOnHover
            pointerOnHover
            noHeader
          />
        </Card>
      </Grid>
    </Grid>
  );
};

export default CaseStudiesListPage;
