import React from 'react';
import axios from '../../utils/axios.utils';
import { QualtricsMetadata, SurveyResponse } from '../model';

interface SurveyResponsesContextInterface {
  surveyResponseLoading?: boolean;
  fetchSurveyResponses?: (phase_id: string, params?: string) => Promise<void>;
  surveyResponses?: SurveyResponse[];

  fetchQualtricsMetadata?: (respondent_id: string, phase_id: string) => Promise<void>;
  qualtricsMetadata?: QualtricsMetadata;
  metadataError?: string;

  phaseId?: string;
  createManualImport?: (phase_id: string) => Promise<void>;
  updateSurveyResponse?: (
    survey_response_id: string,
    formValues: Record<string, boolean>,
    onSuccess: () => void,
  ) => Promise<void>;
  surveyResponseSubmitting?: boolean;
}

const SurveyResponsesContext = React.createContext<SurveyResponsesContextInterface>({});

const SurveyResponsesContextConsumer = SurveyResponsesContext.Consumer;
const SurveyResponsesContextProvider: React.FC = ({ children }) => {
  const [surveyResponses, setSurveyResponses] = React.useState<SurveyResponse[]>([]);
  const [surveyResponseLoading, setSurveyResponseLoading] = React.useState<boolean>(false);
  const [surveyResponseSubmitting, setSurveyResponseSubmitting] = React.useState(false);
  const [metadataError, setMetaDataError] = React.useState('');
  const [qualtricsMetadata, setQualtricsMetadata] = React.useState<QualtricsMetadata>({});

  const fetchSurveyResponses = async (phase_id: string, params?: string) => {
    setSurveyResponseLoading(true);

    const response = await axios.get<string, any>(`survey_responses?phase_id=${phase_id}&${params}`);

    setSurveyResponseLoading(false);
    setSurveyResponses(response?.data?.result);
  };

  const fetchQualtricsMetadata = async (respondent_id: string, phase_id: string) => {
    await axios
      .get<string, any>(`qualtrics_metadata/${phase_id}?respondent_id=${respondent_id}`)
      .then((response) => {
        setQualtricsMetadata(response?.data?.result);
      })
      .catch((error) => {
        setMetaDataError(error.response.data.message);
      });
  };

  const createManualImport = async (phase_id: string) => {
    setSurveyResponseSubmitting(true);

    const response = await axios.post<string, any>(`survey_responses`, {
      phase_id,
    });

    setSurveyResponses(response?.data?.result);
    setSurveyResponseSubmitting(false);
  };

  const updateSurveyResponse = async (
    survey_response_id: string,
    formValues: Record<string, boolean>,
    onSuccess: () => void,
  ) => {
    setSurveyResponseSubmitting(true);

    await axios.put<string, any>(`survey_responses/${survey_response_id}`, {
      survey_response: { ...formValues },
    });

    setSurveyResponseSubmitting(false);
    onSuccess();
  };

  return (
    <SurveyResponsesContext.Provider
      value={{
        surveyResponseLoading,
        fetchSurveyResponses,
        surveyResponses,

        createManualImport,
        updateSurveyResponse,
        surveyResponseSubmitting,

        fetchQualtricsMetadata,
        qualtricsMetadata,
        metadataError,
      }}
    >
      {children}
    </SurveyResponsesContext.Provider>
  );
};

export { SurveyResponsesContextProvider, SurveyResponsesContextConsumer, SurveyResponsesContext };
