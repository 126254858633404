import React from 'react';
import { useHistory } from 'react-router-dom';
import axios from '../../utils/axios.utils';
import { ConditionCategory, ConditionCategoryFormValues } from '../model';

interface ConditionCategoriesContextInterface {
  conditionCategory?: ConditionCategory;
  fetchConditionCategories?: () => void;
  fetchConditionCategory?: (condition_id: string) => void;
  conditionCategoriesLoading?: boolean;
  conditionCategories?: ConditionCategory[];
  formError?: string;
  createConditionCategory?: (formValues: ConditionCategoryFormValues) => void;
  updateConditionCategory?: (condition_category_id: string, formValues: ConditionCategoryFormValues) => void;
  conditionCategorySubmitting?: boolean;
}

const ConditionCategoriesContext = React.createContext<ConditionCategoriesContextInterface>({});

const ConditionCategoriesContextConsumer = ConditionCategoriesContext.Consumer;
const ConditionCategoriesContextProvider: React.FC = ({ children }) => {
  const history = useHistory();

  const [conditionCategories, setConditionCategories] = React.useState<ConditionCategory[]>([]);
  const [conditionCategoriesLoading, setConditionCategoriesLoading] = React.useState<boolean>(false);
  const [conditionCategorySubmitting, setConditionCategorySubmitting] = React.useState(false);
  const [conditionCategory, setConditionCategory] = React.useState<ConditionCategory>({ name: '', id: '' });

  const fetchConditionCategories = async (params = '') => {
    setConditionCategoriesLoading(true);

    const response = await axios.get<string, any>(`condition_categories`);

    setConditionCategories(response?.data?.result);
    setConditionCategoriesLoading(false);
  };

  const fetchConditionCategory = async (conditionCategoryId) => {
    setConditionCategoriesLoading(true);

    const response = await axios.get<string, any>(`condition_categories/${conditionCategoryId}`);
    console.log(response);
    setConditionCategory(response?.data?.result);
    setConditionCategoriesLoading(false);
  };

  const createConditionCategory = async (formValues: ConditionCategoryFormValues) => {
    setConditionCategorySubmitting(true);

    await axios.post<string, any>(`condition_categories`, {
      condition_category: { ...formValues },
    });

    setConditionCategorySubmitting(false);
    history.push('/admin/conditions/categories');
  };

  const updateConditionCategory = async (condition_category_id: string, formValues: ConditionCategoryFormValues) => {
    setConditionCategorySubmitting(true);

    await axios.put<string, any>(`condition_categories/${condition_category_id}`, {
      condition_category: { ...formValues },
    });

    setConditionCategorySubmitting(false);
    history.push('/admin/conditions/categories');
  };

  return (
    <ConditionCategoriesContext.Provider
      value={{
        conditionCategory,
        fetchConditionCategories,
        conditionCategoriesLoading,
        conditionCategories,
        fetchConditionCategory,
        createConditionCategory,
        updateConditionCategory,
      }}
    >
      {children}
    </ConditionCategoriesContext.Provider>
  );
};

export { ConditionCategoriesContextProvider, ConditionCategoriesContextConsumer, ConditionCategoriesContext };
