import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';

import InsighterFilterBar from '../components/InsighterFilterBar';
import InsightersList from '../components/InsightersList';
import AdminPageHeader from '../../adminTools/components/AdminPageHeader';

const InsightersListContainer: React.FunctionComponent = () => {
  let { search } = useLocation();
  let history = useHistory();
  let signupParams = new URLSearchParams(search).get('signups');

  let CreateInsighterButton = () => (
    <Button className="primary-btn" onClick={() => history.push('/admin/insighters/new')}>
      Create Insighter
    </Button>
  );

  return (
    <Grid container style={{ padding: '1% 2%' }}>
      <AdminPageHeader
        title={signupParams ? 'Recent Insighters' : 'All Insighters'}
        adminButtons={<CreateInsighterButton />}
      />
      <Card style={{ padding: '1% 2%' }}>
        <InsighterFilterBar />
        <InsightersList />
      </Card>
    </Grid>
  );
};

export default InsightersListContainer;
