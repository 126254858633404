import React, { Dispatch, SetStateAction } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import Slide from '@material-ui/core/Slide';
import IconButton from '@material-ui/core/IconButton';
import { TeamBio } from '../../../teamBios/model';

interface BioModalProps {
  bioModalOpen: boolean;
  setBioModalOpen: Dispatch<SetStateAction<boolean>>;
  bioModalInfo: TeamBio | null;
}

const BioModal: React.FC<BioModalProps> = (props) => {
  let { bioModalOpen, setBioModalOpen, bioModalInfo } = props;

  let handleClose = () => setBioModalOpen(false);

  const styles = {
    body: {
      fontSize: '1.6rem',
      fontFamily: 'Rethink Sans',
      wordWrap: 'break-word' as const,
    },
    modalContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
    },
    modalContent: {
      position: 'relative' as const,
      backgroundColor: 'white',
      padding: '20px',
      borderRadius: '20px',
      boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
      width: '800px',
      maxWidth: '100%',
    },
    closeButton: {
      position: 'absolute' as const,
      top: '10px',
      right: '10px',
      width: '30px',
      height: '30px',
      borderRadius: '50%',
      border: '1px solid gray',
      backgroundColor: 'transparent',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
    },
    closeIcon: {
      color: 'gray',
      fontSize: '18px',
    },
    heading: {
      fontSize: '20px',
      fontWeight: 'bold' as const,
      fontFamily: 'Crimson Text',
    },
  };

  return (
    <Modal open={bioModalOpen} onClose={handleClose}>
      <Slide direction="left" in={bioModalOpen} mountOnEnter unmountOnExit>
        <div style={styles.modalContainer}>
          <Grid container style={styles.modalContent}>
            <Grid item xs={12} md={6} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <img
                src={bioModalInfo?.bio_image}
                alt={bioModalInfo?.name}
                style={{ width: '100%', maxHeight: '400px', maxWidth: '300px' }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container style={{ padding: 20 }}>
                <Grid item xs={12}>
                  <IconButton style={styles.closeButton} onClick={handleClose}>
                    <span style={styles.closeIcon}>✕</span>
                  </IconButton>
                </Grid>
                <Grid item xs={12}>
                  <Typography style={{ fontFamily: 'Crimson Text', fontSize: 36, fontWeight: 600, color: '#3B4255' }}>
                    {bioModalInfo?.name}
                  </Typography>
                </Grid>
                <Grid item xs={12} style={{ marginTop: -12 }}>
                  <img src="https://cdn.theinsighters.com/BioUnderline.png" alt="" />
                </Grid>
                <Grid item xs={12}>
                  <Typography style={{ fontFamily: 'Rethink Sans', fontSize: 16, fontWeight: 600, color: '#888888' }}>
                    {bioModalInfo?.title}
                  </Typography>
                </Grid>
                <Grid item xs={12} style={{ marginTop: 20 }}>
                  <Typography style={styles.body} className="uploaded-content">
                    {/* eslint-disable-next-line */}
                    <div dangerouslySetInnerHTML={{ __html: bioModalInfo?.bio }} />
                  </Typography>
                </Grid>
                {bioModalInfo?.link && (
                  <a href={bioModalInfo.link} target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}>
                    <Grid
                      item
                      xs={12}
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: 20,
                        alignItems: 'center',
                        paddingLeft: 20,
                      }}
                    >
                      <img
                        src="https://cdn.theinsighters.com/LinkedInIcon.png"
                        style={{ width: 40, height: 40 }}
                        alt="Linked In Icon"
                      />
                      <Typography
                        style={{
                          fontFamily: 'Rethink Sans',
                          fontSize: 16,
                          fontWeight: 600,
                          color: 'blue',
                          marginLeft: 15,
                          textDecoration: 'none',
                        }}
                      >
                        Connect on LinkedIn
                      </Typography>
                    </Grid>
                  </a>
                )}
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Slide>
    </Modal>
  );
};

export default BioModal;
