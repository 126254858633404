import React, { useCallback, useState, useEffect, useContext } from 'react';
import { Button, Card, Loading, Page, Toast } from '@shopify/polaris';
import { useHistory, useLocation } from 'react-router-dom';
import DataTableSkeleton from '../../loading/components/DataTableSkeleton';
import DataTable from '../../dataTable/DataTable/DataTable';
import { EthnicitiesContext } from '../contexts/EthnicitiesContext';
import { IDataTableColumn } from '../../dataTable/DataTable/model';

const listColumns = (): IDataTableColumn[] => [
  {
    name: 'Ethnicity Name',
    selector: 'name',
    sortable: true,
  },
  {
    name: 'Insighter Counter',
    selector: 'insighter_count',
    sortable: true,
  },
  {
    name: 'Descriptions',
    selector: 'alternate_labels',
    sortable: true,
  },
  {
    name: 'Actions',
    selector: 'status',
    sortable: true,
    cell: (row): React.ReactNode => (
      <div style={{ marginRight: 10 }}>
        <Button url={`/admin/ethnicities/${row.id}/edit`}>Edit</Button>
      </div>
    ),
  },
];

interface EthnicitiesListProps {
  isLoading: boolean;
}

const EthnicitiesList: React.FunctionComponent<EthnicitiesListProps> = (props) => {
  const { isLoading } = props;
  const [ethnicitiesFetched, setEthnicitiesFetched] = useState<boolean>(false);

  const { ethnicities, fetchEthnicities, ethnicitiesLoading, totalRecords, fetchParams, sortParams, setSortParams } =
    useContext(EthnicitiesContext);

  const query = new URLSearchParams(useLocation().search);
  const filterParams = query.get('filters')?.split('__')?.join('&');

  useEffect(() => {
    if (!ethnicitiesFetched) {
      setEthnicitiesFetched(true);
      fetchEthnicities(filterParams);
    }
  }, [ethnicitiesFetched, setEthnicitiesFetched, fetchEthnicities, filterParams]);

  const history = useHistory();
  const [toastActive, setToastActive] = useState(false);
  const loadingMarkup = isLoading ? <Loading /> : null;

  const toggleToastActive = useCallback(() => setToastActive((toastActive) => !toastActive), []);
  const toastMarkup = toastActive ? <Toast onDismiss={toggleToastActive} content="Changes saved" /> : null;

  const handleColumnSort = async (column: Record<string, string>, sortDirection: string) => {
    const columnNamesToRansack = {
      name: 'name',
      insighter_count: 'insighter_count',
      status: 'status',
    };

    const sort = `sort=${columnNamesToRansack[column.selector]} ${sortDirection}`;

    setSortParams(sort);
    fetchEthnicities(`${fetchParams}&${sort}&${filterParams}`);
  };

  const handlePageChange = (page: number) => {
    fetchEthnicities(`page=${page}&${filterParams}&${fetchParams}&${sortParams}`);
  };

  const actualPageMarkup = (
    <Page fullWidth title="Ethnicities">
      <Card>
        <br />
        <DataTable
          onRowClicked={(row: Record<string, string>) => history.push(`/admin/ethnicities/${row.id}`)}
          columns={listColumns()}
          progressPending={ethnicitiesLoading}
          data={ethnicities}
          pagination
          striped
          highlightOnHover
          pointerOnHover
          paginationPerPage={25}
          paginationRowsPerPageOptions={[25]}
          noHeader
          paginationServer
          paginationTotalRows={totalRecords}
          onChangePage={handlePageChange}
          sortServer
          onSort={handleColumnSort}
        />
      </Card>
    </Page>
  );

  // ---- Loading ----
  const loadingPageMarkup = <DataTableSkeleton />;

  const pageMarkup = isLoading ? loadingPageMarkup : actualPageMarkup;

  return (
    <>
      {loadingMarkup}
      {pageMarkup}
      {toastMarkup}
    </>
  );
};

export default EthnicitiesList;
