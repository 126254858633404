import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from '../../utils/axios.utils';
import { UserContext } from '../../auth/contexts/UserContext';
import { SelectOption } from '../../form/model';

interface RegistrationsContextInterface {
  registerUser?: (formValues: Record<string, string>) => Promise<void>;
  formErrors?: Record<string, string>;
  setFormErrors?: (formErrors: Record<string, string>) => void;
  formError?: string;
  profileLoading?: boolean;
  profileSubmitting?: boolean;
  profile?: Record<string, any>;
  fetchProfile?: (user_id: string) => Promise<void>;
  updateProfile?: (user_id: string, formValues: Record<string, any>, isAdmin: boolean) => Promise<void>;
  archiveProfile?: (user_id: string) => Promise<void>;
  deleteProfile?: (user_id: string) => Promise<void>;
  fetchFormOptions?: () => Promise<void>;
  formOptions?: Record<string, SelectOption[]>;
  deleteProfileErrors?: string;
  deleteAccountModalOpen?: boolean;
  setDeleteAccountModalOpen?: (deleteAccountModalOpen: boolean) => void;
}

const RegistrationsContext = React.createContext<RegistrationsContextInterface>({
  registerUser: null,
  formErrors: {},
  profileLoading: false,
});

const RegistrationsContextConsumer = RegistrationsContext.Consumer;
const RegistrationsContextProvider: React.FC = ({ children }) => {
  const { setUser, logout } = React.useContext(UserContext);
  const history = useHistory();

  const [formErrors, setFormErrors] = useState({});
  const [deleteProfileErrors, setDeleteProfileErrors] = React.useState('');
  const [formError, setFormError] = useState('');
  const [profile, setProfile] = useState();
  const [profileLoading, setProfileLoading] = useState<boolean>(false);
  const [profileSubmitting, setProfileSubmitting] = useState(false);
  const [formOptions, setFormOptions] = useState({
    insighter_role_options: [{ label: 'Patient', value: 'Patient' }],
  });
  const [deleteAccountModalOpen, setDeleteAccountModalOpen] = useState(false);

  const registerUser = async (formValues: Record<string, string>) => {
    setProfileLoading(true);
    try {
      await axios
        .post(`registrations`, { user: formValues })
        .then((response: Record<string, any>) => {
          if (response.data.jwt) {
            localStorage.setItem('token', response.data.jwt);
            setUser(response.data.user);
            setProfileLoading(false);
            history.push('/sign-up/optional-information');
          } else {
            setProfileLoading(false);
            history.push('/confirm-email');
          }
        })
        .catch((error) => {
          setProfileLoading(false);
          setFormErrors({
            email: error.response.data.error.email,
            password: error.response.data.error.password,
            first_name: error.response.data.error.first_name,
            last_name: error.response.data.error.last_name,
          });
        });
    } catch {
      setProfileLoading(false);
      setFormErrors({ email: 'Something went wrong, please reload the page and try again.' });
    }
  };

  const fetchProfile = async (user_id: string) => {
    setProfileLoading(true);

    try {
      await axios.get(`users/${user_id}`).then((response: Record<string, any>) => {
        setProfile(response.data.result);
        setProfileLoading(false);
      });
    } catch {
      setProfileLoading(false);
      setFormErrors({ email: 'Something went wrong, please reload the page and try again.' });
    }
  };

  const updateProfile = async (user_id: string, formValues: Record<string, any>, isAdmin: boolean) => {
    setProfileSubmitting(true);
    try {
      await axios
        .put<string, any>(`users/${user_id}.json`, {
          user: { ...formValues },
        })
        .then(() => {
          setProfileSubmitting(false);
          if (isAdmin) {
            history.push('/admin/dashboard');
          } else {
            history.push('/opportunities');
          }
        })
        .catch((error) => {
          setProfileSubmitting(false);
          const errorMessages = Object.values(error.response.data.error);
          setFormError(errorMessages.join(', '));
        });
    } catch {
      setProfileSubmitting(false);
      setFormError('Something went wrong, please reload the page and try again.');
    }
  };

  const archiveProfile = async (user_id: string) => {
    try {
      await axios
        .put<string, any>(`users/${user_id}/archive`, {
          user: { archived_profile: true },
        })
        .then(() => {
          logout();
        })
        .catch((error) => {
          const errorMessages = Object.values(error.response.data.error);
          setFormError(errorMessages.join(', '));
        });
    } catch {
      setFormError('Something went wrong, please reload the page and try again.');
    }
  };

  const deleteProfile = async (user_id: string) => {
    setDeleteAccountModalOpen(false);
    try {
      await axios
        .delete<string, any>(`users/${user_id}.json`)
        .then(() => {
          logout();
        })
        .catch((error) => {
          setDeleteProfileErrors(error.response.data.error);
        });
    } catch {
      setFormError('Something went wrong, please reload the page and try again.');
    }
  };

  const fetchFormOptions = async () => {
    const response = await axios.get<string, any>(`users/new.json`);

    setFormOptions(response?.data);
  };

  return (
    <RegistrationsContext.Provider
      value={{
        registerUser,
        formErrors,
        setFormErrors,
        formError,
        profileLoading,
        profileSubmitting,
        profile,
        fetchProfile,
        updateProfile,
        fetchFormOptions,
        formOptions,
        archiveProfile,
        deleteProfile,
        deleteProfileErrors,
        deleteAccountModalOpen,
        setDeleteAccountModalOpen,
      }}
    >
      {children}
    </RegistrationsContext.Provider>
  );
};
export { RegistrationsContextProvider, RegistrationsContextConsumer, RegistrationsContext };
