import React, { useState } from 'react';
import { Grid, Button, InputAdornment, TextField, makeStyles } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import FilterBarPaintSmear from '../../resourcesPage/assets/FilterBarPaintSmear.png';
import { SelectOption } from '../../../form/model';

const useStyleOverrides = makeStyles((theme) => ({
  root: {
    float: 'right',
    '& .MuiFilledInput-root': {
      borderRadius: '20px',
      backgroundColor: '#407667',
      color: 'white',
      fontSize: 16,
      '&:before': {
        borderBottom: 'none',
      },
      '&:after': {
        borderBottom: 'none',
      },
      '&:hover:not(.Mui-disabled):before': {
        borderBottom: 'none',
      },
    },
  },
}));

interface OpportunitiesFilterBarDesktopProps {
  fetchOpportunities: (category?: string, query?: string) => void;
  topicOptions: SelectOption[];
}

const OpportunitiesFilterBarDesktop: React.FC<OpportunitiesFilterBarDesktopProps> = (props) => {
  const { fetchOpportunities, topicOptions } = props;
  const overrideClasses = useStyleOverrides();

  const [selectedCategory, setSelectedCategory] = useState<string | undefined>('0');
  const [searchQuery, setSearchQuery] = useState<string>('');

  const handleCategoryClicked = (category: string | undefined) => {
    setSelectedCategory(category);
    fetchOpportunities(category, searchQuery);
  };

  const handleSearchClicked = () => {
    fetchOpportunities(selectedCategory, searchQuery);
  };

  return (
    <Grid container>
      <Grid item xs={12} style={{ marginTop: 25 }}>
        <div
          style={{
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            backgroundImage: `url(${FilterBarPaintSmear})`,
            backgroundPosition: 'center center',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            height: 80,
            maxWidth: 1200,
            margin: 'auto',
          }}
        >
          <Grid
            container
            spacing={2}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              position: 'relative',
              paddingLeft: '10%',
              paddingRight: '10%',
              alignItems: 'center',
            }}
          >
            {topicOptions?.map((filterLink) => (
              <Grid
                item
                key={filterLink.label}
                style={{
                  position: 'relative',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Button
                  style={
                    selectedCategory === filterLink.value
                      ? { backgroundColor: '#F3C66B' }
                      : { backgroundColor: 'transparent' }
                  }
                  onClick={() => handleCategoryClicked(filterLink.value)}
                >
                  <p
                    style={{
                      color: selectedCategory === filterLink.value ? 'black' : 'white',
                      fontSize: 16,
                      fontFamily: 'Rethink Sans, sans-serif',
                      textTransform: 'capitalize',
                      fontWeight: 700,
                    }}
                  >
                    {filterLink.label}
                  </p>
                </Button>
              </Grid>
            ))}
            <Grid item xs={3}>
              <TextField
                hiddenLabel
                id="filled-hidden-label-small"
                defaultValue=""
                variant="filled"
                size="small"
                className={overrideClasses.root}
                value={searchQuery}
                onChange={(value) => setSearchQuery(value.target.value)}
                InputProps={{
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment position="end">
                      <Search onClick={handleSearchClicked} />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
};

export default OpportunitiesFilterBarDesktop;
