import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((_theme) => ({
  loadingIcon: {
    flexShrink: 0,
    marginTop: '30px',
    marginLeft: 'calc(50% - 20px)',
  },
}));

const LoadingSpinner: React.FC = () => {
  const classes = useStyles();
  return <CircularProgress className={classes.loadingIcon} />;
};

export default LoadingSpinner;
