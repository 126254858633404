import React from 'react';
import HeroPageHeader from '../../common/HeroPageHeader';

interface OpportunitiesListHeroProps {
  heroImageUrl: string;
}

const OpportunitiesListHero: React.FC<OpportunitiesListHeroProps> = (props) => {
  const { heroImageUrl } = props;

  return (
    <HeroPageHeader
      heroImageUrl={heroImageUrl}
      ornamentText="Get Paid for Research"
      titleText="Your Healthcare Insights"
      subtitleText="Unlock Change"
      buttonText="Become an Insighter"
    />
  );
};

export default OpportunitiesListHero;
