import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { Button } from '@shopify/polaris';
import { useHistory } from 'react-router-dom';
import { ProjectPhasesContext } from '../../projectPhases/contexts/ProjectPhasesContext';
import { RespondentsContext } from '../contexts/RespondentsContext';
import SelectInput from '../../form/components/SelectInput';
import FormInput from '../../form/components/FormInput';
import { Respondent, RespondentFormValues } from '../model';
import axios from '../../utils/axios.utils';
import InformationField from '../../form/components/InformationField';

type CopyToInvOppFormProps = {
  respondent: Respondent;
};

const CopyToInvOppForm: React.FC<CopyToInvOppFormProps> = (props) => {
  const { respondent } = props;
  const { phase } = React.useContext(ProjectPhasesContext);
  const [projectOptions, setProjectOptions] = React.useState();
  const [invOppOptions, setInvOppOptions] = React.useState();

  const { copyRespondentToInvOpp } = React.useContext(RespondentsContext);
  const [formValues, setFormValues] = useState<RespondentFormValues>({});
  const [respondentSubmitting, setRespondentSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const history = useHistory();

  useEffect(() => {
    axios.get<string, any>(`invitation_opportunities/new.json`).then((response) => {
      setProjectOptions(response?.data?.project_options);

      axios.get<string, any>(`invitation_opportunities/new?project_id=${respondent?.project_id}`).then((response) => {
        setInvOppOptions(response?.data?.inv_opp_options);
      });
    });
  }, [respondent]);

  useEffect(() => {
    if (!formValues?.invitation_opportunity_id) {
      setErrorMessage('Invitation Opportunity is a Required Field');
    } else {
      setErrorMessage('');
    }
  }, [formValues, respondent]);

  const handleProjectChange = async (value: string) => {
    setFormValues({ ...formValues, project_id: value });
    await axios.get<string, any>(`invitation_opportunities/new?project_id=${value}`).then((response) => {
      setInvOppOptions(response?.data?.inv_opp_options);
    });
  };

  const handleCopy = () => {
    setRespondentSubmitting(true);

    const afterCreate = () => {
      history.push(`/admin/invitation-opportunities/${formValues?.invitation_opportunity_id}`);
    };

    copyRespondentToInvOpp(respondent.id, formValues, afterCreate);
  };

  return (
    <div style={{ marginTop: -40 }}>
      <Grid container spacing={4}>
        <Grid item>
          <InformationField label="Respondent Name" value={respondent.name} />
        </Grid>
        <Grid item>
          <InformationField label="User ID" value={respondent.uuid} />
        </Grid>
        <Grid item>
          <InformationField label="Source Phase" value={phase.name} />
        </Grid>
      </Grid>
      <br />
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <SelectInput
            label="Project"
            value={formValues?.project_id}
            choices={projectOptions}
            onChange={handleProjectChange}
          />
        </Grid>
        <Grid item xs={6}>
          <SelectInput
            label="Invitation Opportunity"
            choices={invOppOptions}
            value={formValues?.invitation_opportunity_id}
            onChange={(value) => setFormValues({ ...formValues, invitation_opportunity_id: value })}
          />
        </Grid>
        <Grid item xs={6}>
          <FormInput
            label="Original Qualtrics Status"
            value={formValues?.qualtrics_status}
            onChange={(value) => null}
            disabled
          />
        </Grid>
        <Grid item xs={6}>
          <FormInput label="Interview Status" value="No Interview" onChange={(value) => null} disabled />
        </Grid>
      </Grid>

      {!respondentSubmitting && (
        <Button primary onClick={handleCopy} disabled={errorMessage?.length > 0}>
          Copy To Invitation Opportunity
        </Button>
      )}

      <p style={{ color: 'red', marginBottom: 7 }}>{errorMessage}</p>
    </div>
  );
};

export default CopyToInvOppForm;
