import React from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  container: {
    position: 'relative',
  },
  icon: {
    position: 'absolute',
    top: 16,
    left: 92.5,
    fontSize: 40,
    color: '#fff',
  },
  iconSimple: {
    left: 16,
  },
  iconSmall: {
    position: 'absolute',
    top: 8,
    left: 8,
    fontSize: 16,
    color: '#e0e0e0',
  },
  circleContainer: {
    marginRight: 31,
  },
  circleContainerSmall: {
    display: 'flex',
    flex: 'auto',
    alignItems: 'center',
    marginRight: 11,
  },
  action: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  tooltip: {
    textAlign: 'center',
  },
  barSimple: {
    position: 'absolute',
    top: '50%',
    left: -31,
    width: 31,
    height: 3,
    backgroundColor: '#f2f2f2',
  },
  barSmall: {
    position: 'absolute',
    top: '50%',
    left: -11,
    width: 11,
    height: 3,
    backgroundColor: '#f2f2f2',
  },
  textContainer: {
    marginTop: -40,
    textAlign: 'center',
  },
  actionContainer: {
    margin: '16px auto',
  },
  buttonContainer: {
    margin: '16px auto',
    textAlign: 'center',
  },
  button: {
    color: '#fff',
  },
});

interface TimelineEventProps {
  variant?: any;
  first?: any;
  color?: any;
  icon?: any;
  title?: any;
  subtitle?: any;
  action?: any;
  titleProps?: any;
}

const TimelineEvent: React.FC<TimelineEventProps> = (props) => {
  const { color = '#e0e0e0', icon: Icon, title = '', subtitle = '', action = null } = props;

  const classes = useStyles();

  return (
    <Grid className={classes.container}>
      <>
        {Icon && <Icon className={classes.icon} />}
        <svg width={225} height={187} viewBox="0 0 59.531 49.477" style={{ marginTop: -50, width: '100%' }}>
          <g transform="matrix(.99959 0 0 .99838 -100.96 -38.57)">
            <path
              d="M101.002 69.656h55.492l4.064 4.158-4.064 4.205h-55.492l3.85-4.205z"
              fill={color}
              strokeWidth={0.24}
            />
            <circle cx={130.726} cy={73.838} r={1.522} fill="#fff" strokeWidth={0.15} />
          </g>
        </svg>
        <Grid className={classes.textContainer}>
          {typeof title === 'string' ? <Typography variant="h6">{title}</Typography> : title}
          {typeof subtitle === 'string' ? <Typography>{subtitle}</Typography> : subtitle}
        </Grid>
        {action && <Grid className={classes.actionContainer}>action</Grid>}
      </>
    </Grid>
  );
};

export default TimelineEvent;
