import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { InvitationOpportunity } from '../model';

const useStyles = makeStyles({
  amountContainer: {
    flexDirection: 'row',
    marginTop: 2,
  },
  accordionHeader: {
    fontSize: '16px',
    fontWeight: 'bold',
  },
  container: { marginBottom: '10px' },
  divContainer: { display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' },
  image: { width: '100%', borderRadius: '6px' },
  imageContainer: { display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' },
  shareNetwork: {
    verticalAlign: 'top',
    display: 'inline-block',
    marginRight: 10,
    textAlign: 'center',
  },
  shareButton: {
    cursor: 'pointer',
  },
  inviteeAmount: {
    fontSize: 14,
    fontWeight: 600,
    marginTop: 4,
  },
  inviterAmount: {
    fontSize: 18,
    fontWeight: 600,
    marginTop: 4,
  },
  invitationTitle: {
    fontSize: '24px',
    lineHeight: '28px',
    fontWeight: 600,
    marginBottom: 4,
  },
});

interface InactiveShareCardProps {
  invitationOpportunity: InvitationOpportunity;
}

const InactiveShareCard: React.FC<InactiveShareCardProps> = (props) => {
  const classes = useStyles();

  const { invitationOpportunity } = props;

  return (
    <>
      {/* Hide Image on Smaller Displays */}
      <Grid container sx={{ display: { xs: 'block', sm: 'block', md: 'block', lg: 'none', xl: 'none' } }}>
        <Grid item xs={12} sm={12} md={5}>
          <div className={classes.divContainer}>
            <h1 className={classes.invitationTitle}>{invitationOpportunity?.name}</h1>
            <p>{invitationOpportunity.description}</p>
          </div>
        </Grid>
      </Grid>
      {/* Show Image on Larger Displays */}
      <Grid container spacing={3} sx={{ display: { xs: 'none', sm: 'none', md: 'none', lg: 'flex', xl: 'flex' } }}>
        <Grid item xs={12} md={2}>
          <div className={classes.imageContainer}>
            <img
              className={classes.image}
              src={invitationOpportunity?.s3_image_url}
              alt={`${invitationOpportunity?.name} Graphic`}
            />
          </div>
        </Grid>
        <Grid item xs={12} md={10}>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item xs={6}>
              <h1 className={classes.invitationTitle}>{invitationOpportunity?.name}</h1>
              <p>{invitationOpportunity.description}</p>
            </Grid>
            <Grid item xs={6} style={{ display: 'flex', alignItems: 'center' }}>
              <div>
                <h3 className={classes.accordionHeader}>Number of Invitations</h3>
                <Typography
                  variant="body1"
                  component="p"
                  className={classes.accordionHeader}
                  style={{ fontSize: '14px', fontWeight: 300, marginTop: 5 }}
                >
                  {invitationOpportunity?.invitations?.length} Invitations
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default InactiveShareCard;
