import React from 'react';
import { useParams } from 'react-router-dom';
import { Card, Layout, Loading } from '@shopify/polaris';
import Grid from '@material-ui/core/Grid';
import OpportunityForm from '../components/OpportunityForm';
import { OpportunitiesContext } from '../contexts/OpportunitiesContext';
import FormSkeleton from '../../loading/components/FormSkeleton';
import { badgeColorByStatus } from '../../projectPhases/constants/phaseColumns';

import AdminPageHeader from '../../adminTools/components/AdminPageHeader';

const EditOpportunity: React.FunctionComponent = () => {
  const { opportunityId } = useParams<Record<string, string>>();
  const [opportunityFetched, setOpportunityFetched] = React.useState<boolean>(false);
  const { opportunity, fetchOpportunity, opportunityLoading } = React.useContext(OpportunitiesContext);

  React.useEffect(() => {
    if (!opportunityFetched) {
      fetchOpportunity(opportunityId);
      setOpportunityFetched(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadingMarkup = opportunityLoading ? <Loading /> : null;

  // ---- Page markup ----
  const actualPageMarkup = (
    <Grid container style={{ padding: '1% 2%' }}>
      <AdminPageHeader
        backButton={{ label: 'Opportunities', link: '/admin/opportunities' }}
        title={`Edit Opportunity: ${opportunity?.name}`}
        badge={{ label: opportunity?.status }}
      />
      <Grid item xs={12}>
        <Card sectioned>{opportunity.id && <OpportunityForm opportunity={opportunity} />}</Card>
      </Grid>
    </Grid>
  );

  // ---- Loading ----
  const loadingPageMarkup = <FormSkeleton />;
  const pageMarkup = opportunityLoading ? loadingPageMarkup : actualPageMarkup;

  return (
    <>
      {loadingMarkup}
      {pageMarkup}
    </>
  );
};

export default EditOpportunity;
