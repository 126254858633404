import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from '../../utils/axios.utils';
import { InvitationOpportunity, InvitationOpportunityFormValues } from '../model';

interface InvitationOpportunitiesContextInterface {
  fetchInvitationOpportunities?: (params?: string) => Promise<void>;
  invitationOpportunities?: InvitationOpportunity[];
  invitationOpportunitySubmitting?: boolean;
  createInvitationOpportunity?: (formValues: InvitationOpportunityFormValues) => Promise<void>;
  invitationOpportunityLoading?: boolean;

  fetchInvitationOpportunity?: (invitation_opportunity_id: string) => Promise<void>;
  invitationOpportunity?: InvitationOpportunity;

  updateInvitationOpportunity?: (
    invitation_opportunity_id: string,
    formValues: InvitationOpportunityFormValues,
  ) => Promise<void>;
  deleteInvitationOpportunity?: (invitation_opportunity_id: string) => Promise<void>;
  archiveInvitationOpportunity?: (invitation_opportunity_id: string) => Promise<void>;

  totalRecords?: number;

  fetchFormOptions?: () => Promise<void>;
  formOptions?: Record<string, any[]>;

  formError?: string;

  statusFilter?: string[];
  setStatusFilter?: React.Dispatch<React.SetStateAction<string[]>>;

  queryValue?: string;
  setQueryValue?: React.Dispatch<React.SetStateAction<string>>;
}

const InvitationOpportunitiesContext = React.createContext<InvitationOpportunitiesContextInterface>({
  fetchInvitationOpportunities: undefined,
  invitationOpportunitySubmitting: false,
  fetchInvitationOpportunity: undefined,
  formOptions: { project_options: [] },
});

const InvitationOpportunitiesContextConsumer = InvitationOpportunitiesContext.Consumer;
const InvitationOpportunitiesContextProvider: React.FC = ({ children }) => {
  const history = useHistory();

  const [invitationOpportunity, setInvitationOpportunity] = React.useState<InvitationOpportunity>({});
  const [invitationOpportunities, setInvitationOpportunities] = React.useState<InvitationOpportunity[]>([]);
  const [invitationOpportunityLoading, setInvitationOpportunityLoading] = React.useState<boolean>(false);
  const [totalRecords, setTotalRecords] = React.useState<number>();
  const [formOptions, setFormOptions] = useState<Record<string, any[]>>();
  const [formError, setFormError] = React.useState<string>();
  const [statusFilter, setStatusFilter] = React.useState<string[]>(['active']);
  const [queryValue, setQueryValue] = useState('');

  const [invitationOpportunitySubmitting, setInvitationOpportunitySubmitting] = React.useState<boolean>(false);

  const fetchInvitationOpportunities = async (params = '') => {
    setInvitationOpportunityLoading(true);
    const response = await axios.get<string, any>(`invitation_opportunities.json?${params}`);

    setInvitationOpportunityLoading(false);
    setInvitationOpportunities(response?.data?.result);
    setStatusFilter(response?.data?.applied_filters?.status?.value.toLowerCase());
    setTotalRecords(response?.data?.total_records);
  };

  const createInvitationOpportunity = async (formValues: InvitationOpportunityFormValues) => {
    try {
      setInvitationOpportunitySubmitting(true);

      axios
        .post<string, any>(`invitation_opportunities.json`, {
          invitation_opportunity: { ...formValues },
        })
        .then((response) => {
          setInvitationOpportunitySubmitting(false);
          history.push(`/admin/invitation-opportunities/${response.data.result.id}`);
        })
        .catch((error) => {
          setInvitationOpportunitySubmitting(false);
          setFormError(error.response.data.error);
        });
    } catch {
      setInvitationOpportunitySubmitting(false);
      setFormError('Something went wrong, please reload the page and try again.');
    }
  };

  const fetchInvitationOpportunity = async (invitation_opportunity_id: string) => {
    setInvitationOpportunityLoading(true);

    const response = await axios.get<string, any>(`invitation_opportunities/${invitation_opportunity_id}.json`);

    setInvitationOpportunityLoading(false);
    setInvitationOpportunity(response?.data?.result);
  };

  const fetchFormOptions = async () => {
    const response = await axios.get<string, any>(`invitation_opportunities/new.json`);
    setFormOptions(response?.data);
  };

  const updateInvitationOpportunity = async (
    invitation_opportunity_id: string,
    formValues: InvitationOpportunityFormValues,
  ) => {
    setInvitationOpportunitySubmitting(true);

    const response = await axios.put<string, any>(`invitation_opportunities/${invitation_opportunity_id}.json`, {
      invitation_opportunity: { ...formValues },
    });

    setInvitationOpportunitySubmitting(false);

    if (response?.data?.error) {
      setFormError(response?.data?.error);
    } else {
      history.push(`/admin/invitation-opportunities/${invitation_opportunity_id}`);
    }
  };

  const deleteInvitationOpportunity = async (invitation_opportunity_id: string) => {
    setInvitationOpportunitySubmitting(true);

    await axios.delete<string, any>(`invitation_opportunities/${invitation_opportunity_id}.json`);

    setInvitationOpportunitySubmitting(false);
    history.push(`/admin/invitation-opportunities/`);
  };

  const archiveInvitationOpportunity = async (invitation_opportunity_id: string) => {
    setInvitationOpportunitySubmitting(true);

    const response = await axios.put<string, any>(`invitation_opportunities/${invitation_opportunity_id}.json`, {
      invitation_opportunity: { status: 'archived', update_status: 'false' },
    });

    setInvitationOpportunitySubmitting(false);

    history.push('/admin/invitation-opportunities/');
  };

  return (
    <InvitationOpportunitiesContext.Provider
      value={{
        fetchInvitationOpportunities,
        invitationOpportunityLoading,
        invitationOpportunities,
        createInvitationOpportunity,
        invitationOpportunitySubmitting,
        totalRecords,
        fetchFormOptions,
        formOptions,
        fetchInvitationOpportunity,
        invitationOpportunity,
        updateInvitationOpportunity,
        deleteInvitationOpportunity,
        archiveInvitationOpportunity,
        formError,
        statusFilter,
        setStatusFilter,
        queryValue,
        setQueryValue,
      }}
    >
      {children}
    </InvitationOpportunitiesContext.Provider>
  );
};

export {
  InvitationOpportunitiesContextProvider,
  InvitationOpportunitiesContextConsumer,
  InvitationOpportunitiesContext,
};
