import React from 'react';
import { useHistory } from 'react-router-dom';
import axios from '../../utils/axios.utils';

interface InsighterNotesContextInterface {
  createInsighterNote?: (formValues: Record<string, string>) => Promise<void>;
  updateInsighterNote?: (noteId: string, formValues: Record<string, string>) => Promise<void>;
  deleteInsighterNote?: (noteId: string, insighterId: string) => Promise<void>;
}

const InsighterNotesContext = React.createContext<InsighterNotesContextInterface>({});

const InsighterNotesContextConsumer = InsighterNotesContext.Consumer;
const InsighterNotesContextProvider: React.FC = ({ children }) => {
  const history = useHistory();

  const createInsighterNote = async (formValues: Record<string, string>) => {
    await axios.post<string, any>(`insighter_notes.json`, {
      insighter_note: { ...formValues },
    });

    history.push(`/admin/insighters/${formValues.insighter_id}`);
  };

  const updateInsighterNote = async (noteId: string, formValues: Record<string, string>) => {
    await axios.put<string, any>(`insighter_notes/${noteId}`, {
      insighter_note: { ...formValues },
    });

    history.push(`/admin/insighters/${formValues.insighter_id}`);
  };

  const deleteInsighterNote = async (noteId: string, insighterId: string) => {
    await axios.delete<string, any>(`insighter_notes/${noteId}`);

    history.push(`/admin/insighters/${insighterId}`);
  };

  return (
    <InsighterNotesContext.Provider
      value={{
        createInsighterNote,
        updateInsighterNote,
        deleteInsighterNote,
      }}
    >
      {children}
    </InsighterNotesContext.Provider>
  );
};

export { InsighterNotesContextProvider, InsighterNotesContextConsumer, InsighterNotesContext };
