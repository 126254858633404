import React, { useState } from 'react';
import { Button, Card, ChoiceList, DatePicker, Filters } from '@shopify/polaris';
import { Grid } from '@material-ui/core';
import { format } from 'date-fns';
import { InterviewsContext } from '../contexts/InterviewsContext';
import interviewStatuses from '../constants/interviewStatuses';

const InterviewFilterBar: React.FunctionComponent = () => {
  const {
    fetchInterviews,
    interviewLoading,
    filterValues,
    setFilterValues,
    selectedDates,
    setSelectedDates,
    statusFilters,
    setStatusFilters,
  } = React.useContext(InterviewsContext);

  const [scheduledTimeActive, setScheduledTimeActive] = useState<any>({
    month: parseInt(format(new Date(), 'MM'), 10) - 1,
    year: parseInt(format(new Date(), 'yyyy'), 10),
  });

  const [queryValue, setQueryValue] = useState<string>(sessionStorage.getItem('interviewFilterQuery') || '');

  const [tempSelectedDates, setTempSelectedDates] = useState<any>({});

  const [tempStatusFilters, setTempStatusFilters] = useState<string[]>();

  React.useEffect(() => {
    setTempSelectedDates({
      start: selectedDates?.start,
      end: selectedDates?.end,
    });
  }, [selectedDates]);

  React.useEffect(() => {
    setTempStatusFilters(statusFilters);
  }, [statusFilters]);

  const applyFilters = () => {
    const queryFilter = `ransack[query]=${queryValue}`;
    let startDateFilter = `ransack[scheduled_time_gteq]=${tempSelectedDates?.start}`;
    let endDateFilter = `ransack[scheduled_time_lteq]=${tempSelectedDates?.end}`;

    try {
      startDateFilter = `ransack[scheduled_time_gteq]=${format(tempSelectedDates?.start, 'dd/MM/yyyy')}`;
      endDateFilter = `ransack[scheduled_time_lteq]=${format(tempSelectedDates?.end, 'dd/MM/yyyy')}`;
    } catch {
      startDateFilter = `ransack[scheduled_time_gteq]=${tempSelectedDates?.start}`;
      endDateFilter = `ransack[scheduled_time_lteq]=${tempSelectedDates?.end}`;
    }

    let formattedStatusFilter = '';
    tempStatusFilters?.forEach((filter, index) => {
      if (index === 0) {
        formattedStatusFilter += `ransack[status_in][]=${filter}`;
      } else {
        formattedStatusFilter += `&ransack[status_in][]=${filter}`;
      }
    });

    setFilterValues({ ...filterValues, query: queryValue });
    setStatusFilters(tempStatusFilters);
    setSelectedDates({ start: tempSelectedDates.start, end: tempSelectedDates.end });

    sessionStorage.setItem(
      'interviewFilters',
      `${queryFilter}&${formattedStatusFilter}&${startDateFilter}&${endDateFilter}&sort=${filterValues.sort}`,
    );
    sessionStorage.setItem('interviewFilterQuery', queryValue);

    fetchInterviews(
      `${queryFilter}&${formattedStatusFilter}&${startDateFilter}&${endDateFilter}&sort=${filterValues.sort}`,
    );
  };

  const clearFilters = () => {
    setQueryValue('');

    setTempSelectedDates({
      start: new Date(1900, 0, 1),
      end: new Date(2100, 0, 1),
    });

    setSelectedDates({
      start: new Date(1900, 0, 1),
      end: new Date(2100, 0, 1),
    });

    setTempStatusFilters(null);
    setStatusFilters(null);
    setFilterValues({ query: '' });

    sessionStorage.removeItem('interviewFilters');
    sessionStorage.removeItem('interviewFilterQuery');
    fetchInterviews();
  };

  const handleFiltersKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      applyFilters();
    }
  };

  const appliedFiltersArray = [];

  if (statusFilters) {
    appliedFiltersArray.push({
      key: 'status',
      label: `Filtered by ${statusFilters}`,
      onRemove: () => {
        setStatusFilters(null);
        setTempStatusFilters(null);
      },
    });
  }

  if (selectedDates.start && selectedDates.end) {
    let formattedStartDate = `${selectedDates.start}`;
    let formattedEndDate = `${selectedDates.end}`;

    try {
      formattedStartDate = `${format(selectedDates?.start, 'MM/dd/yyyy')}`;
      formattedEndDate = `${format(selectedDates?.end, 'MM/dd/yyyy')}`;
    } catch {
      formattedStartDate = `${selectedDates?.start}`;
      formattedEndDate = `${selectedDates?.end}`;
    }

    if (!(formattedStartDate.includes('1900') && formattedEndDate.includes('2100'))) {
      appliedFiltersArray.push({
        key: 'selectedDates',
        label: `Filtering from ${formattedStartDate} to ${formattedEndDate}`,
        onRemove: () => {
          setTempSelectedDates({
            start: new Date(1900, 0, 1),
            end: new Date(2100, 0, 1),
          });
          setSelectedDates({
            start: new Date(1900, 0, 1),
            end: new Date(2100, 0, 1),
          });
        },
      });
    }
  }

  const filters = [
    {
      key: 'status',
      label: 'Status',
      filter: (
        <ChoiceList
          title="Status"
          titleHidden
          allowMultiple
          choices={interviewStatuses}
          selected={tempStatusFilters || []}
          onChange={(value) => setTempStatusFilters(value)}
        />
      ),
      shortcut: true,
    },
    {
      key: 'scheduled_time',
      label: 'Scheduled Time',
      filter: (
        <DatePicker
          month={scheduledTimeActive?.month}
          year={scheduledTimeActive?.year}
          onChange={setTempSelectedDates}
          onMonthChange={(month, year) => setScheduledTimeActive({ month, year })}
          selected={tempSelectedDates}
          allowRange
        />
      ),
      shortcut: true,
    },
  ];

  return (
    <Card.Section>
      <div role="form" onKeyDown={handleFiltersKeyPress}>
        {!interviewLoading && (
          <Filters
            queryValue={queryValue}
            queryPlaceholder="Filter by project number, project name, phase name, or respondent name..."
            filters={filters}
            appliedFilters={appliedFiltersArray}
            onQueryChange={(value) => setQueryValue(value)}
            onQueryClear={() => setQueryValue('')}
            onClearAll={() => null}
          >
            <Grid container>
              <Grid item>
                <div style={{ paddingLeft: '8px' }}>
                  <Button primary onClick={applyFilters}>
                    Apply Filters
                  </Button>
                </div>
              </Grid>
              <Grid item>
                <div style={{ paddingLeft: '8px' }}>
                  <Button onClick={clearFilters}>Clear Filters</Button>
                </div>
              </Grid>
            </Grid>
          </Filters>
        )}
      </div>
    </Card.Section>
  );
};

export default InterviewFilterBar;
