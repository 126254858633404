import React from 'react';
import { Grid } from '@material-ui/core';
import LoginForm from '../components/LoginForm';
import Page from '../../shared/components/Page';

const LoginPage: React.FC = () => {
  return (
    <Page fullWidth title="">
      <div style={{ marginTop: 100, marginLeft: 20, marginRight: 20 }}>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={6}>
            <h2 className="Polaris-Header-Title" style={{ fontFamily: 'Cooper', fontSize: '36px', marginBottom: 10 }}>
              Log In
            </h2>
            <br />
            <LoginForm />
          </Grid>
        </Grid>
      </div>
    </Page>
  );
};

export default LoginPage;
