import React from 'react';
import { TopBar } from '@shopify/polaris';
import UserMenu from './UserMenu';
import Logo from '../../../assets/logo.png';

interface TopNavigationProps {
  mobileNavigationActive: boolean;
  setMobileNavigationActive: any;
}

const TopNavigation: React.FC<TopNavigationProps> = (props) => {
  const [searchActive, setSearchActive] = React.useState<boolean>(false);
  const { mobileNavigationActive, setMobileNavigationActive } = props;

  const toggleMobileNavigationActive = () => {
    setMobileNavigationActive(!mobileNavigationActive);
  };

  const handleSearchResultsDismiss = React.useCallback(() => {
    setSearchActive(false);
  }, []);

  const contextControlMarkup = (
    <div>
      <img alt="logo" style={{ height: 40, margin: '10px 20px' }} src={Logo} />
    </div>
  );

  return (
    <TopBar
      showNavigationToggle
      userMenu={<UserMenu />}
      searchResultsVisible={searchActive}
      onSearchResultsDismiss={handleSearchResultsDismiss}
      onNavigationToggle={toggleMobileNavigationActive}
      contextControl={contextControlMarkup}
      searchResultsOverlayVisible
    />
  );
};

export default TopNavigation;
