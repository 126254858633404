import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from '../../utils/axios.utils';
import { InvitationsResponse, InvitationFormValues } from '../model';
import { SelectOption } from '../../form/model';

interface InvitationsContextInterface {
  statusFilter?: string;
  setStatusFilter?: React.Dispatch<React.SetStateAction<string>>;
  fetchInvitations?: (impersonatedUserId?: string) => Promise<void>;

  invitations?: Record<string, any>[];
  updateInvitation?: (
    invitation_id: string,
    formValues: InvitationFormValues,
    afterUpdate?: () => void,
  ) => Promise<void>;
  fetchFormOptions?: (project_id: string) => Promise<void>;
  formOptions?: Record<string, SelectOption[]>;
  invitationSubmitting?: boolean;
  invitationOpportunities?: Record<string, any>[];
  invitationsLoading?: boolean;
  deleteInvitation?: (invitationId: string) => Promise<void>;
  totalRecords?: number;
  fetchInvitationsForOpportunityDetail?: (params?: string) => Promise<void>;
  fetchParams?: string;
  setFetchParams?: React.Dispatch<React.SetStateAction<string>>;
  invitationsDownload?: Record<string, any>[];
  filterValues?: Record<string, string>;
  setFilterValues?: React.Dispatch<React.SetStateAction<Record<string, string>>>;
  updateFilterValues?: (string) => void;
}

const InvitationsContext = React.createContext<InvitationsContextInterface>({});

const InvitationsContextProvider: React.FC = ({ children }) => {
  const history = useHistory();
  const [statusFilter, setStatusFilter] = useState('');
  const [invitationsLoading, setInvitationsLoading] = useState(false);
  const [invitationSubmitting, setInvitationSubmitting] = useState(false);
  const [totalRecords, setTotalRecords] = useState<number>();
  const [invitations, setInvitations] = useState([]);
  const [invitationOpportunities, setInvitationOpportunities] = useState([]);
  const [fetchParams, setFetchParams] = useState<string>('ransack[query]=');
  const [invitationsDownload, setInvitationsDownload] = useState([]);
  const [filterValues, setFilterValues] = useState<Record<string, string>>({});

  const [formOptions, setFormOptions] = useState({});

  const updateFilterValues = (invitationOpportunityId) => {
    const newFilter = {
      query: sessionStorage.getItem(`invitationFilterQuery-${invitationOpportunityId}`) || '',
    };
    setFilterValues(newFilter);
  };

  const fetchInvitations = async (impersonatedUserId?: string) => {
    setInvitationsLoading(true);
    let response: InvitationsResponse;
    if (impersonatedUserId) {
      response = await axios.get<string, InvitationsResponse>(`impersonations/${impersonatedUserId}/invitations`);
    } else {
      response = await axios.get<string, InvitationsResponse>(`insighter_invitations`);
    }

    setInvitations(response?.data?.invitations);
    setInvitationOpportunities(response?.data?.invitation_opportunities);
    setInvitationsLoading(false);
  };

  const fetchInvitationsForOpportunityDetail = async (params?: string) => {
    setInvitationsLoading(true);
    const response = await axios.get<string, any>(`invitations.json?${params}`).then((response) => {
      setInvitationsLoading(false);
      setInvitations(response?.data?.result);
      setTotalRecords(response?.data?.total_records);
      setInvitationsDownload(response?.data?.invitation_csv_data);
    });
  };

  const fetchFormOptions = async (invitation_opportunity_id: string) => {
    axios.get<string, any>(`invitation_opportunities/${invitation_opportunity_id}/invitations/new`).then((response) => {
      setFormOptions({ phase_options: response?.data?.phase_options });
    });
  };

  const updateInvitation = async (
    invitation_id: string,
    formValues: InvitationFormValues,
    afterUpdate?: () => void,
  ) => {
    setInvitationSubmitting(true);

    await axios.put<string, any>(`invitations/${invitation_id}.json`, {
      invitation: { ...formValues },
    });

    setInvitationSubmitting(false);
    if (afterUpdate) {
      afterUpdate();
    } else {
      history.push(`/admin/invitations/${invitation_id}`);
    }
  };

  const deleteInvitation = async (invitationId: string) => {
    await axios
      .delete<string, any>(`invitations/${invitationId}`)
      .then(() => {
        const filteredInvitations = invitations.filter((invitation) => invitation.id !== invitationId);
        setInvitations(filteredInvitations);
      })
      .catch((error) => {
        // setInvitationError(error.response.data.error);
      });
  };

  return (
    <InvitationsContext.Provider
      value={{
        statusFilter,
        setStatusFilter,
        fetchInvitations,
        invitations,
        invitationOpportunities,
        invitationsLoading,
        updateInvitation,
        formOptions,
        fetchFormOptions,
        deleteInvitation,
        fetchInvitationsForOpportunityDetail,
        totalRecords,
        fetchParams,
        setFetchParams,
        invitationsDownload,
        filterValues,
        setFilterValues,
        updateFilterValues,
      }}
    >
      {children}
    </InvitationsContext.Provider>
  );
};

export { InvitationsContextProvider, InvitationsContext };
