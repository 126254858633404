import React, { useState, useEffect, useContext } from 'react';
import { Card, Page, FormLayout, Button, Toast } from '@shopify/polaris';
import { Grid } from '@material-ui/core';
import ConfirmCancelModal from '../../modal/components/MaterialModal';
import { ConditionsContext } from '../contexts/ConditionsContext';
import { MergeConditionsFormValues } from '../model';
import SelectInput from '../../form/components/SelectInput';
import axios from '../../utils/axios.utils';

const MergeConditions: React.FunctionComponent = () => {
  const { deleteCondition } = useContext(ConditionsContext);

  const [conditionsFetched, setConditionsFetched] = useState<boolean>(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
  const [formValues, setFormValues] = useState<MergeConditionsFormValues>({});
  const [conditionsMerging, setConditionsMerging] = useState<boolean>(false);
  const [mergeConditionsError, setMergeConditionsError] = useState<string>('');
  const [toastActive, setToastActive] = useState<boolean>(false);
  const [optionToDeleteCondition, setOptionToDeleteCondition] = useState<boolean>(false);
  const [conditionChoices, setConditionChoices] = useState<any[]>([]);

  const fetchConditionChoices = async () => {
    const response = await axios.get<string, any>(`/merge_conditions`);

    setConditionChoices(response?.data?.condition_choices);
  };

  useEffect(() => {
    if (!conditionsFetched) {
      fetchConditionChoices();
      setConditionsFetched(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conditionsFetched]);

  const mergeConditions = async (formValues: MergeConditionsFormValues, onSucess: () => void) => {
    setConditionsMerging(true);

    await axios
      .put<string, any>(`/merge_conditions/0`, {
        condition2: formValues.condition2,
        condition1: formValues.condition1,
      })
      .then((response) => {
        setConditionsMerging(false);
        fetchConditionChoices();
        onSucess();
      })
      .catch((error) => {
        setConditionsMerging(false);
        setMergeConditionsError(error?.response?.data?.error);
      });
  };

  const mergeSuccess = () => {
    setToastActive(true);
    setOptionToDeleteCondition(true);
  };

  const findChoice = (choiceId: string) => {
    const condition = conditionChoices.find((choice) => choice.value === choiceId);
    return condition?.label;
  };

  return (
    <Page fullWidth title="Merge Conditions">
      <Card sectioned>
        <FormLayout>
          <Grid container spacing={3}>
            <ConfirmCancelModal
              isOpen={confirmModalOpen}
              title="Delete Condition"
              onCancel={() => setConfirmModalOpen(false)}
              onWarning={() => deleteCondition(formValues.condition1)}
              warningLabel="Delete Condition"
              cancelLabel="Cancel"
              hasCancelButton
              hasWarningButton
              hasFooterBar={false}
              height="250px"
            >
              <div style={{ marginTop: '20px', marginBottom: '20px' }}>
                <p>Are you sure you want to delete this condition?</p>
              </div>
            </ConfirmCancelModal>
            <Grid item xs={12}>
              <SelectInput
                label="Condition 1) Insighters with this condition will have the second condition added."
                choices={conditionChoices}
                value={formValues?.condition1}
                onChange={(value) => setFormValues({ ...formValues, condition1: value })}
              />
            </Grid>
            <Grid item xs={12}>
              <SelectInput
                label="Condition 2) This condition will be added to all Insighters with the first condition."
                choices={conditionChoices}
                value={formValues?.condition2}
                onChange={(value) => setFormValues({ ...formValues, condition2: value })}
              />
            </Grid>
            <br />
            <Grid container>
              <Grid item xs={12}>
                <Button
                  primary
                  onClick={() => mergeConditions(formValues, () => mergeSuccess())}
                  loading={conditionsMerging}
                >
                  Merge Conditions
                </Button>
              </Grid>
              {mergeConditionsError && (
                <Grid item xs={12}>
                  <p style={{ color: 'red', fontSize: '14px', marginTop: 20 }}>{mergeConditionsError}</p>
                </Grid>
              )}
            </Grid>
            <br />
            {optionToDeleteCondition && (
              <>
                <h3 className="Polaris-Heading">Delete Condition</h3>
                <Grid container>
                  <Grid item xs={12}>
                    <p>
                      {`${findChoice(formValues?.condition2)}
                has successfully been added to all the Insighters with ${findChoice(formValues?.condition1)}.`}
                    </p>
                  </Grid>
                  <Grid item xs={12}>
                    <p>{`Would you like to delete the ${findChoice(formValues?.condition1)} condition?`}</p>
                    <br />
                  </Grid>
                  <Grid item xs={12}>
                    <Button destructive onClick={() => setConfirmModalOpen(true)}>
                      {`Delete ${findChoice(formValues?.condition1)}`}
                    </Button>
                  </Grid>
                </Grid>
              </>
            )}
            {toastActive && (
              <Toast
                onDismiss={() => setToastActive(false)}
                error={false}
                content="Successfully added condition to Insighters"
              />
            )}
          </Grid>
        </FormLayout>
      </Card>
    </Page>
  );
};

export default MergeConditions;
