import React from 'react';

interface ShortHoverButtonProps {
  text: string;
  onSubmit: () => void;
}

const styles = {
  shortHoverButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative' as const,
    height: '40px',
    transition: 'background-color 0.3s ease',
    background: '#F3C66B',
    borderRadius: '120px',
    border: 'none',
    cursor: 'pointer',
    overflow: 'hidden',
    padding: '0 20px',
  },
  shortHoverButtonText: {
    fontFamily: 'Rethink Sans',
    fontWeight: 'bold' as const,
  },
};

const ShortHoverButton: React.FC<ShortHoverButtonProps> = (props) => {
  let { text, onSubmit } = props;

  return (
    <button type="button" style={styles.shortHoverButton} onClick={onSubmit}>
      <span style={styles.shortHoverButtonText}>{text}</span>
    </button>
  );
};

export default ShortHoverButton;
