/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Layout, Button } from '@shopify/polaris';
import { Grid } from '@material-ui/core';
import { CSVLink } from 'react-csv';
import axios from '../../utils/axios.utils';
import Page from '../../shared/components/Page';
import DataTable from '../../dataTable/DataTable/DataTable';
import { InsighterFinderContext } from '../contexts/InsighterFinderContext';
import { SavedSearchesContext } from '../contexts/SavedSearchesContext';
import SelectInput from '../../form/components/SelectInput';
import FormInput from '../../form/components/FormInput';
import { InvitationPartnerFormValues } from '../model';
import ConfirmCancelModal from '../../modal/components/MaterialModal';
import LeadGroupForm from '../../leadGroups/components/LeadGroupForm';
import InsighterFinderColumns from '../constants/InsighterFinderColumns';
import filterTests from '../tests/filterTests';
import todaysDate from '../../utils/dateFunctions';
import SavedSearchRow from '../components/SavedSearchRow';
import SavedSearchDeleteModal from '../components/SavedSearchDeleteModal';
import PhasePicker from '../components/PhasePicker';
import InsighterFinderFilterBuilder from '../components/InsighterFinderFilterBuilder';

const InsighterFinder: React.FC = () => {
  const { leadGroupId = '' } = useParams<Record<string, string>>();
  const history = useHistory();
  const {
    fetchInsighters,
    insighters,
    insighterLoading,
    totalRecords,
    createLeads,
    formOptions,
    phaseOptions,
    leadGroupOptions,
    fetchLeadGroupOptions,
    filteredInsighterIDs,
    leadsSubmitting,
    selectedLeads,
    setSelectedLeads,
    leadsDownload,
    invitationPartnersSubmitting,
    addBulkInvitationPartners,
    filterBuilderValue,
    setFilterBuilderValue,
    handleProjectChange,
    handlePhaseChange,
    selectedProject,
    selectedPhase,
    setSelectedProject,
    setSelectedPhase,
    fetchFormOptions,
    setShowPhasePicker,
  } = useContext(InsighterFinderContext);

  const { setSelectedSavedSearchId, setLoadedSavedSearch } = useContext(SavedSearchesContext);

  const [leadGroupOptionsFetched, setLeadGroupOptionsFetched] = useState(false);
  const [toggledClearRows, setToggleClearRows] = useState(false);
  const [selectedLeadGroup, setSelectedLeadGroup] = useState(leadGroupId);
  const [selectedInvitationOpportunity, setSelectedInvitationOpportunity] = useState<string>('');
  const [sortValue, setSortValue] = useState<any>();
  const [leadGroupModalOpen, setLeadGroupModalOpen] = useState<boolean>();
  const [insighterAction, setInsighterAction] = useState<string>('');
  const [invitationOpportunityFormValues, setInvitationOpportunityFormValues] = useState<InvitationPartnerFormValues>({
    invitee_amount: '0',
    inviter_amount: '0',
    invitation_quota: '0',
  });

  const testPhaseRef = useRef(null);

  useEffect(() => {
    if (selectedPhase && !leadGroupOptionsFetched) {
      setLeadGroupOptionsFetched(true);
      fetchLeadGroupOptions(selectedPhase);
    }
  }, [selectedPhase, fetchLeadGroupOptions, leadGroupOptionsFetched]);

  useEffect(() => {
    fetchFormOptions();
  }, []);

  const filterIsCompound = (input) => {
    return input && Array.isArray(input[0]);
  };

  const handleSelectedInsighters = ({ selectedRows }) => {
    setSelectedLeads(selectedRows);
  };

  const handleSubmitSelectedLeads = () => {
    createLeads(
      {
        project_id: selectedProject,
        phase_id: selectedPhase,
        lead_group_id: selectedLeadGroup,
        insighter_ids: selectedLeads.map((lead) => lead.id),
      },
      () => history.push(`/admin/lead_groups/${selectedLeadGroup}`),
    );
  };

  // Clarify the difference between these two
  const handleSubmitFilteredLeads = () => {
    createLeads(
      {
        project_id: selectedProject,
        phase_id: selectedPhase,
        lead_group_id: selectedLeadGroup,
        insighter_ids: filteredInsighterIDs,
      },
      () => history.push(`/admin/lead_groups/${selectedLeadGroup}`),
    );
  };

  const handleSelectedInvitationOpportunity = async (invitation_opportunity_id: string) => {
    const response = await axios.get<string, any>(
      `bulk_invitation_partners/new?invitation_opportunity_id=${invitation_opportunity_id}`,
    );

    setSelectedInvitationOpportunity(invitation_opportunity_id);
    setInvitationOpportunityFormValues({
      ...invitationOpportunityFormValues,
      invitee_amount: response?.data?.result?.default_invitee_amount,
      inviter_amount: response?.data?.result?.default_inviter_amount,
    });
  };

  const handleSubmitSelectedInvitationPartners = () => {
    addBulkInvitationPartners({
      invitation_opportunity_id: selectedInvitationOpportunity,
      invitee_amount: invitationOpportunityFormValues.invitee_amount,
      inviter_amount: invitationOpportunityFormValues.inviter_amount,
      invitation_quota: invitationOpportunityFormValues.invitation_quota,
      insighter_ids: selectedLeads.map((lead) => lead.id),
    });
  };

  const handleSubmitFilteredInvitationPartners = () => {
    addBulkInvitationPartners({
      invitation_opportunity_id: selectedInvitationOpportunity,
      invitee_amount: invitationOpportunityFormValues.invitee_amount,
      inviter_amount: invitationOpportunityFormValues.inviter_amount,
      invitation_quota: invitationOpportunityFormValues.invitation_quota,
      insighter_ids: filteredInsighterIDs,
    });
  };

  const createFilterParams = (inputData) => {
    if (inputData === null) {
      return '';
    }

    const data = filterIsCompound(inputData) ? inputData : [inputData];

    let filterParts = [];

    data.forEach((item: any) => {
      const [key, operation, filterValue] = item;
      let value = filterValue;

      if (operation === '<>') {
        if (typeof value === 'string') {
          value = [value];
        }

        const conditions = value.map((condition: any) => `ransack[${key}][]=${condition}&`);
        filterParts = filterParts.concat(conditions);
      } else if (key === 'already_in_phase') {
        value = testPhaseRef.current || selectedPhase;
        filterParts.push(`ransack[${key}]=${value}&`);
      } else if (key === 'already_in_project') {
        value = selectedProject;
        filterParts.push(`ransack[${key}]=${value}&`);
      } else if (key !== 'a' && key !== 'o') {
        filterParts.push(`ransack[${key}]=${value}&`);
      }
    });

    return filterParts.join('') || '';
  };

  const shouldRunTests = true;

  const runFilterTests = () => {
    if (shouldRunTests) {
      // Remember the current phase.
      testPhaseRef.current = '39';

      // Temporarily set the phase to '39' for testing.
      setSelectedPhase('39');

      filterTests.forEach((test) => {
        if (createFilterParams(test[0]) === test[1]) {
          console.log('Passing');
        } else {
          console.log('Failing');
          console.log(test[0]);
          console.log(createFilterParams(test[0]));
        }
      });

      // Restore the original phase.
      testPhaseRef.current = null;
    }
  };

  useEffect(() => {
    runFilterTests();
  }, []);

  useEffect(() => {
    if (leadGroupId) {
      axios.get(`/lead_groups/${leadGroupId}`).then((response) => {
        const projectId = response?.data?.result?.project_id;
        const phaseId = response?.data?.result?.phase_id;
        setSelectedProject(projectId);
        handleProjectChange(projectId);
        setSelectedPhase(phaseId);
        handlePhaseChange(phaseId);
        setShowPhasePicker(true);
      });
    }
  }, [selectedLeadGroup]);

  const handlePageChange = (page: number) => {
    let filterParams = createFilterParams(filterBuilderValue);

    filterParams += `&page=${page}&${sortValue}`;

    fetchInsighters(filterParams);
  };

  const handleColumnSort = async (column: Record<string, string>, sortDirection: string) => {
    const columnNamesToRansack = {
      name: 'user_last_name',
      uuid: 'user_uuid',
      insighter_roles: 'insighter_roles',
      user_type: 'user_type',
      gender: 'gender',
      city: 'city',
      state: 'state',
      sexuality: 'sexuality',
    };

    let filterParams = createFilterParams(filterBuilderValue);

    setSortValue(`&sort=${columnNamesToRansack[column.selector]} ${sortDirection}`);
    filterParams += `&sort=${columnNamesToRansack[column.selector]} ${sortDirection}`;
    fetchInsighters(filterParams, filterBuilderValue[1]);
  };

  const handleApplyFilter = () => {
    setSelectedLeads([]);
    fetchInsighters(createFilterParams(filterBuilderValue), filterBuilderValue[1]);
    setToggleClearRows(!toggledClearRows);
  };

  return (
    <Page fullWidth title="Insighter Finder">
      <SavedSearchDeleteModal />
      <PhasePicker />
      <Layout>
        <Layout.Section>
          <div style={{ marginLeft: '20px', marginRight: '20px' }}>
            <div style={{ width: '100%', marginBottom: 25 }}>
              <InsighterFinderFilterBuilder />
              <br />
              <SavedSearchRow />
              <br />
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <div>
                  <Button
                    onClick={() => {
                      setFilterBuilderValue(null);
                      setLoadedSavedSearch({ search_filters: '' });
                      setSelectedSavedSearchId(null);
                    }}
                  >
                    Clear Filter
                  </Button>
                  <span style={{ marginLeft: 7 }} />
                  <Button primary disabled={!filterBuilderValue} onClick={handleApplyFilter}>
                    Apply Filter
                  </Button>
                </div>
                <div>
                  {leadsDownload && (
                    <CSVLink
                      data={leadsDownload}
                      filename={`LEADS-${todaysDate()}.csv`}
                      style={{ textDecoration: 'none' }}
                    >
                      <Button primary>{leadsDownload.length > 1999 ? 'Download (Limited to 2000)' : 'Download'}</Button>
                    </CSVLink>
                  )}
                </div>
              </div>
            </div>
            <h2 className="Polaris-Heading" style={{ margin: '10px 0' }}>
              Filtered Insighters
            </h2>
            <DataTable
              columns={InsighterFinderColumns}
              data={insighters}
              progressPending={insighterLoading}
              selectableRows
              onSelectedRowsChange={handleSelectedInsighters}
              clearSelectedRows={toggledClearRows}
              pagination
              paginationServer
              paginationPerPage={25}
              paginationTotalRows={totalRecords}
              onChangePage={handlePageChange}
              highlightOnHover
              pointerOnHover
              noHeader
              sortServer
              onSort={handleColumnSort}
            />
            <br />
            <Grid container spacing={3} justifyContent="space-between">
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <SelectInput
                  label="Choose Insighter Action"
                  choices={[
                    { label: 'Add Leads to Group', value: 'leads' },
                    { label: 'Add Insighters to Invitation Opportunity', value: 'invitation' },
                  ]}
                  onChange={setInsighterAction}
                  value={insighterAction}
                />
              </Grid>
              {selectedPhase && (
                <Grid item xs={6} sm={6} md={3} lg={2} style={{ marginTop: 20 }}>
                  <ConfirmCancelModal
                    isOpen={leadGroupModalOpen}
                    onCancel={() => setLeadGroupModalOpen(false)}
                    title="Create Lead Group"
                  >
                    <div style={{ margin: '40px 20px' }}>
                      <LeadGroupForm
                        leadGroup={{
                          phase_id: selectedPhase,
                        }}
                        afterAction={() => {
                          setLeadGroupModalOpen(false);
                        }}
                      />
                    </div>
                  </ConfirmCancelModal>
                  <Button primary onClick={() => setLeadGroupModalOpen(true)}>
                    Create New Lead Group
                  </Button>
                </Grid>
              )}
            </Grid>
            {insighterAction === 'leads' && (
              <>
                <Grid container spacing={3}>
                  <Grid item xs={6} sm={6} md={3} lg={2}>
                    <SelectInput
                      label="Project"
                      choices={formOptions?.project_options}
                      onChange={handleProjectChange}
                      value={selectedProject}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={3} lg={2}>
                    <SelectInput
                      label="Phase"
                      choices={phaseOptions?.phase_options}
                      onChange={handlePhaseChange}
                      value={selectedPhase}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={3} lg={2}>
                    <SelectInput
                      label="Lead Group"
                      choices={leadGroupOptions?.lead_group_options}
                      onChange={(value) => setSelectedLeadGroup(value)}
                      value={selectedLeadGroup}
                    />
                  </Grid>
                </Grid>
                <br />
                <Button
                  primary
                  disabled={selectedLeadGroup === ''}
                  onClick={handleSubmitSelectedLeads}
                  loading={leadsSubmitting}
                >
                  {selectedPhase === '' ? 'Select a Phase for Leads' : `Add ${selectedLeads.length} Selected Leads`}
                </Button>
                <span style={{ marginLeft: '5px' }}>
                  <Button disabled={selectedPhase === ''} onClick={handleSubmitFilteredLeads} loading={leadsSubmitting}>
                    {selectedPhase === ''
                      ? 'Select a Phase for Leads'
                      : `Add All ${filteredInsighterIDs.length} Filtered Leads`}
                  </Button>
                </span>
              </>
            )}
            {insighterAction === 'invitation' && (
              <>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12} md={6} lg={4}>
                    <SelectInput
                      label="Invitation Opportunity"
                      choices={formOptions?.invitation_opportunity_options}
                      onChange={(invitation_opportunity_id) =>
                        handleSelectedInvitationOpportunity(invitation_opportunity_id)
                      }
                      value={selectedInvitationOpportunity}
                    />
                  </Grid>
                </Grid>
                <br />
                <Grid item xs={12} sm={12} md={6} lg={4}>
                  <FormInput
                    label="Amount Per Invitation"
                    type="money"
                    value={invitationOpportunityFormValues.inviter_amount}
                    onChange={(value) => {
                      const formattedValue = value.charAt(0) === '$' ? value.slice(1) : value;
                      setInvitationOpportunityFormValues({
                        ...invitationOpportunityFormValues,
                        inviter_amount: formattedValue,
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4}>
                  <FormInput
                    label="Invitee Amount"
                    type="money"
                    value={invitationOpportunityFormValues.invitee_amount}
                    onChange={(value) => {
                      const formattedValue = value.charAt(0) === '$' ? value.slice(1) : value;
                      setInvitationOpportunityFormValues({
                        ...invitationOpportunityFormValues,
                        invitee_amount: formattedValue,
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={4}>
                  <FormInput
                    label="Invitation Quota"
                    value={invitationOpportunityFormValues.invitation_quota}
                    onChange={(value) =>
                      setInvitationOpportunityFormValues({
                        ...invitationOpportunityFormValues,
                        invitation_quota: value,
                      })
                    }
                  />
                </Grid>
                <Button
                  primary
                  disabled={selectedInvitationOpportunity === ''}
                  onClick={handleSubmitSelectedInvitationPartners}
                  loading={invitationPartnersSubmitting}
                >
                  {selectedInvitationOpportunity === ''
                    ? 'Select an Invitation Opportunity'
                    : `Add ${selectedLeads.length} Selected Invitation Partners`}
                </Button>
                <span style={{ marginLeft: '5px' }}>
                  <Button
                    disabled={selectedInvitationOpportunity === ''}
                    onClick={handleSubmitFilteredInvitationPartners}
                    loading={invitationPartnersSubmitting}
                  >
                    {selectedInvitationOpportunity === ''
                      ? 'Select an Invitation Opportunity'
                      : `Add All ${filteredInsighterIDs.length} Invitation Partners`}
                  </Button>
                </span>
              </>
            )}
            <br />
          </div>
        </Layout.Section>
      </Layout>
    </Page>
  );
};

export default InsighterFinder;
