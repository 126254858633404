import React from 'react';
import { useHistory } from 'react-router';

import Grid from '@material-ui/core/Grid';

import LoginSplat from '../../../assets/redesignAssets/staticImages/LogInSplat.png';
import Users from '../../../assets/redesignAssets/staticImages/Users.png';
import siteWideStyles from '../../styles/siteWideStyles';

interface LogInButtonProps {
  textColor: string;
}

const LogInButton: React.FC<LogInButtonProps> = (props) => {
  let history = useHistory();

  let { textColor } = props;

  return (
    <Grid
      container
      onClick={() => history.push('/login')}
      style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center' }}
    >
      <Grid
        item
        style={{
          backgroundImage: `url(${LoginSplat})`,
          backgroundSize: 'contain',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          height: '50px',
          width: '50px',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
          padding: 8,
          marginTop: 4,
          marginRight: 10,
        }}
      >
        <img src={Users} alt="Users" style={{ width: '80%', height: 'auto' }} />
      </Grid>
      <Grid item style={{ display: 'flex', alignItems: 'center' }}>
        <p style={{ ...siteWideStyles.navMenuText, color: textColor }}>Log In</p>
      </Grid>
    </Grid>
  );
};

export default LogInButton;
